import React, { useState } from "react";
import { MainContainer } from "components/common";
import { MainHeroSection } from "./styles";
import { BsCurrencyDollar, BsTwitter } from "react-icons/bs";
import tick from "../../assets/tick.png";
import { AiOutlineInstagram } from "react-icons/ai";
import { FaDiscord, FaTiktok } from "react-icons/fa";
import { BsPinterest } from "react-icons/bs";
import { CommonUtility } from "utility";
import { ToastMessage } from "components/common";
const HeroSection = ({ data }) => {
  return (
    <MainHeroSection>
      <MainContainer>
        <div className="heroDetailsInfo ms-lg-3 ">
          <div className="mainTitle">
            <h1>
              <img className="img-fluid" src={data?.logo} />
            </h1>
            <div className="byTitle">
              <h6 className="name">
                {data.username
                  ? data.username
                  : CommonUtility.addressConvertor(data.owner_address)}
                <img className="ms-3" src={tick} />
              </h6>
              <p className="detailsText">{data.description}</p>
            </div>
          </div>
          <div className="socialIcons">
            <div className="socialICons">
              <div className="socialMain">
                <div className="socialIcon">
                  <a href="https://twitter.com/NethtyNFT" target="_blank">
                    <BsTwitter />
                  </a>
                </div>

                <div className="socialIcon">
                  <a href="https://www.instagram.com" target="_blank">
                    <AiOutlineInstagram />
                  </a>
                </div>

                <div className="socialIcon">
                  <a href="https://discord.com" target="_blank">
                    <FaDiscord />
                  </a>
                </div>
                <div className="socialIcon">
                  <a href="https://www.tiktok.com" target="_blank">
                    <FaTiktok />
                  </a>
                </div>
                <div className="socialIcon">
                  <a href="https://www.pinterest.com" target="_blank">
                    <BsPinterest />
                  </a>
                </div>

                {/* <div className="socialIcon">
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">
                      <BsFillShareFill />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        href="https://www.facebook.com/"
                        target="_blank"
                      >
                        <BsFacebook
                          style={{
                            color: "#575757",
                            fontSize: "20px",
                            marginBottom: "5px",
                            marginRight: "10px",
                          }}
                        />
                        Share On Facebook
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="https://web.telegram.org/"
                        target="_blank"
                      >
                        <BsTelegram
                          style={{
                            color: "#575757",
                            fontSize: "20px",
                            marginBottom: "5px",
                            marginRight: "10px",
                          }}
                        />
                        Share On Telegram
                      </Dropdown.Item>
                      <Dropdown.Item href="" onClick={clipToCopy}>
                        <AiFillCopy
                          style={{
                            color: "#575757",
                            fontSize: "20px",
                            marginBottom: "5px",
                            marginRight: "10px",
                          }}
                        />{" "}
                        Copy Link
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="infoSection">
          <div className="wrapper">
            <div className="infoSectionItm">
              <p>{data.items?.length}</p>
              <p className="mt-1">Items</p>
            </div>
            <div className="infoSectionItm">
              <p>{data.owners}</p>
              <p className="mt-1">Owners</p>
            </div>
            <div className="infoSectionItm">
              <p>
                <BsCurrencyDollar style={{ marginBottom: "3px" }} />
                {data.totalVolume}
              </p>
              <p className="mt-1">Total Value</p>
            </div>
            <div className="infoSectionItm">
              <p>
                <BsCurrencyDollar style={{ marginBottom: "3px" }} />
                {data.volume24h}
              </p>
              <p className="mt-1">
                floor <span>-0.65%</span>
              </p>
            </div>
          </div>
          <div className="btnContainer">
            <button className="offerBtn" disabled={true}>
              Make a Collection Offer
            </button>
          </div>
        </div>
      </MainContainer>
    </MainHeroSection>
  );
};

export default HeroSection;
