import React, { useRef, useState } from "react";
import "components/common/datatable/datatable.css";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import * as C from "components/common/datatable/dataTableElements";
import { useMatch } from "react-router-dom";
const CollectionTable = ({ data }) => {
  const dt = useRef(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [collectionDialog, setCollectionDialog] = useState(false);
  const [collection, setCollection] = useState();
  let match = useMatch("minted-nfts");
  const logoBodyTemplate = (rowData) => {
    return <img src={rowData.logo} style={{ width: "6rem" }} />;
  };
  const nftDetail = (data) => {
    setCollection(data);
    setCollectionDialog(true);
  };
  const header = (
    <div className="table-header">
      <h5 className="p-mx-0 p-my-1">{match ? "Minted Nfts" : "Collections"}</h5>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
        />
      </span>
    </div>
  );
  const detailDialogFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => setCollectionDialog(false)}
      />
    </React.Fragment>
  );
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button className=" p-button-info" onClick={() => nftDetail(rowData)}>
          Details
        </Button>
      </React.Fragment>
    );
  };
  return (
    <C.MainDiv className="datatable-crud-demo">
      <C.MainCard className="card">
        <C.DataTables
          ref={dt}
          value={data ? data : []}
          dataKey="id"
          paginator
          globalFilter={globalFilter}
          rows={10}
          rowsPerPageOptions={[5, 10, 25]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Users"
          header={header}
          responsiveLayout="scroll"
        >
          <C.Columns
            field="name"
            header="Name"
            sortable
            style={{ minWidth: "12rem" }}
          ></C.Columns>
          <C.Columns
            field="description"
            header="Description"
            sortable
            style={{ minWidth: "16rem" }}
          ></C.Columns>
          <C.Columns
            field="collection_address"
            header="Collection Address"
            sortable
            style={{ minWidth: "10rem" }}
          ></C.Columns>
          <C.Columns
            field="logo"
            header="Logo"
            body={logoBodyTemplate}
          ></C.Columns>
          <C.Columns
            field="network"
            header="Network"
            sortable
            style={{ minWidth: "8rem" }}
          ></C.Columns>
          <C.Columns
            body={actionBodyTemplate}
            exportable={false}
            style={{ minWidth: "8rem" }}
          ></C.Columns>
        </C.DataTables>
      </C.MainCard>
      <C.Dialogs
        visible={collectionDialog}
        style={{ width: "450px" }}
        header="Nfts Details"
        modal
        className="p-fluid"
        footer={detailDialogFooter}
        onHide={() => setCollectionDialog(false)}
      >
        <img src={collection?.logo} width="100%" />
        <b>Name</b>: {collection?.name} <br />
        <b>Description</b>: {collection?.description} <br />
        <b>Collection Address</b>:{" "}
        <label
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {collection?.collection_address}{" "}
        </label>
        <br />
      </C.Dialogs>
    </C.MainDiv>
  );
};

export default CollectionTable;
