import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector, TypedUseSelectorHook } from "react-redux";
import createSagaMidleware from "redux-saga";
import parentReducer from "./redux/parentReducer";

const store = configureStore({
  reducer: parentReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// export type AppDispatch = typeof store.dispatch;
// export type RootStateType = ReturnType<typeof parentReducer>;

export const useAppDispatch = () => useDispatch();
export const useAppSelector = useSelector;

export default store;
