import { useState, useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { ClaimBtn, ConnectBtn, MainSection, ThemeHandler } from "./elements";
import logo from "../../../assets/starship/logo2.png";
import { Image } from "react-bootstrap";
import { Modal } from "antd";
import { StarShipConnectWalletModal } from "components/modal";
import { profileimg } from "assets";
import { Link } from "react-router-dom";
import UserModal from "components/modal/userModel";
import { darkLightModelSlice } from "store/redux/slices/helperSlices/modelSlice";
import { MdOutlineDarkMode } from "react-icons/md";
import { BsSun } from "react-icons/bs";
import { useAppDispatch, useAppSelector } from "store/store";
import { useLocation } from "react-router-dom";
import { CommonUtility, ERC20Service } from "utility";
import { ToastMessage } from "components/common";
import Loading from "components/common/loader";
import ConnectModals from "components/modal/connectModal";
import OpenPackModal from "components/modal/openpackModal";

const StarshipNavbar = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const { account, web3, paymentTokens } = useAppSelector(
    (state) => state.web3Connect
  );
  const { user } = useAppSelector((x) => x.user);

  const [theme, setTheme] = useState("light");
  const dispatch = useAppDispatch();
  const location = useLocation();
  const claimTestTokens = async () => {
    try {
      setIsLoading(true);

      const txn = await ERC20Service.mint(
        web3,
        CommonUtility.convertToWei(10000000, paymentTokens[0].decimals),
        account,
        paymentTokens[0].address
      );
      if (txn && txn.code == 4001) throw "User denied the transaction";
      if (!txn.status) throw "Transaction Failed";
      if (txn.status) {
        setIsLoading(false);
        ToastMessage("Success!", "Claimed successfully", "success");
      }
    } catch (error) {
      setIsLoading(false);
      console.log("error", error);
      if (error?.message) {
        ToastMessage("Error", error?.message, "error");
      } else {
        ToastMessage("Error", error, "error");
      }
    }
  };
  const paths =
    location.pathname === "/starship/explore" ||
    location.pathname.startsWith("/starship/view-collection") ||
    location.pathname === "/" ||
    location.pathname === "/starship/view-drops" ||
    location.pathname.startsWith("/starship/profile") ||
    location.pathname.startsWith("/starship/pack-details");

  const connectWalletRoute =
    location.pathname.includes("admin") || location.pathname === "/starship";

  const containerPaths =
    location.pathname === "/starship/explore" ||
    location.pathname === "/starship/view-collection" ||
    location.pathname === "/" ||
    location.pathname.startsWith("/starship/profile") ||
    location.pathname === "/admin/starship" ||
    location.pathname === "/starship/admin" ||
    location.pathname === "/starship/admin/template" ||
    location.pathname === "/starship/admin/schemas" ||
    location.pathname === "/starship/admin/schemas/create-schema" ||
    location.pathname === "/starship/admin/template/create-template" ||
    location.pathname === "/starship/admin/template/view-template" ||
    location.pathname.startsWith(
      "/starship/admin/template/view-template/detail"
    ) ||
    location.pathname.startsWith(
      "/starship/admin/template/edit-template/detail"
    ) ||
    location.pathname === "/starship/admin/mint-nfts" ||
    location.pathname === "/starship/admin/drops" ||
    location.pathname === "/starship/admin/create-drop" ||
    location.pathname.startsWith("/starship/admin/packs") ||
    location.pathname === "/starship/view-collection/detail" ||
    location.pathname === "/starship/secure-drops" ||
    location.pathname === "/starship/view-drops" ||
    location.pathname === "/starship/pack-detail/id" ||
    location.pathname.startsWith("/starship/pack-detail");

  const showModal = () => {
    setIsModalOpen(true);
  };

  const showUserModal = () => {
    setIsUserModalOpen(!isUserModalOpen);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setIsUserModalOpen(false);
  };

  const handleDarkTheme = () => {
    localStorage.setItem("themeColor", "dark");
    dispatch(darkLightModelSlice("dark"));
    setTheme("dark");
  };
  const handleLightTheme = () => {
    localStorage.setItem("themeColor", "light");
    dispatch(darkLightModelSlice("light"));
    setTheme("light");
  };

  const allowedBtns = ["/starship/view-collection/detail", "/starship/explore"];

  const darkModePaths =
    location.pathname === "/" ||
    location.pathname === "/starship/view-collection/detail" ||
    location.pathname === "/starship/explore" ||
    // location.pathname === "/starship/view-drops" ||
    location.pathname.startsWith("/starship/view-drops") ||
    location.pathname.startsWith("/starship/profile") ||
    location.pathname.startsWith("/starship/pack-details/");

  const shouldShowButton = allowedBtns.includes(location.pathname);

  useEffect(() => {
    if (!darkModePaths) {
      localStorage.setItem("themeColor", "light");
      dispatch(darkLightModelSlice("light"));
      setTheme("light");
    }
  }, [location.pathname]);

  return (
    <MainSection>
      {isLoading && <Loading content={"Processing"} />}

      {/* <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        width={"489px"}
      >
        <StarShipConnectWalletModal
          handleCancel={handleCancel}
          setIsModalOpen={setIsModalOpen}
        />
      </Modal> */}
      <Modal open={isModalOpen} centered onCancel={handleCancel} width={504}>
        <ConnectModals onCancel={handleCancel} />
      </Modal>

      <Navbar
        expand="lg"
        className={`bg-body-tertiary ${
          !darkModePaths && "light-class"
        } main-nav`}
      >
        <div className={containerPaths ? "container-fluid" : "container"}>
          <Navbar.Brand>
            <Link to="/">
              <Image src={logo} />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Link to="/starship/explore">Marketplace</Link>
              <Link to="/starship/view-drops">Drops</Link>

              {/* <Link to="/">Collections</Link> */}
              {user?.isAdmin && <Link to="/admin/starship">Admin</Link>}
              {/* {<Link to="/admin/starship">Admin</Link>} */}
            </Nav>

            {shouldShowButton && (
              <ClaimBtn disabled={!account} onClick={claimTestTokens}>
                Claim testnet tokens
              </ClaimBtn>
            )}

            {paths && (
              <ThemeHandler>
                <BsSun
                  className="themeIcons"
                  onClick={handleLightTheme}
                  color={theme === "light" ? "#1890D1" : "#FFFFFF"}
                  size={20}
                />
                <MdOutlineDarkMode
                  className="themeIcons"
                  onClick={handleDarkTheme}
                  color={theme === "dark" ? "#1890D1" : "#727989"}
                  size={20}
                />
              </ThemeHandler>
            )}

            {web3 ? (
              <div onClick={showUserModal}>
                <div className="profile-div">
                  <div>
                    <Image
                      src={user?.profImage || profileimg}
                      onError={(e) => (e.target.src = profileimg)}
                      className="profile-img"
                      height={39}
                      width={39}
                      style={{ borderRadius: "100px" }}
                    />
                  </div>
                  <div>
                    <p> {CommonUtility.addressConvertor(account)}</p>
                  </div>
                </div>
              </div>
            ) : (
              !web3 &&
              !connectWalletRoute && (
                <ConnectBtn onClick={() => showModal()}>
                  Connect Wallet
                </ConnectBtn>
              )
            )}
          </Navbar.Collapse>

          {isUserModalOpen && (
            <div className="modal-div">
              <UserModal onClose={handleCancel} />
            </div>
          )}
        </div>
      </Navbar>
    </MainSection>
  );
};

export default StarshipNavbar;
