import styled from "styled-components";

export const CardsWrapper = styled.div`
  margin-top: 2rem;

  .btn-wrapper {
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
  }

  .card {
    /* width: 260px; */
    width: 100%;
    min-height: 434px;
    border-radius: 0.66481rem;
    box-shadow: 0px 0px 4.254847526550293px 0px rgba(0, 0, 0, 0.27);
    background-color: ${({ theme }) => theme.cardBG};
    border: 1.064px solid ${({ theme }) => theme.cardBorder} !important;
  }

  .card-img,
  .card-img-bottom {
    margin-top: -0.6rem;
  }

  .img-wrapper {
    padding-bottom: 0;

    img {
      /* border: 2.064px solid ${({ theme }) => theme.cardBorder} !important; */
      /* object-fit: cover; */
      border-radius: 0px;
    }
  }

  .card-title {
    color: ${({ theme }) => theme.cardText};
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-transform: capitalize;
    text-align: center;
  }

  .icons-div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
  }

  .reduced-padding {
    padding-top: 0;
  }

  .test-text {
    color: #6b6b6b;

    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    text-align: center;
  }

  .token-text {
    color: #c9c9c9;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    text-align: center;
  }

  .additional-content {
    top: 42%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    color: white;
    font-size: 1.9375rem;
    font-family: "Barlow";
    padding: 10px;
    border-radius: 5px;
    position: absolute;
    width: 300px;
    background-color: ${({ theme }) => theme.cardBG};

    /* height: 385px; */
  }

  .reduced-width {
    top: 43%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    color: white;
    font-size: 1.9375rem;
    font-family: "Barlow";
    padding: 10px;
    border-radius: 5px;
    position: absolute;
    /* width: 260px; */
    width: 100%;
    background-color: ${({ theme }) => theme.cardBG};
    height: 360px;
  }

  h5 {
    color: #6b6b6b;
    font-family: Barlow;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
  }

  p {
    color: var(--secondary, #3e3e3e);
    font-family: Barlow;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
  }

  h4 {
    color: #00b7ff;
    font-family: Barlow;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
  }

  .card-title-span {
    cursor: pointer;
  }
`;

export const ListBtn = styled.button`
  width: 10.3125rem;
  border-radius: 28px;
  border: 1px solid #00b7ff;
  margin-top: 1rem;
  color: var(--pimary, #00b7ff);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  background-color: transparent;
  min-height: 35px;
  display: block;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 400px) {
    width: 100%;
    font-size: 12px;
  }
  &:hover {
    background: #00b7ff;
    color: #fff;
  }
`;

export const DelistBtn = styled.button`
  color: var(--pimary, #00b7ff);
  font-family: Barlow;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  border-radius: 1.75rem;
  border: 1px solid var(--pimary, #00b7ff);
  width: 5.8125rem;
  height: 2.1875rem;
  background-color: transparent;

  &:hover {
    background: #00b7ff;
    color: #fff;
  }
`;

export const UpdateBtn = styled.button`
  color: var(--pimary, #00b7ff);
  font-family: Barlow;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  border-radius: 1.75rem;
  border: 1px solid var(--pimary, #00b7ff);
  width: 5.8125rem;
  height: 2.1875rem;
  background-color: transparent;

  &:hover {
    background: #00b7ff;
    color: #fff;
  }
`;
