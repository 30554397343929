import { MainSection, MainWrapper, Text, Title } from "./elements";
import starship from "../../../assets/starship/starshipimg.png";
import { Image } from "react-bootstrap";
import { MainContainer } from "components/common";
import img from "../../../assets/starship/mainimg.svg";
import { Link } from "react-router-dom";
import { useAppSelector } from "store/store";

const MainSectionComp = () => {
  const { user } = useAppSelector((x) => x.user);
  return (
    <MainSection>
      <MainContainer fluid>
        <Image
          src={user?.profImage}
          onError={(e) => {
            e.target.src = starship;
          }}
          width={135}
          height={135}
          style={{ borderRadius: "1000px" }}
        />
        <Title>Starship NFT Game</Title>

        <div
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Link to="/starship/admin/schemas" style={{ textDecoration: "none" }}>
            <MainWrapper>
              <Image src={img} width={20} height={20} />
              <h5>Schemas and templates</h5>
            </MainWrapper>
          </Link>
          <Link
            to="/starship/admin/mint-nfts"
            style={{ textDecoration: "none" }}
          >
            <MainWrapper>
              <Image src={img} width={20} height={20} />
              <h5>Mint NFTs</h5>
            </MainWrapper>
          </Link>

          <Link to="/starship/admin/packs" style={{ textDecoration: "none" }}>
            <MainWrapper>
              <Image src={img} width={20} height={20} />
              <h5>packs</h5>
            </MainWrapper>
          </Link>
          <Link to="/starship/admin/drops" style={{ textDecoration: "none" }}>
            <MainWrapper>
              <Image src={img} width={20} height={20} />
              <h5>Drops</h5>
            </MainWrapper>
          </Link>

          {/* <Link to="/starship/secure-drops" style={{ textDecoration: "none" }}>
            <MainWrapper>
              <Image src={img} width={20} height={20} />
              <h5>Secure Drops</h5>
            </MainWrapper>
          </Link> */}

          <Link to="/" style={{ textDecoration: "none" }}>
            <MainWrapper>
              <Image src={img} width={20} height={20} />
              <h5>view collections</h5>
            </MainWrapper>
          </Link>
        </div>
      </MainContainer>
    </MainSection>
  );
};

export default MainSectionComp;
