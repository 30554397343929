import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import { ToastMessage } from "components/common"
import openNotification from "components/common/toastMessage/toastMessage"
import { CREATE_REWARD } from "gql/mutations"
import { GET_REWARDS, USER_REWARDS } from "gql/queries"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useAppSelector } from "store/store"
import { ErrorConstant } from "utility"



export const UserRewardsHook = () => {

    const [user_rewards, { data, loading, error }] = useLazyQuery(USER_REWARDS)
    const { web3, account } = useAppSelector(state => state.web3Connect)

    useEffect(() => {
        web3 && user_rewards({ variables: { userAddress: account } })
    }, [web3])

    useEffect(() => {
        error && openNotification('Error', error?.message, 'error')
    }, [error])

    return {
        loading,
        data: data?.user_rewards || []
    }

}

export const CreateRewardHook = () => {

    const [create_reward, { data, loading, error }] = useMutation(CREATE_REWARD)

    const createReward = (values) => {
        create_reward({ variables: values })
    }

    useEffect(() => {
        error && openNotification('Error', 'unable to list reward', 'error')
    }, [error])

    return {
        rewardLoading: loading,
        data: data?.user_rewards,
        createReward
    }

}

export const GetRewardsHook = () => {

    const { data, loading, error } = useQuery(GET_REWARDS)
    const navigate = useNavigate()

    useEffect(() => {
        error && error.message === 'invalid token' && navigate("/admin-login")
        error && ToastMessage("Error", ErrorConstant.default, "error")
    }, [error])

    return {
        rewardLoading: loading,
        data: data?.get_rewards,
    }

}