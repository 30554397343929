import StarShipComp from "components/starship";

const StarShipScreen = () => {
  return (
    <div>
      <StarShipComp />
    </div>
  );
};

export default StarShipScreen;
