import { useEffect } from "react";
import {
  BreadCrumbComp,
  MainCol,
  MainContainer,
  MainRow,
} from "components/common";
import { BtnWrapper, CancelBtn } from "../schema/elements";
import { Link, useParams } from "react-router-dom";
import {
  CheckBoxDiv,
  ImgDiv,
  MainWrapper,
  TextareaDiv,
  Title,
} from "./elements";
import { BsQuestionCircleFill } from "react-icons/bs";
import { Input } from "antd";
import { Checkbox } from "antd";
import { useFormik } from "formik";
import { GetTemplateByIdHook } from "hooks/templateHook";
import Loading from "components/common/loader";
import { useAppSelector } from "store/store";

const dataa = [
  {
    id: 1,
    text: "Starship NFT Game",
    link: "/admin/starship",
  },
  {
    id: 2,
    text: "Schemas and templates",
    link: "/starship/admin/schemas",
  },

  {
    id: 3,
    text: "Template Detail",
    link: "",
  },
];

const TemplateDetail = () => {
  const { id } = useParams();
  const { config } = useAppSelector((state) => state.configData);

  const { data, loading } = GetTemplateByIdHook(id);

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    setFieldValue,
    setFieldTouched,
  } = useFormik({
    initialValues: {
      max_supply: "",
      description: "",
      is_transferable: false,
      is_burnable: false,
      schema_attributes: [
        {
          attribute_placeholder: "",
          types: "",
          attribute_value: "",
          id: 0,
        },
      ],
    },
    validate: (values) => {
      const errors = {};

      if (!values.max_supply) {
        errors.max_supply = "required";
      }

      values.schema_attributes.forEach((attribute, index) => {
        if (!attribute.attribute_value.trim()) {
          errors[`schema_attributes.${index}.attribute_value`] = "required.";
        }
      });

      return errors;
    },
    onSubmit: async (values) => {
      values.template_attributes = values.schema_attributes?.map((x) => {
        return {
          attribute_value: x.attribute_value,
          attribute_type: x.types,
          file: x?.file ? x?.file : null,
        };
      });
      values.schema_id = id;
    },
  });

  useEffect(() => {
    if (data) {
      setFieldValue("max_supply", data?.max_supply);
      setFieldValue("description", data?.description);
      setFieldValue("schema_name", data?.schema_id?.schema_name);
      let attributes1 = data?.template_attributes?.map((x, index) => {
        return {
          ...x,
          id: index + 1,
          types: x.attribute_type,
          attribute_type: x.attribute_value,
        };
      });

      setFieldValue("schema_attributes", attributes1);
      setFieldValue("is_transferable", data?.is_transferable);
      setFieldValue("is_burnable", data?.is_burnable);
    }
  }, [data]);

  return (
    <div>
      {loading && <Loading content={"Loading"} />}
      <MainContainer fluid>
        <div style={{ marginTop: "3rem" }}>
          <BreadCrumbComp data={dataa} />
        </div>

        <MainWrapper>
          <Title>
            View template&nbsp;
            <BsQuestionCircleFill fontSize={15} />
          </Title>
          <p>{values?.schema_name}</p>
          <MainRow>
            <MainCol lg={6}>
              <Input
                placeholder="max supply"
                name="max_supply"
                value={
                  +values?.max_supply == 0 ||
                  +values?.max_supply >= +config?.UINT256_MAX
                    ? "∞"
                    : values?.max_supply
                }
                onBlur={handleBlur}
                type="text"
                readOnly
              />

              <TextareaDiv>
                <p>Description</p>
                <textarea
                  rows={5}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="description"
                  value={values.description}
                  readOnly
                />
              </TextareaDiv>

              <CheckBoxDiv>
                <Checkbox
                  type="checkbox"
                  name="is_burnable"
                  value={values?.is_burnable}
                  checked={values?.is_burnable}
                >
                  burnable
                </Checkbox>

                <br />
                <span>when a nFT is burned, it is destroyed forever</span>
              </CheckBoxDiv>

              <CheckBoxDiv>
                <Checkbox
                  name="is_transferable"
                  checked={values?.is_transferable}
                  value={values?.is_transferable}
                >
                  transferable
                </Checkbox>
                <span style={{ color: "#F65D5D", marginTop: "1rem" }}>
                  {touched.is_transferable &&
                    errors.is_transferable &&
                    "required"}
                </span>
                <br />
                <span>transferable NFT’s can change their owner</span>
              </CheckBoxDiv>

              <div>
                <Title className="mt-4">attributes</Title>
                {values?.schema_attributes?.map((x, index) => (
                  <>
                    <div key={x._id} className="mt-4">
                      {x.field !== "file" && x.field !== "number[]" ? (
                        <Input
                          placeholder={`${x?.attribute_placeholder}`}
                          readOnly
                          type={`${x.types}`}
                          value={
                            values.schema_attributes[index]?.attribute_value
                          }
                          onBlur={() =>
                            setFieldTouched(
                              `schema_attributes.${index}.attribute_value`,
                              true
                            )
                          }
                        />
                      ) : (
                        x.field == "number[]" &&
                        x?.numbers?.map((x) => (
                          <Input
                            placeholder={`${x?.types}`}
                            readOnly
                            type={`${x.types}`}
                            value={x.number}
                            onBlur={() =>
                              setFieldTouched(
                                `schema_attributes.${index}.attribute_value`,
                                true
                              )
                            }
                          />
                        ))
                      )}

                      {x.field !== "file" && <span>{`type ${x.types}`}</span>}
                    </div>
                  </>
                ))}
              </div>
            </MainCol>

            <MainCol lg={2}></MainCol>

            <MainCol lg={4}>
              <ImgDiv>
                <img
                  src={data?.ipfs_image}
                  style={{
                    width: "410px",
                    height: "297px",
                  }}
                />
              </ImgDiv>
            </MainCol>
          </MainRow>

          <BtnWrapper>
            <Link
              to={
                "/starship/admin/template/view-template?id=" +
                data?.schema_id?._id
              }
            >
              <CancelBtn>Back</CancelBtn>
            </Link>
          </BtnWrapper>
        </MainWrapper>
      </MainContainer>
    </div>
  );
};

export default TemplateDetail;
