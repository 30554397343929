import styled from "styled-components";

export const ModalWrapper = styled.div`
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid #e7e7e7;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27);
  padding: 1rem 2rem 2rem 2rem;
  min-height: 303px;
  position: relative;

  .close-icon {
    position: absolute;
    top: 5px;
    right: 10px;
  }

  .last-text {
    color: #6b6b6b;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    margin-top: 10px;

    @media (max-width: 575.98px) {
      text-align: center;
    }
  }
`;

export const MainHeader = styled.h2`
  color: #3e3e3e;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-transform: capitalize;
  margin-top: 2rem;

  @media (max-width: 575.98px) {
    text-align: center;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;

  @media (max-width: 575.98px) {
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
  }

  .left-div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2rem;
    flex: 2;

    @media (max-width: 575.98px) {
      flex-direction: column;
      justify-content: center;
      gap: 1rem;
    }
  }

  h4 {
    margin: 0;
    color: #3e3e3e;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-transform: capitalize;

    @media (max-width: 575.98px) {
      text-align: center;
    }
  }

  .name-text {
    color: #3e3e3e;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-transform: uppercase;

    @media (max-width: 575.98px) {
      text-align: center;
    }
  }

  .chain-text {
    color: #6b6b6b;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;

    @media (max-width: 575.98px) {
      text-align: center;
    }
  }

  .right-div {
    flex: 1;

    h4 {
      color: #3e3e3e;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      text-transform: capitalize;
      text-align: right;

      @media (max-width: 575.98px) {
        text-align: center;
      }
    }

    .dollar-text {
      color: #6b6b6b;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: capitalize;
      text-align: right;

      @media (max-width: 575.98px) {
        text-align: center;
      }
    }
  }
`;

export const WalletMainText = styled.h4`
  margin-top: 2rem;
  color: #3e3e3e;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-transform: capitalize;

  @media (max-width: 575.98px) {
    text-align: center;
  }
`;
