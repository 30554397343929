import { DropService } from "utility/services/dropService";
import { CommonHook } from "./commonHook";
// import { storage } from "firebase";
import { useEffect, useState } from "react";
import { CommonUtility } from "utility";

export const CreateDropHook = () => {
  const { data, setData, setError, loading, setLoading, error } = CommonHook();
  const createDrop = async (obj) => {
    try {
      setLoading(true);
      const jsonData = JSON.stringify(obj);
      let encrypted = CommonUtility.encrypt(jsonData);
      const result = await DropService.createDrop(encrypted);
      if (result.data) {
        setData(result.data);
      }
    } catch (error) {
      console.log("hook error", error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    createDrop,
    data,
    loading,
    error,
    setLoading,
  };
};

export const GetDropsHook = (page, showAlldrop) => {
  const { data, setData, setError, loading, setLoading, error } = CommonHook();
  const [dropCount, setDropCount] = useState(0);
  const getDrops = async (page) => {
    try {
      setLoading(true);
      const result = await DropService.getDrops(page, showAlldrop);
      if (result.data) {
        let temp = CommonUtility.decrypt(result.data);
        temp = JSON.parse(temp);
        setData(temp);
        setDropCount(result.dropCount);
        setLoading(false);
      }
    } catch (error) {
      setError(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getDrops(page);
  }, [page]);

  return {
    getDrops,
    data,
    loading,
    error,
    dropCount,
  };
};

export const GetViewAllDropsHook = (page, filter) => {
  const { data, setData, setError, loading, setLoading, error } = CommonHook();
  const [dropCount, setDropCount] = useState(0);
  const getViewAllDrops = async (page, filter) => {
    try {
      setLoading(true);
      const result = await DropService.getViewAllDrops(page, filter);
      if (result.data) {
        let temp = CommonUtility.decrypt(result.data);
        temp = JSON.parse(temp);
        setData(temp);
        setDropCount(result.dropCount);
        setLoading(false);
      }
    } catch (error) {
      setError(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getViewAllDrops(page, filter);
  }, [page, filter]);

  return {
    getViewAllDrops,
    data,
    loading,
    error,
    dropCount,
  };
};
export const UpdateDropHook = () => {
  const { data, setData, setError, loading, setLoading, error } = CommonHook();
  const updateDrop = async (obj) => {
    try {
      setLoading(true);
      const result = await DropService.updateDrop(obj);
      if (result.data) {
        setData(result.data);

        setLoading(false);
      }
    } catch (error) {
      setError(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return {
    updateDrop,
    data,
    loading,
    error,
  };
};

export const GetDropMintsHook = () => {
  const { data, setData, setError, loading, setLoading, error } = CommonHook();
  const getDropMints = async () => {
    try {
      setLoading(true);
      const result = await DropService.getDropsMints();
      if (result.data) {
        let temp = CommonUtility.decrypt(result.data);
        temp = JSON.parse(temp);
        setData(temp);
        setLoading(false);
      }
    } catch (error) {
      setError(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getDropMints();
  }, []);

  return {
    getDropMints,
    data,
    loading,
    error,
  };
};

export const GetUserDropMintsHook = (account) => {
  const { data, setData, setError, loading, setLoading, error } = CommonHook();
  const getUserDropMints = async (account) => {
    try {
      setLoading(true);
      console.log("Hook account", account);
      const result = await DropService.getUserDropMints(account);
      if (result.data) {
        let temp = CommonUtility.decrypt(result.data);
        temp = JSON.parse(temp);
        setData(temp);
        setLoading(false);
      }
    } catch (error) {
      setError(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (account) getUserDropMints(account);
  }, [account]);

  return {
    getUserDropMints,
    data,
    loading,
    error,
  };
};

export const GetDropNftsHook = () => {
  const { data, setData, setError, loading, setLoading, error } = CommonHook();
  const getDropNfts = async (from, to, dropId) => {
    try {
      setLoading(true);
      const result = await DropService.getDropNfts(from, to, dropId);
      if (result.data) {
        let temp = CommonUtility.decrypt(result.data);
        temp = JSON.parse(temp);
        setData(temp);
        setLoading(false);
        return temp;
      }
    } catch (error) {
      setError(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return {
    getDropNfts,
    data,
    loading,
    error,
  };
};

export const UpdateRevealStatus = () => {
  const { data, setData, setError, loading, setLoading, error } = CommonHook();
  const updateRevealStatus = async (hash) => {
    try {
      setLoading(true);
      const result = await DropService.updateRevealStatus(hash);
      if (result.data) {
        setData(result.data);
        setLoading(false);
      }
    } catch (error) {
      setError(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return {
    updateRevealStatus,
    data,
    loading,
    error,
  };
};
