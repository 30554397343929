import styled from "styled-components";
import { Card } from "react-bootstrap";

export const InfoTitle = styled.p`
  text-align: center;
  margin-top: 5rem;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 1.5rem;
  color: white;
`;

export const InfoCardTitle = styled(Card.Title)`
  text-align: center;
  font-family: "Poppins";

  font-size: 1.3rem;
`;
export const InfoCardText = styled(Card.Text)`
  color: rgb(112, 122, 131);
  font-weight: 500;
  font-family: "Poppins";
  text-align: center;
`;
