import { MainContainer } from "components/common";
import React, { useEffect } from "react";
import { NoDataFoundComDiv, PacksDetailsWrapper } from "./element";
import Loading from "components/common/loader";
import NoDataFoundCom from "../noDataFound";
import { ProfileCard } from "components/common/cards";
import { GetNftsByHash, GetNftsByUserHook } from "hooks/mintHook";
import { useAppSelector } from "store/store";
import { useLocation, useParams } from "react-router-dom";
import { UpdateRevealStatus } from "hooks/dropHook";
const PacksDetail = () => {
  const { account } = useAppSelector((state) => state.web3Connect);
  const { data, getNftsByUser, loading } = GetNftsByUserHook(account);
  const { updateRevealStatus } = UpdateRevealStatus();

  const { transaction_hash } = useParams();
  const location = useLocation();
  console.log("TransactionHAsh", transaction_hash);
  const {
    data: packNfts,
    getNftByHash,
    loading: packLoading,
  } = GetNftsByHash(transaction_hash);
  useEffect(() => {
    if (packNfts && transaction_hash) updateRevealStatus(transaction_hash);
  }, [packNfts, transaction_hash]);
  return (
    <PacksDetailsWrapper>
      <MainContainer fluid>
        {loading || packLoading ? (
          <Loading content={"Loading"} />
        ) : (
          <>
            {(location.pathname !== `/starship/pack-details/${transaction_hash}`
              ? data
              : packNfts
            )?.length > 0 ? (
              <div className="card-wrapper">
                {(location.pathname !==
                `/starship/pack-details/${transaction_hash}`
                  ? data
                  : packNfts
                )?.map((x, index) => (
                  <ProfileCard
                    key={index}
                    item={x}
                    index={index}
                    getNftsByUser={
                      location.pathname !==
                      `/starship/pack-details/${transaction_hash}`
                        ? getNftsByUser
                        : getNftByHash
                    }
                  />
                ))}
              </div>
            ) : (
              <NoDataFoundComDiv>
                <NoDataFoundCom message={"No Nfts Found!"} />
              </NoDataFoundComDiv>
            )}
          </>
        )}
      </MainContainer>
    </PacksDetailsWrapper>
  );
};

export default PacksDetail;
