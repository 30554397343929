import styled from "styled-components";

export const ProfileWrapper = styled.div`
  overflow: hidden;
  background-color: ${({ theme }) => theme.backgroundColor};

  .container-fluid {
    margin-top: 1rem;
    padding-bottom: 13rem;
    padding-left: 7rem;
    padding-right: 7rem;

    @media (max-width: 767.98px) {
      padding-right: 2rem;
      padding-left: 2rem;
    }
    @media (max-width: 575.98px) {
      padding-right: 10px;
      padding-left: 10px;
    }
  }

  .card-wrapper {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 0px;

    @media (max-width: 1399.98px) {
      grid-template-columns: repeat(5, 1fr);
    }

    @media (max-width: 1199.98px) {
      grid-template-columns: repeat(4, 1fr);
    }
    @media (max-width: 991.98px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 767.98px) {
      /* grid-template-columns: repeat(2, 1fr); */
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 670.98px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 455.98px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .no-data-div {
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: -2rem; */
  }

  .reduced-card {
    /* display: grid; */
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 0px;

    @media (max-width: 1399.98px) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media (max-width: 1199.98px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 767.98px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 670.98px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 455.98px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .profile-bg {
    width: 100%;
    height: 370px;
    object-fit: cover;
  }

  .whole-content-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    @media (max-width: 991.98px) {
      flex-direction: column;
    }
  }

  .profile-filters-div {
    width: 20%;
    padding-right: 2rem;
    margin-top: 1rem;

    @media (max-width: 1399.98px) {
      width: 40%;
    }
    @media (max-width: 991.98px) {
      width: 100%;
      padding-right: 0;
    }
  }

  .filter-header {
    color: #6b6b6b;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 370px;
    background-color: rgba(0, 0, 0, 0.75);
    opacity: 0;
    transition: opacity 0.3s ease;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      opacity: 1;
    }
  }

  .edit-icon {
    cursor: pointer;
    font-size: 24px;
    color: #fff;
  }
`;

export const TopSectionDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  gap: 1rem;
  position: absolute;
  bottom: 0;
  left: 45%;
  z-index: 100;

  @media (max-width: 991.98px) {
    left: 40%;
  }
  @media (max-width: 575.98px) {
    left: 35%;
  }

  img {
    width: 135px;
    height: 135px;
    border: 5px solid #fff;
    /* object-fit: contain; */
    /* border-radius: 50px; */
  }

  .back-arrow {
    font-size: 32px;
    cursor: pointer;
    position: absolute;
    left: 0;
    z-index: 20;
    top: 50px;
    color: #00b7ff !important;
  }

  .wallet-text {
    color: #6b6b6b;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    border-radius: 10px;
    border: 1px solid #e7e7e7;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27);
    padding: 3px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin: 0;
  }
`;

export const UserName = styled.h2`
  color: ${({ theme }) => theme.filterSubTextColor};

  font-family: Barlow;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-transform: capitalize;
`;

export const JoinedDate = styled.p`
  color: #6b6b6b;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  margin: 0;
`;

export const TabsDiv = styled.div`
  margin-top: 2rem;
  position: relative;

  .filter-img {
    position: absolute;
    top: 10px;
    cursor: pointer;
    z-index: 100;

    @media (max-width: 991.98px) {
      position: static;
    }
    @media (max-width: 575.98px) {
      margin-bottom: 2rem;
    }
  }

  .ant-tabs-bottom > .ant-tabs-nav:before,
  .ant-tabs-bottom > div > .ant-tabs-nav:before,
  .ant-tabs-top > .ant-tabs-nav:before,
  .ant-tabs-top > div > .ant-tabs-nav:before {
    border-bottom: none;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: var(--pimary, #00b7ff);
    text-align: center;
  }

  .ant-tabs-tab {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    color: #6b6b6b;
    &:hover {
      color: #dcdcdc !important;
    }
  }

  .ant-tabs-ink-bar {
    background: #00b7ff;
  }

  @media (max-width: 756px) {
    .ant-tabs > .ant-tabs-nav,
    .ant-tabs > div > .ant-tabs-nav {
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }
  }
`;

export const TableDiv = styled.div`
  margin-top: 2rem;

  .ant-table {
    @media (max-width: 991.98px) {
      overflow-x: auto !important;
    }
  }
`;

export const CancelBtn = styled.button`
  border-radius: 28px;
  border: 0.5px solid #3e3e3e;
  background-color: transparent;
  color: #3e3e3e;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  width: 101px;
  min-height: 25px;
`;

export const NoDataDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;

  p {
    color: #3e3e3e;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-transform: capitalize;
  }
`;

export const FilterTitle = styled.h5`
  color: var(--pimary, #00b7ff);
  font-family: Barlow;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  border-bottom: 0.5px solid #00b7ff;
  padding-bottom: 10px;
  width: 12rem;
`;

export const SelectDiv = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 1.75rem !important;
    border: 1px solid #6b6b6b !important;
  }

  .ant-select-arrow {
    color: #6b6b6b;
  }

  width: 200px;

  @media (max-width: 991.98px) {
    width: 100%;
  }
`;

export const SearchWrapper = styled.div`
  .form-label {
    color: #6b6b6b;
    font-family: Barlow;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    padding-bottom: 0.3rem;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 0.625rem;
    border: 1px solid ${({ theme }) => theme.selectBorder} !important;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27);
    background-color: transparent;
  }

  .form-control {
    border: none;
    width: 100%;
    font-size: 0.75rem;
    width: 3rem;
    padding: 0.375rem 0.35rem;
    &:focus {
      box-shadow: none;
    }

    &::placeholder {
      color: #c9c9c9;
      font-family: Barlow;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  .form-text {
    color: #6b6b6b;
    font-family: Barlow;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    padding: 0px 0.7rem;
    padding-bottom: 0.4rem;
  }

  .field-div {
    display: flex;
    align-items: center;
    border-radius: 0.625rem;
    border: 1px solid #e7e7e7;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27);
  }
`;

export const TableDataDiv = styled.div`
  margin-top: 2rem;
  border: 1.064px solid ${({ theme }) => theme.cardBorder} !important;

  max-height: 500px;
  overflow-y: auto !important;

  .ant-table-thead > tr > th {
    color: #00b7ff !important;
  }

  .ant-table-thead > tr > th {
    background-color: ${({ theme }) => theme.cardBG} !important;
    border-bottom: 1.064px solid ${({ theme }) => theme.cardBorder} !important;
  }

  .ant-table {
    background-color: ${({ theme }) => theme.cardBG} !important;
    border: none !important;
  }

  .ant-table-tbody > tr > td {
    border-bottom: 1.064px solid ${({ theme }) => theme.cardBorder} !important;
  }

  tbody > tr:hover > td {
    background-color: ${({ theme }) => theme.cardBG} !important;
  }
`;

export const NoDataFoundComDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10rem;
  width: 100%;
`;

export const BackImgDiv = styled.div`
  position: relative;
  min-height: 530px;
`;

export const DropdownDiv = styled.div`
  position: relative;

  .form-control {
    border-radius: 10px;
    border: 1px solid ${({ theme }) => theme.selectBorder};
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27);
    margin-top: 1rem;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    padding-top: 10px;
    padding-bottom: 10px;
    color: ${({ theme }) => theme.cardText};
    position: relative;
    background-color: transparent;

    &::placeholder {
      color: #c9c9c9;
    }
  }

  .drops-dropdown {
    position: absolute;
    background-color: ${({ theme }) => theme.backgroundColor};
    top: 100px;
    border: 1px solid ${({ theme }) => theme.selectBorder};
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27);
    z-index: 200 !important;
    max-height: 200px;
    padding: 1rem 10px;
    width: 100%;
    overflow-y: auto !important;
    color: ${({ theme }) => theme.inputtext};
    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background-color: #fff;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background-color: rgb(193, 193, 193);
    }
  }

  .dropdown-arrow {
    color: ${({ theme }) => theme.backArrow};
    position: absolute;
    top: 55px;
    right: 10px;
  }

  .close {
    cursor: pointer;
  }

  .content-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;
    border-bottom: 1px solid ${({ theme }) => theme.selectBorder};
    padding-bottom: 0.5rem;
    cursor: pointer;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      background-color: ${({ theme }) =>
        theme.selectdropdownhoverColor} !important;
    }
  }

  .top-text {
    color: ${({ theme }) => theme.cardText};
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 100% */
    text-transform: capitalize;
  }

  .bottom-text {
    color: ${({ theme }) => theme.cardText};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px; /* 100% */
    text-transform: capitalize;
    margin-top: 5px;
  }
`;

export const FiltersSubHeader = styled.h2`
  color: ${({ theme }) => theme.filterSubTextColor};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  margin: 0;
`;

export const PacksDetailsWrapper = styled.div`
  overflow: hidden;
  background-color: ${({ theme }) => theme.backgroundColor};
  min-height: calc(100vh - 74px);

  .container-fluid {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .card-wrapper {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 0px;

    @media (max-width: 1399.98px) {
      grid-template-columns: repeat(5, 1fr);
    }

    @media (max-width: 1199.98px) {
      grid-template-columns: repeat(4, 1fr);
    }
    @media (max-width: 991.98px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 767.98px) {
      /* grid-template-columns: repeat(2, 1fr); */
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 670.98px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 455.98px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
`;
