import { MainContainer } from "../../components/common";
import {
  CreateNewCollectionWrapper,
  FormContainer,
  MainHeader,
  MainText,
  Required,
  CreateBtn,
} from "./element";
import Form from "react-bootstrap/Form";

function CreateNewCollectionCom() {
  return (
    <CreateNewCollectionWrapper>
      <MainContainer>
        <MainHeader>Create new Collection</MainHeader>
        <MainText>
          Create a new collection, upload you digital art and sell them.
        </MainText>
        <FormContainer>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label className="d-flex align-items-center">
                Email address <Required>*</Required>
              </Form.Label>
              <Form.Control type="text" placeholder="Enter Name" />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Artwork description</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Tell Us About Collection"
                className="textarea"
              />
            </Form.Group>
          </Form>
          <CreateBtn>Create</CreateBtn>
        </FormContainer>
      </MainContainer>
    </CreateNewCollectionWrapper>
  );
}

export default CreateNewCollectionCom;
