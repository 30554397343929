import styled from "styled-components";

export const CreatePackWrapper = styled.div`
  border-radius: 10px;
  padding: 1rem 2rem;
  margin-top: 2rem;
  padding-bottom: 2rem;

  @media (max-width: 991.98px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

export const SelectPackWrapper = styled.div`
  margin-top: 2rem;
`;

export const SelectPackBtn = styled.button`
  color: #fff;
  font-family: "Barlow", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  border-radius: 1.75rem;
  background: var(--pimary, #00b7ff);
  min-width: 133px;
  min-height: 35px;
  padding-left: 15px;
  padding-right: 15px;
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

export const MainHeader = styled.h2`
  color: #3e3e3e;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-transform: capitalize;
  margin-top: 2rem;
`;

export const FormContainer = styled.div`
  margin-top: 1rem;
  .form-control {
    border-radius: 10px;
    border: 1px solid #e7e7e7;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27);
    min-height: 47px;

    &:focus {
      outline: none;
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27);
    }
  }
`;

export const TextEditorWrapper = styled.div`
  margin-top: 2rem;
  position: relative;

  .ql-snow.ql-toolbar {
    display: block;
    /* background: #eaecec; */
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
  }

  .ql-bubble .ql-editor {
    border: 1px solid #e7e7e7;
    border-radius: 0.5em;
  }

  .ql-editor {
    min-height: 400px;
  }
  .ql-container {
    min-height: 400px;

    @media (max-width: 991.98px) {
      min-height: 250px;
    }
  }

  .ql-snow .ql-tooltip {
  }
`;

export const SubText = styled.p`
  color: #6b6b6b;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  margin-top: 1rem;

  &.reduced-margin {
    margin-top: 0;
  }
`;

export const TableDiv = styled.div`
  margin-top: 2rem;
`;

export const LeftDiv = styled.div``;

export const RightDiv = styled.div`
  position: relative;

  .table-close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
  }
  .slot-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.5rem;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .title {
    color: var(--pimary, #00b7ff);
    font-family: Barlow;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    text-transform: capitalize;
  }
`;

export const TableHeader = styled.h4`
  color: #3e3e3e;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-transform: capitalize;

  &.centered-text {
    text-align: center;
  }
`;

export const TableNumber = styled.p`
  color: #6b6b6b;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  margin: 0;
`;

export const TableText = styled(TableNumber)`
  &.centered-text {
    text-align: center;
  }

  input {
    border: none;

    &:focus {
      border: none;
      outline: none !important;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const TableContainer = styled.div`
  border-radius: 10px;
  border: 1px solid #e7e7e7;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27);
  padding: 1rem 2rem;
  margin-top: 1rem;
  @media (max-width: 991.98px) {
    margin-top: 2rem;
  }

  .border-bottom {
    border-bottom: 1px solid #e7e7e7;
    padding-bottom: 1rem;
  }

  .select-col {
    position: relative;
  }

  .close-icon {
    cursor: pointer;
    font-size: 24px;
    color: #c9c9c9;
    position: absolute;
    top: 12px;
    right: 50px;
    z-index: 20;
  }

  .odd-div {
    display: flex;
    gap: 1rem;
    justify-content: center;
    /* justify-content: space-between; */
    align-items: center;
    border-bottom: 1px solid #e7e7e7;
    padding-bottom: 10px;
  }

  .odd-divs {
    border-bottom: 1px solid #e7e7e7;
  }
`;

export const ActionsDiv = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  .icon {
    cursor: pointer;
    font-size: 24px;
  }
`;

export const AddBtn = styled.button`
  color: #fff;
  font-family: "Barlow", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  border-radius: 1.75rem;
  background: #00b7ff;
  width: 133px;
  height: 35px;
  // display: block;
  margin: 0rem 0;

  &:disabled {
    background: ${({ theme }) => theme.disabledBtnBG};
    border: 1px solid ${({ theme }) => theme.disabledBtnBorder} !important;
    color: rgb(183, 189, 202);
  }
`;

export const DateDiv = styled.div`
  margin-top: 2rem;
  border-radius: 10px;
  border: 1px solid #e7e7e7;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27);
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  @media (max-width: 991.98px) {
    padding-left: 1rem;
    padding-right: 1rem;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
  }
`;

export const DateText = styled(TableText)``;

export const UnpackSettingDiv = styled.div`
  margin-top: 2rem;
`;

export const UnpackCollapseDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e7e7e7;
  padding-bottom: 10px;
  cursor: pointer;

  p {
    color: #6b6b6b;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    margin: 0;
  }
`;

export const ShadowedDiv = styled.div`
  border-radius: 10px;
  border: 1px solid #e7e7e7;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27);
  padding: 1rem 2rem;
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: capitalize;

  &.cursor-div {
    cursor: pointer;
  }

  @media (max-width: 991.98px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  p {
    color: #6b6b6b;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    margin: 0;
  }

  .icon {
    font-size: 24px;
    cursor: pointer;
    color: #6b6b6b;
  }

  .color-picker-div {
    width: 20px;
    height: 20px;
    background-color: #e7e7e7;
    cursor: pointer;
    border: none;
  }
`;

export const PreviewBtn = styled(AddBtn)`
  width: 100px;
  margin-top: 1.7rem;
  background: var(--pimary, #00b7ff) !important;

  @media (max-width: 1466.98px) {
    width: 80px;
  }

  @media (max-width: 1266.98px) {
    width: 70px;
  }

  @media (max-width: 991.98px) {
    margin-top: 1rem;
  }
`;

export const BtnsDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 2rem;
  gap: 2rem;

  @media (max-width: 991.98px) {
    justify-content: center;
  }
`;

export const CancelBtn = styled.button`
  border: none;
  background-color: transparent;
  color: #3e3e3e;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
`;

export const CreateBtn = styled.button`
  color: #fff;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  border-radius: 1.75rem;
  background: var(--pimary, #00b7ff);
  width: 9.9375rem;
  height: 2.1875rem;

  &:disabled {
    background: ${({ theme }) => theme.disabledBtnBG};
    border: 1px solid ${({ theme }) => theme.disabledBtnBorder} !important;
    color: rgb(183, 189, 202);
  }
`;

export const ProbabilityDiv = styled.div`
  border-radius: 10px;
  border: 1px solid #e7e7e7;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27);
  padding: 1rem;
  margin-top: 1rem;

  h2 {
    color: #3e3e3e;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */
    text-transform: capitalize;
    text-align: center;
  }

  p {
    text-align: center;
    color: #6b6b6b;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
  }
`;

export const AddTemplateBtn = styled.button`
  width: 135px;
  height: 35px;
  border-radius: 21.398px;
  border: 1px solid #00b7ff;
  background-color: transparent;
  color: #00b7ff;
  font-size: 14.228px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  margin-top: 1rem;
`;

export const AddSlotsBtn = styled(AddTemplateBtn)`
  background-color: #00b7ff;
  color: white;
  display: block;
  margin-left: auto;
  margin-top: 2rem;
`;

export const MainColsWrapper = styled.div``;
