import { GetAdminHook } from "../../hooks";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import UpdatePassword from "./updatePassword";
import UpdateUsername from "./updateUsername";

const UpdateCredentials = () => {
  const { data, loading, error } = GetAdminHook();
  const navigate = useNavigate();

  useEffect(() => {
    error && error.message === "invalid token" && navigate("/admin-login");
  }, [error]);

  return (
    <div style={{ width: "50%", margin: "auto" }}>
      {loading ? (
        "loading..."
      ) : (
        <>
          <UpdatePassword />
          <UpdateUsername />
        </>
      )}
    </div>
  );
};

export default UpdateCredentials;
