import { APIPath } from "utility/constant";
import { BaseService } from "./baseServices";

class SignatureData {
  getSignature = (obj) => {
    return BaseService.post(APIPath.getSignature, obj);
  };
}

const SignatureService = new SignatureData();
Object.freeze(SignatureService);
export { SignatureService };
