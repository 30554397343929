import firebase from "firebase/compat/app";
import "firebase/compat/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAcFNRvlao38Y1FAUdGT-1G4g6TQLsQr24",
  authDomain: "starship-nfts-production.firebaseapp.com",
  projectId: "starship-nfts-production",
  storageBucket: "starship-nfts-production.appspot.com",
  messagingSenderId: "489457104845",
  appId: "1:489457104845:web:aeede45695b21ab9777684",
  measurementId: "G-JGSRLTB1M2"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const storage = firebase.storage();
