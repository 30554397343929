import { FormContainer, MainHeader, SelectPackWrapper } from "./element";
import { Form } from "react-bootstrap";
import Editor from "./textEditor";

function SelectPackCom({
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  quillRef,
  description,
  setDescription,
  schemas,
}) {
  return (
    <SelectPackWrapper>
      <span style={{ color: "#F65D5D", marginTop: "1rem" }}>
        {touched.schema_id && errors.schema_id}
      </span>
      <MainHeader>Display Data</MainHeader>
      <FormContainer>
        <Form.Label>Name</Form.Label>
        <Form.Control
          placeholder="Pack name"
          name="name"
          value={values?.name}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <span style={{ color: "#F65D5D", marginTop: "1rem" }}>
          {touched.name && errors.name}
        </span>
      </FormContainer>
      <Editor
        description={description}
        setDescription={setDescription}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
        quillRef={quillRef}
      />
      <span style={{ color: "#F65D5D", marginTop: "1rem" }}>
        {touched.description && errors.description}
      </span>
    </SelectPackWrapper>
  );
}

export default SelectPackCom;
