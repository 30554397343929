import styled from "styled-components";

import { AiOutlineAreaChart, AiOutlineUser } from "react-icons/ai";
import { IoIosNotifications } from "react-icons/io";

export const ProfileTitle = styled.h3`
  margin-left: 7rem;

  @media (max-width: 991px) {
    margin-left: 0rem;
    text-align: center;
  }
  @media (min-width: 1660px) {
    margin-left: 9.6rem;
  }
`;

export const ProfileIcon = styled(AiOutlineUser)`
  margin-right: 0.8rem;
  margin-bottom: 0.3rem;
  font-size: 1.2rem;
`;

export const NotificationIcon = styled(IoIosNotifications)`
  margin-right: 0.8rem;
  margin-bottom: 0.3rem;
  font-size: 1.2rem;
`;

export const EarningIcon = styled(AiOutlineAreaChart)`
  margin-right: 0.8rem;
  margin-bottom: 0.3rem;
  font-size: 1.2rem;
`;

export const ProfileWrapper = styled.div`
  .connectWallet {
    margin-left: -11rem;
    margin-top: 3rem;
  }
`;

export const ConnectWalletBtn = styled.button`
  background: linear-gradient(98.6deg, #261ce8 6.03%, #511ce8 99.87%);
  border-radius: 4px;
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 100%;
  color: #fff;
  padding: 10px 25px;
`;
