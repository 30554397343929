import { useEffect, useMemo, useState } from "react";
import { MainRow, MainCol, MainContainer } from "components/common";
import { Select, Tabs } from "antd";
import { GetCollectionsHook, GetSellNftsHook } from "hooks";
import {
  FilterRow,
  SearchInput,
  ToggleButton,
  PriceSelect,
  PriceSelectOption,
  CardMainFlex,
} from "components/collectionAll/styles";
import { RiFilterLine, RiFilterOffLine } from "react-icons/ri";
import { SearchOutlined } from "@ant-design/icons";
import profile from "../../assets/profileImg.jpg";
import {
  MinMaxMain,
  AllNftWrapper,
  PanelDiv,
  CollectionWrapper,
  SelectWrappper,
} from "./styled";
import { Panel } from "rsuite";
import { Input } from "antd";
import { AiOutlineSearch } from "react-icons/ai";
import { FaEthereum } from "react-icons/fa";
import ExploreCard from "./exploreCard";
const AllNftsCom = () => {
  const [filter, setFilter] = useState(false);
  //hooks
  const {
    data: collections,
    loading,
    error,
    getCollections,
  } = GetCollectionsHook();
  useEffect(() => {
    getCollections("", 10);
  }, []);
  const { sellNft } = GetSellNftsHook();
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedTrait, setSelectedTrait] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);
  const handleClick = (index) => {
    setSelectedItem(index);
  };
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };
  const getUniqueTraits = (objects) => {
    const uniqueTraitTypes = new Set();
    const uniqueValues = new Set();
    for (let i = 0; i < objects.length; i++) {
      const attributes = JSON.parse(objects[i].attributes);
      for (let j = 0; j < attributes.length; j++) {
        const traitType = attributes[j].trait_type;
        const value = attributes[j].value;
        uniqueTraitTypes.add(traitType);
        uniqueValues.add(value);
      }
    }
    return {
      traitTypes: Array.from(uniqueTraitTypes),
      values: Array.from(uniqueValues),
    };
  };
  const { traitTypes, values } = useMemo(() => {
    if (sellNft?.data) {
      return getUniqueTraits(sellNft?.data);
    }
    return { traitTypes: [], values: [] };
  }, [sellNft]);

  const sellNftData = useMemo(() => {
    if (sellNft?.data) {
      let filteredData = sellNft?.data?.filter((item) => {
        const attributes = JSON.parse(item.attributes);
        let res = attributes.find(
          (attribute) => attribute.trait_type === selectedTrait
        );
        return res;
      });
      filteredData = filteredData?.filter((item) => {
        const attributes = JSON.parse(item.attributes);
        let res = attributes.find(
          (attribute) => attribute.value === selectedValue
        );
        return res;
      });
      return filteredData;
    }
  }, [sellNft, selectedTrait, selectedValue]);
  return (
    <AllNftWrapper style={{ overflowX: "hidden", marginTop: "2rem" }}>
      <MainContainer className="collectionContainer">
        <h2 className="mainHeader">Explore</h2>
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="Items" key="1">
            <MainRow>
              <FilterRow>
                <div className="divTab ">
                  <div
                    className="filterWrapper"
                    onClick={() => setFilter(!filter)}
                  >
                    {filter ? (
                      <RiFilterOffLine className="filterIcon" size={18} />
                    ) : (
                      <RiFilterLine className="filterIcon" size={18} />
                    )}

                    <h2 className="filter">Filter</h2>
                  </div>
                </div>
                <div className="searchMain">
                  <div className="searchDiv">
                    <SearchInput
                      prefix={
                        <SearchOutlined
                          style={{
                            fontSize: "16px",
                            paddingLeft: "1rem",
                            color: " #7F8C8D",
                          }}
                        />
                      }
                      placeholder="Name, token, ID "
                    />
                  </div>
                  <div className="selectDiv">
                    <PriceSelect defaultValue="Recent Activity" size="large">
                      <PriceSelectOption value="Recent Activity">
                        Recent Activity
                      </PriceSelectOption>
                      <PriceSelectOption value="Price High To Low">
                        Price High To Low
                      </PriceSelectOption>
                      <PriceSelectOption value="Price  Low To High ">
                        Price Low To High
                      </PriceSelectOption>
                    </PriceSelect>
                  </div>
                </div>
              </FilterRow>

              <MainCol lg={9} sm={7}></MainCol>
            </MainRow>

            <MainRow className="filterRow">
              <MainCol
                className={
                  filter
                    ? `col-lg-3 col-xs-12 sidebarCol `
                    : " d-none  hideScreen"
                }
              >
                <div className="filterDiv mt-5">
                  <div className="flex justify-content-between filterFooter  ">
                    <p className="buyTxt">Buy Now</p>
                    <ToggleButton type="switch" id="mySwitch" disabled={true} />
                  </div>
                  <p className="priceRange mt-3">Price Marge </p>
                  <MinMaxMain>
                    <div className="minMaxMain mt-4">
                      <input type="text" placeholder="MIN" />
                      <input type="text" placeholder="MAX " />
                    </div>
                  </MinMaxMain>
                </div>
                <PanelDiv>
                  <Panel header="Collections" collapsible bordered={false}>
                    <Input
                      size="large"
                      placeholder="Search"
                      prefix={<AiOutlineSearch />}
                    />
                    <div
                      style={{
                        height: "200px",
                        overflowY: "scroll",
                        paddingRight: "10px",
                      }}
                    >
                      {collections?.map((item, index) => (
                        <CollectionWrapper
                          key={index}
                          onClick={() => handleClick(index)}
                          className={selectedItem === index ? "active" : ""}
                        >
                          <div className="imgWrapper">
                            <img className="img-fluid" src={profile} />
                          </div>
                          <div className="textWrapper">
                            <div className="upperText">
                              <p className="collection">{item?.name}</p>
                              <p className="price">
                                <FaEthereum
                                  style={{
                                    marginBottom: "3px",
                                    marginRight: "3px",
                                  }}
                                />
                                {item.totalVolume}
                              </p>
                            </div>
                            <div className="lowerText">
                              <p className="floorPrice">
                                <FaEthereum
                                  style={{
                                    marginBottom: "3px",
                                    marginRight: "3px",
                                  }}
                                />
                                {item.floorprice}
                              </p>
                              <p className="time">24h</p>
                            </div>
                          </div>
                        </CollectionWrapper>
                      ))}
                    </div>

                    {selectedItem !== null && (
                      <div className="mt-3">
                        <h5>Type</h5>
                        {selectedItem === 0 && (
                          <>
                            <SelectWrappper>
                              <Select
                                defaultValue="rarity"
                                style={{ width: "100%" }}
                                onChange={(e) => setSelectedTrait(e)}
                                virtual
                                options={traitTypes.map((item) => ({
                                  value: item,
                                  label: item,
                                }))}
                              />
                            </SelectWrappper>

                            <SelectWrappper>
                              <Select
                                defaultValue="variant"
                                style={{ width: "100%" }}
                                onChange={(e) => setSelectedValue(e)}
                                options={values.map((item) => ({
                                  value: item,
                                  label: item,
                                }))}
                              />
                            </SelectWrappper>
                          </>
                        )}

                        {selectedItem === 1 && (
                          <>
                            <SelectWrappper>
                              <Select
                                defaultValue="rarity"
                                style={{ width: "100%" }}
                                onChange={handleChange}
                                virtual
                                options={[
                                  { value: "rarity", label: "rarity" },
                                  { value: "1 of 1", label: "1 of 1" },
                                  { value: "Common", label: "Common" },
                                ]}
                              />
                            </SelectWrappper>

                            <SelectWrappper>
                              <Select
                                defaultValue="variant"
                                style={{ width: "100%" }}
                                onChange={handleChange}
                                options={[
                                  { value: "variant", label: "variant" },
                                  { value: "Animated", label: "Animated" },
                                  { value: "Bronze", label: "Bronze" },
                                ]}
                              />
                            </SelectWrappper>
                          </>
                        )}

                        {selectedItem === 2 && (
                          <>
                            <SelectWrappper>
                              <Select
                                defaultValue="rarity"
                                style={{ width: "100%" }}
                                onChange={handleChange}
                                virtual
                                options={[
                                  { value: "rarity", label: "rarity" },
                                  { value: "1 of 1", label: "1 of 1" },
                                  { value: "Common", label: "Common" },
                                ]}
                              />
                            </SelectWrappper>

                            <SelectWrappper>
                              <Select
                                defaultValue="variant"
                                style={{ width: "100%" }}
                                onChange={handleChange}
                                options={[
                                  { value: "variant", label: "variant" },
                                  { value: "Animated", label: "Animated" },
                                  { value: "Bronze", label: "Bronze" },
                                ]}
                              />
                            </SelectWrappper>
                          </>
                        )}
                      </div>
                    )}
                  </Panel>
                </PanelDiv>
              </MainCol>
              <MainCol
                className={filter ? "col-lg-9 col-xs-12 rightCol" : "col-lg-12"}
              >
                <CardMainFlex className="cardMainFlex mt-5 mb-5">
                  {(sellNftData && sellNftData.length > 0
                    ? sellNftData
                    : sellNft?.data
                  )?.map((item) => (
                    <div className={filter ? "cardItem" : "cardItem2"}>
                      <ExploreCard data={item} />
                    </div>
                  ))}
                </CardMainFlex>
              </MainCol>
            </MainRow>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Activity" disabled key="2">
            Content of Tab Pane 2
          </Tabs.TabPane>
        </Tabs>
      </MainContainer>
    </AllNftWrapper>
  );
};

export default AllNftsCom;
