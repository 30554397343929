import { useEffect, useRef, useState } from "react";
import {
  BuyNowBtn,
  ExploreCardWrapper,
  Key,
  TopCardDiv,
  Value,
} from "./element";
import Card from "react-bootstrap/Card";
import {
  AiFillHeart,
  AiOutlineHeart,
  AiOutlineInfoCircle,
} from "react-icons/ai";
import { Image } from "react-bootstrap";
import { GetFavoritesHook, UpdateFavoriteHook } from "hooks/favoriteHook";
import { useAppSelector } from "store/store";
import { CommonUtility, ContractUtility, ERC20Service, Rarity } from "utility";
import { Modal } from "antd";
import { ToastMessage } from "components/common";
import { erc20Abi } from "contract/erc20";
import { BsThreeDots } from "react-icons/bs";
import { MakeOfferModal, ViewOffersModal } from "components/modal";
import { useLocation } from "react-router-dom";
import { Dropdown, Menu } from "antd";
import { Tooltip } from "antd";
import ConnectModals from "components/modal/connectModal";
import { GetTokenPriceInUsd } from "utility/usdPrice";

function ExploreCard({
  item,
  page,
  filter,
  getMints,
  getNftFavorites,
  setLoading,
  refresh,
}) {
  const { web3, account, chainId, swapSdk } = useAppSelector(
    (state) => state.web3Connect
  );
  const { config } = useAppSelector((state) => state.configData);

  const [userOffer, setUserOffer] = useState("");
  const [usdPrice, setUsdPrice] = useState(Number);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOffersModalOpen, setIsOffersModalOpen] = useState(false);
  const [isMakeOfferModalOpen, setIsMakeOfferOpen] = useState(false);
  const [cardHovered, setCardHovered] = useState(false);
  const [isCardContentVisible, setIsCardContentVisible] = useState(false);
  const { data: favorite, getFavorites } = GetFavoritesHook(account);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const showOffersModal = () => {
    setIsOffersModalOpen(true);
  };

  const showMakeOffersModal = () => {
    setIsMakeOfferOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setIsMakeOfferOpen(false);
    setIsOffersModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setIsMakeOfferOpen(false);
    setIsOffersModalOpen(false);
    setUserOffer("");
  };

  const { data, update, loading: upating } = UpdateFavoriteHook();
  useEffect(() => {
    if (data && account) {
      getFavorites(account);
      getNftFavorites && getNftFavorites(account);
    }
  }, [data]);
  useEffect(() => {
    const fetchData = async () => {
      const price = await GetTokenPriceInUsd(config, chainId);
      setUsdPrice(+price);
    };

    fetchData();
  }, [config, chainId]);
  const fillOrder = async () => {
    setLoading(true);

    const erc20Contract = CommonUtility.contract(
      web3,
      erc20Abi,
      item?.signed_order?.erc20Token
    );
    const balance = await erc20Contract.methods.balanceOf(account).call();
    if (+item?.signed_order.erc20TokenAmount > +balance) {
      ToastMessage(
        "Insufficient Balance",
        "You have indufficient balance to proceed this transaction",
        "warning"
      );
      setLoading(false);
    } else {
      const TAKER_ASSET = {
        tokenAddress: item?.signed_order.erc20Token,
        amount: item?.signed_order.erc20TokenAmount,
        type: "ERC20",
      };
      const assetsToSwapTaker = [TAKER_ASSET];

      try {
        let nftSwapSdk = swapSdk;
        const signedOrder = item?.signed_order;
        // Approving the taker asset to swapSdk`
        const approvalStatusForUserB = await nftSwapSdk.loadApprovalStatus(
          assetsToSwapTaker[0],
          account
        );

        // If we do need to approve makers assets for swapping, do that
        if (!approvalStatusForUserB.contractApproved) {
          const approvalTx = await nftSwapSdk.approveTokenOrNftByAsset(
            assetsToSwapTaker[0],
            account
          );
          await approvalTx.wait();
        }
        const feesSum = item?.signed_order.fees.reduce(
          (total, fee) => total + parseFloat(fee.amount),
          0
        );
        const totalAmount =
          parseFloat(item?.signed_order.erc20TokenAmount) + feesSum;
        const totalAmountString = totalAmount.toString();

        const allowance = await ERC20Service.allowance(
          web3,
          item?.signed_order.erc20Token,
          account,
          nftSwapSdk?.exchangeProxyContractAddress
        );
        if (+allowance < +totalAmountString) throw "Insufficient Allowance";
        // Filling the order (Pass the signed order)
        const fillTx = await nftSwapSdk.fillSignedOrder(
          signedOrder,
          undefined,
          {
            gasLimit: "600000",
          }
        );

        // Wait for the transaction receipt
        const fillTxReceipt = await nftSwapSdk.awaitTransactionHash(
          fillTx.hash
        );
        if (fillTxReceipt?.status) {
          page && filter ? await getMints(page, filter) : await getMints();

          setLoading(false);
          ToastMessage("Done", "NFT Bought Successfully", "success");
        } else {
          setLoading(false);
          ToastMessage("error", "Transaction Failed", "error");
        }
      } catch (error) {
        setLoading(false);
        console.log("error", error);
        ToastMessage("Error", "Transaction Failed", "error");
      }
    }
  };

  const location = useLocation();
  const findObjectWithLowestAmount = (listings) => {
    let lowestObject = null;

    for (const listing of listings) {
      if (
        !lowestObject ||
        parseFloat(listing.amount) < parseFloat(lowestObject.amount)
      ) {
        lowestObject = listing;
      }
    }

    return lowestObject;
  };

  const menu = (
    <Menu
      className="explore-menu"
      items={[
        {
          key: "1",
          label: (
            <p
              className=" text-align-center explore-text"
              onClick={showOffersModal}
            >
              View Offers
            </p>
          ),
        },
      ]}
    />
  );

  const cardContentRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      isCardContentVisible &&
      cardContentRef.current &&
      !cardContentRef.current.contains(event.target)
    ) {
      setIsCardContentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isCardContentVisible]);

  function copyToClipboard(text) {
    navigator.clipboard.writeText(`${text}`).then(
      function () {
        console.log("Text copied to clipboard");
        ToastMessage("Success!", "Text copied to clipboard", "success");
      },
      function (err) {
        console.error("Could not copy text: ", err);
      }
    );
  }

  return (
    <ExploreCardWrapper
      className={`${
        location.pathname === "/starship/explore" && "reduce-margin"
      } ${location.pathname === "/" && "no-margin"}`}
    >
      {" "}
      <Modal open={isModalOpen} centered onCancel={handleCancel} width={504}>
        <ConnectModals onCancel={handleCancel} />
      </Modal>
      <Modal
        open={isMakeOfferModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        width={"461px"}
        closable={true}
        className="offer-modal"
      >
        <MakeOfferModal
          item={item}
          onCancel={handleCancel}
          page={page}
          filter={filter}
          getMints={getMints}
          refresh={Math.random()}
          userOffer={userOffer}
          setUserOffer={setUserOffer}
        />
      </Modal>
      <Modal
        open={isOffersModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        width={958}
        closable={true}
        className="offer-modal"
      >
        <ViewOffersModal
          item={item}
          page={page}
          filter={filter}
          getMints={getMints}
          refresh={refresh}
          closeModal={handleCancel}
          usdPrice={usdPrice}
        />
      </Modal>
      <Card
        onMouseEnter={() => setCardHovered(true)}
        onMouseLeave={() => setCardHovered(false)}
        className={`
        ${location.pathname === "/" && "increased-padding"}`}
      >
        <TopCardDiv>
          {account ? (
            favorite?.nft_ids.find((x) => x === item?._id) ? (
              <AiFillHeart
                className="icon liked"
                onClick={() => {
                  if (!upating) {
                    let acc = favorite?.nft_ids.filter((x) => x !== item?._id);
                    favorite.nft_ids = acc;
                    update({
                      user_address: account,
                      status: false,
                      nft_id: item?._id,
                    });
                  }
                }}
              />
            ) : (
              <AiOutlineHeart
                className="icon"
                onClick={() => {
                  if (!upating) {
                    favorite?.nft_ids.push(item?._id);
                    update({
                      user_address: account,
                      status: true,
                      nft_id: item?._id,
                    });
                  }
                }}
              />
            )
          ) : (
            <AiOutlineHeart
              onClick={showModal}
              cursor="pointer"
              className="icon"
            />
          )}
          <AiOutlineInfoCircle
            className="icon"
            onClick={() => setIsCardContentVisible(!isCardContentVisible)}
          />
        </TopCardDiv>

        {isCardContentVisible ? (
          <div className="content-main-dev" ref={cardContentRef}>
            <div className="content-wrapper">
              <Key>Title:</Key>
              <Value>
                {
                  item?.attributes?.find(
                    (obj) => obj?.attribute_type?.toLowerCase() === "name"
                  )?.attribute_value
                }
              </Value>
            </div>
            {item.attributes &&
              item.attributes.some(
                (obj) =>
                  obj.attribute_type?.toLowerCase() === Rarity?.toLowerCase()
              ) && (
                <div className="content-wrapper">
                  <Key>Rarity:</Key>
                  <Value>
                    {" "}
                    {item?.attributes?.find(
                      (obj) =>
                        obj?.attribute_type?.toLowerCase() ===
                        Rarity?.toLowerCase()
                    )?.attribute_value || "-"}
                  </Value>
                </div>
              )}
            <div className="content-wrapper">
              <Key>Collection:</Key>
              <Value>{item?.schema_name}</Value>
            </div>
            <div className="content-wrapper">
              <Key>Template ID:</Key>
              <Value>{item?.template_id?.nft_id}</Value>
            </div>
            <div className="content-wrapper">
              <Key>Is burnable:</Key>
              <Value>{item?.is_burnable ? "Yes" : "No"}</Value>
            </div>
            <div className="content-wrapper">
              <Key>Is transferable:</Key>
              <Value>{item?.is_transferable ? "Yes" : "No"}</Value>
            </div>
            <div className="content-wrapper">
              <Key>Issued:</Key>
              <Value>
                {item?.template_id?.current_supply} /{" "}
                {+item?.template_id?.max_supply == 0 ||
                +item?.template_id?.max_supply == +config?.UINT256_MAX
                  ? "∞"
                  : item?.template_id?.max_supply}
              </Value>
            </div>
            <div className="content-wrapper">
              <Key>Price:</Key>
              <Value className="blue">
                {item?.signed_order
                  ? CommonUtility.calculateTotalFeesAndAmount(
                      item?.signed_order,
                      ContractUtility.getPaymentToken(
                        item?.signed_order?.erc20Token,
                        chainId || config?.DEFAULT_CHAIN_ID
                      )?.decimals
                    ) +
                    ContractUtility.getPaymentToken(
                      item?.signed_order?.erc20Token,
                      chainId || config?.DEFAULT_CHAIN_ID
                    )?.symbol
                  : "0"}
              </Value>
            </div>
            <div className="content-wrapper">
              <Key>Last sold price:</Key>
              <Value>
                {item?.price ? (
                  <>
                    {CommonUtility.convertFromWei(
                      item?.price.price,
                      ContractUtility.getPaymentToken(
                        item?.price.payment_token,
                        chainId || config?.DEFAULT_CHAIN_ID
                      )?.decimals,
                      false
                    )}{" "}
                    {
                      ContractUtility.getPaymentToken(
                        item?.price.payment_token,
                        chainId || config?.DEFAULT_CHAIN_ID
                      )?.symbol
                    }
                  </>
                ) : (
                  "---"
                )}
              </Value>
            </div>
            <div className="content-wrapper">
              <Key>Lowest listing price:</Key>
              {item?.listings?.length ? (
                <Value>
                  {`${CommonUtility.convertFromWei(
                    findObjectWithLowestAmount(item?.listings).amount,
                    ContractUtility.getPaymentToken(
                      findObjectWithLowestAmount(item?.listings).token,
                      chainId || config?.DEFAULT_CHAIN_ID
                    )?.decimals,
                    false
                  )} ${
                    ContractUtility.getPaymentToken(
                      findObjectWithLowestAmount(item?.listings).token,
                      config?.DEFAULT_CHAIN_ID
                    )?.symbol
                  } `}
                </Value>
              ) : (
                <Value>---</Value>
              )}
            </div>
          </div>
        ) : (
          <>
            <Image fluid src={item?.image} />
            <h2 className="title">
              {
                item?.attributes?.find(
                  (obj) => obj?.attribute_type?.toLowerCase() === "name"
                )?.attribute_value
              }
            </h2>
            <p className="sub-text">{item?.schema_name}</p>
            <p
              className="sub-text nft-id cursor-pointer"
              title="NFT ID"
              onClick={() => copyToClipboard(item?.nft_id)}
            >
              #{CommonUtility.nftIdConvertor(item?.nft_id)}
            </p>
            {item?.signed_order ? (
              <h4 className="price">
                {CommonUtility.calculateTotalFeesAndAmount(
                  item?.signed_order,
                  ContractUtility.getPaymentToken(
                    item?.signed_order.erc20Token,
                    chainId || config?.DEFAULT_CHAIN_ID
                  )?.decimals
                )}{" "}
                {
                  ContractUtility.getPaymentToken(
                    item.signed_order?.erc20Token,
                    chainId || config?.DEFAULT_CHAIN_ID
                  )?.symbol
                }
              </h4>
            ) : (
              <h4 className="price" style={{ visibility: "hidden" }}>
                0.001 ETH <span> ($5)</span>
              </h4>
            )}
          </>
        )}

        <BuyNowBtn
          disabled={
            !item?.signed_order ||
            !account ||
            account.toLowerCase() === item?.wallet_address
          }
          onClick={() => fillOrder()}
        >
          Buy Now
        </BuyNowBtn>

        <Tooltip
          title={
            account && account.toLowerCase() === item?.wallet_address
              ? "You are the owner of this NFT!"
              : ""
          }
        >
          <span>
            <BuyNowBtn
              onClick={() => {
                if (web3) {
                  showMakeOffersModal();
                } else {
                  showModal();
                }
              }}
              disabled={
                account && account.toLowerCase() === item?.wallet_address
              }
            >
              Make Offer
            </BuyNowBtn>
          </span>
        </Tooltip>

        <Dropdown
          overlay={menu}
          trigger={["click"]}
          placement="bottomRight"
          overlayClassName="explore-dropdown"
        >
          <a onClick={(e) => e.preventDefault()}>
            <BsThreeDots
              className={`three-dots ${cardHovered && "dots-hovered"}`}
            />
          </a>
        </Dropdown>
      </Card>
    </ExploreCardWrapper>
  );
}

export default ExploreCard;
