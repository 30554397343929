import styled from "styled-components";
import detailsbg from "../../assets/details-bg.png";

export const CreateNewCollectionWrapper = styled.div`
  overflow: hidden;
  background-image: url(${detailsbg});
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const MainHeader = styled.h2`
  font-family: "Anton";
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  line-height: 100%;
  text-transform: uppercase;
  color: #3e3e3e;
  margin: 3rem 0rem 1rem 0;

  @media (max-width: 575.98px) {
    text-align: center;
  }
`;

export const MainText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 100%;
  letter-spacing: -0.05em;
  color: #292929;
  margin-top: 2.5rem;

  @media (max-width: 767.98px) {
    margin-top: 2rem;
    line-height: 130%;
  }
`;

export const FormContainer = styled.div`
  margin-top: 3rem;

  @media (max-width: 575.98px) {
    margin-top: 1rem;
  }

  .form-label {
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 24px;
    letter-spacing: 0.01px;
    color: #000000;
    margin-top: 10px;
  }

  .form-control {
    border-radius: 4px 4px 0px 0px;
    width: 792px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #261ce8;
    box-shadow: none;
    height: 48px;

    @media (max-width: 991.98px) {
      width: 100%;
    }

    &::placeholder {
      color: rgba(0, 0, 0, 0.5);
    }
  }

  .textarea {
    min-height: 120px;
    margin-top: 15px;
  }
`;

export const Required = styled.h2`
  color: red;
  font-size: 18px;
  margin-left: 10px;
`;

export const CreateBtn = styled.button`
  width: 121px;
  height: 52px;
  background: linear-gradient(98.6deg, #261ce8 6.03%, #511ce8 99.87%);
  border-radius: 4px;
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 100%;
  letter-spacing: -0.05em;
  color: #ffffff;
  margin: 1rem 0 3rem 0;

  @media (max-width: 575.98px) {
    display: block;
    margin: 2rem auto 2rem auto;
  }
`;
