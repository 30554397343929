import { FavoriteService } from "utility/services/favoriteService";
import { CommonHook } from "./commonHook";
import { useEffect, useState } from "react";
import { CommonUtility } from "utility";

export const UpdateFavoriteHook = () => {
  const { setError, setData, data, loading, setLoading, error } = CommonHook();

  const update = async (obj) => {
    try {
      setLoading(true);
      const jsonData = JSON.stringify(obj);
      let encrypted = CommonUtility.encrypt(jsonData);
      const result = await FavoriteService.updateFavorite(encrypted);
      if (result.data) {
        setData(result.data);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    update,
    loading,
    data,
    setData,
  };
};

export const GetFavoritesHook = (account) => {
  const { setError, setData, data, loading, setLoading, error } = CommonHook();

  const getFavorites = async (account) => {
    try {
      setLoading(true);
      const result = await FavoriteService.getUserFavoriteNfts(account);

      if (result.data) {
        let temp = CommonUtility.decrypt(result.data);
        temp = JSON.parse(temp);
        setData(temp);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (account) getFavorites(account);
  }, [account]);

  return {
    getFavorites,
    loading,
    data,
    setData,
  };
};

export const GetNftFavoritesHook = (account, page) => {
  const { setError, setData, data, loading, setLoading, error } = CommonHook();
  const [total, setTotal] = useState(0);
  const getNftFavorites = async (account) => {
    try {
      setLoading(true);
      const result = await FavoriteService.getNftFavorites(account, page);

      if (result.data) {
        let temp = CommonUtility.decrypt(result.data);
        temp = JSON.parse(temp);
        setData(temp);
        setTotal(result.total);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (account) getNftFavorites(account);
  }, [account, page]);

  return {
    getNftFavorites,
    loading,
    data,
    setData,
    total,
  };
};
