import styled from "styled-components";
import { Card, Form, Accordion } from "react-bootstrap";
import { Tabs, Input, Select } from "antd";
import headerbg from "../../assets/circle.jpg";
import detailsbg from "../../assets/details-bg.png";

export const MainCollectionDetails = styled.div`
  overflow: hidden;
  background-image: url(${detailsbg});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  .container {
    margin-top: 2rem;
    margin-bottom: 2rem;

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      font-family: "Figtree";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #1c1b1f;
      text-align: center;
      width: 150px;
    }

    .ant-tabs-tab {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 150px;
    }

    .ant-tabs-ink-bar {
      background: #261ce8;
    }
  }

  .filterFooter {
    border-bottom: 1px solid #e7e0ec;
    display: flex;
    justify-content: space-between;

    .buyTxt {
      font-family: "Figtree";
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: #261ce8;
      padding-bottom: 20px;
    }
  }
  .filterDiv {
    .priceRange {
      font-family: "Barlow";
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      color: #1a1a1a;
    }

    .minMaxMain {
      display: flex;
      flex-direction: column;
      gap: 0.7rem;
      flex: 1;

      .form-select {
        border-radius: 10px;
        border: 1px solid #7f8c8d;
        box-shadow: none;
      }
    }

    .inputWrapper {
      display: flex;
      justify-content: center;
      gap: 0.5rem;

      input {
        height: 32px;
        width: 48%;
        border-radius: 10px;
        border: 1px solid #7f8c8d;
        padding: 0.5rem;
        ::placeholder {
          padding-left: 1rem !important;
        }
      }
    }

    .rarityRank {
      padding: 0px 0px 10px 0px;
      border-bottom: 1px solid #e7e0ec;
    }
    .panelDiv {
      padding: 0px 0px 10px 0px;
    }

    .filterInput {
      height: 32px;
      border-radius: 10px;
      margin-top: 2rem;
      border: 1px solid #7f8c8d;
      width: 100%;
      padding: 0.5rem;
      ::placeholder {
        padding-left: 1rem !important;
      }
    }

    .priceRange {
      font-family: "Figtree";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #1c1b1f;
    }
  }
`;

export const MainCollectionCard = styled(Card)`
  width: 285px;
  padding: 0;
  border: none;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1))
    drop-shadow(-4px -4px 10px rgba(0, 0, 0, 0.1));
  overflow: hidden;
  border-radius: 9px;
  min-height: 475px;

  &:hover .buyBtn {
    visibility: visible;
  }

  .header {
    display: flex;
    justify-content: flex-end;
    margin: 2px 10px 8px 10px;
    -ms-transform: translateY(5px);
    -webkit-transform: translateY(5px);
    -moz-transform: translateY(5px);
    -o-transform: translateY(5px);

    z-index: 1;
    overflow: hidden !important;

    P {
      font-family: "Barlow";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #000000;
      text-transform: capitalize;
    }
  }

  .iconDiv {
    background-color: transparent;
    position: absolute;
    z-index: 999;
    left: 90%;
    top: 1%;
  }

  .nftId {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #000000;
    padding: 0px 10px;
    margin-top: 5px;
  }

  .amountWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;

    .img-fluid {
      height: 24px;
    }

    p {
      margin: 0;
      font-family: "Figtree";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 29px;
      color: #261ce8;
    }
  }

  .cardInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0px 10px 0px;
    padding: 0px 10px;
  }

  .bottomWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    margin-left: 15px;
    margin-right: 15px;

    .leftWrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 5px;

      .offerText {
        font-family: "Figtree";
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        color: #3e3e3e;
      }
      .secondContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;

        .bottomPrice {
          font-family: "Figtree";
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;
          color: #3e3e3e;
        }
      }
    }

    .rightWrapper {
      .css-1e6y48t-MuiButtonBase-root-MuiButton-root {
        background-color: #9040c4;
        min-width: 20px !important;
        padding: 3px !important;
        border-radius: 4px;

        &:hover {
          background-color: #9040c4;
        }
      }
    }
  }

  .buyBtn {
    visibility: hidden;
    padding: 8px 18px;
    background: #9040c4;
    border-radius: 4px;
    border: none;
    color: white;
    font-family: "Figtree";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;

    letter-spacing: -0.05em;
    color: #ffffff;
    transition: 0.3s all ease-in-out;

    &:hover {
      scale: 1.05;
      transition: 0.3s all ease-in-out;
    }
  }

  .cardImgDiv {
    overflow: hidden;
    position: relative;
  }

  @media (max-width: 990px) {
    width: 100%;
  }

  @media (max-width: 420px) {
    width: 300px;
  }

  @media (max-width: 322px) {
    width: 100%;
  }
`;

export const ToggleButton = styled(Form.Check)`
  font-size: 1.8rem;

  transform: translate(20px, -10px);

  #mySwitch {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='10' cy='10' r='10' fill='%23D4D4D4'/%3E%3C/svg%3E%0A") !important;

    background-color: #1a1a1a;
    box-shadow: none;
    border: none;
  }
  #mySwitch:checked {
    background-color: #9040c4;
    box-shadow: none;
    border: none;
  }
`;

export const CardImage = styled(Card.Img)`
  @media (max-width: 420px) {
    height: 100%;
  }

  overflow: hidden;
`;

export const CardMainFlex = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;

  @media (min-width: 992px) {
    .cardItem {
      transform: translateX(40px);
    }
    .cardItem2 {
      transform: translateX(15px);
    }
  }

  @media (max-width: 1367px) {
    margin-left: 3rem;
  }

  @media (max-width: 1280px) {
    margin-left: 3.5rem;
  }
  @media (max-width: 1200px) {
    margin-left: 5.5rem;
  }

  @media (max-width: 767px) {
    margin-left: 1rem;
  }

  @media (max-width: 500px) {
    margin-left: 0.5rem;
  }
  @media (max-width: 420px) {
    margin-left: 3rem;
  }

  @media (max-width: 393px) {
    margin-left: 2rem;
  }
  @media (max-width: 372px) {
    margin-left: 1.3rem;
  }
  @media (max-width: 362px) {
    margin-left: 0.7rem;
  }
  @media (max-width: 340px) {
    margin-left: 0.5rem;
  }
  @media (max-width: 322px) {
    margin-left: 0rem;
  }
`;

export const CollapseMain = styled(Accordion)`
  background: transparent !important;
  width: 100%;

  .accordion-button {
    background-color: none !important;
    border: none;
    box-shadow: none !important;
  }

  &.accordion-item {
    border: none !important;
    background-color: transparent !important;
  }
`;

export const FilterRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 2rem 0.7rem 0rem 0.7rem;

  .filter {
    font-family: "Figtree";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #261ce8;
    margin: 0;
  }

  .filterWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid #b4b4b4;
    border-radius: 1px;
    padding: 15px 20px;
    cursor: pointer;

    @media (max-width: 575.98px) {
      width: 100%;
      margin-bottom: 1rem;
    }
  }

  .divTab {
    flex: 15%;

    display: flex;
    align-items: center;
    gap: 0.5rem;

    @media (max-width: 991.98px) {
      flex: 20%;
    }

    @media (max-width: 767.98px) {
      flex: 25%;
    }

    @media (max-width: 575.98px) {
      flex: 100%;
    }

    .filterIcon {
      color: #261ce8;
      cursor: pointer;
    }
  }

  .searchMain {
    flex: 85%;
    display: flex;
    align-items: center;
    gap: 1.5rem;

    @media (max-width: 991.98px) {
      flex: 80%;
    }

    @media (max-width: 767.98px) {
      flex: 75%;
    }
    @media (max-width: 575.98px) {
      flex: 100%;
    }

    .searchDiv {
      flex: 80%;
    }
    .selectDiv {
      flex: 20%;
    }

    @media (max-width: 467px) {
      flex-wrap: wrap;

      margin-bottom: 1rem;
    }
  }
`;

export const FilterTab = styled(Tabs)`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: red !important;
  }

  .ant-tabs-ink-bar {
    background-color: red !important;
  }

  .ant-tabs-nav:before {
    border-bottom: none !important;
  }
`;

export const FilterTabPanel = styled(Tabs.TabPane)`
  &.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: red !important;
  }
`;

// searchInput

export const SearchInput = styled(Input)`
  background: #f0efff;
  border: 1px solid #e7e0ec;
  border-radius: 1px;
  height: 56px;

  & > ::placeholder {
    padding-left: 0.5rem;
    vertical-align: center;
    font-size: 16px;
    line-height: 30px;
    color: #7f8c8d;
  }

  .ant-input {
    background: #f0efff;
  }
`;

export const PriceSelect = styled(Select)`
  width: 100%;

  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    /* background: linear-gradient(98.6deg, #261ce8 6.03%, #511ce8 99.87%); */
    background: linear-gradient(
      90deg,
      rgba(0, 170, 255, 1) 0%,
      rgba(116, 198, 238, 1) 52%,
      rgba(0, 212, 255, 1) 100%
    );
    border-radius: 4px;
    font-family: "Figtree";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #ffffff;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-select-arrow {
    color: #ffffff;
  }

  .ant-select-selection-item {
  }
`;
export const PriceSelectOption = styled(Select.Option)`
  .ant-select-selection-item {
  }
`;

// Tabs Filter Above Row

// HeroSection

export const MainHeroSection = styled.div`
  background-image: url(${headerbg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 40px;
  overflow: hidden;

  /* infoSectionItm */
  .infoSection {
    margin-left: 1rem;
    margin-right: 1rem;
    display: flex;
    gap: 2.5rem;
    margin-top: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      color: rgba(208, 52, 52, 1);
      font-size: 14px;
      margin-left: 10px;
    }

    @media (max-width: 991.98px) {
      flex-direction: column;
      justify-content: center;
    }

    .wrapper {
      display: flex;
      justify-content: flex-start;
      gap: 30px;

      .infoSectionItm {
        p {
          font-family: "Figtree";
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 19px;
          display: flex;
          align-items: center;
          color: #ffffff;
          text-transform: capitalize;
        }
      }
      @media (max-width: 460px) {
        flex-wrap: wrap;
      }
    }

    .btnContainer {
      .offerBtn {
        background: #ea45b6;
        border-radius: 4px;
        padding: 16px 32px;
        font-family: "Figtree";
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 100%;
        letter-spacing: -0.05em;
        color: #ffffff;
      }
    }
  }

  /* buttonSection */

  .heroDetailsInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 3rem;

    @media (max-width: 991.98px) {
      flex-direction: column;
    }

    .mainTitle {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex: 1;
      gap: 30px;

      @media (max-width: 991.98px) {
        flex-direction: column;
      }

      h1 {
        font-family: "Figtree";
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        color: #ffffff;
      }

      .img-fluid {
        height: 100px;
        border-radius: 5px;
      }
    }

    .byTitle {
      .name {
        font-family: "Figtree";
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        color: #ffffff;
        text-transform: capitalize;

        @media (max-width: 991.98px) {
          width: 100%;
          text-align: center;
        }
      }

      .detailsText {
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 100%;
        display: flex;
        align-items: center;
        color: #ffffff;
        width: 40%;
        margin-top: 10px;

        @media (max-width: 1199.98px) {
          width: 60%;
        }

        @media (max-width: 991.98px) {
          width: 100%;
          text-align: center;
        }
      }
    }

    .socialICons {
      @media (max-width: 991.98px) {
        margin-top: 1rem;
      }

      .socialMain {
        display: flex;
        gap: 2.3rem;
        align-items: center;
        justify-content: center;
        .socialIcon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 1.8rem;
          height: 1.8rem;
          border-radius: 50%;
          cursor: pointer;
          font-size: 18px;

          a {
            text-decoration: none;
            color: rgb(87, 87, 87);
            color: #fff;
            transition: 0.3s all ease-in-out;

            &:hover {
              scale: 1.2;
              transition: 0.3s all ease-in-out;
            }
          }

          .dropdown-toggle {
            background-color: transparent;
            border: none;
            color: #fff;
            box-shadow: none;

            transition: 0.3s all ease-in-out;

            &:hover {
              scale: 1.2;
              transition: 0.3s all ease-in-out;
            }
          }

          .dropdown-toggle::after {
            display: none !important;
          }
        }
        .dropdown-item {
          font-size: 14px;
          background-color: #fff;
          color: #000 !important;

          &:hover {
            scale: 1 !important;
            background-color: rgb(36, 7, 73, 0.2);
          }

          &:active {
            background-color: rgb(230, 233, 234);
          }
        }
      }
    }
  }
`;

export const UserWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  padding: 0px 10px;

  .img-fluid {
    height: 20px;
    border-radius: 50px;
  }

  .userName {
    font-family: "Figtree";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
  }
`;

export const MinMaxMain = styled.div`
  padding-bottom: 20px;
  border-bottom: 1px solid #e7e0ec;
  margin-top: 1rem;

  display: flex;
  gap: 0.7rem;
  flex: 1;
  input {
    height: 48px;
    width: 50%;
    border: none !important;
    padding: 0.5rem;
    background: #f0efff;
    border-radius: 4px;

    &:focus {
      border: none !important;
    }

    ::placeholder {
      padding-left: 1rem !important;
    }
  }
`;

export const PanelDiv = styled.div`
  margin-top: 0.5rem;

  .rs-panel-collapsible > .rs-panel-header > .rs-icon {
    background-color: #9040c4;
    color: #fff;
    top: 12px;
    width: 25px;
    height: 25px;
    border-radius: 4px;
  }

  .rs-panel-title {
    font-family: "Figtree";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #261ce8;
    text-transform: capitalize;
  }

  .rs-panel-body,
  .rs-panel-header {
    padding: 10px 0px;
  }

  .ant-input-affix-wrapper {
    background: #f0efff;
    border: 1px solid #e7e0ec;
    border-radius: 4px;
  }

  .ant-input-affix-wrapper > input.ant-input {
    background: #f0efff;
    padding: 0px 5px;
  }
`;
