import { Card } from "react-bootstrap";

import styled from "styled-components";

export const CategoryTitle = styled.p`
  text-align: center;
  margin-top: 5rem;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 1.5rem;
  color: white;
`;

export const CategoryCardTitle = styled(Card.Title)`
  text-align: center;
  font-family: "Poppins";
  font-size: 1.3rem;
  padding: 0.5rem;
`;
