import styled from "styled-components";

export const ExploreCardWrapper = styled.div`
  margin-top: 2rem;
  padding-bottom: 3rem;

  &.reduce-margin {
    margin-top: 1rem;
    padding-bottom: 0rem;
  }
  &.no-margin {
    margin-top: 0rem;
  }

  .card {
    width: 100%;
    /* width: 250px; */
    min-height: 500px;
    border-radius: 10.637px;
    border: 1.064px solid ${({ theme }) => theme.cardBorder} !important;
    box-shadow: 0px 0px 4.254847526550293px 0px rgba(0, 0, 0, 0.27);
    padding: 1rem 0rem;
    background-color: ${({ theme }) => theme.cardBG};
    position: relative;
    &.increased-padding {
      @media (max-width: 575.98px) {
        padding-bottom: 4rem;
      }
    }
    @media (max-width: 1399.98px) {
      min-height: 530px;
    }
  }

  .icon {
    font-size: 18px;
    cursor: pointer;
    color: #6b6b6b;
    cursor: pointer;
  }

  .liked {
    color: rgba(0, 183, 255, 1) !important;
  }

  .icon-disabled {
    cursor: not-allowed;
  }

  img {
    margin-top: 0.3rem;
    width: 100%;
    height: 220.443px;
  }

  .three-dots {
    display: block;
    margin-left: auto;
    font-size: 24px;
    cursor: pointer;
    border-radius: 20px;
    padding: 3px;
    transition: 300ms ease-in-out all;
    position: absolute;
    bottom: 10px;
    right: 20px;

    &.dots-hovered {
      background-color: #00b7ff;
      color: #fff;
      transition: 300ms ease-in-out all;
    }
  }

  .title {
    color: ${({ theme }) => theme.cardText};
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 100% */
    text-transform: capitalize;
    text-align: center;
    margin-top: 1rem;
  }

  .sub-text {
    color: ${({ theme }) => theme.cardSubText};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    text-align: center;
    margin-top: 5px;
  }

  .price {
    color: #00b7ff;
    font-size: 14.892px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    text-align: center;

    span {
      color: #6b6b6b;
      font-family: Barlow;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: capitalize;
    }
  }

  .content-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.929rem;
  }

  .content-main-dev {
    min-height: 339px;
    padding: 0 0.5rem;
  }
`;

export const TopCardDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -0.5rem;
  padding: 0 0.5rem;
`;

export const BuyNowBtn = styled.button`
  border-radius: 28px;
  border: 1px solid ${({ theme }) => theme.cardBtnBorder};
  color: #00b7ff;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  width: 165px;
  height: 35px;
  background-color: transparent;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;

  @media (max-width: 575.98px) {
    width: 100%;
  }

  &:disabled {
    background: ${({ theme }) => theme.disabledBtnBG};
    border: 1px solid ${({ theme }) => theme.disabledBtnBorder} !important;
    color: rgb(183, 189, 202);
  }
`;

export const Key = styled.h4`
  color: ${({ theme }) => theme.cardInnerContent};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
`;
export const Value = styled.h2`
  color: ${({ theme }) => theme.cardInnerContentValue};
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;

  &.blue {
    color: rgba(0, 183, 255, 1);
  }
`;

export const DropdownDiv = styled.div`
  border-radius: 10px;
  border: 1px solid #e7e7e7;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27);
  position: absolute;
  z-index: 2;
  bottom: -40px;
  right: 0px;
  padding: 10px;

  p {
    color: #6b6b6b;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    cursor: pointer;
  }
`;
