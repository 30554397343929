import { buy, nodata, transfer, sell } from "assets";
import { NoDataDiv, TableDataDiv } from "./element";
import { Image } from "react-bootstrap";
import { Table, Space } from "antd";
import { useAppSelector } from "store/store";
import { GetUserActivityHook } from "hooks/activityHook";
import {
  CommonUtility,
  ContractUtility,
  DateUtility,
  transactionType,
  truncateAddress,
} from "utility";
import Loading from "components/common/loader";

function SoldTab() {
  const { account, chainId } = useAppSelector((state) => state.web3Connect);
  const { loading, data: activity } = GetUserActivityHook(account);
  const handleTxnRedirect = (transaction_hash, chain) => {
    const network = ContractUtility.getNetwork(chain);
    const networkExplore = ContractUtility.getNetworkExplorer(network);

    return `${networkExplore}/tx/${transaction_hash}`;
  };
  const handleAddressRedirect = (address, chain) => {
    const network = ContractUtility.getNetwork(chain);
    const networkExplore = ContractUtility.getNetworkExplorer(network);

    return `${networkExplore}/address/${address}`;
  };
  const { theme } = useAppSelector((state) => state.model);

  const columns = [
    {
      title: "Txn #",
      dataIndex: "transaction_hash",
      key: "transaction_hash",
      render: (_, record) => (
        <Space size="middle">
          <a
            style={{ color: theme === "light" ? "#3E3E3E" : "#FBFBFC" }}
            className="table-text"
            href={handleTxnRedirect(record?.transaction_hash, record?.chain_id)}
            target="_blank"
          >
            {truncateAddress(record?.transaction_hash)}
          </a>
        </Space>
      ),
    },
    {
      title: "Txn Time",
      dataIndex: "timestamp",
      key: "timestamp",
      render: (_, record) => (
        <Space
          size="middle"
          style={{ color: theme === "light" ? "#3E3E3E" : "#FBFBFC" }}
        >
          {DateUtility.unixtimeStampToDateTime(record?.timestamp)}
        </Space>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, record) => (
        // <Space size="middle">
        //   {record.status === transactionType?.BOUGHT ? (
        //     <Image
        //       src={buy}
        //       height={30}
        //       width={30}
        //       style={{ cursor: "pointer" }}
        //     />
        //   ) : record.status === transactionType?.SOLD ? (
        //     <Image
        //       src={sell}
        //       height={30}
        //       width={30}
        //       style={{ cursor: "pointer" }}
        //     />
        //   ) : (
        //     <Image
        //       src={transfer}
        //       height={30}
        //       width={30}
        //       style={{ cursor: "pointer" }}
        //     />
        //   )}
        // </Space>
        <Space size="middle">
          {record.from === "0x0000000000000000000000000000000000000000" ? (
            <Image
              src={transfer}
              height={30}
              width={30}
              style={{ cursor: "pointer" }}
            />
          ) : record.from === account?.toLowerCase() ? (
            <Image
              src={sell}
              height={30}
              width={30}
              style={{ cursor: "pointer" }}
            />
          ) : (
            <Image
              src={buy}
              height={30}
              width={30}
              style={{ cursor: "pointer" }}
            />
          )}
        </Space>
      ),
    },
    {
      title: "From",
      dataIndex: "from",
      key: "from",
      render: (_, record) => (
        <Space size="middle">
          <a
            className="table-text"
            style={{ color: theme === "light" ? "#3E3E3E" : "#FBFBFC" }}
            href={handleAddressRedirect(record?.from, record?.chain_id)}
            target="_blank"
          >
            {truncateAddress(record?.from)}
          </a>
        </Space>
      ),
    },
    {
      title: "To",
      dataIndex: "to",
      key: "to",
      render: (_, record) => (
        <Space size="middle">
          <a
            className="table-text"
            style={{ color: theme === "light" ? "#3E3E3E" : "#FBFBFC" }}
            href={handleAddressRedirect(record?.to, record?.chain_id)}
            target="_blank"
          >
            {truncateAddress(record?.to)}
          </a>
        </Space>
      ),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (_, record) => (
        <Space
          size="middle"
          style={{ color: theme === "light" ? "#3E3E3E" : "#FBFBFC" }}
        >
          {record?.price
            ? `${CommonUtility.convertFromWei(
                record?.price,
                ContractUtility.getPaymentToken(record?.payment_token, chainId)
                  ?.decimals,
                false
              )} ${
                ContractUtility.getPaymentToken(record?.payment_token, chainId)
                  ?.symbol
              }`
            : "-"}
        </Space>
      ),
    },
    {
      title: "USD Price",
      dataIndex: "price",
      key: "price",
      render: (_, record) => (
        <Space
          size="middle"
          style={{ color: theme === "light" ? "#3E3E3E" : "#FBFBFC" }}
        >
          {" "}
          {record?.price
            ? CommonUtility.convertFromWei(record?.usd_price, 6, false)
            : "-"}
        </Space>
      ),
    },
  ];

  let locale = {
    emptyText: (
      <span>
        <Image src={nodata} />
        <p
          style={{
            color: "#3E3E3E",
            marginTop: "0.5rem",
            marginLeft: "1.5rem",
          }}
          className="offers-text"
        >
          No Transactions Yet!
        </p>
      </span>
    ),
  };

  const dataCheck = true;
  return (
    <div>
      {dataCheck ? (
        <TableDataDiv>
          {loading && <Loading content={"Loading"} />}
          <Table
            columns={columns}
            dataSource={activity}
            pagination={false}
            locale={locale}
            bordered={false}
          />
        </TableDataDiv>
      ) : (
        !loading && (
          <NoDataDiv>
            <Image src={nodata} />
            <p>No Data Foundddd</p>
          </NoDataDiv>
        )
      )}
    </div>
  );
}

export default SoldTab;
