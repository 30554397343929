import { MainRow, MainCol } from "components/common";
import { ProfileCard } from "components/common/cards";
import { DropdownDiv, FiltersSubHeader, NoDataFoundComDiv } from "./element";
import NoDataFoundCom from "../noDataFound";
import { SearchWrapper } from "./element";
import { Select } from "antd";
import { Form, Image } from "react-bootstrap";
import { IoIosArrowDown } from "react-icons/io";
import { useState, useEffect, useRef } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { grayimgframe } from "assets";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useAppSelector } from "store/store";
import { CommonUtility } from "utility";
import { useLocation } from "react-router-dom";
const drops = [
  {
    id: 1,
    name: "Hello world",
    text: "hello",
    image: grayimgframe,
  },
  {
    id: 1,
    name: "Dips",
    text: "hello",
    image: grayimgframe,
  },
  {
    id: 1,
    name: "Ships",
    text: "hello",
    image: grayimgframe,
  },
  {
    id: 1,
    name: "Wips",
    text: "hello",
    image: grayimgframe,
  },
];

function CollectionsTab({
  data: mints,
  getNftsByUser,
  showFilters,
  loading,
  setFilter,
  filter,
  schemas,
  dropList,
  allMints,
}) {
  const [dropsValue, setDropsValue] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState(null);
  const dropdownRef = useRef(null);
  const scrooleRef = useRef(null);
  const location = useLocation();
  const [mouseHovered, setMouseHovered] = useState(false);

  useEffect(() => {
    if (mints) {
      let tamp = mints;
      if (filter?.schema_name && filter?.drop_id) {
        tamp = mints.filter(
          (x) =>
            x.schema_name == filter.schema_name &&
            x.drop_id?._id == filter?.drop_id
        );
      } else if (filter.schema_name) {
        tamp = mints.filter((x) => x.schema_name == filter.schema_name);
      } else if (filter?.drop_id) {
        tamp = mints.filter((x) => x.drop_id?._id == filter.drop_id);
      }
      setData(tamp);
    }
  }, [mints, filter]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !mouseHovered &&
        isOpen &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isOpen]);

  const handleDropdownClick = (item) => {
    setFilter((pre) => ({
      ...pre,
      drop_id: item?._id,
    }));

    setIsOpen(false);
    setDropsValue(item.name);
  };
  const filteredData = dropList?.filter((item) =>
    item?.name?.toLowerCase()?.includes(dropsValue?.toLowerCase())
  );

  const { theme } = useAppSelector((state) => state.model);

  return (
    <div>
      <div
        className={`${data?.length > 0 && "whole-content-wrapper"} ${
          data?.length === 0 && "centered-content"
        }`}
      >
        {showFilters && (
          <div className="profile-filters-div">
            <FiltersSubHeader>Schema</FiltersSubHeader>

            <SearchWrapper
              className={
                location.pathname.startsWith("/starship/profile") &&
                "profile-wrapper"
              }
            >
              <Select
                showSearch
                className="first-explore-select"
                popupClassName="first-explore-select-dropdown"
                style={{
                  width: "100%",
                  marginTop: "1rem",
                  color: theme === "light" ? "#6B6B6B" : "#FBFBFC",
                }}
                placeholder="Select "
                optionFilterProp="children"
                allowClear
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    ?.toLowerCase()
                    ?.includes(input?.toLowerCase())
                }
                onClear={() => {
                  if (filter?.schema_name) {
                    setFilter((pre) => {
                      let { schema_name, ...rest } = pre;
                      return rest;
                    });
                  }
                }}
                value={filter?.schema_name || "select schema"}
                onSelect={(e) => {
                  setFilter((pre) => ({
                    ...pre,
                    schema_name: e,
                  }));
                }}
                options={schemas?.map((x) => {
                  return {
                    label: x.schema_name,
                    value: x.schema_name,
                  };
                })}
              />

              <div className="mt-4">
                <DropdownDiv>
                  <FiltersSubHeader>Select Drops</FiltersSubHeader>

                  <Form.Control
                    style={{ width: "100%" }}
                    placeholder={
                      drops?.length > 0 ? "Select" : "No Drops Found"
                    }
                    onChange={(e) => setDropsValue(e.target.value)}
                    value={dropsValue}
                    onClick={() => setIsOpen(!isOpen)}
                  />

                  {dropsValue ? (
                    <AiFillCloseCircle
                      className="dropdown-arrow close"
                      onClick={() => {
                        setDropsValue("");
                        setIsOpen(false);
                        setFilter((pre) => {
                          const { drop_id, ...rest } = pre;
                          return rest;
                        });
                      }}
                    />
                  ) : (
                    <IoIosArrowDown className="dropdown-arrow" />
                  )}

                  {isOpen && (
                    <div
                      className="drops-dropdown"
                      ref={dropdownRef}
                      onMouseEnter={() => setMouseHovered(true)}
                      onMouseLeave={() => setMouseHovered(false)}
                    >
                      {dropsValue?.length > 0 ? (
                        <>
                          {filteredData?.length === 0 ? (
                            <p>No drops found</p>
                          ) : (
                            filteredData?.map((item, index) => (
                              <div
                                className="content-wrapper"
                                onClick={() => handleDropdownClick(item)}
                              >
                                <div>
                                  <Image
                                    src={item.image}
                                    height={40}
                                    width={40}
                                    style={{ borderRadius: "20px" }}
                                  />
                                </div>
                                <div>
                                  <h2 className="top-text">{item.name}</h2>
                                  <p className="bottom-text">{item.text}</p>
                                </div>
                              </div>
                            ))
                          )}
                        </>
                      ) : (
                        <>
                          {dropList?.map((item, index) => (
                            <div
                              key={index}
                              className="content-wrapper"
                              onClick={() => handleDropdownClick(item)}
                            >
                              <div>
                                <Image
                                  src={item.image}
                                  height={40}
                                  width={40}
                                  style={{ borderRadius: "20px" }}
                                />
                              </div>
                              <div>
                                <h2 className="top-text">{item.name}</h2>
                                <p className="bottom-text">schema</p>
                              </div>
                            </div>
                          ))}
                        </>
                      )}

                      {dropList?.length == 0 && "No drops found"}
                    </div>
                  )}
                </DropdownDiv>
              </div>
            </SearchWrapper>
          </div>
        )}

        {loading && !data ? (
          <></>
        ) : (
          <>
            <div
              className={`${
                data?.length > 0 ? "card-wrapper" : "no-data-div"
              } ${showFilters && "reduced-card"}`}
              ref={scrooleRef}
            >
              {data?.length > 0 &&
                data?.map((x, index) => (
                  <ProfileCard
                    item={x}
                    index={index}
                    getNftsByUser={getNftsByUser}
                  />
                ))}
            </div>
          </>
        )}
      </div>
      {!loading && (!data || data?.length == 0) && (
        <NoDataFoundComDiv
          style={{
            marginTop: "2rem",
          }}
        >
          <NoDataFoundCom message={"no collection found"} />
        </NoDataFoundComDiv>
      )}
    </div>
  );
}

export default CollectionsTab;
