import { useEffect, useMemo, useRef, useState } from "react";
import {
  AddAssetsBtn,
  DateWrapper,
  DropsWrapper,
  MainWrapper,
  PreviewBtn,
  Title,
} from "./element";
import {
  MainCol,
  MainContainer,
  MainRow,
  ToastMessage,
} from "components/common";
import { Input, Modal, Select } from "antd";
import Editor from "../packs/createPackCom/textEditor";
import { DatePicker } from "antd";
import { BtnWrapper, CancelBtn, CreateSchemaBtn } from "../schema/elements";
import AddAssetsModal from "components/modal/addAssetsModel";
import { MintNftsModal, PreviewImgModal } from "components/modal";
import { CommonUtility, ContractUtility, DateUtility } from "utility";
import { useAppSelector } from "store/store";
import { useFormik } from "formik";
import { validateDrop } from "components/validation/customValidator";
import { GetPackByIdHook } from "hooks/packHook";
import { starshipAbi } from "contract/starship";
import Loading from "components/common/loader";
import { CreateDropHook } from "hooks/dropHook";
import { useNavigate } from "react-router";
import { sendFileToThirdWebIPFS } from "utility/services/ipfsService";
import { BsCloudUploadFill } from "react-icons/bs";
import { storage } from "../../../firebase";
import { useStorageUpload } from "@thirdweb-dev/react";

const CreateDropComp = () => {
  const { web3, account, chainId } = useAppSelector(
    (state) => state.web3Connect
  );
  const { mutateAsync: upload } = useStorageUpload();
  const { config } = useAppSelector((state) => state.configData);
  const quillRef = useRef(null);
  const navigate = useNavigate();
  const { createDrop } = CreateDropHook();
  const { data, getPackById } = GetPackByIdHook();
  const [description, setDescription] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadfileName, setuploadfileName] = useState();
  const [selectedImageFile, setSelectedImageFile] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const paymentTokens = useMemo(() => {
    return ContractUtility.getPaymentList(chainId);
  }, [chainId]);
  const onSearch = (value) => {
    console.log("search:", value);
  };
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileName = file.name;
      setFieldValue("image", file);
      setFieldValue("image_name", fileName);
      setuploadfileName(fileName);
      setSelectedImageFile(event.target.files[0]);
    } else {
      setFieldValue("image", "");
      setFieldValue("image_name", "");
      setuploadfileName("");
      setSelectedImageFile("");
    }
  };
  const showImageModal = () => {
    setIsImageModalOpen(true);
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setIsCreateModalOpen(false);
    setIsImageModalOpen(false);
  };
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    setFieldValue,
    setFieldTouched,
    resetForm,
  } = useFormik({
    initialValues: {
      pack_id: "",
      name: "",
      description: "",
      image: "",
      image_name: "",
      price: "",
      payment_token: "",
      start_time: "",
      end_time: "",
      max_supply: "",
      cool_down_time: "",
      is_hidden: false,
      reveal_time: "",
      is_secure: false,
      amount_limit_per_account: "",
    },
    validate: validateDrop,
    onSubmit: async (values) => {
      const images = document.querySelectorAll("img");
      const uploadImages = async () => {
        const uploadPromises = [];
        for (let i = 1; i < images.length; i++) {
          const image = images[i];
          const src = image.getAttribute("src");
          if (src.startsWith("data:image")) {
            const file = dataURLtoFile(src, `image${i}.png`);
            const storageRef = storage.ref(`/starship/image${i}.png`);
            const uploadTask = storageRef.put(file);
            const uploadPromise = new Promise((resolve, reject) => {
              uploadTask.on(
                "state_changed",
                (snapshot) => {},
                (error) => {
                  console.log(error);
                  reject(error);
                },
                async () => {
                  try {
                    const downloadURL = await storageRef.getDownloadURL();
                    resolve(downloadURL);
                  } catch (error) {
                    console.error("Error getting download URL:", error);
                    reject(error);
                  }
                }
              );
            });
            uploadPromises.push(uploadPromise);
          }
        }
        return Promise.all(uploadPromises);
      };
      try {
        const fileUrls = await uploadImages();
        for (let i = 0; i < images.length; i++) {
          const image = images[i + 1];
          const fileUrl = fileUrls[i];
          fileUrl &&
            fileUrl !== "undefind" &&
            image?.setAttribute("src", fileUrl);
        }
        const editorContent = quillRef.current.getEditor().root.innerHTML;
        values.description = editorContent;
      } catch (error) {
        console.error("Error uploading images:", error);
      }
    },
  });
  function dataURLtoFile(dataURL, filename) {
    const arr = dataURL.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const uint8Array = new Uint8Array(n);
    while (n--) {
      uint8Array[n] = bstr.charCodeAt(n);
    }
    return new File([uint8Array], filename, { type: mime });
  }
  const paymentDecimals = useMemo(() => {
    if (values.payment_token && chainId) {
      const res = ContractUtility.getPaymentToken(
        values.payment_token,
        chainId
      )?.decimals;
      return res;
    }
  }, [values, chainId]);
  const paymentIndex = useMemo(() => {
    if (values.payment_token && chainId) {
      const res = ContractUtility.getPaymentToken(
        values.payment_token,
        chainId
      );
      if (res && res.index) {
        return res.index;
      }
    }
  }, [values, chainId]);

  useEffect(() => {
    const fetchData = async () => {
      if (values?.pack_id) {
        await getPackById(values.pack_id);
      }
    };

    fetchData();
  }, [values?.pack_id]);
  const isObjectEmpty = (obj) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  };

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };
  const generateArrays = (data, arrayCount) => {
    const totalProb = data.reduce(
      (sum, item) => sum + parseInt(item.probability, 10),
      0
    );
    const arrays = [];
    for (let i = 0; i < arrayCount; i++) {
      let currentProbSum = 0;
      const array = new Array(data.length).fill(0);
      for (let j = 0; j < data.length; j++) {
        const prob =
          (parseInt(data[j].probability, 10) / totalProb) * arrayCount;
        if (currentProbSum + prob > i) {
          array[j] = 1;
          break;
        }
        currentProbSum += prob;
      }
      arrays.push(array);
    }
    return arrays;
  };
  const dropPack = async () => {
    try {
      handleSubmit();
      if (isObjectEmpty(errors)) {
        setIsLoading(true);
        const supplyForArrays =
          +values.max_supply == 0 ? 10 : values.max_supply;
        let arr = [];
        for (let i = 0; i < data?.pack_slots?.length; i++) {
          const generatedArrays = generateArrays(
            data?.pack_slots[i],
            supplyForArrays
          );
          const shuffeledArrays = shuffleArray(generatedArrays);
          const obj = {
            slot: i,
            probabilities: shuffeledArrays,
          };
          arr.push(obj);
        }
        const probabilitiesLength = arr[0].probabilities.length;
        const combinedProbabilities = Array.from(
          { length: probabilitiesLength },
          (_, index) => {
            return arr.reduce((acc, slot) => {
              return [...acc, ...slot.probabilities[index]];
            }, []);
          }
        );
        const validArrays = shuffleArray(combinedProbabilities);
        const nftIds = data?.pack_slots.flatMap((slot) =>
          slot.map((template) => template.template_id.nft_id)
        );
        const starShip = config.STAR_SHIP_ADDRESS;
        const contract = CommonUtility.contract(
          web3,
          starshipAbi,
          starShip[+chainId]
        );
        const adminRole = await contract.methods.ADMIN_ROLE().call();
        const hasRole = await contract.methods
          .hasRole(adminRole, account)
          .call();
        if (!hasRole) throw "Only admin can drop packs";
        if (nftIds?.lenght == 0) throw "Please choose templates";
        const price = CommonUtility.convertToWei(
          values?.price,
          paymentDecimals
        );
        const currentTime = Date.now() / 1000;
        if (currentTime >= values?.start_time) throw " Invalid start time";
        if (+values?.start_time >= +values?.end_time) throw " Invalid end time";
        const dropData = [
          values?.is_secure,
          paymentIndex,
          nftIds,
          data?.slots,
          price,
          DateUtility.convertDateToUnix(values?.start_time?._d),
          DateUtility.convertDateToUnix(values?.end_time?._d),
          +values?.max_supply == 0 ? config?.UINT256_MAX : values?.max_supply,
          +values?.max_supply == 0 ? config?.UINT256_MAX : values?.max_supply,
          +values?.amount_limit_per_account == 0
            ? config?.UINT256_MAX
            : values?.amount_limit_per_account,
          values?.cool_down_time,
        ];
        const ipfsImage = await sendFileToThirdWebIPFS(values?.image, upload);
        const txn = await contract.methods
          .createPack(dropData)
          .send({ from: account })
          .on("transactionHash", async (hash) => {
            let obj = {
              ...values,
              start_time: DateUtility.convertDateToUnix(values?.start_time?._d),
              end_time: DateUtility.convertDateToUnix(values?.end_time?._d),
              // reveal_time: DateUtility.convertDateToUnix(
              //   values?.reveal_time?._d
              // ),
              reveal_time: DateUtility.convertDateToUnix(
                values?.start_time?._d
              ),
              transaction_hash: hash,
              payment_token_decimals: paymentDecimals,
              image: ipfsImage,
              drop_nfts: validArrays,
            };
            createDrop(obj);
          });
        if (txn && txn.code == 4001) throw "User denied the transaction";
        if (!txn.status) throw "Transaction Failed";
        if (txn.status) {
          setIsLoading(false);
          navigate("/starship/admin/drops");
          ToastMessage("Success!", "Transaction successfull", "success");
        }
      }
    } catch (error) {
      setIsLoading(false);
      console.log("error", error);
      if (error?.message) {
        ToastMessage("Error", error?.message, "error");
      } else {
        ToastMessage("Error", error, "error");
      }
    }
  };
  return (
    <DropsWrapper>
      {isLoading && <Loading content={"Transaction in progress"} />}
      <Modal open={isModalOpen} onCancel={handleCancel} centered width={463}>
        <AddAssetsModal
          handleCancel={handleCancel}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          values={values}
        />
      </Modal>
      <Modal
        open={isCreateModalOpen}
        onCancel={handleCancel}
        centered
        width={481}
      >
        <MintNftsModal
          handleCancel={handleCancel}
          packsmodal
          setCardsData
          dropmodal
        />
      </Modal>
      <Modal
        open={isImageModalOpen}
        onCancel={handleCancel}
        width={600}
        centered
      >
        <PreviewImgModal
          handleCancel={handleCancel}
          selectedFile={selectedImageFile}
          image_url={undefined} // image_url={getPackById?.image_url}
        />
      </Modal>
      <MainContainer fluid>
        <MainWrapper>
          <Title style={{ marginTop: "-.5rem" }}>Assets</Title>

          <AddAssetsBtn onClick={showModal}>
            {values?.pack_name ? values?.pack_name : "add asset"}{" "}
          </AddAssetsBtn>
          <div
            style={{
              color: "#F65D5D",
              textAlign: "center",
              marginTop: "-2.5rem",
            }}
          >
            {touched.pack_id && errors.pack_id}
          </div>
          <Title>Display data</Title>
          <MainRow>
            <p>Name</p>
            <MainCol lg={12}>
              <Input
                placeholder="Enter name"
                type="text"
                name="name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <span style={{ color: "#F65D5D", marginTop: "1rem" }}>
                {touched.name && errors.name}
              </span>
            </MainCol>
          </MainRow>
          <MainRow className="mt-3">
            <MainCol lg={11}>
              <label htmlFor="update" style={{ width: "100%" }}>
                <div className="input-div">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    {uploadfileName || "Please upload image"}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                    }}
                  >
                    <BsCloudUploadFill fontSize={20} cursor="pointer" />
                  </div>
                </div>
              </label>
              <input
                type="file"
                id="update"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleFileUpload}
                onBlur={() => setFieldTouched("image_name", true)}
                name="image_name"
              />
              <p>uRL of the image to display in the drop</p>
              <span style={{ color: "#F65D5D", marginTop: "1rem" }}>
                {touched.image_name && errors.image_name}
              </span>
            </MainCol>
            <MainCol lg={1}>
              <PreviewBtn onClick={showImageModal}>Preview</PreviewBtn>
            </MainCol>
          </MainRow>
          <Editor
            description={description}
            setDescription={setDescription}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            quillRef={quillRef}
          />
          <span style={{ color: "#F65D5D", marginTop: "1rem" }}>
            {touched.description && errors.description}
          </span>
          <MainRow>
            <Title>Pricing</Title>
            <MainCol lg={6}>
              <p>listing currency</p>
              <Select
                bordered={false}
                className="price-select"
                showSearch
                placeholder="Starship"
                optionFilterProp="children"
                onSearch={onSearch}
                onSelect={(e) => {
                  setFieldValue("payment_token", e);
                }}
                onBlur={(e) => {
                  setFieldTouched("payment_token", true);
                }}
                filterOption={(input, option) =>
                  (option?.name ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={paymentTokens?.map((x) => {
                  return {
                    value: x.address,
                    label: x.name,
                  };
                })}
              />
              <span style={{ color: "#F65D5D", marginTop: "1rem" }}>
                {touched.payment_token && errors.payment_token}
              </span>
            </MainCol>
            <MainCol lg={6}>
              <p>price</p>
              <Input
                placeholder="0"
                type="number"
                name="price"
                value={values.price}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <span style={{ color: "#F65D5D", marginTop: "1rem" }}>
                {touched.price && errors.price}
              </span>
            </MainCol>
          </MainRow>
          <MainRow>
            <Title>Limits</Title>
            <MainCol lg={4}>
              <p>Total Supply</p>
              <Input
                placeholder="0"
                type="number"
                min={0}
                name="max_supply"
                value={values.max_supply}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <span style={{ color: "#F65D5D", marginTop: "1rem" }}>
                {touched.max_supply && errors.max_supply}
              </span>
              <p>The drop size (0 means unlimited)</p>
            </MainCol>
            <MainCol lg={4}>
              <p>amount limit</p>
              <Input
                placeholder="1"
                type="number"
                min={0}
                name="amount_limit_per_account"
                value={values.amount_limit_per_account}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <span style={{ color: "#F65D5D", marginTop: "1rem" }}>
                {touched.amount_limit_per_account &&
                  errors.amount_limit_per_account}
              </span>
              <p>
                The amount of times an account can claim the drop (0 means
                unlimited)
              </p>
            </MainCol>
            <MainCol lg={4}>
              <p>amount limit cool-down</p>
              <Input
                placeholder="0"
                type="number"
                min={0}
                name="cool_down_time"
                value={values.cool_down_time}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <span style={{ color: "#F65D5D", marginTop: "1rem" }}>
                {touched.cool_down_time && errors.cool_down_time}
              </span>
              <p>
                The amount of time in seconds the account limit is reset (O
                means no reset)
              </p>
            </MainCol>
          </MainRow>
          <MainRow className="mt-3">
            <MainCol lg={6}>
              <DateWrapper>
                <Title>schedule</Title>
                <DatePicker
                  showTime={{
                    format: "HH:mm",
                  }}
                  format="YYYY-MM-DD HH:mm"
                  onChange={(e) => {
                    setFieldValue("start_time", e);
                  }}
                  onBlur={() => {
                    setFieldTouched("start_time", true);
                  }}
                  placeholder="start date & time"
                />
                <span style={{ color: "#F65D5D", marginTop: "1rem" }}>
                  {touched.start_time && errors.start_time}
                </span>
              </DateWrapper>
            </MainCol>
            <MainCol lg={6}>
              <DateWrapper>
                <Title>&nbsp;</Title>
                <DatePicker
                  showTime={{
                    format: "HH:mm",
                  }}
                  format="YYYY-MM-DD HH:mm"
                  onChange={(e) => {
                    setFieldValue("end_time", e);
                  }}
                  onBlur={() => {
                    setFieldTouched("end_time", true);
                  }}
                  placeholder="end date & time"
                />
                <span style={{ color: "#F65D5D", marginTop: "1rem" }}>
                  {touched.end_time && errors.end_time}
                </span>
              </DateWrapper>
            </MainCol>
          </MainRow>

          {/* <MainRow>
            <MainCol lg={6}>
              <DateWrapper>
                <Title>reveal date</Title>
                <DatePicker
                  showTime={{
                    format: "HH:mm",
                  }}
                  format="YYYY-MM-DD HH:mm"
                  placeholder="reveal date & time"
                  onChange={(e) => {
                    setFieldValue("reveal_time", e);
                  }}
                  onBlur={() => {
                    setFieldTouched("reveal_time", true);
                  }}
                />
                <span style={{ color: "#F65D5D", marginTop: "1rem" }}>
                  {touched.reveal_time && errors.reveal_time}
                </span>
              </DateWrapper>
            </MainCol>
          </MainRow> */}
          {/* 
          <CheckboxDiv className="mt-4">
            <Checkbox
              name="is_secure"
              checked={values.is_secure}
              onChange={(e) => {
                setFieldValue("is_secure", e.target.checked);
              }}
            >
              secure drop
            </Checkbox>
            <p>a secure drop requires a private key or a whitelist </p>
          </CheckboxDiv> */}

          {/* <CheckboxDiv className="mt-4">
            <Checkbox
              name="is_hidden"
              checked={values.is_hidden}
              onChange={(e) => {
                setFieldValue("is_hidden", e.target.checked);
              }}
            >
              hide drop
            </Checkbox>
            <p>
              Hidden drops are not shown in the drops list. However, they can
              still be claimed using the drop url or calling the smart contract.{" "}
            </p>
          </CheckboxDiv> */}
        </MainWrapper>
        <BtnWrapper>
          <CancelBtn>Cancel</CancelBtn>
          <CreateSchemaBtn
            onClick={dropPack}
            disabled={
              Object.keys(errors).length > 0 || !account || !values.pack_id
            }
          >
            create
          </CreateSchemaBtn>
        </BtnWrapper>
      </MainContainer>
    </DropsWrapper>
  );
};
export default CreateDropComp;
