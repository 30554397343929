import { APIPath } from "utility/constant";
import { BaseService } from "./baseServices";

class Pack {
  createPack = (data) => {
    return BaseService.post(APIPath.createPack, data);
  };
  getPacks = (page) => {
    return BaseService.get(`${APIPath.getPacks}?page=${page}`);
  };
  getPackById = (id) => {
    return BaseService.get(`${APIPath.getPackById}/${id}`);
  };
  deletePack = (id) => {
    return BaseService.delete(`${APIPath.deletePack}/${id}`);
  };
  editPack = (id, obj) => {
    return BaseService.put(`${APIPath.editPack}/${id}`, obj);
  };
}

const PackService = new Pack();
Object.freeze(PackService);
export { PackService };
