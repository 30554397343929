import { useEffect, useState } from "react";
import {
  BreadCrumbComp,
  MainCol,
  MainContainer,
  MainRow,
  ToastMessage,
} from "components/common";
import { BtnWrapper, CancelBtn, CreateSchemaBtn } from "../schema/elements";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CheckBoxDiv,
  ImgDiv,
  MainWrapper,
  TextareaDiv,
  Title,
} from "./elements";
import {
  BsCloudUploadFill,
  BsPlusCircle,
  BsQuestionCircleFill,
} from "react-icons/bs";
import { Input, Modal, Select } from "antd";
import { Checkbox } from "antd";
import { GetCreateSchemaByIdHook } from "hooks/createSchemaHook";
import { useFormik } from "formik";
import { CreateTemplateHook } from "hooks/templateHook";
import Loading from "components/common/loader";
import { useAppSelector } from "store/store";
import { CommonUtility } from "utility";
import { starshipAbi } from "contract/starship";
import { ConnectBtn } from "../navbar/elements";
import { AiFillDelete } from "react-icons/ai";
import {sendFileToThirdWebIPFS } from "utility/services/ipfsService";
import ConnectModals from "components/modal/connectModal";
import { useStorageUpload } from "@thirdweb-dev/react";
const dataa = [
  {
    id: 1,
    text: "Starship NFT Game",
    link: "/admin/starship",
  },
  {
    id: 2,
    text: "Schemas and templates",
    link: "/starship/admin/schemas",
  },

  {
    id: 3,
    text: "Create Template",
    link: "",
  },
];

const CreateTemplateComp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryString = location.search;
  const searchParams = new URLSearchParams(queryString);
  const id = searchParams.get("id");
  const { web3, account, chainId } = useAppSelector(
    (state) => state.web3Connect
  );
  const { config } = useAppSelector((state) => state.configData);

  const [uploadfileName, setuploadfileName] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const emailRegex = /^[\w.%+-]+@[\w.-]+\.[a-zA-Z]{2,}$/;

  const { data, loading } = GetCreateSchemaByIdHook(id);
  const { data: templateData, createTemplate } = CreateTemplateHook();
  const { mutateAsync: upload } = useStorageUpload();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const isObjectEmpty = (obj) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  };
  const handleAddField = (index) => {
    let update = [...values.schema_attributes];
    update[index] = {
      ...update[index],
      numbers: [
        ...update[index].numbers,
        {
          number: "",
        },
      ],
    };

    setFieldValue("schema_attributes", update);
  };

  const handleRemoveField = (innerIndex, outerIndex) => {
    let update = [...values.schema_attributes];
    if (update[outerIndex] && update[outerIndex].numbers) {
      update[outerIndex].numbers.splice(innerIndex, 1);
      setFieldValue("schema_attributes", update);
    }
  };
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    setFieldValue,
    setFieldTouched,
  } = useFormik({
    initialValues: {
      max_supply: "",
      schema_name: "",
      description: "",
      is_transferable: false,
      is_burnable: false,
      schema_attributes: [
        {
          types: "",
          attribute_value: "",
          id: 0,
        },
      ],
    },
    validate: (values) => {
      const errors = {};

      if (!values.max_supply && values.max_supply !== 0) {
        errors.max_supply = "required";
      }

      values.schema_attributes.forEach((attribute, index) => {
        if (
          !attribute?.attribute_value?.toString()?.trim() &&
          attribute?.types !== "number[]"
        ) {
          errors[`schema_attributes.${index}.attribute_value`] = "required";
        } else if (
          attribute?.types === "email" &&
          attribute?.attribute_value &&
          !emailRegex.test(attribute.attribute_value)
        ) {
          errors[`schema_attributes.${index}.attribute_value`] =
            "invalid email.";
        }
        if (
          attribute.types === "number[]" &&
          attribute.numbers &&
          attribute.numbers.length === 0
        ) {
          errors[`schema_attributes.${index}.numbers`] = "Number is required.";
        } else if (attribute.types === "number[]") {
          attribute.numbers.forEach((numberObj, numIndex) => {
            if (!numberObj.number) {
              errors[`schema_attributes.${index}.numbers.${numIndex}.number`] =
                "Number is required.";
            }
          });
        }
      });

      return errors;
    },
    onSubmit: async (values) => {},
  });

  useEffect(() => {
    if (data) {
      setFieldValue("schema_name", data?.schema_name);
      let attributes1 = data?.schema_attributes.map((x, index) => {
        if (x.types == "number[]") {
          return {
            ...x,
            id: index + 1,
            numbers: [{ number: "" }],
          };
        } else {
          return {
            ...x,
            attribute_value: x.attribute_value ? x.attribute_value : "",
            id: index + 1,
          };
        }
      });

      setFieldValue("schema_attributes", attributes1);
    }
  }, [data]);

  const makeTemplate = async () => {
    try {
      handleSubmit();
      if (isObjectEmpty(errors)) {
        setIsLoading(true);
        const starShip = config.STAR_SHIP_ADDRESS;
        const contract = CommonUtility.contract(
          web3,
          starshipAbi,
          starShip[+chainId]
        );
        const adminRole = await contract.methods.ADMIN_ROLE().call();
        const hasRole = await contract.methods
          .hasRole(adminRole, account)
          .call();
        if (!hasRole) throw "Only admin can create template ";
        const supply =
          +values?.max_supply > 0 ? values?.max_supply : config?.UINT256_MAX;

        // upload file
        let file = values.schema_attributes?.map((x) => {
          if (x?.file) {
            return x.file;
          }
        });
        file = file.filter((item) => item !== undefined);
        // const ipfsImage = await sendFileToIPFS(file[0]);
        const ipfsImage = await sendFileToThirdWebIPFS(file[0],upload);
        console.log("ipfsImage",ipfsImage)
        //upload file//
        const txn = await contract.methods
          .createSchema(supply, values?.is_transferable, values?.is_burnable)
          .send({ from: account })
          .on("transactionHash", async (hash) => {
            let obj = {
              ...values,
              max_supply: supply,
              transaction_hash: hash,
              template_attributes: values.schema_attributes?.map((x) => {
                if (x?.file) {
                  return {
                    attribute_value: x.attribute_value,
                    attribute_type: x.attribute_placeholder,
                    file: x.file,
                    field: x?.types,
                  };
                } else if (x?.numbers?.length > 0) {
                  return {
                    numbers: x.numbers,
                    attribute_type: x.attribute_placeholder,
                    attribute_value: "multiple numbers",
                    field: x?.types,
                  };
                } else {
                  return {
                    attribute_value: x.attribute_value,
                    field: x?.types,
                    attribute_type: x.attribute_placeholder,
                  };
                }
              }),
              schema_id: id,
              ipfs_image: ipfsImage,
            };

            createTemplate(obj);
          });
        if (txn && txn.code == 4001) throw "User denied the transaction";
        if (!txn.status) throw "Transaction Failed";
        if (txn.status) {
          setIsLoading(false);
          ToastMessage("Success!", "Template created successfully", "success");
          navigate(`/starship/admin/template/view-template?id=${id}`);
        }
      }
    } catch (error) {
      setIsLoading(false);
      console.log("error", error);
      if (error?.message) {
        ToastMessage("Error", error?.message, "error");
      } else {
        ToastMessage("Error", error, "error");
      }
    }
  };

  return (
    <div>
      {(loading || isLoading) && <Loading content={"Creating template"} />}
      <MainContainer fluid>
        <Modal open={isModalOpen} centered onCancel={handleCancel} width={504}>
          <ConnectModals onCancel={handleCancel} />
        </Modal>
        <div style={{ marginTop: "3rem" }}>
          <BreadCrumbComp data={dataa} />
        </div>
        <MainWrapper>
          <Title>
            create template&nbsp;
            <BsQuestionCircleFill fontSize={15} />
          </Title>
          <p>{values?.schema_name}</p>
          <MainRow>
            <MainCol lg={6}>
              <Input
                placeholder="Max Supply"
                name="max_supply"
                value={values?.max_supply}
                onChange={handleChange}
                onKeyDown={(e) => {
                  if (
                    e.key === "+" ||
                    e.key === "-" ||
                    e.key === "." ||
                    e.key === "e"
                  ) {
                    e.preventDefault();
                  }
                }}
                onBlur={handleBlur}
                type="number"
                min="0"
              />
              <span style={{ color: "#F65D5D", marginTop: "1rem" }}>
                {touched.max_supply && errors.max_supply}
              </span>
              <span>0 means unlimited</span>
              <TextareaDiv>
                <p>Description</p>
                <textarea
                  rows={5}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="description"
                  value={values.description}
                />
              </TextareaDiv>

              <CheckBoxDiv>
                <Checkbox
                  type="checkbox"
                  name="is_burnable"
                  value={values?.is_burnable}
                  onChange={(e) => {
                    setFieldValue("is_burnable", e.target.checked);
                  }}
                >
                  burnable
                </Checkbox>
                <span style={{ color: "#F65D5D", marginTop: "1rem" }}>
                  {touched.is_burnable && errors.is_burnable && "required"}
                </span>
                <br />
                <span>when a nFT is burned, it is destroyed forever</span>
              </CheckBoxDiv>

              <CheckBoxDiv>
                <Checkbox
                  name="is_transferable"
                  value={values?.is_transferable}
                  onChange={(e) => {
                    setFieldValue("is_transferable", e.target.checked);
                  }}
                >
                  transferable
                </Checkbox>
                <span style={{ color: "#F65D5D", marginTop: "1rem" }}>
                  {touched.is_transferable &&
                    errors.is_transferable &&
                    "required"}
                </span>
                <br />
                <span>transferable NFT’s can change their owner</span>
              </CheckBoxDiv>

              <div>
                <Title className="mt-4">attributes</Title>
                {values?.schema_attributes?.map((x, index) => (
                  <>
                    <div key={x._id}>
                      {x.types === "file" ? (
                        <>
                          <label htmlFor="update" style={{ width: "100%" }}>
                            <div className="input-div">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "start",
                                  alignItems: "center",
                                }}
                              >
                                {uploadfileName?.name || (
                                  <span style={{ color: "#c9c9c9" }}>
                                    Upload Image
                                  </span>
                                )}
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "end",
                                  alignItems: "center",
                                }}
                              >
                                <BsCloudUploadFill
                                  fontSize={20}
                                  cursor="pointer"
                                />
                              </div>
                            </div>
                          </label>
                          <input
                            type={`${x.types}`}
                            onChange={(e) => {
                              let update = [...values.schema_attributes];
                              update[index] = {
                                ...update[index],
                                file: e.target.files[0],
                                attribute_value: e.target?.files[0]?.name,
                              };
                              setuploadfileName(e.target?.files[0]);
                              setFieldValue("schema_attributes", update);
                            }}
                            onBlur={() =>
                              setFieldTouched(
                                `schema_attributes.${index}.attribute_value`,
                                true
                              )
                            }
                            id="update"
                            accept="image/*"
                            style={{ display: "none" }}
                          />
                        </>
                      ) : x.types === "boolean" ? (
                        <>
                          <Select
                            placeholder="chose value"
                            style={{ width: "100%" }}
                            onChange={(e) => {
                              let update = [...values.schema_attributes];
                              update[index] = {
                                ...update[index],
                                attribute_value: e,
                              };

                              setFieldValue("schema_attributes", update);
                            }}
                            options={[
                              { value: "true", label: "true" },
                              { value: "false", label: "false" },
                            ]}
                          />
                        </>
                      ) : x.types === "number[]" ? (
                        <>
                          {x?.numbers?.map((item, int) => (
                            <div key={int}>
                              <div className="d-flex gap-3">
                                <Input
                                  type="number"
                                  placeholder="add a number"
                                  min="0"
                                  onWheel={(e) => {
                                    const target = e.target;
                                    target.blur();
                                  }}
                                  onBlur={() =>
                                    setFieldTouched(
                                      `schema_attributes.${index}.numbers[${int}].number`,
                                      true
                                    )
                                  }
                                  value={item.number}
                                  onChange={(e) => {
                                    let update = [...values.schema_attributes];
                                    update[index] = {
                                      ...update[index],
                                      numbers: update[index].numbers.map(
                                        (num, numIndex) => {
                                          if (numIndex === int) {
                                            return {
                                              ...num,
                                              number: e.target.value,
                                            };
                                          }
                                          return num;
                                        }
                                      ),
                                    };

                                    setFieldValue("schema_attributes", update);
                                  }}
                                />

                                {int !== 0 && (
                                  <AiFillDelete
                                    style={{
                                      marginTop: "1.5rem",
                                      cursor: "pointer",
                                    }}
                                    fontSize={15}
                                    onClick={() =>
                                      handleRemoveField(int, index)
                                    }
                                  />
                                )}
                              </div>

                              <span
                                style={{
                                  color: "#F65D5D",
                                  marginTop: "1rem",
                                }}
                              >
                                {touched?.schema_attributes &&
                                  touched?.schema_attributes[index]?.numbers[
                                    int
                                  ] &&
                                  errors[
                                    `schema_attributes.${index}.numbers.${int}.number`
                                  ] &&
                                  errors[
                                    `schema_attributes.${index}.numbers.${int}.number`
                                  ]}
                              </span>
                            </div>
                          ))}
                          <div
                            className="icon"
                            onClick={() => handleAddField(index)}
                          >
                            <BsPlusCircle cursor="pointer" fontSize={15} />
                          </div>
                        </>
                      ) : (
                        <Input
                          placeholder={`${x?.attribute_placeholder}`}
                          type={`${x.types}`}
                          value={
                            values.schema_attributes[index]?.attribute_value
                          }
                          onChange={(e) => {
                            let update = [...values.schema_attributes];
                            update[index] = {
                              ...update[index],
                              attribute_value: e.target.value,
                            };
                            setFieldValue("schema_attributes", update);
                          }}
                          onBlur={() =>
                            setFieldTouched(
                              `schema_attributes.${index}.attribute_value`,
                              true
                            )
                          }
                        />
                      )}

                      <span style={{ color: "#F65D5D", marginTop: "1rem" }}>
                        {touched?.schema_attributes &&
                          touched?.schema_attributes[index]?.attribute_value &&
                          errors[
                            `schema_attributes.${index}.attribute_value`
                          ] &&
                          errors[`schema_attributes.${index}.attribute_value`]}
                      </span>
                      <br />
                      <span>{`type ${x.types}`}</span>
                    </div>
                  </>
                ))}
              </div>
            </MainCol>

            <MainCol lg={2}></MainCol>

            {values?.schema_attributes?.find((x) => x.types == "file") && (
              <MainCol lg={4}>
                <Title>Preview</Title>
                <ImgDiv>
                  {uploadfileName ? (
                    <img
                      src={URL.createObjectURL(uploadfileName)}
                      style={{
                        width: "410px",
                        height: "297px",
                      }}
                    />
                  ) : (
                    <h5>410 x 297</h5>
                  )}
                </ImgDiv>
              </MainCol>
            )}
          </MainRow>

          <BtnWrapper>
            <CancelBtn onClick={() => navigate(-1)}>Cancel</CancelBtn>

            {!account ? (
              <ConnectBtn onClick={showModal}>Connect Wallet</ConnectBtn>
            ) : (
              <CreateSchemaBtn onClick={() => makeTemplate()}>
                {" "}
                Create template
              </CreateSchemaBtn>
            )}
          </BtnWrapper>
        </MainWrapper>
      </MainContainer>
    </div>
  );
};

export default CreateTemplateComp;
