import {
  BtnWrapper,
  CancelBtn,
  FormDiv,
  ModalWrapper,
  Title,
  UpdateBtn,
} from "./elements";
import { MdClose } from "react-icons/md";
import { Form } from "react-bootstrap";
import { useMemo, useState } from "react";
import { CommonUtility, ContractUtility } from "utility";
import { useAppSelector } from "store/store";
import { starshipAbi } from "contract/starship";
import { ToastMessage } from "components/common";
import Loading from "components/common/loader";

const UpdateDropModal = ({ handleCancel, item }) => {
  const { web3, account, chainId } = useAppSelector(
    (state) => state.web3Connect
  );
  const { config } = useAppSelector((state) => state.configData);
  const [price, setprice] = useState(Number);
  const [isLoading, setIsLoading] = useState(false);

  const paymentToken = useMemo(() => {
    const res = ContractUtility.getPaymentToken(
      item.payment_token,
      chainId
    )?.name;
    return res;
  }, [item]);
  const handleChange = (e) => {
    setprice(e.target.value);
  };

  const updatePrice = async () => {
    try {
      setIsLoading(true);
      const starShip = config.STAR_SHIP_ADDRESS;

      const contract = CommonUtility.contract(
        web3,
        starshipAbi,
        starShip[+chainId]
      );
      const adminRole = await contract.methods.ADMIN_ROLE().call();
      const hasRole = await contract.methods.hasRole(adminRole, account).call();
      if (!hasRole) throw "Only admin can update fee";
      const txn = await contract.methods
        .updatePackMintFee(
          item?.drop_id,
          CommonUtility.convertToWei(price, item?.payment_token_decimals)
        )
        .send({ from: account });
      console.log("txn", txn);
      if (txn && txn.code == 4001) throw "User denied the transaction";
      if (!txn.status) throw "Transaction Failed";
      if (txn.status) {
        handleCancel();
        setIsLoading(false);
        ToastMessage("Success!", "Price is updated!!", "success");
      }
    } catch (error) {
      handleCancel();
      setIsLoading(false);
      console.log("error", error);
      if (error?.message) {
        ToastMessage("Error", error?.message, "error");
      } else {
        ToastMessage("Error", error, "error");
      }
    }
  };
  return (
    <ModalWrapper>
      {isLoading && <Loading content={"Loading"} />}
      <MdClose
        cursor="pointer"
        fontSize={22}
        color="#6B6B6B"
        className="close-icon"
        onClick={handleCancel}
      />

      <Title>Update drop price</Title>
      <FormDiv>
        <Form.Label htmlFor="inputPassword5">Currency</Form.Label>
        <br />
        <div className="text">
          <Form.Text id="passwordHelpBlock">{paymentToken}</Form.Text>
        </div>

        <div>
          <Form.Label htmlFor="inputPassword5">Price</Form.Label>
          <Form.Control
            type="number"
            id="number"
            aria-describedby="passwordHelpBlock"
            placeholder="1"
            min={0}
            onChange={handleChange}
          />
        </div>

        <BtnWrapper>
          <CancelBtn onClick={handleCancel}>Cancel</CancelBtn>
          <UpdateBtn disabled={price <= 0} onClick={updatePrice}>
            Update
          </UpdateBtn>
        </BtnWrapper>
      </FormDiv>
    </ModalWrapper>
  );
};

export default UpdateDropModal;
