import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import { useStorageUpload } from "@thirdweb-dev/react"
import openNotification from "components/common/toastMessage/toastMessage"
import { ADD_TO_COLLECTION } from "gql/mutations"
import { GET_COLLECTION, GET_COLLECTIONS, GET_COLLECTION_BY_ID, GET_USER_COLLECTION, USERS_COLLECTIONS } from "gql/queries"
import { useEffect, useState } from "react"
import { useAppSelector } from "store/store"
import { CommonUtility } from "utility"
import { sendFileToIPFS, sendFileToThirdWebIPFS } from "utility/services/ipfsService"
import { AddFavouriteHook, RemoveFavouriteHook } from "./favourites"


export const GetCollectionHook = (id) => {
  const [get_collection, { data, error, loading }] = useLazyQuery(GET_COLLECTION)
  const { web3, account } = useAppSelector(state => state.web3Connect)

  useEffect(() => {
    error && openNotification('Error', error.message, 'error')
  }, [error])

  useEffect(() => {
    web3 && get_collection({ variables: { id: id, userAddress: account } })
  }, [web3])

  return {
    data: data?.get_collection, error, loading
  }
}


export const GetCollectionByIdHook = () => {
  const [get_collection_by_id, { data, error, loading, refetch }] = useLazyQuery(GET_COLLECTION_BY_ID)
  const [collection, setCollection] = useState(null)

  const getCollection = (id, sort, filter) => {
    get_collection_by_id({ variables: { id, sort, filter } })
  }

  //useEffect
  useEffect(() => {
    error && openNotification('Error', error.message, 'error')
    if (data) {
      const collectionObj = data.get_collection_by_id
      const collectionNfts = collectionObj.items.map((item) => {
        return {
          owner: item.owner_address,
          favourites: item.favourites,
          sellNftId: item.sellNft?.id,
          price: item.sellNft?.signed_order.erc20TokenAmount,
          network: item.network_id,
          erc20Token:
            item.sellNft?.signed_order.erc20Token,
          sold:
            item.sellNft?.sold || !item.sellNft,
          token_id: CommonUtility.deStructureNftId(
            item.nft_id
          ).tokenId,
          name: item.name,
          buttonTxt: item.sellNft?.sold
            ? "Sold Out"
            : !item.sellNft
              ? "Not listed"
              : "Details",
          image: item.image,
          nftId: item.nft_id,
          userName: item.user?.name,
          userImage:item.user?.prof_image,
        };
      })
      setCollection({ ...collectionObj, items: collectionNfts })
    }
  }, [error, data])

  return {
    collection, error, loading, getCollection
  }
}

export const GetCollectionsHook = () => {
  const [get_collections, { data, error, loading }] = useLazyQuery(GET_COLLECTIONS)

  const getCollections = (sort, limit) => {
    get_collections({ variables: { sort, limit } })
  }

  useEffect(() => {
    getCollections("volume24h", 2000);
  }, [])

  useEffect(() => {
    error && openNotification('Error', 'Something went wrong', 'error')
  }, [error])

  return {
    data: data ? data.get_collections : [], error, loading, getCollections
  }
}

export const CreateCollectionHook = () => {
  const { mutateAsync: upload } = useStorageUpload();
  const [imageLoading, setImageLoading] = useState(false)
  const [create_collection, { data, loading, error }] = useMutation(ADD_TO_COLLECTION)
  const { collectionData, links } = useAppSelector(state => state.collectionData)
  const create = async () => {
    setImageLoading(true)
    // let featuredImage = await sendFileToIPFS(
    //   collectionData.featuredImage
    // );
    // let bannerImage = await sendFileToIPFS(
    //   collectionData.bannerImage
    // );
    // let logo = await sendFileToIPFS(
    //   collectionData.logo
    // );
    let featuredImage = await sendFileToThirdWebIPFS(
      collectionData.featuredImage,
      upload
    );
    let bannerImage = await sendFileToThirdWebIPFS(
      collectionData.bannerImage,
      upload
    );
    let logo = await sendFileToThirdWebIPFS(
      collectionData.logo,
      upload
    );
    setImageLoading(false)
    create_collection({
      variables: {
        values: {
          ...collectionData,
          featuredImage,
          bannerImage,
          logo,
          links: links.filter(v => v != null),
          status: true
        }
      }
    })
  }

  return {
    data, error, loading: imageLoading || loading, create
  }
}


export const GetUsersCollectionHook = () => {

  const [users_collections, { data, error, loading }] = useLazyQuery(USERS_COLLECTIONS)
  const { web3, account } = useAppSelector(state => state.web3Connect)

  useEffect(() => {
    web3 && users_collections({ variables: { userAddress: account } })
  }, [web3])

  useEffect(() => {
    error && openNotification('Error', 'Something went wrong', 'error')
  }, [error])

  return {
    data: data?.users_collections,
    loading
  }

}