import { useEffect, useRef, useState } from "react";
import {
  CardWrapper,
  ImageWrapper,
  Key,
  MainCardWrapper,
  Value,
} from "./element";
import { Card } from "react-bootstrap";
import { Modal } from "antd";
import { Dropdown, Menu } from "antd";
import { BiDotsHorizontalRounded, BiInfoCircle } from "react-icons/bi";
import { MdModeEditOutline } from "react-icons/md";
import { FaUser } from "react-icons/fa";
import UpdateDropModal from "components/modal/updateDropModal";
import { GetDropMintsHook, GetDropsHook } from "hooks/dropHook";
import { CommonUtility, ContractUtility, DateUtility } from "utility";
import Loading from "components/common/loader";
import ViewOpenerModal from "components/modal/viewOpenerModal";
import { useAppSelector } from "store/store";
const Dropcomp = () => {
  const [page, setPage] = useState(30);
  let showAlldrop = "showAlldrops";
  const { data, loading } = GetDropsHook(page, showAlldrop);
  const { data: dropMints } = GetDropMintsHook();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpenModalOpen, setIsOpenModalOpen] = useState(false);
  const [openers, setOpeners] = useState([]);
  const [currentTime, setCurrentTime] = useState(Number);
  const { config } = useAppSelector((state) => state.configData);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(Math.floor(DateUtility.currentTime()));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const showOpenModal = (itemId) => {
    const itemData = calculateTotalDropAmountsByWallet(dropMints, itemId);
    setOpeners(itemData);
    setIsOpenModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setIsOpenModalOpen(false);
  };
  const calculateTotalDropAmountsByWallet = (data, targetDropId) => {
    const sumByWallet = {};
    data?.forEach((item) => {
      const { wallet_address, drop_amounts, drop_id } = item;
      if (drop_id === targetDropId) {
        if (sumByWallet[wallet_address]) {
          sumByWallet[wallet_address] += drop_amounts;
        } else {
          sumByWallet[wallet_address] = drop_amounts;
        }
      }
    });
    const result = Object.entries(sumByWallet).map(
      ([wallet_address, total]) => ({
        wallet_address,
        drop_amounts: total,
      })
    );
    return result;
  };
  const menu = (item, cardState) => {
    const menuItems = [];
    if (cardState === "ended") {
      menuItems.push({
        key: "1",
        label: (
          <div className="items-wrapper disabled">
            <p className="items-text">update drop price</p>
            <MdModeEditOutline fontSize={20} />
          </div>
        ),
        disabled: true,
      });
    } else {
      menuItems.push({
        key: "1",
        label: (
          <div className="items-wrapper" onClick={showModal}>
            <p className="items-text">update drop price</p>
            <MdModeEditOutline fontSize={20} />
          </div>
        ),
      });
    }

    if (cardState === "upcoming") {
      menuItems.push({
        key: "2",
        label: (
          <div className="items-wrapper disabled">
            <p className="items-text">view openers</p>
            <FaUser fontSize={18} />
          </div>
        ),
        disabled: true,
      });
    } else {
      menuItems.push({
        key: "2",
        label: (
          <div
            className="items-wrapper"
            onClick={() => showOpenModal(item?.drop_id)}
          >
            <p className="items-text">view openers</p>
            <FaUser fontSize={18} />
          </div>
        ),
      });
    }

    return <Menu items={menuItems} />;
  };

  const cardContentRef = useRef(null);
  const [cardContentVisibility, setCardContentVisibility] = useState([]);
  const handleInfoClick = (index) => {
    const newVisibility = [...cardContentVisibility];
    newVisibility[index] = !newVisibility[index];
    setCardContentVisibility(newVisibility);
  };
  return (
    <div>
      {loading && <Loading content={"Getting Data"} />}
      <MainCardWrapper>
        {data?.map((item, index) => {
          let cardState = "live";

          if (currentTime <= item?.start_time) {
            cardState = "upcoming";
          } else if (
            currentTime >= item?.end_time ||
            +item?.currentSupply >= +item?.max_supply
          ) {
            cardState = "ended";
          }

          return (
            <>
              <CardWrapper key={item.id}>
                <Card
                  className={
                    currentTime <= item?.start_time ||
                    currentTime >= item?.end_time
                      ? "hover-card"
                      : ""
                  }
                >
                  <div
                    className="d-flex justify-content-between align-items-center "
                    style={{ padding: "0rem 1rem" }}
                  >
                    <div>
                      <BiInfoCircle
                        cursor="pointer"
                        onClick={() => handleInfoClick(index)}
                      />
                    </div>

                    <div>
                      <Dropdown
                        overlay={() => menu(item, cardState)}
                        trigger={["click"]}
                        placement="bottomRight"
                        overlayStyle={{ width: "200px" }}
                      >
                        <a onClick={(e) => e.preventDefault()}>
                          <BiDotsHorizontalRounded />
                        </a>
                      </Dropdown>
                    </div>
                  </div>
                  {cardContentVisibility[index] ? (
                    <>
                      <div className="content-main-dev" ref={cardContentRef}>
                        <div className="content-wrapper">
                          <Key>Title:</Key>
                          <Value>{item?.name}</Value>
                        </div>

                        <div className="content-wrapper">
                          <Key>Pack Name:</Key>
                          <Value>{item?.pack_id?.name}</Value>
                        </div>

                        {/* <div className="content-wrapper">
                          <Key>Is Secure:</Key>
                          <Value>{item?.is_secure ? "Yes" : "No"}</Value>
                        </div>
                        <div className="content-wrapper">
                          <Key>Is hidden:</Key>
                          <Value>{item?.is_hidden ? "Yes" : "No"}</Value>
                        </div> */}
                        <div className="content-wrapper">
                          <Key>Drop id:</Key>
                          <Value>{item?.drop_id}</Value>
                        </div>
                        <div className="content-wrapper">
                          <Key>Price:</Key>
                          <Value>
                            {item?.price > 0
                              ? `${CommonUtility.convertFromWei(
                                  +item?.price,
                                  item?.payment_token_decimals,
                                  false
                                )} ${
                                  ContractUtility.getPaymentToken(
                                    item?.payment_token,
                                    item?.chain_id
                                  )?.symbol
                                }`
                              : "Free"}
                          </Value>
                        </div>
                        <div className="content-wrapper">
                          <Key>Issued:</Key>
                          <Value>
                            {item?.currentSupply} /
                            {+item.max_supply == +config?.UINT256_MAX ||
                            +item.max_supply == 0
                              ? "∞"
                              : item.max_supply}
                          </Value>
                        </div>
                        <div className="content-wrapper">
                          <Key>Purchasable Nft (upto):</Key>
                          <Value>{item?.nfts_amount}</Value>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <ImageWrapper className="img-wrapper">
                        <Card.Img src={item.image} width={231} height={253} />
                        <div
                          className={
                            currentTime <= item?.start_time ||
                            currentTime >= item?.end_time ||
                            +item?.currentSupply >= +item?.max_supply
                              ? "overlay"
                              : ""
                          }
                        >
                          {currentTime <= item?.start_time ? (
                            <h4 className="hover-text">Upcoming</h4>
                          ) : (
                            (currentTime >= item?.end_time ||
                              +item?.currentSupply >= +item?.max_supply) && (
                              <h4 className="hover-text">Ended</h4>
                            )
                          )}
                        </div>
                      </ImageWrapper>
                      <Card.Body>
                        <Card.Title>{item.name}</Card.Title>
                        <Card.Text>{item?.pack_id?.name}</Card.Text>
                        <h5>
                          {item?.price > 0
                            ? `${CommonUtility.convertFromWei(
                                +item?.price,
                                item?.payment_token_decimals,
                                false
                              )} ${
                                ContractUtility.getPaymentToken(
                                  item?.payment_token,
                                  item?.chain_id
                                )?.symbol
                              }`
                            : "Free"}
                        </h5>
                      </Card.Body>
                    </>
                  )}
                </Card>
              </CardWrapper>

              <Modal
                open={isModalOpen}
                onCancel={handleCancel}
                centered
                width={481}
              >
                <UpdateDropModal handleCancel={handleCancel} item={item} />
              </Modal>
              <Modal open={isOpenModalOpen} centered width={880}>
                <ViewOpenerModal
                  handleCancel={handleCancel}
                  openers={openers}
                />
              </Modal>
            </>
          );
        })}
      </MainCardWrapper>
    </div>
  );
};

export default Dropcomp;
