import styled from "styled-components";

export const ModalWrapper = styled.div`
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid #00b7ff;
  /* background: #fff; */
  background-color: ${({ theme }) => theme.tablebgColor} !important;
  padding: 1rem;
  padding-bottom: 2rem;
`;

export const HeaderTitle = styled.h5`
  color: var(--secondary, #3e3e3e);
  font-family: Barlow;
  font-size: 1.56644rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
`;
