import { AiOutlineWarning } from "react-icons/ai";
import { BorderDiv, ModalWrapper } from "./element";
import { MdClose } from "react-icons/md";
import ReactPlayer from "react-player/lazy";
import { useState, useEffect } from "react";

function PreviewVideoModal({ handleCancel, selectedFile, video_url }) {
  return (
    <ModalWrapper>
      <MdClose
        cursor="pointer"
        fontSize={22}
        color="#6B6B6B"
        className="close-icon"
        onClick={handleCancel}
      />

      {selectedFile || video_url ? (
        <BorderDiv>
          <ReactPlayer
            controls
            url={video_url ? video_url : URL.createObjectURL(selectedFile)}
            width={"100%"}
            height={"100%"}
          />
        </BorderDiv>
      ) : (
        <p className="error">
          <AiOutlineWarning
            style={{ marginRight: "10px", marginBottom: "5px" }}
          />
          Please upload video first{" "}
        </p>
      )}
    </ModalWrapper>
  );
}

export default PreviewVideoModal;
