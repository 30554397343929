import { APIPath } from "utility/constant";
import { BaseService } from "./baseServices";

class Activity {
  userActivity = (wallet) => {
    return BaseService.get(`${APIPath.userActivity}/${wallet}`);
  };
}

const ActivityService = new Activity();
Object.freeze(ActivityService);
export { ActivityService };
