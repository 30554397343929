import styled from "styled-components";

export const MainWrapper = styled.div`
  overflow: hidden;

  .whole-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 991.98px) {
      flex-direction: column;
      justify-content: center;
      gap: 2rem;
    }
  }
`;

export const BreadCrumbDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;

  .arrow-icon {
    color: #c9c9c9;
    font-size: 22px;
  }

  .inner-div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
  }
`;

export const BreadCrumbText = styled.h4`
  color: var(--pimary, #00b7ff);

  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  margin: 0;

  &.last-text {
    color: #c9c9c9;
  }
`;

export const AddBtn = styled.button`
  color: #fff;
  font-family: "Barlow", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  border-radius: 1.75rem;
  background: var(--pimary, #00b7ff);
  width: 133px;
  height: 35px;
`;
