import { APIPath } from "utility/constant";
import { BaseService } from "./baseServices";

class Mint {
  nftsByUser = (account) => {
    return BaseService.get(`${APIPath.nftsByUser}/${account}`);
  };

  deleteMint = (id) => {
    return BaseService.delete(`${APIPath.deleteMint}/${id}`);
  };

  getMints = (page, filter) => {
    return BaseService.get(`${APIPath.getMints}?page=${page}&filter=${filter}`);
  };

  createOrder = (data) => {
    return BaseService.post(`${APIPath.createOrder}`, data);
  };
  createOffer = (data) => {
    return BaseService.post(`${APIPath.createOffer}`, data);
  };

  getMintsMostSold = () => {
    return BaseService.get(APIPath.getMintsMostSold);
  };
  delist = (id) => {
    return BaseService.delete(`${APIPath.delist}/${id}`);
  };
  decline = (data) => {
    return BaseService.put(`${APIPath.decline}`, data);
  };
  getAllMints = () => {
    return BaseService.get(APIPath.getAllMints);
  };
  nftsByHash = (hash) => {
    return BaseService.get(`${APIPath.getNftsByHash}/${hash}`);
  };
  countOccurences = (data) => {
    return BaseService.post(`${APIPath.countOccurences}`, data);
  };
}

const MintService = new Mint();
Object.freeze(MintService);
export { MintService };
