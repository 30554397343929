import StarshipAdminComp from "components/starship/admin";
import React from "react";

const StarshipAdminPage = () => {
  return (
    <div>
      <StarshipAdminComp />
    </div>
  );
};

export default StarshipAdminPage;
