import { CheckNameHook, UpdateMintStatusHook } from "hooks";
import { CreateTransactionHook } from "hooks";
import { useState, useEffect } from "react";
import { useAppSelector } from "store/store";
import EthContract from "web3-eth-contract";
import { getCurrentTokenIdWeb3 } from "./read";
import openNotification from "components/common/toastMessage/toastMessage";
import { CommonUtility } from "utility";

export const MintNFTWeb3Hook = (contract: any, account: string) => {
  const [hash, sethash] = useState("");
  const [status, setStatus] = useState(false);
  const { updateStatus, data } = UpdateMintStatusHook();
  const { nft, mintNftResult } = useAppSelector((state) => state.nftData);
  const { nftMintingContractAddress } = useAppSelector(
    (state) => state.web3Connect
  );
  const { createTransaction } = CreateTransactionHook();

  useEffect(() => {
    if (data) {
      setStatus(false);
      openNotification("Minted", "Successful", "success");
    }
  }, [data]);

  useEffect(() => {
    status &&
      createTransaction({
        nft_id: mintNftResult?.nft_id,
        amount: "0",
        transaction_hash: hash,
        network_id: mintNftResult?.network_id,
        status: "MINTED",
      });
  }, [status]);

  const mint = async (setMintLoading, error) => {
    try {
      if (!error) {
        const tokenId = await getCurrentTokenIdWeb3(contract);
        try {
          const result = await contract?.methods
            .mintNFT()
            .send({ from: account })
            .on("transactionHash", async (hash) => {
              sethash(hash);
            });

          if (result.status) {
            setStatus(true);
            updateStatus(
              CommonUtility.makeNftId(nftMintingContractAddress, +tokenId + 1)
            );
            setMintLoading(false);
          } else {
            setMintLoading(false);
            openNotification("Error", "Transaction Failed", "warning");
          }
        } catch (error) {
          setMintLoading(false);
          openNotification("Error", "Transaction Failed", "warning");
        }
      }
    } catch (err) {
      console.log("error", err);
      setMintLoading(false);
      openNotification("Error", "Transaction Failed", "warning");
      return err;
    }
  };

  return {
    hash,
    mint,
  };
};

export const setApprovalForAllWeb3 = async (
  contract: EthContract.Contract,
  account: string,
  operator: string
) => {
  try {
    const result = await contract?.methods
      .setApprovalForAll(operator, true)
      .send({ from: account });
    return result;
  } catch (err) {
    console.log("err", err);
    return err;
  }
};
