import StarshipNavbar from "components/starship/navbar";

const MainNavbar = () => {
  return (
    <div>
      <StarshipNavbar />
    </div>
  );
};

export default MainNavbar;
