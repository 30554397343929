import { gql } from "@apollo/client";

const CREATE_NFT = gql`
  mutation create_nft($values: NftInput) {
    create_nft(values: $values) {
      name
      id
      description
      image
      nft_id
      total_supply
      owner_address
      price
      network_id
      external_link
      transaction_hash
    }
  }
`;

const SELL_NFT = gql`
  mutation sell_nft($values: SellNftInput!) {
    sell_nft(values: $values) {
      nft_id
    }
  }
`;

const ADD_TRANSACTION = gql`
  mutation add_transaction($values: TransactionInput!) {
    add_transaction(values: $values) {
      id
      transaction_hash
      network_id
      nft_id
    }
  }
`;

const ADD_REPORT = gql`
  mutation add_report($values: ReportInput!) {
    add_report(values: $values) {
      id
      nft_id
      wallet_address
      message
    }
  }
`;

const ADD_VIEW = gql`
  mutation add_view($values: ViewsInput!) {
    add_view(values: $values) {
      id
      view_count
      ip_address
      nft_id
      network_id
    }
  }
`;

const ADD_PAYMENT_CONFIG = gql`
  mutation add_payment_config($values: PaymentConfigInput!) {
    add_payment_config(values: $values) {
      id
      token_address
      decimals
      network_id
    }
  }
`;

const UPDATE_PAYMENT_CONFIG = gql`
  mutation update_payment_config($id: ID, $values: PaymentConfigInput!) {
    update_payment_config(id: $id, values: $values) {
      updated
      updatedData
    }
  }
`;

const UPDATE_USERNAME = gql`
  mutation update_username($username: String!) {
    update_username(username: $username) {
      updated
      updatedData
    }
  }
`;

const UPDATE_PASSWORD = gql`
  mutation update_password($currentPassword: String!, $newPassword: String!) {
    update_password(
      current_password: $currentPassword
      new_password: $newPassword
    ) {
      updated
      updatedData
    }
  }
`;

const ADD_FAVOURITE = gql`
  mutation add_favourite($values: FavouritesInput!) {
    add_favourite(values: $values) {
      id
      user_address
      nft_id
    }
  }
`;

const REMOVE_FAVOURITE = gql`
  mutation remove_favourite($id: ID) {
    remove_favourite(id: $id) {
      deleted_count
      deleted
    }
  }
`;

const ADD_TO_BLACKLIST = gql`
  mutation add_blacklist_token {
    add_blacklist_token {
      id
      token
      iat
      exp
    }
  }
`;

const ADD_TO_COLLECTION = gql`
  mutation create_collection($values: CollectionInput) {
    create_collection(values: $values) {
      name
      description
      network
      logo
      category
      payment_token
      links
      featured_image
      banner_image
    }
  }
`;

const CREATE_USER = gql`
  mutation create_user($values: UserInput!) {
    create_user(values: $values) {
      id
      name
      wallet_address
      bio
      prof_image
      cover_image
      links {
        twitter
        instagram
        website
      }
      email
    }
  }
`;

const UPDATE_USER = gql`
  mutation update_user($values: UserInput) {
    update_user(values: $values) {
      updated
      updatedData
    }
  }
`;

const CREATE_CATEGORY = gql`
  mutation create_category($values: CategoryInput) {
    create_category(values: $values) {
      name
      instructions
      id
    }
  }
`;

const UPDATE_CATEGORY = gql`
  mutation update_category($id: ID, $values: CategoryInput) {
    update_category(id: $id, values: $values) {
      updated
      updatedData
    }
  }
`;

const REMOVE_CATEGORY = gql`
  mutation remove_category($id: ID) {
    remove_category(id: $id) {
      deleted
      deleted_count
    }
  }
`;

const UPDATE_MINT_STATUS = gql`
  mutation update_mint_status($nftId: String) {
    update_mint_status(nftId: $nftId) {
      updated
      updatedData
    }
  }
`;

const UPDATE_SELL_NFT = gql`
  mutation update_sell_nft($id: ID!, $nftId: String, $txHash: String) {
    update_sell_nft(id: $id, nftId: $nftId, txHash: $txHash) {
      updated
      updatedData
    }
  }
`;

const DELETE_SELL_NFT = gql`
  mutation delete_sell_nft($id: ID!) {
    delete_sell_nft(id: $id) {
      deleted
      deleted_count
    }
  }
`;

const CREATE_REWARD = gql`
  mutation create_reward($values: RewardInput!) {
    create_reward(values: $values) {
      referrer
      amount
      nft_id
      sell_nft_id
      referee
    }
  }
`;

export {
  CREATE_NFT,
  SELL_NFT,
  ADD_PAYMENT_CONFIG,
  ADD_REPORT,
  ADD_TRANSACTION,
  REMOVE_FAVOURITE,
  ADD_VIEW,
  UPDATE_PASSWORD,
  CREATE_USER,
  UPDATE_USERNAME,
  UPDATE_PAYMENT_CONFIG,
  ADD_FAVOURITE,
  ADD_TO_BLACKLIST,
  ADD_TO_COLLECTION,
  REMOVE_CATEGORY,
  CREATE_CATEGORY,
  UPDATE_CATEGORY,
  UPDATE_USER,
  UPDATE_MINT_STATUS,
  UPDATE_SELL_NFT,
  DELETE_SELL_NFT,
  CREATE_REWARD,
};
