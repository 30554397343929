import { useEffect, useState } from "react";
import {
  BreadCrumbComp,
  MainCol,
  MainContainer,
  MainRow,
} from "components/common";
import {
  AddAttributeBtn,
  BtnWrapper,
  CancelBtn,
  CreateSchemaBtn,
  InputTitle,
  InputWrapper,
} from "./elements";
import { BsQuestionCircleFill } from "react-icons/bs";
import { Input, Modal, Select } from "antd";
import { AiFillLock, AiOutlinePlus } from "react-icons/ai";
import { RiCloseCircleFill } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CreateSchemaHook,
  GetCreateSchemaByIdHook,
} from "hooks/createSchemaHook";
import { useFormik } from "formik";
import { StarShipConnectWalletModal } from "components/modal";
import { useAppSelector } from "store/store";
import { ConnectBtn } from "../navbar/elements";
import Loading from "components/common/loader";
import ConnectModals from "components/modal/connectModal";
import { Tooltip } from "antd";

const dataa = [
  {
    id: 1,
    text: "Starship NFT Game",
    link: "/admin/starship",
  },
  {
    id: 2,
    text: "Schemas and templates",
    link: "/starship/admin/schemas",
  },
  {
    id: 3,
    text: "Create schema",
    link: "",
  },
];

const CreateSchemaComp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryString = location.search;

  const searchParams = new URLSearchParams(queryString);
  const id = searchParams.get("id");

  const onSearch = (value) => {
    console.log("search:", value);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [attributes, setAttributes] = useState([
    { attribute_placeholder: "", types: "", id: 0 },
    {
      attribute_placeholder: "",
      types: "",
      id: 1,
    },
  ]);

  const handleAddAttribute = () => {
    setAttributes([
      ...attributes,
      {
        attribute_placeholder: "",
        types: "",
        id: attributes?.length + 1,
      },
    ]);

    setFieldValue("schema_attributes", [
      ...values.schema_attributes,
      {
        attribute_placeholder: "",
        types: "",
        id: attributes?.length + 1,
      },
    ]);
  };

  const { createSchema, loading } = CreateSchemaHook();
  const { data } = GetCreateSchemaByIdHook(id);

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    setFieldValue,
    setFieldTouched,
  } = useFormik({
    initialValues: {
      schema_name: "",

      schema_attributes: [
        {
          attribute_placeholder: "Name",
          types: "text",
          id: 0,
        },
        {
          attribute_placeholder: "Image",
          types: "file",
          id: 1,
        },
      ],
    },
    validate: (values) => {
      const errors = {};

      if (!values.schema_name) {
        errors.schema_name = "Schema name is required.";
      }

      values.schema_attributes.forEach((attribute, index) => {
        if (!attribute.attribute_placeholder.trim()) {
          errors[`schema_attributes.${index}.attribute_placeholder`] =
            "Attribute placeholder is required.";
        }

        if (!attribute.types.trim()) {
          errors[`schema_attributes.${index}.types`] = "Types is required.";
        }
      });

      return errors;
    },
    onSubmit: async (values) => {
      if (id) {
        values.update_schema = id;
      }

      createSchema(values);
    },
  });

  const handleDeleteAttribute = (id) => {
    const updatedAttributes = attributes.filter(
      (attribute) => attribute.id !== id
    );
    setFieldValue("schema_attributes", updatedAttributes);
    setAttributes(updatedAttributes);
  };

  useEffect(() => {
    if (data) {
      setFieldValue("schema_name", data?.schema_name);
      let attributes1 = data.schema_attributes.map((x, index) => {
        return {
          ...x,
          id: index + 1,
        };
      });

      setFieldValue("schema_attributes", attributes1);
      setAttributes(attributes1);
    }
  }, [data]);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const { account } = useAppSelector((state) => state.web3Connect);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <div>
      {loading && <Loading content={"Creating Schema"} />}
      <Modal open={isModalOpen} centered onCancel={handleCancel} width={504}>
        <ConnectModals onCancel={handleCancel} />
      </Modal>
      {/* <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        width={"489px"}
      >
        <StarShipConnectWalletModal
          handleCancel={handleCancel}
          setIsModalOpen={setIsModalOpen}
        />
      </Modal> */}
      <MainContainer fluid>
        <div style={{ marginTop: "3rem" }}>
          <BreadCrumbComp data={dataa} />
        </div>

        <InputWrapper>
          <MainRow>
            <InputTitle>
              Create Schema&nbsp; &nbsp;
              <Tooltip placement="top" title={"Create Schemas"}>
                <BsQuestionCircleFill fontSize={12} />
              </Tooltip>
            </InputTitle>
            <MainCol lg={12}>
              <div className="d-flex align-items-center gap-3">
                <Input
                  placeholder="schema Name"
                  name="schema_name"
                  value={values?.schema_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <BsQuestionCircleFill
                  fontSize={20}
                  style={{ marginTop: "1rem" }}
                />
              </div>
              <span style={{ color: "#F65D5D", marginTop: "1rem" }}>
                {touched.schema_name && errors.schema_name && "required"}
              </span>
            </MainCol>
          </MainRow>

          {values?.schema_attributes?.map((attribute, index) => (
            <MainRow key={attribute.id}>
              <InputTitle>
                {index == 0
                  ? `Predefined Attributes `
                  : index == !1 && "New Attributes"}{" "}
              </InputTitle>

              <MainCol lg={6}>
                <p>Attribute name</p>
                <Input
                  placeholder={index == 0 ? "name" : "Image"}
                  value={values.schema_attributes[index]?.attribute_placeholder}
                  onChange={(e) => {
                    if (index !== 0) {
                      let update = [...values?.schema_attributes];
                      update[index] = {
                        ...update[index],
                        attribute_placeholder: e.target.value,
                      };

                      setFieldValue("schema_attributes", update);
                      setAttributes(update);
                    }
                  }}
                  onBlur={() =>
                    setFieldTouched(
                      `schema_attributes.${index}.attribute_placeholder`,
                      true
                    )
                  }
                />

                <span style={{ color: "#F65D5D", marginTop: "1rem" }}>
                  {touched?.schema_attributes &&
                    touched?.schema_attributes[index]?.attribute_placeholder &&
                    errors[
                      `schema_attributes.${index}.attribute_placeholder`
                    ] &&
                    "required"}
                </span>
              </MainCol>

              <MainCol lg={6}>
                <p>type</p>
                <div className="d-flex align-items-center gap-3">
                  <Select
                    bordered={false}
                    showSearch
                    placeholder="select"
                    optionFilterProp="children"
                    value={
                      values.schema_attributes[index]?.types || `Select type`
                    }
                    onChange={(e) => {
                      let update = [...values?.schema_attributes];
                      update[index] = {
                        ...update[index],
                        types: e,
                      };
                      setAttributes(update);
                      setFieldValue("schema_attributes", update);
                    }}
                    onBlur={() =>
                      setFieldTouched(`schema_attributes.${index}.types`, true)
                    }
                    onSearch={onSearch}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={
                      index === 0 || index === 1
                        ? []
                        : [
                            // Conditionally render options based on index
                            {
                              value: "text",
                              label: "Text",
                            },
                            {
                              value: "number",
                              label: "Uint64",
                            },
                            {
                              value: "number[]",
                              label: "Uint64[]",
                            },
                            {
                              value: "boolean",
                              label: "Bool",
                            },
                          ]
                    }
                  />

                  {index == 0 || index == 1 ? (
                    <AiFillLock fontSize={20} style={{ marginTop: "1rem" }} />
                  ) : (
                    <RiCloseCircleFill
                      fontSize={20}
                      style={{ marginTop: "1rem", cursor: "pointer" }}
                      onClick={() => handleDeleteAttribute(attribute.id)}
                    />
                  )}
                </div>
                <span style={{ color: "#F65D5D", marginTop: "1rem" }}>
                  {touched?.schema_attributes &&
                    touched?.schema_attributes[index]?.types &&
                    errors[`schema_attributes.${index}.types`] &&
                    "required"}
                </span>
              </MainCol>
            </MainRow>
          ))}
        </InputWrapper>

        <AddAttributeBtn onClick={handleAddAttribute}>
          <AiOutlinePlus />
          &nbsp;&nbsp;Add attribute
        </AddAttributeBtn>

        <BtnWrapper>
          <CancelBtn
            onClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </CancelBtn>

          {account ? (
            <CreateSchemaBtn
              disabled={
                Object?.keys(errors)?.length > 0 ||
                !values.schema_name ||
                !values.schema_attributes?.find(
                  (x) => x.attribute_placeholder || x.types
                )
              }
              onClick={() => {
                handleSubmit();
              }}
            >
              {id ? "Update Schema" : "Create Schema"}
            </CreateSchemaBtn>
          ) : (
            <ConnectBtn onClick={showModal}>Connect Wallet</ConnectBtn>
          )}
        </BtnWrapper>
      </MainContainer>
    </div>
  );
};

export default CreateSchemaComp;
