import styled from "styled-components";

export const ModalWrapper = styled.div`
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid #e7e7e7;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27);
  padding: 1rem 2rem 2rem 2rem;
  /* min-height: 166px; */
  position: relative;

  .close-icon {
    position: absolute;
    top: 5px;
    right: 10px;
  }

  .text {
    border-radius: 0.625rem;
    border: 1px solid #e7e7e7;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27);
    width: 100%;
    height: 2.9375rem;
    display: flex;
    justify-content: start;
    padding: 0px 10px;
    align-items: center;
  }
`;

export const Title = styled.h5`
  color: var(--secondary, #3e3e3e);

  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem; /* 120% */
  text-transform: capitalize;
`;

export const FormDiv = styled.div`
  .form-label {
    color: #6b6b6b;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    margin-top: 1.5rem;
  }

  .form-text {
    color: #6b6b6b;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    margin-top: 0rem;
  }

  .form-control {
    border-radius: 0.625rem;
    border: 1px solid #e7e7e7;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27);
    color: #6b6b6b;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;

    &::placeholder {
      color: #6b6b6b;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      text-transform: capitalize;
    }
  }
`;

export const BtnWrapper = styled.div`
  display: flex;
  gap: 1.5rem;
  justify-content: end;
  align-items: center;
  margin-top: 2rem;
`;
export const CancelBtn = styled.button`
  color: var(--secondary, #3e3e3e);
  border: none;
  background-color: transparent;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
`;
export const UpdateBtn = styled.button`
  color: #fff;
  font-family: Barlow;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  border-radius: 1.75rem;
  background: var(--pimary, #00b7ff);
  width: 5.5625rem;
  height: 1.5rem;
`;
