import { Image } from "react-bootstrap";
import {
  ButtonsDiv,
  ConnectWalletBtn,
  MainHeader,
  ModalWrapper,
  TermsAndConditionText,
} from "./elements";
import { basewallet, metamask, walletconnect } from "assets";
import {
  loadBlockchain,
  loadWalletConnect,
} from "store/redux/slices/web3/connectWeb3Slice";
import { useAppDispatch } from "store/store";

function AdminloginComp() {
  const dispatch = useAppDispatch();

  const handleWeb3MetaMask = async () => {
    dispatch(loadBlockchain());
  };

  const handleWeb3WalletConnect = async () => {
    dispatch(loadWalletConnect());
  };

  return (
    <ModalWrapper>
      <MainHeader>Connect A Wallet</MainHeader>

      <ButtonsDiv>
        <ConnectWalletBtn onClick={() => handleWeb3MetaMask()}>
          <p>Metamask</p>
          <Image src={metamask} />
        </ConnectWalletBtn>
        {/* <ConnectWalletBtn>
          <p>Coinbase Wallet</p>
          <Image src={basewallet} />
        </ConnectWalletBtn> */}
        <ConnectWalletBtn onClick={() => handleWeb3WalletConnect()}>
          <p>WalletConnect</p>
          <Image src={walletconnect} />
        </ConnectWalletBtn>
      </ButtonsDiv>

      <TermsAndConditionText>
        By connecting a wallet you agree to our
        <span> Terms and Conditions</span>.
      </TermsAndConditionText>
    </ModalWrapper>
  );
}

export default AdminloginComp;
