import React from "react";
import { Dashboard, ReportsTable } from "components";
import { Loader } from "components/common";
import { GetReportsHook } from "hooks";

const ReportsListing = () => {
  const { data, error, loading } = GetReportsHook();

  return (
    <>
      <div>
        {loading ? <Loader content="Loading Reports" /> : ""}

        {data ? <ReportsTable data={data.get_reports} /> : ""}
      </div>
    </>
  );
};

export default ReportsListing;
