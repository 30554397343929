import styled from "styled-components";

export const PacksWrapper = styled.div`
  overflow: hidden;

  .container-fluid {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
`;

export const MainHeader = styled.h2`
  color: #3e3e3e;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-transform: capitalize;
  margin-top: 4rem;

  &.no-margin {
    margin: 0;

    @media (max-width: 991.98px) {
      margin-top: 2rem;
    }
  }
`;

export const TabContainer = styled.div`
  margin-top: 4rem;

  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
    border-radius: 10px;
    border: 1px solid #e7e7e7;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27);
    padding-top: 0.5rem;
    padding-bottom: 1.5rem;
  }

  .ant-tabs-bottom > .ant-tabs-nav:before,
  .ant-tabs-bottom > div > .ant-tabs-nav:before,
  .ant-tabs-top > .ant-tabs-nav:before,
  .ant-tabs-top > div > .ant-tabs-nav:before {
    border-bottom: none;
  }

  .ant-tabs-tab {
    color: #4d1ce8;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-transform: capitalize;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--pimary, #00b7ff);
  }

  .ant-tabs-ink-bar {
    background: #00b7ff !important;
  }
`;

export const NoPacksFoundDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;

  h4 {
    color: #3e3e3e;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-transform: capitalize;
  }

  p {
    color: #6b6b6b;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
  }
`;

export const CreatePackBtn = styled.button`
  color: #fff;
  font-family: "Barlow", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  border-radius: 1.75rem;
  background: var(--pimary, #00b7ff);
  width: 133px;
  height: 35px;
`;

export const TableWrapper = styled.div``;
