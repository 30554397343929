import { BreadCrumbComp, MainContainer } from "components/common";
import { PacksWrapper, TabContainer } from "./element";
import { Tabs } from "antd";
import PacksTabCom from "./packsTabCom";
import { useEffect, useState } from "react";
import { GetPackHook } from "hooks/packHook";
import Loading from "components/common/loader";

const data = [
  {
    id: 1,
    text: "Starship NFT Game",
    link: "/admin/starship",
  },
  {
    id: 2,
    text: "Packs",
    link: "",
  },
];

function PacksCom() {
  const [cardsData, setCardsData] = useState(1);
  const [page, setPage] = useState(30);
  const { data: packs, loading, total, getPacks } = GetPackHook(page);
  const handleScroll = () => {
    const scrollPosition = window.innerHeight + window.pageYOffset;
    const scrollHeight = document.documentElement.scrollHeight;
    if (scrollPosition >= scrollHeight - 180 && !loading) {
      if (page < total) {
        setPage(page + 20);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [packs, page, total, loading]);

  const items = [
    {
      label: "Packs",
      key: "item-1",
      children: (
        <PacksTabCom
          cardsData={packs?.length}
          setCardsData={setCardsData}
          packs={packs}
          getPacks={getPacks}
        />
      ),
    },
  ];

  return (
    <PacksWrapper>
      {loading && <Loading content={"Loading"} />}
      <MainContainer fluid>
        <BreadCrumbComp data={data} btnText="Add Pack" />
        <TabContainer>
          <Tabs items={items} centered />
        </TabContainer>
      </MainContainer>
    </PacksWrapper>
  );
}

export default PacksCom;
