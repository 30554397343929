import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { ToastMessage } from "components/common";
import openNotification from "components/common/toastMessage/toastMessage";
import {
  CREATE_NFT,
  DELETE_SELL_NFT,
  SELL_NFT,
  UPDATE_MINT_STATUS,
  UPDATE_SELL_NFT,
} from "gql/mutations";
import {
  CHECK_NAME,
  GET_ALL_NFTS,
  GET_NFT_BY_TOKEN_ID,
  GET_SELL_NFT,
  GET_SELL_NFTS,
  NFTS_BY_NFT_IDS,
  TOP_VIEWED_NFTS,
  USER_SELL_NFT,
} from "gql/queries";
import { useEffect, useState } from "react";
import {
  createSellNftData,
  sellNftData,
  setMintNft,
} from "store/redux/slices/nftSlices/nftDataSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { CommonUtility } from "utility";
import { CreateTransactionHook } from "./transactions";

export const GetAllNftsHook = () => {
  const { data, error, loading } = useQuery(GET_ALL_NFTS);
  return {
    data,
    error,
    loading,
  };
};

export const CheckNameHook = () => {
  const [check_name, { data, error, loading }] = useLazyQuery(CHECK_NAME);

  return {
    error,
    loading,
    check_name,
  };
};

export const SellNftHook = () => {
  const [sell_nft, { data, loading, error }] = useMutation(SELL_NFT);
  const { createSellNft } = useAppSelector((state) => state.nftData);
  const { chainId } = useAppSelector((state) => state.web3Connect);
  const dispatch = useAppDispatch();

  useEffect(() => {
    data &&
      dispatch(
        createSellNftData({
          ...createSellNft,
          data: data.sell_nft,
          loading: loading,
        })
      ) &&
      openNotification("Listed", "Nft listed for marketplace", "success");
    error &&
      dispatch(
        createSellNftData({
          ...createSellNft,
          loading: loading,
          error: error.message,
        })
      );
  }, [data, error]);

  const sell = async (account, signedOrder, nftData, nftId, recipients) => {
    await sell_nft({
      variables: {
        values: {
          walletAddress: account,
          signedOrder: JSON.stringify(signedOrder),
          metaData: JSON.stringify(nftData),
          network: chainId ? +chainId : "",
          nftId,
          recipients,
        },
      },
    });
  };

  return {
    sell,
    createSellNft,
  };
};

export const CreateNftHook = () => {
  const [create_nft, { data, loading, error }] = useMutation(CREATE_NFT);

  const dispatch = useAppDispatch();

  const create = (values) => {
    create_nft({ variables: { values } });
  };

  useEffect(() => {
    data && dispatch(setMintNft(data.create_nft));
  }, [data]);

  return {
    data,
    loading,
    error,
    create,
  };
};

export const GetSellNftsHook = () => {
  const dispatch = useAppDispatch();
  const { data, loading, error, refetch } = useQuery(GET_SELL_NFTS, {
    fetchPolicy: "network-only",
  });
  const { sellNft } = useAppSelector((state) => state.nftData);

  useEffect(() => {
    loading &&
      dispatch(
        sellNftData({
          ...sellNft,
          loading: loading,
        })
      );
    if (data) {
      const sellNfts = data.get_sell_nfts.map((item) => {
        return {
          refetch,
          sellNftId: item?.id,
          userName: item?.user?.name,
          userImage: item?.user?.prof_image,
          favourites: item?.favourites,
          owner: item?.wallet_address,
          price: item?.signed_order?.erc20TokenAmount,
          network: item?.network,
          erc20Token: item?.signed_order?.erc20Token,
          sold: item?.sold,
          token_id: item?.meta_data?.token_id,
          name: item?.nft?.name || item?.meta_data?.asset_contract?.name,
          buttonTxt: item?.sold ? "Sold Out" : "Details",
          image:
            item?.nft?.image ||
            item?.meta_data?.image_preview_url ||
            item?.meta_data?.image,
          nftId: item?.meta_data ? item?.meta_data.id : item?.nft_id,
          attributes: item?.meta_data?.attributes,
        };
      });
      dispatch(
        sellNftData({
          ...sellNft,
          data: sellNfts,
          loading: loading,
        })
      );
    }
    error &&
      dispatch(sellNftData({ ...sellNft, loading: loading, error: error }));
  }, [data, error, loading]);

  return {
    sellNft,
  };
};

export const GetSellNftHook = (slug) => {
  const { data, loading, error, refetch } = useQuery(GET_SELL_NFT, {
    variables: { id: slug },
    fetchPolicy: "network-only",
  });
  const [detail, setDetail] = useState(null);

  useEffect(() => {
    if (data) {
      const sellNft = data.get_sell_nft;
      setDetail(sellNft);
    }
  }, [data]);

  return {
    detail,
    loading,
    error,
    refetch,
  };
};

export const TrendingNftsHook = () => {
  const { data, loading, error, refetch } = useQuery(TOP_VIEWED_NFTS);

  return {
    data: data?.top_viewed_nfts || [],
    loading,
    error,
    refetch,
  };
};

export const UpdateMintStatusHook = () => {
  const [update_mint_status, { data, loading, error }] =
    useMutation(UPDATE_MINT_STATUS);

  const updateStatus = (nftId) => {
    update_mint_status({ variables: { nftId } });
  };

  return {
    updateStatus,
    data,
  };
};

export const UpdateSellNftHook = () => {
  const [update_sell_nft, { data, loading, error }] =
    useMutation(UPDATE_SELL_NFT);

  const updateStatus = (id, nftId, txHash) => {
    update_sell_nft({ variables: { id, nftId, txHash } });
  };

  useEffect(() => {
    error && ToastMessage("Error", "Error updating sold status", "error");
  }, [error]);

  return {
    updateStatus,
    data,
    loading,
    error,
  };
};

export const GetNftByTokenIdHook = () => {
  const [get_nft_by_token_id, { data, loading, error }] = useLazyQuery(
    GET_NFT_BY_TOKEN_ID,
    { fetchPolicy: "network-only" }
  );

  const getNft = (nftId) => {
    get_nft_by_token_id({ variables: { nftId } });
  };

  useEffect(() => {
    error && openNotification("Error", error.message, "error");
  }, [error]);

  return {
    data: data?.get_nft_by_token_id,
    loading,
    error,
    getNft,
  };
};

export const GetNftsByNftIdsHook = () => {
  const [nfts_by_nft_ids, { data, loading, error, refetch }] = useLazyQuery(
    NFTS_BY_NFT_IDS,
    { fetchPolicy: "network-only" }
  );
  const [createdNfts, setCreatedNfts] = useState([]);
  const { user } = useAppSelector((state) => state.user);

  const getNfts = (nftIds) => {
    nfts_by_nft_ids({ variables: { nftIds } });
  };

  useEffect(() => {
    error && openNotification("Error", error.message, "error");

    if (data) {
      let nfts = data.nfts_by_nft_ids;
      nfts = nfts.map((item) => {
        return {
          user: true,
          owner: item.owner_address,
          favourites: item.favourites,
          sellNftId: item.sell_nft?.id,
          nftId: item.nft_id,
          price: item.sellNft?.signed_order.erc20TokenAmount,
          network: item.sellNft?.network,
          erc20Token: item.sellNft?.signed_order.erc20Token,
          sold: false,
          token_id: CommonUtility.deStructureNftId(item?.nft_id).tokenId,
          name: item?.name,
          buttonTxt: "Details",
          image: item?.image,
          userName: user?.name,
          userImage: user?.profImage,
        };
      });
      setCreatedNfts(nfts);
    }
  }, [error, data]);

  return {
    createdNfts,
    loading,
    error,
    getNfts,
    refetch,
  };
};

export const DeleteSellNftHook = () => {
  const [delete_sell_nft, { data, loading, error }] =
    useMutation(DELETE_SELL_NFT);
  const {
    createTransaction,
    loading: transactionLoading,
    data: transactionStatusData,
  } = CreateTransactionHook();

  const deleteSellNft = (tx) => {
    delete_sell_nft({ variables: { id: tx.nft_id } }).then((data) => {
      if (data) {
        createTransaction({
          amount: "0",
          nft_id: tx?.nft_id,
          network_id: +tx?.network_id,
          transaction_hash: tx?.transaction_hash,
          status: tx.transaction_hash ? "Minted" : "",
        });
      }
    });
  };

  useEffect(() => {
    error && ToastMessage("Error", "Error deleting nft", "error");
    transactionStatusData &&
      ToastMessage("Un listed", "Nft unlisted from marketplace", "success");
  }, [error, transactionStatusData]);

  return {
    deleteSellNft,
    data: transactionStatusData,
    loading: transactionLoading || loading ? true : false,
    error,
  };
};

export const GetUserSellNftHook = () => {
  const [user_sell_nft, { data, loading, error }] = useLazyQuery(
    USER_SELL_NFT,
    { fetchPolicy: "network-only" }
  );

  const getSellNft = (id) => {
    user_sell_nft({ variables: { id } });
  };

  useEffect(() => {
    error && openNotification("Error", error.message, "error");
  }, [error]);

  return {
    data: data?.user_sell_nft,
    loading,
    error,
    getSellNft,
  };
};
