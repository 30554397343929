import { useEffect, useState } from "react";
import { MainHeader } from "../element";
import {
  ActionsDiv,
  AddSlotsBtn,
  AddTemplateBtn,
  LeftDiv,
  MainColsWrapper,
  ProbabilityDiv,
  RightDiv,
  SubText,
  TableContainer,
  TableDiv,
  TableHeader,
  TableText,
} from "./element";
import { MainCol, MainRow } from "components/common";
import { Select } from "antd";
import { IoIosClose } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { GetTemplateHook } from "hooks/templateHook";
import { useAppSelector } from "store/store";

function PackSlotsSection({
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  schemas,
  setSlotsArray,
  slotsArray,
}) {
  console.log("ERRORS", errors);
  const [templates, setTemplate] = useState([]);
  const [record, setRecord] = useState([]);
  const [slot, setSlot] = useState(null);
  const { config } = useAppSelector((state) => state.configData);
  // const [slotsArray, setSlotsArray] = useState([{ templates: [1] }]);

  const { data: templateList } = GetTemplateHook();
  useEffect(() => {
    if (values?.pack_slots && record) {
      const remainingData = record.filter((item) => {
        return !values.pack_slots.some(
          (packSlot) => packSlot.template_id == item._id
        );
      });
      setTemplate(remainingData);
      setSlot(values.pack_slots.length);
      setFieldValue("slots", values.pack_slots.length);
    }
  }, [values.pack_slots]);

  useEffect(() => {
    if (record) {
      setTemplate(record);
    }
  }, [record]);

  useEffect(() => {
    if (templateList && values.schema_id) {
      let filter = templateList.filter(
        (x) => x.schema_id?._id == values.schema_id
      );
      setRecord(filter);
    }
  }, [templateList, values.schema_id]);

  const handleSchemaSelect = (e) => {
    setFieldValue("schema_id", e);
    setFieldTouched("schema_id", true);
  };

  const handleAddTemplate = (slotIndex) => {
    const updatedSlotsArray = [...slotsArray];
    updatedSlotsArray[slotIndex].templates.push(1);
    setSlotsArray(updatedSlotsArray);
  };
  const handleDeleteTemplate = (slotIndex, templateIndex) => {
    const updatedPackSlots = [...values?.pack_slots];

    if (Array.isArray(updatedPackSlots[slotIndex])) {
      updatedPackSlots[slotIndex].splice(templateIndex, 1);
      if (updatedPackSlots[slotIndex].length > 0) {
        const length = updatedPackSlots[slotIndex].length;
        const probability = 100 / length;

        updatedPackSlots[slotIndex].forEach((obj) => {
          obj.probability = probability;
        });
      } else {
      }
      setFieldValue("pack_slots", updatedPackSlots);
    }
    const updatedSlotsArray = [...slotsArray];
    updatedSlotsArray[slotIndex].templates = updatedSlotsArray[
      slotIndex
    ].templates.filter((_, index) => index !== templateIndex);
    setSlotsArray(updatedSlotsArray);
  };

  const handleAddSlot = () => {
    const updatedPackSlots = [...values.pack_slots];
    const newTemplate = { template_id: null, id: null };
    updatedPackSlots.push([newTemplate]);
    setFieldValue("pack_slots", updatedPackSlots);
    const updatedSlotsArray = [...slotsArray, { templates: [1] }];
    setSlotsArray(updatedSlotsArray);
  };

  const handleDeleteSlot = (indexToDelete) => {
    const updatedSlotsArraytoShow = slotsArray.filter(
      (_, index) => index !== indexToDelete
    );
    setSlotsArray(updatedSlotsArraytoShow);
    const updatedSlotsArray = values.pack_slots.filter(
      (_, index) => index !== indexToDelete
    );
    setFieldValue("pack_slots", updatedSlotsArray);
  };
  //new func
  const handleTemplateSelect = (
    selectedTemplateId,
    templateIndex,
    slotIndex
  ) => {
    console.log(
      "handleTemplateSelect",
      templateIndex,
      slotIndex,
      selectedTemplateId
    );
    let updatedPackSlots = [...values.pack_slots];

    if (!Array.isArray(updatedPackSlots[slotIndex])) {
      updatedPackSlots[slotIndex] = [];
    }

    console.log("currentSlot at slotIndex", updatedPackSlots[slotIndex]);

    const foundObj = templates.find((obj) => obj._id === selectedTemplateId);
    console.log("foundObj", foundObj);

    if (foundObj) {
      const obj = {
        template_id: foundObj._id,
        probability: 100,
      };
      if (updatedPackSlots[slotIndex][templateIndex]) {
        updatedPackSlots[slotIndex][templateIndex] = obj;
      } else {
        updatedPackSlots[slotIndex].push(obj);
      }
      const slotLength = updatedPackSlots[slotIndex].length;
      updatedPackSlots[slotIndex].forEach((item) => {
        item.probability = 100 / slotLength;
      });

      // Update the pack_slots state
      setFieldValue("pack_slots", updatedPackSlots);
    }
  };

  const calculateTotalProbability = (slot) => {
    if (!slot || !Array.isArray(slot) || slot.length === 0) {
      return 0; // Return 0 if the slot is not an array or is empty
    }
    const sum = slot.reduce((total, item) => {
      return +total + (+item.probability || 0); // Sum up the probability, assuming each item has a 'probability' property
    }, 0);
    return sum;
  };
  const isTemplateSelectedInSlot = (templateId, slotIndex) => {
    return values?.pack_slots[slotIndex]?.some(
      (item) => item.template_id === templateId
    );
  };
  const handleProbabilityChange = (e, slotIndex, templateIndex) => {
    const updatedProbability = e.target.value;
    let updatedPackSlots = [...values.pack_slots];

    if (
      Array.isArray(updatedPackSlots[slotIndex]) &&
      updatedPackSlots[slotIndex][templateIndex]
    ) {
      updatedPackSlots[slotIndex][templateIndex].probability =
        updatedProbability;
      setFieldValue("pack_slots", updatedPackSlots);
    }
  };
  console.log("Values", values);
  console.log("SLOT", slotsArray);
  return (
    <div>
      <MainHeader>Pack Slots</MainHeader>
      <SubText>
        Add slots for the quantity of NFTs to be delivered by your pack. Adjust
        the probability for each NFT within the slot.
      </SubText>
      <TableDiv>
        <MainRow>
          {slotsArray?.map((slot, slotIndex) => (
            <MainColsWrapper>
              <MainRow className="main-row">
                <MainCol lg={2}>
                  {slotIndex === 0 && (
                    <LeftDiv>
                      <TableHeader>Select Schema</TableHeader>
                      <Select
                        showSearch
                        className="modal-select mt-3"
                        placeholder="Select Schema"
                        style={{ width: "100%" }}
                        value={values.schema_id || `Select Schema`}
                        onSelect={handleSchemaSelect}
                        onBlur={() => {
                          setFieldTouched("schema_id", true);
                        }}
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={schemas?.map((c) => {
                          return {
                            value: c._id,
                            label: c.schema_name,
                          };
                        })}
                      />
                    </LeftDiv>
                  )}
                </MainCol>
                <MainCol lg={8}>
                  <RightDiv>
                    {slotIndex !== 0 && (
                      <IoIosClose
                        className="table-close"
                        onClick={() => handleDeleteSlot(slotIndex)}
                      />
                    )}

                    <TableContainer>
                      <MainRow className="border-bottom">
                        <MainCol xs={8}>
                          <TableHeader>Result</TableHeader>
                        </MainCol>

                        <MainCol xs={2}>
                          <TableHeader className="centered-text">
                            probability (%)
                          </TableHeader>
                        </MainCol>
                        <MainCol xs={2}></MainCol>
                      </MainRow>

                      {slot?.templates?.map((item, templateIndex) => (
                        <MainRow key={item?.template_id} className={`mt-3`}>
                          <MainCol xs={8} className="select-col">
                            <Select
                              bordered={false}
                              value={
                                values?.pack_slots?.length > 0 &&
                                values.pack_slots[slotIndex] &&
                                values.pack_slots[slotIndex].length >
                                  templateIndex
                                  ? templateList?.find(
                                      (template) =>
                                        template._id ===
                                        values.pack_slots[slotIndex][
                                          templateIndex
                                        ]?.template_id
                                    )?.template_attributes[0]
                                      ?.attribute_value || "Select Template"
                                  : "Select Template"
                              }
                              placeholder="Select Template"
                              style={{
                                width: " 100%",
                                borderBottom: "1px solid #e7e7e7",
                              }}
                              onBlur={() => {
                                setFieldTouched("pack_slots", true);
                              }}
                              onSelect={(e) =>
                                handleTemplateSelect(
                                  e,
                                  templateIndex,
                                  slotIndex
                                )
                              }
                              options={templates?.map((x) => {
                                const isSelected = isTemplateSelectedInSlot(
                                  x._id,
                                  slotIndex
                                );

                                return {
                                  value: x._id,
                                  label: `${
                                    x?.template_attributes[0]?.attribute_value
                                  }  (${x?.current_supply}/${
                                    x?.max_supply == 0 ||
                                    +x?.max_supply >= +config?.UINT256_MAX
                                      ? "∞"
                                      : x?.max_supply
                                  })  #${x?.nft_id} `,
                                  disabled: isSelected,
                                };
                              })}
                              notFoundContent="No Template Found"
                            />
                            <IoIosClose className="close-icon" />
                          </MainCol>

                          <MainCol xs={2}>
                            <div className="odd-divs">
                              <TableText className="centered-text">
                                <input
                                  type="number"
                                  className="py-1 px-2 w-12 rounded"
                                  min="0"
                                  max="100"
                                  value={
                                    values?.pack_slots?.[slotIndex]?.[
                                      templateIndex
                                    ]?.probability ?? ""
                                  }
                                  onChange={(e) =>
                                    handleProbabilityChange(
                                      e,
                                      slotIndex,
                                      templateIndex
                                    )
                                  }
                                  name="slots"
                                  placeholder="0"
                                />
                              </TableText>
                            </div>
                          </MainCol>
                          <MainCol xs={2}>
                            {templateIndex !== 0 && (
                              <ActionsDiv>
                                <MdDelete
                                  className="icon"
                                  color="#F65D5D"
                                  onClick={() =>
                                    handleDeleteTemplate(
                                      slotIndex,
                                      templateIndex
                                    )
                                  }
                                />
                              </ActionsDiv>
                            )}
                          </MainCol>
                        </MainRow>
                      ))}
                      <AddTemplateBtn
                        onClick={() => handleAddTemplate(slotIndex)}
                      >
                        Add Template
                      </AddTemplateBtn>
                    </TableContainer>
                    {/* <span style={{ color: "#F65D5D", marginTop: "1rem" }}>
                      {touched.pack_slots &&
                        errors.pack_slots &&
                        errors.pack_slots.map((error, index) => (
                          <div key={index}>{error}</div>
                        ))}
                    </span> */}
                  </RightDiv>
                </MainCol>
                <MainCol lg={2}>
                  <ProbabilityDiv>
                    <h2>Total Probability</h2>
                    <p>
                      {calculateTotalProbability(values?.pack_slots[slotIndex])}
                      %
                    </p>
                  </ProbabilityDiv>
                </MainCol>
              </MainRow>
            </MainColsWrapper>
          ))}
        </MainRow>
        <AddSlotsBtn onClick={handleAddSlot}>Add Slot</AddSlotsBtn>
      </TableDiv>
    </div>
  );
}

export default PackSlotsSection;
