import { MainContainer } from "../../components/common";
import {
  CreateNewNFTWrapper,
  FileTypeText,
  MainHeader,
  ShadowDiv,
  SubText,
  UploadImageDiv,
  FormContainer,
  Required,
  SaveBtn,
  PanelDiv,
  PanelContent,
  PanelContentLeftDiv,
  PanelContentRightDiv,
  Divider,
} from "./element";
import mediaupload from "../../assets/mediaupload.png";
import Form from "react-bootstrap/Form";
import { Panel } from "rsuite";
import properties from "../../assets/properties.png";
import blueinfo from "../../assets/blueinfo.png";
import lock from "../../assets/lock.png";
import star from "../../assets/star.png";
import stats from "../../assets/stats.png";
import { AiFillPlusSquare } from "react-icons/ai";
import { useState } from "react";
import { Toggle } from "rsuite";

function CreateNewNFT() {
  const [panelData, setPanelData0] = useState([
    {
      id: 1,
      mainText: "Properties",
      subText: "Textual traits show up as rectagles",
      img: properties,
      addIcon: true,
      switch: false,
    },
    {
      id: 2,
      mainText: "Levels",
      subText: "Numerical traits that show as a progress bar",
      img: star,
      addIcon: true,
      switch: false,
    },
    {
      id: 3,
      mainText: "Stats",
      subText: "Numerical traits that just show as numbers",
      img: stats,
      addIcon: true,
      switch: false,
    },
    {
      id: 4,
      mainText: "Unlockable content",
      subText:
        "Include unlockable content that can only be revealed by the woner of the iteam",
      img: lock,
      addIcon: false,
      switch: true,
    },
    {
      id: 5,
      mainText: "Explicit & Sensitive Content",
      subText: "Set & Sensitive Content",
      img: blueinfo,
      addIcon: false,
      switch: true,
    },
  ]);
  return (
    <CreateNewNFTWrapper>
      <MainContainer>
        <MainHeader>Create new NFT</MainHeader>
        <SubText>Image,video,Audio, or 3Dmodel</SubText>
        <ShadowDiv />
        <FileTypeText>File types, :JPG,PNG,SVG,MP4</FileTypeText>
        <UploadImageDiv>
          <label htmlFor="uploadImg">
            <img className="img-fluid uploadImg" src={mediaupload} />
            <h4 className="dragText">Drag&drop file</h4>
            <p className="browseText">or browse media on your computer</p>
          </label>
          <input
            type="file"
            style={{ display: "none" }}
            name="uploadImg"
            id="uploadImg"
          />
        </UploadImageDiv>
        <FormContainer>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label className="d-flex align-items-center">
                Artwork Name <Required>*</Required>
              </Form.Label>
              <Form.Control type="text" placeholder="Enter Artwork Name" />
            </Form.Group>

            <Form.Group className="mb-3 externalLink">
              <Form.Label className="d-flex align-items-center">
                External link
              </Form.Label>
              <Form.Control type="text" placeholder="Enter External link " />
            </Form.Group>

            <Form.Group className="mb-3 externalLink">
              <Form.Label>Artwork description</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Tell Us About Artwork"
                className="textarea"
              />
            </Form.Group>

            <Form.Group className="mb-3 externalLink">
              <Form.Label>Collection</Form.Label>
              <PanelDiv>
                <Panel header="Collection name" collapsible bordered>
                  {panelData?.map((panelItem, index, panelLength) => (
                    <PanelContent
                      className={
                        panelLength.length - 1 != index && "borderClass"
                      }
                    >
                      <PanelContentLeftDiv>
                        <div className="imgWrapper">
                          <img
                            className="img-fluid innerImg"
                            src={panelItem.img}
                          />
                        </div>
                        <div className="textWrapper">
                          <h4 className="mainText">{panelItem.mainText}</h4>
                          <p className="subText">{panelItem.subText}</p>
                        </div>
                      </PanelContentLeftDiv>
                      <PanelContentRightDiv>
                        {panelItem.addIcon && (
                          <AiFillPlusSquare className="addIcom" />
                        )}
                        {panelItem.switch && <Toggle size="lg" />}
                      </PanelContentRightDiv>
                    </PanelContent>
                  ))}
                </Panel>
              </PanelDiv>
              <p className="infoText">
                This is the collection where you iteam will appear.
              </p>
            </Form.Group>

            <Form.Group className="mb-3 externalLink">
              <Form.Label className="d-flex align-items-center">
                Supply
              </Form.Label>
              <Form.Control type="text" placeholder="Supply" className="mt-4" />
            </Form.Group>

            <Form.Group className="mb-3 externalLink">
              <Form.Label>Blockchain</Form.Label>
              <PanelDiv>
                <Panel header="Ethereum" collapsible bordered>
                  Information will come here
                </Panel>
              </PanelDiv>
            </Form.Group>

            <Form.Group className="mb-3 externalLink">
              <Form.Label className="d-flex align-items-center">
                Free Metadata
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="To freeze your metadata, you need to create an item first. "
                className="mt-4"
              />
              <p className="infoText">
                Freezeng your metadata will allow you to permanently lock and
                store all the item's content in decwentralised storage.
              </p>
            </Form.Group>
            <SaveBtn>Save</SaveBtn>
          </Form>
        </FormContainer>
      </MainContainer>
    </CreateNewNFTWrapper>
  );
}

export default CreateNewNFT;
