import { erc20Abi } from "contract/erc20";
import { CommonUtility } from "utility/common";

class ERC20 {
  async isApprovedForAll(contract, account, contractAddress) {
    try {
      const receipt = await contract.methods
        .isApprovedForAll(account, contractAddress)
        .call();
      return receipt;
    } catch (error) {
      return error;
    }
  }
  async mint(
    web3,

    amount,
    account,
    contractAddress
  ) {
    try {
      const contract = CommonUtility.contract(web3, erc20Abi, contractAddress);
      const receipt = await contract.methods
        .mint(account, amount)
        .send({ from: account });
      return receipt;
    } catch (error) {
      console.log("Error in mint func", error);
      return error;
    }
  }
  async approve(web3, spenderAddress, amount, account, contractAddress) {
    try {
      const contract = CommonUtility.contract(web3, erc20Abi, contractAddress);
      const receipt = await contract.methods
        .approve(spenderAddress, amount)
        .send({ from: account });
      return receipt;
    } catch (error) {
      console.log("Error in approve func", error);
      return error;
    }
  }

  async tokenBalance(web3, tokenAddress, account) {
    const contractErc20 = new web3.eth.Contract(erc20Abi, tokenAddress);
    const balance = await contractErc20.methods.balanceOf(account).call();
    const decimals = +(await contractErc20.methods.decimals().call());
    return balance / 10 ** decimals;
  }

  async balance(web3, tokenAddress, account) {
    const contractErc20 = new web3.eth.Contract(erc20Abi, tokenAddress);
    const balance = await contractErc20.methods.balanceOf(account).call();
    return balance;
  }

  async decimals(web3, tokenAddress) {
    const contractErc20 = new web3.eth.Contract(erc20Abi, tokenAddress);
    const decimals = await contractErc20.methods.decimals().call();
    return decimals;
  }
  async symbol(web3, tokenAddress) {
    const contractErc20 = new web3.eth.Contract(erc20Abi, tokenAddress);
    const decimals = await contractErc20.methods.symbol().call();
    return decimals;
  }
  async allowance(web3, tokenAddress, owner, spender) {
    const contractErc20 = new web3.eth.Contract(erc20Abi, tokenAddress);
    const allowance = await contractErc20.methods
      .allowance(owner, spender)
      .call();
    return allowance;
  }
}

const ERC20Service = new ERC20();
Object.freeze(ERC20Service);
export { ERC20Service };
