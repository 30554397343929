const environment = {
  BACKEND_BASE_URL: "https://starshipnfts.xyz",
  BACKEND_GRAPHQL_URL: "https://starshipnfts.xyz/graphql",
//   BACKEND_BASE_URL: "http://localhost:4000",
//   BACKEND_GRAPHQL_URL: "http://localhost:4000/graphql",
  BACKEND_GRAPHQL_WS_URL: "wss://metasphere.buildmydapp.co/graphql",
  REACT_APP_MORALIS_API_PATH: "https://deep-index.moralis.io/api/v2",

  REACT_APP_OPENSEA_API_PATH: "https://testnets-api.opensea.io/api/v1",
  REACT_APP_OPENSEA_API_KEY: "c41cd99aa151487cb59e4295882ba3d9",
  FRONTEND_URL: "http://localhost:3000",
  bucketurl: "https://sociallinksimages.s3.ap-south-1.amazonaws.com/",
  S3_BUCKET: "sociallinksimages",
  REGION: "ap-south-1",
  ACCESS_KEY: "AKIAUR63W54DT3ZFU53R",
  SECRET_ACCESS_KEY: "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",
  INFURA: "https://ipfs.infura.io:5001/api/v0",
  PROJECT_ID: "2EQXENsyHZkKIqRaf2NMNSwTEuk",
  PROJECT_SECRET: "f96e5a9defbd90b579eb7c4c280e46cd",
  IPFS_PATH: "https://infura-ipfs.io/ipfs",
  NETHY_SHARE_RECEIPENT: "0xF26EBe41F9e9eF53e468a53A5f53af710a6890Ec",
  COMPANY_SHARE_RECEIPENT: "0xaC8901a209c63eD86B03A4DE17dE67CF8575b20B",
};

/*if(process.env.REACT_APP_ENV==="development"){
    environment.BACKEND_BASE_URL= "http://localhost:8080"
}


if(process.env.REACT_APP_ENV==="production"){
    environment.BACKEND_BASE_URL= "https://owlnew.buildmydapp.co"
}


if(process.env.REACT_APP_ENV==="staging"){
    environment.BACKEND_BASE_URL= "https://api.illuminatiowls.com"
}

*/

export default environment;
