import {
  BreadCrumbComp,
  MainCol,
  MainContainer,
  MainRow,
} from "components/common";
import {
  CreateBtn,
  MainTitle,
  SchemeWrapper,
  SelectDiv,
  Text,
  Title,
} from "./elements";
import { BsInfoCircleFill } from "react-icons/bs";
import { AiOutlinePlus } from "react-icons/ai";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { Select, Tooltip } from "antd";
import { GetCreateSchemaHook } from "hooks/createSchemaHook";

const dataa = [
  {
    id: 1,
    text: "Starship NFT Game",
    link: "/admin/starship",
  },
  {
    id: 2,
    text: "Schemas and templates",
    link: "",
  },
];

const SchemaComp = () => {
  const navigate = useNavigate();

  const onSearch = (value) => {
    console.log("search:", value);
  };

  const { data, loading } = GetCreateSchemaHook();

  const location = useLocation();
  const darkModePaths =
    location.pathname === "/" ||
    location.pathname === "/starship/view-collection/detail" ||
    location.pathname === "/starship/explore" ||
    location.pathname.startsWith("/starship/profile");

  return (
    <SchemeWrapper>
      <MainContainer fluid>
        <BreadCrumbComp data={dataa} />

        <Title>
          Schemas and templates&nbsp;
          <Tooltip placement="top" title={"Create Schemas and Templates"}>
            <BsInfoCircleFill fontSize={15} />
          </Tooltip>
        </Title>
        <div className="first-div">
          <CreateBtn
            onClick={() => navigate("/starship/admin/schemas/create-schema")}
          >
            <AiOutlinePlus color="#ffffff" />
            &nbsp;&nbsp;Create a schema
          </CreateBtn>

          <div className="second-div">
            <MainTitle>Schemas and templates</MainTitle>
            <Text>Please select a schemas</Text>
            <SelectDiv>
              <Select
                className={`bg-body-tertiary ${!darkModePaths && "hello"}`}
                showSearch
                placeholder="choose schema"
                optionFilterProp="children"
                onChange={(e) => {
                  navigate("/starship/admin/template/view-template?id=" + e);
                }}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={data?.map((x) => {
                  return { value: x?._id, label: x.schema_name };
                })}
                notFoundContent="No Schema Found"
              />
            </SelectDiv>
          </div>
        </div>
      </MainContainer>
    </SchemeWrapper>
  );
};

export default SchemaComp;
