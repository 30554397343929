import styled, { css } from "styled-components";
import { Row, Col, Card } from "react-bootstrap";
import { Collapse, Tooltip, Menu, Dropdown } from "antd";

import { Divider } from "rsuite";
import detailsbg from "../../assets/details-bg.png";
import { IoMdRefresh } from "react-icons/io";
import { BsFillShareFill, BsThreeDotsVertical } from "react-icons/bs";

export const PriceCardMain = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  gap: 1rem;

  ${(props) =>
    props.collectionDesc &&
    css`
      justify-content: start;
    `}
  @media (max-width: 960px) {
    display: flex;
    flex-direction: column;
  }
`;
export const CardEthIcon = styled.img`
  /* margin-top: 2rem;  */
`;

export const NftPropertiesTitle = styled.p`
  text-align: center;
  color: rgb(32, 129, 226);
  padding-top: 0.3rem;
`;
export const NftPropertiesDesc = styled.p`
  text-align: center;
  margin-top: -0.6rem;
  font-weight: 600;
`;
export const NftPropertiesDesc2 = styled.p`
  text-align: center;
  margin-top: -0.6rem;
`;

//NftPropertiesCard

// About
export const NftDetailsAbout = styled.span`
  display: inline-block;
`;
export const NftAboutImg = styled.img`
  border-radius: 10px;
  height: 100%;
  object-fit: contain;
  transition: opacity 400ms ease 0s;
  width: 100%;
`;
export const AboutImgDiv = styled.div`
  float: left;
  margin-right: 10px;
  margin-top: 3px;
  height: 80px;
  width: 80px;
  border-radius: 10px;
  height: 100%;
  width: 80px;
`;

// Social Dropdown

// socialIcons

// About

// Details
export const DeatilsTitle = styled.p``;
export const DeatilsValue = styled.p`
  text-align: end;
  font-weight: 600;
  color: #707a83;
`;

// Details

export const NftDetailDesc = styled.div`
  height: 200px;
  overflow: auto;
  padding: 0.5rem 1rem;
  margin-top: -1.9rem;
`;
export const NftDescTitle = styled.p`
  padding: 0.5rem 1rem;
  margin-top: 1rem;

  & span {
    color: #2081e2;
  }
`;
export const NftToolTip = styled(Tooltip)`
  &:hover {
    cursor: pointer;
  }
`;

export const CollectionTitles = styled.h2`
  margin-top: 2rem;
  color: white;
  margin-left: 1.8rem;
  ${(props) =>
    props.mainTitle &&
    css`
      font-size: 1rem;
    `}
  @media (max-width:767px) {
    text-align: center;
  }
`;
export const Collectiondesc = styled.p`
  margin-top: 2rem;
  color: white;
  margin-left: 1.8rem;
  @media (max-width: 767px) {
    text-align: center !important;
  }
`;
export const Collectionfavourite = styled.p`
  margin-top: 2rem;
  color: white;
  @media (max-width: 767px) {
    text-align: center !important;
  }
`;

export const CollapseHeader = styled.div`
  display: flex;
  gap: 0.8rem;
  margin-top: -0.5rem;
`;

export const CollapseHeaderTitle = styled.p`
  transform: translateY(5px);
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #333333;
`;

//Starts Here...

export const MainNtfDetailDiv = styled.div`
  overflow: hidden;
  margin-top: 40px;
  margin-bottom: 100px;

  @media (max-width: 991.98px) {
    margin-bottom: 40px;
  }
`;

export const IconContainer = styled.div``;

export const TopWrapper = styled.div``;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;

  @media (max-width: 991.98px) {
    justify-content: center;
    margin-right: 0;
  }
`;

export const Refresh = styled(IoMdRefresh)`
  font-size: 28px;
  color: #464646;
  cursor: pointer;
`;

export const Share = styled(BsFillShareFill)`
  color: #464646;
  font-size: 20px;
  cursor: pointer;
`;

export const Edit = styled.div`
  .img-fluid {
    cursor: pointer;
  }
`;

export const Dots = styled(BsThreeDotsVertical)`
  color: #464646;
  font-size: 20px;
  cursor: pointer;
`;

export const Header = styled.div`
  margin-top: 30px;
  margin-bottom: 40px;
  @media (max-width: 991.98px) {
    margin-top: 40px;
  }
`;

export const LeftDiv = styled.div`
  margin-top: 40px;

  @media (max-width: 991.98px) {
    margin-top: 0px;
  }
`;

export const RightDiv = styled.div`
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 5rem;

  @media (max-width: 991.98px) {
    margin-top: 40px;
    padding-left: 0rem;
  }
`;

export const ImgContainer = styled.div`
  .img-fluid {
    width: 100%;
    border-radius: 10px;
    height: 657px;

    @media (max-width: 991.98px) {
      height: 400px;
    }
  }
  @media (max-width: 991.98px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const NFTName = styled.h2`
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  text-transform: capitalize;

  .img-fluid {
    margin-bottom: 6px;
    margin-left: 10px;
  }

  @media (max-width: 991.98px) {
    text-align: center;
  }
`;
export const ContributorsName = styled.h2`
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 43px;
  color: #000000;
  text-transform: capitalize;
  margin-top: 2rem;
  position: relative;
  z-index: 10;

  .img-fluid {
    margin-bottom: 6px;
    margin-left: 10px;
  }

  @media (max-width: 991.98px) {
    text-align: center;
  }
`;

export const Owner = styled.h2`
  font-family: "Roboto", sans-serif;
  margin-top: 1rem;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;

  @media (max-width: 991.98px) {
    justify-content: center;
  }

  .img-fluid {
    height: 30px;
    width: 30px;
    border-radius: 30px;
  }

  a {
    color: #000000 !important;
    text-transform: capitalize;
  }

  span {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    margin-left: 15px;
    color: #65676d;
  }

  @media (max-width: 991.98px) {
    text-align: center;
  }
`;

export const ShadowDiv = styled.div`
  background: #eacbff;
  border-radius: 8px;
  height: 15px;
  width: 470px;
  transform: translateY(-21px);
  z-index: 1;
  opacity: 0.8;

  @media (max-width: 991.98px) {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: 575.98px) {
    width: 100%;
  }
`;

export const MainWrapper = styled.div`
  background: #f0efff;
  border-radius: 4px;
  padding: 35px 16px;
  margin-top: 30px;
`;

export const BottomWrapper = styled.div`
  margin-top: 20px;

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 575.98px) {
      flex-direction: column;
      justify-content: center;
      gap: 10px;
    }
  }

  p {
    font-family: "Figtree";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #3e3e3e;
    margin-top: 0px;

    @media (max-width: 575.98px) {
      margin-top: 10px;
    }

    span {
      font-weight: 600;
      font-size: 22px;
      color: #000;
    }
  }
`;

export const PriceWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center !important;

  @media (max-width: 575.98px) {
    flex-direction: column;
    justify-content: center;
    gap: 10px;
  }
`;

export const PriceText = styled.h2`
  font-family: "Figtree";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 19px;
  color: #3e3e3e;

  @media (max-width: 575.98px) {
    text-align: center;
  }
`;

export const Price = styled.p`
  font-family: "Figtree";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 29px;
  color: #000000;
  margin-top: 10px;

  .img-fluid {
    height: 20px;
  }

  small {
    font-family: "Figtree";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 19px;
    color: #9040c4;
  }
`;

export const BtnWrapper = styled.div``;

export const DescriptionSelectionDiv = styled.div`
  width: 100%;
  margin-top: 5rem;

  @media (max-width: 991.98px) {
    margin-top: 3rem;
  }

  .rs-panel-bordered {
    background: #ffffff;
    border: 1px solid #333333;
    border-radius: 5px;
  }

  .rs-panel-header {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 0px;
    color: #333333;
  }

  .rs-anim-collapse.rs-anim-in {
    border-top: 1px solid #333333;
    padding-top: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
  }

  .rs-anim-collapsing {
    padding-top: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    border-top: 1px solid #333333;
  }

  .rs-panel-collapsible > .rs-panel-header > .rs-icon {
    color: #333333;
    top: 14px;
  }
`;

export const TransactionHistoryDiv = styled.div`
  margin-top: 10rem;
  width: 100%;

  @media (max-width: 991.98px) {
    margin-top: 3rem;
  }

  .rs-panel-bordered {
    background: #ffffff;
    border: 1px solid #bdbdbd;
    border-radius: 5px;
  }
  .rs-panel-collapsible > .rs-panel-header {
    padding: 0.8rem !important;
  }

  .rs-anim-collapse.rs-anim-in {
    border-top: 1px solid #bdbdbd;
    padding-top: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
  }

  .rs-anim-collapsing {
    padding-top: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    border-top: 1px solid #bdbdbd;
  }

  .rs-panel-collapsible > .rs-panel-header > .rs-icon {
    color: #333333;
    top: 14px;
  }
`;

export const TopTransactionDiv = styled.div`
  .transactionHeader {
    display: flex;
    justify-content: space-between;
    margin-right: 2.5rem;
    margin-top: 1rem;
  }

  .transactionBody {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-x: auto;

    white-space: nowrap;

    .transactionItem {
      display: flex;
      justify-content: space-between;

      gap: 0.8rem;
    }

    .transactionBodyItem {
      flex: 0 0 12%;
      display: inline-block;
      a {
        margin-left: -2rem;
      }
    }

    .price {
      text-align: center;
      padding: 0 !important;
      margin: 0 !important;
    }
    @media (max-width: 1200px) {
      .transactionItem {
        gap: 3rem;
      }
      .transactionBodyItem {
        flex: 0 0 20%;
        display: inline-block;
        a {
          margin-left: -9rem;
        }
      }
      .price {
        margin-left: -2.5rem;
      }
    }
    @media (max-width: 480px) {
      .transactionItem {
        gap: 6rem;
      }
      .transactionBodyItem {
        flex: 0 0 25%;
        display: inline-block;
        a {
          margin-left: -10rem;
        }
      }
      .price {
        margin-left: -3rem;
      }
    }

    @media (max-width: 400px) {
      .transactionItem {
        gap: 6rem;
      }
      .transactionBodyItem {
        flex: 0 0 30%;
        display: inline-block;
        a {
          margin-left: -10rem;
        }
      }
      .price {
        margin-left: -3rem;
      }
    }
  }
`;

export const RowDivider = styled(Divider)`
  background-color: #bdbdbd;
  margin: 10px 0px;
  width: 100%;
`;

export const EventDiv = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #4f4f4f;
`;

export const PriceDiv = styled.div`
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #4f4f4f;
`;

export const FromDiv = styled.div`
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #4f4f4f;

  a {
    color: #2f80ed !important;
  }
`;

export const ToDiv = styled.div`
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #4f4f4f;

  a {
    color: #2f80ed !important;
  }
`;

export const DateDiv = styled.div`
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #4f4f4f;

  a {
    color: #2f80ed !important;
  }
`;

export const OwnershipSelectionDiv = styled.div`
  width: 100%;
  margin-top: 3rem;

  .ant-select {
    width: 93%;

    @media (max-width: 991.98px) {
      width: 100%;
    }
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: #ffffff;
    border: 1px solid #333333;
    border-radius: 10px 10px 0px 0px;
  }

  .ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select-open .ant-select-selector {
    box-shadow: none !important;
    border-color: #333333 !important;
  }

  .ant-select-selection-placeholder {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: rgba(12, 12, 12, 0.5);
  }

  .ant-select-arrow {
    color: #0c0c0c;
  }
`;

export const TableDiv = styled.div`
  .css-11xur9t-MuiPaper-root-MuiTableContainer-root {
    background: transparent !important;
    box-shadow: none;
    border: 1px solid #bdbdbd;
  }

  .css-1yhpg23-MuiTableCell-root {
  }
`;

export const PanelDiv = styled.div`
  margin-top: 1rem;
  background: #f0efff;
  border-radius: 4px;
  padding: 05px 0px 05px 10px;

  .rs-panel-collapsible > .rs-panel-header > .rs-icon {
    background-color: #9040c4;
    color: #fff;
    height: 30px;
    width: 30px;
    transform: translateY(-5px);
    border-radius: 4px;
  }

  .rs-panel-title {
    font-family: "Figtree";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #261ce8;
  }

  .rs-panel-body,
  .rs-panel-header {
    padding: 20px;
  }

  .ant-input-affix-wrapper {
    background: #f0efff;
    border: 1px solid #e7e0ec;
    border-radius: 4px;
  }

  .ant-input-affix-wrapper > input.ant-input {
    background: #f0efff;
    padding: 0px 5px;
  }
`;

export const PropertiesDiv = styled.div`
  background: #f5f5f5;
  border-radius: 4px;
  padding: 15px;
`;

export const PropertiesDivMaintext = styled.h4`
  font-family: "Figtree";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #261ce8;
  text-transform: capitalize;
`;

export const PropertiesDivSubtext = styled.p`
  font-family: "Figtree";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  margin-top: 10px;
  text-transform: capitalize;
`;

export const MainNFTDetails = styled.div`
  background-image: url(${detailsbg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`;
