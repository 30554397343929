import styled, { createGlobalStyle } from "styled-components";
import hrarrow from "./assets/hrarrow.png";
import hlarrow from "./assets/hlarrow.png";

const GlobalStyle = createGlobalStyle`
  * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  } 
 body
{
  overflow-x: hidden !important;
   background: white !important;
  font-family: 'Barlow', sans-serif;
  font-family: 'Roboto', sans-serif;
}


.navbar-light .navbar-toggler {
  border-color: #00b7ff !important;
}


  .timertext
  {
    color: ${({ theme }) => theme.inputtext} !important;
  }

.ant-select-item, .ant-select-item-empty {
  color: ${({ theme }) => theme.inputtext} !important;

}


.ant-picker-dropdown .ant-picker-panel-container {
  background-color: ${({ theme }) => theme.datepickerbgColor};
  }

  .ant-picker-dropdown .ant-picker-cell-in-view
 {
  color: ${({ theme }) => theme.inputtext};
 }
 .ant-picker-dropdown .ant-picker-time-panel-column >li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner
{
  color: ${({ theme }) => theme.inputtext};
}

.ant-picker-dropdown .ant-picker-content th
{
  color: ${({ theme }) => theme.inputtext};
}

.ant-picker-dropdown .ant-picker-header button
{
  color: ${({ theme }) => theme.inputtext};
}

.ant-btn-primary:disabled
{
  color: ${({ theme }) => theme.inputtext};

}

.ant-picker-time-panel-column>li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner
{
  background-color: ${({ theme }) => theme.dateselectbgColor};
}

.ant-picker-input>input
{
  color: ${({ theme }) => theme.inputtext} !important;
}


.explore-menu {
     background-color: ${({ theme }) =>
       theme.selectdropdownbgColor} !important; 
  }


.explore-dropdown.ant-dropdown {
    min-width: 130px !important;
  }

.ant-dropdown-menu-item.ant-dropdown-menu-item-active, .ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-active, .ant-dropdown-menu-item:hover, .ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-active, .ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-active, .ant-dropdown-menu-submenu-title:hover {
  /* background-color: transparent !important; */
  background-color: ${({ theme }) => theme.selectdropdownhoverColor} !important;
  
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  /* background-color: transparent !important; */
  background-color: ${({ theme }) => theme.selectdropdownhoverColor} !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled)
{
  background-color: ${({ theme }) => theme.selectdropdownhoverColor} !important;
}



.ant-modal-content {
  background-color: transparent !important;
  box-shadow: none !important;
}

.ant-modal-close-x {
  display: none;
}

.ant-modal-footer {
  display: none;
}





.container-fluid {
    padding-right: 7rem;
    padding-left: 6rem;

    @media (max-width: 767.98px) {
      padding-right: 2rem;
      padding-left: 2rem;
    }
    @media (max-width: 575.98px) {
      padding-right: 10px;
      padding-left: 10px;
    }
  }





.disable-scroll {
    overflow: hidden !important;

    @media (max-width: 1199.98px) {
      overflow: auto !important;

    }
  }

  .ant-select-clear
  {
    background-color: ${({ theme }) => theme.selectdropdownbgColor} !important;
  }

  .anticon
  {
    color: ${({ theme }) => theme.selectitemcontentColor} !important;
  }

.from-text {
  /* color: #00B7FF !important; */
  color: ${({ theme }) => theme.tableaddressColor} !important;
  cursor: pointer;
}



.table-text {
  cursor: pointer;

}

.swiper-icon-back {
  width: 50px;
height: 50px;
  color: rgba(0, 183, 255, 1) !important;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: -60px;
  z-index: 200;
}

@media (max-width: 991.98px) {
  .swiper-icon-back {
    position: static;
  }
}

.swiper-icon-next {
  width: 50px;
height: 50px;
  color: rgba(0, 183, 255, 1) !important;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: -50px;
  z-index: 200;

}

@media (max-width: 991.98px) {
  .swiper-icon-next {
    position: static;
  }
}
.top-swiper-icon-back {
  width: 50px;
height: 50px;
  color: rgba(0, 183, 255, 1) !important;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: -60px;
  z-index: 200;
}

@media (max-width: 991.98px) {
  .top-swiper-icon-back {
    position: static;
  }
}

.top-swiper-icon-next {
  width: 50px;
height: 50px;
  color: rgba(0, 183, 255, 1) !important;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: -50px;
  z-index: 200;

}

@media (max-width: 991.98px) {
  .top-swiper-icon-next {
  position: static;
  }
}

.arrow-disabled
{
  color: rgba(0, 183, 255, 0.3) !important;
  cursor: not-allowed !important;
}



.react-datepicker-wrapper {
  position: absolute;
  top: 15px;
  right: 20px;

  input {

    &:focus {
      border: none !important;
      border-bottom: 1px solid #ccc !important;
      outline: none !important;
    }
  }
}
.ant-table-tbody>tr.ant-table-placeholder:hover>td
{
  background-color: transparent !important;
}




.date-picker {
  border: none;
  border-bottom: 1px solid #ccc;
}

.ant-modal-close-x {
    color: ${({ theme }) => theme.tablecloseiconColor} !important;
  }



  /* .ant-dropdown-menu
  {
    background-color:${({ theme }) => theme.selectdropdownbgColor} !important;

    &.hello
    {
      background-color: #fff !important;
    } 
  } */




  
  .first-explore-select-dropdown {
    background-color: ${({ theme }) => theme.datepickerbgColor} !important; 
  }



a 
{
  text-decoration: none !important;
}

@media (min-width: 992px)
  {
   .collectionContainer .filterRow .col-lg-10 {
    flex: 0 0 auto;
    width: 80% !important;
}
.collectionContainer .filterRow .col-lg-2 {
    flex: 0 0 auto;
    width: 18.5% !important;
}
  }

 


iframe
{
  /* position: absolute !important; */
  width: none !important;
  top: none !important;
  left: none !important;
  right: none !important;
  z-index: -5 !important;
}

.select.ant-select {
  width: 100% !important;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
border: 1px solid #E7E7E7;
box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27);
}

.select.ant-select-focused:not(.ant-select-customize-input) .ant-select-selector 

{
  border: 1px solid #E7E7E7;
   box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27) !important;

}
 

 .select.ant-select:not(.ant-select-customize-input) .ant-select-selector { 
    border-radius: 0px;
border:  none ;
box-shadow: none !important;

  }

.explore-select.ant-select {
  width: 100% !important;
  padding-top: 6px;
  padding-bottom: 6px;
  border-radius: 10px;
border: 1px solid #E7E7E7;
box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27);
margin-top: 1rem;
}

.explore-select.ant-select-focused:not(.ant-select-customize-input) .ant-select-selector 

{
  border: 1px solid #E7E7E7;
   box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27) !important;

}
 

 .explore-select.ant-select:not(.ant-select-customize-input) .ant-select-selector { 
    border-radius: 0px;
border:  none ;
box-shadow: none !important;

  }

.explore-top-select.ant-select {
  width: 140px !important;
  padding-top: 6px;
  padding-bottom: 6px;
  border-radius: 10px;
border: 1px solid #E7E7E7;
box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27);
}

.explore-top-select.ant-select-focused:not(.ant-select-customize-input) .ant-select-selector 

{
  border: 1px solid #E7E7E7;
   box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27) !important;

}
 

 .explore-top-select.ant-select:not(.ant-select-customize-input) .ant-select-selector { 
    border-radius: 0px;
border:  none ;
box-shadow: none !important;

  }

  /* .explore-top-select.ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder
  {
   color: red !important;
  } */


.modal-select.ant-select {
  width: 100% !important;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 10px;
border: 1px solid #E7E7E7;
box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27);
}

.modal-select.ant-select-focused:not(.ant-select-customize-input) .ant-select-selector 

{
  border: 1px solid #E7E7E7;
   box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27) !important;

}
 

 .modal-select.ant-select:not(.ant-select-customize-input) .ant-select-selector { 
    border-radius: 0px;
border:  none ;
box-shadow: none !important;

  }

  .items-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.5rem;
  }

  .items-text {
      margin: 0;
      /* color: #6b6b6b; */
      color:  #000;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;

   
  }
  .explore-text {
      margin: 0;
      /* color: #6b6b6b; */
      color: ${({ theme }) => theme.cardText} !important;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;

   
  }



/* .ant-select-item-option
{
  height: 100px;
  overflow-y: scroll;
} */





.opBtn {
  border-right: none !important;
}










.rs-dropdown-item {
  width: 185px;
}


 .hideScreen {
    display: hidden !important;
  }
  .collectionContainer {
      /* max-width: 1177px !important; */
    }
.lazy-load-image-background.blur.lazy-load-image-loaded > img {
    border-radius: 20px 20px 0 0;
}
.main
  {
    min-height: calc(100vh - 72px - 50px);
  }

  .slick-list {
     
  }
  
.slick-arrow {
  display: none !important;
}
.slick-prev::before {
  display: none !important;
}

  .slick-next {
  background: url(${hrarrow}) center center no-repeat !important;
  display: none;

  @media (max-width: 1430.98px) { 
    visibility: hidden;
   }
}
.slick-next::before {
  display: none !important;
}

 .slick-prev {
  background: url(${hlarrow}) center center no-repeat !important;
  @media (max-width: 1430.98px) { 
    visibility: hidden;
   }
}

.slick-prev, .slick-next {
  width: 30px;
  height: 30px;
}

/* Slick */
  .slick-dots li button:before {
    font-size: 10px;
    margin-top: 2rem;


  }
@media (min-width: 1024px) {
  .slick-arrow {
    display: block !important;
  }
}
@media (min-width: 1440px) {
  .slick-list {
    margin: 0px -6px;
    color: white !important;
  }
  .slick-slide > div {
  }
} */

 

 
.trendingSection .ant-select-arrow {
 border:  none !important;
 color: white !important ;
 

 .trendingSection .ant-select-selection-item
 {
  margin-top: 0.1rem !important;
 }
/* Models styles  */
.ant-modal-footer
  {
    display: none;
  }
  .ant-modal-close
  {
    display: none;
  }

  .offer-modal .ant-modal-close {
    display: block !important;

  }
  .ant-modal-content
  {
   background: none;
  }
  .ant-modal-body
  {
    padding: 0px;
  }
  .ant-modal-mask
  {
    background-color: rgb(0 0 0 / 78%) ;
  }
  .closeModelInput
  {
    transform: translateY(-2px);
  }
    .ant-space-align-baseline
  {
    display: flex;
    justify-content:center  ;
  }
  .modalInputMale
  {
    border-top-right-radius:7px;
  border-bottom-right-radius:7px;
  transform: translateX(-6px);
  }
  .ant-form-vertical .ant-form-item-label>label {
  
 
    font-weight:600;
}
  /* Models styles */


  /* ANT Design Collapseable */


  

  

}


  /* ANT Design Collapseable */

//Divider 



/* Dashboard */
.navbarHeader {
  background-color: var(--navbarHeader);
  padding: "5px 0";
}
#components-layout-demo-custom-trigger .trigger {
 
  
  cursor: pointer !important;
  
  
    
}
  
.trigger {
  /* background-color: #3a6af1; */
  margin-left: 1rem;
  color: var(--triggerColor) !important;
  font-size: 1.5rem;
  margin-top: -0.2rem;
  margin-right: 0.5rem;
  padding: 20px 0 !important;
}


 
 
  .modelPopup
{
  margin-left: 51.5rem !important;
}
@media (min-width: 300px) {
  .antRes{
  
    display:none !important;
  }
    .ant-layout-sider-collapsed 
  {
    display: block !important;  
  }
}
@media (min-width: 600px) {
  .antRes    {
  
    display:block !important;
 } 
 .ant-layout-sider-collapsed.ant-layout-sider 
  {
    display: block !important;  
  }
}
:root {
  --bgContent: #fff;
  --navbarHeader: #fff;
  --bgColorSection: #E5F1EA;
  --navbarBtn:#0c8ded;
  --navbarMenuIcon: #1A1C23;
  --navbarMenuItem: #fff;
  --navbarMenuActive: #fff;
  --navbarSubMenuCollapsedActive: #fff;
  --navbarSubMenuBg: #1A1C23;
  --navbarSubMenuTitle: #fff;
  --navbarSubMenuIcon: #fff;
  --navbarMenuColor: #000;
  --triggerColor: #000;
  --bgSidebar:  #1A1C1E; /**for side bar*/
  --swapCardbg: #293443;
  --swapCardColor: #fff;
   
}
.contentx {
  background-color: var(--bgContent);
}
/* Dashboard */
/* Chartjs */
.recharts-wrapper
{
  width: 100% !important;
  height: 440px !important;
}
/* Expense Card */
.expenseCard
{
  margin-top: 2rem;
}
.recharts-default-legend
{
  margin-top:-12.5rem !important;
}
/* Header Dashboard */
.dashboardHeader.dropdown > a  {
    margin-left: 6.5rem !important;
  }
/* Dashboard close */
/* sidebar */
.sidebarCard .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    {
      font-weight: 600;
      padding: 1.2rem;
      /* border-top: 2px solid white; */
    }
 
  .filterIcon
  {
    font-weight: 600;
    font-size: 1.5rem;
    margin-right: 1rem;
  }
  
     
.createCollecton  .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  
  margin-top: 0rem;
}


.createCollecton  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
     margin-left: -0.2rem !important;
     
}
.sidebarCard .ant-select:not(.ant-select-customize-input) .ant-select-selector {
   border-radius: 10px;
   
}
.sidebarCard .ant-select-focused
{
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
}
.priceLowToHighSelect
{
  margin-left: 11rem !important;
}
.mainSection
{
  /* margin-left: -5rem; */
}
/* sidebar */
 
 
 
    @media (min-width: 1880px) {
      .lazyCardImage
      {
        width: 16.38rem;
      }
  }
  @media (max-width: 1840px) {
    .lazyCardImage
      {
        width: 19.7rem;
        
      }
  }
  @media (max-width: 1660px) {
    .lazyCardImage
      {
        width: 17.2rem;
        
      }
  }
  @media (max-width: 1660px) {
    .lazyCardImage
      {
        width: 16.8rem;
        
      }
  }
  @media (max-width: 1540px) {
    .lazyCardImage
      {
        
        width: 16rem;
      }
  }
  @media (max-width: 1496px) {
    .lazyCardImage
      {
        
        width: 15.7rem;
      }
  }
  @media (max-width: 1440px) {
    .lazyCardImage
      {
        
        width: 15.1rem;
      }
  }
  @media (max-width: 1280px) {
    .lazyCardImage
      {
        
        width: 16.7rem;
      }
  }
  @media (max-width: 1200px) {
    .lazyCardImage
      {
        
        width: 24rem;
      }
  }
  @media (max-width: 1180px) {
    .lazyCardImage
      {
        
        width: 19.5rem;
      }
  }
  @media (max-width: 1060px) {
    .lazyCardImage
      {
        
        width: 17.5rem;
      }
  }
  @media (max-width: 990px) {
    .lazyCardImage
      {
        
        width: 100%;
      }
  }
  
 
  
  /* Antd Upload */
  .ant-upload-list
  {
    display: flex ;
    flex-direction: column-reverse;
  }
 .userProfile .ant-upload-list-item , .createCollecton .ant-upload-list-item ,.profileSetting .ant-upload-list-item  {
    
    display: none;
}

.ant-btn:focus
{
  background: transparent !important;
}


  /* Nft Details */
  .nftDetails .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    {
      background: #fff;
    }
   
    /* acordian */
    .accordion-button:not(.collapsed) {
    color: #000;
    background-color: transparent !important;
    border: none;
    box-shadow: none !important;
  }
   
/* Navbar*/


.main-nav .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 183, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}



/*Navbar  */
/* Collection-all  */
 
  /* Collection-all */

//swip

.offers-text {
  color:  #3E3E3E !important; 
font-size: 26px !important;
font-style: normal !important;
font-weight: 500 !important;
line-height: normal !important;
text-transform: capitalize !important;
}




`;

export default GlobalStyle;
