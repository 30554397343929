import {
  useLazyQuery,
  useQuery,
  useMutation,
  useSubscription,
} from "@apollo/client";
import { ToastMessage } from "components/common";
import openNotification from "components/common/toastMessage/toastMessage";
import { ADD_FAVOURITE, REMOVE_FAVOURITE } from "gql/mutations";
import { GET_FAVOURITE, NFTS_FAVOURITE, USER_FAVOURITES } from "gql/queries";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { setFavourite } from "store/redux/slices/nftSlices/favouriteDataSlice";
import { useAppDispatch, useAppSelector } from "store/store";

export const ChangeFavouriteHook = () => {
  const { nftFavourite } = useAppSelector((state) => state.favouritesData);
  const { account } = useAppSelector((state) => state.web3Connect);
  const [add_favourite, { loading, data: addData }] =
    useMutation(ADD_FAVOURITE);
  const [remove_favourite, { loading: removeLoading, data: removeData }] =
    useMutation(REMOVE_FAVOURITE);

  const changeFav = (data) => {
    !nftFavourite.userFavourite &&
      add_favourite({
        variables: {
          values: { userAddress: account, nftId: data.nft_id },
        },
      });
    nftFavourite.userFavourite &&
      remove_favourite({
        variables: {
          id: nftFavourite.userFavourite?.id,
          values: {
            userAddress: account,
            nftId: data.nft_id,
          },
        },
      });
  };

  return {
    changeFav,
    loading,
    removeLoading,
    addData,
    removeData,
  };
};

export const AddFavouriteHook = (setUserInfo, userInfo, refetch) => {
  const [add_favourite, { loading, data, error }] = useMutation(ADD_FAVOURITE);

  const addFav = (data) => {
    add_favourite({
      variables: {
        values: { userAddress: data.userAddress, nftId: data.nft_id },
      },
    });
  };

  useEffect(() => {
    error &&
      ToastMessage("Not added", "nft unable to add to favourite", "warning");
    if (data) {
      setUserInfo({ ...userInfo, fav: data.add_favourite });
      refetch && refetch();
    }
  }, [error, data]);

  return {
    addFav,
    loading,
    data,
  };
};

export const RemoveFavouriteHook = (setUserInfo, userInfo, refetch) => {
  const [remove_favourite, { loading: removeLoading, data, error }] =
    useMutation(REMOVE_FAVOURITE);

  const removeFav = (data) => {
    remove_favourite({
      variables: {
        id: data.id,
      },
    });
  };

  useEffect(() => {
    error && ToastMessage("Not removed", "nft unable to remove", "warning");
    if (data) {
      setUserInfo({ ...userInfo, fav: null });
      refetch && refetch();
    }
  }, [error, data]);

  return {
    removeFav,
    removeLoading,
    data,
  };
};

export const GetFavouriteHook = (detail) => {
  const { nftFavourite } = useAppSelector((state) => state.favouritesData);
  const [
    nfts_favourite,
    { data: getFavourite, loading: getFavouriteLoading, refetch },
  ] = useLazyQuery(NFTS_FAVOURITE, { fetchPolicy: "network-only" });
  const { web3, account } = useAppSelector((state) => state.web3Connect);
  const dispatch = useAppDispatch();

  useEffect(() => {
    detail && nfts_favourite({ variables: { nftId: detail.nft_id } });
  }, [detail]);

  useEffect(() => {
    getFavourite &&
      dispatch(
        setFavourite({
          ...nftFavourite,
          favourites: getFavourite?.nfts_favourite,
          userFavourite: web3
            ? getFavourite?.nfts_favourite.find(
                (x) => x.user_address === account.toLocaleLowerCase()
              )
            : null,
        })
      );
  }, [getFavourite]);

  useEffect(() => {
    web3 &&
      nftFavourite.favourites.length > 0 &&
      dispatch(
        setFavourite({
          ...nftFavourite,
          userFavourite: nftFavourite.favourites.find(
            (x) => x.user_address === account.toLocaleLowerCase()
          ),
        })
      );
  }, [web3]);

  return {
    getFavouriteLoading,
    refetch,
  };
};

export const UserFavouritesHooks = () => {
  const { account } = useAppSelector((state) => state.web3Connect);
  const [user_favourites, { data, loading, error, refetch }] = useLazyQuery(
    USER_FAVOURITES,
    { fetchPolicy: "network-only" }
  );
  const [favourites, setFavourites] = useState([]);
  const { user } = useAppSelector((state) => state.user);

  const getFavourites = () => {
    user_favourites({ variables: { userAddress: account } });
  };

  useEffect(() => {
    account && getFavourites();
  }, [account]);

  useEffect(() => {
    error && openNotification("Error", error.message, "error");
    if (data) {
      let favNfts = data.user_favourites;
      favNfts = favNfts.map((item) => {
        return {
          sellNftId: item.metadata.sellNftId,
          favourites: [item],
          id: item.id,
          user_address: item.user_address,
          owner: item.metadata.owner,
          price: item.metadata.price,
          network: item.metadata.network,
          erc20Token: item.metadata.erc20Token,
          sold: item.metadata.sold,
          token_id: item.metadata.token_id,
          name: item.metadata.name || item.metadata?.asset_contract.name,
          buttonTxt: item?.sold ? "Sold Out" : "Details",
          image: item.metadata.image || item.metadata?.image_preview_url,
          nftId: item.nft_id,
          userName: user?.name,
          userImage: user?.profImage,
        };
      });
      setFavourites(favNfts);
    }
  }, [error, data]);

  return {
    favourites,
    getFavourites,
    loading,
    error,
    refetch,
  };
};
