import PacksCom from "components/starship/packs";

function Packs() {
  return (
    <div>
      <PacksCom />
    </div>
  );
}

export default Packs;
