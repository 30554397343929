import { useEffect } from "react";
import GlobalStyle from "./globalStyles";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { MainNavbar, Error404, AdminLogin, CreatePackCom } from "./components";
import {
  CreateNftForm,
  NftDetail,
  AdminDashboard,
  NftsListing,
  UserProfile,
  ReportsListing,
  CollectionListing,
  CreateCollection,
  ProfileSetting,
  CategoryListing,
  UpdateCredentials,
  UserNftDetail,
  SellNft,
  AllNfts,
  UsersCollection,
  CollectionAll,
  MainCollections,
  CreateNewNFT,
  MintNfts,
  Drops,
  Packs,
  Profile,
  Explore,
} from "./pages";
import { UserSigninSignupHook } from "hooks";
import {
  switchNetwork,
  updateAccount,
} from "store/redux/slices/web3/connectWeb3Slice";
import { useAppDispatch, useAppSelector } from "store/store";
import StarShipScreen from "pages/starshipPages/starship";
import CreateSchemaComp from "components/starship/schema/createSchema";
import SchemaPage from "pages/starshipPages/schema";
import TemplateScreen from "pages/starshipPages/template";
import CreateTemplateComp from "components/starship/template/createTemplate";
import ViewTemplateComp from "components/starship/template/viewTemplate";
import StarshipAdminPage from "pages/starshipPages/starshipAdmin";
import CollectionDetailComp from "components/starship/viewCollection/collectionDetail";
import { loadConfig } from "store/redux/slices/helperSlices/config";
import TemplateDetail from "components/starship/template/templateDetail";
import CreateDropComp from "components/starship/drops/createDrop";
import { darkTheme, lightTheme } from "theme";
import { ThemeProvider } from "styled-components";
import { ContractUtility } from "utility";
import AdminloginPage from "pages/starshipPages/adminlogin";
import EditTemplate from "components/starship/template/editTemplate";
import ViewDrops from "components/starship/viewDrops";
import PacksDetail from "components/starship/profile/packsDetail";

const App = () => {
  const { theme } = useAppSelector((state) => state.model);

  const dispatch = useAppDispatch();

  const { web3, account, chainId } = useAppSelector(
    (state) => state.web3Connect
  );
  useEffect(() => {
    dispatch(loadConfig());
  }, []);
  // account switch
  useEffect(() => {
    web3 &&
      window.ethereum.on("accountsChanged", async (data) => {
        dispatch(updateAccount({ account: data[0] }));
      });
  }, [web3]);

  useEffect(() => {
    if (web3) {
      switchNetwork(web3, ContractUtility.getChainXId(chainId));
    }
  }, [web3]);
  UserSigninSignupHook(account);
  const { user } = useAppSelector((state) => state.user);
  return (
    <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
      <Router>
        <GlobalStyle />
        <MainNavbar />

        <div className="main" style={{ overflowX: "hidden" }}>
          <Routes>
            <Route path="/" element={<Explore />} />

            <Route path="/starship/view-drops" element={<ViewDrops />} />

            <Route
              path="/starship/view-collection/detail"
              element={<CollectionDetailComp />}
            />

            <Route
              path="/create-nft"
              element={user.isAdmin ? <CreateNftForm /> : <AdminloginPage />}
            />
            <Route
              path="/nft-details"
              element={user.isAdmin ? <NftDetail /> : <AdminloginPage />}
            />
            <Route path="/starship/explore" element={<Explore />} />

            {/* Starship User Routes End  Here */}

            {/* Stasrhip Admin Routes Start From Here */}
            <Route
              path="/admin/starship"
              element={user.isAdmin ? <StarShipScreen /> : <AdminloginPage />}
            />
            <Route
              path="/starship/secure-drops"
              element={
                user.isAdmin ? <StarshipAdminPage /> : <AdminloginPage />
              }
            />

            <Route
              path="/starship/admin/template"
              element={user.isAdmin ? <TemplateScreen /> : <AdminloginPage />}
            />
            <Route
              path="/starship/admin/schemas"
              element={user.isAdmin ? <SchemaPage /> : <AdminloginPage />}
            />
            <Route
              path="/starship/admin/schemas/create-schema"
              element={user.isAdmin ? <CreateSchemaComp /> : <AdminloginPage />}
            />
            <Route
              path="/starship/admin/template/create-template"
              element={
                user.isAdmin ? <CreateTemplateComp /> : <AdminloginPage />
              }
            />

            <Route
              path="/starship/admin/template/view-template"
              element={user.isAdmin ? <ViewTemplateComp /> : <AdminloginPage />}
            />

            <Route
              path="/starship/admin/template/view-template/detail/:id"
              element={user.isAdmin ? <TemplateDetail /> : <AdminloginPage />}
            />
            <Route
              path="/starship/admin/template/edit-template/detail/:id"
              element={user.isAdmin ? <EditTemplate /> : <AdminloginPage />}
            />

            <Route
              path="/starship/admin/mint-nfts"
              element={user.isAdmin ? <MintNfts /> : <AdminloginPage />}
            />

            <Route
              path="/starship/admin/drops"
              element={user.isAdmin ? <Drops /> : <AdminloginPage />}
            />

            <Route
              path="/starship/admin/create-drop"
              element={user.isAdmin ? <CreateDropComp /> : <AdminloginPage />}
            />
            <Route
              path="/starship/admin/packs"
              element={user.isAdmin ? <Packs /> : <AdminloginPage />}
            />
            <Route
              path="/starship/admin/packs/add"
              element={user.isAdmin ? <CreatePackCom /> : <AdminloginPage />}
            />
            <Route
              path="/starship/admin/packs/:id"
              element={user.isAdmin ? <CreatePackCom /> : <AdminloginPage />}
            />

            <Route
              path="/starship/pack-details/:transaction_hash"
              element={<PacksDetail />}
            />

            {/* Stasrhip Admin Routes End Here */}

            {/* Stasrhip  Routes End Here */}

            <Route path="/user-nft/:id" element={<UserNftDetail />} />
            <Route path="/user-nft/:id/sell" element={<SellNft />} />
            <Route path="/collection/:id" element={<CollectionAll />} />
            <Route path="/create-new-nft" element={<CreateNewNFT />} />
            <Route path="/collections" element={<UsersCollection />} />
            <Route path="/collection-all" element={<MainCollections />} />
            <Route path="/all-nfts" element={<AllNfts />} />
            <Route path="/starship/profile/:id" element={<Profile />} />

            <Route path="/*" element={<Error404 />} />
            <Route path="/profile/*" element={<UserProfile />} />
            <Route path="/profile-setting/*" element={<ProfileSetting />} />
            {/* admin routes */}
            <Route path="/admin-login" element={<AdminLogin />} />
            <Route path="/admin-dashboard/*" element={<AdminDashboard />} />
            <Route path="/admin/nfts" element={<NftsListing />} />
            <Route path="/admin/reports-list" element={<ReportsListing />} />
            <Route path="/admin/category" element={<CategoryListing />} />
            <Route
              path="/admin/update-credentials"
              element={<UpdateCredentials />}
            />
            <Route
              path="/admin/collection-list"
              element={<CollectionListing />}
            />
            <Route path="/create-collection" element={<CreateCollection />} />
          </Routes>
        </div>
      </Router>
    </ThemeProvider>
  );
};

export default App;
