import React, { useState, useRef, FormEvent } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import { useAppDispatch, useAppSelector } from "store/store";
import InputField from "components/common/InputField/inputField";
import {
  resetCategoryData,
  setCategory,
} from "store/redux/slices/categorySlices/categoryDataSlics";
import { categoryValidate } from "components/validation/customValidator";
import {
  CreateCategoryHook,
  DeleteCategoryHook,
  UpdateCategoryHook,
  useForm,
} from "hooks";

const CategoryTable = () => {
  const [productDialog, setProductDialog] = useState(false);
  const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
  const { create, createCateLoading } = CreateCategoryHook(setProductDialog);
  const { update, updateCateLoading } = UpdateCategoryHook(setProductDialog);
  const { deleteItem, removeCateLoading } = DeleteCategoryHook(
    setDeleteProductsDialog
  );
  const { categories, category } = useAppSelector(
    (state) => state.categoryData
  );
  const [globalFilter, setGlobalFilter] = useState();
  const toast = useRef < Toast > null;
  const dispatch = useAppDispatch();

  const callEdit = (data) => {
    dispatch(
      setCategory({
        ...category,
        id: data.id,
        name: data.name,
        instructions: data.instructions,
        update: true,
      })
    );
    setProductDialog(true);
  };

  const hideDeleteProductDialog = () => {
    dispatch(resetCategoryData());
    setDeleteProductsDialog(false);
  };

  const deleteProductDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteProductDialog}
      />
      <Button
        label={removeCateLoading ? "deleting..." : "delete"}
        icon="pi pi-check"
        disabled={removeCateLoading ? true : false}
        className="p-button-text"
        onClick={() => deleteItem()}
      />
    </React.Fragment>
  );

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success mr-2"
          onClick={() => callEdit(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-danger"
          onClick={() => {
            setDeleteProductsDialog(true);
            dispatch(setCategory(rowData));
          }}
        />
      </React.Fragment>
    );
  };

  const openNew = () => {
    dispatch(setCategory({ ...category, update: false }));
    setProductDialog(true);
  };

  const submit = () => {
    if (category.update) {
      update();
    } else {
      create();
    }
  };

  const { handleSubmit, errors } = useForm(submit, categoryValidate, category);

  const hideDialog = () => {
    dispatch(resetCategoryData());
    setProductDialog(false);
  };

  const productDialogFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <Button
        label={createCateLoading || updateCateLoading ? "saving..." : "Save"}
        icon="pi pi-check"
        disabled={createCateLoading || updateCateLoading ? true : false}
        onClick={handleSubmit}
      />
    </React.Fragment>
  );

  const header = (
    // Options for adding , deleting and exporting
    <div className="flex flex-column md:flex-row md:align-items-center justify-content-between">
      <span className="p-input-icon-left w-full md:w-auto">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.currentTarget.value)}
          placeholder="Search..."
          className="w-full lg:w-auto"
        />
      </span>
      <div className="mt-3 md:mt-0 flex justify-content-end">
        <Button
          icon="pi pi-plus"
          className="mr-2 p-button-rounded"
          onClick={openNew}
          tooltip="New"
          tooltipOptions={{ position: "bottom" }}
        />
      </div>
    </div>
  );

  return (
    <div className="datatable-crud-demo surface-card p-4 border-round shadow-2">
      <Toast ref={toast} />
      <div className="text-3xl text-800 font-bold mb-4">
        Category CRUD Table
      </div>
      <DataTable
        value={categories || []}
        dataKey="id"
        paginator
        rows={10}
        rowsPerPageOptions={[5, 10, 25]}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
        globalFilter={globalFilter}
        header={header}
        responsiveLayout="scroll"
      >
        <Column
          field="id"
          header="id"
          sortable
          style={{ minWidth: "12rem" }}
        ></Column>
        <Column
          field="name"
          header="Name"
          sortable
          style={{ minWidth: "16rem" }}
        ></Column>
        <Column
          field="instructions"
          header="Instructions"
          sortable
          style={{ minWidth: "16rem" }}
        ></Column>
        <Column
          body={actionBodyTemplate}
          exportable={false}
          style={{ minWidth: "8rem" }}
        ></Column>
      </DataTable>

      <Dialog
        visible={productDialog}
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "40vw" }}
        header="Product Details"
        modal
        className="p-fluid"
        footer={productDialogFooter}
        onHide={hideDialog}
      >
        <InputField
          value={category.name}
          setValue={(e) =>
            dispatch(setCategory({ ...category, name: e.target.value }))
          }
          inputType="text"
          inputLabel="Name"
          error={errors.name}
          maxLength={15}
          placeholder="name"
        />
        <InputField
          value={category.instructions}
          setValue={(e) =>
            dispatch(setCategory({ ...category, instructions: e.target.value }))
          }
          inputType="text"
          inputLabel="Instructions"
          error={errors.instructions}
          maxLength={15}
          placeholder="Instruction"
        />
      </Dialog>

      <Dialog
        visible={deleteProductsDialog}
        style={{ width: "450px" }}
        header="Confirm"
        modal
        footer={deleteProductDialogFooter}
        onHide={hideDeleteProductDialog}
      >
        <div className="flex align-items-center justify-content-center">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {
            <span>
              Are you sure you want to delete <b>{category.name}</b>?
            </span>
          }
        </div>
      </Dialog>
    </div>
  );
};

export default CategoryTable;
