import { CardsWrapper, DelistBtn, ListBtn, UpdateBtn } from "./element";
import Card from "react-bootstrap/Card";
import { Dropdown, Menu, Modal } from "antd";
import { BiDotsHorizontalRounded, BiTransfer } from "react-icons/bi";
import { AiOutlineCopy, AiOutlineInfoCircle } from "react-icons/ai";
import { useEffect, useState } from "react";
import TransferModal from "components/modal/transferModal";
import SaleModal from "components/modal/saleModal";
import DelistModal from "components/modal/delistModal";
import { CommonUtility, ContractUtility, Rarity } from "utility";
import { useAppSelector } from "store/store";
import { Tooltip } from "antd";
import { useLocation } from "react-router-dom";
import { ViewOffersModal } from "components/modal";
import { ToastMessage } from "components/common";
import { GetTokenPriceInUsd } from "utility/usdPrice";

function ProfileCard({ item, index, getNftsByUser }) {
  const [open, setopen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [amount, setAmount] = useState("");
  const [isDelistModalOpen, setIsDelistModalOpen] = useState(false);
  const { web3, chainId } = useAppSelector((state) => state.web3Connect);
  const { config } = useAppSelector((state) => state.configData);
  const [usdPrice, setUsdPrice] = useState(Number);
  const infoData = [
    {
      title: "Mound of Charcoal",
      rarity: "-",
      collection: "funmangalaxy",
      templateid: "532520",
      burnable: "Yes",
      transable: "yes",
      issued: "3,498 / ∞",
      price: "2.37 ETH ($0.13)",
      soldprice: "2.37 ETH ($0.13)",
      listingprice: "2.37 ETH ($0.13)",
    },
  ];
  const [showInfo, setShowInfo] = useState(Array(infoData.length).fill(false));
  const [nftData, setNftData] = useState(null);
  const location = useLocation();
  const toggleInfo = (index) => {
    const updatedInfoState = [...showInfo];
    updatedInfoState[index] = !updatedInfoState[index];
    setShowInfo(updatedInfoState);
  };
  const showModal = () => {
    setopen(true);
  };
  const showDelistModal = () => {
    setIsDelistModalOpen(true);
  };
  const handleCancel = () => {
    setopen(false);
    setIsModalOpen(false);
    setIsDelistModalOpen(false);
    setIsOffersModalOpen(false);
    setAmount("");
  };
  const showSellModal = (data) => {
    setIsModalOpen(true);
    setNftData(data);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const [isOffersModalOpen, setIsOffersModalOpen] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      const price = await GetTokenPriceInUsd(config, chainId);
      setUsdPrice(+price);
    };
    fetchData();
  }, [web3, chainId]);
  const showOffersModal = () => {
    setIsOffersModalOpen(true);
  };
  function copyToClipboard(text) {
    navigator.clipboard.writeText(`${text}`).then(
      function () {
        console.log("Text copied to clipboard");
        ToastMessage("Success!", "Text copied to clipboard", "success");
      },
      function (err) {
        console.error("Could not copy text: ", err);
      }
    );
  }

  const menu = (
    <Menu className="explore-menu">
      <Menu.Item
        key="1"
        onClick={showOffersModal}
        disabled={!item?.is_transferable}
      >
        <div className="items-wrapper">
          <p className="explore-text">View Offers</p>
          <AiOutlineCopy fontSize={20} color="#6B6B6B" />
        </div>
      </Menu.Item>
      <Menu.Item key="2" onClick={showModal} disabled={!item?.is_transferable}>
        <div className="items-wrapper">
          <p className="explore-text">Transfer</p>
          <BiTransfer fontSize={20} color="#6B6B6B" />
        </div>
      </Menu.Item>
    </Menu>
  );
  const cardTitle = item?.attributes?.find(
    (obj) => obj?.attribute_type?.toLowerCase() === "name"
  )?.attribute_value;
  const findObjectWithLowestAmount = (listings) => {
    let lowestObject = null;

    for (const listing of listings) {
      if (
        !lowestObject ||
        parseFloat(listing.amount) < parseFloat(lowestObject.amount)
      ) {
        lowestObject = listing;
      }
    }

    return lowestObject;
  };

  return (
    <>
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        width={"455px"}
      >
        <SaleModal
          handleCancel={handleCancel}
          nftData={nftData}
          getNftsByUser={getNftsByUser}
          amount={amount}
          setAmount={setAmount}
        />
      </Modal>
      <Modal open={open} onCancel={handleCancel}>
        <TransferModal
          handleCancel={handleCancel}
          item={item}
          getNftsByUser={getNftsByUser}
        />
      </Modal>

      <Modal open={isDelistModalOpen} onCancel={handleCancel} centered>
        <DelistModal
          handleCancel={handleCancel}
          item={item}
          getNftsByUser={getNftsByUser}
        />
      </Modal>

      <Modal
        open={isOffersModalOpen}
        onCancel={handleCancel}
        centered
        width={958}
        closable={true}
        className="offer-modal"
      >
        <ViewOffersModal
          item={item}
          closeModal={handleCancel}
          page={undefined}
          filter={undefined}
          getMints={undefined}
          refresh={undefined}
          usdPrice={usdPrice}
        />
      </Modal>
      <CardsWrapper>
        <Card>
          <Card.Body className="reduced-padding">
            <div className="icons-div">
              <AiOutlineInfoCircle
                fontSize={18}
                cursor="pointer"
                color=" #6b6b6b"
                onClick={() => toggleInfo(index)}
              />
            </div>
          </Card.Body>

          <>
            <div className="img-wrapper">
              <Card.Img
                src={item?.image}
                onError={(e) => {
                  e.target.src = item?.image;
                }}
                width={230}
                height={264}
              />
            </div>
            <Card.Body>
              <Card.Title>
                {cardTitle?.length > 18 ? (
                  <Tooltip
                    placement="topLeft"
                    title={cardTitle}
                    color="#00b7ff"
                  >
                    <span className="card-title-span">
                      {cardTitle.slice(0, 18) + " ..."}
                    </span>
                  </Tooltip>
                ) : (
                  cardTitle
                )}
              </Card.Title>
              <p className="test-text">{item?.schema_name}</p>
              <p
                className="test-text"
                onClick={() => copyToClipboard(item?.nft_id)}
                style={{ cursor: "pointer" }}
              >
                # {""}
                {CommonUtility.nftIdConvertor(item?.nft_id)}{" "}
              </p>

              {item?.signed_order ? (
                <div className="btn-wrapper">
                  <DelistBtn onClick={showDelistModal}>Delist</DelistBtn>
                  <UpdateBtn onClick={() => showSellModal(item)}>
                    Update
                  </UpdateBtn>
                </div>
              ) : (
                <>
                  <ListBtn
                    onClick={() => showSellModal(item)}
                    disabled={!item?.is_transferable}
                  >
                    list on market
                  </ListBtn>
                </>
              )}

              <Dropdown
                overlay={menu}
                trigger={["click"]}
                placement="bottomRight"
              >
                <div className="icons-div">
                  <BiDotsHorizontalRounded
                    fontSize={20}
                    cursor="pointer"
                    color=" #6b6b6b"
                  />
                </div>
              </Dropdown>
            </Card.Body>
            {showInfo[index] && (
              <div
                className={`${
                  (location.pathname.startsWith("/starship/profile") ||
                    location.pathname.startsWith("/starship/pack-details")) &&
                  "reduced-width"
                } "additional-content"`}
              >
                <>
                  <div className="d-flex justify-content-between ">
                    <h5>Title:</h5>
                    <p>
                      {" "}
                      {
                        item?.attributes?.find(
                          (obj) => obj?.attribute_type?.toLowerCase() === "name"
                        )?.attribute_value
                      }
                    </p>
                  </div>

                  {item.attributes &&
                    item.attributes.some(
                      (obj) =>
                        obj.attribute_type?.toLowerCase() ===
                        Rarity?.toLowerCase()
                    ) && (
                      <div className="d-flex justify-content-between mt-3">
                        <h5>Rarity:</h5>
                        <p>
                          {item.attributes.find(
                            (obj) =>
                              obj?.attribute_type?.toLowerCase() ===
                              Rarity?.toLowerCase()
                          )?.attribute_value || "-"}
                        </p>
                      </div>
                    )}
                  <div className="d-flex justify-content-between mt-3">
                    <h5>Collection:</h5>
                    <p>{item?.schema_name}</p>
                  </div>
                  <div className="d-flex justify-content-between mt-3">
                    <h5>Template ID:</h5>
                    <p>{item?.template_id?.nft_id}</p>
                  </div>
                  <div className="d-flex justify-content-between mt-3">
                    <h5>Is burnable:</h5>
                    <p>{item?.is_burnable ? "Yes" : "No"}</p>
                  </div>
                  <div className="d-flex justify-content-between mt-3">
                    <h5>Is transferable:</h5>
                    <p>{item?.is_transferable ? "Yes" : "No"}</p>
                  </div>
                  <div className="d-flex justify-content-between mt-3">
                    <h5>Issued:</h5>
                    <p>
                      {" "}
                      {item?.template_id?.current_supply} /{" "}
                      {+item?.template_id?.max_supply == 0 ||
                      +item?.template_id?.max_supply == +config?.UINT256_MAX
                        ? "∞"
                        : item?.template_id?.max_supply}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between mt-3">
                    <h5>Price:</h5>
                    <h4>
                      {" "}
                      {item?.signed_order
                        ? CommonUtility.calculateTotalFeesAndAmount(
                            item?.signed_order,
                            ContractUtility.getPaymentToken(
                              item?.signed_order?.erc20Token,
                              chainId || config?.DEFAULT_CHAIN_ID
                            )?.decimals
                          ) +
                          ContractUtility.getPaymentToken(
                            item?.signed_order?.erc20Token,
                            chainId || config?.DEFAULT_CHAIN_ID
                          )?.symbol
                        : "0"}
                    </h4>
                  </div>
                  <div className="d-flex justify-content-between mt-3">
                    <h5>Last sold price:</h5>
                    <h4>
                      {" "}
                      {item?.price ? (
                        <>
                          {CommonUtility.convertFromWei(
                            item?.price.price,
                            ContractUtility.getPaymentToken(
                              item?.price.payment_token,
                              chainId || config?.DEFAULT_CHAIN_ID
                            )?.decimals,
                            false
                          )}{" "}
                          {
                            ContractUtility.getPaymentToken(
                              item?.price.payment_token,
                              chainId || config?.DEFAULT_CHAIN_ID
                            )?.symbol
                          }
                        </>
                      ) : (
                        "---"
                      )}
                    </h4>
                  </div>
                  <div className="d-flex justify-content-between mt-3">
                    <h5>Lowest listing price:</h5>
                    {item?.listings?.length ? (
                      <h4>
                        {`${CommonUtility.convertFromWei(
                          findObjectWithLowestAmount(item?.listings).amount,
                          ContractUtility.getPaymentToken(
                            findObjectWithLowestAmount(item?.listings).token,
                            chainId || config?.DEFAULT_CHAIN_ID
                          )?.decimals,
                          false
                        )} ${
                          ContractUtility.getPaymentToken(
                            findObjectWithLowestAmount(item?.listings).token,
                            chainId || config?.DEFAULT_CHAIN_ID
                          )?.symbol
                        } `}
                      </h4>
                    ) : (
                      <h4>---</h4>
                    )}
                  </div>
                </>
              </div>
            )}
          </>
        </Card>
      </CardsWrapper>
    </>
  );
}

export default ProfileCard;
