import styled from "styled-components";
import { Row, Col, Container, Card, ProgressBar } from "react-bootstrap";
import { Collapse, Tooltip } from "antd";

export const MainContainer = styled.div`
  margin: 4rem 4rem;
`;
export const MainRow = styled(Row)``;
export const MainCol = styled(Col)``;

export const MainCard = styled(Card)`
  margin: 0.5rem 0;
`;
export const MainCardBody = styled(Card.Body)`
  padding: 0.8rem;
`;
export const MainTitle = styled(Card.Title)`
  margin-top: 1rem;
`;

export const MainText = styled(Card.Text)``;
export const ProfitValue = styled(Card.Text)`
  text-align: end;
  margin-top: 1rem;
  color: #5046bb;
`;
export const MainProgress = styled(ProgressBar)`
  margin-top: -0.9rem;
  .progress-bar {
    background: #5046bb;
    border-radius: 18px;
  }
`;

export const ExpenseCard = styled(Card)`
  margin-top: 0rem;
  border: none;
`;
export const ExpenseCardHeader = styled(Card.Header)`
  padding: 0.9rem;
  font-size: 18px;
`;
export const ExpenseCardBody = styled(Card.Body)``;
export const ExpenseTitle = styled(Card.Text)`
  margin-top: 0rem;
  margin-left: 1rem;
`;

export const RevenueCard = styled(Card)`
  margin-top: 2rem;
`;
export const RevenueCardHeader = styled(Card.Header)`
  padding: 0.9rem;
  font-size: 18px;
  & span
  {
    font-size: 12px;

  }
`;
export const RevenueCardBody = styled(Card.Body)``;
export const RevenueTitle = styled(Card.Title)`
  margin-top: 1rem;
`;

export const MainCollapse = styled(Collapse)``;
export const MainCollapsePanel = styled(Collapse.Panel)``;
