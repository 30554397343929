import { PackCard } from "components/common/cards";
import { CreatePackBtn, NoPacksFoundDiv } from "./element";
import { Image } from "react-bootstrap";
import { nodata } from "assets";
import CreatePackCom from "./createPackCom";
import { useNavigate } from "react-router-dom";

function PacksTabCom({ cardsData, packs, getPacks }) {
  const navigate = useNavigate();
  return (
    <div>
      {cardsData === 0 ? (
        <NoPacksFoundDiv>
          <Image src={nodata} />
          <h4>No Packs</h4>
          <p>
            no pack found for collection “starship NFT game” at the moment...{" "}
          </p>
          <CreatePackBtn onClick={() => navigate("/starship/admin/packs/add")}>
            Create Pack
          </CreatePackBtn>
        </NoPacksFoundDiv>
      ) : (
        <>
          <PackCard packs={packs} getPacks={getPacks} />
        </>
      )}
    </div>
  );
}

export default PacksTabCom;
