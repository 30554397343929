import { APIPath } from "utility/constant";
import { BaseService } from "./baseServices";

class MerkleData {
  getMerkleData = (addresses) => {
    return BaseService.get(
      `${APIPath.getMerkleData}?addresses=${JSON.stringify(addresses)}`
    );
  };
}

const MerkleDataService = new MerkleData();
Object.freeze(MerkleDataService);
export { MerkleDataService };
