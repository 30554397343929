const handleChainId = (chanId) => {
  switch ((chanId || "").toString()) {
    //ethereum
    case "1":
      return "0x1";
    //ropsten
    case "3":
      return "0x3";
    // rinkeby
    case "4":
      return "0x4";
    //polygon mumbai
    case "80001":
      return "0x13881";
    //polygon matic
    case "137":
      return "0x89";
    //binance
    case "56":
      return "0x56";
    case "0x61":
      return "0x97";
    default:
      return "Uknown";
  }
};
export const switchNetwork = async (web3, chanId) => {
  try {
    await web3.currentProvider.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: handleChainId(chanId) }],
    });
  } catch (error) {
    console.log("error", error);
  }
};
