// import { APIPath, CommonUtility } from 'utility';
import { APIPath, CommonUtility } from "../../utility";
import { BaseService } from "./base";

class NFT {
  userNFTs = (reqData) => {
    const url = `${APIPath.assets}?${CommonUtility.objectToParams(reqData)}`;
    return BaseService.openseaAPI(url);
  };

  moralisNFTs = (address, reqData) => {
    const url = `${address}/nft?${CommonUtility.objectToParams(reqData)}`;
    return BaseService.moralisAPI(url);
  };

  moralisByTokenAddress = (tokenAddress, tokenId, reqData) => {
    const url = `nft/${tokenAddress}/${tokenId}?${CommonUtility.objectToParams(
      reqData
    )}`;
    return BaseService.moralisAPI(url);
  };

  transactions = (token_address, token_id, reqData) => {
    const url = `nft/${token_address}/${token_id}/transfers?${CommonUtility.objectToParams(
      reqData
    )}`;
    return BaseService.moralisAPI(url);
  };

  metadataLink = (url) => {
    return BaseService.extenralAPICall(url);
  };
  byTokenAddress = (reqData) => {
    const url = `${APIPath.assets}?${CommonUtility.objectToParams(reqData)}`;
    return BaseService.openseaAPI(url);
  };
  moralistTransactions = (token_address, token_id, reqData) => {
    const url = `nft/${token_address}/${token_id}/transfers?${CommonUtility.objectToParams(
      reqData
    )}`;
    return BaseService.moralisAPI(url);
  };
  moralisCollection = (token_address, reqData) => {
    const url = `nft/${token_address}/?${CommonUtility.objectToParams(
      reqData
    )}`;
    return BaseService.moralisAPI(url);
  };
  metadata = (url) => {
    return new Promise(async (resolve) => {
      try {
        await CommonUtility.timeoutPromise(1000);
        const result = await BaseService.extenralAPICall(url);
        resolve(result);
      } catch (error) {
        resolve({});
      }
    });
  };
}

const NFTService = new NFT();
Object.freeze(NFTService);
export { NFTService };
