import { Loader } from "components/common";
import { GetRewardsHook } from "hooks";
import { RewardsTable } from "../components";

const RewardsListing = () => {
  const { data, rewardLoading } = GetRewardsHook();
  return (
    <div>
      {rewardLoading ? (
        <Loader content="Loading rewards..." />
      ) : (
        <RewardsTable data={data} />
      )}
    </div>
  );
};

export default RewardsListing;
