import { createSlice } from "@reduxjs/toolkit";


export const updateCredentialsSlice = createSlice({
    name: "credentials data",
    initialState: {
        credentials: {
            username: "",
            current_password: "",
            new_password: "",
            confirm_password: ""
        }
    },
    reducers: {
        setUpdatedCredentials: (state, action) => {
            return {
                ...state,
                credentials: { ...action.payload }
            }
        },
        resetPassword: (state) => {
            return {
                ...state,
                credentials: {
                    ...state.credentials,
                    current_password: "",
                    new_password: "",
                    confirm_password: ""
                }
            }
        }
    }
});

export const { setUpdatedCredentials, resetPassword } = updateCredentialsSlice.actions;
export const updateCredentialsReducer = updateCredentialsSlice.reducer;
