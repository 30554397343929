import {
  CommonUtility,
  DateUtility,
  currentDateInSeconds,
  regex,
} from "utility";

const nftValidate = (values) => {
  const errors = {};

  if (!values.name.trim()) {
    errors.name = " Name is required";
  }
  if (!values.image.trim()) {
    errors.image = "Image is required";
  }
  if (
    values.externalLink.trim() &&
    !CommonUtility.validatePattern(regex.URL, values?.externalLink)
  ) {
    errors.externalLink = "invalid url";
  }
  if (!values.networkId) {
    errors.networkId = "Network is required";
  }
  if (!values.ownerAddress.trim()) {
    errors.ownerAddress = "Owner address is required";
  } else if (
    !CommonUtility.validatePattern(regex.CONTRACT_ADDRESS, values.ownerAddress)
  ) {
    errors.ownerAddress = "Owner address is required";
  }
  return errors;
};

const credentialsValidate = (values) => {
  const errors = {};

  if (!values.username.trim()) {
    errors.username = "Username is required";
  }
  if (!values.password.trim()) {
    errors.password = "Password is required";
  }
  return errors;
};

const updateUsernameValidate = (values) => {
  const errors = {};

  if (!values.username.trim()) {
    errors.username = "Username is required";
  }
  return errors;
};

const updatePasswordValidate = (values) => {
  const errors = {};

  if (!values.current_password.trim()) {
    errors.current_password = "Current password is required";
  }
  if (!values.new_password.trim()) {
    errors.new_password = "New password is required";
  }
  if (!values.confirm_password.trim()) {
    errors.confirm_password = "Confirm password is required";
  } else if (values.new_password != values.confirm_password) {
    errors.confirm_password = "Password not matched";
  }

  return errors;
};

const collectionValidate = (values) => {
  const errors = {};

  if (!values.name.trim()) {
    errors.name = " Name is required";
  }
  if (!values.logo) {
    errors.logo = "Logo is required";
  }
  if (!values.description.trim()) {
    errors.description = "Description Address is required";
  }

  if (!values.featuredImage) {
    errors.featuredImage = "Featured image is required";
  }
  if (!values.network) {
    errors.network = "Network is required";
  }
  if (!values.paymentToken.trim()) {
    errors.paymentToken = "Payment token is required";
  }
  if (!values.bannerImage) {
    errors.bannerImage = "Banner image is required";
  }
  return errors;
};

const categoryValidate = (values) => {
  const errors = {};

  if (!values.name.trim()) {
    errors.name = "Name is required";
  }
  if (!values.instructions.trim()) {
    errors.instructions = "Instructions is required";
  }
  return errors;
};

const sellValidate = (values) => {
  const errors = {};

  if (!values.tokenAddress.trim()) {
    errors.tokenAddress = "Token Address is required";
  }

  if (!values.amount.trim()) {
    errors.amount = "Amount is required";
  } else if (values.amount < 0) {
    errors.amount = "Price cannot be negative";
  }
  return errors;
};

const userValidate = (values) => {
  const errors = {};

  if (!values.name.trim()) {
    errors.name = "Name is required";
  }

  if (!values.email.trim()) {
    errors.email = "Email is required";
  } else if (CommonUtility.validatePattern(regex.EMAIL, values.email)) {
    errors.email = "Invalid email";
  }
  if (!values.profImage.trim()) {
    errors.profImage = "Profile Image is required";
  }
  if (!values.coverImage.trim()) {
    errors.coverImage = "Cover Image is required";
  }
  if (!values.walletAddress.trim()) {
    errors.walletAddress = "wallet address is required";
  } else if (
    !CommonUtility.validatePattern(regex.CONTRACT_ADDRESS, values.walletAddress)
  ) {
    errors.walletAddress = "Invalid wallet address";
  }
  return errors;
};

const moralisCollectionValidate = (values) => {
  const errors = {};

  if (!values.protocol.toString()?.trim()) {
    errors.protocol = "Select blockchain";
  }
  if (!values.address.trim()) {
    errors.address = "token address is required";
  } else if (
    !CommonUtility.validatePattern(regex.CONTRACT_ADDRESS, values.address)
  ) {
    errors.address = "Invalid token address";
  }
  return errors;
};

const reportValidation = (values) => {
  const errors = {};

  if (!values?.title?.trim()) {
    errors.title = "Title is required";
  }
  if (!values?.message?.trim()) {
    errors.message = "Message is required";
  }
  return errors;
};

const validatePack = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = "required";
  }
  // if (!values.schema_id) {
  //   errors.schema_id = "required";
  // }
  if (!values.description?.trim()) {
    errors.description = "required";
  }

  if (values.pack_slots) {
    if (!Array.isArray(values.pack_slots)) {
      errors.pack_slots = "Invalid pack_slots format";
    } else if (values.pack_slots.length === 0) {
      errors.pack_slots = "Pack slots cannot be empty";
    } else {
      const packSlotErrors = values.pack_slots
        .map((slot, slotIndex) => {
          // Check if slot is an array before mapping over it
          if (!Array.isArray(slot)) {
            return [`Slot ${slotIndex + 1} is not properly formatted`];
          }

          return slot
            .map((template, templateIndex) => {
              if (!template.template_id || template.template_id.trim() === "") {
                return `Fill template in slot ${slotIndex + 1}, row ${
                  templateIndex + 1
                } or delete it`;
              }
              return null;
            })
            .filter((error) => error !== null);
        })
        .filter((slotErrors) => slotErrors.length > 0);

      if (packSlotErrors.length > 0) {
        errors.pack_slots = packSlotErrors;
      }
    }
  }

  return errors;
};

const validateDrop = (values) => {
  const errors = {};
  let start_time = DateUtility.convertDateToUnix(values.start_time);
  let end_time = DateUtility.convertDateToUnix(values.end_time);

  let reveal_time = DateUtility.convertDateToUnix(values.reveal_time);
  if (!values.name?.trim()) {
    errors.name = "required";
  }
  if (!values.pack_id) {
    errors.pack_id = "required";
  }
  if (!values.description?.trim()) {
    errors.description = "required";
  }

  if (!values.start_time) {
    errors.start_time = "required";
  } else if (values.start_time && start_time <= currentDateInSeconds) {
    errors.start_time = "Should be Greater Than Current Date And Time";
  }
  if (!values.cool_down_time && values.cool_down_time !== 0) {
    errors.cool_down_time = "required";
  }
  if (!values.end_time) {
    errors.end_time = "required";
  } else if (values.end_time && end_time <= start_time) {
    errors.end_time = "Should be Greater Than mint start time";
  }
  if (!values.image_name?.trim()) {
    errors.image_name = "required";
  }
  if (!values.price && values.price !== 0) {
    errors.price = "required";
  }
  if (!values.payment_token) {
    errors.payment_token = "required";
  }
  if (!values.max_supply && values.max_supply !== 0) {
    errors.max_supply = "required";
  }

  // if (!values.reveal_time) {
  //   errors.reveal_time = "required";
  // } else if (values.reveal_time && reveal_time < end_time) {
  //   errors.reveal_time = "Should be Greater Than mint end time";
  // }

  return errors;
};

export {
  nftValidate,
  credentialsValidate,
  updateUsernameValidate,
  userValidate,
  updatePasswordValidate,
  collectionValidate,
  categoryValidate,
  sellValidate,
  moralisCollectionValidate,
  reportValidation,
  validatePack,
  validateDrop,
};
