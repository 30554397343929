import { MainCol, MainRow, ToastMessage } from "components/common";
import {
  ButtonsDiv,
  CancelBtn,
  ContentComWrapper,
  MainHeader,
  MintNftsBtn,
  SelectDiv,
  SubText,
} from "./element";
import { Image } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { useState } from "react";
import { Modal } from "antd";
import { GetTemplateByIdHook } from "hooks/templateHook";
import { useAppSelector } from "store/store";
import Loading from "components/common/loader";
import { CommonUtility } from "utility";
import { starshipAbi } from "contract/starship";
import MintNFTsModal from "components/modal/mintNFTsModal";
import { useNavigate } from "react-router-dom";
import { ConnectBtn } from "../navbar/elements";
import { StarShipConnectWalletModal } from "components/modal";
import ConnectModals from "components/modal/connectModal";

function MintNFTsContentCom({ template, setSchema, setTemplate }) {
  const { data, loading } = GetTemplateByIdHook(template);
  const navigate = useNavigate();
  const { web3, account, chainId } = useAppSelector(
    (state) => state.web3Connect
  );

  const { config } = useAppSelector((state) => state.configData);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [assetsToMint, setAssetsToMint] = useState(Number);
  const [nftsReceiver, setNftsReceiver] = useState("");
  const [hash, setHash] = useState("");

  const [isValidAddress, setIsValidAddress] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const showModal2 = () => {
    setIsModalOpen2(true);
  };

  const handleInputChange = (event) => {
    const isValidAddress = CommonUtility.isEthereumAddress(event.target.value);
    setIsValidAddress(isValidAddress);
    setNftsReceiver(event.target.value);
  };
  const handleAssestChange = (event) => {
    setAssetsToMint(event.target.value);
  };
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setSchema("");
    setTemplate("");
  };
  const handleCancel2 = () => {
    setIsModalOpen2(false);
  };
  const mintNfts = async () => {
    try {
      setIsLoading(true);
      const starShip = config.STAR_SHIP_ADDRESS;

      const contract = CommonUtility.contract(
        web3,
        starshipAbi,
        starShip[chainId]
      );
      const adminRole = await contract.methods.ADMIN_ROLE().call();
      const hasRole = await contract.methods.hasRole(adminRole, account).call();
      if (!hasRole) throw "Only admin can mint ";
      const templateInfo = await contract.methods
        .getSchemaInfo(data?.nft_id)
        .call();

      if (+templateInfo?.nftMinted + +assetsToMint > +templateInfo?.maxSupply)
        throw `${
          +templateInfo?.maxSupply - +templateInfo?.nftMinted
        } nfts remaining`;
      if (+assetsToMint > 400) throw "Only 400 nfts are allowed at one time";
      const txn = await contract.methods
        .batchUserMint([nftsReceiver], [assetsToMint], data?.nft_id)
        .send({ from: account });
      if (txn && txn.code == 4001) throw "User denied the transaction";
      if (!txn.status) throw "Transaction Failed";
      if (txn.status) {
        setHash(txn?.transactionHash);
        setIsLoading(false);
        showModal();
      }
    } catch (error) {
      setIsLoading(false);
      console.log("error", error);
      if (error?.message) {
        ToastMessage("Error", error?.message, "error");
      } else {
        ToastMessage("Error", error, "error");
      }
    }
  };

  return (
    <ContentComWrapper>
      {(isLoading || loading) && <Loading content={"Loading"} />}
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        width={481}
      >
        <MintNFTsModal
          handleCancel={handleCancel}
          packsmodal={undefined}
          setCardsData={undefined}
          dropmodal={undefined}
          hash={hash}
          setSchema={setSchema}
          setTemplate={setTemplate}
        />
      </Modal>

      {/* <Modal
        open={isModalOpen2}
        onOk={handleOk}
        onCancel={handleCancel2}
        centered
        width={"489px"}
      >
        <StarShipConnectWalletModal
          handleCancel={handleCancel2}
          setIsModalOpen={setIsModalOpen2}
        />
      </Modal> */}
      <Modal open={isModalOpen2} centered onCancel={handleCancel2} width={504}>
        <ConnectModals onCancel={handleCancel2} />
      </Modal>
      <MainRow>
        <MainCol lg={7}>
          <MainHeader className="no-margin">template Properties</MainHeader>
          {data?.template_attributes.map(
            (x) =>
              x.field !== "file" && (
                <>
                  <MainRow>
                    <MainCol lg={2}>
                      <SubText className="mt-3">{x.attribute_type}</SubText>
                    </MainCol>
                    <MainCol lg={6}>
                      <SubText className="mt-3" style={{ fontWeight: "500" }}>
                        {x.attribute_value}
                      </SubText>
                    </MainCol>
                  </MainRow>
                </>
              )
          )}
        </MainCol>

        <MainCol lg={4}>
          <Image
            src={data?.ipfs_image}
            className="mt-3"
            fluid
            width={410}
            height={297}
          />
        </MainCol>
      </MainRow>

      <MainRow>
        <MainHeader className="no-margin mt-4">Assets To Mint</MainHeader>

        <SelectDiv className="no-margin">
          <MainRow className="select-row">
            <MainCol sm={6}>
              <Form.Label>Assets to mint</Form.Label>
              <Form.Control
                type="number"
                placeholder="1"
                min={0}
                value={assetsToMint}
                onChange={handleAssestChange}
              />
            </MainCol>
            <MainCol sm={6}>
              <Form.Label>Account to receive NFTs</Form.Label>
              <Form.Control
                placeholder="0xdAf524po........."
                value={nftsReceiver}
                onChange={handleInputChange}
              />
            </MainCol>
          </MainRow>
        </SelectDiv>
      </MainRow>
      <ButtonsDiv>
        <CancelBtn onClick={() => navigate(-1)}>Cancel</CancelBtn>
        {/* {!account ? (
          <ConnectBtn onClick={showModal2}>Connect Wallet</ConnectBtn>
        ) : ( */}
        <MintNftsBtn
          onClick={mintNfts}
          disabled={!isValidAddress || +assetsToMint == 0}
        >
          Mint NFTs
        </MintNftsBtn>
        {/* )} */}
      </ButtonsDiv>
    </ContentComWrapper>
  );
}

export default MintNFTsContentCom;
