import { MainContainer } from "components/common";
import { useEffect, useState } from "react";
import { MainSection, NoDataFoundComDiv } from "./elements";
import DropCard from "components/common/cards/dropCard";
import { GetViewAllDropsHook } from "hooks/dropHook";
import { Select } from "antd";
import { ViewDropTypes } from "utility";
import Skeleton from "react-loading-skeleton";
import { useAppSelector } from "store/store";
import NoDataFoundCom from "../noDataFound";
const ViewDrops = () => {
  const [page, setPage] = useState(30);
  const [filter, setFilter] = useState({});

  const { data: drops, loading, dropCount } = GetViewAllDropsHook(page, filter);

  const handleScroll = () => {
    const scrollPosition = window.innerHeight + window.pageYOffset;
    const scrollHeight = document.documentElement.scrollHeight;
    if (scrollPosition >= scrollHeight - 180 && !loading) {
      if (page < dropCount) {
        setPage(page + 20);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [drops, page, dropCount, loading]);

  const { theme } = useAppSelector((state) => state.model);

  return (
    <MainSection>
      <MainContainer fluid>
        <div className="select-div">
          <Select
            bordered={false}
            className="first-explore-select"
            popupClassName="first-explore-select-dropdown"
            defaultValue="All"
            style={{ width: 140 }}
            allowClear={false}
            placeholder="Select"
            onChange={(e) => {
              setFilter({
                ...filter,
                status: e,
              });
            }}
            options={[
              { value: ViewDropTypes.All, label: ViewDropTypes.All },
              { value: ViewDropTypes.Upcoming, label: ViewDropTypes.Upcoming },
              { value: ViewDropTypes.Live, label: ViewDropTypes.Live },
              { value: ViewDropTypes.Ended, label: ViewDropTypes.Ended },
            ]}
          />
        </div>

        <div className="card-wrapper">
          {loading &&
            [2, 3, 4, 5, 6, 7, 8, 9, 10, 1, 2, 3, 4, 4, 5, 6, 7, 8].map(() => (
              <Skeleton
                width="100%"
                height="303px"
                className="mt-4"
                borderRadius="12px"
                baseColor={theme === "light" ? "#ebebeb" : "transparent"}
                style={{
                  border:
                    theme === "light"
                      ? "1px solid #ebebeb"
                      : "1px solid #727989",
                }}
              />
            ))}
          {!loading && drops?.map((item) => <DropCard item={item} />)}
        </div>

        {!loading && drops?.length === 0 && (
          <NoDataFoundComDiv>
            <NoDataFoundCom message={"No Drops Found "} />
          </NoDataFoundComDiv>
        )}
      </MainContainer>
    </MainSection>
  );
};

export default ViewDrops;
