import React, { useState, useEffect } from "react";
import { Layout, Menu } from "antd";
import Main from "components/mainDashboard";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Header from "./header";
import { useAppDispatch, useAppSelector } from "store/store";
import { collapsedDashboard } from "store/redux/slices/helperSlices/modelSlice";
import { Routes, Route } from "react-router-dom";
import {
  TagsOutlined,
  HomeOutlined,
  RadiusBottomleftOutlined,
} from "@ant-design/icons";
import useWindowDimensions from "./useDimensionhook";
import * as D from "./adminDashboardElement";
import { AuthenticateHook } from "hooks";
import ReportsTable from "components/reportsTable";
import { NftsListing, RewardsListing } from "pages";

const Dashboard = () => {
  // getting current path
  const location = useLocation();
  const { pathname } = location;
  const currentPathname = pathname?.split("/")[2];
  const { collapsed } = useAppSelector((state) => state.model);
  const { Sider, Content } = Layout;
  const [triggersx, setTrigger] = useState(false);
  const dispatch = useAppDispatch();
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const { error } = AuthenticateHook();

  useEffect(() => {
    error && navigate("/admin-login");
  }, [error]);

  useEffect(() => {
    if (width <= 400) {
      setTrigger(true);
      dispatch(collapsedDashboard(false));
    } else if (width <= 600) {
      setTrigger(true);
      dispatch(collapsedDashboard(false));
    } else if (width >= 1200) {
      const element = document.querySelectorAll(
        ".ant-layout-sider .ant-layout-sider-dark"
      );

      dispatch(collapsedDashboard(true));
      setTrigger(false);
    } else if (width >= 1450) {
      dispatch(collapsedDashboard(false));
      setTrigger(true);
    }
  }, [width]);

  return (
    <Layout>
      <Sider
        className={triggersx ? "antRes" : ""}
        trigger={null}
        collapsible
        collapsed={collapsed}
      >
        <div className="logo "></div>
        <Menu mode="inline" defaultSelectedKeys={["1"]}>
          <Menu.Item key="1" icon={<HomeOutlined />}>
            <span>Dashboard </span>
            <Link to="/admin-dashboard"> </Link>
          </Menu.Item>

          <Menu.Item key="2" icon={<TagsOutlined />}>
            <span>Report List </span>{" "}
            <Link to="/admin-dashboard/reports-list"> </Link>
          </Menu.Item>

          <Menu.Item key="3" icon={<RadiusBottomleftOutlined />}>
            <span>Nfts </span> <Link to="/admin-dashboard/nfts"> </Link>
          </Menu.Item>

          <Menu.Item key="4" icon={<RadiusBottomleftOutlined />}>
            <span>rewards </span> <Link to="/admin-dashboard/rewards"> </Link>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header />
        <Content
          className="contentx"
          style={{
            minHeight: "100vh",
          }}
        >
          <hr style={{ marginTop: "-0.2rem" }} />
          <div style={{ display: "flex", alignItems: "center" }}>
            <D.DashboardTitle>
              Dashboard <span></span>
            </D.DashboardTitle>
            <D.DashboardDesc>
              {" "}
              {currentPathname === undefined ? "Home" : currentPathname} /
              Dashboard
            </D.DashboardDesc>
          </div>

          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="reports-list" element={<ReportsTable />} />
            <Route path="nfts" element={<NftsListing />} />
            <Route path="rewards" element={<RewardsListing />} />
          </Routes>
        </Content>
      </Layout>
    </Layout>
  );
};

export default Dashboard;
