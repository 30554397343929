import { APIPath } from "utility/constant";
import { BaseService } from "./baseServices";

class Favorite {
  updateFavorite = (data) => {
    return BaseService.post(APIPath.updateFavorite, data);
  };
  getUserFavoriteNfts = (userAddrees) => {
    return BaseService.get(
      `${APIPath.getUserFavoriteNfts}?userAddrees=${userAddrees}`
    );
  };
  getNftFavorites = (userAddrees, page) => {
    return BaseService.get(
      `${APIPath.getNftFavorites}?userAddrees=${userAddrees}&page=${page}`
    );
  };
}

const FavoriteService = new Favorite();
Object.freeze(FavoriteService);
export { FavoriteService };
