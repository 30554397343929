import { useEffect, useState } from "react";
import {
  BreadCrumbComp,
  MainCol,
  MainContainer,
  MainRow,
  ToastMessage,
} from "components/common";
import { BtnWrapper, CancelBtn } from "../schema/elements";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  CheckBoxDiv,
  ImgDiv,
  MainWrapper,
  TextareaDiv,
  Title,
  UpdateBtn,
} from "./elements";
import { BsQuestionCircleFill } from "react-icons/bs";
import { Input } from "antd";
import { Checkbox } from "antd";
import { useFormik } from "formik";
import { EditTemplateHook, GetTemplateByIdHook } from "hooks/templateHook";
import Loading from "components/common/loader";
import { useAppSelector } from "store/store";
import { CommonUtility } from "utility";
import { starshipAbi } from "contract/starship";
import { Tooltip } from "antd";

const dataa = [
  {
    id: 1,
    text: "Starship NFT Game",
    link: "/admin/starship",
  },
  {
    id: 2,
    text: "Schemas and templates",
    link: "/starship/admin/schemas",
  },

  {
    id: 3,
    text: "Template Detail",
    link: "",
  },
];

const EditTemplate = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const [img, setImg] = useState(null);
  const { loading: edting } = EditTemplateHook();
  const { data, loading } = GetTemplateByIdHook(id);
  const { web3, account, chainId } = useAppSelector(
    (state) => state.web3Connect
  );
  const { config } = useAppSelector((state) => state.configData);
  const navigate = useNavigate();

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    setFieldValue,
    setFieldTouched,
  } = useFormik({
    initialValues: {
      max_supply: "",
      description: "",
      is_transferable: false,
      is_burnable: false,
    },
    validate: (values) => {
      const errors = {};

      if (!values.max_supply && values.max_supply !== 0) {
        errors.max_supply = "required";
      }
      return errors;
    },
    onSubmit: async (values) => {
      try {
        setIsLoading(true);

        const starShip = config.STAR_SHIP_ADDRESS;

        const contract = CommonUtility.contract(
          web3,
          starshipAbi,
          starShip[+chainId]
        );
        const templateData = await contract.methods
          .getSchemaInfo(data?.nft_id)
          .call();
        if (
          +values.max_supply !== 0 &&
          +templateData?.nftMinted > +values.max_supply
        )
          throw "Invalid Supply";
        const txn = await contract.methods
          .updateSchemaInfo(
            data?.nft_id,
            values.is_transferable,
            values.is_burnable,
            +values.max_supply == 0 ||
              +values.max_supply == +config?.UINT256_MAX
              ? config?.UINT256_MAX
              : values.max_supply
          )
          .send({ from: account });
        if (txn && txn.code == 4001) throw "User denied the transaction";
        if (!txn.status) throw "Transaction Failed";
        if (txn.status) {
          setIsLoading(false);
          ToastMessage("Success!", "Transaction successful", "success");
          navigate(
            `/starship/admin/template/view-template?id=${data?.schema_id?._id}`
          );
        }
      } catch (error) {
        console.log("error", error);
        setIsLoading(false);
        if (error?.message) {
          ToastMessage("Error", error?.message, "error");
        } else {
          ToastMessage("Error", error, "error");
        }
      }
    },
  });

  useEffect(() => {
    if (data) {
      setFieldValue("max_supply", data?.max_supply);
      setFieldValue("description", data?.description);

      setFieldValue("schema_name", data?.schema_id?.schema_name);
      let attributes1 = data?.template_attributes?.map((x, index) => {
        if (x.field == "file") {
          setImg(data.ipfs_image);
        }
        return {
          ...x,
          id: index + 1,
          types: x.attribute_type,
          field: x.field,
          attribute_type: x.attribute_value,
        };
      });

      setFieldValue("schema_attributes", attributes1);
      setFieldValue("is_transferable", data?.is_transferable);
      setFieldValue("is_burnable", data?.is_burnable);
    }
  }, [data]);

  return (
    <div>
      {(isLoading || loading || edting) && <Loading content={"Loading"} />}
      <MainContainer fluid>
        <div style={{ marginTop: "3rem" }}>
          <BreadCrumbComp data={dataa} />
        </div>

        <MainWrapper>
          <Title>
            Edit template&nbsp;
            <Tooltip placement="top" title={"Edit Template"}>
              <BsQuestionCircleFill fontSize={15} />
            </Tooltip>
          </Title>
          <p>{values?.schema_name}</p>
          <MainRow>
            <MainCol lg={6}>
              <Input
                placeholder="max supply"
                name="max_supply"
                value={
                  +values?.max_supply <= 0 ||
                  +values?.max_supply >= +config?.UINT256_MAX
                    ? "∞"
                    : values?.max_supply
                }
                // value={
                //   values?.max_supply? values?.max_supply:
                // }

                onBlur={handleBlur}
                type={
                  +values?.max_supply == 0 ||
                  +values?.max_supply >= +config?.UINT256_MAX
                    ? "text"
                    : "number"
                }
                min="0"
                // onChange={handleChange}
                onChange={(e) => {
                  if (+e.target.value === 0) {
                    setFieldValue("max_supply", 0);
                  } else if (e.target.value?.charAt(0) === "∞") {
                    let nputValue = e.target.value?.slice(1); // Remove the first character
                    setFieldValue("max_supply", nputValue);
                  } else {
                    handleChange(e);
                  }
                }}
                // readOnly
              />
              <span style={{ color: "#F65D5D", marginTop: "1rem" }}>
                {touched.max_supply && errors.max_supply && "required"}
              </span>

              <TextareaDiv>
                <p>Description</p>
                <textarea
                  rows={5}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="description"
                  value={values.description}
                  // readOnly
                />
              </TextareaDiv>

              <CheckBoxDiv>
                <Checkbox
                  type="checkbox"
                  name="is_burnable"
                  value={values?.is_burnable}
                  checked={values?.is_burnable}
                  onChange={(e) => {
                    setFieldValue("is_burnable", e.target.checked);
                  }}
                >
                  burnable
                </Checkbox>

                <br />
                <span>when a nFT is burned, it is destroyed forever</span>
              </CheckBoxDiv>

              <CheckBoxDiv>
                <Checkbox
                  name="is_transferable"
                  checked={values?.is_transferable}
                  value={values?.is_transferable}
                  onChange={(e) => {
                    setFieldValue("is_transferable", e.target.checked);
                  }}
                >
                  transferable
                </Checkbox>
                <span style={{ color: "#F65D5D", marginTop: "1rem" }}>
                  {touched.is_transferable &&
                    errors.is_transferable &&
                    "required"}
                </span>
                <br />
                <span>transferable NFT’s can change their owner</span>
              </CheckBoxDiv>

              <div>
                <Title className="mt-4">attributes</Title>
                {values?.schema_attributes?.map((x, index) => (
                  <>
                    <div key={x._id} className="mt-4">
                      {x.field !== "file" && x.field !== "number[]" ? (
                        <Input
                          placeholder={`${x?.attribute_placeholder}`}
                          readOnly
                          type={`${x.types}`}
                          value={
                            values.schema_attributes[index]?.attribute_value
                          }
                          onBlur={() =>
                            setFieldTouched(
                              `schema_attributes.${index}.attribute_value`,
                              true
                            )
                          }
                        />
                      ) : (
                        x.field == "number[]" &&
                        x?.numbers?.map((x) => (
                          <Input
                            placeholder={`${x?.types}`}
                            readOnly
                            type={`${x.types}`}
                            value={x.number}
                            onBlur={() =>
                              setFieldTouched(
                                `schema_attributes.${index}.attribute_value`,
                                true
                              )
                            }
                          />
                        ))
                      )}

                      {x.field !== "file" && <span>{`type ${x.types}`}</span>}
                    </div>
                  </>
                ))}
              </div>
            </MainCol>

            <MainCol lg={2}></MainCol>

            <MainCol lg={4}>
              <Title>Preview</Title>
              <ImgDiv>
                {!img ? (
                  <h5>410 x 297</h5>
                ) : (
                  <img
                    src={img}
                    style={{
                      width: "400px",
                      borderRadius: "5%",
                      height: "287px",
                    }}
                  />
                )}
              </ImgDiv>
            </MainCol>
          </MainRow>

          <BtnWrapper>
            <Link
              to={
                "/starship/admin/template/view-template?id=" +
                data?.schema_id?._id
              }
            >
              <CancelBtn>Back</CancelBtn>
            </Link>
            <UpdateBtn
              onClick={() => {
                handleSubmit();
              }}
              disabled={Object.keys(errors)?.length > 0}
            >
              Update
            </UpdateBtn>
          </BtnWrapper>
        </MainWrapper>
      </MainContainer>
    </div>
  );
};

export default EditTemplate;
