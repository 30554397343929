import { Space, Table } from "antd";
import {
  AcceptBtn,
  DeclineBtn,
  ModalWrapper,
  TableDiv,
  Title,
} from "./element";
import { useAppSelector } from "store/store";
import {
  CommonUtility,
  ContractUtility,
  DateUtility,
  ERC20Service,
} from "utility";
import { DeclineOfferHook } from "hooks/mintHook";
import { useEffect, useState } from "react";
import Loading from "components/common/loader";
import { Image } from "react-bootstrap";
import { nodata } from "assets";
import { ToastMessage } from "components/common";
function ViewOffersModal({
  item,
  page,
  filter,
  getMints,
  refresh,
  closeModal,
  usdPrice,
}) {
  const { web3, chainId, swapSdk, account } = useAppSelector(
    (state) => state.web3Connect
  );
  console.log("View offers", item);
  const { decline } = DeclineOfferHook();
  const [isLoading, setIsLoading] = useState(false);
  const [filteredOffers, setFilteredOffers] = useState([]);
  const paymentTokens = ContractUtility.getPaymentList(chainId);
  const findOfferByMaker = (address, offersArray) => {
    return (
      offersArray.find(
        (offer) => offer.maker.toLowerCase() === address.toLowerCase()
      ) || null
    );
  };
  const filterObjectsByExpiry = (objectsArray) => {
    const currentTime = DateUtility.currentTime();

    const filteredArray = objectsArray.filter((obj) => {
      const expiryTime = parseInt(obj.expiry);
      return expiryTime > currentTime;
    });

    setFilteredOffers(filteredArray);
  };

  useEffect(() => {
    filterObjectsByExpiry(item?.offers);
  }, [item]);
  const acceptOffer = async (record) => {
    setIsLoading(true);

    try {
      let nftSwapSdk = swapSdk;
      const signedOrder = findOfferByMaker(record.maker, item?.offers);
      const decimals = ContractUtility.getPaymentToken(
        record?.signed_order
          ? record?.signed_order?.erc20Token
          : paymentTokens[0].address,
        chainId
      )?.decimals;
      let requiredAmount = CommonUtility.calculateTotalFeesAndAmount(
        signedOrder,
        decimals
      );
      console.log("REQUIRED AMOUNT", requiredAmount);
      requiredAmount = CommonUtility.convertToWei(requiredAmount, decimals);
      console.log("REQUIRED AMOUNT123", requiredAmount);
      console.log(signedOrder, requiredAmount, "record.maker", record.maker);
      const userBalanceInWei = await ERC20Service.balance(
        web3,
        signedOrder?.erc20Token,
        record?.maker
      );
      console.log(
        "userBalanceInWei",
        userBalanceInWei,
        +userBalanceInWei < +requiredAmount
      );
      if (+userBalanceInWei < +requiredAmount)
        throw "Currently user do not have enough balance";
      if (swapSdk) {
        const TAKER_ASSET = {
          tokenAddress: signedOrder.erc721Token,
          tokenId: signedOrder?.erc721TokenId,
          type: "ERC721",
          amount: "1",
        };
        const assetsToSwapTaker = [TAKER_ASSET];
        const approvalStatusForUserB = await nftSwapSdk.loadApprovalStatus(
          assetsToSwapTaker[0],
          signedOrder.taker
        );
        // If we do need to approve makers assets for swapping, do that
        if (!approvalStatusForUserB.contractApproved) {
          const approvalTx = await nftSwapSdk.approveTokenOrNftByAsset(
            assetsToSwapTaker[0],
            signedOrder.taker
          );
          await approvalTx.wait();
        }
        // Filling the order (Pass the signed order)
        const fillTx = await nftSwapSdk.fillSignedOrder(signedOrder);
        // Wait for the transaction receipt
        const fillTxReceipt = await nftSwapSdk.awaitTransactionHash(
          fillTx.hash
        );
        setTimeout(async () => {
          await getMints(page, filter);
        }, 30000);

        setIsLoading(false);
        closeModal();
        ToastMessage("Success", "Offer Accepted", "success");
      }
    } catch (err) {
      setIsLoading(false);
      console.log("fillTxReceipt", err);
      ToastMessage("Error", "Transaction Failed", "error");
    }
  };

  const declineOffer = async (record) => {
    try {
      setIsLoading(true);
      await decline(record?.maker, item?._id);
      await getMints(page, filter);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      console.log("error", error);
    }
  };
  const columns = [
    {
      title: `Price (${
        ContractUtility.getPaymentToken(
          item?.signed_order
            ? item?.signed_order?.erc20Token
            : paymentTokens[0].address,
          chainId
        )?.symbol
      })`,
      dataIndex: "erc20TokenAmount",
      key: "price",
      render: (_, record) => (
        <Space size="middle">
          <a className="from-text">
            {CommonUtility.calculateTotalFeesAndAmount(
              record,
              paymentTokens[0]?.decimals
            )}
          </a>
        </Space>
      ),
    },
    {
      title: "Price (USD)",
      dataIndex: "erc20TokenAmount",
      key: "usdPrice",
      render: (_, record) => (
        <Space size="middle">
          <a className="from-text">
            {+CommonUtility.calculateTotalFeesAndAmount(
              record,
              paymentTokens[0]?.decimals
            ) * usdPrice}
          </a>
        </Space>
      ),
    },

    {
      title: "Expiration",
      dataIndex: "expiry",
      key: "expiration",
      render: (_, record) => (
        <Space size="middle">
          <a className="from-text">
            {DateUtility.unixtimeStampToDateTime(record?.expiry)}
          </a>
        </Space>
      ),
    },
    {
      title: "From",
      dataIndex: "maker",
      key: "from",
    },
  ];

  if (account && account.toLowerCase() === item?.wallet_address.toLowerCase()) {
    columns.push({
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <AcceptBtn onClick={() => acceptOffer(record)}>Accept</AcceptBtn>
          <DeclineBtn onClick={() => declineOffer(record)}>Decline</DeclineBtn>
        </Space>
      ),
    });
  }

  let locale = {
    emptyText: (
      <span>
        <Image src={nodata} />
        <p
          style={{
            color: "#3E3E3E",
            marginTop: "0.5rem",
            marginLeft: "1.5rem",
          }}
          className="offers-text"
        >
          No Offers Yet!
        </p>
      </span>
    ),
  };

  return (
    <ModalWrapper>
      {isLoading && <Loading content={"Processing"} />}

      <Title>Offers</Title>
      <TableDiv>
        <Table
          locale={locale}
          columns={columns}
          dataSource={filteredOffers}
          pagination={false}
        />
      </TableDiv>
    </ModalWrapper>
  );
}

export default ViewOffersModal;
