import styled from "styled-components";

export const ViewWrapper = styled.div`
  overflow: hidden;

  .container-fluid {
    /* margin-top: 4rem; */
    margin-bottom: 4rem;
  }

  .card-wrapper {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 0px;

    @media (max-width: 1399.98px) {
      grid-template-columns: repeat(5, 1fr);
    }
    @media (max-width: 1199.98px) {
      grid-template-columns: repeat(4, 1fr);
    }
    @media (max-width: 991.98px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 767.98px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 450.98px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;

export const EditBtn = styled.button`
  border-radius: 1.75rem;
  border: 0.5px solid var(--secondary, #3e3e3e);
  background-color: transparent;
  width: 9.5625rem;
  height: 2.1875rem;
  color: #3e3e3e;
  font-family: "Barlow";
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  margin-top: 2.5rem;
`;

export const NewTemplateBtn = styled.button`
  border-radius: 1.75rem;
  background: var(--pimary, #00b7ff);
  color: #fff;
  font-family: "Barlow";
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  width: 171px;
  height: 35px;
  margin-top: 2.5rem;
  display: block;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 756px) {
    &.tbtn {
      display: none;
    }
  }
`;

export const MainWrapper = styled.div`
  margin-top: 3rem;
  p {
    color: #6b6b6b;
    font-family: "Barlow";
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    margin-top: 1rem;
  }

  .input-div {
    border-radius: 0.625rem;
    border: 1px solid #e7e7e7;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27);
    height: 2.9375rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    cursor: pointer;
    padding: 0rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .ant-input {
    border-radius: 0.625rem;
    border: 1px solid #e7e7e7 !important;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27);
    height: 2.9375rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    &::placeholder {
      color: #c9c9c9;
      font-family: "Barlow";
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;
    }
  }

  span {
    color: #6b6b6b;
    font-family: "Barlow";
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
  }

  .ant-select {
    width: 100%;
    border-radius: 0.625rem;
    border: 1px solid #e7e7e7;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27);
    height: 2.9375rem;
    margin-top: 1rem;
    justify-content: center;
    display: flex;
    align-items: center;
  }

  .createCollecton
    .ant-select-single.ant-select-show-arrow
    .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    color: #c9c9c9;
    font-family: "Barlow";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    margin-top: 0.4rem;
  }

  .icon {
    text-align: end;
  }
`;

export const Title = styled.h5`
  color: var(--secondary, #3e3e3e);
  font-family: "Barlow";
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
  text-transform: capitalize;
  /* margin-top: 3rem; */
`;

export const CheckBoxDiv = styled.div`
  margin-top: 1.5rem;

  .ant-checkbox + span {
    color: #6b6b6b;
    font-family: "Barlow";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
  }

  span {
    color: #6b6b6b;
    font-family: "Barlow";
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
  }
`;

export const ImgDiv = styled.div`
  border-radius: 0.625rem;
  border: 1px solid var(--secondary, #3e3e3e);
  width: 25.625rem;
  height: 18.5625rem;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 990px) {
    width: 100%;
  }
  h5 {
    color: #e7e7e7;
    font-family: "Barlow";
    font-size: 3.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
  }
`;

export const CardWrapper = styled.div`
  margin-top: 2rem;

  .dropdown-wrapper {
    position: relative;
  }

  .card {
    padding: 0rem 0.5rem;
    /* width: 250px; */
    min-height: 365px;
    border-radius: 10px;
    border: 1px solid #00b7ff;
    box-shadow: 0px 0px 4.254847526550293px 0px rgba(0, 0, 0, 0.27);
    position: relative;
  }

  .dots-icon {
    display: block;
    margin-left: auto;
  }

  .card-img,
  .card-img-bottom {
  }

  .card-body {
    padding: 1rem 0rem;
  }

  .card-title {
    color: var(--secondary, #3e3e3e);
    font-family: "Barlow";
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
    text-transform: capitalize;
    text-align: center;
    margin-top: -0.5rem;
  }

  .card-text {
    color: #6b6b6b;
    font-family: "Barlow";
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    text-align: center;
  }

  .ant-divider-horizontal {
    margin-top: 1rem !important;
    margin: 15px 0px;
  }
`;

export const ImageWrapper = styled.div`
  padding: 0 0rem 1rem 0rem;
  padding-bottom: 0;

  img {
    border: 1px solid #727989 !important;
    /* border: 1px solid #00b7ff; */
    /* object-fit: contain; */
    border-radius: 10px;
  }
`;

export const BtnWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-top: 2.5rem; */
`;

export const ViewBtn = styled.button`
  background-color: transparent;
  color: var(--pimary, #00b7ff);
  font-family: Barlow;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #00b7ff;
  border-radius: 1.75rem;
  width: 2.875rem;
  height: 1.3125rem;
`;
export const MintBtn = styled.button`
  background-color: transparent;
  color: #6b6b6b;
  font-family: "Barlow";
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
`;
export const LockBtn = styled.button`
  background-color: transparent;
  color: #c9c9c9;
  font-family: "Barlow";
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
`;

export const SelectDiv = styled.div`
  .ant-select {
    border-radius: 1.75rem;
    /* border: 0.5px solid var(--secondary, #3e3e3e); */
    width: 10.5625rem;
    height: 2.1875rem;
    margin-top: 2.7rem;
    /* margin-left: 4.5rem; */
    justify-content: center;
    display: flex;
    align-items: center;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 0.5px solid var(--secondary, #3e3e3e) !important;
    border-radius: 1.75rem;
  }

  .createCollecton
    .ant-select-single.ant-select-show-arrow
    .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    color: #3e3e3e;
    font-family: "Barlow";
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    margin-top: 0.2rem;
  }
`;

export const MainDev = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  @media (max-width: 756px) {
    display: flex;
    flex-wrap: wrap;
  }
`;

export const InputDiv = styled.div`
  margin-top: 2rem;
  position: relative;
  .ant-input {
    border-radius: 0.625rem;
    border: 1px solid #e7e7e7;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27);
    width: 100% !important;
  }

  .ant-input-group-addon {
    border-radius: 0.625rem 0rem 0rem 0.625rem;
    border: 1px solid #e7e7e7;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27);
    background-color: #fff;
  }

  .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
    display: none;
  }
`;

export const SearchContentDiv = styled.div`
  position: absolute;
  top: 50px;
  z-index: 200;
  border-radius: 5px;
  border: 1px solid #e1e1e1;
  background: #fff;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.56);
  width: 100%;
  max-height: 300px;
  overflow-y: auto !important;
  padding: 10px;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: rgba(94, 166, 36, 0.6);
  }

  .data {
    &:not(:first-child) {
      margin-top: 1.5rem;
    }
  }
`;

export const Name = styled.h5`
  color: var(--secondary, #3e3e3e);
  font-family: "Barlow";
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem; /* 100% */
  text-transform: capitalize;
`;

export const DetailWrapper = styled.div`
  overflow: hidden;

  .container {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
`;

export const DropdownDiv = styled.div`
  border-radius: 10px;
  border: 1px solid #e7e7e7;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27);
  position: absolute;
  z-index: 2;
  top: 25px;
  right: -20px;
  padding: 10px;

  p {
    color: #6b6b6b;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    cursor: pointer;
  }
`;

export const TextareaDiv = styled.div`
  textarea {
    width: 100%;
    border-radius: 0.625rem;
    border: 1px solid #e7e7e7;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27);
    margin-top: 0.5rem;

    &:focus {
      outline: none;
    }
  }
`;

export const UpdateBtn = styled.button`
  border-radius: 1.875rem;
  background: #00b7ff;
  color: #fff;
  font-family: Barlow;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  width: 8.5625rem;
  height: 2.1875rem;
`;

export const dividerStyle = {
  borderColor: "#00B7FF",
};
