import React from "react";
import { NftForms } from "components/common/InputField/inputElement";
import { MainSelect, SelectOption, MainSelectDiv } from "./selectElement";

import { PolygonIcon } from "./selectIcon";

import { ErrorMessage } from "components/nftForm/nftFormElement";
import { FaEthereum } from "react-icons/fa";

const Select = ({
  inputLabel,
  inputLabelText,
  inputRequired,
  dropDownList,
  trendingSelect,
  placeholder,
  value,
  setValue,
  error,
  isPriceSelect,
  isNftLable,
}) => {
  return (
    <MainSelectDiv>
      <NftForms.Group>
        {!isNftLable && (
          <NftForms.Label>
            {" "}
            {inputLabel}
            {inputRequired ? (
              <span
                style={{
                  color: "red",
                  marginLeft: "0.2rem",
                  marginTop: "5rem",
                }}
              >
                *
              </span>
            ) : (
              ""
            )}
          </NftForms.Label>
        )}

        <br />
        <NftForms.Text className="text-muted">{inputLabelText}</NftForms.Text>
        <MainSelect
          style={{ marginTop: "1rem" }}
          trendingSelect={trendingSelect}
          isPriceSelect={isPriceSelect}
          placeholder={placeholder}
          value={value}
          onChange={(e) => setValue(e)}
          defaultValue={dropDownList[0]}
        >
          {dropDownList?.map((item, i) => (
            <SelectOption
              key={i}
              disabled={item?.value === "Loading" ? true : false}
              value={item?.value}
            >
              {item?.value === 1 && (
                <FaEthereum
                  style={{ fontSize: "1.1rem", marginRight: "0.5rem" }}
                />
              )}
              {item?.value === 137 && (
                <PolygonIcon
                  style={{ fontSize: "1.1rem", marginRight: "0.5rem" }}
                />
              )}

              {item?.text}
            </SelectOption>
          ))}
        </MainSelect>
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </NftForms.Group>
    </MainSelectDiv>
  );
};

export default Select;
