import React, { useRef, useState } from "react";
import "components/common/datatable/datatable.css";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";

import * as N from "components/common/datatable/dataTableElements";
import { useAppSelector } from "store/store";
import { useMatch } from "react-router-dom";
import { NetworkIds } from "utility/constant/dropdowns";
import { CommonUtility, shareholders } from "utility";
import { paymentList } from "components/data/selectData";

const RewardsTable = ({ data }) => {
  const dt = useRef(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [detailDialog, setDetailDialog] = useState(false);
  const [nft, setNft] = useState();
  let match = useMatch("minted-nfts");

  const share = (recipient) => {
    const paymentTokens = paymentList(`${recipient?.network}`);
    const erc20Token = paymentTokens.find(
      (x) => x.value.toLocaleLowerCase() === recipient?.erc20Token
    );
    const amount = CommonUtility.handleDivDecimals(
      recipient?.amount,
      erc20Token.decimals,
      8
    );
    return <p>{amount}</p>;
  };

  const ownerAddressBody = (rowData) => {
    return CommonUtility.addressConvertor(rowData?.sell_nft?.wallet_address);
  };

  const nftDetail = (data) => {
    setNft(data);
    setDetailDialog(true);
  };

  const tokenData = (rowData) => {
    const paymentTokens = paymentList(`${rowData?.sell_nft?.network}`);
    const erc20Token = paymentTokens.find(
      (x) =>
        x.value.toLocaleLowerCase() ===
        rowData?.sell_nft?.signed_order.erc20Token
    );
    return <p>{erc20Token?.text}</p>;
  };

  const header = (
    <div className="table-header">
      <h5 className="p-mx-0 p-my-1">{match ? "Minted Nfts" : "Nfts"}</h5>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
        />
      </span>
    </div>
  );

  const detailDialogFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => setDetailDialog(false)}
      />
    </React.Fragment>
  );

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button className=" p-button-info" onClick={() => nftDetail(rowData)}>
          Details
        </Button>
      </React.Fragment>
    );
  };

  return (
    <N.MainDiv className="datatable-crud-demo">
      <N.MainCard className="card">
        <N.DataTables
          ref={dt}
          value={data ? data : []}
          dataKey="id"
          paginator
          globalFilter={globalFilter}
          rows={10}
          rowsPerPageOptions={[5, 10, 25]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Rewards"
          header={header}
          responsiveLayout="scroll"
        >
          <N.Columns
            field="sell_nft"
            header="NFT Owner"
            sortable
            body={ownerAddressBody}
            style={{ minWidth: "16rem" }}
          ></N.Columns>
          <N.Columns
            field="nft_id"
            header="NFT Contract"
            sortable
            body={(rowData) =>
              CommonUtility.addressConvertor(
                CommonUtility.deStructureNftId(rowData.nft_id).tokenAddress
              )
            }
            style={{ minWidth: "10rem" }}
          ></N.Columns>
          <N.Columns
            field="nft_id"
            header="NFT Id"
            sortable
            body={(rowData) =>
              CommonUtility.deStructureNftId(rowData.nft_id).tokenId
            }
            style={{ minWidth: "10rem" }}
          ></N.Columns>
          <N.Columns
            field="sell_nft"
            header="NFT Price"
            sortable
            body={(rowData) =>
              share({
                amount: rowData?.sell_nft?.signed_order.erc20TokenAmount,
                network: rowData?.sell_nft?.network,
                erc20Token: rowData?.sell_nft?.signed_order.erc20Token,
              })
            }
            style={{ minWidth: "16rem" }}
          ></N.Columns>
          <N.Columns
            field="sell_nft"
            header="Payment Token"
            sortable
            body={tokenData}
            style={{ minWidth: "12rem" }}
          ></N.Columns>
          <N.Columns
            field="recipients"
            header="Company Share"
            body={(rowData) =>
              share({
                ...rowData.recipients.find(
                  (x) => x.name === shareholders.COMPANY
                ),
                network: rowData?.sell_nft?.network,
                erc20Token: rowData?.sell_nft?.signed_order.erc20Token,
              })
            }
          ></N.Columns>
          <N.Columns
            field="recipients"
            header="Nethy Share"
            body={(rowData) =>
              share({
                ...rowData.recipients.find(
                  (x) => x.name === shareholders.NETHTY
                ),
                network: rowData?.sell_nft?.network,
                erc20Token: rowData?.sell_nft?.signed_order.erc20Token,
              })
            }
          ></N.Columns>
          <N.Columns
            field="recipients"
            header="Referrer Share"
            body={(rowData) =>
              share({
                ...rowData.recipients.find(
                  (x) => x.name === shareholders.REFERRER
                ),
                network: rowData?.sell_nft?.network,
                erc20Token: rowData?.sell_nft?.signed_order.erc20Token,
              })
            }
          ></N.Columns>
        </N.DataTables>
      </N.MainCard>
      {/* <N.Dialogs
        visible={detailDialog}
        style={{ width: "450px" }}
        header="Nfts Details"
        modal
        className="p-fluid"
        footer={detailDialogFooter}
        onHide={() => setDetailDialog(false)}
      >
        <img src={nft?.image} width="100%" />
        <b>Name</b>: {nft?.name} <br />
        <b>Description</b>: {nft?.description} <br />
        <b>Owner Address</b>:{" "}
        <label>
          {`${nft?.owner_address.slice(0, 6)}...${nft?.owner_address.slice(
            36,
            42
          )}`}{" "}
        </label>
        <br />
        <b>Attributes</b>:
        {attributes
          ? attributes.map((item: any, i: number) => {
              return (
                <div key={i}>
                  {item.trait_type}: {item.value} <br />
                </div>
              );
            })
          : ""}
      </N.Dialogs> */}
    </N.MainDiv>
  );
};

export default RewardsTable;
