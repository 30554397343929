import { createSlice } from "@reduxjs/toolkit";


export const nftDetailSlice = createSlice({
    name: "fractionize Nft",
    initialState: {
        detail: null,
        loading: false,
        error: false,
        errorMessage: false
    },
    reducers: {
        getNftDetailRequest: (state, action) => {
            return {
                ...state,
                detail: null,
                loading: true,
                error: false,
                errorMessage: false
            }
        },
        getNftDetailRequestSuccess: (state, action) => {
            return {
                ...state,
                loading: false,
                detail: action.payload
            }
        },
        getNftDetailRequestFailure: (state, action) => {
            return {
                ...state,
                loading: false,
                error: false,
                errorMessage: action.payload.error
            }
        }
    }
});

export const { getNftDetailRequest, getNftDetailRequestFailure, getNftDetailRequestSuccess } = nftDetailSlice.actions;
export const nftDetailReducer = nftDetailSlice.reducer;
