import styled from "styled-components";

export const ModalWrapper = styled.div`
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid #e7e7e7;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27);
  padding: 1rem 2rem 2rem 2rem;
  position: relative;

  .close-icon {
    position: absolute;
    top: 5px;
    right: 10px;
  }
`;

export const MainHeader = styled.h2`
  color: #3e3e3e;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-transform: capitalize;
  margin-top: 2rem;
`;

export const BtnDiv = styled.div`
  margin-top: 2rem;
  /* display: flex;
  justify-content: flex-end;
  align-items: center; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  @media (max-width: 991.98px) {
    justify-content: center;
  }
`;

export const CancelBtn = styled.button`
  background-color: transparent;
  border: none;
  color: #3e3e3e;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
`;

export const SelectBtn = styled.button`
  /* width: 143px; */
  /* min-height: 29px; */
  padding: 0.4rem 3rem;
  /* padding: 1px 6.553px; */
  border-radius: 28px;
  background: var(--pimary, #00b7ff);
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
`;
