import { MainHeader, ModalWrapper } from "./elements";
import { MdClose } from "react-icons/md";
import { Select } from "antd";
import { GetPackHook } from "hooks/packHook";
import Loading from "components/common/loader";
import { useEffect } from "react";

const AddAssetsModal = ({
  handleCancel,
  setFieldValue,
  setFieldTouched,
  values,
}) => {
  const { data, loading } = GetPackHook();
  useEffect(() => {
    if (data) {
      let name = data?.find((z) => z._id == values.pack_id);
      setFieldValue("pack_name", name?.name);
    }
  }, [data]);
  const handlePackSelect = (e) => {
    setFieldValue("pack_id", e);
    let name = data?.find((z) => z._id == e);
    setFieldValue("pack_name", name?.name);
    setFieldTouched("pack_id", true);
    handleCancel();
  };

  return (
    <div>
      {loading && <Loading content={"Loading"} />}
      <ModalWrapper>
        <MdClose
          cursor="pointer"
          fontSize={22}
          color="#6B6B6B"
          className="close-icon"
          onClick={handleCancel}
        />
        <MainHeader>add asset</MainHeader>

        <Select
          showSearch
          className="modal-select mt-3"
          placeholder="choose pack"
          optionFilterProp="children"
          value={values.pack_id || `Choose pack`}
          onSelect={handlePackSelect}
          onBlur={(e) => {
            setFieldTouched("pack_id", true);
          }}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={data?.map((x) => {
            return {
              value: x._id,
              label: x.name,
            };
          })}
        />
      </ModalWrapper>
    </div>
  );
};

export default AddAssetsModal;
