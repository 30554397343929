import styled from "styled-components";

import { Navbar, NavLink, Container, Nav, NavDropdown } from "react-bootstrap";

export const HeaderNavbar = styled(Navbar)``;
export const HeaderNavLink = styled(NavLink)``;
export const HeaderContainer = styled(Container)``;
export const HeaderNav = styled(Nav)``;
export const ProfileDropDown = styled.img`
  width: 35px;
  height: 35px;
  border-radius: 50%;
`;
export const HeaderNavDropdown = styled(NavDropdown)`
  @media( max-width:370px)
  {
  margin-left: -2rem;

  } 
  @media( max-width:350px)
  {
  margin-left: -3.3rem;

  }` 
  ;
  

export const DashboardTitle = styled.h2`
  margin-left: 2rem;
  @media( max-width:370px)
  {
  font-size: 1.2rem;

  }
`;

export const DashboardDesc = styled.h2`
  margin-left: 2rem;
  background: #e9ecef;
  font-size: 1rem;
  padding:0.5rem;
  text-transform: capitalize;
  border-radius:3px;

  @media( max-width:370px)
  {
  font-size: 0.8rem;

  }
`;
