import { TextEditorWrapper } from "./element";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const Editor = ({
  description,
  setDescription,
  setFieldValue,
  setFieldTouched,
  quillRef,
}) => {
  const handleChange = (html) => {
    setDescription(html);
    setFieldValue("description", html);
  };

  return (
    <>
      <TextEditorWrapper>
        <ReactQuill
          theme={"snow"}
          ref={quillRef}
          modules={Editor.modules}
          formats={Editor.formats}
          bounds=".app"
          onChange={handleChange}
          value={description}
          onBlur={() => {
            setFieldTouched("description", true);
          }}
        />
      </TextEditorWrapper>
    </>
  );
};

Editor.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link"],
  ],
  clipboard: {
    matchVisual: false, // toggle to add extra line breaks when pasting HTML
  },
};

Editor.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
];

export default Editor;
