import styled from "styled-components";

export const NoDataFoundWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  width: 400px;

  @media (max-width: 450px) {
    width: 100%;
  }

  .no-data-text {
    color: ${({ theme }) => theme.noDataFoundColor} !important;
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
  }
`;
