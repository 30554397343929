import { ProfileSettingCom } from "components";

const ProfileSetting = () => {
  return (
    <div>
      <ProfileSettingCom />
    </div>
  );
};

export default ProfileSetting;
