import React from "react";
import { Space, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { LoadindMain } from "./mainStyle/mainStyle";
const antIcon = (
  <LoadingOutlined style={{ fontSize: 40, color: "#00B7FF" }} spin />
);
const Loading = ({ content }) => {
  return (
    <LoadindMain>
      <Space size="middle">
        <Spin size="large" indicator={antIcon} />
        {content}
      </Space>
    </LoadindMain>
  );
};

export default Loading;
