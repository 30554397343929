import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import openNotification from "components/common/toastMessage/toastMessage";
import { CREATE_USER, UPDATE_USER } from "gql/mutations";
import { GET_USER } from "gql/queries";
import { useEffect, useState } from "react";
import { setUser } from "store/redux/slices/userSlices/userSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { CommonUtility } from "utility";
import { CommonHook } from "./commonHook";
import { BrowserUtility } from "utility/browser-utility";
import { UserService } from "utility/services/userService";
import { starshipAbi } from "contract/starship";
import { storage } from "../firebase";

export const GetUserHook = (account) => {
  const { data, loading, error, refetch } = useQuery(GET_USER, {
    variables: { walletAddress: `${account}` },
    fetchPolicy: "network-only",
  });

  const { user } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (data && !error) {
      const userdata = data.get_user;
      BrowserUtility.save("token", userdata.token);
      userdata &&
        dispatch(
          setUser({
            ...user,
            name: userdata?.name || "Starship User",
            profImage: userdata?.prof_image,
            coverImage: userdata?.cover_image,
            walletAddress: userdata?.wallet_address,
            isAdmin: userdata.isAdmin,
            token: userdata.token,
          })
        );
      !userdata &&
        dispatch(
          setUser({
            ...user,
            walletAddress: account,
          })
        );
    }
  }, [data]);

  return {
    user,
    data,
    loading,
    error,
    refetch,
  };
};

export const CreateUserHook = () => {
  const [create_user, { error, loading, data }] = useMutation(CREATE_USER);
  const { user } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  const create = (user) => {
    const { name, profImage, walletAddress } = user;
    create_user({
      variables: {
        values: {
          name,
          profImage,
          walletAddress,
        },
      },
    });
  };

  useEffect(() => {
    data &&
      dispatch(
        setUser({
          ...user,
          update: true,
        })
      ) &&
      openNotification("Created", "Profile successfully created", "success");
    error && openNotification("Error", error.message, "error");
  }, [data, error]);

  return {
    data,
    loading,
    error,
    create,
  };
};

export const UpdateUserHook = () => {
  const [update_user, { error, loading, data }] = useMutation(UPDATE_USER);

  const update = (user) => {
    const { name, profImage, walletAddress, cover_image } = user;
    update_user({
      variables: {
        values: {
          name,
          profImage,
          cover_image,
          walletAddress,
        },
      },
    });
  };

  useEffect(() => {
    data &&
      openNotification("Updated", "Profile successfully Updated", "success");
    error && openNotification("Error", error.message, "error");
  }, [data, error]);

  return {
    data,
    loading,
    error,
    update,
  };
};

export const UpdateUserProfileHook = () => {
  const { data, setData, setError, loading, setLoading, error } = CommonHook();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((x) => x.user);

  const update = async (account, obj) => {
    try {
      setLoading(true);
      const handleImage = async (selectedFile) => {
        try {
          return new Promise(async (resolve, reject) => {
            const storageRef = storage.ref(`/home/${selectedFile?.name}`);
            const uploadTask = storageRef.put(selectedFile);

            uploadTask.on(
              "state_changed",
              (snapshot) => {},
              (error) => {
                console.log(error);
                reject(error);
              },
              async () => {
                const downloadURL = await storageRef.getDownloadURL();
                resolve(downloadURL);
              }
            );
          });
        } catch (error) {
          throw error;
        }
      };
      let record = {};

      if (obj?.profileImage) {
        const url = await handleImage(obj.profileImage);

        dispatch(
          setUser({
            ...user,
            profImage: url,
          })
        );

        record = {
          prof_image: url,
        };
      } else if (obj?.coverImage) {
        const url = await handleImage(obj.coverImage);
        dispatch(
          setUser({
            ...user,
            coverImage: url,
          })
        );
        record = {
          cover_image: url,
        };
      }

      const result = await UserService.updateUser(account, record);
      if (result.data) {
        setData(result.data);
        setLoading(false);
        openNotification("Success!", "Profile Updated", "success");
      }
    } catch (error) {
      setError(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return {
    data,
    loading,
    error,
    update,
    setLoading,
  };
};

export const GetRefreeAddressHook = () => {
  const [get_user, { data, loading, error }] = useLazyQuery(GET_USER, {
    fetchPolicy: "network-only",
  });

  const getReferee = async (walletAddress) => {
    try {
      const result = await get_user({ variables: { walletAddress } });
      if (
        result.data &&
        result.data.get_user &&
        result.data.get_user.referrer
      ) {
        return result.data.get_user.referrer;
      } else return "";
    } catch (error) {
      console.log(error, "error in getting refree address");
      return "";
    }
  };

  return {
    getReferee,
    loading,
    error,
    data,
  };
};

export const UserSigninSignupHook = (account) => {
  const dispatch = useAppDispatch();
  const { config } = useAppSelector((state) => state.configData);
  const { web3, chainId } = useAppSelector((state) => state.web3Connect);
  const { data, setData, setError, loading, setLoading, error } = CommonHook();
  const signinSignup = async (account) => {
    try {
      let checkAdminRole = async () => {
        const starShip = config.STAR_SHIP_ADDRESS;
        const contract = CommonUtility.contract(
          web3,
          starshipAbi,
          starShip[+chainId]
        );
        const adminRole = config?.ADMIN_HASH;
        const hasRole = await contract.methods
          .hasRole(adminRole, account)
          .call();

        return hasRole;
      };

      setLoading(true);
      const result = await UserService.signinSignup({
        wallet_address: account,
      });
      if (result.data) {
        setData(result.data);
        const userdata = result.data;
        BrowserUtility.saveToken(userdata.token);

        dispatch(
          setUser({
            name: userdata?.name || "Starship User",
            profImage: userdata?.prof_image,
            coverImage: userdata?.cover_image,
            walletAddress: userdata?.wallet_address,
            createdAt: userdata?.createdAt,
            isAdmin: await checkAdminRole(),
          })
        );

        setLoading(false);
      }
    } catch (error) {
      setError(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    account && signinSignup(account);
  }, [account]);

  return {
    signinSignup,
    data,
    loading,
    error,
  };
};
