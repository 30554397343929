import { useEffect } from "react";
import { CommonHook } from "./commonHook";
import { ActivityService } from "utility/services/activityService";
import { CommonUtility } from "utility";

export const GetUserActivityHook = (wallet) => {
  const { data, setData, setError, loading, setLoading, error } = CommonHook();
  const getUserActivity = async (wallet) => {
    try {
      setLoading(true);
      const result = await ActivityService.userActivity(wallet);
      if (result.data) {
        let temp = CommonUtility.decrypt(result.data);
        temp = JSON.parse(temp);
        setData(temp);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (wallet) getUserActivity(wallet);
  }, [wallet]);

  return {
    getUserActivity,
    data,
    loading,
    error,
    setData,
  };
};
