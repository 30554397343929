import { combineReducers } from "redux";
import { web3Reducer } from "./slices/web3/connectWeb3Slice";
import { modelReducer } from "./slices/helperSlices/modelSlice";

import { nftDetailReducer } from "store/redux/slices/nftSlices/nftDetailSlice";
import { nftDataReducer } from "store/redux/slices/nftSlices/nftDataSlice";
import { loginDataRedcuer } from "./slices/adminSlices/loginDataSlice";
import { favouritesDataReducer } from "./slices/nftSlices/favouriteDataSlice";
import { viewsDataReducer } from "./slices/nftSlices/viewsSlice";
import { collectionDataReducer } from "./slices/collectionSlices/collectionDataSlice";
import { categoryDataReducer } from "./slices/categorySlices/categoryDataSlics";
import { updateCredentialsReducer } from "./slices/adminSlices/credentialsUpdateSlice";
import { configDataReducer } from "./slices/web3/configSlice";
import { UserReducer } from "./slices/userSlices/userSlice";
import { configReducer } from "./slices/helperSlices/config";

const parentReducer = combineReducers({
  web3Connect: web3Reducer,
  model: modelReducer,
  nftDetail: nftDetailReducer,
  login: loginDataRedcuer,
  nftData: nftDataReducer,
  favouritesData: favouritesDataReducer,
  collectionData: collectionDataReducer,
  categoryData: categoryDataReducer,
  credentials: updateCredentialsReducer,
  views: viewsDataReducer,
  config: configDataReducer,
  user: UserReducer,
  configData: configReducer,
});

export default parentReducer;
