import AdminloginComp from "components/starship/adminLogin";

const AdminloginPage = () => {
  return (
    <div>
      <AdminloginComp />
    </div>
  );
};

export default AdminloginPage;
