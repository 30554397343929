import { APIPath } from "utility/constant";
import { BaseService } from "./baseServices";

class Template {
  createTemplate = (data) => {
    return BaseService.post(APIPath.createTemplate, data);
  };
  getTemplates = () => {
    return BaseService.get(APIPath.getTemplates);
  };
  getTemplateById = (id) => {
    return BaseService.get(`${APIPath.getTemplateById}/${id}`);
  };

  getTemplateBySchemaId = (id, page) => {
    return BaseService.get(
      `${APIPath.getTemplateBySchemaId}/${id}?page=${page}`
    );
  };
  searchTemplate = (data) => {
    return BaseService.post(APIPath.searchTemplate, data);
  };

  editTemplate = (id, data) => {
    return BaseService.put(`${APIPath.editTemplate}/${id}`, data);
  };
}

const TemplateService = new Template();
Object.freeze(TemplateService);
export { TemplateService };
