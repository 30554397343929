import { useEffect, useState } from "react";
import { CommonHook } from "./commonHook";
import { storage } from "../firebase";
import "firebase/storage";
import { PackService } from "utility/services/packServices";
import { ToastMessage } from "components/common";
import { CommonUtility } from "utility";

export const CreatePackHook = () => {
  const { data, setData, setError, loading, setLoading, error } = CommonHook();
  const createPack = async (obj) => {
    try {
      const uploadFile = async (file, storageRef) => {
        return new Promise((resolve, reject) => {
          const uploadTask = storageRef.put(file);

          uploadTask.on(
            "state_changed",
            (snapshot) => {},
            (error) => {
              reject(error);
            },
            async () => {
              try {
                const downloadURL = await storageRef.getDownloadURL();
                resolve(downloadURL);
              } catch (error) {
                reject(error);
              }
            }
          );
        });
      };

      setLoading(true);

      if (obj?.video_url && obj?.video_url?.constructor === File) {
        const storageRef = storage.ref(`/starship/${obj?.video_url?.name}`);
        await uploadFile(obj?.video_url, storageRef);
        obj.video_url = await storageRef.getDownloadURL();
      }

      if (obj?.image_url && obj.image_url.constructor === File) {
        const storageRef = storage.ref(`/starship//${obj.image_url?.name}`);
        await uploadFile(obj.image_url, storageRef);
        obj.image_url = await storageRef.getDownloadURL();
      }
      const jsonData = JSON.stringify(obj);
      let encrypted = CommonUtility.encrypt(jsonData);
      const result = await PackService.createPack(encrypted);
      if (result.data) {
        setData(result.data);
        ToastMessage("Success!", "Pack Successfully Created", "success");
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    createPack,
    data,
    loading,
    error,
    setLoading,
  };
};

export const GetPackHook = (page) => {
  const { data, setData, setError, loading, setLoading, error } = CommonHook();
  const [total, setTotal] = useState(0);

  const getPacks = async (page) => {
    try {
      setLoading(true);
      const result = await PackService.getPacks(page);
      if (result?.data) {
        let temp = CommonUtility.decrypt(result.data);
        temp = JSON.parse(temp);
        setData(temp);

        setTotal(result.total);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getPacks(page);
  }, [page]);

  return {
    getPacks,
    data,
    loading,
    error,
    setData,
    total,
  };
};

export const UpdatePackHook = () => {
  const { data, setData, setError, loading, setLoading, error } = CommonHook();

  const editPack = async (id, obj) => {
    try {
      setLoading(true);

      const uploadFile = async (file, storageRef) => {
        return new Promise((resolve, reject) => {
          const uploadTask = storageRef.put(file);

          uploadTask.on(
            "state_changed",
            (snapshot) => {},
            (error) => {
              reject(error);
            },
            async () => {
              try {
                const downloadURL = await storageRef.getDownloadURL();
                resolve(downloadURL);
              } catch (error) {
                reject(error);
              }
            }
          );
        });
      };
      if (obj?.video_url && obj?.video_url?.constructor === File) {
        const storageRef = storage.ref(`/starship/${obj?.video_url?.name}`);
        await uploadFile(obj?.video_url, storageRef);
        obj.video_url = await storageRef.getDownloadURL();
      }

      if (obj?.image_url && obj.image_url.constructor === File) {
        const storageRef = storage.ref(`/starship//${obj.image_url?.name}`);
        await uploadFile(obj.image_url, storageRef);
        obj.image_url = await storageRef.getDownloadURL();
      }
      const jsonData = JSON.stringify(obj);
      let encrypted = CommonUtility.encrypt(jsonData);

      const result = await PackService.editPack(id, encrypted);
      if (result.data) {
        let temp = CommonUtility.decrypt(result.data);
        temp = JSON.parse(temp);
        setData(temp);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    editPack,
    data,
    loading,
    error,
    setLoading,
  };
};

export const GetPackByIdHook = () => {
  const { data, setData, setError, loading, setLoading, error } = CommonHook();

  const getPackById = async (id) => {
    try {
      setLoading(true);

      const result = await PackService.getPackById(id);
      if (result.data) {
        let temp = CommonUtility.decrypt(result.data);
        temp = JSON.parse(temp);
        setData(temp);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    getPackById,
    data,
    loading,
    error,
  };
};

export const DeletePackHook = () => {
  const { data, setData, setError, loading, setLoading, error } = CommonHook();

  const deletePack = async (id, getPacks) => {
    try {
      setLoading(true);
      let encryptedId = CommonUtility.encrypt(id);
      const result = await PackService.deletePack(encryptedId);
      if (result.data) {
        setData(result.data);
        getPacks && getPacks();
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    deletePack,
    data,
    loading,
    error,
  };
};
