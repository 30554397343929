import styled from "styled-components";
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";

export const MaintenanceElement = styled.div`
  background: linear-gradient(180deg, #cccccc 0%, #000000 100%);
  min-height: 100vh;

  .img-fluid {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const TopbarElement = styled.div`
  .img-fluid {
    width: 220px;
  }
`;

export const ComponentHeader = styled.h2`
  font-size: 48px;
  font-weight: 600;
  line-height: 50px;
  text-align: center;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  color: #ffffff;
  word-wrap: break-word;
  @media (max-width: 575.98px) {
    font-size: 30px;
    margin-top: 20px;
  }
`;

export const TextContainer = styled.p`
  font-weight: 400;
  font-size: 20px;
  line-height: 50px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #ffffff;
`;
export const ContactText = styled.h4`
  margin-top: 40px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  padding: 3px;
  text-align: center;
  letter-spacing: 0.15em;
  color: #ffffff;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 52%;
  @media (max-width: 767.98px) {
    width: 100%;
  }
  &:hover {
    border-color: white;
    box-shadow: 0 0 10px white;
  }
  a {
    text-decoration: none;
    color: #ffffff;
    letter-spacing: 0.15em;
    font-family: "Roboto";
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    &:hover {
      text-decoration: underline;
    }
  }
`;
export const IconContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    &:hover {
      cursor: pointer;
    }
  }
`;

export const IconHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
  margin-left: 30px;
  height: 40px;
  width: 40px;
  border-radius: 30px;
`;

export const FacebookIcon = styled(FaFacebookF)`
  color: #545454;
  font-size: 20px;
  &:hover {
    color: #4267b2;
    cursor: pointer;
  }
`;
export const TwitterIcon = styled(FaTwitter)`
  color: #545454;
  font-size: 20px;
  &:hover {
    color: #1da1f2;
    cursor: pointer;
  }
`;
export const LinkedIn = styled(FaLinkedin)`
  color: #545454;
  font-size: 20px;
  &:hover {
    color: #0a66c2;
    cursor: pointer;
  }
`;

export const Copyrights = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 50px;
  text-align: center;
  letter-spacing: 0.15em;
  color: #ffffff;
  margin-top: 20px;
`;
