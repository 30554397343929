import styled from "styled-components";

export const ModalWrapper = styled.div`
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid #e7e7e7;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27);
  padding: 1rem 2rem 2rem 2rem;
  min-height: 279px;
  /* position: relative; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .close-icon {
    position: absolute;
    top: 5px;
    right: 10px;
  }
`;

export const MainHeader = styled.h2`
  color: #3e3e3e;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-transform: capitalize;
  margin-top: 2rem;
  text-align: center;
`;

export const TermsAndConditionText = styled.p`
  color: #3d4044;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 22px;
  text-align: center;
  margin-top: 2rem;

  span {
    color: rgba(77, 28, 232, 1);
    cursor: pointer;
  }
`;

export const ButtonsDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
`;

export const ConnectWalletBtn = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  border-radius: 5px;
  border: 1px solid #e7e7e7;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27);
  width: 100%;
  padding: 10px 2rem;

  p {
    color: #6b6b6b;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
  }
`;
