import { SignatureService } from "utility/services/signatureService";
import { CommonHook } from "./commonHook";
import { CommonUtility } from "utility";

export const GetSignatureHook = () => {
  const { data, setData, setError, loading, setLoading, error } = CommonHook();

  const getSignature = async (obj) => {
    try {
      setLoading(true);
      const jsonData = JSON.stringify(obj);
      let encrypted = CommonUtility.encrypt(jsonData);
      const result = await SignatureService.getSignature(encrypted);
      if (result.data) {
        let temp = CommonUtility.decrypt(result.data);
        temp = JSON.parse(temp);
        setData(temp);
        return temp;
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    getSignature,
    data,
    loading,
    error,
  };
};
