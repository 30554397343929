import { erc721Abi } from 'contract/erc721'
import EthContract from 'web3-eth-contract'
import { erc20Abi } from "../../../../../contract/erc20"

export const tokensOfOwnerWeb3 = async (contract: EthContract.Contract,
    userAddress: String) => {
    try {
        const result = await contract?.methods.tokensOfOwner(userAddress).call()
        return result
    } catch (err) {
        console.log('err', err)
        return err
    }
}

export const totalSupplyWeb3 = async (contract: EthContract.Contract) => {
    try {
        const result = await contract?.methods.totalSupply().call()
        return result
    } catch (err) {
        console.log('err', err)
        return err
    }
}

export const balanceOfWeb3 = async (contract: EthContract.Contract) => {
    try {
        const result = await contract?.methods.balanceOf().call()
        return result
    } catch (err) {
        console.log('err', err)
        return err
    }
}

export const isApprovedForAllWeb3 = async (contract: EthContract.Contract,
    ownerAddress, operatorAddress
) => {
    try {
        const result = await contract?.methods.isApprovedForAll(ownerAddress, operatorAddress).call()
        return result
    } catch (err) {
        console.log('err', err)
        return err
    }
}

export const getCurrentTokenIdWeb3 = async (contract: any) => {
    try {
        const result = await contract?.methods.getCurrentTokenId().call()
        return result
    } catch (err) {
        console.log('err', err)
        return err
    }
}

export const getUserBalance = async (web3, tokenAddress, owner) => {
    try {
        const contract: EthContract.Contract = new web3.eth.Contract(
            erc20Abi,
            tokenAddress
        );
        const result = await contract?.methods.balanceOf(owner).call()
        return result
    } catch (err) {
        console.log('err', err)
        return err
    }
}



