import environment from "environment";

const url = environment.BACKEND_BASE_URL;
const moralisAPI = environment.REACT_APP_MORALIS_API_PATH;
const openSeaAPI = environment.REACT_APP_OPENSEA_API_PATH;

export const APIPath = {
  openSeaAPI,
  moralisAPI,
  server: environment.BACKEND_BASE_URL,

  assets: "assets",

  chartData:
    "https://api.coingecko.com/api/v3/coins/totem-earth-systems/market_chart",
  //get-config
  getConfig: "/get-config",
  createSchema: "/create-schema",
  getCreateSchemaById: "/get-create-schema",
  getCreateSchema: "/get-create-schema",
  getMerkleData: "/get-merkle-data",
  getSignature: "/get-signature",
  createTemplate: "create-template",
  getTemplateById: "/get-template",
  getTemplates: "/get-templates",
  editTemplate: "/edit-templates",
  deleteTemplate: "/delete-templates",
  getTemplateBySchemaId: "/get-templates-by-schema-id",
  searchTemplate: "/search-template",

  // user

  signinSignup: "/signin-signup-user",
  updateUserProfile: "/update-user-profile",

  //pack

  createPack: "/create-pack",
  getPackById: "/get-pack-by-id",
  getPacks: "/get-packs",
  editPack: "/edit-pack",
  deletePack: "/delete-pack",

  // drop
  updateDrop: "/update-drop",
  createDrop: "/create-drop",
  getDrops: "/get-drops",
  getDropsMints: "/get-drops-mints",
  getViewAllDrops: "/get-view-all-drops",
  userDropMints: "/get-user-drops",
  getDropNfts: "/get-nfts-indexes",
  updateRevealStatus: "/update-reveal-status",
  // mint
  nftsByUser: "/nfts-by-user",
  deleteMint: "/delete-mint",
  getMints: "/get-mints",
  getMintsMostSold: "/get-most-sold-mints",
  createOrder: "/create-order",
  createOffer: "/create-offer",
  delist: "/delist",
  decline: "/decline",
  getNftsByHash: "/get-mints-by-hash",
  countOccurences: "/count-occurences",
  // favorite
  updateFavorite: "/create-favorite",
  getUserFavoriteNfts: "/get-favorite",
  getNftFavorites: "/get-favorite-nft",

  //activity
  userActivity: "/get-user-activity",
  getAllMints: "/get-all-mints",

  getBytes: "/get-bytes",
};
