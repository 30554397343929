import styled from "styled-components";
import { Divider } from "rsuite";
import { Table } from "react-bootstrap";

export const MainRewards = styled.div`
  overflow: hidden;
`;

export const RewardsTable = styled(Table)`
  border: 1px solid #bdbdbd;
  margin-bottom: 4rem;

  .tableHeader {
    font-family: "Figtree";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 100%;
    letter-spacing: -0.05em;
    color: #261ce8;
  }

  &.table > :not(:first-child) {
    border-top: none !important;
  }
  td {
    padding: 1rem;
    font-size: 1rem;
  }
  thead {
  }
  th {
    font-weight: 500 !important;
    color: #4f4f4f;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
  tr:last-child {
    /* border: none !important; */
  }
`;

export const TableDiv = styled.div`
  margin-top: 4rem;
  background: #ffffff;
  /* border: 1px solid #bdbdbd; */

  width: 90%;
  margin-left: auto;
  margin-right: auto;

  .mainheader {
    font-family: "Anton";
    font-style: normal;
    font-weight: 400;
    font-size: 64px;
    line-height: 100%;
    text-transform: uppercase;
    color: #3e3e3e;
    margin: 3rem 0rem;

    @media (max-width: 575.98px) {
      text-align: center;
    }
  }
`;

export const RewardsHeading = styled.div`
  font-family: "Figtree";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 100%;
  letter-spacing: -0.05em;
  color: #261ce8;

  padding: 13px 0px 13px 20px;
  border: 1px solid #bdbdbd;
  border-radius: 12px 10px 0 0px;
  border-bottom: none;
  .img-fluid {
    margin-right: 10px;
    margin-bottom: 3px;
  }
`;

export const RowDivider = styled(Divider)`
  background-color: #bdbdbd;
  margin: 10px 0px;
`;

export const TransactionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 2.5rem;
  padding: 2px 10px 5px 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #4f4f4f;
`;
