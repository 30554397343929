import React, { useEffect, useState } from "react";
import { Buttons, MainCol, MainContainer, MainRow } from "components/common";
import CollectionCard from "./collectionCard";
import {
  CardMainFlex,
  CollapseMain,
  FilterRow,
  FilterTab,
  FilterTabPanel,
  MainCollectionDetails,
  SearchInput,
  ToggleButton,
  PriceSelect,
  PriceSelectOption,
  MinMaxMain,
  PanelDiv,
} from "./styles";
import Form from "react-bootstrap/Form";
import { AiOutlineSearch } from "react-icons/ai";

import { SearchOutlined } from "@ant-design/icons";
import * as C from "../collection/collectionElement";
import { useAppSelector } from "store/store";
import { AddFavouriteHook, RemoveFavouriteHook } from "hooks";
import { CommonUtility } from "utility";
import { RiFilterLine, RiFilterOffLine } from "react-icons/ri";
import { blockchainSelect } from "components/data/selectData";
import { Panel } from "rsuite";
import { Input, Tabs } from "antd";
import ExploreCard from "components/allNfts/exploreCard";

const DetailsCollection = ({
  data,
  setSort,
  sort,
  setFilterData,
  dataFilter,
  attributes,
}) => {
  const [filter, setFilter] = useState(false);
  const [priceFilter, setPriceFilter] = useState({
    type: "",
    max: "",
    min: "",
  });

  return (
    <MainCollectionDetails>
      <MainContainer className="collectionContainer">
        <Tabs
          defaultActiveKey="1"
          items={[
            {
              label: `All`,
              key: "Tab 1",
              children: (
                <>
                  <FilterRow>
                    <div className="divTab  ">
                      <div
                        className="filterWrapper"
                        onClick={() => setFilter(!filter)}
                      >
                        {filter ? (
                          <RiFilterOffLine className="filterIcon" size={18} />
                        ) : (
                          <RiFilterLine className="filterIcon" size={18} />
                        )}

                        <h2 className="filter">Filter</h2>
                      </div>

                      {/* <div>
              <FilterTab defaultActiveKey="1">
                <FilterTabPanel tab="Items" key="1"></FilterTabPanel>
                <FilterTabPanel
                  tab="Activity"
                  key="2"
                  disabled
                ></FilterTabPanel>
              </FilterTab>
            </div> */}
                    </div>
                    <div className="searchMain">
                      <div className="searchDiv">
                        <SearchInput
                          prefix={
                            <SearchOutlined
                              style={{
                                fontSize: "16px",
                                paddingLeft: "1rem",
                                color: " #7F8C8D",
                              }}
                            />
                          }
                          onChange={(e) =>
                            setFilterData({
                              ...dataFilter,
                              name: e.target.value,
                            })
                          }
                          placeholder="Name, token, ID"
                        />
                      </div>
                      <div className="selectDiv">
                        <PriceSelect
                          size="large"
                          onChange={(e) => setSort({ ...sort, price: e })}
                          defaultValue="Recent Activity"
                        >
                          <PriceSelectOption value="recent activity">
                            Recent Activity
                          </PriceSelectOption>
                          <PriceSelectOption value="high">
                            Price High To Low
                          </PriceSelectOption>
                          <PriceSelectOption value="low">
                            Price Low To High
                          </PriceSelectOption>
                        </PriceSelect>
                      </div>
                    </div>
                  </FilterRow>
                  {/* <hr /> */}
                  {/* collection row */}
                  <MainRow className="filterRow ">
                    <MainCol
                      className={
                        filter
                          ? `col-lg-3 col-xs-12 sidebarCol `
                          : " d-none  hideScreen"
                      }
                    >
                      <div className="filterDiv mt-5">
                        <div className=" filterFooter  ">
                          <p className="buyTxt">Buy Now</p>
                          <ToggleButton
                            onClick={(e, value) =>
                              setFilterData({
                                ...dataFilter,
                                listed: e.target.checked,
                              })
                            }
                            type="switch"
                            id="mySwitch"
                          />
                        </div>
                        <p className="priceRange mt-3">Price Marge </p>
                        <MinMaxMain>
                          <input type="text" placeholder="MIN" />
                          <input type="text" placeholder="MAX " />
                        </MinMaxMain>

                        <div className="rarityRank">
                          <PanelDiv>
                            <Panel
                              header="Rarity Rank"
                              collapsible
                              bordered={false}
                            >
                              <Input
                                size="large"
                                placeholder="Search"
                                prefix={<AiOutlineSearch />}
                              />
                            </Panel>
                          </PanelDiv>
                        </div>
                        <div className="panelDiv">
                          <PanelDiv>
                            <Panel
                              header="Background"
                              collapsible
                              bordered={false}
                            >
                              Hello World
                            </Panel>
                          </PanelDiv>
                        </div>
                        <div className="panelDiv">
                          <PanelDiv>
                            <Panel header="Face" collapsible bordered={false}>
                              Hello World
                            </Panel>
                          </PanelDiv>
                        </div>
                        <div className="panelDiv">
                          <PanelDiv>
                            <Panel header="Color" collapsible bordered={false}>
                              Hello World
                            </Panel>
                          </PanelDiv>
                        </div>
                        <div className="panelDiv">
                          <PanelDiv>
                            <Panel header="Mouth" collapsible bordered={false}>
                              Hello World
                            </Panel>
                          </PanelDiv>
                        </div>
                        <div className="panelDiv">
                          <PanelDiv>
                            <Panel
                              header="Headgear"
                              collapsible
                              bordered={false}
                            >
                              Hello World
                            </Panel>
                          </PanelDiv>
                        </div>
                        <div className="panelDiv">
                          <PanelDiv>
                            <Panel header="Arms" collapsible bordered={false}>
                              Hello World
                            </Panel>
                          </PanelDiv>
                        </div>
                        <div className="panelDiv">
                          <PanelDiv>
                            <Panel header="Type" collapsible bordered={false}>
                              Hello World
                            </Panel>
                          </PanelDiv>
                        </div>

                        {/* <PanelDiv>
                <Panel header="Price" collapsible bordered={false}>
                  <div className="minMaxMain">
                    <Form.Select
                      onChange={(e) =>
                        setPriceFilter({ ...priceFilter, type: e.target.value })
                      }
                    >
                      <option value="TestToken">TestToken</option>
                      <option value="USDC">USDC</option>
                    </Form.Select>
                    <div className="inputWrapper">
                      <input
                        disabled={!priceFilter.type}
                        onChange={(e) =>
                          setPriceFilter({
                            ...priceFilter,
                            min: e.target.value,
                          })
                        }
                        type="number"
                        placeholder="MIN"
                      />

                      <input
                        disabled={!priceFilter.type}
                        onChange={(e) =>
                          setPriceFilter({
                            ...priceFilter,
                            max: e.target.value,
                          })
                        }
                        type="number"
                        placeholder="MAX "
                      />
                    </div>
                    <Buttons
                      disabled={Object.values(priceFilter).some(
                        (n) => n.trim() === ""
                      )}
                      onClick={() =>
                        setFilterData({ ...dataFilter, price: priceFilter })
                      }
                      explore
                      className="mt-2"
                    >
                      Apply
                    </Buttons>
                  </div>
                </Panel>
              </PanelDiv> */}

                        {/* <PanelDiv>
                          <Panel header="Chain" collapsible bordered={false}>
                            <MainRow>
                              {blockchainSelect.map((item2) => (
                                <>
                                  <MainCol lg={12}>
                                    <div>
                                      <C.ChainSelect
                                        onChange={(e) => {
                                          if (
                                            dataFilter?.chain &&
                                            dataFilter.chain.length > 0
                                          ) {
                                            if (e.target.checked) {
                                              setFilterData({
                                                ...dataFilter,
                                                chain: [
                                                  ...dataFilter.chain,
                                                  item2.value,
                                                ],
                                              });
                                            } else {
                                              const newChain =
                                                dataFilter.chain.filter(
                                                  (x) => x !== item2.value
                                                );
                                              setFilterData({
                                                ...dataFilter,
                                                chain: newChain,
                                              });
                                            }
                                          } else
                                            setFilterData({
                                              ...dataFilter,
                                              chain: [item2.value],
                                            });
                                        }}
                                      />{" "}
                                      <span
                                        className="ms-3"
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "1rem",
                                        }}
                                      >
                                        {item2?.text}
                                      </span>
                                    </div>
                                  </MainCol>
                                  {item2?.checkboxCount && (
                                    <MainCol lg={12}>
                                      <C.BodyText>
                                        {item2?.checkboxCount}
                                      </C.BodyText>
                                    </MainCol>
                                  )}
                                </>
                              ))}
                            </MainRow>
                          </Panel>
                        </PanelDiv> */}

                        <div>
                          {attributes &&
                            Object.keys(attributes).map((trait) => {
                              return (
                                <PanelDiv>
                                  <Panel
                                    header={trait}
                                    collapsible
                                    bordered={false}
                                  >
                                    <MainRow>
                                      {attributes[trait].map((value) => (
                                        <>
                                          <MainCol lg={12}>
                                            <div>
                                              <C.ChainSelect
                                                onChange={(e) => {
                                                  if (dataFilter?.attributes) {
                                                    let traitValues =
                                                      dataFilter.attributes[
                                                        trait
                                                      ];
                                                    if (traitValues) {
                                                      if (e.target.checked) {
                                                        traitValues.push(value);
                                                        setFilterData({
                                                          ...dataFilter,
                                                          attributes: {
                                                            ...dataFilter.attributes,
                                                            [trait]:
                                                              traitValues,
                                                          },
                                                        });
                                                      } else {
                                                        const index =
                                                          traitValues.indexOf(
                                                            value
                                                          );
                                                        traitValues.splice(
                                                          index,
                                                          1
                                                        );
                                                        if (
                                                          traitValues.length < 1
                                                        ) {
                                                          const attr =
                                                            dataFilter.attributes;
                                                          delete attr[trait];
                                                          setFilterData({
                                                            ...dataFilter,
                                                            attributes: attr,
                                                          });
                                                        } else {
                                                          setFilterData({
                                                            ...dataFilter,
                                                            attributes: {
                                                              ...dataFilter.attributes,
                                                              [trait]:
                                                                traitValues,
                                                            },
                                                          });
                                                        }
                                                      }
                                                    } else {
                                                      setFilterData({
                                                        ...dataFilter,
                                                        attributes: {
                                                          ...dataFilter.attributes,
                                                          [trait]: [value],
                                                        },
                                                      });
                                                    }
                                                  } else
                                                    setFilterData({
                                                      ...dataFilter,
                                                      attributes: {
                                                        [trait]: [value],
                                                      },
                                                    });
                                                }}
                                              />{" "}
                                              <span
                                                className="ms-3"
                                                style={{
                                                  fontWeight: "500",
                                                  fontSize: "1rem",
                                                }}
                                              >
                                                {value}
                                              </span>
                                            </div>
                                          </MainCol>
                                        </>
                                      ))}
                                    </MainRow>
                                  </Panel>
                                </PanelDiv>
                              );
                            })}
                        </div>
                      </div>
                    </MainCol>
                    <MainCol
                      className={
                        filter ? "col-lg-9 col-xs-12 rightCol" : "col-lg-12"
                      }
                    >
                      <CardMainFlex className="cardMainFlex  mt-5 mb-2">
                        {data?.map((item) => (
                          <div className={filter ? "cardItem" : "cardItem2"}>
                            <ExploreCard data={item} />
                          </div>
                        ))}
                      </CardMainFlex>
                    </MainCol>
                  </MainRow>
                </>
              ),
            },
            {
              label: `Verified`,
              key: "Verified",
              disabled: true,
              children: `Content of Tab Pane 2`,
            },
          ]}
        />
      </MainContainer>
    </MainCollectionDetails>
  );
};

export default DetailsCollection;
