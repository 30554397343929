import { ExploreCom } from "components";

function Explore() {
  return (
    <div>
      <ExploreCom />
    </div>
  );
}

export default Explore;
