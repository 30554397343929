import styled from "styled-components";

export const ModalWrapper = styled.div`
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid #00b7ff;
  /* background: #fff; */
  background-color: ${({ theme }) => theme.tablebgColor} !important;
  padding: 1rem;
  padding-bottom: 2rem;
`;

export const Title = styled.h2`
  /* color: #3e3e3e; */
  color: ${({ theme }) => theme.tabletitleColor} !important;
  font-size: 25.063px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
`;

export const TableDiv = styled.div`
  margin-top: 1rem;
  max-height: 500px;
  overflow-y: auto !important;

  .ant-table {
    background-color: ${({ theme }) => theme.tablebgColor} !important;
  }

  .ant-table-thead > tr > th {
    background-color: transparent !important;
    color: #00b7ff !important;
    border-bottom: 1px solid ${({ theme }) => theme.tableborderColor} !important ;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background-color: transparent !important;
  }

  .ant-table-tbody > tr > td {
    color: ${({ theme }) => theme.tabletextColor} !important;
    border-bottom: 1px solid ${({ theme }) => theme.tableborderColor} !important ;
  }

  .ant-table-pagination {
    display: none !important;
  }
`;

export const AcceptBtn = styled.button`
  background-color: transparent;
  border: none;
  color: #19a802;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  text-transform: capitalize;
`;

export const DeclineBtn = styled.button`
  background-color: transparent;
  border: none;
  color: #f65d5d;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  text-transform: capitalize;
`;
