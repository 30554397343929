import styled from "styled-components";

export const ConnectWalletBtn = styled.button`
background: linear-gradient(90deg, rgba(0, 170, 255, 1) 0%, rgba(116, 198, 238, 1) 52%, rgba(0, 212, 255, 1) 100%);
  border-radius: 4px;
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 100%;
  color: #fff;
  padding: 10px 25px;
`;
