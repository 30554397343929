import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { ToastMessage } from "components/common";
import { ADD_TRANSACTION } from "gql/mutations";
import { GET_NFT_TRANSACTIONS } from "gql/queries";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "store/store";
import {
  CommonConstant,
  ContractUtility,
  ErrorConstant,
  NFTService,
} from "utility";

export const CreateTransactionHook = () => {
  const [add_transaction, { data, error, loading }] =
    useMutation(ADD_TRANSACTION);

  const createTransaction = async (values) => {
    await add_transaction({ variables: { values } });
  };

  useEffect(() => {
    error && ToastMessage("Error", error.message, "error");
  }, [error]);

  return {
    data,
    error,
    loading,
    createTransaction,
  };
};

export const GetNftTransactionHook = () => {
  const [get_nft_transactions, { data, error, loading, refetch }] =
    useLazyQuery(GET_NFT_TRANSACTIONS);

  const getTx = (nftId) => {
    get_nft_transactions({ variables: { nftId } });
  };

  useEffect(() => {
    error && ToastMessage("Error", error.message, "error");
  }, [error]);

  return {
    data: data?.get_nft_transactions,
    error,
    loading,
    getTx,
    refetch,
  };
};

export const NFTTransactionsHook = () => {
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [cursor, setCursor] = useState("");
  const { chainId } = useAppSelector((state) => state.web3Connect);
  const fetchData = async (tokenId, tokenAddress) => {
    try {
      setLoading(true);

      const network = ContractUtility.getNetwork(chainId);
      const result = await NFTService.moralistTransactions(
        tokenAddress,
        tokenId,
        {
          chain: network,
          format: "decimal",
          cursor,
        }
      );
      const transactions = result.result.map((item) => ({
        transactionHash: item.transaction_hash,
        _id: Math.random(),
        to: item.to_address,
        from: item.from_address,
        paymentToken: "",
        amount: item.value,
        transactionDate: item.block_timestamp,
        event:
          item.from_address === CommonConstant.nullAddress
            ? "Minted"
            : "Transfer",
        isMinted: item.from_address === CommonConstant.nullAddress,
      }));
      let temp = [];
      if (result.page === 1) {
        temp = transactions;
      } else {
        temp = [...data, ...transactions];
      }
      if (result.total > temp.length) {
        setCursor(result.cursor);
      } else {
        setCursor("");
      }
      setData(temp);
    } catch (error) {
      console.log(error);
      setError(ErrorConstant.default);
    } finally {
      setLoading(false);
    }
  };

  return { data, loading, error, fetchData };
};
