import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { ToastMessage } from "components/common";
import openNotification from "components/common/toastMessage/toastMessage";
import { UPDATE_PASSWORD, UPDATE_USERNAME } from "gql/mutations";
import { DASHBOARD_STATS, GET_ADMIN } from "gql/queries";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  resetPassword,
  setUpdatedCredentials,
} from "store/redux/slices/adminSlices/credentialsUpdateSlice";
import { totalSupplyWeb3 } from "store/redux/slices/web3/nftMinting";
import { useAppDispatch, useAppSelector } from "store/store";

export const GetAdminHook = () => {
  const dispatch = useAppDispatch();
  const { data, loading, error } = useQuery(GET_ADMIN);
  const { credentials } = useAppSelector((state) => state.credentials);

  useEffect(() => {
    data &&
      dispatch(
        setUpdatedCredentials({
          ...credentials,
          username: data?.get_admin.user_name,
        })
      );
  }, [data]);

  return {
    data,
    loading,
    error,
  };
};

export const UpdatePasswordHook = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { credentials } = useAppSelector((state) => state.credentials);
  const [
    update_password,
    { data: updated, loading: updateLoading, error: updateError },
  ] = useMutation(UPDATE_PASSWORD);

  const updatePass = () => {
    update_password({
      variables: {
        currentPassword: credentials.current_password,
        newPassword: credentials.new_password,
      },
    });
  };

  useEffect(() => {
    updated &&
      dispatch(resetPassword()) &&
      openNotification("Updated", "password updated", "success");
    updateError &&
      updateError?.message === "invalid token" &&
      navigate("/admin-login");
    updateError &&
      updateError?.message != "Incorrect old password" &&
      openNotification("Error", "Problem occured while updating", "error");
  }, [updated, updateError]);

  return {
    updatePass,
    updateLoading,
    updateError,
  };
};

export const UpdateUsernameHook = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { credentials } = useAppSelector((state) => state.credentials);

  const [
    update_username,
    { data: updated, loading: updateLoading, error: updateError },
  ] = useMutation(UPDATE_USERNAME);

  const updateUsername = () => {
    update_username({ variables: { username: credentials.username } });
  };

  useEffect(() => {
    updated && openNotification("Updated", "Username updated", "success");
    updateError &&
      updateError.message === "invalid token" &&
      navigate("/admin-login");
    updateError &&
      openNotification("Error", "Problem occured while updating", "error");
  }, [updated, updateError]);

  return {
    updateUsername,
    updateLoading,
    updateError,
  };
};

export const DashboardStatsHook = () => {
  const navigate = useNavigate();
  const [dashboard_stats, { error }] = useLazyQuery(DASHBOARD_STATS);
  const [stats, setStats] = useState(null);
  const [statsLoading, setStatsLoading] = useState(false);
  const { nftMintingContract, web3 } = useAppSelector(
    (state) => state.web3Connect
  );

  const getStats = async () => {
    try {
      setStatsLoading(true);
      dashboard_stats().then(async (result) => {
        const total = await totalSupplyWeb3(nftMintingContract);
        setStats({
          ...result.data?.dashboard_stats,
          totalSupply: total,
        });
        setStatsLoading(false);
      });
    } catch (error) {
      setStatsLoading(false);
      console.log(error, "error in ggetting stats");
      ToastMessage("Error", "Cannot load stats", "warning");
    }
  };
  useEffect(() => {
    error && error.message === "invalid token" && navigate("/admin-login");
  }, [error]);

  useEffect(() => {
    web3 && getStats();
  }, [web3]);

  return {
    stats,
    statsLoading,
    error,
  };
};
