import React, { useEffect, useState, useMemo } from "react";
import * as N from "./nftDetailsElement";
import * as C from "components/common";
import { useAppDispatch, useAppSelector } from "store/store";
import { useLocation, useNavigate } from "react-router-dom";
import { CommonUtility, ContractUtility } from "utility";
import { connectModelSlice } from "store/redux/slices/helperSlices/modelSlice";
import MainModel from "components/modal/mainModel";
import blueeth from "../../assets/blueeth.png";
import { Tooltip } from "antd";
import {
  NFTTransactionsHook,
  GetSellNftHook,
  ChangeFavouriteHook,
  GetFavouriteHook,
  ConvertPriceHook,
  DeleteSellNftHook,
  switchNetwork,
  AddViewHook,
} from "hooks";

import { MainNtfDetailDiv, BtnWrapper } from "./nftDetailsElement";
import profile from "../../assets/profileImg.jpg";
import defaultImage from "../../assets/imgplaceholder.png";
import tick from "../../assets/tick.png";
import { paymentList } from "components/data/selectData";
import { getUserBalance } from "store/redux/slices/web3/nftMinting/read";
import { Panel } from "rsuite";

const NftDetails = () => {
  const search = useLocation().search;
  const slug = new URLSearchParams(search).get("slug");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [tokenIcon, setTokenIcon] = useState("");
  const [amount, setAmount] = useState(0);
  const [balance, setBalance] = useState(0);
  const { web3, account, chainId, signer, swapSdk, paymentTokens } =
    useAppSelector((state) => state.web3Connect);
  //custom hooks
  const { detail, loading: detailLoading, refetch } = GetSellNftHook(slug);
  const { changeFav, addData, removeData } = ChangeFavouriteHook();
  const { refetch: refetchFav } = GetFavouriteHook(detail);
  const { addView } = AddViewHook();
  const { getUSD, data: usd } = ConvertPriceHook();
  const { data: transactionsData, fetchData } = NFTTransactionsHook();
  const {
    data: unlistData,
    loading: unlistLoading,
    deleteSellNft,
  } = DeleteSellNftHook();

  //functions
  const handleAmount = async () => {
    let value = detail?.signed_order.erc20TokenAmount;
    const paymentTokens = paymentList(`${detail?.network}`);
    const erc20Token = paymentTokens.find(
      (x) => x.value.toLocaleLowerCase() === detail?.signed_order.erc20Token
    );
    getUSD({ id: erc20Token.cmcId, amount: 1 });
    setTokenIcon(erc20Token.icon?.default);
    setAmount(CommonUtility.handleDivDecimals(value, erc20Token.decimals, 4));
  };

  const unlist = () => {
    try {
      deleteSellNft({
        nft_id: detail?.nft_id,
        network_id: detail?.network,
        transaction_hash: detail.transaction?.transaction_hash,
      });
    } catch (error) {
      console.log(error, "error on unlisting");
    }
  };
  const connectModelFn = () => {
    dispatch(connectModelSlice(true));
  };
  const fillOrder = async () => {
    setLoading(true);
    if (+detail?.signed_order.erc20TokenAmount > +balance) {
      C.ToastMessage(
        "Insufficient Balance",
        "You have indufficient balance to proceed this transaction",
        "warning"
      );
      setLoading(false);
    } else {
      const TAKER_ASSET = {
        tokenAddress: detail?.signed_order.erc20Token, // USDC contract address
        amount: detail?.signed_order.erc20TokenAmount, //  (USDC is 6 digits)
        type: "ERC20",
      };
      const assetsToSwapTaker = [TAKER_ASSET];

      try {
        let nftSwapSdk = swapSdk;
        const signedOrder = detail?.signed_order;
        // Approving the taker asset to swapSdk`
        const approvalStatusForUserB = await nftSwapSdk.loadApprovalStatus(
          assetsToSwapTaker[0],
          account
        );

        // If we do need to approve makers assets for swapping, do that
        if (!approvalStatusForUserB.contractApproved) {
          const approvalTx = await nftSwapSdk.approveTokenOrNftByAsset(
            assetsToSwapTaker[0],
            account
          );
          await approvalTx.wait();
        }

        // Filling the order (Pass the signed order)
        const fillTx = await nftSwapSdk.fillSignedOrder(
          signedOrder,
          undefined,
          {
            gasLimit: "600000",
          }
        );

        // Wait for the transaction receipt
        const fillTxReceipt = await nftSwapSdk.awaitTransactionHash(
          fillTx.hash
        );

        if (fillTxReceipt.status) {
          setLoading(false);
          C.ToastMessage("Done", "NFT Bought Successfully", "success");
          refetch();
        } else {
          setLoading(false);
          C.ToastMessage("error", "Transaction Failed", "error");
        }
      } catch (err) {
        setLoading(false);
        console.log("fillTxReceipt", err);
        C.ToastMessage("Failed!", "Transaction Rejected By User", "error");
      }
    }
  };

  // use memo
  const switchNet = useMemo(
    () => detail && chainId && chainId != detail?.network,
    [chainId, detail]
  );

  //useEffect
  useEffect(() => {
    if (detail) {
      handleAmount();
    }
  }, [detail]);

  useEffect(() => {
    const getBalance = async () => {
      const paymentTokens = paymentList(`${detail?.network}`);
      const erc20Token = paymentTokens.find(
        (x) => x.value.toLocaleLowerCase() === detail?.signed_order.erc20Token
      );
      const balance = await getUserBalance(web3, erc20Token.value, account);
      setBalance(balance);
    };
    if (detail && web3) {
      addView(detail?.nft_id);
      getBalance();
      fetchData(
        detail?.meta_data?.token_id,
        detail?.meta_data?.asset_contract.address
      );
    }
  }, [detail, web3]);
  useEffect(() => {
    unlistData && navigate("/all-nfts");
  }, [unlistData]);

  useEffect(() => {
    (addData || removeData) && refetchFav();
  }, [addData, removeData]);

  const attributes = useMemo(
    () => JSON.parse(detail?.nft?.attributes || "[]"),
    [detail]
  );

  return (
    <N.MainNFTDetails>
      {loading && <C.Loader content="Buying..." />}
      {detailLoading && <C.Loader content="loading details..." />}
      {unlistLoading && <C.Loader content="unlisting..." />}
      <MainModel />

      <MainNtfDetailDiv>
        <C.MainContainer>
          <N.Header>
            <N.TopWrapper>
              <C.MainRow>
                <C.MainCol lg={5}>
                  <N.LeftDiv>
                    <N.ImgContainer>
                      <img
                        className="img-fluid"
                        alt=""
                        src={
                          detail?.nft?.image ||
                          detail?.meta_data.image_preview_url ||
                          defaultImage
                        }
                      />
                    </N.ImgContainer>
                  </N.LeftDiv>
                </C.MainCol>
                <C.MainCol lg={7}>
                  <N.RightDiv>
                    <N.NFTName>
                      {detail?.user?.name || "Starship user"}
                      <img className="img-fluid" alt="" src={tick} />{" "}
                    </N.NFTName>
                    <N.ContributorsName>
                      {detail?.meta_data.name || detail?.nft?.name}
                    </N.ContributorsName>
                    <N.ShadowDiv></N.ShadowDiv>
                    <N.Owner>
                      <img className="img-fluid" src={profile} />
                      <a>
                        {detail?.user?.name ||
                          CommonUtility.addressConvertor(
                            detail?.wallet_address
                          )}
                      </a>
                    </N.Owner>
                    <N.MainWrapper>
                      <N.PriceWrapper>
                        <div>
                          <N.PriceText>Current Price</N.PriceText>
                          <N.Price>
                            <img
                              style={{
                                marginRight: "5px",
                                marginBottom: "3px",
                                width: "20px",
                              }}
                              src={blueeth}
                            />
                            {amount ? amount : "0"}{" "}
                            <small>(${(usd * amount).toFixed(4)})</small>
                          </N.Price>
                        </div>
                        <BtnWrapper>
                          {web3 ? (
                            <C.Buttons
                              disabled={
                                detail?.transaction.status === "SOLD" ||
                                loading ||
                                unlistLoading
                              }
                              onClick={() =>
                                account.toLocaleLowerCase() ===
                                detail?.wallet_address
                                  ? unlist()
                                  : switchNet
                                  ? switchNetwork(web3, detail?.network)
                                  : fillOrder()
                              }
                              newBuyBtn
                            >
                              {account.toLocaleLowerCase() ===
                              detail?.wallet_address
                                ? "Unlist"
                                : switchNet
                                ? " Switch Network"
                                : detail?.transaction.status === "SOLD"
                                ? "Sold Out!"
                                : "Buy  Now"}
                            </C.Buttons>
                          ) : (
                            <C.Buttons newBuyBtn onClick={connectModelFn}>
                              Connect Wallet
                            </C.Buttons>
                          )}
                          {switchNet && (
                            <p>
                              In order to buy nft kindly switch to
                              {ContractUtility.getNetwork(detail?.network)}
                            </p>
                          )}
                        </BtnWrapper>
                      </N.PriceWrapper>
                      <N.BottomWrapper>
                        <N.PriceText>{/* Current Floor */}</N.PriceText>
                        <div>
                          <p>{/* Time Left: <span>22h 45m</span> */}</p>
                          {web3 && (
                            <Tooltip title="Coming Soon" placement="left">
                              <C.Buttons makeOffer disabled={true}>
                                Make Offer
                              </C.Buttons>
                            </Tooltip>
                          )}
                        </div>
                      </N.BottomWrapper>
                    </N.MainWrapper>

                    <N.PanelDiv>
                      <Panel header="Properties" collapsible bordered={false}>
                        <C.MainRow>
                          {attributes?.map((item) => (
                            <C.MainCol
                              xs={12}
                              sm={6}
                              md={4}
                              lg={6}
                              xl={4}
                              xxl={4}
                              className="mt-3"
                            >
                              <N.PropertiesDiv>
                                <N.PropertiesDivMaintext>
                                  {item?.trait_type}
                                </N.PropertiesDivMaintext>
                                <N.PropertiesDivSubtext>
                                  {item?.value}
                                </N.PropertiesDivSubtext>
                              </N.PropertiesDiv>
                            </C.MainCol>
                          ))}
                        </C.MainRow>
                      </Panel>
                    </N.PanelDiv>
                  </N.RightDiv>
                </C.MainCol>
              </C.MainRow>
            </N.TopWrapper>
          </N.Header>
        </C.MainContainer>
      </MainNtfDetailDiv>
    </N.MainNFTDetails>
  );
};

export default NftDetails;
