import { Image } from "react-bootstrap";
import { NoDataFoundWrapper } from "./element";
// import { darknodata, explorenodata } from "assets";
import { darknodata, explorenodata } from "../../../assets";
import { useAppSelector } from "store/store";

function NoDataFoundCom({ message }) {
  const { theme } = useAppSelector((state) => state.model);

  return (
    <NoDataFoundWrapper>
      {theme === "light" ? (
        <Image src={explorenodata} />
      ) : (
        <Image src={darknodata} />
      )}
      <h4 className="no-data-text">{message}</h4>
    </NoDataFoundWrapper>
  );
}

export default NoDataFoundCom;
