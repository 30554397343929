
const UserProfile = () => {
  return (
    <div>
      {/* <UserProfileCom /> */}
    </div>
  );
};

export default UserProfile;
