import { RewardsCom } from "components";
import { Buttons, Loader, MainButton } from "components/common";
import { UserRewardsHook } from "hooks";
import { connectModelSlice } from "store/redux/slices/helperSlices/modelSlice";
import { useAppDispatch, useAppSelector } from "store/store";

function Rewards() {
  const dispatch = useAppDispatch();
  const { web3 } = useAppSelector((state) => state.web3Connect);

  const { data, loading } = UserRewardsHook();

  const connectModelFn = () => {
    dispatch(connectModelSlice(true));
  };
    return (
    <div>
      {!web3 ? (
        <div className="d-flex justify-content-center mt-2">
          <Buttons newBuyBtn onClick={connectModelFn}>
            Connect Wallet
          </Buttons>
        </div>
      ) : loading ? (
        <Loader content="Loading Rewards" />
      ) : (
        <RewardsCom data={data} />
      )}
    </div>
  );
}

export default Rewards;
