import React from "react";
import { AddBtn, BreadCrumbDiv, BreadCrumbText, MainWrapper } from "./element";
import { IoMdArrowDropright } from "react-icons/io";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";

const BreadCrumbCom = ({ data, btnText }) => {
  const location = useLocation();
  const paths =
    location.pathname.startsWith("/starship/admin/packs") ||
    location.pathname.startsWith("/starship/admin/drops");

  const navigate = useNavigate();

  return (
    <MainWrapper>
      <div className="whole-wrapper">
        <BreadCrumbDiv>
          {data?.map((item, index) => (
            <Link to={item.link} style={{ textDecoration: "none" }}>
              <div className="inner-div">
                <BreadCrumbText
                  className={index === data.length - 1 && "last-text"}
                >
                  {item.text}
                </BreadCrumbText>

                {index !== data.length - 1 && (
                  <IoMdArrowDropright className="arrow-icon" />
                )}
              </div>
            </Link>
          ))}
        </BreadCrumbDiv>
        {paths && (
          <AddBtn
            onClick={(e) => {
              if (location.pathname === "/starship/admin/drops") {
                navigate("/starship/admin/create-drop");
              } else if (location.pathname === "/starship/admin/packs") {
                navigate("/starship/admin/packs/add");
              }
            }}
          >
            {btnText || "Button"}
          </AddBtn>
        )}
      </div>
    </MainWrapper>
  );
};

export default BreadCrumbCom;
