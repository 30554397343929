import styled from "styled-components";

export const MainSection = styled.section`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const Title = styled.h5`
  color: var(--secondary, #3e3e3e);
  font-family: "Barlow";
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
  text-transform: capitalize;
  margin-top: 1.5rem;
`;

export const Text = styled.p`
  color: #6b6b6b;
  font-family: "Barlow";
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  margin-top: 0.5rem;
`;

export const MainWrapper = styled.div`
  border-radius: 1.1875rem;
  border: 1px solid var(--pimary, #00b7ff);
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27);
  width: 35.4375rem;
  height: 4.0625rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 2rem;
  cursor: pointer;

  @media (max-width: 990px) {
    width: 20rem;
  }

  h5 {
    color: #6b6b6b;
    font-family: "Barlow";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    margin-top: 0.5rem;
  }
`;
