import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

export const initialState = {
  collapsed: null,
  modelOpen: null,
  connectModel: null,
  propertiesModel: null,
  reportModel: null,
  nftIdToReport: "",
  theme: localStorage.getItem("themeColor")
    ? localStorage.getItem("themeColor")
    : "dark",
};

export const collapsedDashboard = createAsyncThunk(
  "collapsedDashboard",
  async (collapsed, thunkAPI) => {
    try {
      const result = collapsed;
      return result;
    } catch (error) {
      console.log("Error");
      return error;
    }
  }
);

export const mainModel = createAsyncThunk(
  "mainModel",
  async (modelOpen, thunkAPI) => {
    try {
      const result = modelOpen;
      return result;
    } catch (error) {
      console.log("Error");
      return error;
    }
  }
);

export const darkLightModelSlice = createAsyncThunk(
  "darkModel",
  async (theme, thunkAPI) => {
    try {
      const result = theme;
      return result;
    } catch (error) {
      console.log("Error");
      return error;
    }
  }
);

export const connectModelSlice = createAsyncThunk(
  "connectModel",
  async (connectModel, thunkAPI) => {
    try {
      const result = connectModel;
      return result;
    } catch (error) {
      console.log("Error");
      return error;
    }
  }
);

export const propertiesModelSlice = createAsyncThunk(
  "propertiesModel",
  async (propertiesModel, thunkAPI) => {
    try {
      const result = propertiesModel;
      return result;
    } catch (error) {
      console.log("Error");
      return error;
    }
  }
);

export const reportModelSlice = createAsyncThunk(
  "reportModel",
  async (reportModel, thunkAPI) => {
    try {
      const result = reportModel;
      return result;
    } catch (error) {
      console.log("Error");
      return error;
    }
  }
);

const modelSlice = createSlice({
  name: "Modals",
  initialState,
  reducers: {},
  extraReducers: {
    [collapsedDashboard.fulfilled.toString()]: (state, { payload }) => {
      state.collapsed = payload;
    },
    [mainModel.fulfilled.toString()]: (state, { payload }) => {
      state.modelOpen = payload;
    },
    [connectModelSlice.fulfilled.toString()]: (state, { payload }) => {
      state.connectModel = payload;
    },
    [propertiesModelSlice.fulfilled.toString()]: (state, { payload }) => {
      state.propertiesModel = payload;
    },

    [reportModelSlice.fulfilled.toString()]: (state, { payload }) => {
      state.reportModel = payload.state;
      state.nftIdToReport = payload.nftId;
    },
    [darkLightModelSlice.fulfilled.toString()]: (state, { payload }) => {
      state.theme = payload;
    },
  },
});

export const modelReducer = modelSlice.reducer;
