import { MainCollectionCom } from "components";

function MainCollections() {
  return (
    <div>
      <MainCollectionCom />
    </div>
  );
}

export default MainCollections;
