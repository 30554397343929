import React, { useEffect } from "react";

import * as C from "components/common";

import { Link, useSearchParams } from "react-router-dom";
import { profileSettingNavbarData } from "components/data/navbarData";
import { useLocation, useNavigate } from "react-router-dom";

import * as P from "./profileSettingElement";

import { useAppSelector } from "store/store";
import Profile from "./profile";

type Props = {
  profileSettingRow?: boolean;
  profileSettingUploadImgCol?: boolean;
};
const ProfileSettingCom: React.FC<Props> = () => {
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const refer = searchParams.get("refer");

  const { web3 } = useAppSelector((state) => state.web3Connect);

  useEffect(() => {
    refer
      ? navigate(`/profile-setting/profile?refer=${refer}`)
      : navigate(`/profile-setting/profile`);
  }, [refer]);

  return (
    <div style={{ overflowX: "hidden" }} className="profileSetting">
      <C.MainRow>
        {/* <C.MainCol lg={2}>
          {web3 && (
            <C.MainNav className="flex-column">
              {profileSettingNavbarData?.map((item) => (
                <C.MainNav.Link
                  href={item.route}
                  as={Link}
                  to={item.route}
                  className={pathname == item?.route && "active"}
                >
                  {item.title == "Profile" && <P.ProfileIcon />}

                  {item.title == "Notificaton" && <P.NotificationIcon />}
                  {item.title == "Earnings" && <P.EarningIcon />}

                  {item?.title ==="Profile" && item?.title}
                </C.MainNav.Link>
              ))}
            </C.MainNav>
          )}
        </C.MainCol> */}
        <C.MainCol lg={12}>
          {pathname === "/profile-setting/profile" && <Profile />}
        </C.MainCol>
      </C.MainRow>
    </div>
  );
};

export default ProfileSettingCom;
