import { createSlice } from "@reduxjs/toolkit";

export const UserSlice = createSlice({
  name: "user data slice",
  initialState: {
    user: {
      name: "",
      bio: "",
      profImage: "",
      coverImage: "",
      email: "",
      walletAddress: "",
      instagram: "",
      twitter: "",
      website: "",
      update: false,
      referrer: "",
      createdAt: new Date(),
      isAdmin: false,
    },
  },
  reducers: {
    setUser: (state, action) => {
      return {
        ...state,
        user: { ...action.payload },
      };
    },
    resetUser: (state) => {
      return {
        ...state,
        user: {
          ...state.user,
          name: "",
          bio: "",
          profImage: "",
          coverImage: "",
          email: "",
          walletAddress: "",
          instagram: "",
          twitter: "",
          website: "",
          update: false,
          referrer: "",
          createdAt: new Date(),

          isAdmin: false,
        },
      };
    },
  },
});

export const { setUser, resetUser } = UserSlice.actions;
export const UserReducer = UserSlice.reducer;
