import styled, { css } from "styled-components";
import { Card } from "react-bootstrap";

export const MainCard = styled(Card)`
  /* Main Card */
  ${(props) =>
    props.heroCard &&
    css`
      border-radius: 26px;
      border-top-right-radius: 26px;
      background: rgb(234, 232, 233);
    `}

  ${(props) =>
    props.isSellNftCard &&
    css`
      border-radius: 26px;
      border-top-right-radius: 26px;
      width: 30rem;
      display: block;
      margin-left: auto;
      margin-right: auto;
    `}  

    
  
  @media (min-width: 1440px) {
    ${(props) =>
      props.heroCard &&
      css`
        margin-left: 5rem;
        width: 500px;
      `}
  }

  @media (max-width: 990px) {
    ${(props) =>
      props.heroCard &&
      css`
        margin-top: 5rem;
      `}
    ${(props) =>
      props.isSellNftCard &&
      css`
        margin-top: 2rem;
      `}
  }

  @media (max-width: 575.98px) {
    ${(props) =>
      props.heroCard &&
      css`
        margin-top: 3rem;
      `}
  }

  @media (max-width: 600px) {
    ${(props) =>
      props.isSellNftCard &&
      css`
        width: 100%;
      `}
  }

  @media (min-width: 1880px) {
    ${(props) =>
      props.heroCard &&
      css`
        margin-left: 6rem;
        width: 500px;
      `}
  }

  /* NftDetails Card */
  ${(props) =>
    props.nftDetailsCard &&
    css`
      margin-top: 2rem;
      background: #121619;
      border: 1px solid black;
    `}

  /*  Collaspe Card */

    ${(props) =>
    props.nftDetailsCardCollapse &&
    css`
      margin-top: 2rem;
      background: #fff;
      border: 1px solid black;
      color: black;
    `}

    /* Nft Price Card */
    ${(props) =>
    props.nftPriceCard &&
    css`
      border-radius: 2px;
      background: #121619;
      color: white;
      border: none;

      border-bottom: 1px solid white;
      width: 90%;
      margin-left: 1.8rem;
    `}
  /*Nft Properties Card  */
  ${(props) =>
    props.nftPropertiesCard &&
    css`
      background: #e79751;
      border: 1px solid rgb(21, 178, 229);
      border-radius: 10px;
      margin: 0.5rem 0;
      height: 3.5rem;
    `}

    
    /*Nft mainSectionCard Card  */
  ${(props) =>
    props.mainSectionCard &&
    css`
      margin: 0 20px;
      border-radius: 10px;
    `}

      /*infoCard Card  */
  ${(props) =>
    props.infoCard &&
    css`
      border: none;
      padding: 0.5rem;
      margin: 1.2rem 0rem;
    `}

    /*categoryCard Card  */
  ${(props) =>
    props.categoryCard &&
    css`
      border-radius: 10px;
      margin-top: 1rem;
    `}
`;
export const CardImg = styled(Card.Img)`
  /* heroCardImg Card Img */

  ${(props) =>
    props.heroCardImg &&
    css`
      border-top-right-radius: 26px;
      border-top-left-radius: 26px;
    `}

  ${(props) =>
    props.sellCardImg &&
    css`
      border-radius: 26px;
    `}


    

  /* mainSectionCardImg Card Img */

  ${(props) =>
    props.mainSectionCardImg &&
    css`
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
    `}

    /* trending Card Img */
    ${(props) =>
    props.trendingCardImg &&
    css`
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      height: 12rem;
    `}
`;
export const CardBody = styled(Card.Body)`
  background: ${(p) => p.MainSectionbodyBg && p.MainSectionbodyBg};

  ${(props) =>
    props.mainSectionBody &&
    css`
      padding-bottom: 3rem;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
    `}
  ${(props) =>
    props.trendingCardBody &&
    css`
      background: #fff;

      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
    `}

    ${(props) =>
    props.categoryBody &&
    css`
      padding: 0.5px;
    `}
`;
export const CardTitle = styled(Card.Title)`
  margin-top: 1rem;
  font-size: 16px;
  color: #fff;
  margin-left: 2.7rem;

  ${(props) =>
    props.nFtPriceCardPrice &&
    css`
      font-size: 2rem;
      & span {
        font-size: 1rem;
      }
    `}
  ${(props) =>
    props.isSellTitle &&
    css`
      color: black;
    `}
    
  @media (max-width:990px) {
    text-align: center;

    ${(props) =>
      props.isSellTitle &&
      css`
        text-align: start;
        margin-left: -0.5rem;
      `}
  }
`;
export const CardText = styled(Card.Text)`
  font-weight: 600;
  font-size: 14px;
  color: rgb(4, 17, 29);
  & > span {
    color: #2081e6;
  }

  ${(props) =>
    props.userPriceText &&
    css`
      color: white;
      text-align: center;
      margin-top: 0.3rem;
    `}

  ${(props) =>
    props.isSellValue &&
    css`
      color: black;
      margin-top: 1rem;
    `}

    @media (max-width:990px) {
    ${(props) =>
      props.isSellValue &&
      css`
        margin-top: -1.5rem;
      `}
  }
`;
export const CardBodyImg = styled(Card.Img)`
  ${(props) =>
    props.isUserPriceImg &&
    css`
      width: 200px;
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-top: 10px;
    `}
`;
export const CardHeader = styled(Card.Header)``;
export const CardFooter = styled(Card.Footer)``;
export const CardProfileImg = styled.img`
  border-radius: 50%;
  margin-left: ${({ cardProfileInfoImg }) =>
    cardProfileInfoImg ? "9rem" : "3rem"};
  margin-left: ${({ cardProfileInfoImg1 }) =>
    cardProfileInfoImg1 ? "9rem" : "0rem"};
`;
export const CardMain = styled.div`
  display: flex;
  gap: 1rem;
`;

export const CardInfoIcon = styled.img``;
export const CardInfoIconMain = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 0.5rem;
`;
