import { useTimer } from "react-timer-hook";
import { useLocation } from "react-router-dom";

function MyTimer({ expiryTimestamp }) {
  const location = useLocation();
  const {
    totalSeconds,
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({
    expiryTimestamp,
    onExpire: () => console.warn("onExpire called"),
  });

  return location.pathname !== "/starship/view-collection/detail" ? (
    <div>
      <div>
        <span style={{ fontSize: "10.5px" }}>{days}</span>:
        <span style={{ fontSize: "10.5px" }}>{hours}</span>:
        <span style={{ fontSize: "10.5px" }}>{minutes}</span>
      </div>
    </div>
  ) : (
    <div>
      <div>
        <span style={{ fontSize: "0.9375rem", color: "#6b6b6b" }}>{hours}</span>
        &nbsp;
        <span style={{ fontSize: "0.9375rem", color: "#6b6b6b" }}>:</span>&nbsp;
        <span style={{ fontSize: "0.9375rem", color: "#6b6b6b" }}>
          {minutes}
        </span>
        &nbsp;
        <span style={{ fontSize: "0.9375rem", color: "#6b6b6b" }}>:</span>&nbsp;
        <span style={{ fontSize: "0.9375rem", color: "#6b6b6b" }}>
          {seconds}
        </span>
      </div>
    </div>
  );
}

export default function Timer({ duration }) {
  // console.log("TIMER", duration);
  const time = new Date();
  time.setSeconds(time.getSeconds() + duration);
  // console.log("TIME", time);
  return (
    <div>
      <MyTimer expiryTimestamp={time} />
    </div>
  );
}
