import { CommonHook } from "./commonHook";
import { MerkleDataService } from "utility/services/merkleDataService";

export const GetMerkleDataHook = () => {
  const { data, setData, setError, loading, setLoading, error } = CommonHook();

  const getMerkleData = async (addresses) => {
    try {
      setLoading(true);
      const result = await MerkleDataService.getMerkleData(addresses);
      if (result.data) {
        setData(result.data);
        return result.data;
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    getMerkleData,
    data,
    loading,
    error,
  };
};
