import axios from "axios";
import { APIPath, CommonConstant } from "../constant";
import environment from "environment";
const onSuccess = (response) => {
  console.debug("Request Successful!", response);
  return response.data;
};
const onError = async (error) =>
  Promise.reject({
    error: error.response?.data?.error || error.response?.data,
    status: error.response?.status,
  });

export class BaseService {
  static moralisAPI(url) {
    let headers = {};
    const client = axios.create({
      baseURL: APIPath.moralisAPI,
    });
    headers["X-API-KEY"] =
      "6W3AjSyh88RETutAyxlttdqdfYPoHejvtEs42diCWYTzVymXy2ny8g1TotUbYuCd";

    return client({
      url,
      method: "GET",
      headers,
    })
      .then(onSuccess)
      .catch(onError);
  }

  // static post = (url, data?) => {
  //     return request({
  //       url,
  //       method: "POST",
  //       data,
  //     });
  //   };

  static openseaAPI = (url) => {
    let headers = {};

    if (CommonConstant.mode === "PRODUCTION") {
      headers["X-API-KEY"] = environment.REACT_APP_OPENSEA_API_KEY;
    }

    const client = axios.create({
      baseURL: APIPath.openSeaAPI,
      headers,
    });

    return client({
      url: url,
      method: "GET",
    })
      .then(onSuccess)
      .catch(onError);
  };

  static extenralAPICall(url) {
    const client = axios({
      url,
      method: "GET",
      timeout: 1000 * 3,
    });
    return client.then(onSuccess).catch(onError);
  }
}
