import { useEffect, useMemo, useState } from "react";
import {
  ContentWrapper,
  DurationDiv,
  KeyText,
  MakeOfferBtn,
  ModalWrapper,
  Title,
  TopLeftDiv,
  ValueText,
} from "./element";
import { MainCol, MainRow, ToastMessage } from "components/common";
import { Image } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { CommonUtility, DateUtility, ERC20Service } from "utility";
import { DatePicker, Select } from "antd";
import { useAppSelector } from "store/store";
import Loading from "components/common/loader";
import { CreateOfferHook } from "hooks/mintHook";
import { AiOutlineClose } from "react-icons/ai";
import { GetTokenPriceInUsd } from "utility/usdPrice";
function MakeOfferModal({
  item,
  onCancel,
  page,
  filter,
  getMints,
  refresh,
  userOffer,
  setUserOffer,
}) {
  const { web3, account, chainId, swapSdk, signer } = useAppSelector(
    (state) => state.web3Connect
  );
  const { createOffer } = CreateOfferHook();
  const { config } = useAppSelector((state) => state.configData);
  const [userBalance, setUserBalance] = useState("");
  const [tokenSymbol, setTokenSymbol] = useState("");
  const [paymentToken, setPaymentToken] = useState("");
  const [decimals, setDecimals] = useState("");
  const [usdPrice, setUsdPrice] = useState(Number);

  // const [userOffer, setUserOffer] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    //setUserBalance("");
    // setUserOffer("");
    setSelectedOption(null);
    setSelectedDate(null);
    setIsLoading(false);
  }, [refresh]);
  const handleDateChange = (date) => {
    setSelectedDate(date);
    setSelectedOption(null);
  };
  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    setSelectedDate(null);
  };
  const handleInputChange = (event) => {
    const value = event.target.value;

    const sanitizedValue = value.replace(/[^0-9.]/g, "");
    setUserOffer(sanitizedValue);
  };
  const nftName = useMemo(() => {
    const normalizedAttributeName = "name";
    const attributes = item?.attributes;
    const resultObj = attributes?.find(
      (obj) => obj.attribute_type.toLowerCase() === normalizedAttributeName
    );
    return resultObj ? resultObj.attribute_value : null;
  }, [item]);
  const highestBid = useMemo(() => {
    if (!item?.offers) return 0; // Handle the case when item or signed_order is undefined.

    return item?.offers.reduce((highest, order) => {
      const amount = Number(order.erc20TokenAmount);
      return amount > highest ? amount : highest;
    }, 0);
  }, [item]);

  useEffect(() => {
    if (account && web3) {
      const fetchData = async () => {
        await getDetails();
      };

      fetchData();
    }
  }, [item, account, web3]);

  useEffect(() => {
    const fetchData = async () => {
      if (item) {
        const price = await GetTokenPriceInUsd(config, chainId);
        setUsdPrice(+price);
      }
    };

    fetchData();
  }, [item]);

  const isDisabled = useMemo(() => {
    if (
      (userOffer && +userOffer >= 0 && selectedDate !== null) ||
      (userOffer && +userOffer >= 0 && selectedOption !== null)
    ) {
      return false;
    } else {
      return true;
    }
  }, [selectedDate, selectedOption, userOffer]);

  const getDetails = async () => {
    try {
      const paymentToken = item?.signed_order
        ? item?.signed_order?.erc20Token
        : config?.STARSHIP_TOKEN[chainId];
      setPaymentToken(paymentToken);
      const userBalance = await ERC20Service.balance(
        web3,
        paymentToken,
        account
      );
      const decimals = await ERC20Service.decimals(web3, paymentToken);

      setDecimals(decimals);
      setUserBalance(
        CommonUtility.convertFromWei(userBalance, decimals, false)
      );
      const symbol = await ERC20Service.symbol(web3, paymentToken);
      setTokenSymbol(symbol);
    } catch (error) {
      console.log("error", error);
    }
  };

  const expiryTime = useMemo(() => {
    const expiry = selectedOption
      ? +selectedOption * 86400 + +DateUtility.currentTime()
      : DateUtility.convertDateToUnix(selectedDate?._d);
    return expiry;
  }, [selectedDate, selectedOption]);
  const makeOffer = async () => {
    try {
      setIsLoading(true);
      const fee = +userOffer * (config?.NFT_LISTING_FEE / 100);

      const feeInWei = CommonUtility.convertToWei(fee, decimals);
      // new logic for fee deduction
      const NFT_LISTING_FEE_RECEIVERS =
        config?.NFT_LISTING_FEE_RECEIVERS[chainId];
      const NFT_LISTING_FEE_PERCENTAGES =
        config?.NFT_LISTING_FEE_PERCENTAGES[chainId];
      const feeObjects = NFT_LISTING_FEE_RECEIVERS.map((receiver, index) => ({
        recipient: receiver,
        amount: CommonUtility.convertToWei(
          (NFT_LISTING_FEE_PERCENTAGES[index] / 100) * fee,
          decimals
        ),
      }));

      //
      const actualListingPrice = +userOffer - fee;
      const actualListingPriceInWei = CommonUtility.convertToWei(
        actualListingPrice,
        decimals
      );
      const offerAmountInWei = CommonUtility.convertToWei(userOffer, decimals);
      const currentTime = DateUtility.currentTime();

      const expiryTime = selectedOption
        ? +selectedOption * 86400 + currentTime
        : DateUtility.convertDateToUnix(selectedDate?._d);
      const starShip = config.STAR_SHIP_ADDRESS;
      if (expiryTime < currentTime) throw "Invalid duration for offer";
      const TAKER_ASSET = {
        tokenAddress: starShip[+chainId],
        tokenId: item?.nft_id,
        type: "ERC721",
        amount: "1",
      };
      const MAKER_ASSET = {
        tokenAddress: paymentToken,
        amount: actualListingPriceInWei,
        type: "ERC20",
      };

      let nftSwapSdk = swapSdk;
      const walletAddressUserA = account;
      const assetsToSwapMaker = [MAKER_ASSET];
      const assetsToSwapTaker = [TAKER_ASSET];
      // approving makerAsset to swapSdk
      if (swapSdk) {
        const approvalStatusForUserA = await nftSwapSdk.loadApprovalStatus(
          assetsToSwapMaker[0],
          walletAddressUserA
        );
        // If we do need to approve makers assets for swapping, do that
        if (!approvalStatusForUserA.contractApproved) {
          const approvalTx = await nftSwapSdk.approveTokenOrNftByAsset(
            assetsToSwapMaker[0],
            walletAddressUserA
          );
          const approval = await approvalTx.wait();
          if (approval && approval.code == 4001)
            throw "User denied the transaction";
          if (!approval.status) throw "Transaction Failed";
        }
        const allowance = await ERC20Service.allowance(
          web3,
          paymentToken,
          account,
          nftSwapSdk?.exchangeProxyContractAddress
        );
        if (+allowance < +offerAmountInWei)
          throw `Please provide approval of ${userOffer} ${tokenSymbol}`;
        const order = nftSwapSdk.buildOrder(
          assetsToSwapMaker[0],
          assetsToSwapTaker[0],
          walletAddressUserA,
          {
            expiry: expiryTime,

            fees: feeObjects,
          }
        );

        // Signing the order using the maker's wallet address
        const direction = 1;
        const signedOrder = await nftSwapSdk.signOrder(
          order,
          walletAddressUserA,
          signer,
          direction
        );
        if (signedOrder) {
          const obj = {
            signedOrder: signedOrder,
            id: item?._id,
          };
          await createOffer(obj);
          setSelectedDate(null);
          setUserOffer("");
          onCancel();
          await getMints(page, filter);
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("error", error);
      ToastMessage("Error", "Transaction Failed", "error");
    }
  };

  return (
    <ModalWrapper>
      {isLoading && <Loading content={"Offering your Price"} />}

      <div className="d-flex justify-content-between align-items-center">
        <Title>Make An Offer</Title>
        <AiOutlineClose
          cursor="pointer"
          fontSize={20}
          onClick={onCancel}
          className="icon"
        />
      </div>

      <div className="mt-4 top-row">
        <MainRow>
          <MainCol>
            <TopLeftDiv>
              <Image
                width={42}
                height={42}
                src={item?.image}
                style={{ borderRadius: "50px" }}
              />
              <div className="text-wrapper">
                <h2 className="upper-text">
                  {nftName ? nftName : "Starship Nft"}
                </h2>
                <p className="bottom-text">
                  {nftName ? nftName : "Starship Nft"}
                </p>
              </div>
            </TopLeftDiv>
          </MainCol>
          <MainCol>
            <p className="listing-price-text">Listing price per NFT</p>
            <p className="srt-text">
              {userOffer || 0} {" " + tokenSymbol}
              <small>
                {" "}
                <br />
                {usdPrice > 0 ? +userOffer * usdPrice : "-"} USD
              </small>
            </p>
          </MainCol>
        </MainRow>
      </div>
      <ContentWrapper className="mt-2">
        <KeyText>Balance</KeyText>
        <ValueText>{userBalance + ` ${tokenSymbol}`}</ValueText>
      </ContentWrapper>
      <ContentWrapper className="mt-2 border-bottom">
        <KeyText>Best offer</KeyText>
        <ValueText>
          {+highestBid > 0
            ? CommonUtility.convertFromWei(highestBid, decimals, false)
            : 0}{" "}
          {tokenSymbol}
        </ValueText>
      </ContentWrapper>

      <InputGroup className="mt-3">
        <Form.Control
          type="text"
          placeholder="Your offer"
          value={userOffer}
          onChange={handleInputChange}
        />{" "}
        <InputGroup.Text>{tokenSymbol}</InputGroup.Text>
      </InputGroup>

      <DurationDiv className="mt-3">
        <h4 className="duration-text">Duration</h4>
        <div className="content-div">
          <div className="select-div">
            <Select
              style={{ width: "100%" }}
              popupClassName="first-explore-select-dropdown"
              placeholder="Select"
              options={[
                { value: 1, label: "1 Day" },
                { value: 3, label: "3 Days" },
                { value: 7, label: "1 Week" },
                { value: 30, label: "1 Month" },
              ]}
              value={selectedOption}
              onChange={handleSelectChange}
            />
          </div>
          <div className="input-group">
            <DatePicker
              style={{ width: "100%" }}
              showTime
              showSecond={false}
              value={selectedDate}
              onChange={handleDateChange}
            />
          </div>
        </div>
        <MakeOfferBtn
          disabled={isDisabled || +expiryTime < +DateUtility.currentTime()}
          onClick={makeOffer}
        >
          Make Offer
        </MakeOfferBtn>
      </DurationDiv>
    </ModalWrapper>
  );
}

export default MakeOfferModal;
