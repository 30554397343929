import { Image } from "react-bootstrap";
import {
  ButtonsDiv,
  ConnectWalletBtn,
  MainHeader,
  ModalWrapper,
  TermsAndConditionText,
} from "./element";
import { MdClose } from "react-icons/md";
import { basewallet, metamask, walletconnect } from "assets";
import {
  loadBlockchain,
  loadWalletConnect,
} from "store/redux/slices/web3/connectWeb3Slice";
import { useAppDispatch } from "store/store";
import { useEffect } from "react";
import { useAppSelector } from "store/store";

function StarshipConnectModal({ handleCancel, setIsModalOpen }) {
  const dispatch = useAppDispatch();

  const { web3 } = useAppSelector((state) => state.web3Connect);

  const handleWeb3MetaMask = async () => {
    dispatch(loadBlockchain());
  };

  const handleWeb3WalletConnect = async () => {
    dispatch(loadWalletConnect());
  };

  useEffect(() => {
    if (web3) {
      setIsModalOpen(false);
    }
  }, [web3]);
  return (
    <ModalWrapper>
      <MdClose
        cursor="pointer"
        fontSize={22}
        color="#6B6B6B"
        className="close-icon"
        onClick={handleCancel}
      />
      <MainHeader>Connect A Wallet</MainHeader>

      <ButtonsDiv>
        <ConnectWalletBtn onClick={() => handleWeb3MetaMask()}>
          <p>Metamask</p>
          <Image src={metamask} />
        </ConnectWalletBtn>
        <ConnectWalletBtn>
          <p>Coinbase Wallet</p>
          <Image src={basewallet} />
        </ConnectWalletBtn>
        <ConnectWalletBtn onClick={() => handleWeb3WalletConnect()}>
          <p>WalletConnect</p>
          <Image src={walletconnect} />
        </ConnectWalletBtn>
      </ButtonsDiv>
    </ModalWrapper>
  );
}

export default StarshipConnectModal;
