import { useEffect, useState, useMemo } from "react";
import { Tooltip } from "antd";
import {
  BtnsDiv,
  CancelBtn,
  CreateBtn,
  FormContainer,
  MainHeader,
  PreviewBtn,
  ShadowedDiv,
  UnpackCollapseDiv,
  UnpackSettingDiv,
} from "./element";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import "react-datepicker/dist/react-datepicker.css";
import { IoCloudUploadSharp } from "react-icons/io5";
import { MainCol, MainRow } from "components/common";
import { Modal } from "antd";
import {
  MintNftsModal,
  PreviewImgModal,
  PreviewVideoModal,
} from "components/modal";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ConnectBtn } from "components/starship/navbar/elements";
import { useAppSelector } from "store/store";
import ConnectModals from "components/modal/connectModal";
import { useParams } from "react-router-dom";
import { GetPackHook } from "hooks/packHook";

function ScheduleSection({
  setCardsData,
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  handleSubmit,
  resetForm,
  packCreated,
  getPackById,
  editCreated,
  quillRef,
  setSlotsArray,
  slotsArray,
}) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [page, setPage] = useState(30);
  const [fileName, setFileName] = useState("");
  const [imagefileName, setimageFileName] = useState("");

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedImageFile, setSelectedImageFile] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const { account } = useAppSelector((state) => state.web3Connect);
  const { getPacks } = GetPackHook(page);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const showModal2 = () => {
    setIsModalOpen2(true);
  };
  const showImageModal = () => {
    setIsImageModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleImageOk = () => {
    setIsImageModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setIsCreateModalOpen(false);
    setIsImageModalOpen(false);
  };
  const handleCancel2 = () => {
    setIsModalOpen2(false);
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(event.target.files[0]);
    if (file) {
      setFieldValue("video_url", file);
      setFileName(file.name);
    } else {
      setFieldValue("video_url", "");
      setFileName("");
    }
  };
  const handleImageFileChange = (event) => {
    const file = event.target.files[0];

    setSelectedImageFile(event.target.files[0]);
    if (file) {
      setFieldValue("image_url", file);
      setimageFileName(file.name);
    } else {
      setFieldValue("image_url", "");
      setimageFileName("");
    }
  };

  useEffect(() => {
    if (packCreated || editCreated) {
      setFileName(null);
      setimageFileName(null);
      getPacks();
      navigate("/starship/admin/packs");
    }
  }, [packCreated, editCreated]);
  useEffect(() => {
    if (getPackById) {
      setIsCollapsed(true);
    }
  }, [getPackById]);
  const clearFormContent = () => {
    quillRef.current.getEditor().root.innerHTML = "";
    resetForm();
  };
  const checkArrayLengthsMatch = (objArray, arrayOfArrays) => {
    if (objArray.length !== arrayOfArrays.length) {
      return false; // The lengths of the top-level arrays don't match
    }

    for (let i = 0; i < objArray.length; i++) {
      if (!objArray[i].templates || !Array.isArray(objArray[i].templates)) {
        return false; // Ensure each object has a templates array
      }
      if (objArray[i].templates.length !== arrayOfArrays[i].length) {
        return false; // Lengths of the corresponding arrays don't match
      }
    }
    return true; // All checks passed, arrays match in length at each index
  };
  const checkProbabilitySum = () => {
    if (!values?.pack_slots || !Array.isArray(values.pack_slots)) {
      return false; // Return false if pack_slots is not available or not an array
    }

    return values.pack_slots.every((slot) => {
      const totalProbability = slot.reduce(
        (sum, item) => sum + Number(item.probability),
        0
      );
      return totalProbability === 100;
    });
  };
  return (
    <div>
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={600}
        centered
      >
        <PreviewVideoModal
          handleCancel={handleCancel}
          selectedFile={selectedFile}
          video_url={getPackById?.video_url || values?.video_url}
        />
      </Modal>

      <Modal
        open={isImageModalOpen}
        onOk={handleImageOk}
        onCancel={handleCancel}
        width={600}
        centered
      >
        <PreviewImgModal
          handleCancel={handleCancel}
          selectedFile={selectedImageFile}
          image_url={getPackById?.image_url || values?.image_url}
        />
      </Modal>

      <Modal
        open={isCreateModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        width={481}
      >
        <MintNftsModal
          handleCancel={handleCancel}
          packsmodal
          dropmodal={undefined}
          setCardsData={setCardsData}
        />
      </Modal>
      <Modal open={isModalOpen2} centered onCancel={handleCancel2} width={504}>
        <ConnectModals onCancel={handleCancel2} />
      </Modal>

      <UnpackSettingDiv>
        <UnpackCollapseDiv onClick={() => setIsCollapsed(!isCollapsed)}>
          <p> unpack setting</p>
          {isCollapsed ? (
            <IoIosArrowUp className="icon" />
          ) : (
            <IoIosArrowDown className="icon" />
          )}
        </UnpackCollapseDiv>
        {isCollapsed && (
          <>
            <MainHeader>Unpack Data</MainHeader>
            <MainRow>
              <MainCol sm={11}>
                <label htmlFor="video" style={{ width: "100%" }}>
                  <ShadowedDiv className="cursor-div">
                    <p>{fileName || "Video"}</p>
                    <IoCloudUploadSharp className="icon" />
                  </ShadowedDiv>
                </label>

                <input
                  type="file"
                  id="video"
                  accept="video/*"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
              </MainCol>
              <MainCol lg={1}>
                <PreviewBtn onClick={showModal}>Preview</PreviewBtn>
              </MainCol>
            </MainRow>

            <MainRow>
              <MainCol sm={11}>
                <label
                  htmlFor="image"
                  style={{ width: "100%" }}
                  placeholder="Pack cover image"
                >
                  <ShadowedDiv className="cursor-div">
                    <p>{imagefileName || "Pack cover image"}</p>
                    <IoCloudUploadSharp className="icon" />
                  </ShadowedDiv>
                </label>

                <input
                  type="file"
                  id="image"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleImageFileChange}
                />
              </MainCol>

              <MainCol lg={1}>
                <PreviewBtn onClick={showImageModal}>Preview</PreviewBtn>
              </MainCol>
            </MainRow>

            <FormContainer>
              <Form.Control
                placeholder="reveal button text"
                name="reveal_text"
                value={values.reveal_text}
                className="color-picker-div"
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FormContainer>

            <ShadowedDiv>
              <p>background color</p>
              <input
                type="color"
                name="background_color"
                value={values.background_color}
                className="color-picker-div"
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </ShadowedDiv>
          </>
        )}
        <BtnsDiv>
          <CancelBtn
            onClick={() => {
              clearFormContent();
            }}
          >
            Cancel
          </CancelBtn>

          {account ? (
            <Tooltip
              title={
                Object.keys(errors)?.length > 0
                  ? "Please recheck all fields"
                  : !checkArrayLengthsMatch(slotsArray, values?.pack_slots)
                  ? "Please fill all slots"
                  : !checkProbabilitySum()
                  ? "Total slot probability must be 100"
                  : ""
              }
            >
              <span>
                <CreateBtn
                  onClick={() => handleSubmit()}
                  disabled={
                    Object.keys(errors)?.length > 0 ||
                    !checkArrayLengthsMatch(slotsArray, values?.pack_slots) ||
                    !checkProbabilitySum()
                  }
                >
                  {" "}
                  {id ? "Update" : "Create"}{" "}
                </CreateBtn>
              </span>
            </Tooltip>
          ) : (
            <ConnectBtn onClick={showModal2}>Connect Wallet</ConnectBtn>
          )}
        </BtnsDiv>
      </UnpackSettingDiv>
    </div>
  );
}

export default ScheduleSection;
