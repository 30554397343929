import { MainHeader, ModalWrapper, ViewNftsBtn } from "./element";
import { MdClose } from "react-icons/md";
import { AiFillCheckCircle } from "react-icons/ai";
import { ContractUtility } from "utility";
import { useAppSelector } from "store/store";

function MintNFTsModal({
  handleCancel,
  packsmodal,
  setCardsData,
  dropmodal,
  hash,
  setSchema,
  setTemplate,
}) {
  const { chainId } = useAppSelector((state) => state.web3Connect);

  const handleClick = () => {
    setCardsData((prevValue) => prevValue + 1);
    handleCancel();
  };
  console.log("chainId", chainId);
  return (
    <ModalWrapper>
      <MdClose
        cursor="pointer"
        fontSize={22}
        color="#6B6B6B"
        className="close-icon"
        onClick={handleCancel}
      />

      {dropmodal ? (
        <>
          <MainHeader>
            <AiFillCheckCircle color="#19A802" className="check-icon" />
            Drop Created Successfully!
          </MainHeader>

          <ViewNftsBtn onClick={handleClick}>View Drop</ViewNftsBtn>
        </>
      ) : (
        <>
          {packsmodal ? (
            <>
              <MainHeader>
                <AiFillCheckCircle color="#19A802" className="check-icon" />
                Pack Created Successfully!
              </MainHeader>

              <ViewNftsBtn onClick={handleClick}>View Pack</ViewNftsBtn>
            </>
          ) : (
            <>
              <MainHeader>
                <AiFillCheckCircle color="#19A802" className="check-icon" />
                Minted Successfully!
              </MainHeader>
              <ViewNftsBtn
                onClick={() => {
                  window.open(
                    `${ContractUtility.getNetworkExplorer(
                      chainId?.toString()
                    )}/tx/${hash}`,
                    "_blank"
                  );
                  setSchema("");
                  setTemplate("");
                }}
              >
                View On Explorer
              </ViewNftsBtn>{" "}
            </>
          )}
        </>
      )}
    </ModalWrapper>
  );
}

export default MintNFTsModal;
