import { NftForm } from "../components";

const CreateNftForm = () => {
  return (
    <div>
      <NftForm />
    </div>
  );
};

export default CreateNftForm;
