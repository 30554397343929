import { AiOutlineWarning } from "react-icons/ai";
import { BorderDiv, ModalWrapper } from "./element";
import { MdClose } from "react-icons/md";
import ReactPlayer from "react-player/lazy";
import { useState, useEffect } from "react";
import { Image } from "react-bootstrap";
import { dummyImg, imgframe } from "assets";

function PreviewModal({ handleCancel, selectedFile, image_url }) {
  return (
    <ModalWrapper>
      <MdClose
        cursor="pointer"
        fontSize={22}
        color="#6B6B6B"
        className="close-icon"
        onClick={handleCancel}
      />
      {selectedFile || image_url ? (
        <BorderDiv>
          <Image
            fluid
            src={selectedFile ? URL.createObjectURL(selectedFile) : image_url}
          />
        </BorderDiv>
      ) : (
        <p className="error">
          <AiOutlineWarning
            style={{ marginRight: "10px", marginBottom: "5px" }}
          />
          Please upload image first{" "}
        </p>
      )}
    </ModalWrapper>
  );
}

export default PreviewModal;
