import styled from "styled-components";

export const AdminWrapper = styled.div`
  overflow: hidden;

  .container-fluid {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .btn {
    color: #fff;
    font-family: "Barlow";
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    border-radius: 1.75rem;
    background: var(--pimary, #00b7ff);
    width: 8.3125rem;
    height: 2.1875rem;
    display: block;
    margin-left: auto;

    @media (max-width: 990px) {
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-top: 2rem;
    }
  }

  .textarea-div {
    border-radius: 10px;
    border: 1px solid #e7e7e7;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27);
    margin-top: 1rem;
    padding: 10px 20px;
    position: relative;
  }

  .close-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
  }

  .bordered-row {
    border-radius: 10px;
    border: 1px solid #e7e7e7;
    margin-top: 2rem;
    max-height: 300px;
    overflow-y: auto !important ;
    overflow-x: hidden !important;
    padding: 1rem;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background-color: rgb(141, 141, 141);
    }
  }

  .btn-wrapper {
    @media (max-width: 990px) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const SelectDiv = styled.div`
  .ant-select {
    width: 100%;
    border-radius: 0.625rem;
    border: 1px solid #e7e7e7;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27);
    height: 2.9375rem;
    margin-top: 1rem;
    justify-content: center;
    display: flex;
    align-items: center;
  }

  .createCollecton
    .ant-select-single.ant-select-show-arrow
    .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    color: #c9c9c9;
    font-family: "Barlow";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    margin-top: 0.4rem;
  }

  p {
    color: #6b6b6b;
    margin-top: 1rem;
    font-family: "Barlow";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
  }
`;

export const Title = styled.h5`
  color: var(--secondary, #3e3e3e);
  font-family: "Barlow";
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
  text-transform: capitalize;
  margin-top: 2rem;
`;

export const DataDiv = styled.div`
  margin-top: 1.5rem;

  .form-control {
    border-radius: 0.625rem;
    border: 1px solid #e7e7e7;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27);
  }
`;

export const SaveBtn = styled.button`
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  border-radius: 1.75rem;
  background: var(--pimary, #00b7ff);
  color: #fff;
  font-family: "Barlow";
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  width: 5.6875rem;
  height: 1.8125rem;
`;
