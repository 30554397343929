import React, { useEffect } from "react";
import { NftTable } from "components"
import { Loader } from "components/common";
import { useNavigate } from "react-router-dom";
import { GetAllNftsHook } from "hooks";


const NftsTable = () => {

    const { data, error, loading } = GetAllNftsHook()
    const navigate = useNavigate();

    useEffect(() => {
        error?.message === 'invalid token' && navigate('/admin-login')
    }, [error])

    return (
        <div>
            {
                loading ? <Loader content="Loading Nfts" /> : ""
            }

            {
                data ? <NftTable data={data.get_all_nfts} /> : ""
            }

        </div>
    )
}

export default NftsTable