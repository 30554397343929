import { useEffect, useMemo, useState } from "react";

import { Card } from "react-bootstrap";
import {
  ArrowIcon,
  CardDetailWrapper,
  ClaimBtn,
  CollectionDetailSection,
  DetailDiv,
  DetailsWrapper,
  DividerStyle,
  DropDiv,
  ImgDiv,
  InfoDiv,
} from "./elements";
import {
  MainCol,
  MainContainer,
  MainRow,
  ToastMessage,
} from "components/common";
import { Divider, Input, Tooltip, Modal } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { GetSignatureHook } from "hooks/signatureHook";
import { useAppSelector } from "store/store";
import {
  CommonUtility,
  ContractUtility,
  DateUtility,
  ERC20Service,
} from "utility";
import { starshipAbi } from "contract/starship";
import Loading from "components/common/loader";
import Timer from "./timer";
import { BsInfoCircle } from "react-icons/bs";
import StarshipFooter from "../footer";
import { ConnectBtn } from "../navbar/elements";
import ConnectModals from "components/modal/connectModal";
import Web3 from "web3";
import { GetCountMintOccurencesHook } from "hooks/mintHook";
import { GetDropNftsHook } from "hooks/dropHook";

const CollectionDetailComp = () => {
  const { web3, account, chainId } = useAppSelector(
    (state) => state.web3Connect
  );
  const { config } = useAppSelector((state) => state.configData);
  const { countMintOccurences } = GetCountMintOccurencesHook();
  const { getDropNfts } = GetDropNftsHook();

  const { getSignature } = GetSignatureHook();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const item = location?.state;
  const [sold, setSold] = useState(null);
  const [coolDownTime, setCoolDownTime] = useState(Number);
  const [remainingCounts, setRemainingCounts] = useState(null);
  const [claimNumber, setClaimNumber] = useState(Number);
  const [currentTime, setCurrentTime] = useState(Number);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(Math.floor(DateUtility.currentTime()));
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  const currentChain = useMemo(() => {
    return chainId ? chainId : config?.DEFAULT_CHAIN_ID;
  });
  const publicWeb3 = useMemo(() => {
    if (currentChain) {
      const network = ContractUtility.getNetwork(currentChain);
      const rpc = ContractUtility.getNetworkRpc(network.toLowerCase());
      const publicWeb3 = new Web3(rpc);
      return publicWeb3;
    }
  }, [currentChain]);
  const handleInputChange = (event) => {
    const newValue = event.target.value.replace(/[^\d]/g, "");
    setClaimNumber(newValue);
  };
  const getDropDetails = async () => {
    try {
      if (item) {
        const starShip = config.STAR_SHIP_ADDRESS;
        const contract = CommonUtility.contract(
          publicWeb3,
          starshipAbi,
          starShip[+currentChain]
        );
        const drop = await contract.methods.getPack(item?.drop_id).call();
        +drop?.maxSupply == +config?.UINT256_MAX
          ? setSold("--")
          : setSold(+drop?.maxSupply - +drop?.availableSupply);
        let coolTime;
        if (account) {
          coolTime = await contract.methods
            .getUserCoolDownTime(account, item?.drop_id)
            .call();
          if (coolTime > DateUtility.currentTime()) {
            setCoolDownTime(+coolTime);
          } else {
            setCoolDownTime(0);
          }
        }

        if (+drop?.maxClaimablePacks == +config?.UINT256_MAX) {
          setRemainingCounts("∞");
        } else {
          const remainingCounts = await contract.methods
            .getUserCountedPacks(account, item?.drop_id)
            .call();
          if (+coolTime > +DateUtility.currentTime()) {
            setRemainingCounts(0);
          } else {
            setRemainingCounts(
              +item.amount_limit_per_account - +remainingCounts
            );
          }
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useMemo(async () => {
    try {
      if (item && config && (publicWeb3 || web3)) await getDropDetails();
    } catch (error) {
      console.log("error", error);
    }
  }, [item, publicWeb3, web3, config, account]);

  const extractProbabilityAndNFTIDs = (item) => {
    const probabilities = [];
    const nftIds = [];

    try {
      if (item && item.pack_id && Array.isArray(item.pack_id.pack_slots)) {
        for (const slot of item.pack_id.pack_slots) {
          if (slot.probability !== undefined) {
            probabilities.push(slot.probability);
          }

          if (slot.template_id && slot.template_id.nft_id !== undefined) {
            nftIds.push(slot.template_id.nft_id);
          }
        }
      }
    } catch (error) {
      console.error("Error extracting probabilities and nftIds:", error);

      return { probabilities: [], nftIds: [] };
    }

    return { probabilities, nftIds };
  };

  const isAddressInWhitelist = (accountAddress, whiteListAddresses) => {
    const lowerCaseAddresses = whiteListAddresses.map((address) =>
      address.toLowerCase()
    );
    return lowerCaseAddresses.includes(accountAddress.toLowerCase());
  };

  const generateRandomNumbersWithDifference = (val) => {
    const difference = Number(val);
    if (difference < 0 || difference > 9) {
      throw new Error("Difference must be between 0 and 9 (inclusive).");
    }
    let num1;
    if (difference == 1) {
      num1 = Math.floor(Math.random() * 10);
    } else {
      num1 = Math.floor(Math.random() * 6);
    }
    const num2 = num1 + difference;
    return { start: num1, end: num2 };
  };

  const sumAtIndex = (arrays, index) => {
    let sum = 0;

    for (let i = 0; i < arrays.length; i++) {
      const array = arrays[i];

      // Check if the array has enough elements at the specified index
      if (index < array.length) {
        sum += array[index];
      }
    }

    return sum;
  };
  const buy = async () => {
    try {
      setIsLoading(true);

      const signExpiryTime = DateUtility.currentTime() + config?.SIGN_DURATION;
      const signObj = {
        address: account,
        dropId: item?.drop_id,
        signExpiryTime: signExpiryTime,
        nftAmounts: item?.nfts_amount,
        packAmounts: claimNumber,
      };
      const hooksign = await getSignature(signObj);
      const starShip = config.STAR_SHIP_ADDRESS;
      const contract = CommonUtility.contract(
        web3,
        starshipAbi,
        starShip[+chainId]
      );
      const drop = await contract.methods.getPack(item?.drop_id).call();
      let nftsFrom, nftsTo;
      if (+item?.max_supply != +config.UINT256_MAX) {
        nftsFrom = +item?.max_supply - drop?.availableSupply;
        nftsTo = nftsFrom + (+claimNumber - 1);
      } else {
        const res = generateRandomNumbersWithDifference(claimNumber);
        nftsFrom = res.start;
        nftsTo = +res.end - 1;
      }
      const nftAmounts = await getDropNfts(nftsFrom, nftsTo, item?.drop_id);
      if (+claimNumber > +drop?.availableSupply)
        throw `${drop?.availableSupply} drops remaining`;
      if (+drop?.availableSupply == 0) throw "All Sold";
      const remainingCounts = await contract.methods
        .getUserCountedPacks(account, item?.drop_id)
        .call();
      if (+drop?.maxClaimablePacks - remainingCounts < claimNumber)
        throw `You can claim ${
          +drop?.maxClaimablePacks - remainingCounts
        } packs`;
      const isFeeExempted = await contract.methods
        .isFeeExempted(account)
        .call();
      const sign = isFeeExempted ? "" : hooksign;
      const coolDownTime = await contract.methods
        .getUserCoolDownTime(account, item?.drop_id)
        .call();
      if (+coolDownTime > +DateUtility.currentTime())
        throw "Cannot claim right now ";
      for (let i = 0; i < drop?.nftIds?.length; i++) {
        const template = await contract.methods
          .getSchemaInfo(drop?.nftIds[i])
          .call();
        const value = sumAtIndex(nftAmounts, i);
        let sum = 0;
        if (+value + +template?.nftMinted > +template?.maxSupply) {
          for (let j = 0; j < nftAmounts?.length; j++) {
            const array = nftAmounts[j];
            sum += array[i];
            if (sum + +template?.nftMinted > +template?.maxSupply) {
              const updatedAmount =
                +sum + +template?.nftMinted - +template?.maxSupply;
              nftAmounts[j][i] =
                +updatedAmount > 0 && +updatedAmount < nftAmounts[j][i]
                  ? updatedAmount
                  : 0;
            }
          }
        }
      }
      console.log("Modified", nftAmounts);
      const currentTime = DateUtility.currentTime();
      if (
        +currentTime <= +drop?.mintStartTime ||
        +currentTime >= +drop?.mintEndtime
      )
        throw "Pack is not Live";
      if (
        drop?.isWhiteListingEnabled &&
        !isAddressInWhitelist(account, item?.whiteList_addresses)
      ) {
        throw "You are not whitelisted";
      }
      const proof = drop?.isWhiteListingEnabled
        ? item?.proof
        : [config?.ZERO_PROOF];
      const userBalance = await ERC20Service.balance(
        web3,
        item?.payment_token,
        account
      );
      const tokenDecimals = ContractUtility.getPaymentToken(
        item.payment_token,
        chainId
      )?.decimals;
      const feeInToken = CommonUtility.convertFromWei(
        drop?.mintFee,
        tokenDecimals,
        false
      );
      const requiredFee = +feeInToken * claimNumber;
      const requiredFeeInWei = CommonUtility.convertToWei(
        requiredFee,
        tokenDecimals
      );
      if (+userBalance < +requiredFeeInWei) throw "Insuffficient Balance";
      const allowance = await ERC20Service.allowance(
        web3,
        item?.payment_token,
        account,
        starShip[+chainId]
      );
      if (+allowance < +requiredFeeInWei) {
        const approval = await ERC20Service.approve(
          web3,
          starShip[+chainId],
          requiredFeeInWei,
          account,
          item?.payment_token
        );
        if (approval && approval.code == 4001)
          throw "User denied the transaction";
        if (!approval.status) throw "Transaction Failed";
      }
      const isSignUsed = await contract.methods.isSignatureUsed(sign).call();
      if (isSignUsed) throw "Signature already used";
      console.log(
        "Values",
        account,
        item?.drop_id,
        claimNumber,
        signExpiryTime,
        nftAmounts,
        proof,
        sign
      );
      const txn = await contract.methods
        .mintPack(
          account,
          item?.drop_id,
          claimNumber,
          signExpiryTime,
          nftAmounts,
          proof,
          sign
        )
        .send({ from: account });
      if (txn && txn.code == 4001) throw "User denied the transaction";
      if (!txn.status) throw "Transaction Failed";
      if (txn.status) {
        await getDropDetails();
        setIsLoading(false);
        ToastMessage("Success!", "Transaction successful", "success");
        setClaimNumber(null);
      }
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
      setClaimNumber(null);
      if (error?.message) {
        ToastMessage("Error", error?.message, "error");
      } else {
        ToastMessage("Error", error, "error");
      }
    }
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  return (
    <DetailsWrapper>
      <Modal open={isModalOpen} centered onCancel={handleCancel} width={504}>
        <ConnectModals onCancel={handleCancel} />
      </Modal>
      <MainContainer>
        {isLoading && <Loading content={"Loading"} />}

        <DropDiv
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowIcon />
          &nbsp; Drops
        </DropDiv>
      </MainContainer>
      <CollectionDetailSection>
        <MainContainer>
          <CardDetailWrapper>
            <MainRow>
              <MainCol lg={4}>
                <Card>
                  <ImgDiv className="mt-2">
                    <Card.Img src={item?.image} width={375} height={361} />
                  </ImgDiv>

                  <Card.Body>
                    <div>
                      <Card.Title>{item?.name}</Card.Title>
                    </div>
                    <Card.Text>
                      {+item?.price > 0
                        ? CommonUtility.convertFromWei(
                            item?.price,
                            item?.payment_token_decimals,
                            false
                          ) +
                          " " +
                          ContractUtility.getPaymentToken(
                            item.payment_token,
                            chainId
                          )?.symbol
                        : "Free"}
                    </Card.Text>
                    <Divider style={DividerStyle} />
                    <div className="mt-1">
                      <InfoDiv>
                        <h5>max supply</h5>
                        <p>
                          {+item?.max_supply == +config?.UINT256_MAX
                            ? "∞"
                            : item?.max_supply}{" "}
                        </p>
                      </InfoDiv>

                      <InfoDiv>
                        <h5>sold </h5>
                        <p>{sold}</p>
                      </InfoDiv>

                      {account && (
                        <InfoDiv>
                          <h5>your remaining claims </h5>
                          <p>{remainingCounts}</p>
                        </InfoDiv>
                      )}
                      {account && (
                        <InfoDiv>
                          <h5>
                            cooldown Time&nbsp;
                            <Tooltip
                              title={`Upon reaching the account limit of ${
                                +item.amount_limit_per_account > 0 &&
                                +item.amount_limit_per_account !=
                                  config.UINT256_MAX
                                  ? item.amount_limit_per_account
                                  : "certain"
                              } drops, accompanied by a designated cool-down time of ${
                                +item.cool_down_time > 0
                                  ? item.cool_down_time
                                  : "certain"
                              } seconds, users are temporarily restricted from initiating additional purchases during this period. Eligibility for further transactions will be reinstated once the one-hour cool-down duration has elapsed`}
                              placement="right"
                            >
                              <BsInfoCircle
                                style={{ cursor: "pointer" }}
                                fontSize={10}
                              />
                            </Tooltip>
                          </h5>

                          {currentTime > 0 && coolDownTime > 0 ? (
                            <Timer
                              duration={
                                +currentTime <= +coolDownTime
                                  ? +coolDownTime - +currentTime
                                  : 0
                              }
                            />
                          ) : (
                            <p
                              style={{ marginBottom: "0rem", color: "#6b6b6b" }}
                            >
                              0:0:0
                            </p>
                          )}
                        </InfoDiv>
                      )}
                    </div>

                    <div className="d-flex justify-content-center mt-4">
                      <Input
                        type="number"
                        placeholder="Drop Amount"
                        onChange={handleInputChange}
                      />
                    </div>

                    {account ? (
                      <ClaimBtn
                        onClick={buy}
                        disabled={
                          claimNumber < 1 || claimNumber > 5 || !account
                        }
                      >
                        claim
                      </ClaimBtn>
                    ) : (
                      <ConnectBtn
                        style={{ marginLeft: "110px", marginTop: "15px" }}
                        onClick={showModal}
                      >
                        Connect Wallet
                      </ConnectBtn>
                    )}

                    <Divider />

                    <DetailDiv>
                      {
                        <span
                          dangerouslySetInnerHTML={{
                            __html: item?.description,
                          }}
                        />
                      }
                    </DetailDiv>
                  </Card.Body>
                </Card>
              </MainCol>
            </MainRow>
          </CardDetailWrapper>
        </MainContainer>
      </CollectionDetailSection>
      <StarshipFooter />
    </DetailsWrapper>
  );
};

export default CollectionDetailComp;
