import styled from "styled-components";

import background from "assets/login-bg.png";
import loginbg from "../../assets/login.png";

export const Content = styled.div`
  background-image: url(${loginbg});
  background-size: 100vw 100vh;
  background-repeat: no-repeat;

  @media (max-width: 991px) {
    background-size: 100vw 100vh;
    background-position: 0;
  }
  @media (max-width: 460px) {
    background-image: none;
  }
`;

export const LoginMain = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  @media (max-width: 460px) {
    padding: 0 1rem;
  }
`;

export const LoginContainer = styled.div`
  height: 20rem;
  width: 25rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  color: white;
  flex-direction: column;
  background: #ffffff;
  box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.1), 2px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
`;

export const Heading = styled.h1`
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 44px;
  text-transform: uppercase;
  color: #333333;
`;

export const EmailInputField = styled.input`
  border: none;
  outline: none;
  margin-top: 10px;
  text-indent: 15px;
  background: #ffffff;
  box-shadow: inset -2px -2px 4px rgba(0, 0, 0, 0.12),
    inset 2px 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 20px;
  width: 289px;
  height: 42px;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;

  color: #8c8c8c;
`;

export const PasswordInputField = styled.input`
  margin-top: 30px;
  border: none;
  outline: none;
  text-indent: 15px;
  background: #ffffff;
  box-shadow: inset -2px -2px 4px rgba(0, 0, 0, 0.12),
    inset 2px 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 20px;
  width: 289px;
  height: 42px;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;

  color: #8c8c8c;
`;
export const LoginButton = styled.button`
  margin-top: 30px;
  width: 88px;
  height: 40px;
  background: #d35400;
  border-radius: 10px;
  border: none;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
`;
