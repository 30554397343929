// model
import { MainModel } from "components";
import * as C from "components/common";
import openNotification from "components/common/toastMessage/toastMessage";
import { userValidate } from "components/validation/customValidator";
// hooks
import { CreateUserHook, GetUserHook, UpdateUserHook, useForm } from "hooks";

import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";

import { useNavigate, useSearchParams } from "react-router-dom";
// redux slices
import {
  connectModelSlice,
  mainModel,
} from "store/redux/slices/helperSlices/modelSlice";
import { setUser } from "store/redux/slices/userSlices/userSlice";
import { useAppDispatch, useAppSelector } from "store/store";

import { sendFileToThirdWebIPFS } from "utility/services/ipfsService";
import {
  ConnectWalletBtn,
  ProfileTitle,
  ProfileWrapper,
} from "./profileSettingElement";
import { AiFillEye } from "react-icons/ai";
import { useStorageUpload } from "@thirdweb-dev/react";

const Profile = () => {
  const toast = useRef < Toast > null;
  const { mutateAsync: upload } = useStorageUpload();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const refer = searchParams.get("refer");

  const { web3, account } = useAppSelector((state) => state.web3Connect);

  const [uploadLoading, setUploading] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [imageUploading, setImageUploading] = useState(null);
  const [connectModel, setConnectModel] = useState(false);
  const [path, setPath] = useState(null);
  const [path2, setPath2] = useState(null);

  useEffect(() => {
    refer && dispatch(setUser({ ...user, referrer: refer }));
  }, [refer]);

  const handleFileInput = (e) => {
    let fileList = e.fileList.slice(-1);

    setSelectedFile(fileList[0].originFileObj);
    setPath(URL.createObjectURL(fileList[0].originFileObj));

    dispatch(setUser({ ...user, profImage: "profimage" }));
  };

  const handleFileInput2 = (e) => {
    let fileList = e.fileList.slice(-1);

    setSelectedFile2(fileList[0].originFileObj);
    setPath2(URL.createObjectURL(fileList[0].originFileObj));
    dispatch(setUser({ ...user, coverImage: "coverimage" }));
  };

  const goToUserProfile = () => {
    navigate("/profile");
  };

  const profileAction = async () => {
    setUploading(true);
    let profImage = user.profImage;
    let coverImage = user.coverImage;
    if (selectedFile) {
      try {
        setImageUploading(true);
        // const url = await sendFileToIPFS(selectedFile);
        const url = await sendFileToThirdWebIPFS(selectedFile, upload);

        dispatch(setUser({ ...user, profImage: url }));
        profImage = url;
      } catch (error) {
        openNotification("Error", "Profile Image Upload Error", "error");
        console.log("Profile Image Upload Error", error);
      }
    }
    if (selectedFile2) {
      try {
        setImageUploading(true);

        // const url = await sendFileToIPFS(selectedFile2);
        const url = await sendFileToThirdWebIPFS(selectedFile2, upload);

        dispatch(setUser({ ...user, coverImage: url }));
        coverImage = url;
      } catch (error) {
        openNotification("Error", "Cover Image Upload Error", "error");
        console.log("Cover Image Upload Error", error);
      }
    }
    if (user.update) {
      setImageUploading(false);
      setUploading(false);
      update({ ...user, profImage, coverImage });
    } else {
      setImageUploading(false);
      setUploading(false);
      create({ ...user, profImage, coverImage });
    }
  };

  const connectModelFn = () => {
    dispatch(connectModelSlice(true));
  };

  const { user, loading: getLoading, error: getError } = GetUserHook(account);

  const { create, loading } = CreateUserHook();
  const { update, loading: updateLoading } = UpdateUserHook();
  const { handleSubmit, errors } = useForm(profileAction, userValidate, user);

  return (
    <ProfileWrapper>
      {updateLoading && <C.Loader content="Updating" />}
      <MainModel />
      <Toast ref={toast} />

      {!web3 && (
        <div className="text-center  mt-5">
          <ConnectWalletBtn onClick={connectModelFn}>
            Connect Wallet
          </ConnectWalletBtn>
        </div>
      )}
      {web3 && (
        <C.MainRow profileSettingRow>
          <C.MainRow>
            <C.MainCol lg={7}>
              <ProfileTitle>Profile Setting</ProfileTitle>
            </C.MainCol>
            <C.MainCol>
              <C.Buttons isPreviewBtn onClick={goToUserProfile}>
                <AiFillEye style={{ marginTop: "-0.199rem" }} /> Preview
              </C.Buttons>
            </C.MainCol>
          </C.MainRow>
          <C.MainCol lg={7}>
            <C.InputField
              value={user.name}
              setValue={(e) =>
                dispatch(setUser({ ...user, name: e.target.value }))
              }
              inputLabel="username"
              placeholder="Enter Username"
              inputType="text"
              error={errors.name}
            />
            <C.TextArea
              value={user.bio}
              setValue={(e) =>
                dispatch(setUser({ ...user, bio: e.target.value }))
              }
              inputLabel="Bio"
              maxLength={115}
              placeholder="Tell the world your story!"
            />
            <label htmlFor="" style={{ color: "black", marginTop: "0.9rem" }}>
              Email
            </label>
            <C.InputField
              value={user.email}
              setValue={(e) =>
                dispatch(setUser({ ...user, email: e.target.value }))
              }
              placeholder="Enter Email"
              inputType="email"
              error={errors.email}
              inputLinks
            />

            <C.InputField
              value={user.twitter}
              setValue={(e) =>
                dispatch(setUser({ ...user, twitter: e.target.value }))
              }
              inputLinks
              placeholder="Enter Twitter Handler"
              inputType="text"
            />
            <C.InputField
              value={user.instagram}
              setValue={(e) =>
                dispatch(setUser({ ...user, instagram: e.target.value }))
              }
              inputLinks
              placeholder="Enter Instagram Handler"
              inputType="text"
            />
            <C.InputField
              value={user.website}
              setValue={(e) =>
                dispatch(setUser({ ...user, website: e.target.value }))
              }
              inputLinks
              placeholder="Enter Yoursite"
              inputType="text"
            />

            <C.InputField
              value={user.referrer}
              setValue={(e) =>
                dispatch(setUser({ ...user, referrer: e.target.value }))
              }
              disabled={user.update}
              inputLabel="Refreee"
              placeholder="Enter referrer wallet address"
              inputType="text"
            />

            <C.InputField
              inputLabel="Wallet Address"
              value={account || ""}
              disabled
              inputType="text"
            />
            <ConnectWalletBtn
              disabled={
                loading || updateLoading || imageUploading ? true : false
              }
              style={{ marginTop: "2rem" }}
              onClick={handleSubmit}
            >
              {loading
                ? "Saving..."
                : updateLoading
                ? "Updating..."
                : imageUploading
                ? " Image Uploading... "
                : "Save"}
            </ConnectWalletBtn>
          </C.MainCol>
          <C.MainCol lg={2} profileSettingUploadImgCol>
            <C.UploadField
              bgimg={!path ? user.profImage : path}
              collectionProfileBg
              createUploadBtn
              setValue={(e) => handleFileInput(e)}
              uploadLabel="Logo image"
              error={errors.profImage}
            />

            <C.UploadField
              bgimg={!path2 ? user.coverImage : path2}
              profileSettingBanner
              profileSettingBannerBtn
              setValue={(e) => handleFileInput2(e)}
              uploadLabel="Profile Banner"
              error={errors.coverImage}
            />
          </C.MainCol>
        </C.MainRow>
      )}
    </ProfileWrapper>
  );
};

export default Profile;
