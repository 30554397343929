import styled from "styled-components";
import detailsbg from "../../assets/details-bg.png";

export const CreateNewNFTWrapper = styled.div`
  overflow: hidden;
  background-image: url(${detailsbg});
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;

  .container {
    margin-bottom: 4rem;
  }
`;

export const MainHeader = styled.h2`
  font-family: "Anton";
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  line-height: 100%;
  text-transform: uppercase;
  color: #3e3e3e;
  margin: 3rem 0rem 1rem 0;

  @media (max-width: 1199.98px) {
    text-align: center;
  }
`;

export const SubText = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 43px;
  color: #000000;
  margin-top: 2rem;
  z-index: 10;
  position: relative;

  @media (max-width: 1199.98px) {
    text-align: center;
  }

  @media (max-width: 767.98px) {
    margin-top: 0.5rem;
  }

  @media (max-width: 575.98px) {
    font-size: 36px;
  }
`;

export const ShadowDiv = styled.div`
  background: #eacbff;
  border-radius: 8px;
  width: 473px;
  height: 15px;

  transform: translateY(-20px);
  z-index: 1;

  @media (max-width: 1199.98px) {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: 575.98px) {
    width: 260px;
  }
`;

export const FileTypeText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 100%;
  letter-spacing: -0.05em;
  color: #292929;
  margin-top: 2rem;
`;

export const UploadImageDiv = styled.div`
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  border-radius: 5px;
  cursor: pointer;
  width: 788px;
  height: 340px;
  border: 1px dashed #636363;
  border-radius: 4px;
  margin-top: 2rem;

  label {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    .dragText {
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 100%;
      letter-spacing: -0.05em;
      color: #292929;
      margin-top: 10px;
    }

    .browseText {
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 100%;
      letter-spacing: -0.05em;
      color: #261ce8;
      margin-top: 10px;
    }
  }

  .upload {
    margin-bottom: 3px;
    font-size: 20px;
    margin-right: 10px;
  }
`;

export const FormContainer = styled.div`
  margin-top: 3rem;

  @media (max-width: 575.98px) {
    margin-top: 1rem;
  }

  .form-label {
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 24px;
    letter-spacing: 0.01px;
    color: #000000;
    margin-top: 10px;
  }

  .form-control {
    border-radius: 4px 4px 0px 0px;
    width: 792px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #261ce8;
    box-shadow: none;
    height: 48px;

    @media (max-width: 991.98px) {
      width: 100%;
    }

    &::placeholder {
      color: rgba(0, 0, 0, 0.5);
    }
  }

  .textarea {
    min-height: 120px;
    margin-top: 15px;
  }

  .externalLink {
    margin-top: 2rem;
  }

  .infoText {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #1c1b1f;
    margin-top: 15px;
  }
`;

export const Required = styled.h2`
  color: red;
  font-size: 18px;
  margin-left: 10px;
`;

export const SaveBtn = styled.button`
  width: 121px;
  height: 52px;
  background: linear-gradient(98.6deg, #261ce8 6.03%, #511ce8 99.87%);
  border-radius: 4px;
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 100%;
  letter-spacing: -0.05em;
  color: #ffffff;
  margin: 1rem 0 3rem 0;

  @media (max-width: 575.98px) {
    display: block;
    margin: 2rem auto 2rem auto;
  }
`;

export const PanelDiv = styled.div`
  margin-top: 1rem;
  width: 792px;

  @media (max-width: 991.98px) {
    width: 100%;
  }

  .rs-panel-collapsible > .rs-panel-header > .rs-icon {
    background-color: #9040c4;
    color: #fff;
    width: 26px;
    height: 26px;
    transform: translateY(-3px);
    border-radius: 4px;
  }

  .rs-panel-title {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #261ce8;
  }

  .rs-panel-body,
  .rs-panel-header {
    padding: 20px 15px;
    border-radius: 4px 4px 0px 0px;
  }

  .borderClass {
    border-bottom: 1px solid #e7e0ec;
  }
`;

export const PanelContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;

  padding-bottom: 20px;
`;

export const PanelContentLeftDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;

  .imgWrapper {
    .innerImg {
      height: 34px;
      width: 34px;
    }
  }

  .textWrapper {
    .mainText {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.5px;
      color: #1c1b1f;
    }

    .subText {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      letter-spacing: 0.5px;
      color: #1c1b1f;
    }
  }
`;

export const PanelContentRightDiv = styled.div`
  .addIcom {
    color: #9040c4;
    border-radius: 4px;
    width: 36px;
    height: 36px;
    cursor: pointer;
  }

  .rs-toggle-checked .rs-toggle-presentation {
    background-color: #9040c4 !important;
  }
  .rs-toggle-checked .rs-toggle-presentation:hover {
    background-color: #9040c4 !important;
  }
`;

export const Divider = styled.div`
  height: 10px;
`;
