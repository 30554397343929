import { APIPath } from "utility/constant";
import { BaseService } from "./baseServices";
// import { BaseService } from "./base";

class CreateSchema {
  createSchema = (data) => {
    return BaseService.post(APIPath.createSchema, data);
  };
  getCreateSchema = () => {
    return BaseService.get(APIPath.getCreateSchema);
  };
  getCreateSchemaById = (id) => {
    return BaseService.get(`${APIPath.getCreateSchemaById}/${id}`);
  };

  //   editFeatured = (id, data) => {
  //     return BaseService.put(${APIPath.editFeatured}/${id}, data);
  //   };
  //   deleteFeatured = (id) => {
  //     return BaseService.delete(${APIPath.deleteFeatured}/${id});
  //   };
  //   update0xFeaturedBySerial = (data) => {
  //     return BaseService.post(APIPath.update0xFeaturedBySerial, data);
  //   };
}

const CreateSchemaService = new CreateSchema();
Object.freeze(CreateSchemaService);
export { CreateSchemaService };
