import { useEffect } from "react";
import { ErrorMessage } from "components/nftForm/nftFormElement";
import { reportValidation } from "components/validation/customValidator";
import { AddReportHook, useForm } from "hooks";
import { useState } from "react";
import { useAppSelector } from "store/store";

import * as R from "../propertiesModel/propertiesModelElement";

const ReportModel = ({ nftId }) => {
  const { account, chainId } = useAppSelector((state) => state.web3Connect);
  const initialValues = {
    nft_id: nftId,
    wallet_address: account,
    message: "",
    network_id: chainId,
    title: "",
  };
  const [reportData, setReportData] = useState(initialValues);

  const add = () => {
    addReport(reportData);
  };

  const { addReport, data, loading } = AddReportHook();
  const { handleSubmit, errors } = useForm(add, reportValidation, reportData);

  useEffect(() => {
    data && setReportData(initialValues);
  }, [data]);

  return (
    <div>
      {" "}
      <R.ReportModels>
        <R.ReportModelsData>
          <R.ReportForm style={{ padding: "1rem 2rem" }}>
            <h5 style={{ marginBottom: "1rem" }}>Report this item</h5>

            <R.InputField
              value={reportData.title}
              onChange={(e) =>
                setReportData({ ...reportData, title: e.target.value })
              }
              placeholder="Enter Title"
              type="text"
            ></R.InputField>
            {errors?.title && <ErrorMessage>{errors?.title}</ErrorMessage>}

            <R.InputTextArea
              value={reportData.message}
              onChange={(e) =>
                setReportData({ ...reportData, message: e.target.value })
              }
              rows={5}
              placeholder="Enter Your Message"
            ></R.InputTextArea>
            {errors?.message && <ErrorMessage>{errors?.message}</ErrorMessage>}

            <R.ReportBtn disabled={loading} onClick={handleSubmit}>
              {loading ? "Sending..." : "Report"}
            </R.ReportBtn>
          </R.ReportForm>
        </R.ReportModelsData>
      </R.ReportModels>
    </div>
  );
};

export default ReportModel;
