import styled from "styled-components";

export const ExploreWrapper = styled.div`
  overflow: hidden;
  background-color: ${({ theme }) => theme.backgroundColor};
  width: 100vw;

  .fluid {
    padding-bottom: 4rem;
    position: relative;
    padding-left: 7rem;

    @media (max-width: 767.98px) {
      padding-right: 2rem !important;
      padding-left: 2rem !important;
    }
    @media (max-width: 575.98px) {
      padding-right: 10px !important;
      padding-left: 10px !important;
    }
  }
  .filter-img {
    margin-top: 5rem;
    @media (min-width: 1199.98px) {
      display: none;
    }
  }

  .back-arrow {
    font-size: 32px;
    cursor: pointer;
    color: ${({ theme }) => theme.backArrow};
    position: absolute;
    top: 65px;
    z-index: 20;
  }

  .main-row {
    margin-top: 2rem;
  }

  .card-wrapper {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 0px;
    padding-bottom: 8rem;

    @media (max-width: 1399.98px) {
      grid-template-columns: repeat(4, 1fr);
    }
    @media (max-width: 1210px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 991.98px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 575.98px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .extra-content {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 2rem;

    @media (max-width: 991.98px) {
      margin-top: 2rem;
      justify-content: flex-start;
    }
  }

  .refresh-icon {
    font-size: 32px;
    border: 1px solid ${({ theme }) => theme.selectText};
    border-radius: 20px;
    padding: 6px;
    cursor: pointer;
    color: ${({ theme }) => theme.selectText};
  }

  .first-explore-select.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border-radius: 1.75rem !important;
    border: 1px solid ${({ theme }) => theme.selectText} !important;
    background-color: transparent;
    color: ${({ theme }) => theme.selectText} !important;
  }

  /* .select-div {
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border-radius: 1.75rem !important;
      border: 1px solid ${({ theme }) => theme.selectText} !important;
      background-color: transparent;
      color: ${({ theme }) => theme.selectText} !important;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      color: ${({ theme }) => theme.selectText} !important;
    }

    .ant-select-arrow {
      color: ${({ theme }) => theme.selectText} !important;
    }
  } */

  .sidebar {
    display: flex;
    gap: 5px;
    overflow-y: hidden !important;
    position: relative;

    @media (max-width: 1199.98px) {
      flex-direction: column;
      overflow-y: auto !important;
    }
  }

  .sidebar__sidebar {
    width: 18%;
    height: 100vh;
    overflow-y: hidden !important;
    padding-top: 4rem;

    @media (max-width: 1199.98px) {
      height: auto;
      width: 100%;
    }
  }

  .sidebar__main {
    flex: 1;
    height: 100vh;
    overflow-y: auto !important;
    padding-right: 7rem;
    padding-top: 2rem;
    position: relative;

    @media (max-width: 1199.98px) {
      height: auto;
      padding-bottom: 2rem;
      padding-right: 2rem;
    }

    @media (max-width: 575.98px) {
      padding-right: 10px !important;
    }
  }

  .border-div {
    border-left: 1px solid ${({ theme }) => theme.navBorder};
    padding-left: 1rem;
    min-height: calc(100vh - 2rem);

    @media (max-width: 1199.98px) {
      border-left: none;
      padding-left: 0;
    }
  }

  .filter-img {
    margin-top: 5rem;
    @media (min-width: 1199.98px) {
      display: none;
    }
  }

  .no-border {
    border-left: none !important;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item {
    color: #c9c9c9 !important;
  }

  .ant-select-arrow {
    color: #c9c9c9 !important;
  }

  .filter-inner-div {
    height: 70vh;
    overflow-y: auto;
    padding-right: 1rem;
    padding-bottom: 5rem;
    overflow-x: hidden !important;

    @media (max-width: 1199.98px) {
      height: auto;
      padding-bottom: 0rem;
      overflow-y: hidden !important;
    }

    @media (max-width: 575.98px) {
      padding-right: 10px !important;
    }

    &.height-class {
      height: 80vh;

      @media (max-width: 1399.98px) {
        height: 70vh;
      }
      @media (max-width: 1199.98px) {
        height: 800px;
      }

      @media (max-width: 1399.98px) {
        height: 70vh;
      }
      @media (max-width: 1199.98px) {
        height: 800px;
      }
    }
  }
`;

export const MainHeader = styled.h2`
  color: ${({ theme }) => theme.headerColor};
  font-size: 51px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  text-align: center;
  margin: 0;
`;

export const FiltersWrapper = styled.div`
  /* margin-top: 4rem; */
  margin-top: 4rem;

  @media (max-width: 1199.98px) {
    margin-top: 0;
  }
  @media (max-width: 1199.98px) {
    margin-top: 0;
  }
  .form-wrapper {
    position: relative;
  }
  .form-control {
    border-radius: 10px;
    border: 1px solid ${({ theme }) => theme.selectBorder};
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27);
    margin-top: 1rem;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    padding-top: 10px;
    padding-bottom: 10px;
    position: relative;
    background-color: transparent;
    color: ${({ theme }) => theme.inputtext};
    &::placeholder {
      color: #c9c9c9;
    }
  }

  .search {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 18px;
    cursor: pointer;
    color: rgba(201, 201, 201, 1);
  }

  .no-margin {
    margin-top: -0.5rem;
  }

  .attribut-select
    .ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border: none !important;
  }
`;

export const FiltersHeader = styled.h2`
  color: #00b7ff;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  padding-bottom: 1rem;
  border-bottom: 1px solid #00b7ff;

  @media (max-width: 1199.98px) {
    width: 50px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const FiltersSubHeader = styled.h2`
  color: ${({ theme }) => theme.filterSubTextColor};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  margin: 0;
`;

export const SearchWrapper = styled.div`
  .form-label {
    color: #6b6b6b;
    font-family: Barlow;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    padding-bottom: 0.3rem;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 0.625rem;
    border: 1px solid ${({ theme }) => theme.selectBorder} !important;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27);
    background-color: transparent;
  }

  .form-control {
    border: none;
    font-size: 0.75rem;
    width: 3rem;
    padding: 0.375rem 0.35rem;
    &:focus {
      box-shadow: none;
    }

    &::placeholder {
      color: #c9c9c9;
      font-family: Barlow;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  .form-text {
    color: #6b6b6b;
    font-family: Barlow;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    padding: 0px 0.7rem;
    padding-bottom: 0.4rem;
  }

  .field-div {
    display: flex;
    align-items: center;
    border-radius: 0.625rem;
    border: 1px solid #e7e7e7;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27);
  }
`;

export const DropdownDiv = styled.div`
  position: relative;

  .form-control {
    border-radius: 10px;
    border: 1px solid ${({ theme }) => theme.selectBorder};
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27);
    margin-top: 1rem;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    padding-top: 10px;
    padding-bottom: 10px;
    color: ${({ theme }) => theme.cardText};
    position: relative;
    background-color: transparent;

    &::placeholder {
      color: #c9c9c9;
    }
  }

  .drops-dropdown {
    position: absolute;
    top: 100px;
    border: 1px solid ${({ theme }) => theme.selectBorder};
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27);
    z-index: 200 !important;
    max-height: 200px;
    padding: 1rem 10px;
    width: 100%;
    overflow-y: auto !important;
    overflow-x: hidden !important;

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background-color: #fff;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background-color: rgb(193, 193, 193);
    }
  }

  .dropdown-arrow {
    color: ${({ theme }) => theme.backArrow};
    position: absolute;
    top: 55px;
    right: 10px;
  }

  .close {
    cursor: pointer;
  }

  .content-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;
    border-bottom: 1px solid ${({ theme }) => theme.selectBorder};
    padding-bottom: 0.5rem;
    cursor: pointer;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      background-color: ${({ theme }) =>
        theme.selectdropdownhoverColor} !important;
    }
  }

  .top-text {
    color: ${({ theme }) => theme.cardText};
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 100% */
    text-transform: capitalize;
  }

  .bottom-text {
    color: ${({ theme }) => theme.cardText};
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 100% */
    text-transform: capitalize;
    margin-top: 5px;
  }
`;

export const NoDataFoundComDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10rem;

  @media (max-width: 991.98px) {
    margin-top: 2rem;
  }
`;
