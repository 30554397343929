import { BsArrowLeftCircle } from "react-icons/bs";
import { ExploreWrapper, NoDataFoundComDiv } from "./element";
import { useNavigate, useLocation } from "react-router-dom";
import FilterComp from "./filterComp";
import { ExploreCard } from "components/common/cards";
import { Select } from "antd";
import { FiRefreshCcw } from "react-icons/fi";
import { GetMintsHook } from "hooks/mintHook";
import { useEffect, useState, useRef } from "react";
import StarshipFooter from "../footer";
import { CommonUtility, filterTypes } from "utility";
import NoDataFoundCom from "../noDataFound";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useAppSelector } from "store/store";
import Loading from "components/common/loader";
function ExploreCom() {
  const [page, setPage] = useState(30);
  const [filter, setFilter] = useState({});
  const [cardsLoading, setCardsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const {
    data: mintedNfts,
    getMints,
    loading,
    total,
    refresh,
  } = GetMintsHook(page, filter);

  const [mints, setMints] = useState([]);
  useEffect(() => {
    let arr = [];
    if (mintedNfts) {
      for (let i = 0; i < mintedNfts.length; i++) {
        if (mintedNfts[i].drop_id == null) {
          arr.push(mintedNfts[i]);
        } else {
          if (+mintedNfts[i].drop_id.reveal_time <= Date.now() / 1000)
            arr.push(mintedNfts[i]);
        }
      }
    }
    setMints(arr);
  }, [mintedNfts]);

  useEffect(() => {
    const isExploreRoute = location.pathname === "/starship/explore";
    const isHomeRoute = location.pathname === "/";

    if (isExploreRoute || isHomeRoute) {
      document.body.classList.add("disable-scroll");
    } else {
      document.body.classList.remove("disable-scroll");
    }

    return () => {
      document.body.classList.remove("disable-scroll");
    };
  }, [location, navigate]);

  const exploreRef = useRef(null);

  const handleScroll = () => {
    const exploreElement = exploreRef.current;
    if (exploreElement) {
      const scrollPosition =
        exploreElement.clientHeight + exploreElement.scrollTop;
      const scrollHeight = exploreElement.scrollHeight;
      if (scrollPosition >= scrollHeight - 180 && !loading) {
        if (page < total) {
          setPage(page + 20);
        }
      }
    }
  };

  useEffect(() => {
    const exploreElement = exploreRef.current;
    if (exploreElement) {
      exploreElement.addEventListener("scroll", handleScroll);
      return () => {
        exploreElement.removeEventListener("scroll", handleScroll);
      };
    }
  }, [page, total, loading]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const { theme } = useAppSelector((state) => state.model);

  return (
    <div>
      {cardsLoading && <Loading content={"Loading"} />}
      <ExploreWrapper>
        <div className="fluid">
          {location.pathname !== "/" && (
            <BsArrowLeftCircle
              className="back-arrow"
              onClick={() => navigate("/")}
            />
          )}

          <div className="sidebar">
            <aside className="sidebar__sidebar">
              <FilterComp filter={filter} setFilter={setFilter} />
            </aside>
            <main className="sidebar__main" ref={exploreRef}>
              <div className={"border-div"}>
                <div className="extra-content">
                  <div className="select-div">
                    <Select
                      bordered={false}
                      className="first-explore-select"
                      popupClassName="first-explore-select-dropdown"
                      defaultValue="All"
                      style={{ width: 140 }}
                      allowClear
                      placeholder="Select"
                      value={filter?.listFilter || "All"}
                      onClear={() => {
                        setFilter({});
                      }}
                      onChange={(e) => {
                        setFilter({
                          ...filter,
                          listFilter: e,
                        });
                      }}
                      options={[
                        { value: filterTypes.All, label: filterTypes.All },
                        {
                          value: filterTypes.Listed,
                          label: filterTypes.Listed,
                        },
                        {
                          value: filterTypes.NotListed,
                          label: filterTypes.NotListed,
                        },
                      ]}
                    />
                  </div>

                  <FiRefreshCcw
                    className="refresh-icon"
                    onClick={() => {
                      getMints(page, filter);
                    }}
                  />
                </div>
                <div className="card-wrapper">
                  {loading && !mints ? (
                    <>
                      {Array.from({ length: 10 }).map((_, index) => (
                        <Skeleton
                          width="100%"
                          height="530px"
                          borderRadius="12px"
                          baseColor={
                            theme === "light" ? "#ebebeb" : "transparent"
                          }
                          style={{
                            border:
                              theme === "light"
                                ? "1px solid #ebebeb"
                                : "1px solid #727989",
                          }}
                        />
                      ))}
                    </>
                  ) : (
                    <>
                      {mints?.length > 0 &&
                        mints?.map((item) => (
                          <ExploreCard
                            item={item}
                            setLoading={setCardsLoading}
                            page={page}
                            filter={filter}
                            getMints={getMints}
                            refresh={refresh}
                          />
                        ))}
                    </>
                  )}

                  {loading && (
                    <>
                      {Array.from({ length: 10 }).map((_, index) => (
                        <div style={{ marginTop: "1rem" }} key={index}>
                          <Skeleton
                            width="100%"
                            height="530px"
                            borderRadius="12px"
                            baseColor={
                              theme === "light" ? "#ebebeb" : "transparent"
                            }
                            style={{
                              border:
                                theme === "light"
                                  ? "1px solid #ebebeb"
                                  : "1px solid #727989",
                            }}
                          />
                        </div>
                      ))}
                    </>
                  )}
                </div>
                {!loading && mints?.length === 0 && (
                  <NoDataFoundComDiv>
                    <NoDataFoundCom
                      message={"no nFT’s Found for this filter"}
                    />
                  </NoDataFoundComDiv>
                )}
              </div>
            </main>
          </div>
        </div>
        <StarshipFooter />
      </ExploreWrapper>
    </div>
  );
}

export default ExploreCom;
