import styled from "styled-components";

export const MainSection = styled.section`
  border-top: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
  padding: 2rem 0rem;
`;

export const SocialWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
`;

export const Imgwrapper = styled.div`
  text-align: center;
  margin-top: 1.5rem;
`;

export const LastDiv = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.footertext};
  font-family: Barlow;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem; /* 150% */
  text-transform: capitalize;
  padding-bottom: 1rem;
  margin-top: 0.5rem;
`;
