import styled from "styled-components";
import { AiOutlineInstagram, AiOutlineGithub } from "react-icons/ai";
import { FaTelegramPlane, FaFacebookF } from "react-icons/fa";

export const MainFooter = styled.div`
  overflow: hidden;
  background-color: #118cd5;

  .container {
    margin-top: 4rem;

    @media (max-width: 767.98px) {
      margin-top: 2rem;
    }
  }
`;

export const BottomText = styled.p`
  font-family: "Figtree";
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 100%;
  letter-spacing: -0.02em;
  color: #ffffff;
  margin-top: 2rem;
  width: 80%;

  @media (max-width: 1199.98px) {
    width: 100%;
    text-align: center;
    margin-top: 1rem;
  }

  @media (max-width: 575.98px) {
    font-size: 18px;
  }
`;

export const LinksContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;

  @media (max-width: 1199.98px) {
    margin-top: 1rem;
  }
`;
export const FooterLinks = styled.h4`
  font-family: "Figtree";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 19px;
  text-decoration-line: underline;
  color: #ffffff;
  cursor: pointer;
`;

export const SocialIconsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 2rem;
`;

export const InstagramIcon = styled(AiOutlineInstagram)`
  color: #fff;
  font-size: 24px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    scale: 1.2;
    transition: all 0.3s ease-in-out;
  }
`;
export const FacebookIcon = styled(FaFacebookF)`
  color: #fff;
  font-size: 24px;

  transition: all 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    scale: 1.2;
    transition: all 0.3s ease-in-out;
  }
`;
export const GithubIcon = styled(AiOutlineGithub)`
  color: #fff;
  font-size: 24px;

  transition: all 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    scale: 1.2;
    transition: all 0.3s ease-in-out;
  }
`;
export const TelegramIcon = styled(FaTelegramPlane)`
  color: #fff;
  font-size: 24px;

  transition: all 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    scale: 1.2;
    transition: all 0.3s ease-in-out;
  }
`;

export const FooterLogoWrapper = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: center;
`;

export const BottomTextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  margin-top: 4rem;
  margin-bottom: 0.5rem;

  @media (max-width: 767.98px) {
    margin-top: 2rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
`;

export const BottomWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  & > p {
    text-decoration: underline;
    text-underline-offset: 3px;
    cursor: pointer;
  }
`;

export const BottomLinks = styled.p`
  margin: 0;
  font-family: "Figtree";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;

  @media (max-width: 575.98px) {
    font-size: 12px;
    text-align: center;
  }
`;

// Admin Footer

export const Ul = styled.ul`
  list-style: none;
  color: white;
`;
export const Li = styled.li`
  font-size: 12px;
  font-weight: 500;
  margin-top: 0.8rem;
  list-style: none;
  color: white;
`;

export const MainUl = styled.p`
  display: flex;
  justify-content: end;
  gap: 0.5rem;
`;
export const CopyrighText = styled.p`
  color: white;
  margin-left: 2rem;
  font-weight: 600;
`;
