import { MainHeader, ModalWrapper } from "./element";
import { MdClose } from "react-icons/md";
import { Select } from "antd";
function SelectPackModal({
  handleCancel,
  setFieldValue,
  setFieldTouched,
  values,
  errors,
  touched,
  schemas,
}) {
  console.log("VALUESSSS", values);

  const handleSchemaSelect = (e) => {
    setFieldValue("schema_id", e);
    setFieldTouched("schema_id", true);
    handleCancel(); // Close the modal when schema is selected
  };

  return (
    <ModalWrapper>
      <MdClose
        cursor="pointer"
        fontSize={22}
        color="#6B6B6B"
        className="close-icon"
        onClick={handleCancel}
      />
      <MainHeader>select schema for the pack</MainHeader>
      <Select
        showSearch
        className="modal-select mt-3"
        placeholder="Select Schema"
        optionFilterProp="children"
        value={values.schema_id || `Select Schema`}
        onSelect={handleSchemaSelect}
        onBlur={() => {
          setFieldTouched("schema_id", true);
        }}
        filterOption={(input, option) =>
          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
        }
        options={schemas?.map((c) => {
          return {
            value: c._id,
            label: c.schema_name,
          };
        })}
      />
    </ModalWrapper>
  );
}

export default SelectPackModal;
