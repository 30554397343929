

const UserNftDetail = () => {
 
  return (
    <div>

    </div>
  );
};

export default UserNftDetail;
