import {
  BtnWrapper,
  CancelBtn,
  FormDiv,
  ModalWrapper,
  Title,
  UpdateBtn,
} from "./elements";
import { MdClose } from "react-icons/md";
import { Form } from "react-bootstrap";
import { useMemo, useState } from "react";
import { CommonUtility, ContractUtility } from "utility";
import { useAppSelector } from "store/store";
import { starshipAbi } from "contract/starship";
import { ToastMessage } from "components/common";
import Loading from "components/common/loader";

const UpdateDropModal = ({ handleCancel }) => {
  return (
    <ModalWrapper>
      <MdClose
        cursor="pointer"
        fontSize={22}
        color="#6B6B6B"
        className="close-icon"
        onClick={handleCancel}
      />

      <Title>Update Supply </Title>
      <FormDiv>
        <div>
          <Form.Label htmlFor="inputPassword5">Supply</Form.Label>
          <Form.Control
            type="number"
            id="number"
            aria-describedby="passwordHelpBlock"
            placeholder="1"
            min={0}
          />
        </div>

        <BtnWrapper>
          <CancelBtn onClick={handleCancel}>Cancel</CancelBtn>
          <UpdateBtn>Update</UpdateBtn>
        </BtnWrapper>
      </FormDiv>
    </ModalWrapper>
  );
};

export default UpdateDropModal;
