import { useFormik } from "formik";
import { CreatePackWrapper } from "./element";
import PackSlotsSection from "./packSlotsSection";
import ScheduleSection from "./scheduleSection";
import SelectPackCom from "./selectPackSection";
import {
  CreatePackHook,
  GetPackByIdHook,
  UpdatePackHook,
} from "hooks/packHook";
import Loading from "components/common/loader";
import { storage } from "../../../../firebase";
import { useEffect, useRef, useState } from "react";
import { validatePack } from "components/validation/customValidator";
import dayjs from "dayjs";
import utcPlugin from "dayjs/plugin/utc";
import { GetCreateSchemaHook } from "hooks/createSchemaHook";
import { useParams } from "react-router-dom";
import { Container } from "react-bootstrap";

function CreatePackCom({ setCardsData }) {
  const { id } = useParams();
  const quillRef = useRef(null);
  const [description, setDescription] = useState(null);
  const [slotsArray, setSlotsArray] = useState([{ templates: [1] }]);
  const {
    data: packCreated,
    loading,
    createPack,
    setLoading,
  } = CreatePackHook();
  const {
    data: editCreated,
    setLoading: setEditLoading,
    loading: editLoading,
    editPack,
  } = UpdatePackHook();

  const {
    data: dataByPackId,
    loading: getPackLoading,
    getPackById,
  } = GetPackByIdHook();

  const getData = async () => {
    try {
      await getPackById(id);
    } catch (error) {
      console.error("Error fetching pack data:", error);
    }
  };
  useEffect(() => {
    if (id) {
      getData();
    }
  }, [id]);
  const { data: schemas } = GetCreateSchemaHook();

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    setFieldValue,
    setFieldTouched,
    resetForm,
  } = useFormik({
    initialValues: {
      name: "",
      description: "",
      pack_slots: [[]],
      video_url: "",
      image_url: "",
      reveal_text: "",
      background_color: "",
      schema_id: "",
      schema_name: "",
    },
    validate: validatePack,
    onSubmit: async (values) => {
      if (id) {
        setEditLoading(true);
      } else {
        setLoading(true);
      }

      const images = document.querySelectorAll("img");
      const uploadImages = async () => {
        const uploadPromises = [];
        for (let i = 1; i < images.length; i++) {
          const image = images[i];
          const formData = new FormData();

          const src = image.getAttribute("src");
          if (src.startsWith("data:image")) {
            const file = dataURLtoFile(src, `image${i}.png`);
            formData.append("story_images", file);

            const storageRef = storage.ref(`/starship/image${i}.png`);
            const uploadTask = storageRef.put(file);

            const uploadPromise = new Promise((resolve, reject) => {
              uploadTask.on(
                "state_changed",
                (snapshot) => {},
                (error) => {
                  console.log(error);
                  reject(error);
                },
                async () => {
                  try {
                    const downloadURL = await storageRef.getDownloadURL();
                    resolve(downloadURL);
                  } catch (error) {
                    console.error("Error getting download URL:", error);
                    reject(error);
                  }
                }
              );
            });

            formData &&
              formData instanceof FormData &&
              formData.entries().next().done &&
              uploadPromises.push(uploadPromise);
          }
        }
        return Promise.all(uploadPromises);
      };

      try {
        const fileUrls = await uploadImages();
        for (let i = 0; i < images.length; i++) {
          const image = images[i + 1];
          const fileUrl = fileUrls[i];
          fileUrl &&
            fileUrl !== "undefind" &&
            image?.setAttribute("src", fileUrl);
        }
        const editorContent = quillRef.current.getEditor().root.innerHTML;
        values.description = editorContent;
        id ? editPack(id, values) : createPack(values);
        setDescription(null);
        resetForm();
      } catch (error) {
        console.error("Error uploading images:", error);
      }
    },
  });

  useEffect(() => {
    if (dataByPackId || values.schema_id) {
      let name = schemas?.find((z) => z._id == values.schema_id);
      setFieldValue("schema_name", name?.schema_name);
    }
  }, [schemas, dataByPackId, values.schema_id]);

  useEffect(() => {
    if (dataByPackId) {
      setFieldValue("name", dataByPackId.name);
      setFieldValue("slots", dataByPackId.slots);
      setFieldValue(
        "pack_slots",
        dataByPackId.pack_slots?.map((slotArray) => {
          return slotArray.map((x) => {
            return {
              template_id: x.template_id?._id,
              probability: x.probability,
            };
          });
        })
      );
      setDescription(dataByPackId.description);
      dayjs.extend(utcPlugin);
      setFieldValue("unpacking_date_times", dataByPackId?.unpacking_date_time);
      setFieldValue("image_url", dataByPackId.image_url);
      setFieldValue("video_url", dataByPackId.video_url);
      setFieldValue("reveal_text", dataByPackId?.reveal_text);
      setFieldValue("background_color", dataByPackId.background_color);
      setFieldValue(
        "schema_id",
        dataByPackId?.pack_slots[0]?.template_id?.schema_id
      );
      setFieldValue("description", dataByPackId?.description);

      setSlotsArray(
        dataByPackId.pack_slots.map((slot) => {
          return {
            templates: slot.map((template) => template.template_id._id),
          };
        })
      );
    }
  }, [dataByPackId]);
  const dataURLtoFile = (dataURL, filename) => {
    const arr = dataURL.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const uint8Array = new Uint8Array(n);
    while (n--) {
      uint8Array[n] = bstr.charCodeAt(n);
    }
    return new File([uint8Array], filename, { type: mime });
  };
  return (
    <CreatePackWrapper>
      <Container fluid>
        {(loading || getPackLoading || editLoading) && (
          <Loading content={id ? "Updating Pack" : "Creating pack"} />
        )}
        <SelectPackCom
          values={values}
          handleChange={handleChange}
          handleBlur={handleBlur}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          quillRef={quillRef}
          description={description}
          setDescription={setDescription}
          schemas={schemas}
        />
        <PackSlotsSection
          values={values}
          handleChange={handleChange}
          handleBlur={handleBlur}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          schemas={schemas}
          setSlotsArray={setSlotsArray}
          slotsArray={slotsArray}
        />
        <ScheduleSection
          setCardsData={setCardsData}
          values={values}
          handleChange={handleChange}
          handleBlur={handleBlur}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          handleSubmit={handleSubmit}
          resetForm={resetForm}
          packCreated={packCreated}
          dataByPackId={dataByPackId}
          editCreated={editCreated}
          quillRef={quillRef}
          setSlotsArray={setSlotsArray}
          slotsArray={slotsArray}
        />
      </Container>
    </CreatePackWrapper>
  );
}
export default CreatePackCom;
