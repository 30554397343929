import { MainContainer, MainCol, MainRow } from "components/common";
import {
  TopbarElement,
  MaintenanceElement,
  IconContainer,
  IconHolder,
  FacebookIcon,
  TwitterIcon,
  LinkedIn,
  ComponentHeader,
  TextContainer,
  ContactText,
  Copyrights,
} from "./element";
import { Navbar } from "react-bootstrap";
import secondlogo from "../../assets/secondlogo.png";
import construction from "../../assets/construction.png";

function MaintenancePage() {
  return (
    <MaintenanceElement>
      <TopbarElement>
        <Navbar collapseOnSelect expand="lg">
          <MainContainer>
            <Navbar.Brand href="/">
              <img src={secondlogo} className="img-fluid" alt="Responsive " />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav"></Navbar.Collapse>
          </MainContainer>
        </Navbar>
      </TopbarElement>
      <MainContainer className="pt-lg-5 py-3 ">
        <MainRow>
          <MainCol>
            <ComponentHeader>maintenance</ComponentHeader>
          </MainCol>
        </MainRow>
        <MainRow>
          <MainCol>
            {/* <TextContainer>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit
              iusto accusamus rerum dolores.
            </TextContainer> */}
          </MainCol>
        </MainRow>
        <MainRow>
          <MainCol>
            <img src={construction} className="img-fluid" alt="Responsive " />
          </MainCol>
        </MainRow>
        <MainRow>
          <MainCol>
            <ContactText>
              For business queries, please send your query at {""}
              <a href="mailto:contact@nethty.co">jake@nethty.co</a>
            </ContactText>
          </MainCol>
        </MainRow>
        <MainRow>
          <MainCol>
            <IconContainer>
              <IconHolder>
                <FacebookIcon />
              </IconHolder>
              <IconHolder>
                <a href="https://twitter.com/NethtyNFT">
                <TwitterIcon/>
                </a>
              </IconHolder>
              <IconHolder>
                <LinkedIn />
              </IconHolder>
            </IconContainer>
          </MainCol>
        </MainRow>
        <Copyrights>Copyright 2022 by Nethty. All rights reserved.</Copyrights>
      </MainContainer>
    </MaintenanceElement>
  );
}

export default MaintenancePage;
