import cardimg from "../../../../assets/cardimg.webp";
import { CardsWrapper, StatusDiv } from "./element";
import Card from "react-bootstrap/Card";
import { Dropdown, Menu } from "antd";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { MdDelete, MdModeEditOutline } from "react-icons/md";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MainRow } from "components/common";
import { DeletePackHook } from "hooks/packHook";
import Loading from "components/common/loader";
import { MainCardWrapper } from "components/starship/drops/element";
import { AiOutlineGlobal } from "react-icons/ai";
import Timer from "components/starship/viewCollection/timer";
import { DateUtility } from "utility";
function PacksCard({ packs, getPacks }) {
  const navigate = useNavigate();
  const [schemaId, setSchemaId] = useState(null);
  const [pack, setPack] = useState(null);
  const { deletePack, loading } = DeletePackHook();
  const [hoverIndex, setHoverIndex] = useState();
  const location = useLocation();
  const [currentTime, setCurrentTime] = useState(Number);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(Math.floor(DateUtility.currentTime()));
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  const menu = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <div
              className="items-wrapper"
              onClick={() => {
                navigate(`/starship/admin/packs/${pack?._id}`);
              }}
            >
              <p className="items-text">modify pack</p>
              <MdModeEditOutline fontSize={20} />
            </div>
          ),
        },

        pack?.is_deletable && {
          key: "3",
          label: (
            <div
              className="items-wrapper"
              onClick={() => {
                deletePack(pack?._id, getPacks);
              }}
            >
              <p className="items-text">Delete Pack</p>
              <MdDelete fontSize={20} />
            </div>
          ),
        },
      ]}
    />
  );

  return (
    <MainRow>
      {loading && <Loading content={"Loading"} />}
      <MainCardWrapper>
        {packs?.map((item, index) => (
          <CardsWrapper key={item._id}>
            <Card
              onClick={() => {
                if (
                  location.pathname === "/starship/profile/packs" &&
                  item?.reveal_time &&
                  currentTime > 0 &&
                  currentTime >= +item?.reveal_time
                ) {
                  navigate(`/starship/pack-details/${item.transaction_hash}`);
                }
              }}
              style={{
                cursor:
                  location.pathname === "/starship/profile/packs"
                    ? "pointer"
                    : "default",
              }}
              onMouseEnter={() => setHoverIndex(index)}
              onMouseLeave={() => setHoverIndex(null)}
            >
              {location.pathname == "/starship/profile/packs" && (
                <div className="d-flex justify-content-end pr-2 pb-2">
                  <StatusDiv>
                    <h5 className="d-flex align-items-center justify-content-center">
                      <AiOutlineGlobal />
                      &nbsp;&nbsp;
                      {item?.reveal_time &&
                        currentTime > 0 &&
                        (currentTime <= +item?.reveal_time ? (
                          <>
                            <span style={{ fontSize: "10px" }}>Reveal In:</span>
                            &nbsp;&nbsp;
                            <Timer
                              duration={+item?.reveal_time - currentTime}
                            />
                          </>
                        ) : (
                          "Revealed"
                        ))}
                    </h5>
                  </StatusDiv>
                </div>
              )}
              {location.pathname !== "/starship/profile/packs" && (
                <div style={{ padding: "0rem 1rem", marginTop: "-3.3rem" }}>
                  <Dropdown
                    overlay={menu}
                    trigger={["click"]}
                    placement="bottomRight"
                  >
                    <a onClick={(e) => e.preventDefault()}>
                      <BiDotsHorizontalRounded
                        className={`three-dots ${
                          index === hoverIndex && "dots-hovered"
                        }`}
                        onClick={() => {
                          setSchemaId(
                            item?.pack_slots[index]?.template_id?.schema_id
                          );
                          setPack(item);
                        }}
                      />
                    </a>
                  </Dropdown>
                </div>
              )}
              <div className="img-wrapper">
                <Card.Img
                  src={item.image_url}
                  onError={(e) => (e.target.src = cardimg)}
                  width={203}
                  height={254}
                />

                {location.pathname === "/starship/profile/packs" &&
                  !item?.is_revealed && (
                    <div className="overlay">
                      {currentTime <= item?.reveal_time &&
                      !item?.is_revealed ? (
                        <h4 className="hover-text">Not revealed</h4>
                      ) : (
                        <h4 className="hover-text">
                          {item?.reveal_text
                            ? item.reveal_text
                            : "Reveal Starship Pack"}
                        </h4>
                      )}
                    </div>
                  )}
              </div>

              <Card.Body>
                <Card.Title>{item.name}</Card.Title>
              </Card.Body>
            </Card>
          </CardsWrapper>
        ))}
      </MainCardWrapper>
    </MainRow>
  );
}

export default PacksCard;
