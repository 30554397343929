import React, { useRef } from "react";
import plus from "assets/plus.png";
import { Button } from "antd";
import styled from "styled-components";

const UploadButton = styled(Button)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 8px;
  gap: 8px;

  width: 104px;
  height: 104px;

  /* Neutral / 2 */

  background: #fafafa;
  /* Gray 3 */

  border: 1px dashed #828282;
  border-radius: 2px;
`;

const UploadFile = ({ onSelectFile, title, accept }) => {
  const inputRef = useRef < HTMLInputElement > null;

  const triggerFileSelectPopup = (event) => {
    inputRef.current?.click();
  };

  return (
    <div className="upload-container">
      <input
        type="file"
        ref={inputRef}
        onChange={(e) => onSelectFile(e)}
        name=""
        id=""
        className="upload"
        style={{ visibility: "hidden" }}
        accept={accept}
      />
      <div className="div-btn-upload">
        <UploadButton onClick={(event) => triggerFileSelectPopup(event)}>
          <img
            width="15px"
            style={{ marginRight: "7px", marginBottom: "3px" }}
            src={plus}
          />
          {title}
        </UploadButton>
      </div>
    </div>
  );
};

export default UploadFile;
