import styled from "styled-components";

export const MainSection = styled.section`
  .container-fluid {
    padding: 0 7rem 0 5rem !important;

    @media (max-width: 767.98px) {
      padding-right: 2rem !important;
      padding-left: 2rem !important;
    }
    @media (max-width: 575.98px) {
      padding-right: 10px !important;
      padding-left: 10px !important;
    }
  }

  .modal-div {
    position: relative;
  }

  .navbar-light {
    border-bottom: 1px solid ${({ theme }) => theme.navBorder} !important;
    background-color: ${({ theme }) => theme.backgroundColor} !important;
    padding: 1rem 0rem;

    &.light-class {
      background-color: #fff !important;
      border-bottom: 1px solid rgba(107, 107, 107, 1) !important ;
    }
  }

  a {
    color: ${({ theme }) => theme.navLinks} !important;
    font-family: "Barlow", sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    margin-left: 1.5rem;
    text-decoration: none;

    @media (max-width: 991.98px) {
      text-align: center;
      margin-top: 10px;
    }

    &:hover {
      text-decoration: none;
    }
  }

  .navbar-light .navbar-nav .nav-link {
    color: #6b6b6b;
    font-family: "Barlow", sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    margin-left: 1.5rem;
    &:hover {
      text-decoration: none;
    }
  }

  .profile-div {
    display: flex;
    gap: 1rem;
    align-items: center;
    border-radius: 1.21875rem;
    border: 0.5px solid #00b7ff;
    cursor: pointer;

    @media (max-width: 991.98px) {
      margin-top: 1rem;
      width: 10rem;
      margin-left: auto;
      margin-right: auto;
    }

    .profile-img {
      cursor: pointer;
      width: 39px;
      height: 39px;
      object-fit: cover;
      border-radius: 50px;
    }

    p {
      color: #00b7ff;
      font-family: Barlow;
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.5rem; /* 150% */
      text-transform: capitalize;
      padding-bottom: 0rem;
      margin-right: 1rem;
    }
  }
`;

export const ConnectBtn = styled.button`
  color: #fff;
  font-family: "Barlow", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  border-radius: 1.75rem;
  background: var(--pimary, #00b7ff);
  width: 133px;
  height: 35px;

  @media (max-width: 991.98px) {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
  }
`;

export const ThemeHandler = styled.div`
  border: 1px solid #00b7ff;
  border-radius: 5px;
  width: 93px;
  height: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-right: 1rem;
  background-color: transparent;

  @media (max-width: 991.98px) {
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
  }

  &.mobileThemeHandler {
    width: 150px;
  }

  .themeIcons {
    cursor: pointer;
    transition: 0.3s all ease-in-out;
  }
`;

export const ClaimBtn = styled.button`
  color: #fff;
  font-family: Barlow;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  border-radius: 1.75rem;
  border: 1px solid #00b7ff;
  background: #00b7ff;
  width: 14.125rem;
  height: 2.1875rem;
  margin-right: 1rem;

  @media (max-width: 991.98px) {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
  }

  &:disabled {
    background: ${({ theme }) => theme.disabledBtnBG};
    border: 1px solid ${({ theme }) => theme.disabledBtnBorder} !important;
    color: rgb(183, 189, 202);
    /* border: 1px solid ${({ theme }) =>
      theme.disabledBtnBorder} !important; */
  }
`;
