import Card from "react-bootstrap/Card";
import {
  MainExploreCard,
  BottomContainer,
  Wrapper,
  PriceText,
  BuyNowBtn,
} from "./styled";
import woman from "../../assets/woman.png";
import profile from "../../assets/profileImg.jpg";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { BsThreeDots } from "react-icons/bs";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "store/store";
import { CommonUtility } from "utility";
import { paymentList } from "components/data/selectData";
import environment from "environment";
import { AddFavouriteHook, RemoveFavouriteHook } from "hooks";

function ExploreCard({ refetch, data }) {
  const navigate = useNavigate();

  //useStates
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [tokenIcon, setTokenIcon] = useState("");
  const [amount, setAmount] = useState(0);
  const [userInfo, setUserInfo] = useState({
    fav: null,
    isOwner: false,
  });

  //useAppSelectors
  const { account, chainId } = useAppSelector((state) => state.web3Connect);

  //custom hooks
  const { addFav } = AddFavouriteHook(setUserInfo, userInfo, refetch);
  const { removeFav, data: removeFavData } = RemoveFavouriteHook(
    setUserInfo,
    userInfo,
    refetch
  );

  //methods
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleAmount = async () => {
    let value = data?.price;
    const paymentTokens = paymentList(`${data?.network}`);
    const erc20Token = paymentTokens.find(
      (x) => x.value.toLocaleLowerCase() === data?.erc20Token
    );
    setTokenIcon(erc20Token.icon?.default);
    setAmount(CommonUtility.handleDivDecimals(value, erc20Token.decimals, 4));
  };

  function copyToClipboard(text) {
    navigator.clipboard.writeText(`${environment.FRONTEND_URL}${text}`).then(
      function () {
        handleClose();
        console.log("Text copied to clipboard");
      },
      function (err) {
        console.error("Could not copy text: ", err);
      }
    );
  }
  //useEffects
  useEffect(() => {
    if (account) {
      const fav = data?.favourites.find(
        (x) => x.user_address === account?.toLocaleLowerCase()
      );
      setUserInfo({
        fav,
        isOwner: account?.toLocaleLowerCase() === data?.owner ? true : false,
      });
    }
  }, [account, data?.favourites]);

  useEffect(() => {
    data && data?.price && handleAmount();
  }, [data]);

  return (
    <MainExploreCard>
      <Card>
        <Card.Img
          onClick={() =>
            userInfo.isOwner
              ? navigate(`/user-nft/${data?.nftId}?chain=${chainId}`)
              : !data?.sold
              ? navigate(`/nft-details?slug=${data?.sellNftId}`)
              : null
          }
          src={data?.image || woman}
          className="img-fluid cardImage"
        />
        {userInfo.fav ? (
          <AiFillHeart
            onClick={() => {
              if (account) {
                removeFav({
                  nft_id: data?.nftId,
                  userAddress: account,
                  id: userInfo.fav.id,
                });
              }
            }}
            cursor={account ? "pointer" : "not-allowed"}
            className="heartIcon"
            size={20}
          />
        ) : (
          <AiOutlineHeart
            onClick={() => {
              if (account) {
                addFav({
                  nft_id: data?.nftId,
                  userAddress: account,
                });
              }
            }}
            cursor={account ? "pointer" : "not-allowed"}
            className="heartIcon"
            size={20}
          />
        )}
        <Card.Body>
          <Card.Title>
            <img
              className="img-fluid mainImage"
              src={data?.userImage || profile}
            />
            {data?.userName || "Starship User"}
          </Card.Title>
          <Card.Text>
            {data?.name} #{data?.token_id}
          </Card.Text>
          <BottomContainer>
            <PriceText>
              <img
                src={tokenIcon || ""}
                width="20px"
                style={{ marginRight: "5px" }}
              />
              {amount || "0"}
            </PriceText>
            <Wrapper>
              <Button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <BsThreeDots fontSize={24} color="#fff" cursor="pointer" />
              </Button>

              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  style={{ background: "rgb(116, 40, 165)", color: "white" }}
                  disabled={true}
                >
                  Make Collection Offer
                </MenuItem>
                <MenuItem
                  style={{ background: "rgb(116, 40, 165)", color: "white" }}
                  onClick={() =>
                    copyToClipboard(
                      userInfo.isOwner
                        ? `/user-nft/${data?.nftId}?chain=${chainId}`
                        : !data?.sold
                        ? `/nft-details?slug=${data?.sellNftId}`
                        : "null"
                    )
                  }
                >
                  Copy Link
                </MenuItem>
                <MenuItem
                  style={{ background: "rgb(116, 40, 165)", color: "white" }}
                  onClick={handleClose}
                >
                  Refresh Metadata
                </MenuItem>
              </Menu>
              <BuyNowBtn
                onClick={() =>
                  userInfo.isOwner
                    ? navigate(`/user-nft/${data?.nftId}?chain=${chainId}`)
                    : !data?.sold
                    ? navigate(`/nft-details?slug=${data?.sellNftId}`)
                    : null
                }
              >
                {data?.user ? "Sell Now" : "Buy Now"}
              </BuyNowBtn>
            </Wrapper>
          </BottomContainer>
        </Card.Body>
      </Card>
    </MainExploreCard>
  );
}

export default ExploreCard;
