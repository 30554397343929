import styled from "styled-components";

export const MainWrapper = styled.div`
  overflow: hidden;

  .profile-modal-div {
    position: relative;
  }
`;

export const UserModalWrapper = styled.div`
  overflow: hidden;
  /* min-height: 288.63px; */
  width: 242px;
  position: absolute;
  top: 40px;
  right: 0px;
  z-index: 200;
  border: 1px solid ${({ theme }) => theme.selectdropdownborderColor} !important;
  background-color: ${({ theme }) => theme.selectdropdownbgColor} !important;
  /* background: #ffffff; */
  box-shadow: 0px 4.32405px 4.32405px rgba(0, 0, 0, 0.25);
  border-radius: 5.40506px;
  padding: 1rem 1rem;

  &.main-wrapper {
    /* background-color: #fff !important; */
    border: none !important;
  }

  @media (max-width: 991.98px) {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  @media (max-width: 575.98px) {
    width: 90%;
  }

  .closeIcon {
    display: block;
    margin-left: auto;
    /* color: #3d4044; */
    color: ${({ theme }) => theme.tablecloseiconColor} !important;
    cursor: pointer;
    transition: 0.3s ease-in-out all;

    &:hover {
      scale: 1.2;
      transition: 0.3s ease-in-out all;
    }
  }
`;

export const LogoutBtn = styled.button`
  color: #fff;
  font-family: "Barlow";
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  text-transform: capitalize;
  border-radius: 1.75rem;
  background: #00b7ff;
  padding: 5px 30px;
  width: 8.9375rem;
`;

export const InfoDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  h5 {
    color: var(--secondary, #3e3e3e);
    font-family: "Barlow";
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;
    text-transform: capitalize;
  }

  p {
    color: var(--secondary, #3e3e3e);
    font-family: "Barlow";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    text-transform: capitalize;
    margin-bottom: 0rem;
  }
`;

export const ViewProfileBtn = styled.button`
  color: #00b7ff;
  font-family: "Barlow";
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  border-radius: 1.75rem;
  border: 1px solid #00b7ff;
  padding: 10px 20px;
  background-color: transparent;
  width: 8.9375rem;
  &:hover {
    background: #00b7ff;
    color: #fff;
  }
`;

export const BtnWrapper = styled.div`
  margin-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: center;
  align-items: center;
`;
