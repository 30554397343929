import styled from "styled-components";
import { BiArrowBack } from "react-icons/bi";

export const ArrowIcon = styled(BiArrowBack)`
  border-radius: 0.9375rem;
  border: 1px solid ${({ theme }) => theme.backArrow};
  color: ${({ theme }) => theme.backArrow} !important;
  font-size: 24px;
  padding: 3px;
`;

export const Title = styled.h5`
  /* color: ${({ theme }) => theme.headerColor}; */
  color: var(--pimary, #00b7ff);
  font-family: "Barlow";
  font-size: 3.1875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;

  @media (max-width: 991.98px) {
    text-align: center;
  }
`;

export const ReadBtn = styled.button`
  color: var(--pimary, #00b7ff);
  font-family: "Barlow";
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  border-radius: 1.75rem;
  border: 1px solid var(--pimary, #00b7ff);

  width: 8.3125rem;
  height: 2.1875rem;
  background-color: transparent;
  margin-top: 0rem;
`;

export const ExploreBtn = styled.button`
  color: ${({ theme }) => theme.viewBtnText};
  font-family: "Barlow";
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background-color: #00b7ff;
  text-transform: capitalize;
  border-radius: 1.75rem;

  width: 8.3125rem;
  height: 2.1875rem;
  margin-top: 0rem;
`;

export const MainWrapper = styled.div`
  border-radius: 0.625rem;
  border: 1px solid #e7e7e7;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27);
  width: 12.3125rem;
  height: 7.4375rem;
  padding: 20px;

  h5 {
    color: ${({ theme }) => theme.cardText};
    font-family: "Barlow";
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
    text-transform: capitalize;
  }

  p {
    color: ${({ theme }) => theme.viewContainerText};
    font-family: "Barlow";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    margin-top: 1rem;
  }
`;

export const MainSection = styled.section`
  background-color: ${({ theme }) => theme.backgroundColor};
  overflow: hidden;

  .container-fluid {
    margin-top: 3rem;
    padding-bottom: 6rem;
    padding-right: 7rem;
    padding-left: 6rem;

    @media (max-width: 767.98px) {
      padding-right: 2rem;
      padding-left: 2rem;
    }
    @media (max-width: 575.98px) {
      padding-right: 10px;
      padding-left: 10px;
    }
  }

  .btn-div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2rem;
    margin-top: 2rem;

    @media (max-width: 991.98px) {
      justify-content: center;
    }
  }

  .card-icon {
    color: ${({ theme }) => theme.cardText};
  }
`;

export const ImgWrapper = styled.div`
  text-align: center;

  @media (max-width: 991.98px) {
    margin-top: 3rem;
  }
`;

export const LatestDropDiv = styled.div`
  margin-top: 2rem;
`;

export const ImgDiv = styled.div`
  padding-bottom: 0;
  position: relative;
  overflow: hidden;

  img {
    /* border: 1px solid ${({ theme }) => theme.cardimgBorder}; */
    /* object-fit: cover; */
    transition: transform 0.3s ease;
    border-radius: 0px !important;
  }
`;

export const CardWrapper = styled.div`
  position: relative;
  margin-top: 1rem;

  .arrow-wrappper {
    @media (max-width: 991.98px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .flex-div {
    @media (max-width: 575.98px) {
      display: flex;
      flex-direction: column;
      justify-content: center !important;
      align-items: center !important;
    }
  }

  &.top-card-wrapper {
    .top-div {
      padding: 0 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .icon {
        font-size: 18px;
        cursor: pointer;
        color: #6b6b6b;
        cursor: pointer;
        margin-top: 10px;
      }
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: left 0.3s ease;
      opacity: 0;
      /* border-radius: 10px; */
    }

    .card:hover img {
      transform: scale(1.1);
    }

    .card:hover .overlay {
      left: 0;
      opacity: 1;
    }

    .content-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 0.929rem;
      padding: 0rem 1rem;
    }

    .content-main-dev {
      min-height: 359px;
    }

    .card {
      .card-img,
      .card-img-bottom {
        height: 253px;
        width: 100%;
      }
    }
  }

  .card {
    width: 100%;

    /* width: 250px; */
    height: 392px;
    border-radius: 10px;
    box-shadow: 0px 0px 4.254847526550293px 0px rgba(0, 0, 0, 0.27);
    border: 1.064px solid ${({ theme }) => theme.cardBorder} !important;
    background-color: ${({ theme }) => theme.cardBG} !important;
    position: relative;

    @media (max-width: 575.98px) {
      min-height: 402px;
      height: auto;
      padding-bottom: 0rem;
    }
  }

  .card-img,
  .card-img-bottom {
    /* padding: 1rem;
    border-radius: 10px;
    border: 1px solid #e7e7e7;
    width: 200px; */
  }

  .card-title {
    color: ${({ theme }) => theme.cardText};
    font-family: "Barlow";
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;
    text-transform: capitalize;
    text-align: center;
    margin-top: 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 225px;
  }

  .card-text {
    color: var(--pimary, #00b7ff);
    font-family: "Barlow";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    text-align: center;
    margin-top: 0.5rem;
  }
  .ant-input {
    border: none;
  }

  .btn {
    border-radius: 0.625rem;
    border: 1px solid #e7e7e7;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27);
    width: 8.6875rem;
    height: 2rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .ant-input-focused,
  .ant-input:focus {
    box-shadow: none;
  }

  input[type="number"],
  input[type="password"],
  input[type="text"],
  textarea {
    text-align: center !important;
  }
`;

export const SoldItem = styled.div`
  margin-top: 0.5rem;
  color: #c9c9c9;
  font-family: "Barlow";
  font-size: 0.93075rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  text-align: center;
`;

export const StatusDiv = styled.div`
  margin-top: 0.5rem;
  border-radius: 0.9375rem;
  border: 1px solid ${({ theme }) => theme.cardText} !important;
  padding: 0.1rem 0.8rem;
  /* width: 7rem;
  height: 2rem; */
  h5 {
    color: ${({ theme }) => theme.cardText} !important;
    font-family: "Barlow";
    font-size: 0.78881rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
  }
`;

export const InfoDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;

  h5 {
    color: ${({ theme }) => theme.cardInnerContent};
    font-family: "Barlow";
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
  }

  p {
    color: #6b6b6b;
    font-family: "Barlow";
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
  }
`;

export const ClaimBtn = styled.button`
  color: ${({ theme }) => theme.viewBtnText};
  font-family: "Barlow";
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  border-radius: 1.75rem;
  border: 1px solid var(--pimary, #00b7ff);
  background-color: #00b7ff;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 8.6875rem;
  height: 1.875rem;
  margin-top: 1.3rem;
  height: 1.5375rem;
  margin-bottom: 0rem;
  &:disabled {
    background: ${({ theme }) => theme.disabledBtnBG};
    border: 1px solid ${({ theme }) => theme.disabledBtnBorder} !important;
    color: rgb(183, 189, 202);
  }
`;

export const CollectionDetailSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  padding-bottom: 2rem;
  .row {
    justify-content: center;
  }
`;

export const DropDiv = styled.div`
  /* transform: translateY(6rem); */
  margin-top: 3rem;
  color: ${({ theme }) => theme.backArrow};
  font-family: "Barlow";
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem;
  text-transform: capitalize;
  cursor: pointer;
`;

export const DetailDiv = styled.div`
  color: #6b6b6b;
  font-family: "Barlow";
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  padding-bottom: 0rem;
  max-height: 100px;
  overflow-y: auto;
`;

export const CardDetailWrapper = styled.div`
  margin-top: 2rem;

  .ant-divider {
    border-top: 0.5px solid ${({ theme }) => theme.cardDivider};
  }

  .card {
    width: 392px;
    border-radius: 10px;
    border: 1px solid ${({ theme }) => theme.cardBorder};
    box-shadow: 0px 0px 4.254847526550293px 0px rgba(0, 0, 0, 0.27);
    background-color: ${({ theme }) => theme.backgroundColor};
  }

  .card-img,
  .card-img-bottom {
    /* padding: 1rem;
    border-radius: 10px;
    border: 1px solid #e7e7e7;
    width: 200px; */
  }

  .card-title {
    color: ${({ theme }) => theme.cardText};
    font-family: "Barlow";
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;
    text-transform: capitalize;
    text-align: center;
    margin-top: -0.3rem;
  }

  .card-text {
    color: var(--pimary, #00b7ff);
    font-family: "Barlow";
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    text-align: center;
    margin-top: 0.5rem;
  }
  .ant-input {
    /* border: none; */
    width: 8.6875rem;
    height: 1.9375rem;
    border-radius: 1.75rem;
    background-color: transparent;
    color: ${({ theme }) => theme.inputtext};
  }

  .btn {
    border-radius: 0.625rem;
    border: 1px solid #e7e7e7;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27);
    width: 8.6875rem;
    height: 2rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .ant-input-focused,
  .ant-input:focus {
    box-shadow: none;
  }

  input[type="number"],
  input[type="password"],
  input[type="text"],
  textarea {
    text-align: center !important;
  }
`;

export const SwiperContainer = styled.div`
  margin-top: 2rem;
`;

export const DetailsWrapper = styled.div`
  background-color: ${({ theme }) => theme.backgroundColor};
  overflow: hidden;
`;

export const DividerStyle = {
  marginTop: "10px",
};

export const Key = styled.h4`
  color: ${({ theme }) => theme.cardInnerContent};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
`;
export const Value = styled.h2`
  color: ${({ theme }) => theme.cardInnerContentValue};
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;

  &.blue {
    color: rgba(0, 183, 255, 1);
  }
`;
