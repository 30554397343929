import { MainContainer } from "components/common";
import {
  MainFooter,
  LinksContainer,
  FooterLinks,
  SocialIconsContainer,
  InstagramIcon,
  FacebookIcon,
  GithubIcon,
  TelegramIcon,
  FooterLogoWrapper,
  BottomTextContainer,
  BottomWrapper,
  BottomLinks,
} from "./footerElement";
import footerlogo from "../../assets/starship-logo.png";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <MainFooter>
      <MainContainer>
        <LinksContainer>
          <Link to="/all-nfts">
            <FooterLinks>Explore</FooterLinks>
          </Link>
          <Link to="/collection-all">
            <FooterLinks>Collections</FooterLinks>
          </Link>
        </LinksContainer>
        <SocialIconsContainer>
          <InstagramIcon />
          <FacebookIcon />
          <GithubIcon />
          <TelegramIcon />
        </SocialIconsContainer>
        <FooterLogoWrapper>
          <img className="img-fluid" src={footerlogo} height={70} width={70} />
        </FooterLogoWrapper>
      </MainContainer>
      <BottomTextContainer>
        <BottomLinks>
          Copyright {new Date().getFullYear()} Startship | By NFT people, for
          NFT people
        </BottomLinks>
        <BottomWrapper>
          <BottomLinks>Terms Of Services</BottomLinks>
          <BottomLinks>Privacy Policy</BottomLinks>
        </BottomWrapper>
      </BottomTextContainer>
    </MainFooter>
  );
}

export default Footer;
