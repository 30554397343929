import { CreateNewNFTCom } from "components";

function CreateNewNFT() {
  return (
    <div>
      <CreateNewNFTCom />
    </div>
  );
}

export default CreateNewNFT;
