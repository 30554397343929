import styled from "styled-components";

export const ModalWrapper = styled.div`
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid #e7e7e7;
  /* background: #fff; */
  background-color: ${({ theme }) => theme.selectdropdownbgColor} !important;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27);
  padding: 1rem 2rem 2rem 2rem;
  position: relative;

  min-height: 460px;
  .close-icon {
    /* position: absolute;
    top: 5px;
    right: 10px; */
  }
  .main-div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 575.98px) {
      flex-direction: column;
    }
  }
  .first-div {
    display: flex;
    gap: 1.5rem;
    align-items: center;
    @media (max-width: 575.98px) {
      flex-direction: column;
      justify-content: center;
      gap: 5px;
    }
    h4 {
      /* color: var(--secondary, #3e3e3e); */
      color: ${({ theme }) => theme.noDataFoundColor} !important;
      font-family: Barlow;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: capitalize;
      @media (max-width: 575.98px) {
        text-align: center;
      }
      /* text-align: center; */
    }

    p {
      color: ${({ theme }) => theme.noDataFoundColor} !important;
      /* color: #6b6b6b; */
      font-family: Barlow;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;
      margin-top: 0.8rem;
      @media (max-width: 575.98px) {
        margin-top: 5px;
      }
    }
  }

  .second-div {
    text-align: end;
    h5 {
      color: #6b6b6b;
      font-family: Barlow;
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;

      @media (max-width: 575.98px) {
        margin-top: 5px;
      }
    }

    p {
      color: var(--secondary, #3e3e3e);
      font-family: Barlow;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: capitalize;
    }
  }

  .listing-text {
    color: #6b6b6b;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
  }

  .srt-text {
    color: #3e3e3e;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    text-align: right;
    margin-top: 5px;
    @media (max-width: 575.98px) {
      text-align: center;
    }
    small {
      color: #6b6b6b;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;
    }
  }
`;

export const HeaderTitle = styled.div`
  /* color: var(--secondary, #3e3e3e); */
  color: ${({ theme }) => theme.noDataFoundColor} !important;
  font-family: Barlow;
  font-size: 1.56644rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  /* margin-top: 1rem; */
`;

export const FormContainer = styled.div`
  margin-top: 1rem;
  border-bottom: 0.5px solid #e7e7e7;
  padding-bottom: 1.5rem;
  .form-label {
    /* color: var(--secondary, #3e3e3e); */
    color: ${({ theme }) => theme.noDataFoundColor} !important;
    font-family: Barlow;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    /* text-transform: capitalize; */
    padding-bottom: 0.6rem;
  }

  .form-control {
    border: none;
    padding: 0rem;
    background-color: transparent;
    color: ${({ theme }) => theme.inputtext} !important;
    font-size: 0.75rem;
    &:focus {
      box-shadow: none;
    }

    &::placeholder {
      color: #c9c9c9;
      font-family: Barlow;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;
    }
  }

  .form-text {
    /* color: var(--secondary, #3e3e3e); */
    color: ${({ theme }) => theme.noDataFoundColor} !important;
    font-family: Barlow;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    padding-bottom: 0.5rem;
  }

  .input-div {
    display: flex;
    border-radius: 0.625rem;
    border: 1px solid #e7e7e7;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27);
    align-items: center;
    padding: 0.1rem 0.7rem;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const LastDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  h5 {
    /* color: #6b6b6b; */
    color: ${({ theme }) => theme.noDataFoundColor} !important;
    font-family: Barlow;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
  }

  p {
    /* color: var(--secondary, #3e3e3e); */
    color: ${({ theme }) => theme.selectText} !important;
    font-family: Barlow;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
  }
`;

export const ListBtn = styled.button`
  border-radius: 1.75rem;
  border: 1px solid #00b7ff;
  background-color: #00b7ff;
  color: #fff;
  font-family: Barlow;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  width: 10.3125rem;
  height: 2.1875rem;
  margin-top: 1.5rem;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &:disabled {
    background: ${({ theme }) => theme.disabledBtnBG};
    border: 1px solid ${({ theme }) => theme.disabledBtnBorder} !important;
    color: rgb(183, 189, 202);
  }
`;
