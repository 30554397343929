// import MintNftsCom from "components/starship/mintNfts";
import MintNftsCom from "components/starship/mintNfts";
import React from "react";

function MintNfts() {
  return (
    <div>
      <MintNftsCom />
    </div>
  );
}

export default MintNfts;
