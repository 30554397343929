import styled from "styled-components";
import { Select } from "antd";
import { AiFillCaretDown } from "react-icons/ai";
import detailsbg from "../../assets/details-bg.png";

export const MainCollectionsElement = styled.div`
  background-image: url(${detailsbg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .container {
    .ant-tabs-bottom > .ant-tabs-nav:before,
    .ant-tabs-bottom > div > .ant-tabs-nav:before,
    .ant-tabs-top > .ant-tabs-nav:before,
    .ant-tabs-top > div > .ant-tabs-nav:before {
      border-bottom: none;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      font-family: "Figtree";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #1c1b1f;
      text-align: center;
      width: 150px;
      text-align: center;
      width: 150px;
    }

    .ant-tabs-tab {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 150px;
    }

    .ant-tabs-ink-bar {
      background: #261ce8;
    }

    .ant-tabs > .ant-tabs-nav,
    .ant-tabs > div > .ant-tabs-nav {
      @media (max-width: 1199.98px) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 20px;
      }
    }

    @media (max-width: 575.98px) {
      padding: 0px 15px;
    }

    .selectorDiv {
      /* .rs-picker-toggle-wrapper {
        height: 56px !important;
        display: flex;
        justify-content: center !important;
        align-items: center !important;
      }

      .rs-picker-default .rs-picker-toggle.rs-btn {
        background-color: #261ce8 !important;
        height: 100% !important;
        display: flex;
        justify-content: center !important;
        align-items: center !important;
      }

      .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-textbox {
        height: 100% !important;
        display: flex;
        justify-content: center !important;
        align-items: center !important;
      }
      .rs-picker-toggle-value {
        font-family: "Figtree";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.5px;
        color: #ffffff !important;
      }

      .rs-icon {
        color: #fff;
        transform: translateY(8px);

        &:nth-child(1) {
          display: none;
        }
      } */

      /* .form-select {
        background-color: #261ce8 !important;
        font-family: "Figtree";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.5px;
        color: #ffffff !important;
        cursor: pointer;
        box-shadow: none !important;
        outline: none !important;
        background-image: url(<AiFillCaretDown />);
      }

      .dropdown-toggle::after {
        color: #fff !important;
      } */

      .ant-select {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #261ce8 !important;
        border-radius: 4px;
        width: 200px !important;
        height: 56px !important;
      }
      .ant-select-selector {
        background-color: #261ce8 !important;
        font-family: "Figtree";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.5px;
        color: #ffffff !important;
      }

      .ant-select-arrow {
        color: #fff;
      }

      .ant-select-item {
        color: #fff !important;
      }

      .ant-select-item-option-content {
        color: #fff !important;
      }
    }
  }
`;

export const CollectionsHeading = styled.h2`
  font-family: "Anton";
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  line-height: 100%;
  text-transform: uppercase;
  color: #3e3e3e;
  margin: 3rem 0rem;

  @media (max-width: 575.98px) {
    text-align: center;
  }
`;

export const CollectionsTable = styled.div`
  margin-top: 40px;

  @media (max-width: 575.98px) {
    margin-top: 20px;
  }

  .tableImg {
    margin-left: 10px;
    margin-bottom: 5px;
    border-radius: 5px;
    width: 60px;
    height: 60px;
  }

  .css-11xur9t-MuiPaper-root-MuiTableContainer-root {
    box-shadow: none;
  }

  .css-1ygcj2i-MuiTableCell-root {
    border-bottom: 1px solid #f2f2f2;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    color: #636363;
  }

  .css-1azl6jz-MuiTableCell-root {
    text-align: center;
    border-bottom: 1px solid #f2f2f2;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    color: #636363;
  }

  .css-1ex1afd-MuiTableCell-root {
    border-bottom: 1px solid #f2f2f2;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    text-transform: capitalize;

    padding: 25px 0px;

    p {
      cursor: pointer;
      font-family: "Figtree";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
    }

    input {
      height: 21px;
      width: 24px;
      margin-right: 15px;
      transform: translateY(4px);
    }
  }

  .css-177gid-MuiTableCell-root {
    font-family: "Figtree";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #240749;
    text-align: center;
    border-bottom: 1px solid #f2f2f2;

    .img-fluid {
      margin-right: 5px;
    }

    span {
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 18px;
      color: #d03434;
      margin-left: 20px;
    }
  }
`;

export const CollectionsText = styled.h4`
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 48px;
  color: #1a1a1a;
  margin-top: 20px;

  @media (max-width: 575.98px) {
    text-align: center;
  }
`;

export const BtnContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0rem;
`;

export const HorizontalBreak = styled.hr`
  color: #f2f2f2;
`;

export const Selector = styled(Select)`
  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: linear-gradient(98.6deg, #261ce8 6.03%, #511ce8 99.87%);
    border-radius: 4px;
    font-family: "Figtree";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #ffffff;
    width: 200px;
  }

  .ant-select-arrow {
    color: #ffffff;
  }
`;
