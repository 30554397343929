import {
  useLazyQuery,
  useMutation,
  useQuery,
  useSubscription,
} from "@apollo/client";
import { ADD_VIEW } from "gql/mutations";
import { GET_VIEWS } from "gql/queries";
import { useEffect, useState } from "react";
import { useAppSelector } from "store/store";
import { BaseService } from "utility/services/base";

export const AddViewHook = () => {
  const [add_view, { loading }] = useMutation(ADD_VIEW);
  const { chainId, account } = useAppSelector((state) => state.web3Connect);

  const addView = async (id) => {
    const res = await BaseService.extenralAPICall(
      "https://api.ipify.org?format=json"
    );
    add_view({
      variables: {
        values: {
          nftId: id,
          ipAddress: res?.ip,
          networkId: chainId,
          walletAddress: account,
        },
      },
    });
  };

  return {
    addView,
    loading,
  };
};

export const GetViewsHook = () => {
  const [get_views, { data: viewsData, loading: viewsLoading }] =
    useLazyQuery(GET_VIEWS);
  const [views, setViews] = useState("");

  const getView = (id) => {
    get_views({ variables: { tokenId: id } });
  };

  useEffect(() => {
    viewsData && setViews(viewsData.get_views?.count);
  }, [viewsData]);

  return {
    views,
    viewsLoading,
    getView,
  };
};
