import React from "react";
import { PackCard } from "components/common/cards";
import { NoDataFoundComDiv } from "./element";
import NoDataFoundCom from "../noDataFound";

function Packs({ packs, getPacks }) {
  return (
    <div>
      {!packs || packs.length === 0 ? (
        <NoDataFoundComDiv style={{ marginTop: ".1rem" }}>
          <NoDataFoundCom message={"No Packs Available!"} />
        </NoDataFoundComDiv>
      ) : (
        <PackCard packs={packs} getPacks={getPacks} />
      )}
    </div>
  );
}

export default Packs;
