import { useMutation, useQuery } from "@apollo/client"
import { ToastMessage } from "components/common"
import { ADD_REPORT } from "gql/mutations"
import { GET_REPORTS } from "gql/queries"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"



export const GetReportsHook = () => {
    const { data, error, loading } = useQuery(GET_REPORTS)
    const navigate = useNavigate()

    useEffect(() => {
        error?.message === 'invalid token' && navigate('/admin-login')
    }, [error])

    return {
        data, error, loading
    }
}


export const AddReportHook = () => {
    const [add_report, { data, error, loading }] = useMutation(ADD_REPORT)

    const addReport = (values) => {
        add_report({ variables: { values } })
    }

    useEffect(() => {
        data && ToastMessage('Added', 'Report Sent', "success")
        error && ToastMessage("Error", "Unable to generate report", "error")
    }, [error, data])

    return {
        data, error, loading, addReport
    }
}