import react from "react"
import { Update } from "components"


const UpdateCredentials = () => {
    return (
        <Update />
    )
}

export default UpdateCredentials