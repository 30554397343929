import facebookimg from "../../../assets/starship/facebook.png";
import instaimg from "../../../assets/starship/insta.png";
import twitterimg from "../../../assets/starship/twitter.png";
import footerlogo from "../../../assets/starship/footerlogo.png";
import { Imgwrapper, LastDiv, MainSection, SocialWrapper } from "./elements";
import { Image } from "react-bootstrap";

const StarshipFooter = () => {
  return (
    <>
      <MainSection>
        <SocialWrapper>
          <Image src={facebookimg} width={29} height={29} />
          <Image src={instaimg} width={29} height={29} />
          <Image src={twitterimg} width={29} height={25} />
        </SocialWrapper>
        <Imgwrapper>
          <Image src={footerlogo} />
        </Imgwrapper>
      </MainSection>
      <LastDiv>Copyright ©2023 All rights reserved</LastDiv>
    </>
  );
};

export default StarshipFooter;
