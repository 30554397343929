import PlygonLogo from "assets/polygonlogo.svg";
import EthLogo from "assets/ethereum.svg";
import SVG from "react-inlinesvg";

import Icon from "@ant-design/icons";

export const PolygonIcon = (props) => (
  <Icon
    component={(iProps) => <SVG src={PlygonLogo} {...iProps} />}
    {...props}
  />
);

export const EthIcon = (props) => (
  <Icon component={(iProps) => <SVG src={EthLogo} {...iProps} />} {...props} />
);
