import React, { useEffect, useMemo, useState } from "react";
import {
  NftFormContainer,
  NftFormRow,
  NftFormCol,
  NftForms,
  NftFormTitle,
  RequiredLabel,
  NftUpload,
  NftUploadLabel,
  NftUploadInfo,
  AddBtn,
  PropertiesMain,
  PropertiesDetails,
  ErrorMessage,
  SecondRequiredLabel,
  SaveBtn,
} from "./nftFormElement";
import { BsFillCalendarRangeFill, BsImage } from "react-icons/bs";
import { nftValidate } from "components/validation/customValidator";
import { useAppDispatch, useAppSelector } from "store/store";
import {
  resetNftData,
  setAttributes,
  setNft,
  setSaveAttribute,
} from "store/redux/slices/nftSlices/nftDataSlice";
import { MainModel } from "components";
import sampleCsv from "data/sampleForm.csv";
// redux Slice
import {
  connectModelSlice,
  propertiesModelSlice,
} from "store/redux/slices/helperSlices/modelSlice";
import UploadFilee from "components/common/uploadField/uploadFile";
import { InputField, Select, TextArea, ToastMessage } from "components/common";
import {
  getCurrentTokenIdWeb3,
  MintNFTWeb3Hook,
} from "store/redux/slices/web3/nftMinting";
import { CommonUtility } from "utility";
import {
  CreateNftHook,
  useForm,
  switchNetwork,
  GetUsersCollectionHook,
} from "hooks";
import openNotification from "components/common/toastMessage/toastMessage";
import { NetworkIds } from "utility/constant/dropdowns";
import { sendFileToIPFS } from "utility/services/ipfsService";

const NftForm = () => {
  //declarations
  const dispatch = useAppDispatch();

  //useStates

  const [file, setFile] = useState(null);
  const [csvData, setCsvData] = useState([]);
  const [csvName, setCsvName] = useState("");

  const [imageUploading, setImageUploading] = useState(false);
  const [mintLoading, setMintLoading] = useState(false);
  const [csvImage, setCsvImage] = useState("");
  const [fileName, setFileName] = useState(false);

  //useAppSelectors
  const { nft, mintNftResult } = useAppSelector((state) => state.nftData);
  const {
    web3,
    account,
    nftMintingContract,
    nftMintingContractAddress,
    chainId,
  } = useAppSelector((state) => state.web3Connect);
  const { networkConfig } = useAppSelector((state) => state.config);

  //methods
  const openPropertiesModel = () => {
    dispatch(propertiesModelSlice(true));
  };
  const handleModel = () => {
    dispatch(connectModelSlice(true));
  };
  const submit = async () => {
    if (!switchNet) {
      setMintLoading(true);
      mint(setMintLoading, error);
    }
  };

  const dropDownCollections = (data) =>
    data?.map((x) => ({
      text: CommonUtility.toTitleCase(x.name),
      value: x.name,
    }));

  //custom hooks
  const { data, loading, error, create } = CreateNftHook();
  const { data: collections, loading: collectionsLoading } =
    GetUsersCollectionHook();
  const { mint, hash } = MintNFTWeb3Hook(nftMintingContract, account);
  const { handleSubmit, errors } = useForm(submit, nftValidate, nft);
  // use memo
  const switchNet = useMemo(
    () => nft?.networkId && chainId && chainId != nft?.networkId,
    [chainId, nft?.networkId]
  );
  //useEffects
  useEffect(() => {
    if (csvData.length > 0) {
      let isError;
      let mainError = false;

      csvData.forEach((item, index) => {
        let newValue = item.value;

        isError = newValue.length === 0 || newValue.trim().length === 0;

        if (isError) {
          mainError = true;
        }
      });

      if (mainError) {
        ToastMessage("CSV is Invalid", "", "error");
        setFileName(false);
        setCsvImage("");
        dispatch(resetNftData());
      } else {
        let tempNft = { ...nft };
        for (let i = 0; i < csvData.length; i++) {
          const obj = csvData[i];
          if (obj.key === "trait_type") {
            const properties = csvData.slice(i + 1, csvData.length);
            let temp = [];
            for (const property of properties) {
              temp = [
                ...temp,
                { trait_type: property.key, value: property.value },
              ];
              dispatch(
                setNft({ ...tempNft, attributes: JSON.stringify(temp) })
              );
              dispatch(setAttributes(temp));
            }
            return;
          } else if (obj.key === "networkId") {
            const value = NetworkIds.find(
              (x) =>
                x.text.toLocaleLowerCase() === obj.value.toLocaleLowerCase()
            );
            tempNft = { ...tempNft, networkId: value?.value };
            dispatch(setNft({ ...tempNft, networkId: value?.value }));
          } else if (obj.key === "collectionName") {
            const collection = collections.find(
              (x) =>
                x.name.toLocaleLowerCase() === obj.value.toLocaleLowerCase()
            );
            if (collection) {
              tempNft = { ...tempNft, collectionName: collection.name };
              dispatch(
                setNft({ ...tempNft, collectionName: collection.value })
              );
            }
          } else {
            tempNft = { ...tempNft, [obj.key]: obj.value };
            if (obj.key === "image") setCsvImage(obj.value);
            dispatch(setNft(tempNft));
          }
        }
      }
    }
  }, [csvData]);

  useEffect(() => {
    web3 && dispatch(setNft({ ...nft, ownerAddress: account }));
  }, [web3]);

  useEffect(() => {
    const offChainMint = async () => {
      const tokenId = await getCurrentTokenIdWeb3(nftMintingContract);
      const data = {
        name: nft.name,
        description: nft.description,
        image: nft.image,
        attributes: nft.attributes,
        externalLink: nft.externalLink,
        collectionName: nft.collectionName,
        collectionId: collections.find(
          (x) =>
            x.name.toLocaleLowerCase() ===
            nft.collectionName.toLocaleLowerCase()
        )?._id,
        ownerAddress: nft.ownerAddress,
        totalSupply: nft.totalSupply,
        nftId: CommonUtility.makeNftId(nftMintingContractAddress, +tokenId + 1),
        tokenId: `${+tokenId + 1}`,
        networkId: nft.networkId,
        transaction_hash: hash,
      };
      // info.file.originFileObj.name
      if (!csvImage) {
        setImageUploading(true);
        const url = await sendFileToIPFS(file?.fileList[0].originFileObj);
        setImageUploading(false);
        create({ ...data, image: url });
      } else {
        create(data);
      }
    };

    hash && offChainMint();
  }, [hash]);

  useEffect(() => {
    if (data) {
      dispatch(resetNftData());
      dispatch(setAttributes([{ trait_type: "", value: "" }]));
      dispatch(setSaveAttribute(false));
      csvImage && setCsvImage("");
    }
    error && openNotification("Error", error.message, "error");
  }, [data, error]);

  const [selectedFile, setSelectedFile] = useState(null);

  const preview = useMemo(
    () => CommonUtility.imagePreview(selectedFile),
    [selectedFile]
  );
  return (
    <>
      <NftFormContainer>
        {/* connect Model */}
        {/* {mintLoading ? <Loader content="Minting..." /> : ""} */}
        <MainModel />
        {web3 ? (
          <NftFormRow className="d-flex justify-content-center">
            <NftFormCol lg={11}>
              <NftFormTitle>Create New Item</NftFormTitle>
              <RequiredLabel>
                Upload CSV to auto fill the form ?{" "}
                <a href={sampleCsv} download>
                  Download Sample Form
                </a>
              </RequiredLabel>
              <UploadFilee
                title="Upload CSV"
                onSelectFile={async (e) => {
                  setCsvName(e.target.files[0].name);
                  CommonUtility.csvToJson(e, setCsvData);
                  e.target.value = null;
                }}
                accept=".csv"
              />
              <p style={{ color: "black" }}>{csvName}</p>
              <br />
              <SecondRequiredLabel>
                {" "}
                <span>*</span>Required Fields
              </SecondRequiredLabel>
              <NftForms>
                <NftUploadLabel>
                  Image, Video, Audio, or 3D Model <span>*</span>
                </NftUploadLabel>
                <NftUploadInfo>
                  File types supported: JPG, PNG, GIF, SVG, MP4, WEBM, MP3, WAV,
                  OGG, GLB, GLTF. Max size: 10 MB
                </NftUploadInfo>

                {csvImage ? (
                  <img src={csvImage} style={{ width: "150px" }} />
                ) : (
                  <NftUpload
                    name="avatar"
                    listType="picture-card"
                    showUploadList={false}
                    className="avatar-uploader"
                    onChange={(e) => {
                      let fileList = e.fileList.slice(-1);

                      setSelectedFile(fileList[0].originFileObj);
                      setCsvImage("");
                      if (e.fileList.length > 0) {
                        dispatch(setNft({ ...nft, image: "save" }));
                        setFile(e);
                      } else {
                        dispatch(setNft({ ...nft, image: "" }));
                        setFile(null);
                      }
                    }}
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  >
                    {preview ? (
                      <img src={preview} style={{ width: "150px" }} />
                    ) : (
                      <BsImage />
                    )}
                  </NftUpload>
                )}

                {/* {csvImage ? <img src={csvImage} style={{ width: "25%" }} /> : ""} */}

                {errors.image && <ErrorMessage>{errors.image}</ErrorMessage>}
                <InputField
                  inputLabel="Name"
                  inputRequired={true}
                  error={errors.name}
                  value={nft.name}
                  setValue={(e) =>
                    dispatch(setNft({ ...nft, name: e.target.value }))
                  }
                  inputType="text"
                  maxLength={20}
                />
                <InputField
                  inputLabel="External Link"
                  inputLabelText=" Nethty will include a link to  URL on this items."
                  error={errors.externalLink}
                  value={nft.externalLink}
                  setValue={(e) =>
                    dispatch(setNft({ ...nft, externalLink: e.target.value }))
                  }
                  inputType="text"
                />
                <TextArea
                  inputLabel="Description"
                  inputLabelText=" The description will be included on the items detail page."
                  error={errors.description}
                  value={nft.description}
                  setValue={(e) =>
                    dispatch(setNft({ ...nft, description: e.target.value }))
                  }
                  maxLength={200}
                />
                <Select
                  inputLabel="Collection"
                  inputLabelText=" This is the collection where your item will appear."
                  placeholder="Select collection"
                  setValue={(e) => {
                    dispatch(setNft({ ...nft, collectionName: e }));
                  }}
                  error={errors.collectionName}
                  value={nft.collectionName}
                  dropDownList={
                    !collections
                      ? [{ text: "loading...", value: "Loading" }]
                      : dropDownCollections(collections)
                  }
                />

                <NftFormRow>
                  <NftFormCol lg={6} sm={6} xs={10} md={6}>
                    <PropertiesMain>
                      <BsFillCalendarRangeFill
                        style={{ marginTop: "0.3rem" }}
                      />
                      <PropertiesDetails>
                        Properties <br />
                        <span>Textual traits that show up as rectangles</span>
                      </PropertiesDetails>
                    </PropertiesMain>{" "}
                  </NftFormCol>
                  <NftFormCol lg={6} sm={6} xs={2} md={6}>
                    <div className="text-end">
                      <AddBtn
                        type="button"
                        onClick={openPropertiesModel}
                        disabled={!web3}
                      >
                        +
                      </AddBtn>
                    </div>
                  </NftFormCol>
                  {errors.attributes && (
                    <ErrorMessage>{errors.attributes}</ErrorMessage>
                  )}
                </NftFormRow>

                {/* Supply */}
                <InputField
                  inputLabel="Supply"
                  error={errors.totalSupply}
                  value={1}
                  setValue={(e) =>
                    dispatch(setNft({ ...nft, totalSupply: e.target.value }))
                  }
                  inputType="text"
                  disabled
                />

                <Select
                  inputLabel="Select Network"
                  placeholder="Select Network"
                  setValue={(e) => {
                    dispatch(setNft({ ...nft, networkId: e }));
                  }}
                  error={errors.networkId}
                  value={nft.networkId}
                  dropDownList={NetworkIds}
                />
                {web3 ? (
                  <SaveBtn
                    disabled={
                      imageUploading || loading || mintLoading ? true : false
                    }
                    onClick={
                      switchNet
                        ? () => switchNetwork(web3, nft.networkId)
                        : handleSubmit
                    }
                  >
                    {switchNet
                      ? "Switch Network"
                      : imageUploading
                      ? "uploading image..."
                      : loading || mintLoading
                      ? "Submitting..."
                      : "Submit"}
                  </SaveBtn>
                ) : (
                  <SaveBtn onClick={handleModel}>Connect Wallet</SaveBtn>
                )}
              </NftForms>
            </NftFormCol>
          </NftFormRow>
        ) : (
          <div className="d-flex justify-content-center">
            <SaveBtn onClick={handleModel}>Connect Wallet</SaveBtn>
          </div>
        )}
      </NftFormContainer>
    </>
  );
};

export default NftForm;
