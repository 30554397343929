import styled from "styled-components";

export const ModalWrapper = styled.div`
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid #e7e7e7;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27);
  padding: 1rem 2rem 2rem 2rem;
  min-height: 166px;
  position: relative;

  .close-icon {
    position: absolute;
    top: 5px;
    right: 10px;
  }
`;

export const ViewNftsBtn = styled.button`
  min-width: 143px;
  min-height: 29px;
  padding: 1px 6.553px;
  border-radius: 28px;
  background: var(--pimary, #00b7ff);
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  display: block;
  margin: 1.5rem auto 0 auto;
`;

export const MainHeader = styled.h2`
  color: #3e3e3e;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-transform: capitalize;
  text-align: center;
  margin-top: 2rem;

  .check-icon {
    margin-right: 0.5rem;
    font-size: 24px;
  }
`;
