import DropsCom from "components/starship/drops";

function Drops() {
  return (
    <div>
      <DropsCom />
    </div>
  );
}

export default Drops;
