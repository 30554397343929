import { HeaderTitle, ModalWrapper } from "./elements";
import { MdClose } from "react-icons/md";
import { TableDiv } from "../viewOffersModal/element";
import { Table } from "antd";
import { Image } from "react-bootstrap";
import { nodata } from "assets";
import { useAppSelector } from "store/store";
import { ContractUtility } from "utility";

const ViewOpenerModal = ({ handleCancel, openers }) => {
  const { chainId } = useAppSelector((state) => state.web3Connect);

  const columns = [
    {
      title: "Sr#",
      dataIndex: "key",
      key: "sr",
      width: "25%",
    },

    {
      title: "Drop Amount",
      dataIndex: "drop_amounts",
      key: "drop_amounts",
      width: "25%",
    },
    {
      title: "Wallet Address",
      dataIndex: "wallet_address",
      key: "wallet_address",
      width: "50%",
      render: (text) => (
        <a
          href={`${ContractUtility.getNetworkExplorer(
            chainId
          )}/address/${text}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {text}
        </a>
      ),
    },
  ];

  let locale = {
    emptyText: (
      <span>
        <Image src={nodata} />
        <p
          style={{
            color: "#3E3E3E",
            marginTop: "0.5rem",
            marginLeft: "1.5rem",
          }}
          className="offers-text"
        >
          No openers Yet!
        </p>
      </span>
    ),
  };

  return (
    <ModalWrapper>
      <div className="d-flex justify-content-between align-items-center">
        <HeaderTitle>Drop openers</HeaderTitle>
        <MdClose
          cursor="pointer"
          fontSize={22}
          color="#6B6B6B"
          className="close-icon"
          onClick={handleCancel}
        />
      </div>

      <TableDiv>
        <Table
          dataSource={openers.map((opener, index) => ({
            ...opener,
            key: index + 1,
          }))}
          columns={columns}
          locale={locale}
        />
      </TableDiv>
    </ModalWrapper>
  );
};

export default ViewOpenerModal;
