import { Card } from "react-bootstrap";
import styled from "styled-components";

export const CardMainDiv = styled.div`
  text-align: center;
`;
export const MainCardText = styled(Card.Text)`
  color: white;
`;
export const MainCardDesc = styled(Card.Text)`
  color: white;
`;
