import { ErrorMessage } from "components/nftForm/nftFormElement";
import React from "react";
import { MainTextArea, NftForms } from "./inputElement";

const TextArea = ({
  inputLabel,
  inputLabelText,
  inputRequired,
  placeholder,
  value,
  setValue,
  error,
  maxLength,
}) => {
  const maxLengthCheck = (object) => {
    if (maxLength && object.target.value.length > maxLength) {
      let value = object.target.value.slice(0, maxLength);
      setValue(value);
    }
  };

  return (
    <MainTextArea>
      <NftForms.Group>
        <NftForms.Label>
          {" "}
          {inputLabel}
          {inputRequired ? (
            <span
              style={{ color: "red", marginLeft: "0.2rem", marginTop: "5rem" }}
            >
              *
            </span>
          ) : (
            ""
          )}
        </NftForms.Label>
        <label
          style={{
            float: "right",
            fontSize: "11px",
            marginTop: "1.5rem",
            color: "white",
          }}
          className="label"
        >
          {value && maxLength ? `${value.length} / ${maxLength}` : ""}
        </label>
        <br />
        <NftForms.Text className="text-muted">{inputLabelText}</NftForms.Text>
        <NftForms.Control
          style={{ marginTop: "1rem" }}
          as="textarea"
          value={value}
          onChange={(e) => {
            setValue(e);
            maxLengthCheck(e);
          }}
          name="description"
          placeholder={placeholder}
        />
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </NftForms.Group>
    </MainTextArea>
  );
};

export default TextArea;
