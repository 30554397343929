import {
  CardWrapper,
  ImgDiv,
  SoldItem,
  StatusDiv,
  Key,
  Value,
} from "components/starship/viewCollection/elements";
import Timer from "components/starship/viewCollection/timer";
import { GetDropMintsHook } from "hooks/dropHook";
import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { AiOutlineGlobal, AiOutlineInfoCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "store/store";
import { CommonUtility, ContractUtility, DateUtility } from "utility";

const DropCard = ({ item }) => {
  console.log("ITEM", item);
  const [currentTime, setCurrentTime] = useState(Number);
  const { chainId } = useAppSelector((state) => state.web3Connect);
  const { config } = useAppSelector((state) => state.configData);
  const [isCardContentVisible, setIsCardContentVisible] = useState(false);
  const { data: dropMints } = GetDropMintsHook();

  const navigate = useNavigate();
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(Math.floor(DateUtility.currentTime()));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <CardWrapper className="top-card-wrapper">
      <Card
        className={
          currentTime <= item?.start_time ||
          currentTime >= item?.end_time ||
          +item?.currentSupply >= +item?.max_supply
            ? "hover-card"
            : ""
        }
      >
        <div className="top-div">
          <AiOutlineInfoCircle
            className="icon"
            onClick={() => setIsCardContentVisible(!isCardContentVisible)}
          />

          <StatusDiv>
            <h5 className="d-flex align-items-center justify-content-center">
              <AiOutlineGlobal />
              &nbsp;&nbsp;
              {item?.start_time &&
                currentTime > 0 &&
                (currentTime <= item?.start_time ? (
                  <>
                    <span style={{ fontSize: "10px" }}>Live In:</span>{" "}
                    &nbsp;&nbsp;
                    <Timer duration={+item?.start_time - currentTime} />
                  </>
                ) : currentTime >= item?.end_time ||
                  +item?.currentSupply >= +item?.max_supply ? (
                  "Ended"
                ) : (
                  <>
                    <span style={{ fontSize: "10px" }}>Ends In:</span>{" "}
                    &nbsp;&nbsp;
                    <Timer duration={+item?.end_time - currentTime} />
                  </>
                ))}
            </h5>
          </StatusDiv>
        </div>

        {isCardContentVisible ? (
          <>
            <div className="content-main-dev">
              <div className="content-wrapper">
                <Key>Title:</Key>
                <Value>{item?.name}</Value>
              </div>

              <div className="content-wrapper">
                <Key>Pack Name:</Key>
                <Value>{item?.pack_id?.name}</Value>
              </div>

              {/* <div className="content-wrapper">
                <Key>Is Secure:</Key>
                <Value>{item?.is_secure ? "Yes" : "No"}</Value>
              </div>
              <div className="content-wrapper">
                <Key>Is hidden:</Key>
                <Value>{item?.is_hidden ? "Yes" : "No"}</Value>
              </div> */}
              <div className="content-wrapper">
                <Key>Drop id:</Key>
                <Value>{item?.drop_id}</Value>
              </div>
              <div className="content-wrapper">
                <Key>Price:</Key>
                <Value>
                  {item?.price > 0
                    ? `${CommonUtility.convertFromWei(
                        +item?.price,
                        item?.payment_token_decimals,
                        false
                      )} ${
                        ContractUtility.getPaymentToken(
                          item?.payment_token,
                          item?.chain_id
                        )?.symbol
                      }`
                    : "Free"}
                </Value>
              </div>
              <div className="content-wrapper">
                <Key>Issued:</Key>
                <Value>
                  {" "}
                  {item?.currentSupply} /
                  {+item.max_supply == +config?.UINT256_MAX ||
                  +item.max_supply == 0
                    ? "∞"
                    : item.max_supply}
                </Value>
              </div>
              <div className="content-wrapper">
                <Key>Purchasable Nft (upto):</Key>
                <Value>{item?.nfts_amount}</Value>
              </div>
            </div>
          </>
        ) : (
          <div>
            <ImgDiv className="mt-2">
              <Card.Img
                src={item.image}
                width={"100%"}
                height={253}
                style={{
                  cursor: "pointer",
                  backgroundColor:
                    currentTime <= item?.start_time ||
                    currentTime >= item?.end_time ||
                    +item?.currentSupply >= +item?.max_supply
                      ? ""
                      : "initial",
                }}
                onClick={() => {
                  currentTime > item?.start_time &&
                    currentTime < item?.end_time &&
                    +item?.currentSupply < +item?.max_supply &&
                    navigate("/starship/view-collection/detail", {
                      state: item,
                    });
                }}
              />

              <div
                className={
                  currentTime <= item?.start_time ||
                  currentTime >= item?.end_time ||
                  +item?.currentSupply >= +item?.max_supply
                    ? "overlay"
                    : ""
                }
              >
                {currentTime <= item?.start_time ? (
                  <h4 className="hover-text">Upcoming</h4>
                ) : (
                  (currentTime >= item?.end_time ||
                    +item?.currentSupply >= +item?.max_supply) && (
                    <h4 className="hover-text">Ended</h4>
                  )
                )}
              </div>
            </ImgDiv>

            <Card.Body>
              <div>
                <Card.Title>{item.name}</Card.Title>
              </div>
              <div
                className={`d-flex justify-content-between align-items-center mt-3 flex-div`}
              >
                <Card.Text>
                  {+item?.price > 0
                    ? CommonUtility.convertFromWei(
                        item?.price,
                        item?.payment_token_decimals,
                        false
                      ) +
                      " " +
                      ContractUtility.getPaymentToken(
                        item.payment_token,
                        chainId
                      )?.symbol
                    : "Free"}
                </Card.Text>

                <SoldItem>
                  {item?.currentSupply +
                    "/" +
                    (+item.max_supply === +config?.UINT256_MAX
                      ? "∞"
                      : item.max_supply) +
                    " sold"}
                </SoldItem>
              </div>
            </Card.Body>
          </div>
        )}
      </Card>
    </CardWrapper>
  );
};

export default DropCard;
