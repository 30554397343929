import { createSlice } from "@reduxjs/toolkit";


export const collectionDataSlice = createSlice({
    name: "collection data slice",
    initialState: {
        collectionData: {
            name: "",
            logo: "",
            collectionAddress: "null",
            featuredImage: "",
            description: "",
            category: "",
            paymentToken: "",
            network: "",
            status: false,
            bannerImage: "",
            ownerAddress:""
        },
        collection: [],
        links: new Array(5)
    },
    reducers: {
        setCollectionData: (state, action) => {

            return {
                ...state,
                collectionData: { ...action.payload }
            }
        },

        setCollection: (state, action) => {
            return {
                ...state,
                collection: { ...action.payload }
            }
        },
        setLinks: (state, action) => {
            return void {
                ...state,
                links: state.links.splice(action.payload.index, 1, action.payload.value)
            }
        },
        resetCollectionData: (state) => {
            return {
                ...state,
                collectionData: {
                    ...state.collectionData,
                    name: "",
                    logo: "",
                    collectionAddress: "null",
                    featuredImage: "",
                    links: new Array(5),
                    description: "",
                    category: "",
                    paymentToken: "",
                    network: "",
                    status: false,
                    bannerImage: ""
                }
            }
        }
    }
});

export const { setCollection, setCollectionData, setLinks, resetCollectionData } = collectionDataSlice.actions;
export const collectionDataReducer = collectionDataSlice.reducer;
