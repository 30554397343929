import React from "react";
import ReactDOM from "react-dom";
import App from "./app";
import store from "./store/store";
import { Provider } from "react-redux";
import "antd/dist/antd.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "rsuite/dist/rsuite.min.css";
// import "swiper/swiper-bundle.min.css";
// import "swiper/swiper.min.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { ThirdwebProvider } from "@thirdweb-dev/react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  concat,
  ApolloProvider,
  createHttpLink,
} from "@apollo/client";

import "rsuite/dist/rsuite.min.css";
import environment from "environment";

const httpLink = createHttpLink({
  uri: environment.BACKEND_GRAPHQL_URL,
});

// const splitLink = split(
//   ({ query }) => {
//     const definition = getMainDefinition(query);
//     return (
//       definition.kind === "OperationDefinition" &&
//       definition.operation === "subscription"
//     );
//   },
//   wsLink,
//   httpLink
// );

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: `Bearer ${localStorage.getItem("access_token")}` || null,
    },
  }));
  return forward(operation);
});

const client = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <React.StrictMode>
    <ThirdwebProvider
      clientId="f3559efcdb36ccf887a8d96ceed8e316" // You can get a client id from dashboard settings
      activeChain="goerli"
    >
      <ApolloProvider client={client}>
        <Provider store={store}>
          <App />
        </Provider>
      </ApolloProvider>
    </ThirdwebProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
