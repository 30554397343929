import styled from "styled-components";

export const ModalWrapper = styled.div`
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid #e7e7e7;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27);
  padding: 3rem 3rem 3rem 3rem;
  min-height: 303px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 575.98px) {
    min-height: 100px;
  }

  .close-icon {
    position: absolute;
    top: 5px;
    right: 10px;
  }

  .error {
    color: #f65d5d;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
  }
`;

export const MainHeader = styled.h2`
  color: #3e3e3e;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-transform: capitalize;
  margin-top: 2rem;
`;

export const BtnDiv = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 2rem;

  @media (max-width: 991.98px) {
    justify-content: center;
  }
`;

export const CancelBtn = styled.button`
  background-color: transparent;
  border: none;
  color: #3e3e3e;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
`;

export const SelectBtn = styled.button`
  min-width: 143px;
  min-height: 29px;
  padding: 1px 6.553px;
  border-radius: 28px;
  background: #4d1ce8;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
`;

export const BorderDiv = styled.div`
  padding: 1rem;
  border: 2px solid #ccc;
  border-radius: 5px;
`;
