import { TableDiv } from "./element";
import { ExploreCard } from "components/common/cards";
import { useAppSelector } from "store/store";
import { GetNftFavoritesHook } from "hooks/favoriteHook";
import { useEffect, useState } from "react";
import { NoDataFoundComDiv } from "../explore/element";
import NoDataFoundCom from "../noDataFound";
import { MainCol, MainRow } from "components/common";
import Skeleton from "react-loading-skeleton";

function ActiveListings() {
  const { account } = useAppSelector((state) => state.web3Connect);
  const [page, setPage] = useState(30);
  const {
    data: favorites,
    loading,
    getNftFavorites,
    total,
  } = GetNftFavoritesHook(account, page);
  const handleScroll = () => {
    const scrollPosition = window.innerHeight + window.pageYOffset;
    const scrollHeight = document.documentElement.scrollHeight;
    if (scrollPosition >= scrollHeight - 180 && !loading) {
      if (page < total) {
        setPage(page + 10);
      }
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [favorites, page, total, loading]);

  const [cardsLoading, setCardsLoading] = useState(false);
  console.log(favorites, "favorites");
  const { theme } = useAppSelector((state) => state.model);
  return (
    <TableDiv>
      {/* {(loading || cardsLoading) && <Loading content={"Loading"} />} */}

      {(loading || cardsLoading) && (
        <>
          <MainRow>
            {Array.from({ length: 6 }).map((_, index) => (
              <MainCol xs={6} lg={2}>
                <Skeleton
                  width="100%"
                  height="490px"
                  borderRadius="12px"
                  baseColor={theme === "light" ? "#ebebeb" : "transparent"}
                  style={{
                    border:
                      theme === "light"
                        ? "1px solid #ebebeb"
                        : "1px solid #727989",
                  }}
                />
              </MainCol>
            ))}
          </MainRow>
        </>
      )}

      <div className="card-wrapper mt-3">
        {favorites?.nft_ids?.length > 0 &&
          favorites.nft_ids.map((x, index) => (
            <ExploreCard
              item={x}
              getNftFavorites={getNftFavorites}
              setLoading={setCardsLoading}
            />
          ))}
      </div>

      {!loading &&
        (!favorites ||
          !favorites?.nft_ids ||
          favorites?.nft_ids?.length == 0) && (
          <NoDataFoundComDiv style={{ marginTop: ".1rem" }}>
            <NoDataFoundCom message={"no favourites NFTs"} />
          </NoDataFoundComDiv>
        )}
    </TableDiv>
  );
}

export default ActiveListings;
