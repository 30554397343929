import styled from "styled-components";

export const MintNftsWrapper = styled.div`
  overflow: hidden;

  .container-fluid {
    margin-top: 3rem;
    margin-bottom: 4rem;
  }

  .select-row {
    @media (max-width: 575.98px) {
      gap: 2rem;
    }
  }
`;

export const MainHeader = styled.h2`
  color: #3e3e3e;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-transform: capitalize;
  margin-top: 2rem;

  &.no-margin {
    margin: 0;

    @media (max-width: 991.98px) {
      margin-top: 2rem;
    }
  }
`;

export const SelectDiv = styled.div`
  margin-top: 2rem;

  @media (max-width: 991.98px) {
    margin-top: 2rem;
  }

  &.no-margin {
    margin-top: 1rem;
  }

  .form-control {
    border-radius: 10px;
    border: 1px solid #e7e7e7;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27);
    min-height: 47px;

    &:focus {
      outline: none;
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27);
    }
  }
`;

export const InfoText = styled.p`
  color: #6b6b6b;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  margin-top: 10px;
`;

export const ContentComWrapper = styled.div`
  margin-top: 4rem;

  @media (max-width: 991.98px) {
    margin-top: 0rem;
  }
`;

export const SubText = styled.p`
  color: #6b6b6b;
  font-family: Barlow;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 135.5%; /* 1.355rem */
  text-transform: capitalize;
  width: 85%;
  margin-top: 0rem;

  span {
    font-weight: 500;
  }

  @media (max-width: 991.98px) {
    width: 100%;
  }
`;

export const ButtonsDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 2rem;
  margin-top: 2rem;

  @media (max-width: 991.98px) {
    justify-content: center;
  }
`;

export const CancelBtn = styled.button`
  background-color: transparent;
  border: none;
  color: #3e3e3e;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
`;

export const MintNftsBtn = styled.button`
  min-width: 143px;
  min-height: 29px;
  padding: 1px 6.553px;
  border-radius: 28px;
  background: var(--pimary, #00b7ff);
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;

  &:disabled {
    background: ${({ theme }) => theme.disabledBtnBG};
    border: 1px solid ${({ theme }) => theme.disabledBtnBorder} !important;
    color: rgb(183, 189, 202);
  }
`;
