import styled from "styled-components";

export const ModalWrapper = styled.div`
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid #00b7ff;
  /* background: #fff; */
  background-color: ${({ theme }) => theme.selectdropdownbgColor} !important;
  padding: 1rem;
  padding-bottom: 2rem;
  width: 431px;
  .listing-price-text {
    /* color: #6b6b6b; */
    color: ${({ theme }) => theme.noDataFoundColor} !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    margin: 0;
    text-align: end;
  }

  .srt-text {
    /* color: #3e3e3e; */
    color: ${({ theme }) => theme.noDataFoundColor} !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    text-align: end;
  }

  .top-row {
    padding-bottom: 0rem;
    border-bottom: 1px solid #e7e7e7;
  }

  .form-control {
    border-radius: 10px;
    border: 1px solid #e7e7e7;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    background-color: transparent !important;
    /* color: #3e3e3e; */
    color: ${({ theme }) => theme.inputtext} !important;
    &::placeholder {
      color: #c9c9c9;
    }
  }

  .input-group-text {
    border-radius: 10px;
    border: 1px solid #e7e7e7;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27);
    background-color: transparent;
    /* color: #3e3e3e; */
    color: ${({ theme }) => theme.noDataFoundColor} !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    padding-left: 15px;
    padding-right: 15px;
  }

  .icon {
    color: ${({ theme }) => theme.tablecloseiconColor} !important;
  }
`;

export const Title = styled.h2`
  /* color: #3e3e3e; */
  color: ${({ theme }) => theme.noDataFoundColor} !important;
  font-size: 25.063px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
`;

export const TopLeftDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;

  .text-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.5rem;

    .upper-text {
      /* color: #3e3e3e; */
      color: ${({ theme }) => theme.selectText} !important;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: capitalize;
    }

    .bottom-text {
      /* color: #6b6b6b; */
      color: ${({ theme }) => theme.selectText} !important;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;
    }
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.border-bottom {
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #e7e7e7;
  }
`;

export const KeyText = styled.h4`
  /* color: #6b6b6b; */
  color: ${({ theme }) => theme.selectText} !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
`;

export const ValueText = styled.p`
  /* color: #3e3e3e; */
  color: ${({ theme }) => theme.noDataFoundColor} !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
`;

export const DurationDiv = styled.div`
  .duration-text {
    /* color: #3e3e3e; */
    color: ${({ theme }) => theme.noDataFoundColor} !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
  }

  .content-div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    margin-top: 0.5rem;

    .select-div {
      flex: 1;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background-color: transparent;
      color: green !important;
      border-radius: 10px !important;
      border: 1px solid #e7e7e7 !important;
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27) !important;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      color: #6b6b6b !important;
    }

    .ant-select-arrow {
      color: #6b6b6b !important;
    }
  }

  .input-group {
    flex: 3;
  }

  .ant-picker {
    background-color: transparent;
    color: green !important;
    border-radius: 10px !important;
    border: 1px solid #e7e7e7 !important;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27) !important;
  }

  .ant-picker-dropdown .ant-picker-panel-container {
    background-color: ${({ theme }) => theme.datepickerbgColor} !important;
  }

  .ant-picker-dropdown .ant-picker-cell-in-view {
    color: ${({ theme }) => theme.inputtext};
  }
  .ant-picker-dropdown
    .ant-picker-time-panel-column
    > li.ant-picker-time-panel-cell
    .ant-picker-time-panel-cell-inner {
    color: ${({ theme }) => theme.inputtext};
  }

  .ant-picker-dropdown .ant-picker-content th {
    color: ${({ theme }) => theme.inputtext};
  }

  .ant-picker-dropdown .ant-picker-header button {
    color: ${({ theme }) => theme.inputtext};
  }

  .ant-btn-primary:disabled {
    color: ${({ theme }) => theme.inputtext};
  }

  .ant-picker-time-panel-column
    > li.ant-picker-time-panel-cell-selected
    .ant-picker-time-panel-cell-inner {
    background-color: ${({ theme }) => theme.dateselectbgColor};
  }
`;

export const MakeOfferBtn = styled.button`
  width: 165px;
  height: 35px;
  border-radius: 28px;
  border: 1px solid #00b7ff;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  background-color: rgba(0, 183, 255, 1);

  &:disabled {
    background: ${({ theme }) => theme.disabledBtnBG};
    border: 1px solid ${({ theme }) => theme.disabledBtnBorder} !important;
    color: rgb(183, 189, 202);
  }
`;
