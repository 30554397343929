import styled, { css } from "styled-components";
import { Card, Offcanvas } from "react-bootstrap";
import { Dropdown, Collapse, Select, InputNumber, Checkbox, Input } from "antd";
// assets
import heroImg from "assets/heroImg.png";
// Props

export const HeaderDiv = styled.div`
  overflow-x: hidden !important;
  background: url(${(props) => props.headerImage || heroImg}) no-repeat;
  background-size: cover !important;
  height: 15rem;
`;

export const MainProfile = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  border: 3px solid white;
  transform: translateY(-70px);
`;
export const MainProfileTitle = styled.h2`
  text-align: center;
  margin-top: -3.2rem;
  font-weight: 600;
  font-size: 2.2rem;
  color: black;
`;

export const MainProfileDesc = styled.p`
  text-align: center;
  color: black;
  ${(props) =>
    props.profileScreenDesc &&
    css`
      margin-top: 1rem;
    `}
`;
export const MainProfileSocailBtn = styled.button`
  display: block;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid rgb(223, 232, 235);
  padding: 0.8rem 1rem;
  border-radius: 50px;
  background: transparent;
  color: black;
  font-size: 1.2rem;
  font-weight: 600;

  & span {
    font-weight: 500;
    font-size: 12px;
    color: rgb(112, 122, 131);
  }

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    transition: all 0.5s linear;
  }

  ${(props) =>
    props.profileScreenBtn &&
    css`
      margin-top: 1rem;
      padding: 0.2rem 1rem;
      font-size: 0.9rem;
      font-weight: normal;
    `}
`;

export const MainCollectionStatsMain = styled.div`
  text-align: center;
  margin-top: 2rem;
`;

export const MainCollectionStats = styled.button`
  border: 1px solid rgb(223, 232, 235);
  padding: 0.3rem 1.9rem;
  background: transparent;
  color: white;

  width: 8.7rem;

  &:nth-child(1) {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-right: none;
  }

  &:nth-child(2) {
    border-right: none;
  }
  &:nth-child(3) {
    border-right: none;
  }
  &:nth-child(4) {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    height: 5.7rem;
  }

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    transition: all 0.5s linear;
  }

  @media (max-width: 558px) {
    &:nth-child(3) {
      border-right: 1px solid rgb(223, 232, 235);
      border-top-right-radius: 10px;
    }
  }
  @media (max-width: 414px) {
    &:nth-child(1) {
      border-bottom-left-radius: 0px;
    }
    &:nth-child(2) {
      border-top-right-radius: 10px;
    }
    &:nth-child(2) {
      border-right: 1px solid rgb(223, 232, 235);
      border-top-right-radius: 10px;
    }
    &:nth-child(3) {
      border-right: 1px solid rgb(223, 232, 235);
      border-top-right-radius: 0px;
      border-bottom-left-radius: 10px;
      border-right: none;
      border-top: none;
    }
    &:nth-child(4) {
      border-right: 1px solid rgb(223, 232, 235);
      border-top-right-radius: 0px;
      border-top: none;
    }
  }
`;
export const MainCollectionStatsTitle = styled.h5`
  margin-top: 0.8rem;
  font-weight: 600;
  color: white;
`;

export const MainCollectionStatsValue = styled.p`
  color: rgb(138, 147, 155);
  font-size: 0.8rem;
  width: 6rem;
  margin-left: -0.5rem;
  font-weight: 500;
`;
export const MainDesc = styled.p`
  text-align: center;
  margin-top: 1.3rem;
  color: #fff;
  font-weight: 400;
`;

export const MainReport = styled.button`
  float: right;
  transform: translateY(-26rem);
  margin-right: 2rem;

  border: 1px solid rgb(223, 232, 235);
  padding: 0.3rem 0.8rem;
  background: transparent;
  border-radius: 9px;

  ${(props) =>
    props.profileScreenReport &&
    css`
      transform: translateY(-13rem);
    `}

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    transition: all 0.5s linear;
  }

  @media (max-width: 558px) {
    transform: translateY(-32rem);
    ${(props) =>
      props.profileScreenReport &&
      css`
        transform: translateY(-13rem);
      `}
  }
  @media (max-width: 400px) {
    transform: translateY(-35rem);
  }
  @media (max-width: 350px) {
    transform: translateY(-36rem);
    ${(props) =>
      props.profileScreenReport &&
      css`
        transform: translateX(10px);
        transform: translateY(-13rem);
      `}
  }
`;

export const Dropdowns = styled(Dropdown)``;

/*=============x===========sidebar==========x===========*/

export const SidebarCard = styled(Card)`
  /* width: 19.5rem; */
  background: transparent !important;

  @media (min-width: 300px) {
    display: none;
  }
  @media (min-width: 600px) {
    width: 100%;
    display: block;
  }
  /* @media (min-width: 570px) {
    width: 16.2rem;
  }
  @media (min-width: 1024px) {
    width: 19rem;
  }
  @media (min-width: 1400px) {
    width: 20.7rem;
  }
  @media (min-width: 1600px) {
    width: 19.5rem;
  } */

  @media (min-width: 1600px) {
    width: 22.5rem;
  }
  @media (min-width: 1700px) {
    width: 25rem;
  }
`;
export const SidebarCardHeader = styled(Card.Header)`
  padding: 1.2rem;
  font-weight: 600;
  background: transparent !important;
  color: #fff;
  border: 1px solid white;
`;

export const SidebarCollapse = styled(Collapse)`
  border: none;

  background: transparent !important;
  color: #000;
`;
export const SidebarCollapsePanel = styled(Collapse.Panel)`
  border: none !important;
  color: #000;
`;

export const StatusBtn = styled.button`
  border: 1px solid rgb(223, 232, 235);
  padding: 0.9rem 1rem;
  background: transparent;
  border-radius: 5px;
  font-weight: 600;
  width: 8rem;
  @media (max-width: 1019px) {
    width: 100%;
    margin-top: 0.8rem;
  }
`;

// Price
export const PriceSelect = styled(Select)`
  border-radius: 12px;
  border: 1px solid rgb(223, 232, 235);
  width: 18rem;
  height: 3rem;
  @media (max-width: 1380px) {
    width: 16rem;
  }
  @media (max-width: 990px) {
    width: 14rem;
  }
`;

export const PriceSelectMin = styled(InputNumber)`
  margin-top: 1rem;
  border-radius: 10px;
  padding: 0.5rem 0;
  width: 8rem;

  & > span {
    margin-left: 2rem;
  }
`;

export const PriceSelectSpan = styled.p`
  margin-top: 2rem !important;
  margin-left: 1rem !important;
  margin-right: 1rem !important;
`;

export const SelectMainDiv = styled.div`
  display: flex;
`;

export const PriceSelectMax = styled(InputNumber)`
  margin-top: 1rem;
  border-radius: 10px;
  padding: 0.5rem 0;
  width: 8rem;
`;

export const PriceSelectOption = styled(Select.Option)`
  margin-top: 0.6rem;
`;

export const SelectApplyBtn = styled.button`
  margin-top: 1.4rem;
  border: 1px solid rgb(223, 232, 235);
  padding: 0.7rem 1rem;
  background: transparent;
  border-radius: 40px;
  font-weight: 600;
  width: 9rem;
`;

// Price

// chains
export const ChainSelect = styled(Checkbox)`
  margin-top: 0.6rem;
`;

// chains

// chains
export const BodySelect = styled(Checkbox)`
  margin-top: 0.6rem;
`;

export const BodyText = styled.p`
  margin-top: -1.5rem;
  text-align: end;
  margin-right: 1rem;
`;

// chains

// Canvas Sidebar

export const FilterBtn = styled.button`
  border: none;
  padding: 0.8rem 2rem;
  background: #2c3136;
  color: white;
  border-radius: 30px;
  display: none;

  @media (min-width: 300px) {
    width: 16.5rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
  }
  @media (min-width: 600px) {
    display: none;
  }
`;

export const MobileSidebar = styled(Offcanvas)`
  background: #2c3136;
`;
export const MobileSidebarCard = styled(Card)`
  width: 100%;
  background: #121619 !important;
  border: none;
  margin-top: -3rem;

  @media (min-width: 600px) {
    width: 16.5rem;
  }
  @media (min-width: 570px) {
    width: 16.2rem;
  }
  @media (min-width: 1400px) {
    width: 20.7rem;
  }
  @media (min-width: 1600px) {
    width: 19.5rem;
  }
`;
export const MobileSidebarCardHeader = styled(Card.Header)`
  padding: 1.2rem;
  font-weight: 600;
  background: transparent !important;
`;

// Canvas Sidebar

/*=============x===========sidebar==========x===========*/

/* =========================Main Section====================*/

export const MainSectionSearch = styled(Input)`
  border: 1px solid #7f8c8d;
  border-radius: 10px;
  padding: 0.3rem;
  & > ::placeholder {
    padding-left: 1.5rem;
    padding-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Barlow";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;

    color: #7f8c8d;
  }
  & > .ant-input {
    background: none !important;
    color: black;
  }
  .anticon svg {
    color: #707a83;
    font-size: 20px;
  }
`;

export const MainSelect = styled(Select)`
  border-radius: 12px;
  border: 1px solid rgb(223, 232, 235);
  width: 100%;
  height: 3rem;

  background-color: transparent !important;
  color: white;
`;

export const PriceLowToHighSelect = styled(Select)`
  border-radius: 12px;
  border: 1px solid rgb(223, 232, 235);
  width: 100%;

  height: 3rem;
  color: white;
`;

export const MainSelectOption = styled(Select.Option)`
  margin-top: 1rem;
  border-radius: 10px;
  padding: 0.5rem 0;
`;

// Card
export const MainSectionCard = styled(Card)`
  margin: 1rem 0;
  border-radius: 20px;
  cursor: pointer;
  height: 25rem;

  @media (max-width: 1199px) {
    height: 100%;
  }
`;
export const MainSectionCardFooter = styled(Card.Footer)`
  border-top: 0;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
`;
export const MainSectionCardBody = styled(Card.Body)`
  border-top-left-radius: 20px;
`;
export const MainSectionCardImg = styled(Card.Img)`
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`;
export const MainSectionCardTitle = styled(Card.Title)`
  font-family: 700;
  color: #353840 !important;
  font-size: 12px;
  margin-top: 0.7rem;
`;
export const MainSectionCardText = styled(Card.Text)`
  font-size: 12px;
`;

// card
/* =========================Main Section====================*/
