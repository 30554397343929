import React, { useEffect } from "react";
import { CollectionTable } from "components";
import { Loader } from "components/common";
import { useNavigate } from "react-router-dom";
import { GetCollectionsHook } from "hooks";

const CollectionListing = () => {
  const { data, error, loading, getCollections } = GetCollectionsHook();

  useEffect(() => {
    getCollections("", 0);
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    error?.message === "invalid token" && navigate("/admin-login");
  }, [error]);

  return (
    <div>
      {loading ? <Loader content="Loading Nfts" /> : ""}

      {data ? <CollectionTable data={data} /> : ""}
    </div>
  );
};

export default CollectionListing;
