import React from "react";
import { metamask } from "assets";
import {
  loadBlockchain,
  loadWalletConnect,
} from "store/redux/slices/web3/connectWeb3Slice";
import { useDispatch } from "react-redux";

import { Image } from "react-bootstrap";
import {
  ButtonsDiv,
  ConnectWalletBtn,
  MainHeader,
  ModalWrapper,
} from "./elements";
import { MdClose } from "react-icons/md";
import { basewallet, walletconnect } from "../../../assets";

const ConnectModals = ({ onCancel }) => {
  const dispatch = useDispatch();
  return (
    <div>
      <ModalWrapper>
        <MdClose
          cursor="pointer"
          fontSize={22}
          color="#6B6B6B"
          className="close-icon"
          onClick={onCancel}
        />
        <MainHeader>Connect A Wallet</MainHeader>

        <ButtonsDiv>
          <ConnectWalletBtn
            onClick={() => {
              dispatch(loadBlockchain());
              onCancel();
            }}
          >
            <p>Metamask</p>
            <Image src={metamask} />
          </ConnectWalletBtn>
          {/* <ConnectWalletBtn>
            <p>Coinbase Wallet</p>
            <Image src={basewallet} />
          </ConnectWalletBtn> */}
          <ConnectWalletBtn
            onClick={() => {
              dispatch(loadWalletConnect());
              onCancel();
            }}
          >
            <p>WalletConnect</p>
            <Image src={walletconnect} />
          </ConnectWalletBtn>
        </ButtonsDiv>
      </ModalWrapper>
    </div>
  );
};

export default ConnectModals;
