import { useMutation, useQuery, useSubscription } from "@apollo/client";
import openNotification from "components/common/toastMessage/toastMessage";
import {
  CREATE_CATEGORY,
  REMOVE_CATEGORY,
  UPDATE_CATEGORY,
} from "gql/mutations";
import { GET_CATEGORIES } from "gql/queries";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  resetCategoryData,
  setCategories,
} from "store/redux/slices/categorySlices/categoryDataSlics";
import { useAppDispatch, useAppSelector } from "store/store";

export const GetCategoriesHook = () => {
  const dispatch = useAppDispatch();
  const {
    data: cateData,
    loading: cateLoading,
    error: cateError,
  } = useQuery(GET_CATEGORIES);

  const { categories } = useAppSelector((state) => state.categoryData);

  useEffect(() => {
    cateData && dispatch(setCategories(cateData?.get_categories));
  }, [cateData]);

  return {
    categories,
    cateLoading,
    cateError,
  };
};

export const CreateCategoryHook = (setProductDialog) => {
  const [create_category, { loading: createCateLoading }] =
    useMutation(CREATE_CATEGORY);
  const { category } = useAppSelector((state) => state.categoryData);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const create = () => {
    create_category({
      variables: {
        values: { name: category.name, instructions: category.instructions },
      },
    }).then(
      (result) => {
        if (result.data) {
          openNotification("Created", "Category Created", "success");
          dispatch(resetCategoryData());
          setProductDialog(false);
        }
      },
      (error) => {
        if (error.message === "invalid token") navigate("/admin-login");
        else openNotification("Error", "Something went wrong", "error");
      }
    );
  };

  return {
    createCateLoading,
    create,
  };
};

export const UpdateCategoryHook = (setProductDialog) => {
  const [update_category, { loading: updateCateLoading }] =
    useMutation(UPDATE_CATEGORY);
  const { category } = useAppSelector((state) => state.categoryData);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const update = () => {
    update_category({
      variables: {
        id: category.id,
        values: { name: category.name, instructions: category.instructions },
      },
    }).then(
      (result) => {
        if (result.data) {
          openNotification("Updated", "Category Updated", "success");
          dispatch(resetCategoryData());
          setProductDialog(false);
        }
      },
      (error) => {
        if (error.message === "invalid token") navigate("/admin-login");
        else openNotification("Error", "Something went wrong", "error");
      }
    );
  };

  return {
    updateCateLoading,
    update,
  };
};

export const DeleteCategoryHook = (setDeleteProductsDialog) => {
  const [remove_category, { loading: removeCateLoading }] =
    useMutation(REMOVE_CATEGORY);
  const { category } = useAppSelector((state) => state.categoryData);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const deleteItem = () => {
    remove_category({ variables: { id: category.id } }).then(
      (result) => {
        if (result.data) {
          openNotification("Deleted", "Category Deleted", "success");
          dispatch(resetCategoryData());
          setDeleteProductsDialog(false);
        }
      },
      (error) => {
        if (error.message === "invalid token") navigate("/admin-login");
        else openNotification("Error", "Something went wrong", "error");
      }
    );
  };

  return {
    removeCateLoading,
    deleteItem,
  };
};
