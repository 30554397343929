const polygonIcon = require("../../assets/polygonIcon.png");
const ethIcon = require("../../assets/ethIcon.png");
const usdcIcon = require("../../assets/usdc.png");

export const blockchainSelect = [
  {
    text: "Ethereum",
    value: 1,
    icon: ethIcon,
  },
  {
    value: 137,
    text: "Polygon",
    icon: polygonIcon,
  },
];

export const paymentTokenSelect = [
  {
    value: "DAI",
  },
  {
    value: "USDC",
  },
  {
    value: "BASED",
  },
  {
    value: "1337",
  },
];

export const paymentList = (chainId) => {
  switch (chainId) {
    case "4":
      return [
        {
          text: "USDC",
          value: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
          decimals: 6,
          icon: usdcIcon,
          cmcId: 3408,
        },
        {
          text: "TestToken",
          decimals: 18,
          value: "0xb177461582d3B08cB905aF5B1AeDBBE98C865Fa4",
          icon: ethIcon,
          cmcId: 1027,
        },
      ];

    case "137":
      return [
        {
          text: "USDC",
          value: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
          decimals: 6,
          icon: usdcIcon,
          cmcId: 3408,
        },
        {
          text: "TestToken",
          value: "0xb177461582d3B08cB905aF5B1AeDBBE98C865Fa4",
          decimals: 18,
          icon: ethIcon,
          cmcId: 1027,
        },
      ];

    case "3":
      return [
        {
          text: "USDC",
          value: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
          decimals: 6,
          icon: usdcIcon,
          cmcId: 3408,
        },
        {
          text: "TestToken",
          value: "0xb177461582d3B08cB905aF5B1AeDBBE98C865Fa4",
          decimals: 18,
          icon: ethIcon,
          cmcId: 1027,
        },
      ];

    case "1":
      return [
        {
          text: "USDC",
          value: "",
          decimals: 6,
          icon: usdcIcon,
          cmcId: 3408,
        },
        {
          text: "TestToken",
          value: "",
          decimals: 18,
          icon: ethIcon,
          cmcId: 1027,
        },
      ];

    default:
      return [];
  }
};

export const trendingSelect = [
  {
    value: "Last 7 Days",
  },
  {
    value: "Last 24 Hours",
  },
];

export const DUMMY_CATEGORIES = [
  {
    value: "category1",
  },
  {
    value: "category2",
  },
  {
    value: "category3",
  },
];
