import styled from "styled-components";
import { AddBtn } from "../packs/createPackCom/element";

export const DropsWrapper = styled.div`
  overflow: hidden;

  .container-fluid {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .price-select.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border: none !important;

    &:focus {
      border: none !important;
      outline: none !important;
      box-shadow: none !important;
    }
  }
`;

export const PreviewBtn = styled(AddBtn)`
  width: 100px;
  margin-top: 12px;
  background: var(--pimary, #00b7ff) !important;

  @media (max-width: 1466.98px) {
    width: 80px;
  }

  @media (max-width: 1266.98px) {
    width: 70px;
  }

  @media (max-width: 991.98px) {
    margin-top: 1rem;
    width: 100px;
  }
`;

export const MainHeader = styled.h2`
  color: #3e3e3e;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-transform: capitalize;
  margin-top: 4rem;

  &.no-margin {
    margin: 0;

    @media (max-width: 991.98px) {
      margin-top: 2rem;
    }
  }
`;

export const MainCardWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 0px;

  @media (max-width: 1399.98px) {
    grid-template-columns: repeat(5, 1fr);
  }
  @media (max-width: 1199.98px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: 991.98px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 767.98px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 450.98px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const CardWrapper = styled.div`
  margin-top: 2rem;

  position: relative .card {
    /* width: 250px; */
    width: 100%;
    height: 379px;
    border-radius: 10px;
    border: 1px solid #00b7ff;
    box-shadow: 0px 0px 4.254847526550293px 0px rgba(0, 0, 0, 0.27);
    position: relative;
  }

  .card-img,
  .card-img-bottom {
    /* padding: 2rem; */
    /* border-radius: 10px;
    border: 1px solid #e7e7e7; */
    /* width: 194px;
    height: 132px; */
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: left 0.3s ease;
    opacity: 0;
    border-radius: 10px;
  }
  .card:hover .img-wrapper {
    transform: scale(1.03);
  }
  .card:hover .overlay {
    left: 0;
    opacity: 1;
  }
  .card-body {
    padding: 1rem 0rem;
  }

  .card-title {
    color: var(--pimary, #00b7ff);
    text-align: center;
    font-family: Barlow;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem; /* 120% */
    text-transform: capitalize;
    text-align: center;
    margin-top: -0.5rem;
  }

  .card-text {
    color: #6b6b6b;
    font-family: Barlow;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    text-align: center;
    margin-top: -0.2rem;
  }

  h5 {
    color: var(--pimary, #00b7ff);
    font-family: Barlow;
    font-size: 0.93075rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    text-align: center;
    margin-top: 0.5rem;
    span {
      color: #6b6b6b;
      font-family: Barlow;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: capitalize;
    }
  }

  .content-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.929rem;
    padding: 0rem 1rem;
  }

  .content-main-dev {
    min-height: 359px;
  }
`;

export const ImageWrapper = styled.div`
  margin: 0.2rem 0.5rem;
  padding-bottom: 0;
  position: relative;
  transition: transform 0.3s ease;

  img {
    /* border: 1px solid #00b7ff; */
    border: 1px solid #727989 !important;
    /* object-fit: cover; */
    border-radius: 10px;
  }
`;

export const Title = styled.h5`
  color: var(--secondary, #3e3e3e);
  font-family: "Barlow";
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
  text-transform: capitalize;
  margin-top: 1rem;
`;

export const MainWrapper = styled.div`
  margin-top: 2rem;
  border-radius: 0.625rem;
  border: 1px solid #e7e7e7;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27);
  padding: 20px;
  p {
    color: #6b6b6b;
    font-family: "Barlow";
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    margin-top: 0.7rem;
  }

  .ant-input {
    border-radius: 0.625rem;
    border: 1px solid #e7e7e7;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27);
    height: 2.9375rem;
    margin-top: 0.5rem;
    &::placeholder {
      color: #c9c9c9;
      font-family: "Barlow";
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;
    }
  }

  .input-div {
    border-radius: 0.625rem;
    border: 1px solid #e7e7e7;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27);
    height: 2.9375rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    cursor: pointer;
    padding: 0rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .ant-select {
    width: 100%;
    border-radius: 0.625rem;
    border: 1px solid #e7e7e7;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27);
    height: 2.9375rem;
    margin-top: 0.5rem;
    justify-content: center;
    display: flex;
    align-items: center;
  }

  .createCollecton
    .ant-select-single.ant-select-show-arrow
    .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    color: #c9c9c9;
    font-family: "Barlow";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    margin-top: 0.4rem;
  }
`;

export const DateWrapper = styled.div`
  .ant-picker {
    width: 100%;
    border-radius: 0.625rem;
    border: 1px solid #e7e7e7;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27);
    height: 2.9375rem;
    margin-top: 0.5rem;
    justify-content: center;
    display: flex;
    align-items: center;
  }
`;

export const CheckboxDiv = styled.div`
  margin-top: 0.8rem;

  .ant-checkbox + span {
    color: #6b6b6b;
    font-family: "Barlow";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
  }
`;

export const AddAssetsBtn = styled.button`
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;
  margin-bottom: 4rem;
  color: #fff;
  font-family: "Barlow";
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  border-radius: 1.75rem;
  background: var(--pimary, #00b7ff);
  /* width: 7.5625rem;
  height: 1.8125rem; */
  padding: 7px 30px;
`;

export const Key = styled.h4`
  color: ${({ theme }) => theme.cardInnerContent};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
`;
export const Value = styled.h2`
  color: ${({ theme }) => theme.cardInnerContentValue};
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;

  &.blue {
    color: rgba(0, 183, 255, 1);
  }
`;
