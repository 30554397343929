import styled, { css } from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import Slider from "react-slick";
import { Upload, Button, Dropdown, Menu, Collapse } from "antd";
import { Nav } from "react-bootstrap";

export const MainContainer = styled(Container)`
  ${(props) =>
    props.heroSectionContainer &&
    css`
      padding: 2.5rem 5rem;
      & {
        max-width: 1440px;
      }
      @media (max-width: 460px) {
        padding: 0rem 1rem;
      }
    `}

  ${(props) =>
    props.isSellNftContainer &&
    css`
      padding: 8rem 5rem;
      & {
        max-width: 1440px;
      }
      /* @media (max-width: 460px) {
        padding: 0rem 1rem;
      } */
    `}
    

  ${(props) =>
    props.nftDetailsContainer &&
    css`
      margin-bottom: 2rem;
    `}
    
    @media (max-width: 460px) {
    ${(props) =>
      props.mainSectionContainer &&
      css`
        margin-top: 3rem;
      `}
  }

  ${(props) =>
    props.importCollection &&
    css`
      padding: 2rem;
    `}

  @media (min-width:1200px) {
    .container {
      max-width: 1177px !important;
    }
  }
`;

export const MainRow = styled(Row)`
  ${(props) =>
    props.nftFormRow &&
    css`
      display: flex;
      justify-content: center;
    `}
  ${(props) =>
    props.mainSectionRow &&
    css`
      margin-top: 2rem;
    `}
    ${(props) =>
    props.profileSettingRow &&
    css`
      display: flex;
      justify-content: center;
      /* margin-left: 8rem; */
      padding: 5rem 0rem;
    `}

    ${(props) =>
    props.isAllNftSearchRow &&
    css`
      margin-top: 0.5rem;
    `}


    
    @media (max-width:991px) {
    ${(props) =>
      props.profileSettingRow &&
      css`
        padding: 5rem 2rem;
      `}
  }

  @media (max-width: 420px) {
    ${(props) =>
      props.collectionAllRow &&
      css`
        flex-direction: column !important;
      `}
  }
`;

export const MainCol = styled(Col)`
  ${(props) =>
    props.profileSettingUploadImgCol &&
    css`
      margin-top: 3rem;
      margin-left: 4rem;
    `}
`;

export const MainButton = styled.button`
  background: #d35400;
  border: none;
  padding: 0.5rem 2rem;
  color: white;

  ${(props) =>
    props.connectModel &&
    css`
      /* display: block;
      margin-left: auto;
      margin-right: auto;
      margin-top: 4rem; */
    `}

  ${(props) =>
    props.newConnectWalletBtn &&
    css`
      background: linear-gradient(
        90deg,
        rgba(0, 170, 255, 1) 0%,
        rgba(116, 198, 238, 1) 52%,
        rgba(0, 212, 255, 1) 100%
      );
      border-radius: 4px;
      border: none;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 100%;
      color: #fff;
      padding: 10px 25px;
    `}
`;

export const MainProfileBg = styled.div`
  background-image: url(${(props) => props.bgImg});

  background-size: cover !important;

  ${(props) => props.bgImg && css``}

  ${(props) =>
    props.mainBg &&
    css`
      height: 15rem;
      overflow-x: hidden;
      background-repeat: no-repeat;
    `}

  ${(props) =>
    props.profileBg &&
    css`
      display: block;
      margin-left: auto;
      width: 9rem;
      height: 9rem;
      margin-right: auto;
      border-radius: 50%;
      border: 3px solid white;
      transform: translateY(-70px);
    `}

    ${(props) =>
    props.collectionProfileBg &&
    css`
      width: 9rem;
      height: 9rem;
      margin-top: 1rem;
      border-radius: 50%;
      border: 1px solid black;
    `}

    ${(props) =>
    props.collectionFeaturedImage &&
    css`
      width: 300px !important;
      height: 200px !important;
      margin-top: 1rem;
      border: 3px solid white;
    `}

    ${(props) =>
    props.collectionBannerImage &&
    css`
      width: 700px !important;
      height: 200px !important;
      margin-top: 1rem;
      border: 1px solid black;
    `}

    ${(props) =>
    props.profileSettingBanner &&
    css`
      width: 150px !important;
      height: 120px !important;
      margin-top: 1rem;
      border: 1px solid black;
    `}

    

    @media (max-width:767px) {
    ${(props) =>
      props.collectionBannerImage &&
      css`
        max-width: 100% !important;
        height: 200px !important;
        margin-top: 1rem;
        border: 1px solid black;
      `}
  }
`;

export const MainUpload = styled(Upload)`
  display: flex;
  justify-content: center;
`;
export const MainUploadBtn = styled(Button)`
  width: 100vw !important;
  height: 15rem;
  background: none;
  border: none;

  ${(props) =>
    props.profileUpload &&
    css`
      width: 8.8rem !important;
      height: 8.8rem !important;
      border-radius: 50%;
      border: 1px solid black;
      transform: translateY(-3px);
    `}

  ${(props) =>
    props.featuredUploadBtn &&
    css`
      width: 300px !important;
      height: 200px !important;
    `}

    
    

  ${(props) =>
    props.createUploadBtn &&
    css`
      width: 8.8rem !important;
      height: 8.8rem !important;
      border-radius: 50%;
    `}

    ${(props) =>
    props.bannerUploadBtn &&
    css`
      width: 700px !important;
      height: 200px !important;
    `}

    ${(props) =>
    props.profileSettingBannerBtn &&
    css`
      width: 150px !important;
      height: 120px !important;
    `}



  &:hover {
    background: #f2f2f2;
    opacity: 0.8 !important;
  }
`;

export const MainSlider = styled(Slider)``;

export const Dropdowns = styled(Dropdown)``;

export const DropdownMenu = styled(Menu)``;

export const DropdownItem = styled(Menu.Item)``;
export const DropdownItemA = styled.a`
  text-decoration: none;
  padding: 0.4rem 0.8rem;
  margin-left: -0.2rem;
  font-size: 1rem;
  font-weight: 600;
`;

export const Buttons = styled.button`
  /* Share Button */
  padding: 0.2rem 0.8rem;
  font-size: 20px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border: 1px solid rgb(229, 232, 235);
  background: transparent;

  ${(props) =>
    props.sharebtn &&
    css`
      border-right: none;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    `}

  ${(props) =>
    props.buyNowBtn &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      background: #2081e2;
      padding: 0.8rem 5rem;
      border-radius: 10px;
      color: white;
      font-weight: 600;
    `}

    /* createCategory */

    ${(props) =>
    props.createCategoryBtn &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      background: #2081e2;
      padding: 0.5rem 2rem;
      margin-top: 0.5rem;
      border-radius: 10px;
      color: white;
      font-size: 16px;
    `}

    
  ${(props) =>
    props.makeOfferBtn &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      background: transparent;
      border: 1px solid #2081e2;
      padding: 0.8rem 5rem;
      border-radius: 10px;
      font-weight: 600;
      color: #fff;
    `}

    ${(props) =>
    props.explore &&
    css`
      padding: 10px 24px;
      background: linear-gradient(
        90deg,
        rgba(0, 170, 255, 1) 0%,
        rgba(116, 198, 238, 1) 52%,
        rgba(0, 212, 255, 1) 100%
      );
      border-radius: 4px;
      font-family: "Figtree";
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 100%;
      letter-spacing: -0.05em;
      color: #ffffff;
      width: 140px;
      margin: 0 !important;
      height: 52px;

      @media (max-width: 575.98px) {
        width: 100% !important;
      }
    `}

    ${(props) =>
    props.startStaking &&
    css`
      background: #ffffff;
      border: 1px solid #261ce8;
      border-radius: 4px;
      font-family: "Figtree";
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 100%;
      letter-spacing: -0.05em;
      color: #261ce8;
      padding: 16px 32px;
    `}

    
    ${(props) =>
    props.buyBtn &&
    css`
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      color: #ffffff;
      align-items: center;
      padding: 12px 16px;
      gap: 8px;
      width: 380px;
      height: 48px;
      background: #d35400;
      border-radius: 8px;
    `}

    ${(props) =>
    props.newBuyBtn &&
    css`
      background: linear-gradient(
        90deg,
        rgba(0, 170, 255, 1) 0%,
        rgba(116, 198, 238, 1) 52%,
        rgba(0, 212, 255, 1) 100%
      );
      border-radius: 4px;
      font-family: "Figtree";
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 100%;
      letter-spacing: -0.05em;
      color: #ffffff;
      padding: 16px 32px;
    `}

    ${(props) =>
    props.makeOffer &&
    css`
      background: #ffffff;
      border: 1px solid #9040c4;
      border-radius: 4px;
      padding: 16px 24px;
      font-family: "Figtree";
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 100%;
      letter-spacing: -0.05em;
      color: #261ce8;
    `}

    ${(props) =>
    props.sellNFTBtn &&
    css`
      padding: 10px 24px;
      background: #ffffff;
      border: 1px solid #00aaff;
      border-radius: 4px;
      font-family: "Figtree";
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 100%;
      letter-spacing: -0.05em;
      color: #00aaff;
      width: 140px;
      height: 52px;

      @media (max-width: 575.98px) {
        width: 100%;
      }
    `}


    
    ${(props) =>
    props.create &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 24px;
      gap: 12px;
      background: #ffffff;
      border-radius: 10px;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: #d35400;
    `}

   
    ${(props) =>
    props.mainSectionLiveBtn &&
    css`
      background: transparent;
      padding: 0rem 0.7rem;
      border: 1px solid white;

      border-radius: 5px;

      color: white;
    `}

    ${(props) =>
    props.isPreviewBtn &&
    css`
      background: transparent;
      padding: 0.2rem 0.7rem;
      border: 1px solid white;
      border-radius: 5px;
      color: white;
      display: block;
      margin-left: auto;
      margin-right: auto;
      font-size: 1rem;
    `}
    
    ${(props) =>
    props.isWeb3AddressBtn &&
    css`
      background: transparent;
      border-radius: 41px;
      color: white;
      font-size: 1rem;
      margin-left: 1rem;
    `}

       
    ${(props) =>
    props.isSellBtn &&
    css`
      background: #240749;
      border-radius: 41px;
      color: white;
      font-size: 1rem;
      margin-left: 1.8rem;
      margin-top: 0.5rem;
      padding: 0.5rem 3rem;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;

      @media (max-width: 990px) {
        display: block;
        margin: auto;
        margin-top: 1rem;
      }
    `}

    ${(props) =>
    props.isNftSellBtn &&
    css`
      display: inline-flex;
      flex-direction: row;
      -webkit-box-align: center;
      align-items: center;
      border-radius: 10px;
      -webkit-box-pack: center;
      justify-content: center;
      font-size: 16px;
      font-weight: 600;
      padding: 7px 26px;
      background: #240749;
      border: none;
      color: rgb(255, 255, 255);

      margin-right: 1rem;
      z-index: 999;
    `}

    ${(props) =>
    props.saveBtn &&
    css`
      display: inline-flex;
      flex-direction: row;
      -webkit-box-align: center;
      align-items: center;
      border-radius: 10px;
      -webkit-box-pack: center;
      justify-content: center;
      font-size: 16px;
      font-weight: 600;
      padding: 8px 20px;
      background-color: #d35400;

      color: rgb(255, 255, 255);

      margin-right: 1rem;
      z-index: 999;
    `}
    
    
    
    ${(props) =>
    props.isSumbitBtn &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      background: #2081e2;
      padding: 0.5rem 2rem;
      margin-top: 0.9rem;
      border-radius: 10px;
      color: white;
      font-size: 16px;

      @media (max-width: 990px) {
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
    `}

    ${(props) =>
    props.isFavouriteBtn &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      background: #2081e2;
      padding: 0.5rem 2rem;
      margin-left: 1.8rem;
      color: white;
      font-size: 16px;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;

      @media (max-width: 990px) {
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
    `}

    
    
    


    @media (max-width:1199px) {
    ${(props) =>
      props.makeOfferBtn &&
      css`
        padding: 0.8rem 2rem;
      `}
    ${(props) =>
      props.buyNowBtn &&
      css`
        padding: 0.8rem 2rem;
      `}

      @media (max-width:991px) {
      ${(props) =>
        props.isWeb3AddressBtn &&
        css`
          width: 50%;
          margin-top: 0.7rem;
          display: block;
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 2rem;
        `}
    }

    @media (max-width: 460px) {
      ${(props) =>
        props.explore &&
        css`
          margin-bottom: 1rem;
          width: 70%;
          justify-content: center;
          margin-left: 1.1rem;
        `}
      ${(props) =>
        props.create &&
        css`
          margin-bottom: 1rem;
          width: 70%;
          justify-content: center;
          margin-left: 1.1rem;
        `}
    }
  }
`;

export const NftCollapse = styled(Collapse)``;
export const CollapsePanel = styled(Collapse.Panel)`
  border-bottom: none !important;
`;

export const LoadindMain = styled.div`
  /* Loader */

  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(141, 141, 141, 0.7);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MainNav = styled(Nav)``;
