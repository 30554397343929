import { Form, Image } from "react-bootstrap";
import {
  DropdownDiv,
  FiltersHeader,
  FiltersSubHeader,
  FiltersWrapper,
} from "./element";
import { MainCol, MainRow } from "components/common";
import { Select } from "antd";
import { SearchWrapper } from "../profile/element";
import { GetCreateSchemaHook } from "hooks/createSchemaHook";
import { useEffect, useRef, useState } from "react";
import { GetTemplateHook } from "hooks/templateHook";
import { IoIosArrowDown } from "react-icons/io";
import { AiFillCloseCircle } from "react-icons/ai";
import { GetDropsHook } from "hooks/dropHook";
import Loading from "components/common/loader";
import { useAppSelector } from "store/store";
import { Rarity } from "utility";

function FilterComp({ filter, setFilter }) {
  const { data: schemas, loading: schemaLoading } = GetCreateSchemaHook();
  const { data: templates, loading: tempLoading } = GetTemplateHook();
  const { data: dropList, loading: dropLoading } = GetDropsHook();
  const [attribute, setAttribute] = useState([]);
  const [drops, setDrops] = useState([]);
  const [schemaName, setSchemaName] = useState("");
  const [schemaId, setSchemaId] = useState(null);
  // const [rarity, setRarity] = useState([]);
  const [rarityList, setRarityList] = useState([]);

  const [dropsValue, setDropsValue] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [mouseHovered, setMouseHovered] = useState(false);

  const onSearch = (value) => {
    console.log("search:", value);
  };
  const { theme } = useAppSelector((state) => state.model);

  const getTemplateOptions = (item) => {
    let attributeType = item?.attribute_placeholder;
    const uniqueAttributeValues = new Set();
    let filtered = templates.filter((x) => x?.schema_id?._id == schemaId);
    filtered
      ?.flatMap((template) => template.template_attributes)
      .filter((attr) => attr.attribute_type === attributeType)
      .forEach((attr) => {
        if (item.types !== "number[]") {
          uniqueAttributeValues.add(attr.attribute_value);
        } else if (Array.isArray(attr.numbers)) {
          const sortedNumbers = attr.numbers
            .map((num) => parseFloat(num.number))
            .sort((a, b) => a - b);

          if (sortedNumbers.length === 1) {
            uniqueAttributeValues.add(`${sortedNumbers[0]}`);
          } else if (sortedNumbers.length >= 2) {
            const lowest = sortedNumbers[0];
            const highest = sortedNumbers[sortedNumbers.length - 1];

            uniqueAttributeValues.add(`${lowest}-${highest}`);
          }
        }
      });

    return Array.from(uniqueAttributeValues).map((attrValue) => ({
      value: attrValue,
      label: attrValue,
    }));
    // }
  };

  const getFieldIdentifier = (item, index) => {
    // Use item.id if available, otherwise, use a combination of index and item types
    return item.id || `${index}-${item.types}`;
  };

  const handleDropdownClick = (data) => {
    setFilter((pre) => ({
      ...pre,
      drop_id: data?._id,
    }));
    setDropsValue(data.name); // Set the name of the selected data to the input field
    setIsOpen(false); // Close the dropdown
  };

  useEffect(() => {
    if (dropList) {
      let temp = dropList;
      if (schemaId) {
        temp = dropList.filter(
          (x) =>
            x?.pack_id?.pack_slots?.length > 0 &&
            x?.pack_id?.pack_slots[0]?.template_id?.schema_id == schemaId
        );
      }
      setDrops(temp);
    }
  }, [dropList, schemaId]);

  const filteredData = drops?.filter((item) =>
    item?.name?.toLowerCase()?.includes(dropsValue?.toLowerCase())
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !mouseHovered &&
        isOpen &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isOpen]);

  // useEffect(() => {
  //   if (templates) {
  //     // setRarity()

  //     const nameAttributeValues = templates.flatMap((template) =>
  //       template.template_attributes
  //         .filter((attribute) => attribute.attribute_type === "Rarity")
  //         .map((attribute) => attribute.attribute_value)
  //     );
  //     setRarity(nameAttributeValues);
  //   }
  // }, [templates]);
  useEffect(() => {
    if (templates) {
      // setRarity()

      const nameAttributeValues = templates.flatMap((template) =>
        template.template_attributes
          .filter((attribute) => attribute.attribute_type === Rarity)
          .map((attribute) => attribute.attribute_value)
      );
      const uniqueNameAttributeValues = new Set(nameAttributeValues);
      setRarityList(Array.from(uniqueNameAttributeValues));
    }
  }, [templates]);

  return (
    <FiltersWrapper>
      {/* {(dropLoading || tempLoading || schemaLoading) && (
        <Loading content={"Loading"} />
      )} */}
      <FiltersHeader>Filters</FiltersHeader>
      <div className={`filter-inner-div ${isOpen && "height-class"}`}>
        <div className="mt-4 ">
          <SearchWrapper>
            <FiltersSubHeader>Select Schema</FiltersSubHeader>

            <Select
              showSearch
              popupClassName="first-explore-select-dropdown"
              style={{
                width: "100%",
                marginTop: "1rem",
                color: theme === "light" ? "#6B6B6B" : "#FBFBFC",
              }}
              placeholder="Select a schema"
              optionFilterProp="children"
              allowClear
              onClear={() => {
                setDropsValue("");
                setSchemaName("");
                setSchemaId(null);
                // setFilter({});
                setAttribute([]);
                // console.log(Object?.keys(filter)?.length > 0, "true");
                if (Object?.keys(filter)?.length > 0) {
                  setFilter({});
                }
              }}
              value={schemaName || "Select a schema"}
              onSelect={(e) => {
                // if (Object?.keys(filter)?.length > 0) {
                //   setFilter({});
                //   setDropsValue("");
                // }
                setDropsValue("");
                let schema_name = schemas?.find((x) => x?.schema_name == e);

                if (filter?.attribute?.find((x) => x?.field == Rarity)) {
                  setFilter({
                    attribute: filter?.attribute?.filter(
                      (x) => x.field == Rarity
                    ),
                    schema_name: schema_name?.schema_name,
                  });
                } else {
                  setFilter({
                    // ...filter,
                    schema_name: schema_name?.schema_name,
                  });
                }
                setSchemaName(schema_name?.schema_name);
                setSchemaId(schema_name?._id);
                const uniqueAttributesMap = new Map();
                schema_name?.schema_attributes?.forEach((x) => {
                  if (
                    x.types !== "file" &&
                    x.attribute_placeholder !== Rarity &&
                    !uniqueAttributesMap.has(x.attribute_placeholder)
                  ) {
                    uniqueAttributesMap.set(x.attribute_placeholder, x);
                  }
                });
                const uniqueAttributesArray = Array.from(
                  uniqueAttributesMap.values()
                );
                setAttribute(uniqueAttributesArray);
              }}
              onSearch={onSearch}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  ?.toLowerCase()
                  ?.includes(input?.toLowerCase())
              }
              options={schemas?.map((x) => {
                return {
                  value: x.schema_name,
                  label: x.schema_name,
                };
              })}
            />

            <div className="attribut-select">
              {attribute?.length > 0 && (
                <FiltersSubHeader className="mt-4">Attribute</FiltersSubHeader>
              )}
              {attribute?.map((item, index) => (
                <div key={index}>
                  <Select
                    className="explore-select"
                    allowClear
                    popupClassName="first-explore-select-dropdown"
                    onClear={() => {
                      if (filter?.attributes?.length <= 1) {
                        setFilter({});
                      } else {
                        const attribute = filter?.attribute || [];
                        const updatedAttribute = attribute.filter(
                          (x) => x?.field !== getFieldIdentifier(item, index)
                        );

                        const newFilter = {
                          ...filter,
                          attribute: updatedAttribute,
                        };

                        setFilter(newFilter);
                      }
                    }}
                    value={
                      filter?.attribute?.find(
                        (x) => x.field === getFieldIdentifier(item, index)
                      )?.value || item?.attribute_placeholder
                    }
                    onSelect={(value) => {
                      if (value) {
                        const updatedAttribute = filter?.attribute || [];
                        const fieldIdentifier = getFieldIdentifier(item, index);

                        // Filter out any previous values for the same field
                        const filteredAttribute = updatedAttribute.filter(
                          (item) => item.field !== fieldIdentifier
                        );

                        // Add the new value for the field
                        filteredAttribute.push({
                          value,
                          field: fieldIdentifier,
                          multiples: item?.types == "number[]" ? true : false,
                        });

                        const newFilter = {
                          ...filter,
                          attribute: filteredAttribute,
                        };

                        setFilter(newFilter);
                      }
                    }}
                    placeholder={item?.types}
                    style={{
                      width: "100%",

                      color: theme === "light" ? "#6B6B6B" : "#FBFBFC",
                    }}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={getTemplateOptions(item)}
                  />
                </div>
              ))}
            </div>

            {/*  from here  */}

            <div className="attribut-select">
              {rarityList?.length > 0 && (
                <FiltersSubHeader className="mt-4">Rarity</FiltersSubHeader>
              )}
              {/* {rarity?.map((item, index) => ( */}
              <div>
                <Select
                  className="explore-select"
                  allowClear
                  popupClassName="first-explore-select-dropdown"
                  placeholder={Rarity}
                  onClear={() => {
                    if (filter?.attributes?.length <= 1) {
                      setFilter({});
                    } else {
                      const attribute = filter?.attribute || [];
                      const updatedAttribute = attribute.filter(
                        (x) => x?.field !== Rarity
                      );

                      const newFilter = {
                        ...filter,
                        attribute: updatedAttribute,
                      };

                      setFilter(newFilter);
                    }
                  }}
                  style={{
                    width: "100%",

                    color: theme === "light" ? "#6B6B6B" : "#FBFBFC",
                  }}
                  value={
                    filter?.attribute?.find((x) => x.field === Rarity)?.value ||
                    Rarity
                  }
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  // onSelect={(value) => {
                  //   if (value) {
                  //     // Add the new value for the field
                  //     let filteredAttribute = [

                  //       {
                  //         value,
                  //         field: "Rarity",
                  //         multiples: false,
                  //       },
                  //     ];

                  //     const newFilter = {
                  //       ...filter,
                  //       attribute: filteredAttribute,
                  //     };

                  //     setFilter(newFilter);
                  //   }
                  // }}
                  onSelect={(value) => {
                    if (value) {
                      // Check if "Rarity" attribute already exists in the attribute array
                      const existingRarityAttribute = filter?.attribute?.find(
                        (attr) => attr.field === Rarity
                      );

                      if (existingRarityAttribute) {
                        // Update the existing "Rarity" attribute value
                        const updatedAttributes = filter?.attribute?.map(
                          (attr) =>
                            attr.field === Rarity ? { ...attr, value } : attr
                        );

                        const newFilter = {
                          ...filter,
                          attribute: updatedAttributes,
                        };

                        setFilter(newFilter);
                      } else {
                        // Add a new "Rarity" attribute
                        const newRarityAttribute = {
                          value,
                          field: Rarity,
                          multiples: false,
                        };

                        const newFilter = {
                          ...filter,
                          attribute:
                            filter?.attribute?.length > 0
                              ? [...filter?.attribute, newRarityAttribute]
                              : [
                                  {
                                    value,
                                    field: Rarity,
                                    multiples: false,
                                  },
                                ],
                        };

                        setFilter(newFilter);
                      }
                    }
                  }}
                  options={rarityList?.map((x) => {
                    return {
                      label: x,
                      value: x,
                    };
                  })}
                />
              </div>
              {/* ))} */}
            </div>

            {/* to here */}
          </SearchWrapper>
        </div>
        <div className="mt-4">
          <FiltersSubHeader>Price</FiltersSubHeader>
          <MainRow className="no-margin">
            <MainCol>
              <Form.Control
                placeholder="Min"
                value={filter?.erc20Amount?.$gte || ""}
                onChange={(e) => {
                  const value = e?.target?.value?.trim();
                  if (value !== "") {
                    setFilter((prevFilter) => ({
                      ...prevFilter,
                      erc20Amount: {
                        ...prevFilter.erc20Amount,
                        $gte: parseFloat(value),
                      },
                    }));
                  } else {
                    setFilter((prevFilter) => {
                      const updatedFilter = { ...prevFilter };
                      delete updatedFilter?.erc20Amount?.$gte;
                      return updatedFilter;
                    });
                  }
                }}
              />
            </MainCol>
            <MainCol>
              <Form.Control
                placeholder="Max"
                value={filter?.erc20Amount?.$lte || ""}
                onChange={(e) => {
                  const value = e?.target?.value?.trim();
                  if (value !== "") {
                    setFilter((prevFilter) => ({
                      ...prevFilter,
                      erc20Amount: {
                        ...prevFilter?.erc20Amount,

                        $lte: parseFloat(value),
                      },
                    }));
                  } else {
                    setFilter((prevFilter) => {
                      const updatedFilter = { ...prevFilter };
                      delete updatedFilter?.erc20Amount?.$lte;
                      return updatedFilter;
                    });
                  }
                }}
              />
            </MainCol>
          </MainRow>
        </div>

        <div className="mt-4">
          <FiltersSubHeader>Mint Id</FiltersSubHeader>
          <MainRow className="no-margin">
            <MainCol>
              <Form.Control
                placeholder="Min"
                type="number"
                value={filter?.nft_id?.$gte || ""}
                min="0"
                onChange={(e) => {
                  const value = e.target.value;
                  if (value) {
                    setFilter((prevFilter) => ({
                      ...prevFilter,
                      nft_id: { ...prevFilter.nft_id, $gte: value },
                    }));
                  } else {
                    setFilter((prevFilter) => {
                      const { $lte, $gte, ...rest } = prevFilter.nft_id || {};
                      if (!$lte) {
                        delete prevFilter["nft_id"];
                        return { ...prevFilter };
                      } else {
                        return { ...prevFilter, nft_id: { ...rest } };
                      }
                    });
                  }
                }}
              />
            </MainCol>
            <MainCol>
              <Form.Control
                placeholder="Max"
                type="number"
                min="0"
                value={filter?.nft_id?.$lte || ""}
                onChange={(e) => {
                  const value = e.target.value;
                  if (Number(value)) {
                    setFilter((prevFilter) => ({
                      ...prevFilter,
                      nft_id: { ...prevFilter.nft_id, $lte: value },
                    }));
                  } else {
                    setFilter((prevFilter) => {
                      const { $lte, $gte, ...rest } = prevFilter?.nft_id || {};
                      if (Object.entries(prevFilter?.nft_id)?.length < 1) {
                        delete prevFilter["nft_id"];
                        return { ...prevFilter };
                      } else {
                        return { ...prevFilter, nft_id: { ...rest } };
                      }
                    });
                  }
                }}
              />
            </MainCol>
          </MainRow>
        </div>
        <div className="mt-4">
          <DropdownDiv>
            <FiltersSubHeader>Select Drops</FiltersSubHeader>
            <Form.Control
              placeholder={drops?.length > 0 ? "Select" : "No Drops Found"}
              onChange={(e) => setDropsValue(e.target.value)}
              value={dropsValue}
              onClick={() => setIsOpen(!isOpen)}
            />

            {dropsValue ? (
              <AiFillCloseCircle
                className="dropdown-arrow close"
                onClick={() => {
                  setDropsValue("");
                  setIsOpen(false);
                  // if (filter?.drop_id) {
                  //   setFilter((pre) => delete filter["drop_id"]);
                  // }
                  if (filter?.drop_id) {
                    setFilter((prevFilter) => {
                      const newFilter = { ...prevFilter };
                      delete newFilter.drop_id;
                      return newFilter;
                    });
                  }
                }}
              />
            ) : (
              <IoIosArrowDown className="dropdown-arrow" />
            )}

            {isOpen && drops?.length > 0 && (
              <div
                className="drops-dropdown"
                ref={dropdownRef}
                onMouseEnter={() => setMouseHovered(true)}
                onMouseLeave={() => setMouseHovered(false)}
              >
                {dropsValue?.length > 0 ? (
                  <>
                    {filteredData.length === 0 ? (
                      <p
                        style={{
                          color: theme === "light" ? "#6B6B6B" : "#FBFBFC",
                        }}
                      >
                        No data available
                      </p>
                    ) : (
                      filteredData.map((item, index) => (
                        <div
                          className="content-wrapper"
                          onClick={() => handleDropdownClick(item)}
                        >
                          <div>
                            <Image
                              src={item.image}
                              height={40}
                              width={40}
                              style={{ borderRadius: "20px" }}
                            />
                          </div>
                          <div>
                            <h2 className="top-text">{item.name}</h2>
                            <p className="bottom-text">
                              Schema Name
                              {/* {item.text} */}
                            </p>
                          </div>
                        </div>
                      ))
                    )}
                  </>
                ) : (
                  <>
                    {drops?.map((item, index) => (
                      <div
                        key={index}
                        className="content-wrapper"
                        onClick={() => handleDropdownClick(item)}
                      >
                        <div>
                          <Image
                            src={item.image}
                            height={40}
                            width={40}
                            style={{ borderRadius: "20px" }}
                          />
                        </div>
                        <div>
                          <h2 className="top-text">{item.name}</h2>
                          <p className="bottom-text">
                            {item?.pack_id?.pack_slots?.length > 0 &&
                              item?.pack_id?.pack_slots[0]?.template_id
                                ?.schema_id?.schema_name}
                          </p>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            )}
          </DropdownDiv>
        </div>
      </div>
    </FiltersWrapper>
  );
}

export default FilterComp;
