import { createSlice } from "@reduxjs/toolkit";

export const configDataSlice = createSlice({
  name: "config data slice",
  initialState: {
    networkConfig: null,
  },
  reducers: {
    setNetworkConfig: (state, action) => {
      return {
        ...state,
        networkConfig: action.payload,
      };
    },
  },
});

export const { setNetworkConfig } = configDataSlice.actions;
export const configDataReducer = configDataSlice.reducer;
