import React, { useRef, useState } from "react";
import "components/common/datatable/datatable.css";
import { InputText } from "primereact/inputtext";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";

import * as N from "components/common/datatable/dataTableElements";
import { CommonUtility } from "utility";

const ReportsTable = ({ data }) => {
  const dt = useRef(null);
  const [globalFilter, setGlobalFilter] = useState(null);

  const header = (
    <div className="table-header">
      <h5 className="p-mx-0 p-my-1">Reports</h5>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
        />
      </span>
    </div>
  );

  return (
    <N.MainDiv className="datatable-crud-demo">
      <N.MainCard className="card">
        <N.DataTables
          ref={dt}
          value={data ? data : []}
          dataKey="id"
          paginator
          globalFilter={globalFilter}
          rows={10}
          rowsPerPageOptions={[5, 10, 25]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Users"
          header={header}
          responsiveLayout="scroll"
        >
          <N.Columns
            field="nft_id"
            header="Token Id"
            sortable
            body={(data) => CommonUtility.deStructureNftId(data.nft_id).tokenId}
            style={{ minWidth: "12rem" }}
          ></N.Columns>
          <N.Columns
            field="nft_id"
            header="Token Address"
            sortable
            body={(data) =>
              CommonUtility.addressConvertor(
                CommonUtility.deStructureNftId(data.nft_id).tokenAddress
              )
            }
            style={{ minWidth: "12rem" }}
          ></N.Columns>
          <N.Columns
            field="title"
            header="Title"
            sortable
            style={{ minWidth: "16rem" }}
          ></N.Columns>
          <N.Columns
            field="message"
            header="Message"
            sortable
            style={{ minWidth: "10rem" }}
          ></N.Columns>
          <N.Columns
            field="wallet_address"
            header="Wallet Address"
            sortable
            body={(data) => CommonUtility.addressConvertor(data.wallet_address)}
            style={{ minWidth: "10rem" }}
          ></N.Columns>
        </N.DataTables>
      </N.MainCard>
    </N.MainDiv>
  );
};

export default ReportsTable;
