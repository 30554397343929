import styled from "styled-components";

// socialIcons
export const SocailMain = styled.div`
  display: flex;
  /* gap: 0.2rem; */
  margin-top: 1.5rem;
  &:nth-child(4) {
    border-right: none;
  }
  @media (max-width:767px) {
    justify-content: center;
    margin-bottom: 2rem;
  }
`;
export const SocialLiDiv = styled.div`
  border: 1px solid black;
  padding: 0.2rem 0.8rem;
  font-size: 20px;

  border: 1px solid rgb(229, 232, 235);
  border-right: none;
  cursor: pointer;

  &:nth-child(1) {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
`;