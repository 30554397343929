import React, { useEffect } from "react";
import { CategoryTable } from "components";
import { Loader } from "components/common";
import { GetCategoriesHook, AuthenticateHook } from "hooks";
import { useNavigate } from "react-router-dom";

const CategoriesListing = () => {
  const navigate = useNavigate();
  const { error } = AuthenticateHook();
  const { categories, cateLoading, cateError } = GetCategoriesHook();

  // useEffect(() => {
  //     error && navigate("/admin-login");
  //   }, [error]);

  return (
    <div>
      {cateLoading ? (
        <Loader content="Loading" />
      ) : categories ? (
        <CategoryTable />
      ) : (
        ""
      )}
    </div>
  );
};

export default CategoriesListing;
