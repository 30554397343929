import { MdClose } from "react-icons/md";
import {
  FormContainer,
  HeaderTitle,
  LastDiv,
  ListBtn,
  ModalWrapper,
} from "./elements";
import { Form, Image } from "react-bootstrap";
import { useAppSelector } from "store/store";
import { useEffect, useState } from "react";
import { ToastMessage } from "components/common";
import { CreateOrderHook } from "hooks/mintHook";
import Loading from "components/common/loader";
import { CommonUtility } from "utility";
import { GetTokenPriceInUsd } from "utility/usdPrice";

const SaleModal = ({
  handleCancel,
  nftData,
  getNftsByUser,
  amount,
  setAmount,
}) => {
  const { web3, account, chainId, signer, swapSdk, paymentTokens } =
    useAppSelector((state) => state.web3Connect);
  const { createOrder } = CreateOrderHook();
  const [usdPrice, setUsdPrice] = useState(Number);

  const { config } = useAppSelector((state) => state.configData);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      const price = await GetTokenPriceInUsd(config, chainId);
      setUsdPrice(+price);
    };

    fetchData();
  }, [web3, chainId]);
  const handleListing = async () => {
    try {
      let userSigner = signer;
      let nftSwapSdk = swapSdk;

      setLoading(true);

      const fee = +amount * (config?.NFT_LISTING_FEE / 100);
      const feeInWei = CommonUtility.convertToWei(
        fee,
        paymentTokens[0].decimals
      );
      // new logic for fee deduction
      const NFT_LISTING_FEE_RECEIVERS =
        config?.NFT_LISTING_FEE_RECEIVERS[chainId];
      const NFT_LISTING_FEE_PERCENTAGES =
        config?.NFT_LISTING_FEE_PERCENTAGES[chainId];
      const feeObjects = NFT_LISTING_FEE_RECEIVERS.map((receiver, index) => ({
        recipient: receiver,
        amount: CommonUtility.convertToWei(
          (NFT_LISTING_FEE_PERCENTAGES[index] / 100) * fee,
          paymentTokens[0].decimals
        ),
      }));

      //
      const actualListingPrice = +amount - fee;
      const actualListingPriceInWei = CommonUtility.convertToWei(
        actualListingPrice,
        paymentTokens[0].decimals
      );

      const TAKER_ASSET = {
        tokenAddress: paymentTokens[0].address,
        amount: actualListingPriceInWei,
        type: "ERC20",
      };
      const nftAddress = config.STAR_SHIP_ADDRESS;
      const MAKER_ASSET = {
        tokenAddress: nftAddress[Number(chainId)],
        tokenId: nftData.nft_id,
        type: "ERC721",
      };

      const walletAddressUserA = await userSigner?.getAddress();

      const assetsToSwapMaker = [MAKER_ASSET];
      const assetsToSwapTaker = [TAKER_ASSET];

      // approving makerAsset to swapSdk
      const approvalStatusForUserA = await nftSwapSdk?.loadApprovalStatus(
        assetsToSwapMaker[0],
        walletAddressUserA
      );

      // If we do need to approve makers assets for swapping, do that
      if (!approvalStatusForUserA.contractApproved) {
        const approvalTx = await nftSwapSdk.approveTokenOrNftByAsset(
          assetsToSwapMaker[0],
          walletAddressUserA
        );
        const approvalTxReceipt = await approvalTx.wait();
      }

      let order = await nftSwapSdk.buildOrder(
        assetsToSwapMaker[0],
        assetsToSwapTaker[0],
        walletAddressUserA,
        {
          fees: feeObjects,
        }
      );
      // Signing the order using the maker's wallet address
      const signedOrder = await nftSwapSdk.signOrder(
        order,
        walletAddressUserA,
        signer
      );
      if (signedOrder) {
        const obj = {
          id: nftData?._id,
          signedOrder,
        };

        const res = await createOrder(obj);
      }
      handleCancel();
      await getNftsByUser(account);
      ToastMessage("Success", "Nft listed on marketplace", "Success");
      setLoading(false);
    } catch (err) {
      console.log("err", err);
      setLoading(false);
    }
  };
  return (
    <ModalWrapper>
      {loading && <Loading content={"Listing"} />}

      <div className="d-flex justify-content-between align-items-center">
        <HeaderTitle>Market List</HeaderTitle>
        <MdClose
          cursor="pointer"
          fontSize={22}
          color="#6B6B6B"
          className="close-icon"
          onClick={() => {
            handleCancel();
          }}
        />
      </div>

      <div
        className="main-div mt-4"
        style={{
          borderBottom: "0.5px solid #E7E7E7",
          paddingBottom: "1.5rem",
        }}
      >
        <div className="first-div">
          <Image
            src={nftData?.image}
            width={42}
            height={42}
            style={{ borderRadius: "50px" }}
          />
          <div>
            <h4>
              {
                nftData?.attributes?.find(
                  (obj) => obj?.attribute_type?.toLowerCase() === "name"
                )?.attribute_value
              }
            </h4>
            <p>
              {" "}
              # {""}
              {nftData?.nft_id}
            </p>
          </div>
        </div>
        <div>
          <h4 className="listing-text">Listing price per NFT</h4>
          <p className="srt-text">
            {amount ? amount + " " + paymentTokens[0]?.symbol || 0 : "--"}
            <br />
            <small> {usdPrice > 0 ? usdPrice * amount : 0} USD</small>
          </p>
        </div>
      </div>

      <FormContainer>
        <Form.Label>Set a price</Form.Label>
        <div className="input-div">
          <Form.Control
            type="number"
            min={0}
            value={amount}
            placeholder="Amount"
            onChange={(e) => setAmount(e.target.value)}
          />
          <Form.Text>{paymentTokens[0]?.symbol}</Form.Text>
        </div>
      </FormContainer>

      <LastDiv className="mt-4">
        <h5>listing price</h5>
        <p>
          {+amount > 0 ? amount : 0} {paymentTokens[0]?.symbol}
        </p>
      </LastDiv>
      <LastDiv>
        <h5>starship fee</h5>
        <p>
          {config?.NFT_LISTING_FEE}
          {" %"}
        </p>
      </LastDiv>
      <LastDiv>
        <h5>total potential earning</h5>
        <p>
          {+amount - +amount * (config?.NFT_LISTING_FEE / 100)}{" "}
          {paymentTokens[0]?.symbol}
        </p>
      </LastDiv>

      <ListBtn
        onClick={() => handleListing()}
        disabled={!amount || amount <= 0}
      >
        list on market
      </ListBtn>
    </ModalWrapper>
  );
};

export default SaleModal;
