import styled from "styled-components";

import { Row, Col, Container, Card, Button, Form } from "react-bootstrap";
import { Input, Upload } from "antd";

export const NftFormContainer = styled(Container)`
  font-family: "Roboto", sans-serif !important;
  margin-bottom: 40px;
`;

export const NftFormRow = styled(Row)``;
export const NftFormCol = styled(Col)``;

export const NftFormTitle = styled.h2`
  margin-top: 5rem;
  margin-bottom: 2rem;

  font-family: "Anton";
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  line-height: 100%;
  text-transform: uppercase;
  color: #3e3e3e;
  margin: 3rem 0rem 1rem 0;

  @media (max-width: 575.98px) {
    text-align: center;
  }
`;
export const NftForms = styled(Form)``;
export const InputValue = styled(Input)``;

export const RequiredLabel = styled.label`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #333333;
  margin-top: 30px;
  font-family: "Roboto", sans-serif !important;

  & span {
    color: red;
    margin-right: 0.19rem;
  }
`;
export const SecondRequiredLabel = styled.label`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #333333;
  font-family: "Roboto", sans-serif !important;

  & span {
    color: red;
    margin-right: 0.19rem;
  }
`;
export const ErrorMessage = styled.p`
  text-align: start;
  color: red;
  margin-bottom: 0px;
  font-family: "Roboto", sans-serif !important;
`;
export const NameLabel = styled.label`
  font-size: 14px;
  color: white;
  font-family: "Roboto", sans-serif !important;

  & span {
    color: red;
  }
`;

export const NftUpload = styled(Upload)`
  .ant-upload.ant-upload-select-picture-card {
    width: 201px;
    height: 175px;
  }
`;
export const NftUploadLabel = styled.h5`
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 22px;
  font-family: "Roboto", sans-serif !important;
  /* identical to box height, or 92% */

  /* Gray 1 */

  color: #333333;
  margin-top: 20px;

  & span {
    color: red;
  }
`;
export const NftUploadInfo = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  /* Gray 1 */

  color: #333333;
  margin-top: 10px;
  font-family: "Roboto", sans-serif !important;
`;

export const AddBtn = styled.button`
  margin-top: 3rem;
  padding: 0.5rem 1rem;
  background: linear-gradient(98.6deg, #261ce8 6.03%, #511ce8 99.87%);
  border-radius: 4px;
  border: none;
  color: #fff;
  font-size: 20px;
`;

export const BottomBtn = styled.button`
  margin-top: 3rem;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px 16px;
  background: #d35400;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 5px;
  border: none;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;

  @media (max-width: 575.98px) {
    margin-left: auto;
    margin-right: auto;
  }
`;

export const PropertiesMain = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 2.5rem;
  font-size: 18px;
  line-height: 22px;
  font-family: "Roboto", sans-serif !important;
  color: #333333;
`;
export const PropertiesDetails = styled.div`
  font-weight: 500;

  & span {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height, or 110% */
    color: #4f4f4f;
  }
`;

export const SaveBtn = styled.button`
background: linear-gradient(90deg, rgba(0, 170, 255, 1) 0%, rgba(116, 198, 238, 1) 52%, rgba(0, 212, 255, 1) 100%);
  border-radius: 4px;
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 100%;
  letter-spacing: -0.05em;
  color: #ffffff;
  margin: 1rem 0 3rem 0;
  padding: 10px 20px;
  margin-top: 2rem;

  @media (max-width: 575.98px) {
    display: block;
    margin: 2rem auto 2rem auto;
  }
`;
