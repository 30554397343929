import { APIPath } from "utility/constant";
import { BaseService } from "./baseServices";

class User {
  signinSignup = (data) => {
    return BaseService.post(APIPath.signinSignup, data);
  };
  updateUser = (wallet_address, data) => {
    return BaseService.put(
      `${APIPath.updateUserProfile}/${wallet_address}`,
      data
    );
  };
}

const UserService = new User();
Object.freeze(UserService);
export { UserService };
