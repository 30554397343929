import { useEffect, useState } from "react";
import { CommonHook } from "./commonHook";
import { MintService } from "utility/services/mintServices";
import { CommonUtility } from "utility";

export const GetNftsByUserHook = (account) => {
  const { data, setData, setError, loading, setLoading, error } = CommonHook();
  const [schemas, setSchema] = useState([]);
  const [drops, setDrops] = useState([]);
  const getNftsByUser = async (account) => {
    try {
      setLoading(true);
      const result = await MintService.nftsByUser(account);
      if (result.data) {
        let temp = CommonUtility.decrypt(result.data);
        temp = JSON.parse(temp);
        setData(temp);
        let schema = temp
          ?.filter((x) => x.schema_name)
          .map((x) => {
            return { schema_name: x.schema_name };
          });

        const uniqueSchemaNamesSet = new Set();

        schema = schema?.filter((item) => {
          if (!uniqueSchemaNamesSet.has(item.schema_name)) {
            uniqueSchemaNamesSet.add(item.schema_name);
            return true;
          }
          return false;
        });

        let drop = temp
          .filter((x) => x.drop_id !== null)
          .map((x) => {
            return {
              _id: x?.drop_id?._id,
              name: x?.drop_id?.name,
              image: x?.drop_id?.image,
            };
          });
        const uniqueDropNamesSet = new Set();

        // Filter out duplicate drops and keep only unique ones
        drop = drop.filter((item) => {
          if (!uniqueDropNamesSet.has(item.name)) {
            uniqueDropNamesSet.add(item.name);
            return true;
          }
          return false;
        });

        setSchema(schema);
        setDrops(drop);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (account) getNftsByUser(account);
  }, [account]);

  return {
    getNftsByUser,
    data,
    loading,
    error,
    setData,
    schemas,
    drops,
  };
};

export const GetMintsHook = (page, filter) => {
  const { data, setData, setError, loading, setLoading, error } = CommonHook();
  const [total, setTotal] = useState(0);
  const [random, setRandom] = useState(0);
  const getMints = async (page, filter) => {
    try {
      setLoading(true);
      const result = await MintService.getMints(page, JSON.stringify(filter));
      if (result.data) {
        let temp = CommonUtility.decrypt(result.data);
        temp = JSON.parse(temp);
        setData(temp);
        setTotal(result.total);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  const refresh = () => {
    setRandom(Math.random());
  };
  useEffect(() => {
    setData(null);
    getMints(page, filter);
  }, [page, filter, random]);

  return {
    getMints,
    data,
    loading,
    error,
    setData,
    total,
    refresh,
  };
};

export const CreateOrderHook = () => {
  const { data, setData, setError, loading, setLoading, error } = CommonHook();
  const createOrder = async (body) => {
    try {
      setLoading(true);
      const jsonData = JSON.stringify(body);
      let encrypted = CommonUtility.encrypt(jsonData);
      const result = await MintService.createOrder(encrypted);
      if (result.data) {
        setData(result.data);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    createOrder,
    data,
    loading,
    error,
    setData,
  };
};

export const CreateOfferHook = () => {
  const { data, setData, setError, loading, setLoading, error } = CommonHook();
  const createOffer = async (body) => {
    try {
      setLoading(true);
      const jsonData = JSON.stringify(body);
      let encrypted = CommonUtility.encrypt(jsonData);
      const result = await MintService.createOffer(encrypted);
      if (result.data) {
        setData(result.data);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    createOffer,
    data,
    loading,
    error,
    setData,
  };
};

export const DelistHook = () => {
  const { data, setData, setError, loading, setLoading, error } = CommonHook();
  const delist = async (id) => {
    try {
      setLoading(true);
      const result = await MintService.delist(id);
      if (result.data) {
        setData(result.data);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    delist,
    data,
    loading,
    error,
    setData,
  };
};

export const GetMintsMostSoldHook = () => {
  const { data, setData, setError, loading, setLoading, error } = CommonHook();
  const [total, setTotal] = useState(0);
  const getMintsMostSold = async () => {
    try {
      setLoading(true);
      const result = await MintService.getMintsMostSold();
      if (result.data) {
        let temp = CommonUtility.decrypt(result.data);
        temp = JSON.parse(temp);
        setData(temp);
        setTotal(result.total);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getMintsMostSold();
  }, []);

  return {
    getMintsMostSold,
    data,
    loading,
    error,
    setData,
    total,
  };
};

export const DeclineOfferHook = () => {
  const { data, setData, setError, loading, setLoading, error } = CommonHook();
  const decline = async (wallet, srNo) => {
    try {
      setLoading(true);

      const obj = {
        address: wallet,
        id: srNo,
      };
      const jsonData = JSON.stringify(obj);
      let encrypted = CommonUtility.encrypt(jsonData);
      const result = await MintService.decline(encrypted);
      if (result.data) {
        setData(result.data);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    decline,
    data,
    loading,
    error,
    setData,
  };
};
export const GetAllMintedHook = () => {
  const { data, setData, setError, loading, setLoading, error } = CommonHook();

  const getAllMints = async () => {
    try {
      setLoading(true);
      const result = await MintService.getAllMints();
      if (result.data) {
        let temp = CommonUtility.decrypt(result.data);
        temp = JSON.parse(temp);
        setData(temp);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getAllMints();
  }, []);
  return {
    getAllMints,
    data,
    loading,
    error,
    setData,
  };
};

export const GetNftsByHash = (hash) => {
  const { data, setData, setError, loading, setLoading, error } = CommonHook();

  const getNftByHash = async () => {
    try {
      setLoading(true);
      const result = await MintService.nftsByHash(hash);
      if (result.data) {
        let temp = CommonUtility.decrypt(result.data);
        temp = JSON.parse(temp);
        setData(temp);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getNftByHash();
  }, []);
  return {
    getNftByHash,
    data,
    loading,
    error,
    setData,
  };
};

export const GetCountMintOccurencesHook = () => {
  const { data, setData, setError, loading, setLoading, error } = CommonHook();
  const countMintOccurences = async (body) => {
    try {
      setLoading(true);
      const jsonData = JSON.stringify(body);
      let encrypted = CommonUtility.encrypt(jsonData);
      const result = await MintService.countOccurences(encrypted);
      if (result.data) {
        let temp = CommonUtility.decrypt(result.data);
        temp = JSON.parse(temp);
        setData(temp);
        return temp;
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    countMintOccurences,
    data,
    loading,
    error,
    setData,
  };
};
