import env from "../../environment";
import { create as ipfsHttpClient } from "ipfs-http-client";
import { ThirdwebStorage } from "@thirdweb-dev/storage";
import { useStorageUpload } from "@thirdweb-dev/react";

export const sendFileToIPFS = async (file) => {
  if (file) {
    try {
      const authorization =
        "Basic " + btoa(env.PROJECT_ID + ":" + env.PROJECT_SECRET);
      const ipfs = ipfsHttpClient({
        url: env.INFURA,
        headers: {
          authorization,
        },
      });
      const result = await ipfs.add(file);
      const ImgHash = `${env.IPFS_PATH}/${result.path}`;

      return ImgHash;
    } catch (error) {
      console.log("Error sending File to IPFS: ");
      console.log(error);
    }
  }
};

export const sendFileToThirdWebIPFS = async (file,upload) => {
  if (file) {
    try {

      const dataToUpload = [file];

      // And upload the data with the upload function
      let url = await upload({ data: dataToUpload });
      url = url[0];
      url = url.replace(
        "ipfs://",
        "https://ipfs.io/ipfs/"
      )
      return url
      
    } catch (error) {
      console.log("Error sending File to IPFS: ");
      console.log("sendFileToIPFSThirdWeb",error);
    }
  }
};
