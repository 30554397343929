import { ErrorMessage } from "components/nftForm/nftFormElement";
import React from "react";
import {
  NftForms,
  FormControl,
  CopyIcon,
  InputGroups,
  MainInputField,
} from "./inputElement";

const InputField = ({
  inputLabel,
  inputLabelText,
  inputRequired,
  inputType,
  inputLinks,
  placeholder,
  inputValue,
  error,
  value,
  setValue,
  maxLength,
  disabled,
  isSellNftControl,
  isImportInput,
  onClick,
  isCopyIcon,
}) => {
  const maxLengthCheck = (object) => {
    if (maxLength && object.target.value.length > maxLength) {
      let value = object.target.value.slice(0, maxLength);
      setValue(value);
    }
  };

  return (
    <MainInputField>
      <NftForms.Group>
        <NftForms.Label>
          {" "}
          {inputLabel}
          {inputRequired ? (
            <span
              style={{ color: "red", marginLeft: "0.2rem", marginTop: "5rem" }}
            >
              *
            </span>
          ) : (
            ""
          )}
        </NftForms.Label>
        <label style={{ float: "right", fontSize: "11px" }} className="label">
          {value != null && maxLength ? `${value.length} / ${maxLength}` : ""}
        </label>
        <br />
        <NftForms.Text className="text-muted">{inputLabelText}</NftForms.Text>

        {isCopyIcon ? (
          <InputGroups>
            <FormControl
              isSellNftControl={isSellNftControl}
              className={inputLinks ? "LinksInput" : "inputField"}
              type={inputType}
              disabled={disabled}
              onChange={(e) => {
                setValue(e);
                maxLengthCheck(e);
              }}
              value={value}
              placeholder={placeholder}
            ></FormControl>
            <InputGroups.Text>
              <div onClick={onClick}>
                <CopyIcon color="black" />
              </div>
            </InputGroups.Text>
          </InputGroups>
        ) : (
          <FormControl
            isSellNftControl={isSellNftControl}
            isImportInput={isImportInput}
            className={inputLinks ? "LinksInput" : "inputField"}
            type={inputType}
            disabled={disabled}
            onChange={(e) => {
              setValue(e);
              maxLengthCheck(e);
            }}
            value={value}
            placeholder={placeholder}
          ></FormControl>
        )}

        {/* {isCopyIcon && (
          <div onClick={onClick}>
            <CopyIcon />
          </div>
        )} */}
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </NftForms.Group>
    </MainInputField>
  );
};

export default InputField;
