import { CommonConstant, Protocols } from "utility";
import { marketplaceContractAbi } from "contract/marketplace";
import { AbiItem } from "web3-utils";

export class ContractUtility {
  static config = {};
  static getNetworkText(network) {
    switch (network) {
      case "eth":
      case "goerli":
        return "Ethereum (ETH)";

      case "bsc":
      case "bsc testnet":
        return "Binance Smart Chain (BSC)";

      case "polygon":
        return "Polygon";
      case "mumbai":
        return "Polygon Mumbai";
      case "avax":
        return "Avalanche";
      default:
        return "";
    }
  }
  static getSymbol(network) {
    switch (network) {
      case "eth":
      case 1:
      case 5:
      case 42161:
      case "goerli":
        return "eth";
      case "bsc":
      case 97:
      case 38:
      case "bsc testnet":
        return "bnb";
      case "polygon":
      case "mumbai":
      case 89:
      case 80001:
        return "MATIC";
      case "avax":
        return "avax";
      default:
        return "";
    }
  }
  static getNetworkRpc(network) {
    switch (network) {
      case "eth":
        return "https://mainnet.infura.io/v3/";
      case "goerli":
        return "https://goerli.infura.io/v3/2b2b802ce8414591a6c76a30cf192ad3";
      case "bsc":
        return "https://speedy-nodes-nyc.moralis.io/482892835df3520a7ee8bb72/bsc/mainnet";
      case "bsc testnet":
      case "97":
        return "https://data-seed-prebsc-1-s3.binance.org:8545/";
      case "mumbai":
        return "https://polygon-mumbai.g.alchemy.com/v2/Ct1C16FFAJ-wN6RBSzyxkbyM8Z8X9y1a";
      case "polygon":
        return "https://polygon-rpc.com/";
      case "sepolia":
        return "https://sepolia.infura.io/v3/";
      case "eth mainnet":
        return "https://mainnet.infura.io/v3/2b2b802ce8414591a6c76a30cf192ad3";
      default:
        return "";
    }
  }
  static getVersionParam = () => {
    let url = new URL(window.location.href);
    let version = url.searchParams.get("v");
    return version;
  };
  static version = ContractUtility.getVersionParam() || "v";

  static setConfig = (config) => {
    ContractUtility.config = { ...config };
  };

  static getContractAddress = (chainId) => {
    switch (chainId) {
      case "1":
        return ContractUtility.config.ethereum;
      case "137":
        return ContractUtility.config.polygon;
      default:
        return {};
    }
  };

  static getPaymentMethods = (protocol) => {
    switch (protocol) {
      case Protocols.ethereum.name:
      case Protocols.bsc.name:
      case Protocols.polygon.name:
        return ContractUtility.config[protocol].payment_methods;
      default:
        return [];
    }
  };

  static getMoralisKeys = (chainId) => {
    switch (chainId) {
      case "1":
      case "0x1":
      case "137":
        return {
          serverUrl: "https://im5edb4xnfxc.usemoralis.com:2053/server",
          appId: "O8QrWqOWXUypTAMKhNd5nj1qVQymXfu71edNCKgL",
          chain: chainId == "0x1" ? "ethereum" : "polygon",
        };
      case "5":
        return {
          serverUrl: "https://kodzkxzmdrce.usemoralis.com:2053/server",
          appId: "AZbnPhH6KAFCIDTOAr7YWTr4CdLikf9ujqgwKHis",
          chain: "rinkeby",
        };
      default:
        return {};
    }
  };

  static getNetwork = (netId) => {
    switch ((netId || "").toString()) {
      case "1":
      case "0x1":
      case 1:
        return "ethereum";
      case "5":
      case "0x5":
      case 5:
        return "goerli";
      case "2":
      case "0x2":
        return "morden";
      case "3":
      case "0x3":
        return "ropsten";
      case "4":
      case "0x4":
        return "rinkeby";
      case "42":
      case "0x42":
        return "Kovan";
      case "13881":
      case "0x13881":
      case 80001:
      case "80001":
      case "0x80001":
      case "0x13881":
        return "Mumbai";
      case "89":
      case "0x89":
      case "137":
        return "polygon";
      case "38":
      case "0x38":
      case "56":
      case "0x56":
        return "bsc";
      case "61":
      case "0x61":
      case "97":
      case "0x97":
        return "bsc testnet";
      default:
        return "Unknown";
    }
  };

  static getMoralisSymbol = (netId) => {
    switch ((netId || "").toString()) {
      case "1":
      case "0x1":
        return "eth";
      case "2":
      case "0x2":
        return "morden";
      case "3":
      case "0x3":
        return "ropsten";
      case "4":
      case "0x4":
        return "rinkeby";
      case "42":
      case "0x42":
        return "Kovan";
      case "13881":
      case "0x13881":
      case "80001":
      case "0x80001":
        return "Mumbai";
      case "89":
      case "0x89":
      case "137":
        return "polygon";
      case "38":
      case "0x38":
      case "56":
      case "0x56":
        return "bsc";
      case "61":
      case "0x61":
      case "97":
      case "0x97":
        return "bsc testnet";
      default:
        return "Unknown";
    }
  };

  static getChainXId(chainId) {
    switch ((chainId || "").toString()) {
      case "1":
        return "0x1";
      case "5":
        return "0x5";
      case "137":
        return "0x89";
      case "80001":
        return "0x13881";
      case "89":
        return "0x89";
      case "97":
        return "0x61";
      case "38":
        return "0x38";
      case "42161":
        return "0xa4b1";
      default:
        return null;
    }
  }

  static getNetworkExplorer = (network) => {
    switch (network) {
      case "Main":
      case "1":
      case 1:
        return "https://etherscan.io";
      case "goerli":
      case "5":
      case 5:
        return "https://goerli.etherscan.io/";
      case "bsc":
      case "38":
      case "56":
      case 56:
      case "0x38":
        return "https://bscscan.com";
      case "bsc testnet":
      case "61":
      case "97":
        return "https://testnet.bscscan.com";
      case "polygon":
      case "137":
        return "https://polygonscan.com/";
      case "mumbai":
      case "Mumbai":
      case "13881":
      case "80001":
      case 80001:
        return "https://mumbai.polygonscan.com/";
    }
  };

  static getOwnerAddress = async (tokenId, contractAddress, web3) => {
    const contract = new web3.eth.Contract(
      marketplaceContractAbi,
      contractAddress
    );
    const ownerAddress = await contract?.methods.ownerOf(tokenId).call();
    return ownerAddress;
  };

  static getChainId(protocol) {
    switch (protocol) {
      case Protocols.ethereum.name:
      case Protocols.bsc.name:
      case Protocols.polygon.name:
        return Protocols[protocol].chainId[
          CommonConstant.mode === "PRODUCTION" ? "mainnet" : "testnet"
        ];
      default:
        return null;
    }
  }
  static getPaymentToken = (value, chainId) => {
    const list = ContractUtility.getPaymentList(chainId);
    const token = list.filter((item) => {
      return item.address.toLowerCase() === value.toLowerCase();
    });
    return token[0];
  };
  static getPaymentTokenBySymbol = (symbol, chainId) => {
    const list = ContractUtility.getPaymentList(chainId);
    const token = list.filter((item) => {
      return item.symbol === symbol;
    });
    return token[0];
  };
  static getPaymentTokenList = (chainId) => {
    return ContractUtility.getPaymentList(chainId);
  };

  static getPaymentList = (chainId) => {
    switch (chainId) {
      case "11155111":
      case 11155111:
      case "sepolia":
        return [
          {
            name: "Starship Coin",
            symbol: "STC",
            address: "0x7244Ae409eD0580e1071C64CD28DbF6b3D57F407",
            decimals: 18,
            index: 1,
          },
          {
            name: "USDC",
            symbol: "USDC",
            address: "0x66C9cA6e60FFfFefa60Df8fF5435802c1E5e5751",

            decimals: 6,
            index: 2,
          },
          {
            name: "USDT",
            symbol: "USDT",
            address: "0x8700A02D571Eee37c80E9eBe743e25e4CA4777C0",

            decimals: 6,
            index: 3,
          },
        ];
      case "5":
      case 5:
      case "goerli":
        return [
          {
            name: "StarShip",
            symbol: "STARSHIP",
            address: "0x27789b8FbA029644D8c985F382Ff4b76f43EF20C",
            decimals: 9,
            index: 1,
          },
          // {
          //   name: "USD",
          //   symbol: "USD",
          //   address: "0x1865b931a505BB404A63731E92fDf24260e441aA",

          //   decimals: 6,
          //   index: 2,
          // },
        ];
      case "80001":
      case 80001:
      case "mumbai":
        return [
          {
            name: "Starship Token",
            symbol: "STK",
            address: "0xe607Aa3e69Db636205DD8B977d9e6eD9008c97aB",
            decimals: 9,
            index: 1,
          },
          // {
          //   name: "USDC",
          //   symbol: "USDC",
          //   address: "0x66C9cA6e60FFfFefa60Df8fF5435802c1E5e5751",

          //   decimals: 6,
          //   index: 2,
          // },
          // {
          //   name: "USDT",
          //   symbol: "USDT",
          //   address: "0x8700A02D571Eee37c80E9eBe743e25e4CA4777C0",

          //   decimals: 6,
          //   index: 3,
          //},
        ];
      case 97:
      case "97":
      case "binance-testnet":
        return [
          {
            name: "BNB",
            symbol: "BNB",
            address: "0x0000000000000000000000000000000000000000",

            decimals: 18,
          },
          {
            name: "WETH",
            symbol: "WETH",
            address: "0x0000000000000000000000000050000000000000",

            decimals: 18,
          },

          {
            name: "USDC",
            symbol: "USDC",
            address: "0xC02081C90E2D95808C606129CafecF7c80758093",

            decimals: 6,
          },
          {
            name: "USDT",
            symbol: "USDT",
            address: "0xD8c0214098447dF7758E108b8431d3694bDc603A",

            decimals: 6,
          },
        ];
      case "56":
      case 56:
      case "binance":
        return [
          {
            name: "StarShip",
            symbol: "STARSHIP",
            address: "0x52419258E3fa44DEAc7E670eaDD4c892B480A805",
            decimals: 9,
            index: 1,
          },
          {
            name: "USD",
            symbol: "USD",
            address: "0x55d398326f99059fF775485246999027B3197955",
            decimals: 6,
            index: 2,
          },
        ];
      case 38:
      case "0x38":
      case "binance":
        return [
          {
            name: "BNB",
            symbol: "BNB",
            address: "0x0000000000000000000000000000000000000000",

            decimals: 18,
          },
          {
            name: "WETH",
            symbol: "WETH",
            address: "0x0000000000000000000000000050000000000000",

            decimals: 18,
          },

          {
            name: "USDC",
            symbol: "USDC",
            address: "0xC02081C90E2D95808C606129CafecF7c80758093",

            decimals: 6,
          },
          {
            name: "USDT",
            symbol: "USDT",
            address: "0xD8c0214098447dF7758E108b8431d3694bDc603A",

            decimals: 6,
          },
        ];

      default:
        return [];
    }
  };
}
