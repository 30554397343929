import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CreateSchemaService } from "utility/services/createSchemaServices";
import { CommonHook } from "./commonHook";
import { ToastMessage } from "components/common";
import { CommonUtility } from "utility";

export const CreateSchemaHook = () => {
  const navigate = useNavigate();
  const { data, setData, setError, loading, setLoading, error } = CommonHook();

  const createSchema = async (obj) => {
    try {
      setLoading(true);
      const jsonData = JSON.stringify(obj);
      let encrypted = CommonUtility.encrypt(jsonData);
      const result = await CreateSchemaService.createSchema(encrypted);
      if (result.data) {
        setData(result.data);

        if (!obj?.update_schema) {
          ToastMessage("Success!", "Schema Created Successfully", "success");
        } else
          ToastMessage("Success!", "Schema Updated Successfully", "success");

        navigate("/starship/admin/template?id=" + result.data?._id);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    createSchema,
    data,
    loading,
    error,
  };
};

export const GetCreateSchemaHook = () => {
  const { data, setData, setError, loading, setLoading, error } = CommonHook();

  const getCreateSchema = async () => {
    try {
      setLoading(true);
      const result = await CreateSchemaService.getCreateSchema();
      if (result.data) {
        let temp = CommonUtility.decrypt(result.data);
        temp = JSON.parse(temp);
        setData(temp);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getCreateSchema();
  }, []);

  return {
    getCreateSchema,
    data,
    loading,
    error,
  };
};

export const GetCreateSchemaByIdHook = (id) => {
  const { data, setData, setError, loading, setLoading, error } = CommonHook();

  const getCreateSchemaById = async (id) => {
    try {
      setLoading(true);
      const result = await CreateSchemaService.getCreateSchemaById(id);
      if (result.data) {
        let temp = CommonUtility.decrypt(result.data);
        temp = JSON.parse(temp);
        setData(temp);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    id && getCreateSchemaById(id);
  }, [id]);

  return {
    getCreateSchemaById,
    data,
    loading,
    error,
  };
};
