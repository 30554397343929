import { InputField } from "../../components/common";
import React from "react";
import { updatePasswordValidate } from "../../components/validation/customValidator";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { setUpdatedCredentials } from "../../store/redux/slices/adminSlices/credentialsUpdateSlice";
import { ErrorMessage } from "../../components/nftForm/nftFormElement";
import { UpdatePasswordHook, useForm } from "../../hooks";

const UpdatePassword = () => {
  const { credentials } = useAppSelector((state) => state.credentials);
  const { updateLoading, updatePass, updateError } = UpdatePasswordHook();
  const dispatch = useAppDispatch();
  const { handleSubmit, errors } = useForm(
    updatePass,
    updatePasswordValidate,
    credentials
  );

  return (
    <div>
      <h3>Update Password</h3>
      <InputField
        value={credentials.current_password}
        inputLabel="Current Password"
        error={errors.current_password}
        placeholder="current password"
        setValue={(e) =>
          dispatch(
            setUpdatedCredentials({
              ...credentials,
              current_password: e.target.value,
            })
          )
        }
        inputType="password"
      />
      {updateError?.message === "Incorrect old password" && (
        <ErrorMessage>{updateError.message}</ErrorMessage>
      )}
      <InputField
        value={credentials.new_password}
        inputLabel="New  Password"
        error={errors.new_password}
        placeholder="new password"
        setValue={(e) =>
          dispatch(
            setUpdatedCredentials({
              ...credentials,
              new_password: e.target.value,
            })
          )
        }
        inputType="password"
      />
      <InputField
        value={credentials.confirm_password}
        inputLabel="Confirm Password"
        error={errors.confirm_password}
        placeholder="confirm password"
        setValue={(e) =>
          dispatch(
            setUpdatedCredentials({
              ...credentials,
              confirm_password: e.target.value,
            })
          )
        }
        inputType="password"
      />
      <br />
      <button
        disabled={updateLoading ? true : false}
        onClick={handleSubmit}
        type="button"
      >
        {updateLoading ? "updateing..." : "update password"}
      </button>
    </div>
  );
};

export default UpdatePassword;
