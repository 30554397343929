import { BreadCrumbComp, MainContainer } from "components/common";
import { DropsWrapper } from "./element";
import { TabContainer } from "../packs/element";
import { Tabs } from "antd";
import Dropcomp from "./dropcomp";

const data = [
  {
    id: 1,
    text: "Starship NFT Game",
    link: "/admin/starship",
  },
  {
    id: 2,
    text: "Drops",
    link: "",
  },
];

function DropsCom() {
  const items = [
    {
      label: "drops",
      key: "item-1",
      children: <Dropcomp />,
    },
  ];

  return (
    <DropsWrapper>
      <MainContainer fluid>
        <BreadCrumbComp data={data} btnText="New Drop" />
        <TabContainer>
          <Tabs items={items} centered />
        </TabContainer>
      </MainContainer>
    </DropsWrapper>
  );
}

export default DropsCom;
