import React, { useRef, useState } from "react";
import "components/common/datatable/datatable.css";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";

import * as N from "components/common/datatable/dataTableElements";
import { useAppSelector } from "store/store";
import { useMatch } from "react-router-dom";
import { NetworkIds } from "utility/constant/dropdowns";

const NftTable = ({ data }) => {
  const dt = useRef(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [detailDialog, setDetailDialog] = useState(false);
  const [nft, setNft] = useState();
  let match = useMatch("minted-nfts");

  const imageBodyTemplate = (rowData) => {
    return <img src={rowData.image} style={{ width: "6rem" }} />;
  };

  const ownerAddressBody = (rowData) => {
    const address = `${rowData.owner_address.slice(
      0,
      6
    )}...${rowData.owner_address.slice(36, 42)}`;
    return address;
  };

  const nftDetail = (data) => {
    setNft(data);
    setDetailDialog(true);
  };

  const attributes = nft ? JSON.parse(nft.attributes) : null;
  const header = (
    <div className="table-header">
      <h5 className="p-mx-0 p-my-1">{match ? "Minted Nfts" : "Nfts"}</h5>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
        />
      </span>
    </div>
  );

  const detailDialogFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => setDetailDialog(false)}
      />
    </React.Fragment>
  );

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button className=" p-button-info" onClick={() => nftDetail(rowData)}>
          Details
        </Button>
      </React.Fragment>
    );
  };

  return (
    <N.MainDiv className="datatable-crud-demo">
      <N.MainCard className="card">
        <N.DataTables
          ref={dt}
          value={data ? data : []}
          dataKey="id"
          paginator
          globalFilter={globalFilter}
          rows={10}
          rowsPerPageOptions={[5, 10, 25]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Users"
          header={header}
          responsiveLayout="scroll"
        >
          <N.Columns
            field="name"
            header="Name"
            sortable
            style={{ minWidth: "12rem" }}
          ></N.Columns>
          <N.Columns
            field="description"
            header="Description"
            sortable
            style={{ minWidth: "16rem" }}
          ></N.Columns>
          <N.Columns
            field="owner_address"
            header="Owner Address"
            sortable
            body={ownerAddressBody}
            style={{ minWidth: "10rem" }}
          ></N.Columns>
          <N.Columns
            field="image"
            header="Image"
            body={imageBodyTemplate}
          ></N.Columns>
          <N.Columns
            field="share_price"
            header="Price"
            sortable
            style={{ minWidth: "8rem" }}
          ></N.Columns>
          <N.Columns
            field="network_id"
            header="Network"
            sortable
            body={(data) =>
              NetworkIds.find((x) => x.value === data.network_id)?.text
            }
            style={{ minWidth: "8rem" }}
          ></N.Columns>
          <N.Columns
            body={actionBodyTemplate}
            exportable={false}
            style={{ minWidth: "8rem" }}
          ></N.Columns>
        </N.DataTables>
      </N.MainCard>
      <N.Dialogs
        visible={detailDialog}
        style={{ width: "450px" }}
        header="Nfts Details"
        modal
        className="p-fluid"
        footer={detailDialogFooter}
        onHide={() => setDetailDialog(false)}
      >
        <img src={nft?.image} width="100%" />
        <b>Name</b>: {nft?.name} <br />
        <b>Description</b>: {nft?.description} <br />
        <b>Owner Address</b>:{" "}
        <label>
          {`${nft?.owner_address.slice(0, 6)}...${nft?.owner_address.slice(
            36,
            42
          )}`}{" "}
        </label>
        <br />
        <b>Attributes</b>:
        {attributes
          ? attributes.map((item, i) => {
              return (
                <div key={i}>
                  {item.trait_type}: {item.value} <br />
                </div>
              );
            })
          : ""}
        {/* <b>Presale End time</b> {nft?.pre_sale_end_time} */}
      </N.Dialogs>
    </N.MainDiv>
  );
};

export default NftTable;
