import { DelistHook } from "hooks/mintHook";
import { ConformBtn, ModalWrapper, Title } from "./elements";
import { MdClose } from "react-icons/md";
import { useState } from "react";
import { useAppSelector } from "store/store";
import Loading from "components/common/loader";

const DelistModal = ({ handleCancel, item, getNftsByUser }) => {
  const { account } = useAppSelector((state) => state.web3Connect);
  const [isLoading, setIsLoading] = useState(false);
  const { delist } = DelistHook();
  const delistNft = async () => {
    try {
      setIsLoading(true);
      await delist(item?._id);
      handleCancel();
      await getNftsByUser(account);
      setIsLoading(false);
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
  };
  return (
    <ModalWrapper>
      {isLoading && <Loading content={"Loading"} />}

      <MdClose
        cursor="pointer"
        fontSize={22}
        color="#6B6B6B"
        className="close-icon"
        onClick={handleCancel}
      />

      <Title>Are you sure you want to delist?</Title>

      <div className="btn-wrapper">
        <ConformBtn onClick={handleCancel}>Cancel</ConformBtn>
        <ConformBtn onClick={delistNft}>Confirm</ConformBtn>
      </div>
    </ModalWrapper>
  );
};

export default DelistModal;
