import {
  MainCol,
  MainContainer,
  MainRow,
  ToastMessage,
} from "components/common";
import { AdminWrapper, DataDiv, SaveBtn, SelectDiv, Title } from "./elements";
import { Select } from "antd";
import { BsInfoCircleFill } from "react-icons/bs";
import { useEffect, useMemo, useState } from "react";
import { IoClose } from "react-icons/io5";
import { Form } from "react-bootstrap";
import { GetMerkleDataHook } from "hooks/merkleDataHook";
import { CommonUtility } from "utility";
import { GetDropsHook, UpdateDropHook } from "hooks/dropHook";
import Loading from "components/common/loader";
import { useAppSelector } from "store/store";
import { starshipAbi } from "contract/starship";

const StarshipAdminComp = () => {
  const { getMerkleData } = GetMerkleDataHook();
  const { updateDrop } = UpdateDropHook();
  const [text, setText] = useState("");
  const [error2, setError2] = useState(null);
  const [error, setError] = useState(null);
  const [divs, setDivs] = useState([]);
  const [csvData, setCsvData] = useState(null);
  const [csvData2, setCsvData2] = useState(null);
  const [dropId, setDropId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { web3, account, chainId } = useAppSelector(
    (state) => state.web3Connect
  );
  const { config } = useAppSelector((state) => state.configData);
  const { data: drops, loading } = GetDropsHook();
  const secureDrops = useMemo(
    () => drops?.filter((drop) => drop?.is_secure === true),
    [drops]
  );
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      const newDiv = { id: Date.now(), text };
      setDivs([...divs, newDiv]);
      setText("");
    }
  };

  const handleChange = (event) => {
    setText(event.target.value);
  };

  const handleDelete = (id) => {
    const updatedDivs = divs.filter((div) => div.id !== id);
    setDivs(updatedDivs);
    setError(false);
  };

  const [secondText, setSecondText] = useState("");
  const [secondDivs, setSecondDivs] = useState([]);

  const handleSecondKeyPress = (event) => {
    if (event.key === "Enter") {
      const newSecondDiv = { id: Date.now(), text: secondText };
      setSecondDivs([...secondDivs, newSecondDiv]);
      setSecondText("");
    }
  };

  const handleSecondChange = (event) => {
    setSecondText(event.target.value);
  };

  const handleSecondDelete = (id) => {
    const updatedSecondDivs = secondDivs.filter((div) => div.id !== id);
    setSecondDivs(updatedSecondDivs);
    setError2(false);
  };

  const setDropWhitelisters = async (status) => {
    try {
      setIsLoading(true);
      const addressArray = divs.map((obj) => obj.text);
      const addresses = Array.from(new Set(addressArray));
      const obj = {
        id: dropId,
        addresses: addresses,
        status: status,
      };
      const merkleData = await getMerkleData(obj);
      console.log("merkleData", merkleData);
      const starShip = config.STAR_SHIP_ADDRESS;

      const contract = CommonUtility.contract(
        web3,
        starshipAbi,
        starShip[+chainId]
      );
      const adminRole = await contract.methods.ADMIN_ROLE().call();
      const hasRole = await contract.methods.hasRole(adminRole, account).call();
      if (!hasRole) throw "Only admin can set whitelisters";

      const txn = await contract.methods
        .updateRoot(dropId, merkleData?.root)
        .send({ from: account })
        .on("transactionHash", async (hash) => {
          await updateDrop({
            id: dropId,
            proof: merkleData.hexProof,
            addresses: merkleData.addressesForUsage,
          });
        });
      if (txn && txn.code == 4001) throw "User denied the transaction";
      if (!txn.status) throw "Transaction Failed";
      if (txn.status) {
        setIsLoading(false);
        ToastMessage("Success!", "Root Updated successfully", "success");
      }
    } catch (error) {
      setIsLoading(false);
      console.log("error", error);
      if (error?.message) {
        ToastMessage("Error", error?.message, "error");
      } else {
        ToastMessage("Error", error, "error");
      }
    }
  };
  useEffect(() => {
    if (csvData) {
      let ac = csvData?.map((x) => {
        return {
          id: Date.now(),
          text: x?.wallet_address,
        };
      });
      setDivs(ac);

      const textSet = new Set(ac.map((item) => item.text));
      if (textSet.size !== ac.length) {
        setError(true);
      } else {
        setError(false);
      }
    }
  }, [csvData]);

  useEffect(() => {
    if (csvData2) {
      let ac = csvData2?.map((x) => {
        return {
          id: Date.now(),
          text: x?.wallet_address,
        };
      });
      setSecondDivs(ac);
      const textSet = new Set(ac.map((item) => item.text));
      if (textSet.size !== ac.length) {
        setError2(true);
      } else {
        setError2(false);
      }
    } else {
      setError2(false);
    }
  }, [csvData2]);

  console.log(error, error2, "error");

  return (
    <AdminWrapper>
      {(loading || isLoading) && <Loading content={"Loading"} />}
      <MainContainer fluid>
        <MainRow>
          <Title>Drops</Title>
          <MainCol lg={12}>
            <SelectDiv>
              <Select
                bordered={false}
                value={dropId}
                showSearch
                placeholder="free"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                onChange={(e) => {
                  setDropId(e);
                }}
                options={secureDrops?.map((x) => {
                  return {
                    value: x.drop_id,
                    label: x.name,
                  };
                })}
              />
              <p>
                Select packs&nbsp; <BsInfoCircleFill />
              </p>
            </SelectDiv>
          </MainCol>
        </MainRow>

        <MainRow>
          <Title>add whitelist address</Title>
          <MainCol lg={8}>
            <DataDiv>
              <Form.Control
                value={text}
                onChange={handleChange}
                onKeyPress={handleKeyPress}
              />
              {divs.length > 0 && (
                <div className="bordered-row">
                  <MainRow>
                    {divs.map((div, index) => (
                      <MainCol xxl={6} key={div.id}>
                        <div className="textarea-div">
                          <IoClose
                            className="close-icon"
                            onClick={() => handleDelete(div.id)}
                          />
                          {div.text}
                        </div>
                      </MainCol>
                    ))}
                  </MainRow>
                </div>
              )}
            </DataDiv>
          </MainCol>
          <MainCol lg={4}>
            <label htmlFor="update" style={{ width: "100%" }}>
              <div className="btn">Upload CSV</div>
            </label>
            <input
              type="file"
              id="update"
              accept=".csv"
              onChange={(e) => {
                CommonUtility.csvToJson(e, setCsvData);
              }}
              style={{ display: "none" }}
            />
          </MainCol>
        </MainRow>

        <MainRow>
          <MainCol lg={8}>
            <SaveBtn onClick={() => setDropWhitelisters(1)}>Save</SaveBtn>
          </MainCol>
        </MainRow>

        <MainRow>
          <Title>add blacklist address</Title>
          <MainCol lg={8}>
            <DataDiv>
              <Form.Control
                value={secondText}
                onChange={handleSecondChange}
                onKeyPress={handleSecondKeyPress}
              />
              {secondDivs.length > 0 && (
                <div className="bordered-row">
                  <MainRow>
                    {secondDivs?.map((div, index) => (
                      <MainCol xxl={6} key={div.id}>
                        <div className="textarea-div">
                          <IoClose
                            className="close-icon"
                            onClick={() => handleSecondDelete(div.id)}
                          />
                          {div.text}
                        </div>
                      </MainCol>
                    ))}
                  </MainRow>
                </div>
              )}
            </DataDiv>
          </MainCol>
          <MainCol lg={4}>
            <label htmlFor="update2" style={{ width: "100%" }}>
              <div className="btn">Upload CSV</div>
            </label>
            <input
              type="file"
              id="update2"
              style={{ display: "none" }}
              accept=".csv"
              onChange={(e) => {
                CommonUtility.csvToJson(e, setCsvData2);
              }}
            />
          </MainCol>
        </MainRow>

        <MainRow>
          <MainCol lg={8}>
            <SaveBtn onClick={() => setDropWhitelisters(0)}>Save</SaveBtn>
          </MainCol>
        </MainRow>
      </MainContainer>
    </AdminWrapper>
  );
};

export default StarshipAdminComp;
