import React from "react";
import { MainProfileBg, MainUpload, MainUploadBtn } from "components/common";
import { EditOutlined, SearchOutlined } from "@ant-design/icons";
import heroImg from "assets/heroImg.png";

import { NftForms } from "components/common/InputField/inputElement";
import { ErrorMessage } from "components/nftForm/nftFormElement";

const UploadField = ({
  collectionProfileBg,
  createUploadBtn,
  collectionFeaturedImage,
  collectionBannerImage,
  uploadLabel,
  inputLabelText,
  inputRequired,
  featuredUploadBtn,
  bannerUploadBtn,
  profileSettingBanner,
  profileSettingBannerBtn,
  setValue,
  bgimg,
  error,
}) => {
  const upload = {
    name: "file",
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    onchange: (e) => {
      setValue(e);
    },
  };

  return (
    <div>
      <NftForms.Group>
        <NftForms.Label style={{ marginTop: "1rem", color: "black" }}>
          {" "}
          {uploadLabel}
          {inputRequired ? (
            <span
              style={{ color: "red", marginLeft: "0.2rem", marginTop: "5rem" }}
            >
              *
            </span>
          ) : (
            ""
          )}
        </NftForms.Label>
        <br />

        <NftForms.Text className="text-muted">{inputLabelText}</NftForms.Text>
        <MainProfileBg
          collectionProfileBg={collectionProfileBg}
          collectionFeaturedImage={collectionFeaturedImage}
          collectionBannerImage={collectionBannerImage}
          profileSettingBanner={profileSettingBanner}
          bgImg={bgimg}
        >
          <MainUpload
            onChange={(e) => setValue(e)}
            beforeUpload={() => false}
            {...upload}
          >
            <MainUploadBtn
              icon={<EditOutlined />}
              createUploadBtn={createUploadBtn}
              featuredUploadBtn={featuredUploadBtn}
              bannerUploadBtn={bannerUploadBtn}
              profileSettingBannerBtn={profileSettingBannerBtn}
            >
              {" "}
            </MainUploadBtn>
          </MainUpload>
        </MainProfileBg>
        {error ? <ErrorMessage>{error}</ErrorMessage> : ""}
      </NftForms.Group>
    </div>
  );
};

export default UploadField;
