import styled from "styled-components";

export const ModalWrapper = styled.div`
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid #e7e7e7;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27);
  padding: 1rem 2rem 2rem 2rem;
  position: relative;

  .close-icon {
    position: absolute;
    top: 5px;
    right: 10px;
  }

  .btn-wrapper {
    display: flex;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
  }
`;

export const Title = styled.h5`
  color: var(--secondary, #3e3e3e);
  font-family: Barlow;
  font-size: 1.56644rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  text-align: center;
  margin-top: 1.5rem;
`;

export const CancelBtn = styled.button`
  color: #c9c9c9;
  font-family: Barlow;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  border-radius: 1.75rem;
  border: 1px solid #c9c9c9;
  width: 5.8125rem;
  height: 2.1875rem;
  background-color: transparent;

  &:hover {
    background: #00b7ff;
    color: #fff;
  }
`;
export const ConformBtn = styled.button`
  color: var(--pimary, #00b7ff);
  font-family: Barlow;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  border-radius: 1.75rem;
  border: 1px solid var(--pimary, #00b7ff);
  width: 5.8125rem;
  height: 2.1875rem;
  background-color: transparent;

  &:hover {
    background: #00b7ff;
    color: #fff;
  }
`;
