import styled from 'styled-components';
const background = require('../../assets/bg.png');

export const Content = styled.div`
    
`;

export const ComputerImageIcon = styled.img`
    max-width: 100%;   
`
 