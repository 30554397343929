import styled from "styled-components";

import { DatePicker } from "antd";

export const DateRangePicker = styled(DatePicker.RangePicker)`
  width: 100%;
  padding: 0.5rem;
  border-radius: 8px;
`;

export const Label = styled.label`
  display: block;
  color: white;

  margin-top: 1rem;
  margin-bottom: 1rem;
`;
