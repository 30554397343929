import TemplateComp from "components/starship/template";
import React from "react";

const TemplateScreen = () => {
  return (
    <div>
      <TemplateComp />
    </div>
  );
};

export default TemplateScreen;
