import { Card, Button } from "react-bootstrap";

import styled from "styled-components";

export const TrendingMain = styled.div`
  display: flex;
  justify-content: center;
`;

export const TrendingTitle = styled.h3`
  text-align: center;
  margin-top: 5rem;
  color: white;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 1.5rem;
`;

export const CardTrendingDiv = styled.div`
  text-align: center;
`;
export const TrendingCardText = styled(Card.Text)`
  color: black;
  margin-top: -5rem;
  font-weight: 600;
`;
export const TrendingCardDescFull = styled.p`
  color: rgb(112, 122, 131);
  font-weight: 500;
  font-family: "Poppins";
`;
export const ProfileMain = styled.div``;
export const ProfileImage = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  transform: translateY(-90px);
  border: 3px solid white;
`;

export const TrendingCardDesc = styled(Card.Text)`
  color: #abb1b6;

  & span {
    color: #2081e2;
  }
`;
export const TrendingButton = styled.div``;
export const Buttons = styled(Button)`
  background: transparent;
  padding: 0.2rem 0.7rem;
  border: 1px solid white;
`;
