import ProfileCom from "components/starship/profile";

function Profile() {
  return (
    <div>
      <ProfileCom />
    </div>
  );
}

export default Profile;
