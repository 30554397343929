import styled from "styled-components";

export const ModalWrapper = styled.div`
  overflow: hidden;
  /* border-radius: 10px;
  border: 1px solid #e7e7e7;
  background-color: ${({ theme }) => theme.selectdropdownbgColor} !important;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27); */
  border-radius: 0.625rem;
  border: 1px solid var(--pimary, #00b7ff);
  background: #fff;
  padding: 1rem 2rem 3rem 2rem;
  position: relative;

  .close-icon {
    position: absolute;
    top: 5px;
    right: 10px;
  }
`;

export const Title = styled.h5`
  color: var(--secondary, #3e3e3e);
  font-family: Barlow;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  text-align: center;
  margin-top: 2rem;
`;

export const Text = styled.p`
  color: #6b6b6b;
  text-align: center;
  font-family: Barlow;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 144%; /* 1.44rem */
  text-transform: capitalize;
  margin-top: 0.5rem;
`;

export const CardWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 1rem;
  .card {
    width: 15.75rem;
    height: 20.4375rem;
    border-radius: 0.625rem;
    border: 1.064px solid var(--pimary, #00b7ff);
    box-shadow: 0px 0px 4.25485px 0px rgba(0, 0, 0, 0.27);

    img {
      height: 16.4375rem;
    }
  }

  .card-title {
    color: #3e3e3e;
    text-align: center;
    font-family: Barlow;
    font-size: 1.19669rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.59556rem; /* 133.333% */
    text-transform: capitalize;
    margin-top: 1rem;
  }
`;

export const OnePackBtn = styled.button`
  color: #fff;
  font-family: Barlow;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  border-radius: 1.75rem;
  border: 1px solid var(--pimary, #00b7ff);
  background: var(--pimary, #00b7ff);
  width: 15.625rem;
  height: 2.1875rem;
  margin-top: 1.5rem;
`;
