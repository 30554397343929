import { createSlice } from "@reduxjs/toolkit";


export const nftDataSlice = createSlice({
    name: "nft data slice",
    initialState: {
        nft: {
            name: "",
            description: "",
            image: "",
            attributes: "",
            externalLink: "",
            collectionName: "",
            collectionId: "2",
            ownerAddress: "",
            totalSupply: 1,
            networkId: null
        },
        createSellNft:{
            data:null,
            loading: false,
            error: null
        },
        sellNft:{
            data:null,
            loading: false,
            error: null
        },
        mintNftResult:null,
        attributes: [{ trait_type: "", value: "" }],
        nfts: null,
        collection: [],
        saveAttribute: false
    },
    reducers: {
        setNft: (state, action) => {
            return {
                ...state,
                nft: { ...action.payload }
            }
        },
        setAttributes: (state, action) => {
          
            return {
                ...state,
                attributes: [...action.payload]
            }
        },

        setNftsData: (state, action) => {
            return {
                ...state,
                nfts: action.payload
            }
        },
        setSaveAttribute: (state, action) => {
            return {
                ...state,
                saveAttribute: action.payload
            }
        },
        createSellNftData: (state, action) => {
            return {
                ...state,
                createSellNft: action.payload
            }
        },
        sellNftData: (state, action) => {
            return {
                ...state,
                sellNft: action.payload
            }
        },
        setMintNft:(state,action)=>{
            return{
                ...state,
                mintNftResult:action.payload
            }
        },
        resetNftData: (state) => {
            return {
                ...state,
                nft: {
                    ...state.nft,
                    name: "",
                    description: "",
                    image: "",
                    attributes: "",
                    externalLink: "",
                    collectionName: "",
                    collectionId: "2",
                    totalSupply: 1,
                    networkId: null
                }
            }
        }
    }
});

export const { setNft,setMintNft, setSaveAttribute,createSellNftData,sellNftData, resetNftData, setAttributes, setNftsData } = nftDataSlice.actions;
export const nftDataReducer = nftDataSlice.reducer;
