import { useEffect, useState } from "react";
import { CommonHook } from "./commonHook";
import { TemplateService } from "utility/services/templateServices";
import "firebase/storage";
import { useNavigate } from "react-router-dom";
import { CommonUtility } from "utility";

export const CreateTemplateHook = () => {
  const { data, setData, setError, loading, setLoading, error } = CommonHook();

  const createTemplate = async (obj) => {
    try {
      setLoading(true);
      const jsonData = JSON.stringify(obj);
      let encrypted = CommonUtility.encrypt(jsonData);
      const result = await TemplateService.createTemplate(encrypted);
      if (result.data) {
        setData(result.data);
      }
    } catch (error) {
      console.log(error, "hook");
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    createTemplate,
    data,
    loading,
    error,
  };
};

export const GetTemplateHook = () => {
  const { data, setData, setError, loading, setLoading, error } = CommonHook();

  const getTemplates = async () => {
    try {
      setLoading(true);
      const result = await TemplateService.getTemplates();
      if (result.data) {
        let temp = CommonUtility.decrypt(result.data);
        temp = JSON.parse(temp);
        setData(temp);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getTemplates();
  }, []);

  return {
    getTemplates,
    data,
    loading,
    error,
    setData,
  };
};

export const GetTemplateBySchemaIdHook = (id, page) => {
  const { data, setData, setError, loading, setLoading, error } = CommonHook();
  const [templateCount, setTemplateCount] = useState(0);
  const getTemplateBySchemaId = async (id, page) => {
    try {
      setLoading(true);
      const result = await TemplateService.getTemplateBySchemaId(id, page);
      if (result.data) {
        let temp = CommonUtility.decrypt(result.data);
        temp = JSON.parse(temp);
        setData(temp);
        setTemplateCount(result.templateCount);
        setLoading(false);
      }
    } catch (error) {
      setError(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    id && getTemplateBySchemaId(id, page);
  }, [id, page]);

  return {
    getTemplateBySchemaId,
    data,
    loading,
    error,
    templateCount,
  };
};

export const GetTemplateByIdHook = (id) => {
  const { data, setData, setError, loading, setLoading, error } = CommonHook();

  const getTemplateById = async (id) => {
    try {
      setLoading(true);
      const result = await TemplateService.getTemplateById(id);
      if (result.data) {
        let temp = CommonUtility.decrypt(result.data);
        temp = JSON.parse(temp);
        setData(temp);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    id && getTemplateById(id);
  }, [id]);

  return {
    getTemplateById,
    data,
    loading,
    error,
  };
};

export const SearchTemplateHook = (search, id) => {
  const { data, setData, setError, loading, setLoading, error } = CommonHook();

  const searchTemplate = async (search) => {
    try {
      setLoading(true);
      const result = await TemplateService.searchTemplate({
        nft_id: search,
        schema_id: id,
      });
      if (result.data) {
        let temp = CommonUtility.decrypt(result.data);
        temp = JSON.parse(temp);
        setData(temp);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    search && searchTemplate(search);
  }, [search]);

  return {
    searchTemplate,
    data,
    loading,
    error,
  };
};

export const EditTemplateHook = () => {
  const { data, setData, setError, loading, setLoading, error } = CommonHook();
  const navigate = useNavigate();
  const editTemplate = async (id, obj) => {
    try {
      setLoading(true);
      const result = await TemplateService.editTemplate(id, obj);
      if (result.data) {
        let temp = CommonUtility.decrypt(result.data);
        temp = JSON.parse(temp);
        setData(temp);
        navigate(
          `/starship/admin/template/view-template?id=${result.data.schema_id}`
        );
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    editTemplate,
    data,
    loading,
    error,
  };
};
