import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import { MainButton } from "components/common";
import { connectModelSlice } from "store/redux/slices/helperSlices/modelSlice";
import { useSearchParams } from "react-router-dom";
import { ContractUtility } from "utility";
import { UserNFTDeatilsWrapper } from "./element";
import { switchNetwork } from "hooks";

const SellNft = () => {
  // const dispatch = useAppDispatch();
  // const [searchParams, setSearchParams] = useSearchParams();
  // const nftNetwork = searchParams.get("chain");
  // const { chainId, web3 } = useAppSelector((state) => state.web3Connect);

  // const connectModelFn = () => {
  //   dispatch(connectModelSlice(true));
  // };

  // useEffect(() => {}, [chainId]);

  return (
    <div>
      {/* {!web3 ? (
        <MainButton
          newConnectWalletBtn
          onClick={connectModelFn}
          style={{ display: "block", margin: "4rem auto" }}
        >
          Connect Wallet
        </MainButton>
      ) : chainId != nftNetwork ? (
        <UserNFTDeatilsWrapper>
          <p>
            Your are requesting nft from{" "}
            {ContractUtility.getNetwork(nftNetwork)} but you are still connected
            to {ContractUtility.getNetwork(chainId)}. Kindly switch to{" "}
            {ContractUtility.getNetwork(nftNetwork)} to view nft.
          </p>
          <MainButton
            newConnectWalletBtn
            onClick={() => switchNetwork(web3, nftNetwork)}
          >
            Switch Network
          </MainButton>
        </UserNFTDeatilsWrapper>
      ) : (
        <SellNftComponent />
      )} */}
    </div>
  );
};

export default SellNft;
