import styled from "styled-components";

export const ConnectModalWrapper = styled.div`
  overflow: hidden;
  min-height: 328px;
  border: 1px solid #a2fd16;
  background: #070708;
  padding: 10px 20px;

  @media (max-width: 575.98px) {
    padding-bottom: 2rem;
  }

  .closeIcon {
    display: block;
    margin-left: auto;
    color: #a0a0a0;
    width: 24px;
    height: 24px;
    cursor: pointer;
    transition: 0.3s ease-in-out all;

    &:hover {
      scale: 1.2;
      transition: 0.3s ease-in-out all;
    }
  }
`;

export const ConnectWalletText = styled.h2`
  color: #fff;
  font-family: Squada One;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  text-align: center;
  margin-top: 1rem;

  @media (max-width: 575.98px) {
    margin-top: 1rem;
    font-size: 24px;
  }
`;
export const ButtonHolder = styled.div`
  margin-top: 3rem;

  @media (max-width: 575.98px) {
    margin-top: 2rem;
  }
`;

export const LeftDiv = styled.div`
  /* width: 136.04px;
  height: 100.16px;
  border: 0.5px solid #8e92a3;
  border-radius: 10px; */
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;

  .icon {
    height: 103px;
    width: 103px;
  }
`;

export const RightDiv = styled.div`
  /* width: 136.04px;
  height: 100.16px;
  border: 0.5px solid #8e92a3;
  border-radius: 10px; */
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;

  @media (max-width: 575.98px) {
    margin-top: 1rem;
  }

  .icon {
    height: 103px;
    width: 103px;
    object-fit: contain;
  }
`;

export const BtnText = styled.p`
  margin: 0;
  color: #fff;
  font-family: Squada One;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
`;

export const ModalWrapper = styled.div`
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid #e7e7e7;
  /* background: #fff; */
  background-color: ${({ theme }) => theme.selectdropdownbgColor} !important;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27);
  padding: 1rem 2rem 2rem 2rem;
  min-height: 279px;
  position: relative;

  .close-icon {
    position: absolute;
    top: 5px;
    right: 10px;
  }
`;

export const MainHeader = styled.h2`
  /* color: #3e3e3e; */
  color: ${({ theme }) => theme.noDataFoundColor} !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-transform: capitalize;
  margin-top: 2rem;
  text-align: center;
`;

export const TermsAndConditionText = styled.p`
  /* color: #3d4044; */
  color: ${({ theme }) => theme.inputtext} !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 22px;
  text-align: center;
  margin-top: 2rem;

  span {
    color: #00b7ff;
    cursor: pointer;
  }
`;

export const ButtonsDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
`;

export const ConnectWalletBtn = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  border-radius: 5px;
  border: 1px solid #e7e7e7;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27);
  width: 100%;
  padding: 10px 2rem;

  p {
    /* color: #6b6b6b; */
    color: ${({ theme }) => theme.selectText} !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
  }
`;
