import styled, { css } from "styled-components";

import { Form, InputGroup } from "react-bootstrap";
import { AiFillCopy } from "react-icons/ai";

// import {  } from "react-icons/all";

export const NftForms = styled(Form)``;

export const FormControl = styled(Form.Control)`
  &.form-control:disabled,
  .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1;
    cursor: not-allowed;
  }

  ${(props) =>
    props.isSellNftControl &&
    css`
      margin-top: 2rem !important;
      padding: 0.6rem;
      border-radius: 8px;
    `}

  ${(props) =>
    props.isImportInput &&
    css`
      padding: 0.5rem;
      border-radius: 8px;
    `}

    @media (max-width:767px) {
    ${(props) =>
      props.isImportInput &&
      css`
        margin-top: -1.2rem;
      `}
  }
`;

export const MainInputField = styled.div`
  input {
    background: #ffffff;
    border: 1px solid #828282;
    border-radius: 5px;

    &::placeholder {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.5);
    }
  }
  .form-label {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    color: #333333;
    margin-top: 1.5rem;
    margin-bottom: 0;
    font-family: "Roboto", sans-serif !important;
  }

  .text-muted {
    font-style: normal;
    font-family: "Roboto", sans-serif !important;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height, or 110% */

    /* Gray 3 */

    color: #828282;
  }
`;

export const MainTextArea = styled.div`
  .form-control {
    background: #ffffff;
    border: 1px solid #828282;
    border-radius: 5px;
  }
  .form-label {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    color: #333333;
    margin-top: 1.5rem;
    margin-bottom: 0;
    font-family: "Roboto", sans-serif !important;
  }

  .text-muted {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height, or 110% */

    /* Gray 3 */

    color: #828282;
    font-family: "Roboto", sans-serif !important;
  }
`;

export const InputGroups = styled(InputGroup)`
  & .input-group-text {
    height: 2.7rem;
    margin-top: 1rem;
  }
`;

export const CopyIcon = styled(AiFillCopy)`
  padding: 0;
  cursor: pointer;
`;
