import MainSectionComp from "./mainSection";

const StarShipComp = () => {
  return (
    <div>
      <MainSectionComp />
    </div>
  );
};

export default StarShipComp;
