import { useEffect } from "react";
import {
  BtnWrapper,
  LogoutBtn,
  UserModalWrapper,
  ViewProfileBtn,
} from "./elements";
import { AiOutlineClose } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "store/store";
import { logoutBlockchain } from "store/redux/slices/web3/connectWeb3Slice";
import { connectModelSlice } from "store/redux/slices/helperSlices/modelSlice";
import { resetUser } from "store/redux/slices/userSlices/userSlice";

const UserModal = ({ onClose }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleViewProfile = () => {
    onClose();
    navigate("/starship/profile/collections");
  };

  const handleClickOutside = (event) => {
    const modalWrapper = document.getElementById("user-modal-wrapper");
    if (modalWrapper && !modalWrapper.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const location = useLocation();

  const darkModePaths =
    location.pathname === "/" ||
    location.pathname === "/starship/view-collection/detail" ||
    location.pathname === "/starship/explore" ||
    location.pathname.startsWith("/starship/profile");

  const logoutRedirect = (location) => {
    if (location && location.includes("admin")) {
      navigate("/");
    }
    if (location && location.includes("profile")) {
      navigate("/");
    }
  };
  return (
    <UserModalWrapper
      id="user-modal-wrapper"
      className={`bg-body-tertiary ${!darkModePaths && "main-wrapper"}`}
    >
      <AiOutlineClose className="closeIcon" onClick={() => onClose()} />

      <BtnWrapper>
        <ViewProfileBtn onClick={handleViewProfile}>profile</ViewProfileBtn>
        <LogoutBtn
          onClick={() => {
            dispatch(logoutBlockchain());
            dispatch(connectModelSlice(false));
            onClose();
            dispatch(resetUser());
            logoutRedirect(location.pathname);
          }}
        >
          Logout
        </LogoutBtn>
      </BtnWrapper>
    </UserModalWrapper>
  );
};

export default UserModal;
