import React, { useEffect, useState } from "react";
import * as P from "./propertiesModelElement";
import { Space } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import {
  setAttributes,
  setNft,
  setSaveAttribute,
} from "store/redux/slices/nftSlices/nftDataSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { ErrorMessage } from "components/nftForm/nftFormElement";
const PropertiesModal = ({ close, data }) => {
  const dispatch = useAppDispatch();
  const [error, setError] = useState("dummy");
  const { nft, attributes, saveAttribute } = useAppSelector(
    (state) => state.nftData
  );
  useEffect(() => {
    if (nft?.attributes != "") {
      dispatch(setAttributes(JSON.parse(nft.attributes)));
    }
  }, []);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const newArray = attributes.map((obj, i) => {
      if (i == index) {
        return { ...obj, [name]: value };
      }
      return obj;
    });
    dispatch(setAttributes(newArray));
  };

  const handleRemoveClick = (index) => {
    const list = [...attributes];
    list.splice(index, 1);
    dispatch(setAttributes(list));
  };

  const saveAttributes = () => {
    setError("");
    if (attributes.length > 0) {
      for (let i = 0; i < attributes.length; i++) {
        if (!attributes[i].trait_type) setError("Trait type is missing");
        else if (!attributes[i].value)
          setError(`${attributes[i].trait_type}'s value is missing`);
      }
    }
  };

  useEffect(() => {
    !error && error != "dummy" && dispatch(setSaveAttribute(true));
  }, [error]);

  useEffect(() => {
    saveAttribute &&
      !error &&
      dispatch(setNft({ ...nft, attributes: JSON.stringify(attributes) })) &&
      dispatch(setSaveAttribute(false)) &&
      close();
  }, [error, saveAttribute]);

  return (
    <P.MainModel>
      <P.ModelsData>
        <P.ModalHeader>
          <P.ModalTitle>Add Properties</P.ModalTitle>
        </P.ModalHeader>
        <hr />
        <P.ModalDesc>
          Properties show up underneath your item, are clickable, and can be
          filtered in your collection's sidebar.
        </P.ModalDesc>
        <P.ModalForm
          name="dynamic_form_nest_item"
          autoComplete="off"
          layout="vertical"
        >
          {attributes.map((obj, i) => {
            return (
              <Space key={`atttributes-${i}`}>
                <P.CloseDiv2>
                  <CloseOutlined
                    onClick={() => {
                      handleRemoveClick(i);
                    }}
                    className="closeModelInput"
                  />
                </P.CloseDiv2>
                <P.ModalForm.Item label="Type">
                  <P.ModalInput
                    name="trait_type"
                    value={obj.trait_type}
                    onChange={(e) => handleInputChange(e, i)}
                    placeholder="Character"
                  />
                </P.ModalForm.Item>
                <P.ModalForm.Item label="Name">
                  <P.ModalInput
                    name="value"
                    value={obj.value}
                    onChange={(e) => handleInputChange(e, i)}
                    className="modalInputMale"
                    placeholder="Male"
                  />
                </P.ModalForm.Item>
              </Space>
            );
          })}

          <P.ModalForm.Item>
            <P.AddMoreBtn
              onClick={() => {
                dispatch(
                  setAttributes([...attributes, { trait_type: "", value: "" }])
                );
              }}
            >
              Add More
            </P.AddMoreBtn>
          </P.ModalForm.Item>
        </P.ModalForm>
        <hr />
        <ErrorMessage> {error && error != "dummy" && error}</ErrorMessage>{" "}
        <br />
        <P.FormSaveBtn
          onClick={() => {
            saveAttributes();
          }}
        >
          Save
        </P.FormSaveBtn>
      </P.ModelsData>
    </P.MainModel>
  );
};

export default PropertiesModal;
