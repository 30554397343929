import { Image } from "react-bootstrap";
import {
  ContentWrapper,
  MainHeader,
  ModalWrapper,
  WalletMainText,
} from "./element";
import { MdClose } from "react-icons/md";
import { imgframe } from "assets";
// import imgframe from "../../../assets/i";

function CancelListing({ handleCancel }) {
  return (
    <ModalWrapper>
      <MdClose
        cursor="pointer"
        fontSize={22}
        color="#6B6B6B"
        className="close-icon"
        onClick={handleCancel}
      />
      <MainHeader>Cancel Listing</MainHeader>

      <ContentWrapper>
        <div className="left-div">
          <Image src={imgframe} width={18} height={18} />
          <div>
            <h4>34</h4>
            <p className="name-text">mETASHERE NFT V3</p>
            <p className="chain-text">chain: polygon</p>
          </div>
        </div>
        <div className="right-div">
          <h4>0.0001ETH</h4>
          <p className="dollar-text">$0.19</p>
        </div>
      </ContentWrapper>

      <WalletMainText>go to your wallet</WalletMainText>
      <p className="last-text">
        you’ll be asked to review and confirm this cancellation from your
        wallet,
      </p>
    </ModalWrapper>
  );
}

export default CancelListing;
