import { MainContainer } from "components/common";
import { useEffect } from "react";
import {
  MainRewards,
  RewardsHeading,
  RewardsTable,
  RowDivider,
  TableDiv,
  TransactionHeader,
} from "./element";
import trophy from "../../assets/trophy.png";
import { CommonUtility, shareholders } from "utility";
import { useAppSelector } from "store/store";
import { paymentList } from "components/data/selectData";
import { AiOutlineTrophy } from "react-icons/ai";

function RewardsCom({ data }) {
  const handleAmount = (detail, value) => {
    const paymentTokens = paymentList(`${detail?.network}`);
    const erc20Token = paymentTokens.find(
      (x) => x.value.toLocaleLowerCase() === detail?.signed_order.erc20Token
    );
    return CommonUtility.handleDivDecimals(value, erc20Token.decimals, 8);
  };

  const tokenInfo = (detail) => {
    const paymentTokens = paymentList(`${detail?.network}`);
    const erc20Token = paymentTokens.find(
      (x) => x.value.toLocaleLowerCase() === detail?.signed_order.erc20Token
    );
    return erc20Token.text;
  };

  return (
    <MainRewards>
      <MainContainer>
        <TableDiv>
          {/* <RewardsHeading>
            <AiOutlineTrophy
              style={{ marginRight: "10px", marginBottom: "3px" }}
            />
            Rewards
          </RewardsHeading> */}
          <h2 className="mainheader">
            {" "}
            <AiOutlineTrophy
              style={{ marginRight: "10px", marginBottom: "3px" }}
            />
            Rewards
          </h2>

          <RewardsTable
            responsive
            style={{
              textAlign: "center",
            }}
          >
            <thead>
              <tr>
                <th style={{ minWidth: "150px" }} className="tableHeader">
                  Date
                </th>
                <th style={{ minWidth: "150px" }} className="tableHeader">
                  Nft Contract Address
                </th>
                <th style={{ minWidth: "150px" }} className="tableHeader">
                  Nft id
                </th>
                <th style={{ minWidth: "150px" }} className="tableHeader">
                  Token
                </th>
                <th style={{ minWidth: "150px" }} className="tableHeader">
                  Amount{" "}
                </th>
                <th style={{ minWidth: "150px" }} className="tableHeader">
                  From
                </th>
              </tr>
            </thead>

            <tbody>
              {data &&
                data.length > 0 &&
                data.map((item) => {
                  const amount = item.recipients.find(
                    (x) => x.name === shareholders.REFERRER
                  ).amount;
                  return (
                    <>
                      <tr>
                        <td>{CommonUtility.mm_dd_yy(item.updatedAt)}</td>
                        <td>
                          {" "}
                          {CommonUtility.addressConvertor(
                            CommonUtility.deStructureNftId(item.nft_id)
                              .tokenAddress
                          )}
                        </td>
                        <td style={{ maxWidth: "10px" }}>
                          {CommonUtility.deStructureNftId(item.nft_id).tokenId}
                        </td>
                        <td>{tokenInfo(item.sell_nft)}</td>
                        <td>{handleAmount(item.sell_nft, amount)}</td>
                        <td>
                          {CommonUtility.addressConvertor(
                            item.sell_nft.wallet_address
                          )}
                        </td>
                      </tr>
                    </>
                  );
                })}
            </tbody>
          </RewardsTable>
        </TableDiv>
      </MainContainer>
    </MainRewards>
  );
}

export default RewardsCom;
