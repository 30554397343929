import { gql } from "@apollo/client";

const GET_USER_COLLECTION = gql`
  query user_collection($userAddress: String!) {
    user_collection(userAddress: $userAddress) {
      name
      id
      description
      image
      nft_id
      total_supply
      owner_address
      price
      external_link
    }
  }
`;

const GET_ALL_NFTS = gql`
  query get_all_nfts {
    get_all_nfts {
      name
      id
      description
      image
      nft_id
      total_supply
      owner_address
      price
      network_id
      external_link
      attributes
    }
  }
`;

const GET_NFT_TRANSACTIONS = gql`
  query get_nft_transactions($nftId: ID!) {
    get_nft_transactions(nftId: $nftId) {
      id
      nft_id
      transaction_hash
      network_id
      status
    }
  }
`;

const GET_VIEWS = gql`
  query get_views($nftId: Int!) {
    get_views(nftId: $nftId) {
      count
    }
  }
`;

const GET_PAYMENT_CONFIG = gql`
  query get_payment_config($id: ID!) {
    get_payment_config(id: $id) {
      id
      token_address
      index
      decimals
      network_id
    }
  }
`;

const GET_REPORTS = gql`
  query get_reports {
    get_reports {
      id
      nft_id
      wallet_address
      message
      network_id
      title
    }
  }
`;

const LOGIN = gql`
  query login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      authenticated
      token
    }
  }
`;

const AUTHENTICATE = gql`
  query authenticate {
    authenticate
  }
`;

const GET_FAVOURITE = gql`
  query get_favourite($nftId: ID!, $userAddress: String!) {
    get_favourite(nftId: $nftId, userAddress: $userAddress) {
      id
      user_address
      nft_id
    }
  }
`;

const GET_COLLECTION = gql`
  query get_collection($id: ID, $userAddress: String) {
    get_collection(id: $id, userAddress: $userAddress) {
      name
      description
      logo
      featured_image
      collection_address
      banner_image
      category
      links
      network
      status
      payment_token
      owner_address
      items {
        name
        image
        nft_id
        price
      }
    }
  }
`;

const GET_COLLECTION_BY_ID = gql`
  query get_collection_by_id($id: ID, $sort: Object, $filter: Object) {
    get_collection_by_id(id: $id, sort: $sort, filter: $filter) {
      name
      description
      logo
      featured_image
      collection_address
      banner_image
      category
      links
      network
      status
      payment_token
      owner_address
      items {
        owner_address
        name
        image
        attributes
        network_id
        user {
          wallet_address
          name
          prof_image
        }
        nft_id
        favourites {
          id
          user_address
        }
        views {
          count
        }
        sellNft {
          id
          signed_order
          sold
        }
      }
      totalVolume
      owners
      username
      volume24h
    }
  }
`;

const GET_NFT_BY_TOKEN_ID = gql`
  query get_nft_by_token_id($nftId: String) {
    get_nft_by_token_id(nftId: $nftId) {
      name
      id
      description
      image
      nft_id
      total_supply
      owner_address
      price
      favourites {
        nft_id
        user_address
      }
      views {
        count
      }
      transaction {
        status
        transaction_hash
        network_id
      }
      network_id
      external_link
      attributes
      transaction_hash
    }
  }
`;

const GET_COLLECTIONS = gql`
  query get_collections($sort: String, $limit: Int) {
    get_collections(sort: $sort, limit: $limit) {
      name
      description
      _id
      logo
      featured_image
      collection_address
      category
      links
      network
      items {
        name
        image
        nft_id
        price
      }
      status
      payment_token
      volume24h
      totalVolume
      owners
      volumeDiff
    }
  }
`;

const GET_USERS = gql`
  query Get_users {
    get_users {
      id
      name
      wallet_address
      bio
      referrer
      prof_image
      cover_image
      links {
        Twitter
        Instagram
        Website
      }
      email
    }
  }
`;

const GET_USER = gql`
  query Get_user($walletAddress: String!) {
    get_user(walletAddress: $walletAddress) {
      id
      name
      wallet_address
      bio
      referrer
      prof_image
      cover_image
      links {
        twitter
        instagram
        website
      }
      email
      createdAt
      isAdmin
      token
    }
  }
`;

const GET_CATEGORIES = gql`
  query get_categories {
    get_categories {
      id
      name
      instructions
    }
  }
`;

const GET_ADMIN = gql`
  query get_admin {
    get_admin {
      user_name
      id
    }
  }
`;

const GET_CONFIG = gql`
  query get_config {
    get_config {
      ethereum {
        marketplaceContractAddress
        erc721ContractAddress
        stakingContractAddress
        mintingContractAddress
        moralisServerUrl
        moralisAppId
        networkId
        companyAddress
        nethtyAddress
      }
      polygon {
        marketplaceContractAddress
        erc721ContractAddress
        stakingContractAddress
        mintingContractAddress
        moralisServerUrl
        moralisAppId
        networkId
        companyAddress
        nethtyAddress
      }
      rinkeby {
        marketplaceContractAddress
        erc721ContractAddress
        stakingContractAddress
        mintingContractAddress
        moralisServerUrl
        moralisAppId
        networkId
        companyAddress
        nethtyAddress
      }
      ropsten {
        marketplaceContractAddress
        erc721ContractAddress
        stakingContractAddress
        mintingContractAddress
        moralisServerUrl
        moralisAppId
        networkId
        companyAddress
        nethtyAddress
      }
    }
  }
`;

const GET_SELL_NFTS = gql`
  query get_sell_nfts {
    get_sell_nfts {
      meta_data
      signed_order
      wallet_address
      sold
      nft_id
      id
      network
      nft {
        image
      }
      user {
        prof_image
        name
      }
      favourites {
        nft_id
        user_address
        id
      }
    }
  }
`;

const TOP_VIEWED_NFTS = gql`
  query top_viewed_nfts {
    top_viewed_nfts {
      meta_data
      signed_order
      wallet_address
      sold
      nft_id
      id
      network
      nft {
        image
      }
      favourites {
        nft_id
        user_address
        id
      }
    }
  }
`;

const GET_SELL_NFT = gql`
  query get_sell_nft($id: ID!) {
    get_sell_nft(id: $id) {
      meta_data
      signed_order
      wallet_address
      nft_id
      id
      sold
      network
      nft {
        id
        image
        description
        attributes
        name
        network_id
      }
      views {
        count
      }
      transaction {
        status
        transaction_hash
      }
      user {
        name
      }
    }
  }
`;

const CHECK_NAME = gql`
  query check_name($name: String!) {
    check_name(name: $name)
  }
`;

const NFTS_FAVOURITE = gql`
  query nfts_favourite($nftId: ID) {
    nfts_favourite(nftId: $nftId) {
      id
      user_address
      nft_id
    }
  }
`;

const USERS_COLLECTIONS = gql`
  query users_collections($userAddress: String) {
    users_collections(userAddress: $userAddress) {
      name
      _id
      description
      logo
      featured_image
      collection_address
      category
      links
      network
      status
      payment_token
      owner_address
    }
  }
`;

const USER_REWARDS = gql`
  query user_rewards($userAddress: String!) {
    user_rewards(userAddress: $userAddress) {
      recipients {
        amount
        name
      }
      nft_id
      sell_nft {
        wallet_address
        signed_order
        network
      }
      updatedAt
    }
  }
`;

const NFTS_BY_NFT_IDS = gql`
  query nfts_by_nft_ids($nftIds: [String]) {
    nfts_by_nft_ids(nftIds: $nftIds) {
      name
      id
      description
      image
      owner_address
      nft_id
      total_supply
      favourites {
        nft_id
        user_address
        id
      }
      sellNft {
        signed_order
        network
        sold
      }
    }
  }
`;

const USER_FAVOURITES = gql`
  query user_favourites($userAddress: String) {
    user_favourites(userAddress: $userAddress) {
      nft_id
      id
      user_address
      metadata
    }
  }
`;
const USER_SELL_NFT = gql`
  query user_sell_nft($id: ID!) {
    user_sell_nft(id: $id) {
      id
    }
  }
`;

const CONVERT_PRICE = gql`
  query convert_price($values: ConvertPriceInput!) {
    convert_price(values: $values) {
      data
    }
  }
`;

const DASHBOARD_STATS = gql`
  query dashboard_stats {
    dashboard_stats {
      minted
      users
      reports
    }
  }
`;

const GET_REWARDS = gql`
  query get_rewards {
    get_rewards {
      recipients {
        name
        amount
      }
      sell_nft {
        signed_order
        wallet_address
        network
      }
      nft_id
    }
  }
`;

export {
  GET_USER_COLLECTION,
  GET_ALL_NFTS,
  LOGIN,
  GET_NFT_TRANSACTIONS,
  GET_REPORTS,
  GET_VIEWS,
  GET_FAVOURITE,
  NFTS_FAVOURITE,
  GET_PAYMENT_CONFIG,
  AUTHENTICATE,
  GET_COLLECTION,
  GET_COLLECTIONS,
  GET_USERS,
  GET_USER,
  GET_CATEGORIES,
  GET_ADMIN,
  GET_CONFIG,
  GET_SELL_NFTS,
  GET_SELL_NFT,
  GET_NFT_BY_TOKEN_ID,
  CHECK_NAME,
  USERS_COLLECTIONS,
  USER_REWARDS,
  NFTS_BY_NFT_IDS,
  USER_SELL_NFT,
  USER_FAVOURITES,
  CONVERT_PRICE,
  DASHBOARD_STATS,
  GET_REWARDS,
  TOP_VIEWED_NFTS,
  GET_COLLECTION_BY_ID,
};
