import { Loader, MainContainer } from "components/common";
import { HorizontalBreak, MainCollectionsElement } from "./element";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { CollectionsHeading, CollectionsTable } from "./element";
import tick from "../../assets/tick.png";
import { Buttons } from "components/common";
import eth from "../../assets/ethblack.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { GetCollectionsHook } from "hooks";
import { useEffect, useState } from "react";
import { BsCurrencyDollar } from "react-icons/bs";
import { Select, Tabs } from "antd";

function MainCollections() {
  const { data, loading, error, getCollections } = GetCollectionsHook();
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    getCollections("", limit);
  }, []);

  const navigate = useNavigate();

  const style = {
    backgroundColor: "#9040C4",
    color: "#fff",
  };

  const operations = (
    <div className="selectorDiv">
      {/* <InputPicker
        data={selectorData}
        style={{ width: 224 }}
        defaultValue="Highest 24h Vol"
        size="lg"
      /> */}
      {/* <Form.Select>
        <option hidden>Highest 24h Vol</option>
        <option>Highest 24h Vol</option>
        <option value="1">Recent Activity</option>
        <option value="2">Price: High To Low</option>
        <option value="3">Price: Low To High</option>
      </Form.Select> */}
      <Select
        defaultValue="Highest 24h Vol"
        dropdownStyle={style}
        options={[
          {
            value: "Highest 24h Vol",
            label: "Highest 24h Vol",
          },
          {
            value: "Recent Activity",
            label: "Recent Activity",
          },
          {
            value: "Price: High To Low",
            label: "Price: High To Low",
          },
          {
            value: "Price: Low To High",
            label: "Price: High To Low",
          },
        ]}
      />
    </div>
  );

  return (
    <MainCollectionsElement>
      {loading && <Loader content="Loading Collections..." />}
      <MainContainer>
        <CollectionsHeading>Collections</CollectionsHeading>
        <Tabs
          defaultActiveKey="1"
          tabBarExtraContent={operations}
          items={[
            {
              label: `All`,
              key: "1",
              children: (
                <>
                  <CollectionsTable>
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Collection</TableCell>
                            <TableCell align="right">Floor</TableCell>
                            <TableCell align="right">24h vol</TableCell>
                            <TableCell align="right">Total vol</TableCell>
                            <TableCell align="right">Owners</TableCell>
                            <TableCell align="right">Items</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data &&
                            data.map((row, index) => (
                              <TableRow key={row._id}>
                                <TableCell component="th" scope="row">
                                  <img
                                    className="tableImg me-3"
                                    width={40}
                                    alt=""
                                    src={row.logo}
                                  />
                                  <p
                                    onClick={() =>
                                      navigate(`/collection/${row?._id}`)
                                    }
                                  >
                                    {row.name}
                                  </p>
                                  <img
                                    className="img-fluid"
                                    alt=""
                                    src={tick}
                                  />
                                </TableCell>
                                <TableCell align="right">
                                  <img className="img-fluid" src={eth} />
                                  1.5
                                  <br />
                                  <span>+1.42%</span>
                                </TableCell>
                                <TableCell align="right">
                                  <img className="img-fluid" src={eth} />
                                  {row.volume24h}
                                  <br />
                                  <span>{row.volumeDiff}%</span>
                                </TableCell>
                                <TableCell align="right">
                                  <img className="img-fluid" src={eth} />
                                  {row.totalVolume}
                                </TableCell>
                                <TableCell align="right">
                                  {row.owners}
                                </TableCell>
                                <TableCell align="right">
                                  {row.items.length}
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </CollectionsTable>
                  {/* <BtnContainer>
                    <Buttons
                      onClick={() => {
                        getCollections("", limit + 10);
                        setLimit(limit + 10);
                      }}
                      explore
                    >
                      Load More
                    </Buttons>
                  </BtnContainer> */}
                </>
              ),
            },
            {
              label: `Verified`,
              key: "2",
              disabled: true,
              children: `Content of Tab Pane 2`,
            },
          ]}
        />
      </MainContainer>
    </MainCollectionsElement>
  );
}

export default MainCollections;
