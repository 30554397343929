import styled from "styled-components";

export const MainSection = styled.section`
  background-color: ${({ theme }) => theme.backgroundColor};
  overflow: hidden;
  min-height: 100vh;

  .card-wrapper {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 0px;
    padding-bottom: 8rem;

    @media (max-width: 1399.98px) {
      grid-template-columns: repeat(4, 1fr);
    }
    @media (max-width: 1210px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 991.98px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 575.98px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .first-explore-select.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border-radius: 1.75rem !important;
    border: 1px solid ${({ theme }) => theme.selectText} !important;
    background-color: transparent;
    color: ${({ theme }) => theme.selectText} !important;
  }

  .select-div {
    margin-top: 2rem;
    display: flex;
    justify-content: end;
    align-items: end;
  }
`;

export const NoDataFoundComDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10rem;

  @media (max-width: 991.98px) {
    margin-top: 2rem;
  }
`;
