import { createSlice } from "@reduxjs/toolkit";


export const viewsDataSlice = createSlice({
    name: "favourite data slice",
    initialState: {
        views: null
    },
    reducers: {
        setViews: (state, action) => {
            return {
                ...state,
                views: action.payload
            }
        }
    }
});

export const { setViews } = viewsDataSlice.actions;
export const viewsDataReducer = viewsDataSlice.reducer;
