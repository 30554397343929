import styled from "styled-components";

export const ModalWrapper = styled.div`
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid #e7e7e7;
  /* background: #fff; */
  background-color: ${({ theme }) => theme.selectdropdownbgColor} !important;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27);
  padding: 1rem 2rem 2rem 2rem;
  position: relative;

  .close-icon {
    position: absolute;
    top: 5px;
    right: 10px;
  }

  .form-label {
    /* color: var(--secondary, #3e3e3e); */
    color: ${({ theme }) => theme.noDataFoundColor} !important;
    font-family: Barlow;
    font-size: 1.56644rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    margin-top: 2rem;
  }

  .form-control {
    border-radius: 0.625rem;
    border: 1px solid #e7e7e7;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27);
    margin-top: 1.5rem;
    background-color: transparent;
    color: ${({ theme }) => theme.inputtext} !important;
    &::placeholder {
      color: #c9c9c9;
      font-family: Barlow;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;
    }
  }
`;

export const TransferBtn = styled.button`
  color: #fff;
  font-family: Barlow;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  border-radius: 1.75rem;
  border: 1px solid #00b7ff;
  background-color: #00b7ff;
  width: 8.5625rem;
  height: 2.1875rem;
  margin-top: 2rem;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &:disabled {
    background: ${({ theme }) => theme.disabledBtnBG};
    border: 1px solid ${({ theme }) => theme.disabledBtnBorder} !important;
    color: rgb(183, 189, 202);
  }
`;
