export const lightTheme = {
  button: {
    primary: "#f45511",
    primaryBtn: "#f45511",
    secondaryBtn: "green",
    connectBtnText: "#000000",
  },

  model: {
    connectModelbg: "#e5f1ea",
    connectModelbtnBgColor: "#fff",
    connectModelbtnTextColor: "#000",
    inputBgColor: "transparent",
    inputColor: "#fff",
  },

  backgroundColor: "#ffffff",
  navLinks: "rgba(107, 107, 107, 1)",
  navBorder: "rgba(107, 107, 107, 1)",
  backArrow: "rgba(62, 62, 62, 1)",
  headerColor: "#3E3E3E",
  filterSubTextColor: "#6B6B6B",
  selectBorder: "#E7E7E7",
  cardBorder: "#00B7FF",
  cardimgBorder: "#00b7ff",
  cardBG: "#FFF",
  cardText: "#3E3E3E",
  cardSubText: "#6B6B6B",
  cardBtnBorder: "#00B7FF",
  cardInnerContent: "#6B6B6B",
  cardInnerContentValue: "#3E3E3E",
  viewContainerText: "#6B6B6B",
  viewBtnText: "#fff",
  cardDivider: "#00B7FF",
  selectText: "#6B6B6B",
  footertext: "#3e3e3e",
  disabledBtnBG: "rgb(250, 250, 252)",
  disabledBtnBorder: "#b7bdca",
  selectdropdownbgColor: "#ffffff",
  selectdropdownborderColor: "#ffffff",
  selectitemcontentColor: "#3E3E3E",
  noDataFoundColor: "#3E3E3E",
  inputtext: "#000",
  selectdropdownhoverColor: "#f5f5f5",
  tablebgColor: "#ffffff",
  tabletitleColor: "#3e3e3e",
  tableaddressColor: "#00B7FF",
  tabletextColor: "#000",
  tableborderColor: "#ffffff",
  tablecloseiconColor: "#6B6B6B",
  datepickerbgColor: "#ffffff",
  dateselectbgColor: "#e6f7ff",
};

export const darkTheme = {
  button: {
    primary: "#f45511",
    primaryBtn: "#f45511",
    secondaryBtn: "green",
    connectBtnText: "#ffffff",
  },

  model: {
    connectModelbg: "#e5f1ea",
    connectModelbtnBgColor: "#fff",
    connectModelbtnTextColor: "#000",
    inputBgColor: "transparent",
    inputColor: "#fff",
  },

  backgroundColor: "#0E0F15",
  navLinks: "#727989",
  navBorder: "#727989",
  backArrow: "#727989",
  headerColor: "rgba(0, 183, 255, 1)",
  filterSubTextColor: "#FBFBFC",
  selectBorder: "#727989",
  cardBorder: "#727989",
  cardimgBorder: "#727989",
  cardBG: "#0E0F15",
  cardText: "#FBFBFC",
  cardSubText: "#727989",
  cardBtnBorder: "#727989",
  cardInnerContent: "#FBFBFC",
  cardInnerContentValue: "#727989",
  viewContainerText: "#727989",
  viewBtnText: "#0E0F15",
  cardDivider: "#727989",
  selectText: "#FBFBFC",
  footertext: "#727989",
  disabledBtnBG: "transparent",
  disabledBtnBorder: "#727989",
  selectdropdownbgColor: "#0E0F15",
  selectdropdownborderColor: "#727989",
  selectitemcontentColor: "#c9c9c9",
  noDataFoundColor: "#00B7FF",
  inputtext: "#fff",
  selectdropdownhoverColor: "#1C1D25",
  tablebgColor: "#0E0F15",
  tabletitleColor: "#FBFBFC",
  tableaddressColor: "#fff",
  tabletextColor: "#00B7FF",
  tableborderColor: "#727989",
  tablecloseiconColor: "#727989",
  datepickerbgColor: "#0E0F15",
  dateselectbgColor: "#1890ff",
};
