// import ToastMessage from "components/common/toastMessage";
import { ToastMessage } from "components/common";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { removeUserOxData } from "store/redux/slices/user0xSlice";
// import { logOutAccount } from "store/redux/slices/wallet3Connect/web3ConnectSlice";
import { useAppDispatch } from "store/store";
// import { BrowserUtility } from "utility";

export const CommonHook = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  // const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (error) {
      if (error.status === 401) {
        // navigate("/login");
        ToastMessage("Error", error.error, "error");
      }
      if (error.status === 403) {
        ToastMessage("Error", error.error, "error");
      } else {
        if (error.error && typeof error.error === "string") {
          ToastMessage("Error", error.error, "error");
        } else if (typeof error === "string") {
          ToastMessage("Error", error, "error");
        } else if (
          (error.error && typeof error.error != "string") ||
          !error.error
        )
          ToastMessage("Error", "Something went wrong", "error");
      }
    }
  }, [error]);

  return {
    data,
    setData,
    loading,
    setLoading,
    setError,
    error,
  };
};
