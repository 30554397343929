import {
  BreadCrumbComp,
  MainCol,
  MainContainer,
  MainRow,
} from "components/common";
import { InfoText, MainHeader, MintNftsWrapper, SelectDiv } from "./element";
import { Select } from "antd";
import { useState } from "react";
import MintNFTsContentCom from "./mintNFTsContentCom";
import { GetCreateSchemaHook } from "hooks/createSchemaHook";
import { GetTemplateHook } from "hooks/templateHook";
import Loading from "components/common/loader";
import { useAppSelector } from "store/store";

const data = [
  {
    id: 1,
    text: "Starship NFT Game",
    link: "/admin/starship",
  },
  {
    id: 2,
    text: "Mint NFTs",
    link: "",
  },
];

function MintNftsCom() {
  const { data: schemasList, loading } = GetCreateSchemaHook();
  const { data: templateList, loading: loads } = GetTemplateHook();
  const [schema, setSchema] = useState("");
  const [template, setTemplate] = useState("");
  const [templates, setTemplates] = useState([]);
  const { config } = useAppSelector((state) => state.configData);

  return (
    <MintNftsWrapper>
      {(loading || loads) && <Loading content={"Loading"} />}
      <MainContainer fluid>
        <BreadCrumbComp data={data} />
        <MainHeader>Mint NFTs</MainHeader>
        <SelectDiv>
          <MainRow className="select-row">
            <MainCol sm={6}>
              <Select
                showSearch
                className="select"
                placeholder="Select Schema"
                optionFilterProp="children"
                value={schema || "Select Schema"}
                onChange={(value) => {
                  let ac = templateList.filter(
                    (d) => d?.schema_id?._id == value
                  );
                  setTemplates(ac);
                  setSchema(value);
                  setTemplate("");
                }}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={schemasList?.map((x) => {
                  return {
                    value: x._id,
                    label: `${x.schema_name}   (${x._id?.slice(-5)}) `,
                  };
                })}
              />
            </MainCol>
            <MainCol sm={6}>
              <Select
                showSearch
                className="select"
                placeholder="Select Template"
                optionFilterProp="children"
                value={template || "Select Template"}
                onChange={(value) => {
                  setTemplate(value);
                }}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={templates?.map((x) => {
                  return {
                    value: x._id,
                    label: ` #${x?.nft_id} ${
                      x?.template_attributes[0]?.attribute_value
                    }   (${
                      x?.max_supply == 0 ||
                      +x?.max_supply >= +config?.UINT256_MAX
                        ? "∞"
                        : x?.max_supply
                    })`,
                  };
                })}
              />
            </MainCol>
          </MainRow>
        </SelectDiv>
        <InfoText>Select a schema and template</InfoText>

        {schema && template && (
          <MintNFTsContentCom
            template={template}
            setSchema={setSchema}
            setTemplate={setTemplate}
          />
        )}
      </MainContainer>
    </MintNftsWrapper>
  );
}

export default MintNftsCom;
