import React, { useEffect } from "react";
import * as L from "./adminLoginElements";
import { credentialsValidate } from "components/validation/customValidator";
import { useAppDispatch, useAppSelector } from "store/store";
import { setLoginData } from "store/redux/slices/adminSlices/loginDataSlice";
import { ErrorMessage } from "components/nftForm/nftFormElement";
import { useNavigate } from "react-router-dom";
import { AuthenticateHook, LoginHook, useForm } from "hooks";
import { Loader } from "components/common";

const AdminLogin = () => {
  const { doLogin, loading, error } = LoginHook();
  const { loginData } = useAppSelector((state) => state.login);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { handleSubmit, errors } = useForm(
    doLogin,
    credentialsValidate,
    loginData
  );

  const { loading: authLoading, data: authData } = AuthenticateHook();

  useEffect(() => {
    authData?.authenticate && navigate("/admin-dashboard");
  }, [authData]);

  return (
    <L.Content>
      <>
        {loading && <Loader content="authenticating..." />}
        <L.LoginMain>
          <L.LoginContainer>
            <L.Heading>LOGIN</L.Heading>
            <L.EmailInputField
              value={loginData.username}
              onChange={(e) =>
                dispatch(
                  setLoginData({ ...loginData, username: e.target.value })
                )
              }
              placeholder="email"
              type="text"
            />
            <ErrorMessage>{errors.username && errors.username}</ErrorMessage>
            <L.PasswordInputField
              value={loginData.password}
              onChange={(e) =>
                dispatch(
                  setLoginData({ ...loginData, password: e.target.value })
                )
              }
              placeholder="Password"
              type={"password"}
              pattern="/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
            />
            <ErrorMessage>{errors.password && errors.password}</ErrorMessage>
            <L.LoginButton
              disabled={loading ? true : false}
              onClick={handleSubmit}
            >
              Login
            </L.LoginButton>
            {error ? <ErrorMessage>{error.message}</ErrorMessage> : ""}
          </L.LoginContainer>
        </L.LoginMain>
      </>
    </L.Content>
  );
};

export default AdminLogin;
