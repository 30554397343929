import SchemaComp from "components/starship/schema";
import SchemaAndTemplateComp from "components/starship/schema";
import React from "react";

const SchemaPage = () => {
  return (
    <div>
      <SchemaComp />
    </div>
  );
};

export default SchemaPage;
