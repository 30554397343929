import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { ADD_TO_BLACKLIST } from "gql/mutations";
import { AUTHENTICATE, CONVERT_PRICE, GET_CONFIG, LOGIN } from "gql/queries";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetLoginData } from "store/redux/slices/adminSlices/loginDataSlice";
import { setNetworkConfig } from "store/redux/slices/web3/configSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { ContractUtility } from "utility";




export const GetConfigHook = () => {

  const dispatch = useDispatch();
  const { data } = useQuery(GET_CONFIG);

  useEffect(() => {
    data && dispatch(setNetworkConfig(data.get_config));
    data && ContractUtility.setConfig(data.get_config)
  }, [data]);
  return {
    data
  }
}


export const AuthenticateHook = () => {
  const { data, loading, error } = useQuery(AUTHENTICATE, {
    fetchPolicy: "network-only"
  });

  return {
    loading, data, error
  }
}

export const LogoutHook = () => {
  const [add_blacklist_token, { data, error, loading }] = useMutation(ADD_TO_BLACKLIST)
  const navigate = useNavigate()

  useEffect(() => {
    if (data) {
      localStorage.removeItem('access_token');
      navigate('/admin-login')
    }
    error && error.message === 'invalid token' && navigate('/admin-login')
  }, [error, data])

  return {
    add_blacklist_token
  }

}

export const LoginHook = () => {
  const [login, { loading, error, data }] = useLazyQuery(LOGIN);
  const { loginData } = useAppSelector((state) => state.login);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();


  const doLogin = () => {
    login({ variables: loginData });
  };

  useEffect(() => {
    data && data?.login?.authenticated && dispatch(resetLoginData()) && localStorage.setItem("access_token", data?.login?.token);
    !error && data && navigate("/admin-dashboard");
  }, [data]);


  return {
    loading, error, doLogin, data
  }

}

export const ConvertPriceHook = () => {
  const [convert_price, { loading, error, data }] = useLazyQuery(CONVERT_PRICE);


  const getUSD = (values) => {
    convert_price({ variables: { values } })
  }

  return {
    loading, error, data: data?.convert_price?.data.quote.USD.price || 0, getUSD
  }

}