import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import { Form, Input, Button, Space } from "antd";

export const MainModel = styled.div``;

export const ModelsData = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  width: 500px;
  background: #fff;
  border-radius: 15px;
  text-align: center;
  border-radius: 30px 30px 30px 30px;
  @media (max-width: 420px) {
    width: 370px;
    /* height: 300px; */
  }
  @media (max-width: 380px) {
    width: 330px;
    /* height: 300px; */
  } ;
`;

export const ModalHeader = styled.div``;

export const ModalTitle = styled.h4`
  margin-top: 0.9rem;
`;
export const ModalDesc = styled.p`
  padding: 0.8rem;
  font-weight: 600;
  color: #707a83;
`;

export const ModalForm = styled(Form)``;
export const FormList = styled(Form.List)``;
export const ModalInput = styled(Input)`
  background: transparent;
  margin-top: 1rem;
  padding: 0.7rem;

  .ant-input::placeholder {
    color: black;
  }
  & {
  }
  &:focus {
    box-shadow: none;
  }
`;
export const CloseDiv = styled.div`
  background: transparent;
  padding: 0.7rem;
  transform: translateX(8px);
  border: 1px solid #d9d9d9;
  border-right: none;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
`;
export const CloseDiv2 = styled.div`
  background: transparent;
  padding: 0.7rem;
  transform: translateX(8px);
  /* transform: translateY(9px); */
  margin-top: 1.35rem;
  border: 1px solid #d9d9d9;
  border-right: none;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
`;

export const AddMoreBtn = styled.button`
  border-radius: 10px;
  font-size: 16px;
  font-weight: 600;
  background: linear-gradient(98.6deg, #261ce8 6.03%, #511ce8 99.87%);
  display: block;
  margin: 0 auto;
  height: 45px;
  color: #fff;
  border: none;
  padding: 0px 20px;
`;

export const FormSaveBtn = styled.button`
  border-radius: 10px;
  font-size: 16px;
  font-weight: 600;
  background: linear-gradient(98.6deg, #261ce8 6.03%, #511ce8 99.87%);
  display: block;
  margin: 0 auto 2rem auto;
  height: 45px;
  color: #fff;
  border: none;
  padding: 0px 20px;
`;

// Report Model

export const ReportModels = styled.div``;

export const ReportModelsData = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  width: 400px;
  height: 320px;
  background: #fff;
  border: "2px solid #000";
  text-align: center;
  border-radius: 30px 30px 30px 30px;
  @media (max-width: 420px) {
    width: 370px;
    height: 320px;
  }

  @media (max-width: 380px) {
    width: 330px;
    height: 320px;
  }
`;
export const ReportBtn = styled.button`
  border: none;
  background: #e61a5a;
  color: white;
  padding: 0.3rem 1.4rem;
  border-radius: 20px;
  font-size: 1rem;
  margin-top: 1rem;
`;

export const ReportForm = styled(Form)`
  margin-top: 1rem;
`;

export const InputField = styled(Input)`
  padding: 0.3rem 1.4rem;
  font-size: 1rem;
`;

export const InputTextArea = styled(Input.TextArea)`
  margin-top: 1rem;
  padding: 0.3rem 1.4rem;
`;
