import { MainContainer, ToastMessage } from "components/common";
import {
  JoinedDate,
  ProfileWrapper,
  TabsDiv,
  TopSectionDiv,
  BackImgDiv,
  UserName,
} from "./element";
import { Image } from "react-bootstrap";
import {
  darkfiltersimg,
  darkprofilebgimg,
  filtersimg,
  filtersselected,
} from "assets";
import { AiOutlineCopy } from "react-icons/ai";
import { Tabs } from "antd";
import CollectionsTab from "./collectionsTab";
import ActiveListings from "./activeListings";
import SoldTab from "./soldTab";
import { useEffect, useState } from "react";
import { useAppSelector } from "store/store";
import { CommonUtility, DateUtility } from "utility";
import { GetNftsByUserHook } from "hooks/mintHook";
import Loading from "components/common/loader";
import { UpdateUserProfileHook } from "hooks";
import { profilebgimg } from "assets";
import profileIcons from "assets/profile-icon.png";
import { FaPencilAlt } from "react-icons/fa";
import Packs from "./packs";
import { GetPackHook } from "hooks/packHook";
import { useLocation, useNavigate } from "react-router-dom";
import { GetUserDropMintsHook } from "hooks/dropHook";
function ProfileCom() {
  const { account } = useAppSelector((state) => state.web3Connect);
  const [filter, setFilter] = useState({});
  const {
    data: userNfts,
    getNftsByUser,
    loading,
    setData,
    schemas,
    drops,
  } = GetNftsByUserHook(account);
  const {
    data: userDrops,
    getUserDropMints,
    loading: userDropLoading,
  } = GetUserDropMintsHook(account);
  const { user } = useAppSelector((x) => x.user);
  const { update, loading: updating, setLoading } = UpdateUserProfileHook();

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedBGFile, setSelectedBGFile] = useState(null);
  const [showFilters, setShowFilters] = useState(false);
  const [activeKey, setActiveKey] = useState("1");
  const [tabs, setTabs] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  const [mint, setMint] = useState([]);
  useEffect(() => {
    if (!account) {
      setData(null);
    }
  }, [account]);
  useEffect(() => {
    let arr = [];
    if (userNfts) {
      for (let i = 0; i < userNfts.length; i++) {
        if (userNfts[i].drop_id == null) {
          arr.push(userNfts[i]);
        } else {
          if (+userNfts[i].drop_id.reveal_time <= Date.now() / 1000)
            arr.push(userNfts[i]);
        }
      }
    }
    setMint(arr);
  }, [userNfts]);

  useEffect(() => {
    async function profile() {
      update(account, {
        profileImage: selectedFile,
      });
    }
    selectedFile && profile();
  }, [selectedFile]);

  useEffect(() => {
    async function profile() {
      update(account, {
        coverImage: selectedBGFile,
      });
    }

    selectedBGFile && profile();
  }, [selectedBGFile]);

  const { theme } = useAppSelector((x) => x.model);

  const [page, setPage] = useState(30);
  const {
    data: packs,
    loading: packsLoading,
    total,
    getPacks,
  } = GetPackHook(page);
  const handleScroll = () => {
    const scrollPosition = window.innerHeight + window.pageYOffset;
    const scrollHeight = document.documentElement.scrollHeight;
    if (scrollPosition >= scrollHeight - 180 && !packsLoading) {
      if (page < total) {
        setPage(page + 20);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [packs, page, total, packsLoading]);

  const handleTabChange = (activeKey) => {
    setTabs(activeKey);
    if (activeKey == 1) {
      navigate("/starship/profile/collections");
    } else if (activeKey == 2) {
      navigate("/starship/profile/favorites");
    } else if (activeKey == 3) {
      navigate("/starship/profile/activity");
    } else if (activeKey == 4) {
      navigate("/starship/profile/packs");
    } else {
      navigate("/launchpad-list/collections");
    }
  };

  useEffect(() => {
    if (location.pathname === "/starship/profile/collections") {
      navigate("/starship/profile/collections");
      setTabs("1");
    } else if (location.pathname === "/starship/profile/favorites") {
      navigate("/starship/profile/favorites");
      setTabs("2");
    } else if (location.pathname === "/starship/profile/activity") {
      navigate("/starship/profile/activity");
      setTabs("3");
    } else if (location.pathname === "/starship/profile/packs") {
      navigate("/starship/profile/packs");
      setTabs("4");
    } else {
      setTabs("1");
    }
  }, []);

  return (
    <ProfileWrapper>
      {(updating || userDropLoading || loading) && (
        <Loading content={"Loading"} />
      )}
      <BackImgDiv>
        <label htmlFor="bgimg" style={{ width: "100%", cursor: "pointer" }}>
          <Image
            src={
              user?.coverImage
                ? user?.coverImage
                : theme == "light"
                ? profilebgimg
                : darkprofilebgimg
            }
            className="profile-bg"
            onError={(e) =>
              (e.target.src =
                theme == "light" ? profilebgimg : darkprofilebgimg)
            }
          />
          <div className="overlay">
            <FaPencilAlt className="edit-icon" />
          </div>
        </label>
        <input
          type="file"
          style={{ display: "none" }}
          name="bgimg"
          id="bgimg"
          accept="image/png, image/gif, image/jpeg"
          onChange={(e) =>
            user.walletAddress && setSelectedBGFile(e.target.files[0])
          }
        />
        <TopSectionDiv>
          <div>
            {account ? (
              <label htmlFor="img" style={{ width: "100%", cursor: "pointer" }}>
                <Image
                  src={user?.profImage || profileIcons}
                  onError={(e) => (e.target.src = profileIcons)}
                  width={46}
                  height={46}
                  style={{ borderRadius: "50%" }}
                />
              </label>
            ) : (
              <label
                htmlFor="img22"
                style={{ width: "100%", cursor: "pointer" }}
              >
                <Image
                  src={profileIcons}
                  width={46}
                  height={46}
                  style={{ borderRadius: "50%" }}
                />
              </label>
            )}
          </div>

          <input
            type="file"
            style={{ display: "none" }}
            name="img"
            onChange={(e) => user && setSelectedFile(e.target.files[0])}
            id="img"
            accept="image/png, image/gif, image/jpeg"
          />
          <UserName>Starship User</UserName>
          <p className="wallet-text">
            {CommonUtility.addressConvertor(account)}
            <AiOutlineCopy
              color="#00b7ff"
              cursor="pointer"
              onClick={() => {
                navigator.clipboard.writeText(account).then(
                  function () {
                    console.log("Text copied to clipboard");
                  },
                  function () {
                    console.log("Error copying text to clipboard");
                  }
                );
                ToastMessage("Success!", "Successfully Copied!", "success");
              }}
            />
          </p>
          {user?.walletAddress && (
            <JoinedDate>
              Joined {DateUtility?.monthYear(user?.createdAt)}
            </JoinedDate>
          )}
        </TopSectionDiv>
      </BackImgDiv>

      <MainContainer fluid>
        <TabsDiv>
          {account && (
            <div>
              {tabs === "1" && mint?.length > 0 && (
                <Image
                  src={
                    showFilters
                      ? filtersselected
                      : theme == "light"
                      ? filtersimg
                      : darkfiltersimg
                  }
                  className="filter-img"
                  onClick={() => {
                    setShowFilters(!showFilters);
                  }}
                  width={35}
                />
              )}
            </div>
          )}
          <Tabs
            activeKey={tabs}
            onChange={handleTabChange}
            centered
            defaultActiveKey={activeKey}
            items={[
              {
                label: "NFTs",
                key: "1",
                children: (
                  <CollectionsTab
                    data={mint}
                    getNftsByUser={getNftsByUser}
                    showFilters={showFilters}
                    loading={loading}
                    setFilter={setFilter}
                    filter={filter}
                    schemas={schemas}
                    dropList={drops}
                  />
                ),
              },
              {
                label: "Favorites",
                key: "2",
                children: <ActiveListings />,
              },
              {
                label: "Activity",
                key: "3",
                children: <SoldTab />,
              },
              {
                label: "Packs",
                key: "4",
                children:
                  location.pathname === "/starship/profile/packs" ? (
                    <Packs packs={userDrops} getPacks={getUserDropMints} />
                  ) : (
                    <Packs packs={packs} getPacks={getPacks} />
                  ),
              },
            ]}
          />
        </TabsDiv>
      </MainContainer>
    </ProfileWrapper>
  );
}

export default ProfileCom;
