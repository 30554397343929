import styled from "styled-components";
import detailsbg from "../../assets/details-bg.png";

export const AllNftWrapper = styled.div`
  overflow: hidden;
  background-image: url(${detailsbg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .container {
    @media (max-width: 575.98px) {
      padding: 0px 15px !important;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      font-family: "Figtree";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #1c1b1f;
      text-align: center;
      width: 150px;
    }

    .ant-tabs-tab {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 150px;
    }

    .ant-tabs-ink-bar {
      background: #261ce8;
    }

    .ant-tabs-bottom > .ant-tabs-nav:before,
    .ant-tabs-bottom > div > .ant-tabs-nav:before,
    .ant-tabs-top > .ant-tabs-nav:before,
    .ant-tabs-top > div > .ant-tabs-nav:before {
      border-bottom: none;
    }
  }

  .mainHeader {
    font-family: "Anton";
    font-style: normal;
    font-weight: 400;
    font-size: 64px;
    line-height: 100%;
    text-transform: uppercase;
    color: #3e3e3e;
    margin: 2rem 0rem;
  }

  .filterAllInput {
    height: 32px;
    border-radius: 10px;
    margin-top: 2rem;
    border: 1px solid #7f8c8d;
    width: 100%;
    padding: 0.5rem;

    ::placeholder {
      padding-left: 1rem !important;
    }
  }

  .buyTxt {
    font-family: "Figtree";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #261ce8;
    padding-bottom: 20px;
  }

  .filterFooter {
    border-bottom: 1px solid #e7e0ec;
  }

  .priceRange {
    font-family: "Figtree";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #1c1b1f;
  }
`;

export const MinMaxMain = styled.div`
  padding-bottom: 20px;
  border-bottom: 1px solid #e7e0ec;

  .minMaxMain {
    display: flex;
    gap: 0.7rem;
    flex: 1;
    /* flex-wrap: wrap; */
    input {
      height: 48px;
      width: 50%;
      border: none !important;
      padding: 0.5rem;
      background: #f0efff;
      border-radius: 4px;

      &:focus {
        border: none !important;
      }

      ::placeholder {
        padding-left: 1rem !important;
      }
    }
  }
`;

export const PanelDiv = styled.div`
  margin-top: 1rem;

  .rs-panel-collapsible > .rs-panel-header > .rs-icon {
    background-color: #9040c4;
    color: #fff;
    width: 26px;
    height: 26px;
    transform: translateY(-3px);
    border-radius: 4px;
  }

  .rs-panel-title {
    font-family: "Figtree";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #261ce8;
  }

  .rs-panel-body,
  .rs-panel-header {
    padding: 20px 0px;
  }

  .ant-input-affix-wrapper {
    background: #f0efff;
    border: 1px solid #e7e0ec;
    border-radius: 4px;
  }

  .ant-input-affix-wrapper > input.ant-input {
    background: #f0efff;
    padding: 0px 5px;
    height: 36px;
  }
`;

export const CollectionWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1.5rem;
  cursor: pointer;
  .imgWrapper {
    flex: 1;

    .img-fluid {
      height: 30px;
      border-radius: 40px;
    }
  }

  .textWrapper {
    flex: 4;
    display: flex;
    flex-direction: column;
  }

  .upperText {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .collection {
      font-family: "Figtree";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      color: #000000;
      margin: 0;
    }

    .price {
      font-family: "Figtree";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      color: #261ce8;
      margin: 0;
    }
  }

  .lowerText {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;

    .floorPrice {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #3e3e3e;
      margin: 0;
    }

    .time {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #636363;
      margin: 0;
    }
  }
`;

export const MainExploreCard = styled.div`
  overflow: hidden;

  .card {
    width: 285px;
    border-radius: 9px;
    background-color: #ffffff;
    filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.08));
    border-radius: 9px;
    min-height: 475px;

    .cardImage {
      height: 330px !important;
    }

    .img-fluid .mainImage {
      height: 320px;
      position: relative;
      z-index: 1;
      opacity: 0.1;
    }

    .heartIcon {
      position: absolute;
      z-index: 10;
      left: 90%;
      top: 1%;
    }

    .card-title {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 23px;
      color: #000000;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 15px;

      .img-fluid {
        height: 42px;
        width: 42px;
        border-radius: 50px;
      }
    }

    .card-text {
      font-family: "Figtree", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: #000000;
      margin-top: 20px;
    }
  }
`;

export const BottomContainer = styled.div`
  margin: 15px 0px 10px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PriceText = styled.h6`
  font-family: "Figtree", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 29px;
  color: #000000;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;

  .css-1e6y48t-MuiButtonBase-root-MuiButton-root {
    background-color: #9040c4;
    min-width: 20px !important;
    padding: 3px !important;
    border-radius: 4px;

    &:hover {
      background-color: #9040c4;
    }
  }

  .ant-dropdown-trigger {
    background-color: #9040c4;
    border-radius: 4px;
    height: 30px;
    width: 30px;
    padding: 5px;
  }
`;

export const BuyNowBtn = styled.button`
  background: #9040c4;
  border-radius: 4px;
  font-family: "Figtree", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: -0.05em;
  color: #ffffff;
  padding: 8px 16px;
`;

export const SelectWrappper = styled.div`
  margin-top: 1rem;
  width: 100%;

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    /* background: linear-gradient(98.6deg, #261ce8 6.03%, #511ce8 99.87%); */
    
   /* background: linear-gradient(
      90deg,
      rgba(0, 170, 255, 1) 0%,
      rgba(116, 198, 238, 1) 52%,
      rgba(0, 212, 255, 1) 100%
    ); */
    background: #f0efff;

    border-radius: 4px;
    font-family: "Figtree";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: black;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-select-arrow {
    color: #ffffff;
  }
`;
