import styled from "styled-components";

export const CardsWrapper = styled.div`
  margin-top: 2rem;
  .card {
    /* width: 250px; */
    height: 355px;
    border-radius: 10px;
    box-shadow: 0px 0px 4.254847526550293px 0px rgba(0, 0, 0, 0.27);
    position: relative;
    background-color: red;
    border: 1.064px solid ${({ theme }) => theme.cardBorder} !important;
    background-color: ${({ theme }) => theme.cardBG};
  }

  .card-img,
  .card-img-bottom {
    /* padding: 1rem;
    border-radius: 10px;
    border: 1px solid #e7e7e7;
    width: 194px;
    height: 132px; */
  }

  .img-wrapper {
    padding-bottom: 0;

    img {
      border-radius: 0px !important;
    }
  }

  .card-title {
    color: var(--pimary, #00b7ff);
    text-align: center;
    font-family: Barlow;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem; /* 120% */
    text-transform: capitalize;
    margin-top: 0.5rem;
  }

  a {
    text-decoration: none !important;
    color: #000;
    display: block;
    text-align: right;

    margin-top: 3.5rem;
  }

  .three-dots {
    display: block;
    margin-left: auto;
    font-size: 24px;
    cursor: pointer;
    border-radius: 20px;
    padding: 3px;
    transition: 300ms ease-in-out all;

    &.dots-hovered {
      background-color: #00b7ff;
      color: #fff;
      transition: 300ms ease-in-out all;
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100% !important;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: left 0.3s ease;
    opacity: 0;
    border-radius: 10px;
  }

  // .card:hover img {
  //   transform: scale(1.1);
  // }

  .card:hover .overlay {
    left: 0;
    opacity: 1;
  }
`;

export const StatusDiv = styled.div`
  margin-top: 0.5rem;
  border-radius: 0.9375rem;
  border: 1px solid ${({ theme }) => theme.cardText} !important;
  padding: 0.1rem 0.8rem;
  // width: 9rem;

  h5 {
    color: ${({ theme }) => theme.cardText} !important;
    font-family: "Barlow";
    font-size: 0.78881rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
  }
`;
