import styled from "styled-components";
import Slider from "react-slick";
import bgImg from "assets/img1.jpg";
import headerbg from "../../assets/header-bg.png";
import { motion } from "framer-motion";

// Props

export const MainHeroSection = styled(motion.div)`
  background-image: url(${headerbg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 40px;
`;

export const HeroTitle = styled.h1`
  font-style: normal;
  font-weight: 600;
  font-size: 44px;
  line-height: 58px;
  color: #1a1a1a;
  margin-top: 8rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  text-align: start;

  @media (max-width: 991.98px) {
    flex-direction: column;
    justify-content: center;
    margin-top: 1rem;
    text-align: center;
  }

  @media (max-width: 575.98px) {
    font-size: 30px;
    text-align: center;
    margin-top: 3rem;
    gap: 10px;
  }

  .img-fluid {
  }
`;

export const LeftWrapper = styled.div`
  margin-left: 8rem;

  @media (max-width: 1399.98px) {
    margin-left: 0rem;
  }
`;

export const HeroDesc = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  color: #1a1a1a;
  margin-top: 20px;

  @media (max-width: 991.98px) {
    text-align: center;
  }

  @media (max-width: 416px) {
    font-size: 16px;
    text-align: center;
  }
`;

export const CardMain = styled.div`
  display: flex;
  gap: 1rem;
`;

export const HeroSlider = styled(Slider)``;

export const MainButtonDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 40px;
  margin-top: 4rem;

  @media (max-width: 991.98px) {
    justify-content: center;
    margin-top: 2rem;
  }

  @media (max-width: 575.98px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const CardInfoIcon = styled.img``;

export const CardInfoIconMain = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 0.5rem;
`;
