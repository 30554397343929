import { APIPath } from "utility/constant";
import { BaseService } from "./baseServices";

class Drop {
  createDrop = (data) => {
    return BaseService.post(APIPath.createDrop, data);
  };
  updateDrop = (data) => {
    return BaseService.post(APIPath.updateDrop, data);
  };
  getDrops = (page, showAlldrop) => {
    return BaseService.get(
      `${APIPath.getDrops}?page=${page}&showAlldrop=${showAlldrop}`
    );
  };
  getViewAllDrops = (page, filter) => {
    return BaseService.get(
      `${APIPath.getViewAllDrops}?page=${page}&filter=${JSON.stringify(filter)}`
    );
  };

  getDropsMints = () => {
    return BaseService.get(APIPath.getDropsMints);
  };

  getUserDropMints = (account) => {
    console.log("Service account", account);
    return BaseService.get(`${APIPath.userDropMints}/${account}`);
  };
  getDropNfts = (from, to, dropId) => {
    return BaseService.get(`${APIPath.getDropNfts}/${from}/${to}/${dropId}`);
  };
  updateRevealStatus = (hash) => {
    return BaseService.get(`${APIPath.updateRevealStatus}/${hash}`);
  };
}

const DropService = new Drop();
Object.freeze(DropService);
export { DropService };
