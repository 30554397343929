import React from "react";
import { CollectionAllComp } from "components";

const CollectionAll = () => {
  return (
    <div>
      <CollectionAllComp />
    </div>
  );
};

export default CollectionAll;
