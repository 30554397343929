import { Card } from "antd";
import * as C from "components/common";
import MainModel from "components/modal/mainModel";
import { GetUsersCollectionHook } from "hooks";
import { useState } from "react";
import {
  connectModelSlice,
  mainModel,
} from "store/redux/slices/helperSlices/modelSlice";
import { useAppDispatch, useAppSelector } from "store/store";

const UsersCollection = () => {
  //variable declaration
  const dispatch = useAppDispatch();

  //useAppSelection
  const { web3 } = useAppSelector((state) => state.web3Connect);

  // custom hooks
  const { data, loading } = GetUsersCollectionHook();

  //functions
  const connectModelFn = () => {
    dispatch(connectModelSlice(true));
  };

  return (
    <div>
      <MainModel />
      {loading ? <C.Loader content="Loading Collections" /> : ""}
      <br />
      <h4 style={{ color: "white", textAlign: "center" }}>My Collections</h4>
      <br />
      {web3 ? (
        <div>
          {data?.map((collection, id) => {
            return (
              <Card key={id}>
                <img src={collection.logo} />
                <br />
                <p style={{ margin: "0px" }}>
                  {" "}
                  <b>Name:</b> {collection.name}{" "}
                </p>
                <p style={{ margin: "0px" }}>
                  {" "}
                  <b>Category:</b> {collection.category}{" "}
                </p>
              </Card>
            );
          })}
        </div>
      ) : (
        <C.MainButton connectModel onClick={connectModelFn}>
          Connect Wallet
        </C.MainButton>
      )}
    </div>
  );
};

export default UsersCollection;
