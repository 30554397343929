import {
  BreadCrumbComp,
  MainCol,
  MainContainer,
  MainRow,
} from "components/common";
import { CreateBtn, MainTitle, Text, Title } from "../schema/elements";
import { BsInfoCircleFill } from "react-icons/bs";
import { AiOutlinePlus } from "react-icons/ai";
import { MainDev, NewTemplateBtn, SelectDiv } from "./elements";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Select } from "antd";
import { GetCreateSchemaHook } from "hooks/createSchemaHook";

const dataa = [
  {
    id: 1,
    text: "Starship NFT Game",
    link: "/admin/starship",
  },
  {
    id: 2,
    text: "Schemas and templates",
    link: "/starship/admin/schemas",
  },
];

const TemplateComp = () => {
  const location = useLocation();
  const queryString = location.search;
  const searchParams = new URLSearchParams(queryString);
  const id = searchParams.get("id");

  const navigate = useNavigate();

  const onSearch = (value) => {
    console.log("search:", value);
  };
  const { data, loading } = GetCreateSchemaHook();

  return (
    <div>
      <MainContainer fluid>
        <div style={{ marginTop: "3rem" }}>
          <BreadCrumbComp data={dataa} />
        </div>
        <Title>
          Schemas and templates&nbsp;
          <BsInfoCircleFill fontSize={15} />
        </Title>

        <div className="d-flex justify-content-between">
          <MainDev>
            <Link to="/starship/admin/schemas/create-schema">
              <CreateBtn>
                <AiOutlinePlus color="#ffffff" />
                &nbsp;&nbsp;Create a schema
              </CreateBtn>
            </Link>

            <SelectDiv>
              <div className="select-div">
                <Select
                  showSearch
                  placeholder="edit schema"
                  optionFilterProp="children"
                  onChange={(e) => {
                    navigate("/starship/admin/schemas/create-schema?id=" + e);
                  }}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={data?.map((x) => {
                    return { value: x?._id, label: x.schema_name };
                  })}
                />
              </div>
            </SelectDiv>

            <SelectDiv>
              <Select
                showSearch
                placeholder="choose schema"
                optionFilterProp="children"
                onChange={(e) => {
                  navigate("/starship/admin/template/create-template?id=" + e);
                }}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={data?.map((x) => {
                  return { value: x?._id, label: x.schema_name };
                })}
              />
            </SelectDiv>
          </MainDev>

          <Link to={"/starship/admin/template/create-template?id=" + id}>
            <NewTemplateBtn className="tbtn">
              <AiOutlinePlus color="#ffffff" />
              &nbsp;&nbsp;new template
            </NewTemplateBtn>
          </Link>
        </div>

        <MainRow>
          <MainCol lg={5}></MainCol>
          <MainCol lg={4}>
            <MainTitle>let’s create your first template</MainTitle>
            <Text>
              your schema has no template yet, let’s start creating one
            </Text>

            <Link to={"/starship/admin/template/create-template?id=" + id}>
              <NewTemplateBtn>
                <AiOutlinePlus color="#ffffff" />
                &nbsp;&nbsp;new template
              </NewTemplateBtn>
            </Link>
          </MainCol>
        </MainRow>
      </MainContainer>
    </div>
  );
};

export default TemplateComp;
