import { createSlice } from "@reduxjs/toolkit";


export const favouritesDataSlice = createSlice({
    name: "favourite data slice",
    initialState: {
        nftFavourite: {
            favourites: [],
            userFavourite: null
        }
    },
    reducers: {
        setFavourite: (state, action) => {
            return {
                ...state,
                nftFavourite: action.payload
            }
        },
        resetFavouriteData: (state) => {
            return {
                ...state,
                nftFavourite: {
                    favourites: [],
                    userFavourite: null
                }
            }
        }
    }
});

export const { setFavourite, resetFavouriteData } = favouritesDataSlice.actions;
export const favouritesDataReducer = favouritesDataSlice.reducer;
