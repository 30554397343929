export const transactionType = {
  BOUGHT: "BOUGHT",
  SOLD: "SOLD",
  TRANSFER: "TRANSFER",
};

export const CommonConstant = {
  // mode: "PRODUCTION",
  mode: "TESTING",
  nullTx: "0x0000000000000000000000000000000000000000000000000000000000000000",
  nullAddress: "0x0000000000000000000000000000000000000000",
};
export const Rarity = "Rarity";

export const ErrorConstant = {
  default: "Something went wrong",
};
export const ViewDropTypes = {
  All: "All",
  Upcoming: "Upcoming",
  Ended: "Ended",
  Live: "Live",
};

export const currentDateInSeconds = Math.floor(new Date().getTime() / 1000);
export const twoDecimal = /^\d+(\.\d{0,2})?$/;
export const filterTypes = {
  All: "All",
  Listed: "Listed for sale",
  NotListed: "Not listed",
};

export const Protocols = {
  ethereum: {
    name: "ethereum",
    chainId: {
      mainnet: "0x1",
      testnet: "0x4",
    },

    assetUrl: {
      mainnet: "https://etherscan.io/address",
      testnet: "https://rinkeby.etherscan.io/address",
    },
    txUrl: {
      mainnet: "https://etherscan.io/tx",
      testnet: "https://rinkeby.etherscan.io/tx",
    },
  },
  bsc: {
    name: "bsc",
    chainId: {
      mainnet: "0x38",
      testnet: "0x61",
    },
    assetUrl: {
      mainnet: "https://bscscan.com/address",
      testnet: "https://testnet.bscscan.com/address",
    },
    txUrl: {
      mainnet: "https://bscscan.com/tx",
      testnet: "https://testnet.bscscan.com/tx",
    },
  },
  polygon: {
    name: "polygon",
    chainId: {
      mainnet: "0x89",
      testnet: "0x13881",
    },
    assetUrl: {
      mainnet: "https://polygonscan.com/address",
      testnet: "https://mumbai.polygonscan.com/address",
    },
    txUrl: {
      mainnet: "https://polygonscan.com/tx",
      testnet: "https://mumbai.polygonscan.com/tx",
    },
  },
};

export const StorageConstant = {
  protocol: "protocol",
};

export const app = {
  IPFS_PATH: "https://ipfs.infura.io/ipfs",
};

export const RentalTypes = {
  collateral: "Collateral",
  nonCollateral: "NonCollateral",
};

export const regex = {
  URL: "^(?:(?:(?:https?|ftp):)?//)(?:S+(?::S*)?@)?(?:(?!(?:10|127)(?:.d{1,3}){3})(?!(?:169.254|192.168)(?:.d{1,3}){2})(?!172.(?:1[6-9]|2d|3[0-1])(?:.d{1,3}){2})(?:[1-9]d?|1dd|2[01]d|22[0-3])(?:.(?:1?d{1,2}|2[0-4]d|25[0-5])){2}(?:.(?:[1-9]d?|1dd|2[0-4]d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:.(?:[a-z\u00a1-\uffff]{2,})))(?::d{2,5})?(?:[/?#]S*)?$",
  NON_NEGATIVE: "^[1-9]+[0-9]*$",
  EMAIL: "^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,}$",
  CONTRACT_ADDRESS: "0x[a-fA-F0-9]{40}",
};

export const shareholders = {
  REFERRER: "REFERRER",
  COMPANY: "COMPANY",
  NETHTY: "NETHTY",
};
export const truncateAddress = (address) => {
  if (!address) return "No Account";
  const match = address.match(
    /^(0x[a-zA-Z0-9]{2})[a-zA-Z0-9]+([a-zA-Z0-9]{2})$/
  );
  if (!match) return address;
  return `${match[1]}…${match[2]}`;
};
