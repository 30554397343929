import React from "react";
import * as D from "./mainDashboardElement";

import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  BarChart,
  Bar,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import { chartData } from "components/data/chartData";
import { DashboardStatsHook } from "hooks";
import MainModel from "components/modal/mainModel";
import { useAppDispatch, useAppSelector } from "store/store";
import { connectModelSlice } from "store/redux/slices/helperSlices/modelSlice";
import { Loader, MainButton } from "components/common";
const MainDashboard = () => {
  const dispatch = useAppDispatch();
  const { web3 } = useAppSelector((state) => state.web3Connect);
  const { stats, statsLoading } = DashboardStatsHook();

  const connectModelFn = () => {
    dispatch(connectModelSlice(true));
  };

  return (
    <div>
      <D.MainContainer>
        {statsLoading && <Loader content="Loading data..." />}
        <MainModel />
        {web3 ? (
          <>
            <D.MainRow>
              <D.MainCol lg={3} md={6}>
                <D.MainCard>
                  <D.MainCardBody>
                    <D.MainRow>
                      <D.MainCol>
                        <D.MainTitle>Total users</D.MainTitle>
                      </D.MainCol>
                      <D.MainCol>
                        <D.ProfitValue>{stats?.users}</D.ProfitValue>
                      </D.MainCol>
                    </D.MainRow>
                    {/* <D.MainText>This Month</D.MainText>
                <D.MainProgress now={60} />
                <D.MainText>Last Month</D.MainText> */}
                  </D.MainCardBody>
                </D.MainCard>
              </D.MainCol>
              <D.MainCol lg={3} md={6}>
                <D.MainCard>
                  <D.MainCardBody>
                    <D.MainRow>
                      <D.MainCol>
                        <D.MainTitle>Total Minted</D.MainTitle>
                      </D.MainCol>
                      <D.MainCol>
                        <D.ProfitValue>{stats?.minted}</D.ProfitValue>
                      </D.MainCol>
                    </D.MainRow>
                    {/* <D.MainText>This Month</D.MainText>
                <D.MainProgress now={60} />
                <D.MainText>Last Month</D.MainText> */}
                  </D.MainCardBody>
                </D.MainCard>
              </D.MainCol>
              <D.MainCol lg={3} md={6}>
                <D.MainCard>
                  <D.MainCardBody>
                    <D.MainRow>
                      <D.MainCol>
                        <D.MainTitle>Total Supply</D.MainTitle>
                      </D.MainCol>
                      <D.MainCol>
                        <D.ProfitValue>{stats?.totalSupply}</D.ProfitValue>
                      </D.MainCol>
                    </D.MainRow>
                    {/* <D.MainText>This Month</D.MainText>
                <D.MainProgress now={60} />
                <D.MainText>Last Month</D.MainText> */}
                  </D.MainCardBody>
                </D.MainCard>
              </D.MainCol>
              <D.MainCol lg={3} md={6}>
                <D.MainCard>
                  <D.MainCardBody>
                    <D.MainRow>
                      <D.MainCol>
                        <D.MainTitle>Reports</D.MainTitle>
                      </D.MainCol>
                      <D.MainCol>
                        <D.ProfitValue>{stats?.reports}</D.ProfitValue>
                      </D.MainCol>
                    </D.MainRow>
                    {/* <D.MainText>This Month</D.MainText>
                <D.MainProgress now={60} />
                <D.MainText>Last Month</D.MainText> */}
                  </D.MainCardBody>
                </D.MainCard>
              </D.MainCol>
            </D.MainRow>
          </>
        ) : (
          <div className="d-flex justify-content-center mt-3 ">
            <MainButton connectModel onClick={connectModelFn}>
              Connect Wallet
            </MainButton>
          </div>
        )}
        {/* <D.MainContainer>
        <D.MainRow>
          <D.MainCol lg={8}>
            <D.RevenueCard>
              <D.RevenueCardHeader>
                Total Revenue <span>This Year</span>
              </D.RevenueCardHeader>
              <D.RevenueCardBody>
                <ResponsiveContainer width="97%" height={400}>
                  <AreaChart
                    data={chartData}
                    margin={{
                      top: 10,
                      right: 30,
                      left: 0,
                      bottom: 0,
                    }}
                  >
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Area
                      type="monotone"
                      dataKey="uv"
                      stackId="1"
                      stroke="#8884d8"
                      fill="#8884d8"
                    />
                    <Area
                      type="monotone"
                      dataKey="pv"
                      stackId="1"
                      stroke="#82ca9d"
                      fill="#82ca9d"
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </D.RevenueCardBody>
            </D.RevenueCard>
          </D.MainCol>
          <D.MainCol lg={4}>
            <D.RevenueCard>
              <D.RevenueCardHeader>
                Total Expense <span>This Year</span>
              </D.RevenueCardHeader>
              <D.RevenueCardBody>
                <ResponsiveContainer width="100%" height={250}>
                  <BarChart
                    data={chartData}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="pv" fill="#8884d8" />
                    <Bar dataKey="uv" fill="#82ca9d" />
                  </BarChart>
                </ResponsiveContainer>
              </D.RevenueCardBody>
              <hr style={{ marginBottom: "0.8rem" }} />
              <D.ExpenseTitle>Costs of Sales</D.ExpenseTitle>
              <hr style={{ marginTop: "-0.2rem" }} />

              <D.ExpenseTitle>Management costs</D.ExpenseTitle>
              <hr style={{ marginTop: "-0.2rem" }} />

              <D.ExpenseTitle>Financial costs</D.ExpenseTitle>
              <hr style={{ marginTop: "0rem" }} />
              <D.ExpenseTitle style={{ marginBottom: "0.7rem" }}>
                Other Operting Costs
              </D.ExpenseTitle>
            </D.RevenueCard>
          </D.MainCol>
        </D.MainRow>
      </D.MainContainer> */}
      </D.MainContainer>
    </div>
  );
};

export default MainDashboard;
