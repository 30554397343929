import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Web3 from "web3";

import WalletConnectProvider from "@walletconnect/web3-provider";

import {
  marketplaceContractAddress,
  marketplaceContractAbi,
} from "contract/marketplace";
import {
  nftMintingContractAddress,
  nftMintingContractAbi,
} from "contract/nftMinting";

import { ethers } from "ethers";
import { NftSwapV4 } from "@traderxyz/nft-swap-sdk";

// import { ContractUtility } from "utility";
import { ContractUtility } from "../../../../utility";
import UniversalProvider from "@walletconnect/universal-provider";
import { Web3Modal } from "@web3modal/standalone";

export const initialState = {
  web3: null,
  marketPlaceContract: null,
  nftMintingContract: null,
  marketplaceContractAddress: "0x418d541b05D932371EC148852448F7445b2b73dF",
  nftMintingContractAddress: "0x0DA516A3DC76bef71f6b0459D6D01eD64EEf26e4",
  account: null,
  web3LoadingErrorMessage: "",
  web3Loading: false,
  ErrorMessage: "",
  provider: null,
  etherProvider: null,
  signer: null,
  swapSdk: null,
  chainId: "5",
  paymentTokens: null,
  networkName: null,
  moralisConfig: null,
};

export const loadBlockchain = createAsyncThunk(
  "loadwalletcoonnect",
  async (_, thunkAPI) => {
    try {
      //if (Web3.givenProvider && CommonUtility.networkShould(Web3.givenProvider.chainId)) {
      if (Web3.givenProvider) {
        await Web3.givenProvider.enable();
        const web3 = new Web3(Web3.givenProvider);
        // let networkName = await web3.eth.net.getNetworkType();
        await switchNetwork(web3, "0x5");

        const chainId = await web3?.eth.getChainId();
        let networkName = "private";
        let account = await web3.eth.getAccounts();
        account = account[0];
        let contractAddress = ContractUtility.getContractAddress(chainId);
        const marketPlaceContract = new web3.eth.Contract(
          marketplaceContractAbi,
          contractAddress.marketplaceContractAddress
        );

        const nftMintingContract = new web3.eth.Contract(
          nftMintingContractAbi,
          contractAddress.mintingContractAddress
        );
        const etherProvider = new ethers.providers.Web3Provider(
          window.ethereum
        );
        const signer = etherProvider.getSigner();
        const swapSdk = new NftSwapV4(etherProvider, signer, chainId);

        const paymentTokens = ContractUtility.getPaymentList(chainId);
        let moralisConfig = ContractUtility.getMoralisKeys(chainId);
        return {
          web3,
          account,
          marketPlaceContract,
          nftMintingContract,
          marketplaceContractAddress:
            contractAddress.marketplaceContractAddress,
          nftMintingContractAddress: contractAddress.mintingContractAddress,
          etherProvider,
          signer,
          swapSdk,
          chainId,
          paymentTokens,
          networkName,
          moralisConfig,
        };
      } else {
        console.log("error connecting to metamask");
        return {
          web3LoadingErrorMessage: "error connecting to metamask",
        };
      }
    } catch (err) {
      console.log(err);
    }
  }
);

// Connect with Wallet of users choice
export const loadWalletConnect = createAsyncThunk(
  "LoadWalletConnect",
  async (_, thunkAPI) => {
    try {
      const DEFAULT_PROJECT_ID = "1eccdcef1fec662a8e65ca062f39ed04";
      const DEFAULT_RELAY_URL = "wss://relay.walletconnect.com";

      const connector = await UniversalProvider.init({
        projectId: DEFAULT_PROJECT_ID,
        logger: "debug",
        relayUrl: DEFAULT_RELAY_URL,
      });

      const web3Modal = new Web3Modal({
        projectId: DEFAULT_PROJECT_ID || "",
        walletConnectVersion: 2,
      });

      connector.on("display_uri", async (uri) => {
        console.log("EVENT", "QR Code Modal open");
        web3Modal?.openModal({ uri });
      });

      // Subscribe to session ping
      connector.on("session_ping", ({ id, topic }) => {
        console.log("EVENT", "session_ping");
        console.log(id, topic);
      });

      // Subscribe to session event
      connector.on("session_event", ({ event, chainId }) => {
        console.log("EVENT", "session_event");
        console.log(event, chainId);
      });

      // Subscribe to session update
      connector.on("session_update", ({ topic, session }) => {
        console.log("EVENT", "session_updated");
      });

      // Subscribe to session delete
      connector.on("session_delete", ({ id, topic }) => {
        console.log("EVENT", "session_deleted");
        // resetApp();
      });
      // let rpc;
      // if (chain === 5) {
      //   rpc = { 5: "https://rpc.goerli.mudit.blog" };
      // } else {
      //   rpc = {
      //     80001: "https://rpc-mumbai.matic.today",
      //   };
      // }

      await connector.connect({
        namespaces: {
          eip155: {
            methods: [
              "eth_sendTransaction",
              "eth_signTransaction",
              "eth_sign",
              "personal_sign",
              "eth_signTypedData",
            ],
            chains: [`eip155:80001`],
            events: ["chainChanged", "accountsChanged"],
            rpcMap: "https://rpc-mumbai.matic.today",
          },
        },
        // pairingTopic: pairing?.topic,
      });
      const accounts = await connector.enable();
      const web3 = new Web3(connector);
      let chainId = await web3.eth.getChainId();
      let account = accounts[0];

      web3Modal?.closeModal();

      const etherProvider = new ethers.providers.Web3Provider(connector);
      const signer = etherProvider.getSigner();
      console.log("signer", signer, etherProvider);

      const swapSdk = new NftSwapV4(etherProvider, signer, chainId);
      const paymentTokens = ContractUtility.getPaymentList(chainId);

      return {
        account,
        web3,
        chainId,
        etherProvider,
        signer,
        swapSdk,
        paymentTokens,
      };

      // console.log("data", data);
      // dispatch({ type: ActionTypes.WEB3CONNECT, payload: data });

      // }

      // const marketPlaceContract = new web3.eth.Contract(
      //   marketplaceContractAbi,
      //   ContractUtility.config.ropsten.marketplaceContractAddress
      // );

      // const nftMintingContract = new web3.eth.Contract(
      //   nftMintingContractAbi,
      //   ContractUtility.config.ropsten.mintingContractAddress
      // );

      //   return {
      //     web3,
      //     account,
      //     // marketPlaceContract,
      //     // nftMintingContract,
      //     marketplaceContractAddress:
      //       ContractUtility.config.ropsten.marketplaceContractAddress,
      //     nftMintingContractAddress:
      //       ContractUtility.config.ropsten.mintingContractAddress,
      //   };
      // } else {
      //   return {
      //     web3LoadingErrorMessage:
      //       "Please install an Ethereum-compatible browser or extension like MetaMask to use this dApp!",
      //   };
      // }
    } catch (err) {
      console.log("loadWalletConnect", err);
    }
  }
);

// Connect with Wallet of users choice
export const logoutBlockchain = createAsyncThunk(
  "logoutBlockchain",
  async (_, thunkAPI) => {
    try {
      const web3 = null;

      let account = null;

      const marketPlaceContract = null;

      const nftMintingContract = null;

      return {
        web3,
        account,
        marketPlaceContract,
        nftMintingContract,
        marketplaceContractAddress,
        nftMintingContractAddress,
      };
    } catch (err) {
      console.log(err);
    }
  }
);
export const switchNetwork = async (web3, chainId) => {
  const network = ContractUtility.getNetwork(chainId);

  try {
    await web3.currentProvider.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: chainId }],
    });
  } catch (error) {
    if (error.code == 4902) {
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: chainId,
            chainName: ContractUtility.getNetworkText(network),
            nativeCurrency: {
              name: network,
              symbol: ContractUtility.getSymbol(network),
              decimals: 18,
            },
            blockExplorerUrls: [ContractUtility.getNetworkExplorer(network)],
            rpcUrls: [ContractUtility.getNetworkRpc(network)],
          },
        ],
      });
    }
    let connectErrorMsg;
    if (error.code == 4001) {
      connectErrorMsg =
        "Your request to switch networks was rejected. Please make sure you approve the request.";
      return {
        web3ConnectingError: connectErrorMsg,
      };
    }
  }
};
const web3ConnectSlice = createSlice({
  name: "Web3Connect",
  initialState,
  reducers: {
    updateChainId: (state, { payload }) => {
      state.chainId = payload?.networkId;
    },
    updateAccount: (state, { payload }) => {
      state.account = payload?.account;
    },
  },
  extraReducers: {
    [loadWalletConnect.fulfilled.toString()]: (state, { payload }) => {
      state.web3 = payload?.web3;
      state.account = payload?.account;
      state.web3Loading = false;
      state.marketPlaceContract = payload?.marketPlaceContract;
      state.nftMintingContract = payload?.nftMintingContract;
      state.marketplaceContractAddress = payload?.marketplaceContractAddress;
      state.nftMintingContractAddress = payload?.nftMintingContractAddress;

      state.etherProvider = payload?.etherProvider;
      state.signer = payload?.signer;
      state.swapSdk = payload?.swapSdk;
      state.chainId = payload?.chainId;
      state.paymentTokens = payload?.paymentTokens;

      // state.web3LoadingErrorMessage = payload?.web3LoadingErrorMessage
    },
    [loadBlockchain.fulfilled.toString()]: (state, { payload }) => {
      state.web3 = payload?.web3;
      state.account = payload?.account;
      state.web3Loading = false;
      state.marketPlaceContract = payload?.marketPlaceContract;
      state.nftMintingContract = payload?.nftMintingContract;
      state.marketplaceContractAddress = payload?.marketplaceContractAddress;
      state.nftMintingContractAddress = payload?.nftMintingContractAddress;
      state.web3LoadingErrorMessage = payload?.web3LoadingErrorMessage;
      state.etherProvider = payload?.etherProvider;
      state.signer = payload?.signer;
      state.swapSdk = payload?.swapSdk;
      state.chainId = payload?.chainId;
      state.paymentTokens = payload?.paymentTokens;
      state.moralisConfig = payload?.moralisConfig;
    },

    [logoutBlockchain.fulfilled.toString()]: (state, { payload }) => {
      state.web3 = null;
      state.account = null;
      state.web3Loading = false;
      state.marketPlaceContract = null;
      state.nftMintingContract = null;
      state.marketplaceContractAddress = null;
      state.nftMintingContractAddress = null;
      state.web3LoadingErrorMessage = null;
      state.etherProvider = null;
      state.signer = null;
      state.swapSdk = null;
      state.paymentTokens = null;
      state.moralisConfig = null;
    },
  },
});

export const web3Reducer = web3ConnectSlice.reducer;
export const { updateChainId } = web3ConnectSlice.actions;
export const { updateAccount } = web3ConnectSlice.actions;
