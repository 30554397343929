import React, { useEffect } from "react";
import * as H from "./adminDashboardElement";

import { AlignLeftOutlined, AlignRightOutlined } from "@ant-design/icons";
import profileImg from "assets/profile.jpg";

import { useAppDispatch, useAppSelector } from "store/store";
import {
  collapsedDashboard,
  connectModelSlice,
} from "store/redux/slices/helperSlices/modelSlice";

import { LogoutHook } from "hooks";
import { MainButton } from "components/common";

const Header = () => {
  const dispatch = useAppDispatch();

  const { add_blacklist_token } = LogoutHook();

  const toggle = () => {
    dispatch(collapsedDashboard(!collapsed));
  };
  const { collapsed } = useAppSelector((state) => state.model);

  const { web3 } = useAppSelector((state) => state.web3Connect);

  const connectModelFn = () => {
    dispatch(connectModelSlice(true));
  };

  return (
    <div>
      <H.HeaderNavbar bg="light">
        <H.HeaderContainer fluid>
          {React.createElement(
            collapsed ? AlignLeftOutlined : AlignRightOutlined,
            {
              className: "trigger",
              onClick: toggle,
            }
          )}
          <H.HeaderNavbar.Brand href="#">Nethty</H.HeaderNavbar.Brand>
          <H.HeaderNavbar.Toggle aria-controls="navbarScroll" />
          <H.HeaderNavbar.Collapse id="navbarScroll">
            <H.HeaderNav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: "100px" }}
              navbarScroll
            ></H.HeaderNav>
            {web3 ? (
              <div className="d-flex justify-content-center  ">
                <MainButton connectModel onClick={() => add_blacklist_token()}>
                  Logout
                </MainButton>
              </div>
            ) : (
              <div className="d-flex justify-content-center  ">
                <MainButton connectModel onClick={connectModelFn}>
                  Connect Wallet
                </MainButton>
              </div>
            )}
          </H.HeaderNavbar.Collapse>
        </H.HeaderContainer>
      </H.HeaderNavbar>
    </div>
  );
};

export default Header;
