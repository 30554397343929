import React, { useEffect, useState } from "react";
import * as Cr from "../../components/nftForm/nftFormElement";
import * as C from "../../components/common";
import { MainModel } from "../../components";
// redux Slice
import {
  connectModelSlice,
  mainModel,
} from "../../store/redux/slices/helperSlices/modelSlice";
import { collectionValidate } from "../../components/validation/customValidator";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { blockchainSelect } from "../../components/data/selectData";
import {
  MainContainer,
  MainRow,
  UploadField,
  MainCol,
} from "../../components/common";
import {
  setCollectionData,
  setLinks,
  resetCollectionData,
} from "../../store/redux/slices/collectionSlices/collectionDataSlice";
import { CommonUtility } from "../../utility";
import { CreateCollectionHook, useForm, GetCategoriesHook } from "../../hooks";
import { ConnectWalletBtn } from "./createCollectionElement";
import { useNavigate } from "react-router-dom";

const CreateCollectionCom = () => {
  const dispatch = useAppDispatch();
  const imagesPreviewInit = {
    bannerImage: "",
    logo: "",
    featuredImage: "",
  };

  const [connectModel, bannerImage] = useState(false);
  const [imagesPreview, setImagesPreview] = useState(imagesPreviewInit);
  const navigate = useNavigate();

  const { web3, paymentTokens, account, nftMintingContractAddress } =
    useAppSelector((state) => state.web3Connect);
  const { collectionData, links } = useAppSelector(
    (state) => state.collectionData
  );

  const { cateError, categories, cateLoading } = GetCategoriesHook();
  const { data, loading, error, create } = CreateCollectionHook();
  const { handleSubmit, errors } = useForm(
    create,
    collectionValidate,
    collectionData
  );

  useEffect(() => {
    web3 &&
      dispatch(
        setCollectionData({
          ...collectionData,
          ownerAddress: account,
          collectionAddress: nftMintingContractAddress,
        })
      );
  }, [web3]);
  useEffect(() => {
    error && C.ToastMessage("Error", error.message, "error");
    if (data) {
      dispatch(resetCollectionData());
      C.ToastMessage(
        "Created",
        "Collection is successfully created",
        "success"
      );
      URL.revokeObjectURL(imagesPreview.bannerImage);
      URL.revokeObjectURL(imagesPreview.featuredImage);
      URL.revokeObjectURL(imagesPreview.logo);
      navigate("/collection-all");
    }
  }, [error, data]);

  useEffect(() => {
    return () => {
      URL.revokeObjectURL(imagesPreview.bannerImage);
      URL.revokeObjectURL(imagesPreview.featuredImage);
      URL.revokeObjectURL(imagesPreview.logo);
    };
  }, []);

  const handleModelOpen = (event) => {
    event.preventDefault();
    dispatch(connectModelSlice(true));
  };
  return (
    <div style={{ overflowX: "hidden" }} className="createCollecton">
      <MainModel />{" "}
      {web3 ? (
        <MainContainer>
          <MainRow nftFormRow>
            <MainCol lg={8}>
              <Cr.NftFormTitle>Create a Collection</Cr.NftFormTitle>
              <Cr.RequiredLabel>
                {" "}
                <span>*</span>Required Fields
              </Cr.RequiredLabel>
              <Cr.NftForms>
                <UploadField
                  collectionProfileBg
                  bgimg={imagesPreview?.logo}
                  createUploadBtn
                  setValue={async (e) => {
                    const preview = CommonUtility.imagePreview(
                      e?.fileList[e?.fileList.length - 1].originFileObj
                    );
                    setImagesPreview({ ...imagesPreview, logo: preview });
                    dispatch(
                      setCollectionData({
                        ...collectionData,
                        logo: e?.fileList[e?.fileList.length - 1].originFileObj,
                      })
                    );
                  }}
                  error={errors.logo}
                  uploadLabel="Logo image"
                  inputLabelText="This image will also be used for navigation. 350 x 350 recommended."
                  inputRequired
                />

                <UploadField
                  uploadLabel="Featured image"
                  inputLabelText="This image will be used for featuring your collection on the homepage, category pages, or other promotional areas of OpenSea. 600 x 400 recommended.
                "
                  bgimg={imagesPreview?.featuredImage}
                  setValue={async (e) => {
                    const preview = CommonUtility.imagePreview(
                      e?.fileList[e?.fileList.length - 1].originFileObj
                    );
                    setImagesPreview({
                      ...imagesPreview,
                      featuredImage: preview,
                    });
                    dispatch(
                      setCollectionData({
                        ...collectionData,
                        featuredImage:
                          e?.fileList[e?.fileList.length - 1].originFileObj,
                      })
                    );
                  }}
                  collectionFeaturedImage
                  featuredUploadBtn
                  error={errors.featuredImage}
                />

                <UploadField
                  bannerUploadBtn
                  uploadLabel="Banner image"
                  bgimg={imagesPreview?.bannerImage}
                  setValue={async (e) => {
                    const preview = CommonUtility.imagePreview(
                      e?.fileList[e?.fileList.length - 1].originFileObj
                    );
                    setImagesPreview({
                      ...imagesPreview,
                      bannerImage: preview,
                    });
                    dispatch(
                      setCollectionData({
                        ...collectionData,
                        bannerImage:
                          e?.fileList[e?.fileList.length - 1].originFileObj,
                      })
                    );
                  }}
                  inputLabelText="This image will appear at the top of your collection page. Avoid including too much text in this banner image, as the dimensions change on different devices. 1400 x 400 recommended."
                  collectionBannerImage
                  error={errors.bannerImage}
                />

                <C.InputField
                  inputLabel="Name"
                  inputType="text"
                  inputRequired
                  placeholder="Enter Name"
                  setValue={(e) => {
                    dispatch(
                      setCollectionData({
                        ...collectionData,
                        name: e.target.value,
                      })
                    );
                  }}
                  value={collectionData.name}
                  error={errors.name}
                />

                <C.TextArea
                  inputLabel="Description"
                  inputLabelText="Markdown syntax is supported. 0 of 1000 characters used. "
                  placeholder="Enter Description"
                  setValue={(e) => {
                    dispatch(
                      setCollectionData({
                        ...collectionData,
                        description: e.target.value,
                      })
                    );
                  }}
                  maxLength={200}
                  value={collectionData.description}
                  error={errors.description}
                />

                <C.Select
                  inputLabel="Category"
                  inputLabelText="Markdown syntax is supported. 0 of 1000 characters used."
                  dropDownList={
                    categories
                      ? categories.map((cate) => ({
                          text: cate.name,
                          value: cate.name,
                        }))
                      : [{ text: "Loading", value: "loading..." }]
                  }
                  value={collectionData.category}
                  setValue={(e) =>
                    dispatch(
                      setCollectionData({ ...collectionData, category: e })
                    )
                  }
                  error={errors.category}
                />

                <div className="Links">
                  <label
                    htmlFor=""
                    style={{
                      color: "rgba(0,0,0,0.7)",
                      margin: "1rem 0 0 0",
                      fontSize: "1.4rem",
                    }}
                  >
                    Links
                  </label>
                  <C.InputField
                    inputType="text"
                    placeholder="Enter Google Link"
                    setValue={(e) => {
                      dispatch(setLinks({ value: e.target.value, index: 0 }));
                    }}
                    value={links[0]}
                  />
                  <C.InputField
                    inputType="text"
                    placeholder="Enter Disord Link"
                    setValue={(e) => {
                      dispatch(setLinks({ value: e.target.value, index: 1 }));
                    }}
                    value={links[1]}
                  />
                  <C.InputField
                    inputType="text"
                    placeholder="Enter Instagram Link"
                    setValue={(e) => {
                      dispatch(setLinks({ value: e.target.value, index: 2 }));
                    }}
                    value={links[2]}
                  />
                  <C.InputField
                    inputType="text"
                    placeholder="Enter Medium Link"
                    setValue={(e) => {
                      dispatch(setLinks({ value: e.target.value, index: 3 }));
                    }}
                    value={links[3]}
                  />
                  <C.InputField
                    inputType="text"
                    placeholder="Enter Twitter Link"
                    inputLinks
                    setValue={(e) => {
                      dispatch(setLinks({ value: e.target.value, index: 4 }));
                    }}
                    value={links[4]}
                  />
                </div>

                <C.Select
                  inputLabel="Blockchain"
                  inputLabelText="Select the blockchain where you'd like new items from this collection to be added by default."
                  dropDownList={blockchainSelect}
                  value={collectionData.network}
                  setValue={(e) =>
                    dispatch(
                      setCollectionData({ ...collectionData, network: e })
                    )
                  }
                  error={errors.network}
                />

                <C.Select
                  inputLabel="Payment tokens"
                  inputLabelText="These tokens can be used to buy and sell your items."
                  dropDownList={paymentTokens}
                  value={collectionData.paymentToken}
                  setValue={(e) =>
                    dispatch(
                      setCollectionData({ ...collectionData, paymentToken: e })
                    )
                  }
                  error={errors.paymentToken}
                />

                {web3 ? (
                  <ConnectWalletBtn
                    onClick={handleSubmit}
                    disabled={loading ? true : false}
                    className="mt-4 mb-4"
                  >
                    {loading ? "submiting.." : "Submit"}
                  </ConnectWalletBtn>
                ) : (
                  <ConnectWalletBtn
                    className="mt-4 mb-4"
                    onClick={handleModelOpen}
                  >
                    Connect
                  </ConnectWalletBtn>
                )}
              </Cr.NftForms>
            </MainCol>
          </MainRow>
        </MainContainer>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "60vh" }}
        >
          <ConnectWalletBtn onClick={handleModelOpen}>
            Connect Wallet
          </ConnectWalletBtn>
        </div>
      )}
    </div>
  );
};

export default CreateCollectionCom;
