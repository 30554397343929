import {
  BreadCrumbComp,
  MainCol,
  MainContainer,
  MainRow,
} from "components/common";
import { useEffect, useRef, useState } from "react";
import {
  CardWrapper,
  DropdownDiv,
  ImageWrapper,
  NewTemplateBtn,
  SelectDiv,
  ViewBtn,
  ViewWrapper,
  dividerStyle,
} from "./elements";
import { BsInfoCircleFill, BsThreeDots } from "react-icons/bs";
import { CreateBtn, Title } from "../schema/elements";
import { AiOutlinePlus } from "react-icons/ai";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Divider, Modal, Select } from "antd";

import { Card } from "react-bootstrap";
import { GetTemplateBySchemaIdHook } from "hooks/templateHook";
import Loading from "components/common/loader";
import { GetCreateSchemaHook } from "hooks/createSchemaHook";
import { UpdateSupplyModal } from "components/modal";
import { useAppSelector } from "store/store";

const dataa = [
  {
    id: 1,
    text: "Starship NFT Game",
    link: "/admin/starship",
  },
  {
    id: 2,
    text: "Schemas and templates",
    link: "/starship/admin/schemas",
  },
];

const ViewTemplateComp = () => {
  const location = useLocation();
  const queryString = location.search;
  const searchParams = new URLSearchParams(queryString);
  const id = searchParams.get("id");

  const [template, setTemplate] = useState([]);
  const [page, setPage] = useState(30);
  const [search, setSearch] = useState(null);

  const { data, loading, templateCount } = GetTemplateBySchemaIdHook(id, page);
  const { data: schemasList } = GetCreateSchemaHook();
  const { config } = useAppSelector((state) => state.configData);

  useEffect(() => {
    if (data) {
      let temp = data?.map((x) => {
        let res = x?.template_attributes?.find((m) => m.field == "file");
        if (res) {
          return {
            ...x,
            image: x?.ipfs_image,
          };
        } else {
          return {
            ...x,
          };
        }
      });

      setTemplate(temp);
    }
  }, [data]);

  const navigate = useNavigate();

  const onSearch = (value) => {
    console.log("search:", value);
  };

  const [contentDiv, setContentDiv] = useState("");
  const dropdownRef = useRef(null);
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setContentDiv("");
      setSearch(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleScroll = () => {
    const scrollPosition = window.innerHeight + window.pageYOffset;
    const scrollHeight = document.documentElement.scrollHeight;
    if (scrollPosition >= scrollHeight - 180 && !loading) {
      if (page < templateCount) {
        setPage(page + 20);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [data, page, templateCount, loading]);

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [clickedIndex, setClickedIndex] = useState();

  const handleToggleDropdown = (index) => {
    setClickedIndex(index);
    setDropdownVisible(!dropdownVisible);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <ViewWrapper>
      <Modal open={isModalOpen} onCancel={handleCancel} centered width={481}>
        <UpdateSupplyModal handleCancel={handleCancel} />
      </Modal>
      <MainContainer fluid>
        {loading && <Loading content={"Getting Data"} />}

        <div style={{ marginTop: "3rem" }}>
          <BreadCrumbComp data={dataa} />
        </div>

        <Title>
          Schemas and templates&nbsp;
          <BsInfoCircleFill fontSize={15} />
        </Title>

        <div className="d-flex justify-content-between">
          <div className="d-flex gap-3 align-items-center">
            <CreateBtn
              onClick={() => {
                navigate("/starship/admin/schemas/create-schema");
              }}
            >
              <AiOutlinePlus color="#ffffff" />
              &nbsp;&nbsp;Create a schema
            </CreateBtn>
            <SelectDiv>
              <Select
                showSearch
                placeholder="choose schema"
                optionFilterProp="children"
                onChange={(e) => {
                  navigate("/starship/admin/template/view-template?id=" + e);
                }}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={schemasList?.map((x) => {
                  return { value: x?._id, label: x.schema_name };
                })}
              />
            </SelectDiv>
          </div>

          <Link to={"/starship/admin/template/create-template?id=" + id}>
            <NewTemplateBtn className="tbtn">
              <AiOutlinePlus color="#ffffff" />
              &nbsp;&nbsp;new template
            </NewTemplateBtn>
          </Link>
        </div>
        <MainRow>
          <MainCol lg={12}>
            <div className="card-wrapper">
              {template?.map((item, index) => (
                <div key={item?._id}>
                  <CardWrapper>
                    <Card>
                      <div className="dropdown-wrapper">
                        <BsThreeDots
                          fontSize={18}
                          cursor="pointer"
                          className="dots-icon"
                          onClick={() => handleToggleDropdown(index)}
                        />
                        {clickedIndex === index && dropdownVisible && (
                          <DropdownDiv>
                            <Link
                              to={
                                "/starship/admin/template/edit-template/detail/" +
                                item?._id
                              }
                            >
                              <p>Edit Template</p>
                            </Link>
                          </DropdownDiv>
                        )}
                      </div>
                      <ImageWrapper>
                        <Card.Img
                          src={item?.ipfs_image}
                          width={231}
                          height={254}
                        />
                      </ImageWrapper>
                      <Card.Body>
                        <Card.Title>#{item?.nft_id}</Card.Title>

                        <Card.Text>
                          {
                            item?.template_attributes?.find(
                              (obj) =>
                                obj?.attribute_type?.toLowerCase() === "name"
                            )?.attribute_value
                          }
                          (
                          {+item?.max_supply == 0 ||
                          +item?.max_supply >= +config?.UINT256_MAX
                            ? "∞"
                            : item?.max_supply}
                          )
                        </Card.Text>

                        <Divider style={dividerStyle} />
                        <Link
                          to={`/starship/admin/template/view-template/detail/${item?._id}`}
                        >
                          <ViewBtn>view</ViewBtn>
                        </Link>
                      </Card.Body>
                    </Card>
                  </CardWrapper>
                </div>
              ))}
            </div>
          </MainCol>
        </MainRow>
      </MainContainer>
    </ViewWrapper>
  );
};

export default ViewTemplateComp;
