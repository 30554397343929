import styled from "styled-components";

export const SchemeWrapper = styled.div`
  overflow: hidden;

  .container-fluid {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .first-div {
    width: 60%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @media (max-width: 1399.98px) {
      width: 70%;
    }
    @media (max-width: 1199.98px) {
      width: 80%;
    }

    @media (max-width: 991.98px) {
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-content: center;
    }
  }

  .second-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: 991.98px) {
      flex: 1;
      width: 100%;
    }
  }
`;

export const Title = styled.h5`
  color: var(--secondary, #3e3e3e);
  font-family: "Barlow";
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
  text-transform: capitalize;
  margin-top: 3rem;

  @media (max-width: 990px) {
    text-align: center;
  }
`;

export const CreateBtn = styled.button`
  border-radius: 1.75rem;
  background: var(--pimary, #00b7ff);
  color: #fff;
  font-family: "Barlow";
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  width: 171px;
  height: 35px;
  margin-top: 2.5rem;

  @media (max-width: 991.98px) {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const MainTitle = styled.div`
  color: var(--secondary, #3e3e3e);
  font-family: "Barlow";
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
  text-transform: capitalize;
  margin-top: 2.5rem;
  text-align: center;
`;

export const Text = styled.p`
  color: #6b6b6b;
  font-family: "Barlow";
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  margin-top: 1rem;
  /* margin-left: -2rem; */
  margin-bottom: 0rem;
  text-align: center;
`;

export const MainWrapper = styled.div`
  /* display: flex;
  justify-content: space-between; */
`;

export const TopDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 3rem;
  h5 {
    color: #6b6b6b;
    font-family: "Barlow";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    cursor: pointer;
  }

  h4 {
    color: #c9c9c9;
    font-family: "Barlow";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    cursor: pointer;
  }
`;

export const InputTitle = styled.h5`
  margin-top: 1.5rem;
  color: var(--secondary, #3e3e3e);
  font-family: "Barlow";
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
  text-transform: capitalize;
`;

export const InputWrapper = styled.div`
  margin-top: 2rem;
  .ant-input {
    border-radius: 0.625rem;
    border: 1px solid #e7e7e7;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27);
    height: 2.9375rem;
    margin-top: 1rem;
    &::placeholder {
      color: #c9c9c9;
      font-family: "Barlow";
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;
    }
  }

  p {
    color: #6b6b6b;
    font-family: "Barlow";
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    margin-top: 0.7rem;
    margin-bottom: 0rem;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    /* border-radius: 0.625rem;
    border: 1px solid #e7e7e7;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27);
    height: 2.9375rem;
    margin-top: 1rem;
    justify-content: center;
    display: flex;
    align-items: center; */
  }

  /* .ant-select-arrow {
    top: 64% !important;
  } */

  .ant-select {
    width: 100%;
    border-radius: 0.625rem;
    border: 1px solid #e7e7e7;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27);
    height: 2.9375rem;
    margin-top: 1rem;
    justify-content: center;
    display: flex;
    align-items: center;
  }

  .createCollecton
    .ant-select-single.ant-select-show-arrow
    .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    color: #c9c9c9;
    font-family: "Barlow";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    margin-top: 0.4rem;
  }
`;

export const AddAttributeBtn = styled.button`
  border-radius: 1.75rem;
  border: 0.5px solid var(--secondary, #3e3e3e);
  width: 9.5625rem;
  height: 1.9375rem;
  background-color: transparent;
  color: #3e3e3e;
  font-family: "Barlow";
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  margin-top: 2.5rem;

  @media (max-width: 756px) {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const BtnWrapper = styled.div`
  display: flex;
  gap: 1.5rem;
  margin-top: 2rem;
  justify-content: end;
  align-items: center;
  padding-bottom: 2rem;

  @media (max-width: 756px) {
    display: flex;
    justify-content: center;
  }
`;

export const CancelBtn = styled.button`
  border: none;
  background-color: transparent;
  color: var(--secondary, #3e3e3e);
  font-family: "Barlow";
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
`;

export const CreateSchemaBtn = styled.button`
  color: #fff;
  font-family: "Barlow";
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  border-radius: 1.75rem;
  background: var(--pimary, #00b7ff);
  width: 9.9375rem;
  height: 2.1875rem;

  &:disabled {
    background: ${({ theme }) => theme.disabledBtnBG};
    border: 1px solid ${({ theme }) => theme.disabledBtnBorder} !important;
    color: rgb(183, 189, 202);
  }
`;

export const LastDiv = styled.div`
  p {
    color: #6b6b6b;
    font-family: "Barlow";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    margin-top: 1rem;
    text-align: center;
  }

  h5 {
    color: var(--primary, #4d1ce8);
    font-family: "Barlow";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;
    text-transform: capitalize;
    margin-top: 1rem;
    text-align: center;
  }
`;

export const SchemaDiv = styled.div`
  border-radius: 0.625rem;
  border: 1px solid #e7e7e7;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27);
  width: 10.6875rem;
  padding: 15px 10px;
  margin-top: 2rem;
  h5 {
    color: #6b6b6b;
    font-family: "Barlow";
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;

    &:not(:first-child) {
      margin-top: 2rem;
    }
  }
`;

export const SelectDiv = styled.div`
  .ant-select {
    border-radius: 1.75rem;
    /* border: 0.5px solid var(--secondary, #3e3e3e); */
    width: 10.5625rem;
    height: 2.1875rem;
    margin-top: 1rem;
    justify-content: center;
    display: flex;
    align-items: center;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 0.5px solid var(--secondary, #3e3e3e) !important;
    border-radius: 1.75rem;
  }

  .createCollecton
    .ant-select-single.ant-select-show-arrow
    .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    color: #3e3e3e;
    font-family: "Barlow";
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    margin-top: 0.2rem;
  }
`;
