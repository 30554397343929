import { InputField } from "../../components/common";
import React from "react";
// import { updateUsernameValidate } from "components/validation/customValidator";
import { updateUsernameValidate } from "../../components/validation/customValidator";
// import { useAppDispatch, useAppSelector } from "store/store"
import { useAppDispatch, useAppSelector } from "../../store/store";
// import { setUpdatedCredentials } from "store/redux/slices/adminSlices/credentialsUpdateSlice";
import { setUpdatedCredentials } from "../../store/redux/slices/adminSlices/credentialsUpdateSlice";
// import { UpdateUsernameHook, useForm } from "hooks";
import { UpdateUsernameHook, useForm } from "../../hooks";

const UpdateUsername = () => {
  const { credentials } = useAppSelector((state) => state.credentials);

  const { updateLoading, updateUsername, updateError } = UpdateUsernameHook();
  const dispatch = useAppDispatch();

  const { handleSubmit, errors } = useForm(
    updateUsername,
    updateUsernameValidate,
    credentials
  );

  return (
    <div>
      <h3>Update Username</h3>
      <InputField
        value={credentials.username}
        inputLabel="Username"
        error={errors.username}
        placeholder="username"
        setValue={(e) =>
          dispatch(
            setUpdatedCredentials({ ...credentials, username: e.target.value })
          )
        }
        inputType="text"
      />
      <br />
      <button
        disabled={updateLoading ? true : false}
        onClick={handleSubmit}
        type="button"
      >
        {updateLoading ? "updateing..." : "update username"}
      </button>
    </div>
  );
};

export default UpdateUsername;
