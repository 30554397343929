import { MdClose } from "react-icons/md";
import { ModalWrapper, TransferBtn } from "./elements";
import { Form } from "react-bootstrap";
import { useAppSelector } from "store/store";
import { CommonUtility } from "utility";
import { starshipAbi } from "contract/starship";
import { useState } from "react";
import { ToastMessage } from "components/common";
import Loading from "components/common/loader";

const TransferModal = ({ handleCancel, item, getNftsByUser }) => {
  const { web3, account, chainId } = useAppSelector(
    (state) => state.web3Connect
  );

  const { config } = useAppSelector((state) => state.configData);
  const [recipient, setRecipient] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const transferNft = async () => {
    try {
      setIsLoading(true);
      if (!CommonUtility.isEthereumAddress(recipient))
        throw "Not a valid Ethereum Address";
      const starShip = config.STAR_SHIP_ADDRESS;

      const contract = CommonUtility.contract(
        web3,
        starshipAbi,
        starShip[+chainId]
      );
      const txn = await contract.methods
        .transferFrom(account, recipient, item?.nft_id)
        .send({ from: account });
      if (txn && txn.code == 4001) throw "User denied the transaction";
      if (!txn.status) throw "Transaction Failed";
      setTimeout(await getNftsByUser(account), 10000);
      if (txn.status) {
        handleCancel();
        setRecipient(null);
        setIsLoading(false);

        ToastMessage("Success!", "Transaction successful", "success");
      }
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
      if (error?.message) {
        ToastMessage("Error", error?.message, "error");
      } else {
        ToastMessage("Error", error, "error");
      }
    }
  };
  return (
    <ModalWrapper>
      {isLoading && <Loading content={"Transfer in progress"} />}

      <MdClose
        cursor="pointer"
        fontSize={22}
        color="#6B6B6B"
        className="close-icon"
        onClick={handleCancel}
      />

      <Form.Label>Transfer</Form.Label>
      <Form.Control
        type="text"
        placeholder="wallet address"
        onChange={(e) => setRecipient(e.target.value)}
      />
      <p style={{ color: "red" }}>
        {recipient &&
          !CommonUtility.isEthereumAddress(recipient) &&
          "Invalid recipient address"}
      </p>
      <TransferBtn
        disabled={!CommonUtility.isEthereumAddress(recipient)}
        onClick={transferNft}
      >
        Transfer
      </TransferBtn>
    </ModalWrapper>
  );
};

export default TransferModal;
