import styled, { css } from "styled-components";
import { Card } from "react-bootstrap";

import { Select } from "antd";

const { Option } = Select;

export const MainSelectDiv = styled.div`
  .form-label {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    color: #333333;
    margin-top: 1.5rem;
    margin-bottom: 0;
    font-family: "Roboto", sans-serif !important;
  }

  .text-muted {
    font-style: normal;
    font-family: "Roboto", sans-serif !important;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height, or 110% */

    /* Gray 3 */

    color: #828282;
  }
`;

export const MainSelect = styled(Select)`
  display: block;
  background: white;
  padding: 0.3rem;
  background: #ffffff;
  border: 1px solid #828282;
  border-radius: 5px;

  .ant-select-arrow {
  }

  ${(props) =>
    props.trendingSelect &&
    css`
      display: inline-block;
      width: 10rem;
      background: transparent;
      color: white;
      margin-top: 2.6rem !important;
    `}

  ${(props) =>
    props.isPriceSelect &&
    css`
      display: inline-block;
      width: 13rem;

      margin-top: -3rem !important;
    `}
`;

export const SelectOption = styled(Select.Option)`
  padding: 1.5rem !important;
`;
