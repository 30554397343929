import { Loader } from "components/common";
import { GetCollectionByIdHook } from "../../hooks";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import DetailsCollection from "./detailsCollection";
import HeroSection from "./heroSection";
import { paymentList } from "components/data/selectData";

const CollectionAllCom = () => {
  const params = useParams();
  const [sort, setSort] = useState(null);
  const [data, setData] = useState(null);
  const [filter, setFilter] = useState(null);
  const { getCollection, loading, collection } = GetCollectionByIdHook();

  const attributes = useMemo(() => {
    if (collection && collection.items.length > 0) {
      let attributes = {};
      for (let i = 0; i < collection.items.length; i++) {
        const nft = collection.items[i];
        const attri = nft.attributes ? JSON.parse(nft.attributes) : [];
        for (let i = 0; i < attri.length; i++) {
          const a = attri[i];
          if (
            attributes[a.trait_type.toLocaleLowerCase()] &&
            !attributes[a.trait_type.toLocaleLowerCase()].includes(a.value)
          ) {
            attributes[a.trait_type.toLocaleLowerCase()].push(a.value);
          } else {
            attributes[a.trait_type.toLocaleLowerCase()] = [a.value];
          }
        }
      }
      return attributes;
    }
  }, [collection]);

  useEffect(() => {
    getCollection(params?.id, sort, filter);
  }, []);

  useEffect(() => {
    collection && setData(collection);
  }, [collection]);

  useEffect(() => {
    if ((sort || filter) && data) {
      let copied = [...collection?.items];

      if (filter?.listed) {
        copied = copied.filter((x) => x.sellNft);
      }
      if (filter.name) {
        copied = copied.filter((x) => x.name.includes(filter.name));
      }
      if (filter?.price) {
        copied = copied.filter(
          (x) =>
            x.sellNft &&
            paymentList(`${x.network_id}`).some((n) => {
              const userPrice =
                x.sellNft.signed_order.erc20TokenAmount / 10 ** n.decimals;
              if (
                n.value.toLocaleLowerCase() ===
                  x.sellNft.signed_order.erc20Token &&
                n.text === filter.price.type &&
                userPrice >= +filter.price.min &&
                userPrice <= +filter.price.max
              ) {
                return true;
              } else return false;
            })
        );
      }
      if (filter.attributes && Object.keys(filter.attributes).length > 0) {
        copied = copied.filter((x) => {
          if (x.attributes) {
            const attributes = JSON.parse(x.attributes);
            return (
              attributes.length > 0 &&
              Object.keys(filter.attributes).every((n) =>
                filter.attributes[n].some((x) =>
                  attributes.some((a) => a.trait_type === n && a.value === x)
                )
              )
            );
          }
        });
      }
      if (filter.chain && filter.chain.length > 0) {
        copied = copied.filter((x) =>
          filter.chain.some((c) => c === x.network_id)
        );
      }
      if (sort?.price === "low") {
        copied = copied.sort(
          (a, b) =>
            a.sellNft?.signed_order.erc20TokenAmount -
            b.sellNft?.signed_order.erc20TokenAmount
        );
      }
      if (sort?.price === "high") {
        copied = copied.sort(
          (a, b) =>
            b.sellNft?.signed_order.erc20TokenAmount -
            a.sellNft?.signed_order.erc20TokenAmount
        );
      }

      setData({ ...data, items: copied });
    }
  }, [sort, filter]);

  return (
    <div>
      {loading && <Loader content="Loading Collection..." />}
      {data && (
        <>
          <HeroSection data={collection} />
          <DetailsCollection
            data={data?.items}
            sort={sort}
            setFilterData={setFilter}
            setSort={setSort}
            dataFilter={filter}
            attributes={attributes}
          />
        </>
      )}
    </div>
  );
};

export default CollectionAllCom;
