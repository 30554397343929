import { createSlice } from "@reduxjs/toolkit";


export const categoryDataSlice = createSlice({
    name: "category data slice",
    initialState: {
        category: {
            id:"",
            name:"",
            instructions:"",
            update: false
        },
        categories: null
    },
    reducers: {
        setCategory: (state, action) => {
            return {
                ...state,
                category: {...action.payload}
            }
        },
        setCategories: (state, action) => {
            return {
                ...state,
                categories: action.payload
            }
        },
        resetCategoryData: (state) => {
            return {
                ...state,
                category: {
                    id:"",
                    name:"",
                    instructions:"",
                    update: false
                }
            }
        }
    }
});

export const { setCategory,setCategories, resetCategoryData } = categoryDataSlice.actions;
export const categoryDataReducer = categoryDataSlice.reducer;
