import { AllNftsCom } from "components";

const AllNfts = () => {
  
  return (
    <div>
        <AllNftsCom />
    </div>
  );
};

export default AllNfts;
