import { routerAbi } from "contract/uniswapRouter";
import Web3 from "web3";

export const GetTokenPriceInUsd = async (config, chainId) => {
  try {
    const currentChain = chainId ? chainId : config.DEFAULT_CHAIN_ID;
    const web3 = new Web3(config.RPC[currentChain]);
    const routerContract = new web3.eth.Contract(
      routerAbi,
      config.ROUTER_ADDRESS[currentChain]
    );
    const price = await routerContract.methods
      .getAmountsOut("1000000000", [
        config.STARSHIP_TOKEN[currentChain],
        // config.WETH[currentChain],
        config.USDT[currentChain],
      ])
      .call();
    const tokenPrice = +price[price?.length - 1] / 10 ** 6;
    return tokenPrice;
  } catch (error) {
    console.log("error", error);
  }
};
